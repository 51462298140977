(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-name', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.route',
    'pp.filters.property'
]);

ngModule.component('ppPropertyCardName', {
    templateUrl: 'widgets/_angular/property-card-name/property-card-name.tpl.html',
    bindings: {
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', 'routeService', 'R', function ($scope, ppConfig, routeService, R) {
        var vm = this;

        // -- initial state

        var __endpoints = ppConfig.get('pp.external.endpoints') || {};
        vm.assetsEndpoint = __endpoints.assets;

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$onChanges = function (changes) {
            var symbol = R.path(['property', 'currentValue', 'symbol'], changes);
            if (symbol) {
                vm.propertyLink = routeService.getIndividualPropertyPath(symbol);
            }
        };

        // -- main

    }]
});
})(window, window.angular);