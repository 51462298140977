(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.faqs-box', ['ngSanitize', 'pp.services.core', 'pp.widgets-templates', 'pp.widgets.expandable-toggle', 'pp.values.faqs']);

var QUOTE = 'quote';

ngModule.component('ppFaqsBox', {
    templateUrl: 'widgets/_angular/faqs-box/faqs-box.tpl.html',
    bindings: {
        type: '@',
        property: '<?'
    },
    controllerAs: 'vm',
    bindToController: true,
    controller: ['ppUtil', 'faqs', function (ppUtil, faqs) {
        var vm = this;

        // -- initial state

        vm.faqs = [];

        var quoteFaqs = [faqs.regulatedByFCA, faqs.dividendsPaid, faqs.investAsCorporate, faqs.fees];
        // -- util functions

        function init() {
            switch (vm.type) {
            case QUOTE:
                if (ppUtil.hasPathIn(vm.property, 'state.status') && vm.property.state.status === 'pre-order') {
                    quoteFaqs.unshift(faqs.scaleBack);
                }
                vm.faqs = quoteFaqs;
                break;
            }
            vm.faqs = vm.faqs.map(function (faq) {
                var f = angular.copy(faq);
                f.collapsed = true;
                return f;
            });
        }

        vm.collapseAllExceptClicked = function (title) {
            vm.faqs = vm.faqs.map(function (faq) {
                var f = angular.copy(faq);
                if (title !== faq.title) {
                    f.collapsed = true;
                }
                return f;
            });
        };

        // -- api

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            init();
        };

    }]

});
})(window, window.angular);