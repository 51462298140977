(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.required-user-data-dialog', ['pp.services.core', 'pp.services.dialog', 'pp.widgets.required-user-data']);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/required-user-data`.
 */
ngModule.service('requiredUserDataDialogService', ['$rootScope', '$timeout', 'ppTrack', 'dialogService', function ($rootScope, $timeout, ppTrack, dialogService) {

    // -- initial state

    var promiseCache;

    // -- util functions

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(user, goodReputeAgreed, isSourceOfFundsRequired, onSuccess) {

        ppTrack.action('required-user-data.dialog.show');

        var scope = $rootScope.$new();
        scope.user = user;
        scope.onSuccess = onSuccess;
        scope.goodReputeAgreed = goodReputeAgreed;
        scope.isSourceOfFundsRequired = isSourceOfFundsRequired;

        var options = {
            clickClose: false,
            escapeClose: false,
            showClose: false,
            classNames: 'large',
            scope: scope,
        };

        options.contents = '<div pp-required-user-data user="user" good-repute-agreed="goodReputeAgreed" is-source-of-funds-required="isSourceOfFundsRequired" on-success="onSuccess()"></div>';

        return dialogService.show(options).then(function () {

            // if dialog is closed set cache to null
            // This means service can be replayed
            promiseCache = null;
        });
    }

    // -- api

    var api = {};

    api.show = function (user, goodReputeAgreed, isSourceOfFundsRequired, onSuccess) {

        if (!promiseCache) {
            promiseCache = showDialog(user, goodReputeAgreed, isSourceOfFundsRequired, onSuccess);
        }

        return promiseCache;
    };

    api.close = function () {
        if (promiseCache) {
            dialogService.close();
        }
    };

    return api;
}]);
})(window, window.angular);