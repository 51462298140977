(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.team-member-details', ['pp.widgets-templates']);

ngModule.directive('ppTeamMemberDetails', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/team-member-details/team-member-details.tpl.html',
        scope: {
            teamMember: '=ppTeamMemberDetails',
            s3Endpoint: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: [function () {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- scope bindings

            // -- api

            // -- main

        }]
    };
});
})(window, window.angular);