(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.browser-store', []);

/**
 * @ngdoc service
 * @name browser-storeService
 *
 * @description
 */
ngModule.service('browserStoreService', [function () {

    var api = {};

    /**
     * @ngdoc method
     * @name browserStoreService#
     *
     * @description
     * sessionStorage.setItem('key', 'value');

     *
     */

    function constructStorageKey(key, isPermanentStorage) {
        var storageType = isPermanentStorage ? 'permanent.' : '';
        return 'pp-store.' + storageType + key;
    }

    function normaliseStorageBooleans(data) {
        if (data === 'true') {
            data = true;
        }

        if (data === 'false') {
            data = false;
        }

        return data;
    }

    api.setSessionStorageItem = function (key, value) {
        //set session storage, takes an object
        var storageKey = constructStorageKey(key);
        sessionStorage.setItem(storageKey, value);
    };

    api.getSessionStorageItem = function (key) {
        //get session storage, takes an key
        var storageKey = constructStorageKey(key);
        var data = sessionStorage.getItem(storageKey);

        return normaliseStorageBooleans(data);
    };

    api.deleteSessionStorageItem = function (key) {
        //deletes session storage
        var storageKey = constructStorageKey(key);
        sessionStorage.removeItem(storageKey);
    };

    api.setLocalStorageItem = function (key, value) {
        // sets permanent (local) storage
        var storageKey = constructStorageKey(key, true);
        localStorage.setItem(storageKey, value);
    };

    api.getLocalStorageItem = function (key) {
        // gets permanent (local) storage
        var storageKey = constructStorageKey(key, true);
        var data = localStorage.getItem(storageKey);

        return normaliseStorageBooleans(data);
    };

    return api;
}]);
})(window, window.angular);