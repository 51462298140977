(function(window, angular){'use strict';

var ngModule = angular.module('pp.filters.property', []);

// 'flat, nameOrNumber, location1, location2, city, postcode'
ngModule.filter('propertyAddress', [function () {

    function reverseString(str) {
        return str.split('').reverse().join('');
    }

    return function (property) {
        var address = [];
        var propertyCopy = angular.copy(property) || {};
        var location = angular.copy(propertyCopy.location) || {};

        if (location.flat) {
            address.push(location.flat);
        }
        if (location.nameOrNumber) {
            address.push(location.nameOrNumber);
        }
        if (location.location1) {
            address.push(location.location1);
        }
        if (location.location2) {
            address.push(location.location2);
        }
        if (location.city) {
            address.push(location.city);
        }
        if (location.postcode && location.postcode !== '-') {
            address.push(reverseString(reverseString(location.postcode).replace(/([A-Z0-9]{0,3})(\s)?([A-Z0-9]*)/i, '$1 $3')));
        }

        return address.join(', ');
    };
}]);

// 'block', 'flat', 'house', 'maisonette'
ngModule.filter('propertyTypeLabel', [function () {
    return function (type) {
        type = type || '';
        return type.substring(0, 1).toUpperCase() + type.substring(1);
    };
}]);

// 'freehold', 'leasehold', 'share of freehold'
ngModule.filter('propertyOwnershipLabel', [function () {
    return function (type) {
        switch (type) {
        case 'FH':
            return 'Freehold';
        case 'LH':
            return 'Leasehold';
        case 'SF':
            return 'Share of Freehold';
        default:
            return 'Undeclared ownership';
        }
    };
}]);

// '3 Unit Block' or '2 Bedroom Flat'
ngModule.filter('propertyUnitCount', [function () {
    return function (attributes) {
        if (attributes) {
            if (attributes.residentialUnits || attributes.commercialUnits) {
                var units = attributes.residentialUnits + attributes.commercialUnits;
                return units + ' Unit';
            } else {
                return attributes.bedrooms + ' Bed';
            }
        }
        return '';
    };
}]);

// 'Surrey Quays, London, SE16'
ngModule.filter('propertyLocation', [function () {
    return function (location) {
        if (location) {
            var parts = [];
            if (location.area) {
                parts.push(location.area);
            }
            if (location.region) {
                parts.push(location.region);
            }
            var postcode = location.postcode;
            if (angular.isString(postcode)) {
                var postcodeSubstring = postcode.substring(0, postcode.length - 3).trim();
                parts.push(postcodeSubstring.length > 1 ? postcodeSubstring : postcode);
            }
            return parts.join(', ');
        }
        return '';
    };
}]);

// @todo JIRA PROPERTY API kill filters below, use the ones above

ngModule.filter('propertyType', [function () {
    return function (type) {
        switch (type) {
        case 'H':
            return 'House';
        case 'F':
            return 'Flat';
        case 'M':
            return 'Maisonette';
        case 'B':
            return 'Block';
        default:
            return 'Property';
        }
    };
}]);

ngModule.filter('ownershipType', [function () {
    return function (type) {
        switch (type) {
        case 'FH':
            return 'Freehold';
        case 'LH':
            return 'Leasehold';
        case 'SF':
            return 'Share of Freehold';
        }
    };
}]);

ngModule.filter('unitOrBedroomCountSummary', [function () {
    return function (details) {
        if (angular.isDefined(details)) {
            if (details.residentialUnitCount || details.commercialUnitCount) {
                var units = (details.residentialUnitCount + details.commercialUnitCount);
                return units + ' Unit';
            } else {
                return details.bedroomCount + ' Bed';
            }
        } else {
            return '';
        }
    };
}]);

ngModule.filter('slugify', [function () {
    return function (details) {
        return details.replace(/\s/g, '_').split('').map(function (letter) {
            if (!/[a-z0-9_]/i.test(letter)) {
                letter = '';
            }
            return letter;
        }).join('').replace(/__/g, '_');
    };
}]);
})(window, window.angular);