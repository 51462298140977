(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.terms-dialog', ['pp.services.core', 'pp.services.dialog', 'pp.widgets.terms-dialog']);

var PATHS = {
    'transfer-agreement': '/statics/agreement-to-transfer-a-beneficial-interest',
    'investment-articles': '/statics/investment-articles',
    'general-terms': '/statics/terms'
};

/**
 * @ndgoc service
 * @description
 * gets dialog html based from path based on PATHS map
 */

ngModule.service('termsDialogService', ['$rootScope', '$http', 'ppTrack', 'ppConfig', 'dialogService', 'R', function ($rootScope, $http, ppTrack, ppConfig, dialogService, R) {

    var promiseCache = {};

    // -- util functions

    function loadHtml(path) {

        if (!promiseCache[path]) {
            promiseCache[path] = $http.get(path).then(function (response) {
                return response.data;
            });
        }

        return promiseCache[path];
    }

    function normaliseVars(vars) {
        return R.mergeRight(vars, {
            spvName: vars.name,
            spvOffice: vars.officeAddress,
            spvNumber: vars.number
        });
    }

    // -- api

    var api = {};

    // create a scope, show the dialog
    api.show = function (name, vars) {

        var normalisedVars = normaliseVars(vars || {});

        var scope = $rootScope.$new();
        var options = {
            clickClose: false,
            escapeClose: false,
            showClose: false,
            classNames: 'terms-dialog-modal ' + name,
            scope: scope
        };

        scope.vars = normalisedVars;

        options.contents = '<div pp-terms-dialog="contents" vars="vars"></div>';

        dialogService.show(options);

        var paths = angular.extend({}, PATHS, ppConfig.get('pp.ui.services.terms-dialog'));
        var path = paths[name];

        loadHtml(path).then(function (trustedHtml) {
            scope.contents = trustedHtml;
        });
    };

    return api;
}]);
})(window, window.angular);