(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.waypoint', []);

/**
 * @ngdoc service
 * @name windowService
 *
 * @description
 * Provides methods to invoke the `/window` endpoints.
 */
ngModule.service('waypointService', ['$window', '$timeout', function ($window, $timeout) {

    var api = {};

    api.stickyBottomAction = function (component, contextSelector, actionSelector, handler, offset) {
        return $timeout(function () {
            var el = component.find(contextSelector)[0];
            var elHeight = $(el).height();
            var actionHeight = $(component.find(actionSelector)[0]).height();
            return new $window.Waypoint({
                element: el,
                handler: handler,
                offset: function () {
                    //bottom in view
                    return this.context.innerHeight() - this.adapter.outerHeight() - actionHeight - (offset || 24);
                }
            });
        });
    };

    return api;
}]);
})(window, window.angular);