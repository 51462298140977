(function(window, angular){'use strict';

var ngModule = angular.module('pp.filters.numbers', ['pp.services.number']);

// @todo SOMEDAY refactor to pure function service
ngModule.factory('ordinalService', ['$window', 'numberService', function ($window, numberService) {
    var ordinal = function (input) {
        var n = Number(input) % 100;

        if (numberService.comprehensiveIsNotANumber(input, $window) || input === 0) {
            return '';
        }

        return (n < 11 || n > 13) ? ['st', 'nd', 'rd', 'th'][Math.min((n - 1) % 10, 3)] : 'th';
    };
    return {
        ordinal: ordinal
    };
}]);

ngModule.filter('ordinal', ['ordinalService', 'numberService', '$window', function (ordinalService, numberService, $window) {
    return function (input) {
        if (numberService.comprehensiveIsNotANumber(input, $window) || input === 0) {
            return '';
        }

        return Math.floor(input) + ordinalService.ordinal(input);
    };
}]);

ngModule.filter('ppPence', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, symbol, dp) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        var decimalPlaces = angular.isDefined(dp) ? dp : 2;

        symbol = symbol || 'p';
        input = input || 0;
        input = input * 100;

        return $filter('number')(input, decimalPlaces) + symbol;
    };
}]);

ngModule.filter('ppPenceWhole', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, symbol) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        symbol = symbol || 'p';
        input = input || 0;
        input = input * 100;
        return $filter('number')(input, 0) + symbol;
    };
}]);

ngModule.filter('ppPenceWholeRoundDown', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, symbol) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        symbol = symbol || 'p';
        input = input || 0;
        input = input * 100;
        input = Math.floor(input);
        return input + symbol;
    };
}]);

ngModule.filter('ppPenceWholeRoundUp', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, symbol) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        symbol = symbol || 'p';
        input = input || 0;
        input = input * 100;
        input = Math.ceil(input);
        return input + symbol;
    };
}]);

ngModule.filter('ppPenceWholeOr2dp', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, symbol) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        var dp = numberService.round2(Number(input * 100)) % 1 <= 0.005 ? 0 : 2;
        symbol = symbol || 'p';
        input = input || 0;
        input = input * 100;
        return $filter('number')(input, dp) + symbol;
    };
}]);

/**
 * @ngdoc filter
 * @name ppSignedPercentage
 * @description
 * Wraps angular `number` filter (which formats decimal places) and adds `+` sign on positive numbers.
 *
 * @todo post-conversion-phase-2 rename to ppSignedPercentage and bring factor in the '%' now hardcoded in templates
 */
ngModule.filter('ppSignedPercentage', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, decimalPlaces) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        return ((input > 0) ? '+' : '') + $filter('number')(input, decimalPlaces) + '%';
    };
}]);

ngModule.filter('ppPercentage', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, decimalPlaces) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        return $filter('number')(input, decimalPlaces) + '%';
    };
}]);

ngModule.filter('ppCurrency', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, symbol, decimalPlaces) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        if (numberService.comprehensiveIsNotANumber(decimalPlaces, $window)) {
            decimalPlaces = 2;
        }

        symbol = symbol || '£';
        input = input || 0;

        return (input < 0 ? '-' : '') + symbol + $filter('number')(Math.abs(input), decimalPlaces);
    };
}]);

ngModule.filter('ppAbsPercent', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, decimalPlaces) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        return $filter('number')(Math.abs(input), decimalPlaces) + '%';
    };
}]);

ngModule.filter('ppPctToPercent', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, decimalPlaces) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        return $filter('number')(numberService.round2(input * 100), decimalPlaces) + '%';
    };
}]);

ngModule.filter('absCurrency', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, decimalPlaces) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        return $filter('currency')(Math.abs(input), '£', angular.isDefined(decimalPlaces) ? decimalPlaces : 2);
    };
}]);

ngModule.filter('abs', ['$filter', function ($filter) {
    return function (val) {
        return Math.abs(val);
    };
}]);

ngModule.filter('numberSigned', ['$filter', '$window', 'numberService', function ($filter, $window, numberService) {
    return function (input, dp) {
        if (numberService.comprehensiveIsNotANumber(input, $window)) {
            return '';
        }

        var sign = input > 0 ? '+' : '';

        return sign + $filter('number')(input, dp);
    };
}]);
})(window, window.angular);