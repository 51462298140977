(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.duo-group-dropdown', [
    'pp.widgets-templates',
    'pp.components.disallowed-value-validation'
]);
var DEFAULT_CODE_KEY = 'code';
var DEFAULT_SELECT_MESSAGE = 'Select option';

ngModule.component('ppDuoGroupDropdown', {
    templateUrl: 'widgets/_angular/duo-group-dropdown/duo-group-dropdown.tpl.html',
    bindings: {
        ppValue: '=',
        disallowedValues: '<',
        ppId: '@',
        ppName: '@',
        codeKey: '@',
        selectMessage: '@',
        uniqueNamespace: '@',
        onChange: '&?',
        isDisabled: '<',
        isRequired: '<',
        bottomValues: '<',
        topValues: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            vm.selectMessage = vm.selectMessage || DEFAULT_SELECT_MESSAGE;
            vm.codeKey = vm.codeKey || DEFAULT_CODE_KEY;
        };

    }]
});
})(window, window.angular);