(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.list-menu', [
    'pp.components-templates',
    'pp.components.list-menu-item',
    'pp.components.list-menu-expandable-items'
]);

ngModule.component('ppListMenu', {
    templateUrl: 'components/_angular/list-menu/list-menu.tpl.html',
    bindings: {
        items: '<',
        user: '<',
        context: '@'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);