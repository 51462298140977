(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.list-menu-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.components.list-menu'
]);

/**
 * @ndgoc service
 * @description
 * checks if we need to notify the user that they have been qualified/disqualified for an rff scheme
 * shows the dialog and sets a preference when the user dismisses it
 * so that the dialog is never shown again for the same scheme
 */
ngModule.service('listMenuDialog', ['$rootScope', 'ppTrack', 'dialogService', function ($rootScope, ppTrack, dialogService) {

    // -- util functions

    var __currentInstance;

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(menuItems) {
        var scope = $rootScope.$new();
        var options = {
            clickClose: false,
            escapeClose: true,
            showClose: true,
            scope: scope,
            classNames: 'list-menu-modal',
        };
        scope.menuItems = menuItems;
        options.contents = '<pp-list-menu items="menuItems"></pp-list-menu>';

        return dialogService.show(options);
    }

    // -- api

    var api = {};

    api.show = function (menuItems) {
        __currentInstance = showDialog(menuItems);
        return __currentInstance;
    };

    api.close = function () {
        dialogService.close();
    };

    return api;
}]);
})(window, window.angular);