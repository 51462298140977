(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-plan-card-carousel', [
    'pp.widgets-templates',
    'pp.widgets.investment-plan-card',
    'pp.values.investment-plans',
    'pp.services.route'
]);

ngModule.component('ppInvestmentPlanCardCarousel', {
    templateUrl: 'widgets/_angular/investment-plan-card-carousel/investment-plan-card-carousel.tpl.html',
    bindings: {
        user: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', '$timeout', 'investmentPlans', 'routeService', function ($scope, $element, $timeout, investmentPlans, routeService) {
        var vm = this;

        // -- initial state

        //vm.showIncomePlan = Math.random() >= 0.5;
        vm.showIncomePlan = false;

        var options = {
            items: 1,
            margin: 12,
            startPosition: 1
        };

        vm.income = investmentPlans.income;
        vm.balanced = investmentPlans.balanced;
        vm.growth = investmentPlans.growth;

        vm.investmentPlanPath = routeService.investmentPlansPath;

        // -- util functions

        // -- api

        vm.goBack = function () {
            if (vm.carousel) {
                vm.carousel.trigger('prev.owl.carousel');
            }
        };

        vm.goForward = function () {
            if (vm.carousel) {
                vm.carousel.trigger('next.owl.carousel');
            }
        };

        // -- scope bindings

        vm.$onDestroy = function () {
            if (vm.carousel) {
                vm.carousel.trigger('destroy.owl.carousel');
            }
        };

        vm.$onInit = function () {
            // $timeout(function () {
            //     vm.carousel = $($element).find('.owl-carousel').owlCarousel(options);
            // });
        };

        // -- main

    }]
});
})(window, window.angular);