(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.button-sell', ['pp.widgets-templates', 'pp.services.investor', 'pp.components.tooltip', 'pp.values.glossary']);

var SELL_PAGE_URL = '/properties/:symbol/sell';

var NO_SHARES_MESSAGE = 'sellSharesNoShares';
var ERROR_MESSAGE = 'sellSharesError';
var ALL_SHARES_LISTED_MESSAGE = 'allSharesListed';

ngModule.directive('ppButtonSell', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/button-sell/button-sell.tpl.html',
        scope: {
            symbol: '<',
            user: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$window', 'ppTrack', 'investorService', 'glossary', function ($scope, $window, ppTrack, investorService, glossary) {
            var vm = this;

            // -- initial state

            vm.loading = true;
            vm.error = false;

            // -- util functions

            function loadHoldings() {
                investorService.getInvestorHoldingsByProperty(vm.symbol).then(function (data) {
                    vm.sharesListable = (data.units - data.offered > 0);
                    vm.loading = false;
                    if (!vm.sharesListable) {
                        vm.title = data.units ? glossary[ALL_SHARES_LISTED_MESSAGE].desc : glossary[NO_SHARES_MESSAGE].desc;
                    }
                }, function (error) {
                    vm.loading = false;
                    vm.sharesListable = false;
                    if (error.reason === 'investor.error.no-shares') {
                        vm.title = glossary[NO_SHARES_MESSAGE].desc;
                    } else {
                        vm.title = glossary[ERROR_MESSAGE].desc;
                    }
                });
            }

            function init(user) {
                if (user && !user.anon) {
                    loadHoldings();
                } else {
                    vm.title = glossary[NO_SHARES_MESSAGE].desc;
                    vm.loading = false;
                    vm.sharesListable = false;
                }
            }

            // -- api

            // -- scope bindings

            vm.ppTrackCloseAll = function () {
                ppTrack.closeAll();
            };

            vm.$onChanges = function (changes) {
                if (changes.user && changes.user.currentValue) {
                    init(changes.user.currentValue);
                }
            };

            // -- main

        }]
    };
}]);
})(window, window.angular);