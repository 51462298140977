(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.promo-banner', [
    'pp.widgets-templates',
    'pp.widgets.info-card',
    'pp.services.core'
]);

ngModule.component('ppPromoBanner', {
    templateUrl: 'widgets/_angular/promo-banner/promo-banner.tpl.html',
    bindings: {
        ctaClick: '&?onCtaClick',
        promoCard: '<'
    },
    controllerAs: 'vm',
    controller: ['$element', 'ppComponentEmitter', function ($element, ppComponentEmitter) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        vm.$postLink = function () {
            ppComponentEmitter.emit('promo-banner.dom.loaded', $element);
        };

    }]
});
})(window, window.angular);