(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.resources-dropdown', [
    'pp.widgets-templates',
    'pp.services.route'
]);

var MENU_TOGGLE_DELAY = 300;

ngModule.directive('ppResourcesDropdown', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/resources-dropdown/resources-dropdown.tpl.html',
        transclude: true,
        scope: {},
        bindToController: true,
        controllerAs: 'vm',
        controller: ['$scope', '$timeout', '$window', 'routeService', function ($scope, $timeout, $window, routeService) {
            var vm = this;

            // -- initial state

            vm.contactUsPath = routeService.contactUsPath;
            vm.aboutUsPath = routeService.aboutusPath;
            vm.howItWorksPath = routeService.howItWorksPath;
            vm.propertyTeamPath = routeService.propertyTeamPath;
            vm.blogPath = routeService.blogPath;
            vm.knowledgeBaseUrl = routeService.knowledgeBaseLink;

            // -- util functions

            function closeMenu() {
                $scope.$evalAsync(function () {
                    vm.isMenuOpen = false;
                });
            }

            function handleScroll(originalPosition) {
                if (Math.abs($window.scrollY - originalPosition) > 50) {
                    closeMenu();
                }
            }

            function onScrollDifferenceCleanDropdowns(originalPosition) {
                $($window).on('scroll', handleScroll.bind(null, originalPosition));
            }

            // -- api

            vm.toggleMenu = function (ev) {

                if (vm.delay) {
                    // note early return
                    return;
                }

                vm.isMenuOpen = !vm.isMenuOpen;

                // always cleanup other bindings
                $($window).off('click', closeMenu);
                $($window).off('scroll', handleScroll);

                if (vm.isMenuOpen) {
                    $($window).on('click', closeMenu);
                    onScrollDifferenceCleanDropdowns($window.scrollY);
                }

                vm.delay = $timeout(function () {
                    vm.delay = undefined;
                }, MENU_TOGGLE_DELAY);

                ev.stopPropagation();
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);