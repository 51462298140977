(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.backers', ['pp.widgets-templates', 'pp.services.core']);

/**
 * @todo
 */
ngModule.directive('ppBackers', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/backers/backers.tpl.html',
        scope: {},
        controllerAs: 'vm',
        bindToController: true,
        controller: ['ppConfig', function (ppConfig) {
            var vm = this;
            var __config = ppConfig.get('pp.external.endpoints');

            // -- initial state

            vm.s3Endpoint = __config ? __config.assets : null;

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);