(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property', [
    'ui.router',
    'pp.widgets-templates',
    'pp.services.auth',
    'pp.services.property',
    'pp.services.investor',
    'pp.services.route',
    'pp.services.config',
    'pp.services.preference',
    'pp.ui.services.restricted-investor-dialog',
    'pp.ui.services.authenticate-investor',
    'pp.ui.services.classification-dialog',
    'pp.ui.services.risk-warning',
    'pp.widgets.property-status',
    'pp.widgets.property-carousel',
    'pp.widgets.property-image-single',
    'pp.widgets.document-links',
    'pp.widgets.expandable',
    'pp.widgets.floorplan',
    'pp.widgets.property-diagram',
    'pp.widgets.property-financials',
    'pp.widgets.property-notice-banner',
    'pp.widgets.map',
    'pp.widgets.property-details',
    'pp.widgets.property-events',
    'pp.widgets.property-social-sharing',
    'pp.widgets.funding-round-details',
    'pp.widgets.wistia-inline-video',
    'pp.ui.services.home-now-reit-disclaimer-dialog',
    'pp.ui.services.suitability-quiz-dialog'
]);

var PAGE_NAME = 'property';
var CAROUSEL_READY_TIMEOUT_MS = 1200;
var PROPERTY_STATE_PRE_ORDER = 'pre-order';
var PROPERTY_STATE_PRE_ORDER_CLOSED = 'pre-order-closed';

ngModule.component('ppProperty', {
    templateUrl: 'widgets/_angular/property/property.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$q', '$timeout', '$state', '$anchorScroll', '$location', 'ppConfig', 'ppTrack', 'propertyService', 'investorService', 'routeService', 'authenticateInvestorAnonReject', 'restrictedInvestorDialog', 'classificationDialogService', 'riskWarningService', 'R', 'configService', 'preferenceService', 'homeNowReitDisclaimerDialog', 'suitabilityQuizDialog', function ($scope, $q, $timeout, $state, $anchorScroll, $location, ppConfig, ppTrack, propertyService, investorService, routeService, authenticateInvestorAnonReject, restrictedInvestorDialog, classificationDialogService, riskWarningService, R, configService, preferenceService, homeNowReitDisclaimerDialog, suitabilityQuizDialog) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.widgets.property') || {};
        var symbol = __config.property ? __config.property.symbol : null;

        var googleMaps = window.pp.googleMaps;
        var googlemapsApiKey = (window.pp.config.get('googlemaps.api') || {}).key;

        var financialTabs = {
            unitsDisclosure: 'individual-unit-details',
            forecastIncome: 'rental-income-breakdown',
            shareValuation: 'share-valuation'
        };

        vm.exitMechanicHelp = routeService.exitMechanicHelp;
        vm.imHomeNow = routeService.imHomeNow;
        vm.kidHomeNow = routeService.kidHomeNow;

        function redirectBlockedUser() {
            ppTrack.pageEvent(PAGE_NAME, 'requiredPermissions');
            window.location.href = routeService.marketplacePath;
        }

        function handleImagesSuccess(images) {
            vm.numberOfImages = images.numberOfImages;
            vm.carouselLoaded = true;
            $timeout(function () {
                vm.carouselReady = true;
            }, CAROUSEL_READY_TIMEOUT_MS).then(function () {
                $timeout(function () {
                    vm.hideCarouselLoading = true;
                }, 300);

            });
        }

        function handleImagesError() {
            vm.numberOfImages = 0;
            vm.carouselReady = true;
            vm.carouselLoaded = true;
        }

        function handleNonMainstreamLoad(user, property) {
            var localUser;
            return authenticateInvestorAnonReject()
                .then(function (user) {
                    localUser = user;
                    return classificationDialogService.classifyUser(user, angular.noop);
                })
                .then(restrictedInvestorDialog.reclassifyUserForRestrictedInvestment)
                .then(function () {
                    if (property.isFund) {
                        return investorService.doesInvestorHaveUKAddress().then(function (hasUKAddress) {
                            if (hasUKAddress) {
                                return;
                            } else {
                                return $timeout(function () {
                                    return homeNowReitDisclaimerDialog.showNonUKBlockedDialog().then(function () {
                                        return $q.reject();
                                    });
                                }, 100);
                            }
                        });
                    } else {
                        return $q.when();
                    }
                })
                .then(function () {
                    if (property.isFund) {
                        return preferenceService.hasAgreedToHomeNowDisclaimer().then(function (hasAgreed) {
                            if (hasAgreed) {
                                return;
                            } else {
                                return $timeout(function () {
                                    return investorService.escapeFromIsa(localUser).then(function () {
                                        return homeNowReitDisclaimerDialog.showDialog(user, property);
                                    });
                                }, 100);
                            }
                        }).then(function () {
                            return $timeout(function () {
                                return suitabilityQuizDialog.showForPassAnyQuizzes(['fund_1'], false, localUser.classification);
                            }, 100);
                        });
                    }
                });
        }

        function handleLoad(res) {
            if (res.property.isBlockedForRetail) {
                handleNonMainstreamLoad(res.investor, res.property).then(function () {
                    vm.ready = true;

                    $timeout(function () {
                        if ($state.current.name === 'property-key-risks') {
                            $location.hash('pp-property-key-risks-section');
                            $timeout(function () {
                                $anchorScroll.yOffset = 100;
                                $anchorScroll();
                            });
                        }

                    });
                }, redirectBlockedUser);
            } else {
                vm.ready = true;
            }

            if (res.property.isDevelopmentLoan && !res.property.notMiniBond) {
                riskWarningService.showDevLoanRiskWarning();
            }

            if (res.property.isFundingRound) {
                riskWarningService.showFundRaisingRiskWarning();
            }
        }

        function handleInvestorSuccess(user) {
            vm.user = user;
            return user;
        }

        function handleIncomeDisclosureSuccess(data) {
            vm.incomeDisclosure = data;
            return data;
        }

        function handleUnitsDisclosureSuccess(data) {
            vm.unitsDisclosure = R.head(data);
            return data;
        }

        function handlePropertySuccess(data) {
            vm.property = data;

            if (vm.property.state.status === PROPERTY_STATE_PRE_ORDER || vm.property.state.status === PROPERTY_STATE_PRE_ORDER_CLOSED) {
                ppTrack.setContext('offer.preorder', true);
            }

            ppTrack.pageLoad(PAGE_NAME);

            // -- map
            vm.type = PAGE_NAME;
            vm.loc = [{
                latitude: vm.property.location.geo.latitude,
                longitude: vm.property.location.geo.longitude
            }];

            var disclosurePropertySymbol = vm.property.parentPropertySymbol || vm.property.symbol;

            var disclosurePromises = $q.all({
                disclosure: propertyService.getPropertyIncomeDisclosure(disclosurePropertySymbol).then(handleIncomeDisclosureSuccess, function () {
                    vm.incomeDisclosure = [];
                }),
                unitDisclosure: propertyService.getPropertyUnitsDisclosure(disclosurePropertySymbol).then(handleUnitsDisclosureSuccess, function () {
                    vm.unitsDisclosure = [];
                })
            });

            return disclosurePromises.then(function () {
                return data;
            }, function () {
                return data;
            });
        }

        function handleUnitValuationDateSuccess(data) {
            vm.unitValuationDate = data;
            return data;
        }

        // -- api

        vm.tabChange = function (tab) {
            var newPath = financialTabs[tab];
            if (newPath) {
                $state.go('property.' + tab, null, {
                    location: 'replace'
                });
            }
        };

        // -- scope bindings

        // -- main

        propertyService.getImages(symbol).then(handleImagesSuccess, handleImagesError);

        var promises = {
            investor: investorService.getInvestor().then(handleInvestorSuccess),
            property: propertyService.getPropertyData(symbol).then(handlePropertySuccess),
            unitValuationDate: configService.getUnitValuationDate().then(handleUnitValuationDateSuccess, function () {})
        };

        $q.all(promises).then(handleLoad);

    }]
});
})(window, window.angular);