(function(window, angular){'use strict';
var ngModule = angular.module('pp.services.mfa', []);

var API_CONTENT_TYPE = 'application/vnd.propertypartner.feapi.auth.v1+json';
var BASE_ENDPOINT = '/feapi/r1';
var MFA_ENDPOINT = BASE_ENDPOINT + '/auth/secondary/mfa';
var PIN_ENDPOINT = BASE_ENDPOINT + '/auth/secondary/pin';

/**
 * @ngdoc service
 * @name mfaService
 *
 * @description
 * Provides methods to get all the offer details.
 */
ngModule.service('mfaService', ['$http', '$q', function ($http, $q) {

    var api = {};

    api.requestNewMfaSecret = function () {
        var options = {};
        return $http.get(MFA_ENDPOINT, options).then(function (response) {
            return response.data.secret;
        }, function (error) {
            return $q.reject({
                reason: 'mfa.error.unexpected'
            });
        });
    };

    api.activateMfa = function (totp) {
        var options = {};
        options.headers = {
            'Content-type': API_CONTENT_TYPE
        };

        var payload = {
            totp: Number(totp)
        };

        return $http.put(MFA_ENDPOINT, payload, options).then(function (response) {
            return true;
        }, function (error) {
            switch (error.status) {
            case 404: //No MFA secrets found
                return $q.reject({
                    reason: 'mfa.secret.not.found'
                });
            case 422: // when totp is incorrect
                return $q.reject({
                    reason: 'mfa.token.invalid'
                });
            default:
                return $q.reject({
                    reason: 'mfa.error.unexpected'
                });
            }
        });
    };

    api.disableMfaAndActivatePin = function (totp, newPin) {
        var options = {};

        options.headers = {
            'Content-type': API_CONTENT_TYPE
        };

        var payload = {
            totp: Number(totp),
            newPin: newPin
        };

        return $http.put(PIN_ENDPOINT, payload, options).then(function (response) {
            return response.data.secret;
        }, function (error) {
            var errorCode = error.data ? error.data.code : null;
            return $q.reject({
                reason: errorCode
            });
        });
    };

    return api;

}]);
})(window, window.angular);