(function(window, angular){'use strict';

var ngModule = angular.module('pp.filters.text', []);

ngModule.filter('titleCase', [function () {
    return function (input) {

        var shortWords = /^(a|an|and|as|at|but|by|for|if|in|into|near|nor|of|on|or|per|the|to|vs|with|via)$/i;

        input = input || '';

        var sanitisedSrting = input.replace(/_/g, ' ');

        return sanitisedSrting.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, function (word, offset) {

            if (offset > 0 && word.search(shortWords) > -1) {
                return word.toLowerCase();
            }

            if (word.substr(1).search(/[A-Z]/) > -1) {
                return word;
            }

            return word.charAt(0).toUpperCase() + word.substr(1);
        });
    };
}]);

ngModule.filter('sortCode', [function () {
    return function (input) {

        if (!angular.isString(input)) {
            return '';
        }

        return [
            input.slice(0, 2),
            input.slice(2, 4),
            input.slice(4, 6)
        ].join('-');
    };
}]);

ngModule.filter('iban', [function () {
    return function (input) {

        if (!angular.isString(input)) {
            return '';
        }

        return input
            .replace(/\s*\-*/g, '')
            .split('')
            .reduce(function (acc, letter, ix) {
                if ((ix + 1) % 4 === 0) {
                    return acc + letter + ' ';
                } else {
                    return acc + letter;
                }
            }, '')
            .trim();
    };
}]);
})(window, window.angular);