(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.investment-plans-closed-period-warning-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.services.config',
    'pp.widgets.investment-plans-closed-period-warning'
]);

/**
 * @ndgoc service
 * @description
 * checks if we need to notify the user that they have been qualified/disqualified for an rff scheme
 * shows the dialog and sets a preference when the user dismisses it
 * so that the dialog is never shown again for the same scheme
 */
ngModule.service('ppInvestmentPlansClosedPeriodWarningDialog', ['$rootScope', '$q', '$timeout', 'ppTrack', 'dialogService', 'configService', function ($rootScope, $q, $timeout, ppTrack, dialogService, configService) {

    // -- util functions

    function onAccept(defer) {
        return function () {
            defer.resolve();
        };
    }

    function onReject(defer) {
        return function () {
            defer.reject();
        };
    }

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(defer) {

        ppTrack.action('investment-plans-closed-period-warning-dialog.show');

        var scope = $rootScope.$new();
        scope.onAccept = onAccept(defer);
        scope.onReject = onReject(defer);

        var options = {
            clickClose: false,
            escapeClose: false,
            showClose: false,
            classNames: 'pp-investment-plans-closed-period-warning-dialog',
            scope: scope
        };

        options.contents = '<pp-investment-plans-closed-period-warning on-accept="onAccept()" on-reject="onReject()"></pp-investment-plans-closed-period-warning>';

        dialogService.show(options);

        return defer.promise;
    }

    // -- api

    var api = {};

    api.show = function () {
        return configService.isInvestmentPlanDeploymentClosed().then(function (isClosed) {
            if (isClosed) {
                // timeout to allow other dialogs to close before opening
                return $timeout(function () {
                    return showDialog($q.defer());
                });
            } else {
                return $q.when();
            }
        });

    };

    return api;
}]);
})(window, window.angular);