(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.take-up-rights', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.route'
]);

ngModule.component('ppTakeUpRights', {
    templateUrl: 'widgets/_angular/take-up-rights/take-up-rights.tpl.html',
    bindings: {
        holdings: '<',
        properties: '<',
        orders: '<',
        onFilterYourProperties: '&',
        onTakeUpYourRights: '&',
        onReset: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'R', 'routeService', function ($scope, R, routeService) {
        var vm = this;

        // -- initial state

        // -- util functions

        vm.portfolioPendingPropertiesPath = routeService.portfolioPendingPropertiesPath;

        // -- api

        vm.reset = function () {
            vm.onReset();
            vm.isViewingYourProperties = false;
        };

        vm.filterYourProperties = function () {
            vm.onFilterYourProperties();
            vm.isViewingYourProperties = true;
        };

        vm.takeUpYourRights = function () {
            vm.onTakeUpYourRights();
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            vm.totalOrders = R.sum(vm.orders.map(function (item) {
                return item.total;
            }));
        };

    }]
});
})(window, window.angular);