(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.placeholder-promotion', ['pp.widgets-templates', 'pp.services.core']);

/**
 * @ngdoc directive
 * @name ppPlaceholder-promotion
 * @description
 * @restrict A
 * @scope
 */

ngModule.directive('ppPlaceholderPromotion', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/placeholder-promotion/placeholder-promotion.tpl.html',
        scope: {
            promotionName: '@'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$element', 'ppComponentEmitter', function ($element, ppComponentEmitter) {

            var vm = this;

            // -- lifecycle

            vm.$postLink = function () {
                ppComponentEmitter.emit('placeholder-promotion.dom.loaded.' + vm.promotionName, $element);
            };

        }]
    };
}]);
})(window, window.angular);