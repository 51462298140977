(function(window, angular){'use strict';

var ACCOUNT_TYPE_STATE = 'portfolio.account-history.type';
var ACCOUNT_TYPE_MANUAL = 'manual';

var DASHBOARD_STATE = 'portfolio.dashboard';
var PAGE_NAME = 'portfolio';

var ngModule = angular.module('pp.controllers.portfolio', [
    'pp.controllers-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.route',
    'pp.ui.services.dashboard-sidenav-dialog',
    'pp.controllers.portfolio-router',
    'pp.components.list-menu',
    'pp.widgets.list-menu-switcher',
    'pp.widgets.dashboard-sidenav',
    'pp.widgets.footer',
    'pp.components.secondary-nav-center'
]);

ngModule.controller('portfolioCtrl', ['$q', '$window', '$element', '$transitions', '$state', 'ppTrack', 'investorService', 'routeService', 'ppUtil', 'dashboardSidenavDialog', 'R', function ($q, $window, $element, $transitions, $state, ppTrack, investorService, routeService, ppUtil, dashboardSidenavDialog, R) {
    var vm = this;

    // -- initial state

    var __waypoint;

    vm.investorKindMap = {
        isa: {
            title: 'ISA Portfolio',
            switchLabel: 'Switch to General',
            switchHref: routeService.dashboardPathGeneral,
            switchKind: 'general'
        },
        general: {
            title: 'General Portfolio',
            switchLabel: 'Switch to ISA',
            switchHref: routeService.dashboardPathIsa,
            switchKind: 'isa'

        }
    };

    var transferFundsMenuItem = {
        label: 'Transfer funds',
        href: routeService.transferPath
    };

    var propertyMenuSection = {
        label: 'Properties',
        items: [{
                label: 'Current',
                sref: 'portfolio.properties-current'
            },
            {
                label: 'Pending',
                sref: 'portfolio.pending'
            },
            {
                label: 'Bid orders',
                sref: 'portfolio.bids'
            },
            {
                label: 'Sell offers',
                sref: 'portfolio.offers'
            },
            {
                label: 'Sold',
                sref: 'portfolio.sold'
            }
        ]
    };

    var loanMenuSection = {
        label: 'Loans',
        items: [{
                label: 'Current',
                sref: 'portfolio.current-loans'
            },
            {
                label: 'Repaid',
                sref: 'portfolio.exited-loans'
            },
            {
                label: 'Pending',
                sref: 'portfolio.pending-loans'
            }
        ]

    };

    var menuItemsBase = [{
            label: 'Dashboard',
            sref: DASHBOARD_STATE
        },
        {
            label: 'Account history',
            sref: 'portfolio.account-history.type({page: 0, type: \'' + ACCOUNT_TYPE_MANUAL + '\'})'
        },
        {
            label: 'Investment docs',
            sref: 'portfolio.documents'
        },
        {
            label: 'Tax statement',
            sref: 'portfolio.tax'
        }
    ];

    // -- util functions

    function getActiveMenuTitle(prefix, menuItems, state) {
        return menuItems.reduce(function (title, item) {

            if (title) {
                return title;
            }

            if (item.sref && item.sref.indexOf(state) > -1) {
                return prefix + item.label;
            }

            if (angular.isArray(item.items)) {
                return getActiveMenuTitle(prefix + item.label + ' - ', item.items, state);
            }

        }, undefined);
    }

    function initMenu(investor) {

        var menuItems = menuItemsBase;

        // copy sections to force onChanges lifecycle hook in menu
        var loanSection = angular.copy(loanMenuSection);
        var propertySection = angular.copy(propertyMenuSection);

        if (!investor.isIsa) {
            loanSection.isOpen = true;
            propertySection.isOpen = true;
            menuItems = ppUtil.insertInList(menuItems, loanSection, 1);
            menuItems = ppUtil.insertInList(menuItems, propertySection, 1);
            vm.menuItems = menuItems;
        } else {
            loanSection.isOpen = true;
            menuItems = ppUtil.insertInList(menuItems, loanSection, 1);
            vm.menuItems = menuItems;
        }
    }

    function isStateOnMenu(menuItems, state) {
        return menuItems.reduce(function (isOnMenu, item) {
            if (isOnMenu) {
                return true;
            }

            if (item.sref && item.sref === state) {
                return true;
            }

            if (angular.isArray(item.items)) {
                return isStateOnMenu(item.items, state);
            }
        }, false);
    }

    function scrollTop() {
        $window.scroll(0, 0);
        $element.find('#portfolio-main-contents').scrollTop(0);
    }

    // -- api

    vm.openMenuModal = function () {
        dashboardSidenavDialog.show(vm.menuItems, vm.investor, vm.isIsaSetup, vm.investorKindMap, vm.switchAccounts);
    };

    vm.switchAccounts = function (investor) {
        investorService.switchToInvestorByKind(vm.investorKindMap[investor.kind].switchKind)
            .then(function (investor) {
                var state = $state.current.name;
                if (!isStateOnMenu(vm.menuItems, state)) {
                    $state.go(DASHBOARD_STATE);
                } else {
                    $state.reload();
                }
            });

    };

    // -- scope bindings

    $transitions.onStart({
        to: 'portfolio.**'
    }, function (trans) {
        var state = trans.$to().name;
        vm.state = state;
        if (angular.isArray(vm.menuItems)) {
            vm.activeTitle = getActiveMenuTitle('', vm.menuItems, state);
        }

        dashboardSidenavDialog.close();

        if (state === ACCOUNT_TYPE_STATE) {
            state += '.' + trans.params().type;
        }

        ppTrack.pageLoad(state);
    });

    $transitions.onFinish({
        to: 'portfolio.**'
    }, function (trans) {
        var from = trans.from().name;
        var to = trans.to().name;

        // avoid scrolling to top when switching from
        // property sidebar open to dashboard table and vice versa
        if (R.indexOf(from, to) > -1 || R.indexOf(to, from) > -1) {
            return; // note early return
        }
        scrollTop();
    });

    // -- lifecycle

    vm.$postLink = function () {
        __waypoint = new Waypoint({
            element: document.getElementById('pp-portfolio-mobile-menu-switcher-waypoint'),
            handler: function (direction) {
                var el = $('#pp-portfolio-mobile-menu-switcher');
                if (direction === 'down') {
                    el.addClass('stuck');
                } else {
                    el.removeClass('stuck');
                }
            }
        });

        $($window).on('resize', function () {
            if (__waypoint && angular.isFunction(__waypoint.refreshAll)) {
                __waypoint.refreshAll();
            }
        });
    };

    vm.$onDestroy = function () {
        if (__waypoint && __waypoint.destroy) {
            __waypoint.destroy();
            $($window).off('resize');
        }
    };

    // -- main

    investorService.on('investor-updated', function (investor) {
        vm.investor = investor;
        initMenu(investor);
    });

    $q.all({
        investors: investorService.getUserInvestors(),
        investor: investorService.getInvestor()
    }).then(function (res) {
        vm.isIsaSetup = investorService.isIsaSetup(res.investors);
        vm.investor = res.investor;
        vm.activeTitle = getActiveMenuTitle('', vm.menuItems, vm.state);
        ppTrack.pageLoad(PAGE_NAME);
        vm.ready = true;
    });

}]);
})(window, window.angular);