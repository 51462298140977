(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.about', [
    'pp.widgets-templates',
    'pp.widgets.our-story',
    'pp.widgets.about-overview',
    'pp.widgets.contact-us-view',
    'pp.widgets.team',
    'pp.services.core',
    'pp.services.investor',
    'pp.components.secondary-nav-center'
]);

ngModule.component('ppAbout', {
    templateUrl: 'widgets/_angular/about/about.tpl.html',
    bindings: {},
    transclude: true,
    controllerAs: 'vm',
    controller: ['$element', '$stateParams', 'investorService', 'ppComponentEmitter', function ($element, $stateParams, investorService, ppComponentEmitter) {

        // -- initial state
        var vm = this;

        // -- scope bindings

        // -- main

        investorService.getInvestor().then(function (user) {
            vm.user = user;
            vm.ready = true;
        });

        vm.$onInit = function () {
            ppComponentEmitter.emit('about.controller.loaded', vm);

            vm.title = $stateParams.title || 'About LHX';
        };
    }]
});
})(window, window.angular);