(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.copy-to-clipboard', [
    'pp.widgets-templates'
]);

var COPIED_TIMEOUT = 3000;
var COPIED_TOOLTIP = 'Copied';

ngModule.component('ppCopyToClipboard', {
    templateUrl: 'widgets/_angular/copy-to-clipboard/copy-to-clipboard.tpl.html',
    bindings: {
        text: '<',
        label: '@',
        btnClass: '@',
        onCopy: '&?'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', '$timeout', function ($scope, $element, $timeout) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.copyToClipboard = function () {

            if (vm.onCopy) {
                vm.onCopy();
            }

            var el = $element.find('.textarea-to-copy')[0];
            el.select();
            document.execCommand('copy');
            vm.copied = true;

            $timeout(function () {
                vm.copied = false;
            }, COPIED_TIMEOUT);
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);