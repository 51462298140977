(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketing-preferences-form-quick', [
    'pp.widgets-templates',
    'pp.components.radio-button',
    'pp.services.core'
]);

ngModule.component('ppMarketingPreferencesFormQuick', {
    templateUrl: 'widgets/_angular/marketing-preferences-form-quick/marketing-preferences-form-quick.tpl.html',
    bindings: {
        onSubmit: '&',
        skip: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', 'ppConfig', function ($scope, ppTrack, ppConfig) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints') || {};

        var s3Endpoint = __config.assets;

        vm.phoneImage = s3Endpoint + '/images/marketing/investment-insider-iphone-lhe.jpg';

        // -- util functions

        // -- api

        vm.submit = function (canContact) {

            if (vm.processing) {
                return; // @note early return
            }

            vm.processing = true;
            vm.apiError = false;

            if (canContact) {
                vm.canContact = true;
            } else {
                vm.cannotContact = true;
            }

            ppTrack.action('marketing-preferences-form-quick.submit', {
                canContact: canContact
            });

            vm.onSubmit({
                    canContact: canContact
                })
                .then(function () {
                    ppTrack.event('marketing-preferences-form-quick.submit.success', {
                        canContact: canContact
                    });

                }, function (error) {
                    vm.processing = false;
                    vm.apiError = true;
                    vm.canContact = false;
                    vm.cannotContact = false;
                    ppTrack.event('marketing-preferences-form-quick.submit.error', {
                        canContact: canContact,
                        error: angular.toJson(error)
                    });
                });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);