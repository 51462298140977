(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.contact-us', ['pp.widgets-templates']);

/**
 * @todo document
 */
ngModule.directive('ppContactUs', ['$window', function ($window) {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/contact-us/contact-us.tpl.html',
        scope: true,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

        }]
    };
}]);
})(window, window.angular);