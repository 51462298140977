(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.kyc-form', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.auth',
    'pp.services.gbGroup',
    'pp.services.investor-compliance',
    'pp.services.nationality',
    'pp.services.preference',
    'pp.filters.dates',
    'pp.widgets.address-kyc-non-uk',
    'pp.widgets.duo-group-dropdown',
    'pp.widgets.address-kyc-uk',
    'pp.widgets.nationality-list-form',
    'pp.widgets.lei-validation-message',
    'pp.components.smart-input-date',
    'pp.components.match-against',
    'pp.components.focus-first-error',
    'pp.components.toggle-bricks',
    'pp.components.checkbox',
    'pp.values.form-lists',
    'pp.values.regex'
]);

var MIN_AGE = 18;
var BLOCKED_COUNTRIES = ['USA', 'UMI'];
var BLOCKED_NATIONALITIES = ['US', 'UM'];
var DATE_EXAMPLE_TEXT_MOBILE = 'e.g: DDMMYYYY';
var DATE_EXAMPLE_TEXT = 'e.g: DD/MM/YYYY';

/**
 * @ngdoc directive
 * @name ppKycForm
 * @description
 * KYC form, handles submission and validation
 * Requires the helper service to process outcomes from backend and manipulate oddities in kyc object
 *
 * * **EVENTS**
 * - emits on `$scope` event `pp.kyc-form.onComplete` with payload `{result: <string>}` (consumed by investCtrl)
 *
 * @restrict A
 * @$scope
 * @param {boolean} enabled
 * @param {string} lastname
 * @param {string} firstname
 *
 * @todo i.e both structured address should be a list of objects, but the business kyc address should only be one address long
 */

ngModule.directive('ppKycForm', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/kyc-form/kyc-form.tpl.html',
        scope: {
            formEnabled: '=',
            firstName: '@',
            lastName: '@',
            companyName: '<',
            nationalityList: '<',
            preDefinedKycType: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', '$filter', '$timeout', '$q', 'gbGroupService', 'investorComplianceService', 'investorComplianceValues', 'preferenceService', 'ppTrack', 'formLists', 'ppComponentEmitter', 'ppBrowser', 'ppMoment', 'R', 'leiRegex', function ($scope, $element, $filter, $timeout, $q, gbGroupService, investorComplianceService, investorComplianceValues, preferenceService, ppTrack, formLists, ppComponentEmitter, ppBrowser, ppMoment, R, leiRegex) {

            var vm = this;

            // -- initial state

            var __storedAddress = {};
            var date = new Date();

            vm.formData = formLists;

            vm.structuredAddress = {};
            vm.nationalities = [undefined];
            vm.blockedCountries = BLOCKED_COUNTRIES;
            vm.blockedNationalities = BLOCKED_NATIONALITIES;

            vm.leiRegex = leiRegex;

            vm.kycTypes = [{
                id: 'individual',
                label: 'Individual Investor'
            }, {
                id: 'business',
                label: 'Corporate Investor'
            }];

            // -- util functions

            var isGBR = R.equals('GBR');

            function submitKyc() {

                vm.processing = true;

                var getAddress = R.ifElse(isGBR, R.always(vm.structuredAddress), R.always(vm.freeFormAddress));

                var data = {
                    countryCode: vm.countryCode,
                    firstName: vm.firstName,
                    lastName: vm.lastName,
                    dateOfBirth: $filter('dateServerInThePast')(vm.date),
                    nationalities: vm.nationalities
                };

                if (vm.kycType === 'business') {
                    data.companyName = vm.companyName;
                    data.companyNumber = vm.companyNumber;
                }

                data.address = R.mergeRight(getAddress(vm.countryCode), {
                    country: vm.countryCode
                });

                preferenceService.setKycNationalities(vm.nationalities);

                return gbGroupService.submitKyc(vm.kycType, data);
            }

            function deleteLei() {
                return investorComplianceService.deleteLei()
                    .then(angular.noop, angular.noop);
            }

            function submitLei(lei) {
                return investorComplianceService.checkLeiValidity(lei).then(function (res) {
                        if (res.validity === investorComplianceValues.LEI_ISSUED) {
                            return;
                        } else {

                            return $q.reject({
                                reason: vm.identifierError
                            });
                        }
                    })
                    .then(function () {
                        return deleteLei().then(function () {
                            return investorComplianceService.getLeiType();
                        }).then(function (result) {
                            return investorComplianceService.postLei(result.id, lei);
                        });
                    })
                    .catch(function (error) {
                        ppTrack.formError('lei', error);
                    });
            }

            function waitAndPassThrough(result) {
                return $timeout(function () {
                    return result;
                }, 4000);
            }

            // -- api

            // Possible outcomes are
            // Passed, PassWithPepSanctionsFlag, Rejected, Referred, ReferOnError, SecondaryDataRequired, Denied, Error
            vm.submitKyc = function () {
                var kycResult;

                if (vm.formEnabled) {
                    vm.error = null;
                    if (!vm.form.$valid) {
                        ppTrack.ngFormValidation('kyc', vm.form);
                    } else {
                        vm.processing = true;
                        submitKyc()
                            .then(function (result) {
                                ppTrack.formSubmit('kyc', result);
                                kycResult = result;
                                if (vm.kycType === 'business' && vm.lei) {
                                    return submitLei(vm.lei);
                                } else {
                                    return result;
                                }

                            }, function (error) {
                                ppTrack.formError('kyc', error.reason);
                                vm.error = error.reason;
                                vm.processing = false;
                            })
                            .then(waitAndPassThrough)
                            .then(function () {

                                ppTrack.event('kyc-form.success');

                                $scope.$emit('pp.kyc-form.onComplete');
                            });
                    }
                }
            };

            vm.removeNationality = function (index) {
                vm.nationalities = vm.nationalities.filter(function (item, ix) {
                    return ix !== index;
                });
            };

            vm.addNationality = function () {
                vm.nationalities.push(undefined);
            };

            // -- $scope bindings

            $scope.$watch('vm.countryCode', function (val) {
                var wasBlocked = vm.blockedCountry;
                vm.blockedCountry = BLOCKED_COUNTRIES.indexOf(val) !== -1;
                if (!wasBlocked && vm.blockedCountry) {
                    $('html, body').scrollTop($('#pp-kyc-form').offset().top);
                }
            });

            $scope.$watch('vm.kycType', function (val) {
                ppTrack.setContext('form.kyc.type', val);
            });

            // -- main

            vm.$postLink = function () {
                ppComponentEmitter.emit('kyc-form.dom.loaded', $element);
            };

            vm.$onInit = function () {
                vm.kycType = angular.copy(vm.preDefinedKycType) || 'individual';
                vm.livedMoreThanYear = true;
                vm.freeFormAddress = {};
                vm.countryCode = 'GBR';
                vm.dateMax = ppMoment().subtract(18, 'years');
                vm.dobDateMin = ppMoment().year(1900);
                vm.yearDiffNowMin = ppMoment().diff(vm.dobDateMin, 'years');

                vm.egText = (ppBrowser.isMobile || ppBrowser.isTablet) ? DATE_EXAMPLE_TEXT_MOBILE : DATE_EXAMPLE_TEXT;

                // track submit but do not generate an event now
                ppTrack.setContext('form.kyc.type', vm.kycType);
                ppTrack.formStart('kyc', vm.form, true, true);
                ppComponentEmitter.emit('kyc-form.controller.loaded', vm);
            };

        }]
    };
}]);
})(window, window.angular);