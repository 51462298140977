(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-map-info-window', [
    'pp.widgets-templates'
]);

ngModule.component('ppPropertyMapInfoWindow', {
    templateUrl: 'widgets/_angular/property-map/info-window.tpl.html',
    bindings: {
        image: '<',
        location: '<',
        address: '<',
        symbol: '<',
        onClick: '&'
    },
    controllerAs: 'vm',
    controller: [function () {
        var vm = this;

        vm.click = function (symbol) {
            vm.onClick({
                symbol: symbol
            });
        };

    }]
});
})(window, window.angular);