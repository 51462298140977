(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.business-summary-earn', [
    'pp.widgets-templates',
    'pp.widgets.auth-signup-cta'
]);

var EARN_TITLE = 'Earn rental income';

ngModule.component('ppBusinessSummaryEarn', {
    templateUrl: 'widgets/_angular/business-summary-earn/business-summary-earn.tpl.html',
    bindings: {
        user: '<',
        s3Endpoint: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        vm.earnTitle = EARN_TITLE;

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$onInit = function () {
            vm.earnImage = vm.s3Endpoint + '/images/homepage/income-mobile.jpg';
            vm.iphoneImage = vm.s3Endpoint + '/images/homepage/mobille-screen-rebrand.png';
        };

        // -- main

    }]
});
})(window, window.angular);