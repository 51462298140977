(function(window, angular){'use strict';

var INTERVAL_PERIOD_MS = 100;

var ngModule = angular.module('pp.components.secondary-nav-center', []);

/**
 * @ngdoc directive
 * @name secondaryNavCenter
 * @description
 * centers pills in secondary nav on load and click
 *
 * @restrict A
 * @scope
 */
ngModule.directive('ppSecondaryNavCenter', function () {
    return {
        restrict: 'A',
        scope: {}, // an isolate $scope is used for easier/cleaner
        // $watching and cleanup (on destruction)
        controller: ['$element', function ($element) {

            var pillElement = $element.find('.pill');
            var secondaryNavElement = $element;
            var navAreaElement = $element.find('.nav-area');
            var navAreaElementTable = $element.find('.nav-area-table');
            var _element = angular.element;

            function getNavAreaWith() {
                return navAreaElement.width() || navAreaElementTable.width();
            }

            function shouldMovePillToTheLeft(pill) {
                var pillWidth = _element(pill).width();
                var pillOffset = _element(pill).offset();
                var pillIsOnTheRightSide = (getNavAreaWith() / 2 < (pillOffset.left + (pillWidth / 2)));
                return pillIsOnTheRightSide;
            }

            function getNewOffsetForPill(pill) {
                var pillWidth = _element(pill).width();
                var pillOffset = _element(pill).offset();
                var pillCenter = pillWidth / 2;
                var shouldMoveToLeft = shouldMovePillToTheLeft(pill);

                //calculate new offset here depending on the pill position
                var newOffset = Math.abs(pillOffset.left - (secondaryNavElement.width() / 2));
                newOffset += shouldMoveToLeft ? pillCenter : -pillCenter;
                return shouldMoveToLeft ? newOffset : -newOffset;
            }

            //on page load: center the active pill
            //TODO: This interval waits for the pill to be loaded by angular.
            //We need to move pills to a component to avoid doing this.
            function init() {
                var iteration = 0;
                var pillInterval = setInterval(function () {
                    var activePill = $element.find('.pill.active')[0];
                    if (activePill) {
                        var newOffset = getNewOffsetForPill(activePill);
                        //no animation on page load
                        secondaryNavElement.scrollLeft(secondaryNavElement.scrollLeft() + newOffset);
                        clearInterval(pillInterval);
                    } else if (iteration > 10) {
                        clearInterval(pillInterval);
                    }
                    iteration++;
                }, INTERVAL_PERIOD_MS);
            }

            // -- scope bindings --

            pillElement.on('click', function () {
                if (_element(this).hasClass('active')) {
                    return;
                }

                //calculate new offset here depending on the pill position
                var newOffset = getNewOffsetForPill(this);

                //animate and scroll left/right using newOffset
                secondaryNavElement.animate({
                    scrollLeft: secondaryNavElement.scrollLeft() + newOffset
                });
            });

            // -- main --

            init();

        }]
    };
});
})(window, window.angular);