(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.input-sort-code', [
    'ng.cork.ui.focus-on',
    'pp.components-templates',
    'pp.components.auto-tab',
    'pp.components.auto-tab-reverse'
]);

ngModule.directive('ppInputSortCode', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        templateUrl: 'components/_angular/input-sort-code/input-sort-code.tpl.html',
        scope: {
            sortCode: '=ngModel'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state
            vm.sort = {};

            // -- util functions

            function getSortObjectFromSortcode(sortCode) {
                var code = String(sortCode || '') || '';

                return {
                    one: code.slice(0, 2),
                    two: code.slice(2, 4),
                    three: code.slice(4, 6)
                };
            }

            // -- scope bindings

            vm.$onInit = function () {
                vm.sort = getSortObjectFromSortcode(vm.sortCode);

                $scope.$watch('vm.sort', function () {
                    //bring up required error on above controller
                    if (vm.sort.one && vm.sort.two && vm.sort.three) {
                        vm.sortCode = '' + vm.sort.one + vm.sort.two + vm.sort.three;
                    } else {
                        vm.sortCode = '';
                    }
                }, true);
            };

        }]
    };
}]);
})(window, window.angular);