(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.nationality-form', [
    'pp.widgets-templates',
    'pp.widgets.duo-group-dropdown',
    'pp.services.nationality',
    'pp.values.form-lists',
    'pp.components.required-any',
    'pp.components.validation-unique'
]);

var PROCESSING_TIMEOUT = 1000;
var READY_VALIDATION_KEY = 'formReady';
var DISALLOWED_NATIONALITY_VALIDATION_KEY = 'nationalityDisallowed';

var DISALLOWED_NATIONALITIES = ['US', 'UM'];
var NATIONALITY_NAME_CATCHALL = 'this';

ngModule.directive('ppNationalityForm', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/nationality-form/nationality-form.tpl.html',
        scope: {
            nationalityData: '=ppNationalityForm',
            feedbackEnabled: '<',
            isDisabled: '<',
            nationalitiesList: '<',
            showNote: '<',
            index: '@'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$q', 'nationalityService', 'formLists', function ($scope, $timeout, $q, nationalityService, formLists) {
            var vm = this;

            // -- initial state

            vm.disallowedNationalities = DISALLOWED_NATIONALITIES;

            // -- util functions

            function setReadyValidation(isValid) {
                $timeout(function () {
                    vm.form.nationality.$setValidity(READY_VALIDATION_KEY, isValid);
                });
            }

            // -- api

            vm.nationalityChange = function (nationality) {

                if (vm.form.$error.disallowed) {
                    return; //@note early return
                }

                vm.updatingIdentifiers = true;
                setReadyValidation(false);

                var promises = [
                    nationalityService.constructInvestorNationalitiesAndIdentifiersForFormByNationality(nationality),
                    $timeout(angular.noop, PROCESSING_TIMEOUT)
                ];

                $q.all(promises).then(function (responses) {
                        var identifiers = responses[0];
                        vm.nationalityData.identifiers = identifiers;
                    })
                    .catch(function () {
                        // todo handle rejection
                    })
                    .finally(function () {
                        vm.updatingIdentifiers = false;
                        setReadyValidation(true);
                    });
            };

            // -- scope bindings

            // -- main

            vm.$postLink = function () {
                vm.nationalityData = vm.nationalityData || {};
                if (vm.nationalityData.nationality) {
                    vm.nationalityChange(vm.nationalityData.nationality);
                }
            };

            vm.$onInit = function () {
                vm.nationalityData = vm.nationalityData || {};
                vm.nationalities = vm.nationalitiesList ? vm.nationalitiesList.all : [];
                vm.topNationalities = vm.nationalitiesList ? vm.nationalitiesList.top : [];
            };
        }]
    };
}]);
})(window, window.angular);