(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-card-form', [
    'pp.widgets-templates',
    'pp.components.input-amount',
    'pp.components.tooltip',
    'pp.components.checkbox',
    'pp.widgets.bond-card',
    'pp.services.core',
    'pp.services.apo',
    'pp.services.investor',
    'pp.services.preference',
    'pp.services.route',
    'pp.ui.services.required-user-data-dialog',
    'pp.ui.services.classification-dialog',
    'pp.values.glossary',
    'pp.values.investment-plans',
    'pp.filters.numbers'
]);

var PAGE_NAME = 'investment-card';
var INVESTMENT_PLAN_TYPE = 'investment_plan';
var STUDENT_TERMS = 'advance-pre-order-ts-and-cs-10-05-2018.pdf';
var COMMERCIAL_TERMS = 'advance-pre-order-ts-and-cs.pdf';
var RESIDENTIAL_TERMS = 'advance-pre-order-ts-and-cs-06-06-2018.pdf';
var INVESTMENT_PLAN_TERMS = 'investor_terms_and_conditions_investmentplans_lhe.pdf';
var DEFAULT_MIN_FUNDING = 5000;

ngModule.component('ppInvestmentCardForm', {
    templateUrl: 'widgets/_angular/investment-card-form/investment-card-form.tpl.html',
    bindings: {
        user: '<',
        type: '<',
        bondConfig: '<',
        plan: '<',
        minFunding: '<',
        onSubmit: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', '$timeout', '$q', '$window', 'glossary', 'ppPubSub', 'ppConfig', 'ppTrack', 'investmentPlans', 'routeService', 'ppComponentEmitter', function ($scope, $element, $timeout, $q, $window, glossary, ppPubSub, ppConfig, ppTrack, investmentPlans, routeService, ppComponentEmitter) {
        var vm = this;
        var endpoints = ppConfig.get('pp.external.endpoints') || {};

        // -- initial state
        vm.assetsEndpoint = endpoints.assets;
        vm.inputRegexp = /^(?=.*[1-9])\d*(\.\d{1,2})?$/;
        vm.minFundingAmount = DEFAULT_MIN_FUNDING;

        vm.fundPath = routeService.fundPath;

        vm.termsAndConditions = {
            student: STUDENT_TERMS,
            commercial: COMMERCIAL_TERMS,
            residential: RESIDENTIAL_TERMS,
            investment_plan: INVESTMENT_PLAN_TERMS
        };

        // -- util functions

        function getPathAndHash() {
            return $window.location.pathname + ($window.location.hash || '');
        }

        function handleSuccess() {
            ppTrack.event(PAGE_NAME + '.invest.success');

            vm.feedbackEnabled = false;
            vm.amount = null;
            vm.processing = false;
        }

        function handleFailure(error) {
            ppTrack.event(PAGE_NAME + '.invest.error');

            vm.error = error.data || {
                code: 'unexpected.error'
            };

            vm.feedbackEnabled = true;
            vm.processing = false;
        }

        // -- api

        vm.submit = function () {
            ppTrack.action('form.' + PAGE_NAME + '.submit', {
                amount: vm.amount
            });
            vm.error = null;

            if (vm.form.$valid) {
                ppTrack.event('form.' + PAGE_NAME + '.success', {
                    amount: vm.amount
                });
                vm.processing = true;

                vm.onSubmit({
                    amount: vm.amount
                }).then(handleSuccess, handleFailure);
            } else {
                ppTrack.event('form.' + PAGE_NAME + '.validation-error');
                vm.feedbackEnabled = true;
            }
        };

        vm.showTermsAndConditions = function () {
            if (!vm.processing) {
                ppTrack.pageAction(PAGE_NAME, 'terms-and-conditions.show');
            }
        };

        vm.setPostFundRedirect = function () {
            routeService.setPostFundRedirect(getPathAndHash());
        };

        // -- scope bindings

        vm.$onChanges = function (changes) {
            if (changes.minFunding) {
                vm.minFundingAmount = changes.minFunding.currentValue || DEFAULT_MIN_FUNDING;
            }

            if (changes.plan && changes.plan.currentValue) {
                vm.icon = investmentPlans.createIconUrl(vm.assetsEndpoint, changes.plan.currentValue.key);
            }

            ppComponentEmitter.emit('investment-card-form.controller.loaded', vm);
        };

        vm.$postLink = function () {
            ppComponentEmitter.emit('investment-card-form.dom.loaded', $element);
        };

        // -- main

    }]
});
})(window, window.angular);