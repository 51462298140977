(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.terms-apply-badge', [
    'pp.services.core',
    'pp.services.route',
    'pp.widgets-templates'
]);

ngModule.component('ppTermsApplyBadge', {
    templateUrl: 'widgets/_angular/terms-apply-badge/terms-apply-badge.tpl.html',
    bindings: {
        type: '@',
        openNewTab: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', 'routeService', function ($scope, ppConfig, routeService) {
        var vm = this;

        var __config = ppConfig.get('pp.external.endpoints') || {};

        // -- initial state

        var TERMS_LINK_MAP = {
            'investment-plans': routeService.investmentPlansTerms,
            'investment-plans-undeployed-funds': routeService.investmentPlansUndeployedFundsTerms
        };

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$onInit = function () {
            vm.link = TERMS_LINK_MAP[vm.type];
        };

        // -- main

    }]
});
})(window, window.angular);