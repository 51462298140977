(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.carousel', [
    'pp.components-templates',
    'pp.services.core'
]);

ngModule.directive('ppCarousel', [function () {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/carousel/carousel.tpl.html',
        scope: {
            slides: '<',
            currentSlide: '='
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$element', 'ppHammer', function ($scope, $timeout, $element, Hammer) {

            var vm = this;

            // -- initial state

            var forwardBackIcons = [
                '<div class="back-arrow arrow"><i class="i-arrow-back icon"></i></div>',
                '<div class="forward-arrow arrow"><i class="i-arrow-forward icon"></i></div>'
            ];

            var __carouselOptions = {
                items: 1,
                slideSpeed: 1000,
                navigation: true,
                nav: true,
                navText: forwardBackIcons,
                dots: true,
                lazyLoad: true,
                responsiveRefreshRate: 200,
                rewindNav: false
            };

            // -- util functions

            function maintainCurrentSlide(event) {
                var currentPosition = event.item.index;
                $scope.$evalAsync(function () {
                    vm.activeSlide = currentPosition;
                    vm.currentSlide = currentPosition;
                });
            }

            function goToNextSlide() {
                if (vm.carousel) {
                    vm.carousel.trigger('next.owl.carousel');
                }
            }

            function goToPrevSlide() {
                if (vm.carousel) {
                    vm.carousel.trigger('prev.owl.carousel');
                }
            }

            function goToSlide(position) {
                if (vm.carousel) {
                    vm.carousel.trigger('to.owl.carousel', position);
                }
            }

            function createCarousel() {
                vm.carousel = $($element).find('.owl-carousel').owlCarousel(__carouselOptions);
                vm.carousel.on('changed.owl.carousel', maintainCurrentSlide);
                goToSlide(vm.currentSlide);
            }

            function slideClick() {
                $scope.$emit('pp.carousel.click');
            }

            // -- api

            // -- scope bindings

            $scope.$on('pp.carousel.refresh', function () {
                if (!vm.carousel || !vm.carousel.trigger) {
                    return;
                }

                vm.carousel.trigger('refresh.owl.carousel');
            });

            $scope.$on('pp.carousel.next', goToNextSlide);
            $scope.$on('pp.carousel.prev', goToPrevSlide);

            // -- main

            vm.$onDestroy = function () {
                if (vm.carousel) {
                    vm.carousel.trigger('destroy.owl.carousel');
                }
            };

            vm.$postLink = function () {
                $timeout(createCarousel).then(function () {
                    $scope.$watch('vm.currentSlide', function (slide) {
                        var carousel = vm.carousel;
                        if (!carousel) {
                            return;
                        }

                        var current = carousel.currentItem;
                        if (vm.activeSlide !== slide) {
                            goToSlide(slide);
                        }
                    });

                    var hammer = new Hammer.Manager($element[0].querySelector('.owl-stage-outer'));

                    hammer.add(new Hammer.Tap({
                        taps: 1
                    }));

                    hammer.on('tap', slideClick);
                });

            };

        }]
    };
}]);
})(window, window.angular);