(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-vote-form', [
    'pp.widgets-templates',
    'pp.components.radio-button',
    'pp.services.route',
    'pp.services.core'
]);

ngModule.component('ppPropertyVoteForm', {
    templateUrl: 'widgets/_angular/property-vote-form/property-vote-form.tpl.html',
    bindings: {
        property: '<',
        choice: '<',
        holding: '<',
        onUpdateVote: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', 'R', function ($scope, routeService, R) {
        var vm = this;

        // -- initial state

        vm.exitMechanicHelp = routeService.exitMechanicHelp;

        // -- util functions

        var getPropertyPath = R.compose(
            routeService.getIndividualPropertyPath,
            R.path(['property', 'currentValue', 'symbol'])
        );

        var getBaseShareValuation = function (property) {
            var isVpvBased = R.path(['property', 'currentValue', 'isVpvBased'], property);
            if (isVpvBased) {
                return R.path(['property', 'currentValue', 'valuation', 'shareVpv'], property);
            } else {
                return R.path(['property', 'currentValue', 'valuation', 'share'], property);
            }

        };

        // -- api

        vm.goToVote = function () {
            vm.step = 'vote';
        };

        vm.updateVote = function (choice) {
            if (R.isNil(choice)) {
                vm.requiredError = true;
                return; // @note early return
            }

            vm.requiredError = false;
            vm.error = false;
            vm.processing = true;
            vm.onUpdateVote({
                choice: choice
            }).then(function () {
                vm.processing = false;
                vm.step = 'registered';
            }, function () {
                vm.processing = false;
                vm.error = true;
            });
        };

        // -- scope bindings

        vm.$onChanges = function (changes) {
            vm.propertyPath = getPropertyPath(changes);
            vm.shareValuation = getBaseShareValuation(changes);

        };

        // -- main

    }]
});
})(window, window.angular);