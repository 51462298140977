(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-map', [
    'pp.widgets-templates',
    'pp.widgets.property-map-info-window',
    'pp.services.core',
    'pp.services.google-maps-api',
    'pp.filters.property',
    'pp.services.google-maps',
    'pp.services.browser-store',
    'pp.services.route'
]);

ngModule.component('ppPropertyMap', {
    templateUrl: 'widgets/_angular/property-map/property-map.tpl.html',
    bindings: {
        filter: '<',
        onSelectedProperty: '&',
        mapReady: '=',
        disableAutoSelect: '<',
        loadPropertyData: '&',
        user: '<'
    },
    controllerAs: 'vm',
    controller: ['$rootScope', '$scope', '$element', '$q', '$filter', '$timeout', '$compile', 'ppConfig', 'ppTrack', 'googleMapsApiService', 'googleMapsService', 'browserStoreService', 'routeService', function ($rootScope, $scope, $element, $q, $filter, $timeout, $compile, ppConfig, ppTrack, googleMapsApiService, googleMapsService, browserStoreService, routeService) {
        // -- initial state

        var vm = this;

        vm.mapReady = false;
        vm.markers = [];
        var __config = ppConfig.get('pp.external.endpoints') || {};
        var assetsBaseUrl = __config ? __config.assets : '';

        var prev_infowindow = false;

        var mapOptions = {
            scaleControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            styles: [{
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{
                    visibility: 'off'
                }]
            }]
        };

        // -- util functions

        function setMapToReady() {
            $scope.$evalAsync(function () {
                vm.mapReady = true;
            });
        }

        function getIconUrl(marketState) {
            switch (marketState) {
            case 'primary-closed':
            case 'primary':
                return assetsBaseUrl + '/images/pins/primary-unselected-lhe.png';
            case 'secondary':
            case 'secondary-informational':
            case 'finished':
                return assetsBaseUrl + '/images/pins/secondary-unselected-lhe.png';
            case 'pre-order-closed':
            case 'preview':
            case 'pre-listing':
            case 'pre-order':
                return assetsBaseUrl + '/images/pins/preorder-unselected-lhe.png';
            default:
                return assetsBaseUrl + '/images/pins/secondary-unselected-lhe.png';
            }
        }

        function getSelectedIconUrl(marketState) {
            switch (marketState) {
            case 'primary-closed':
            case 'primary':
                return assetsBaseUrl + '/images/pins/primary-selected-lhe.png';
            case 'secondary':
            case 'secondary-informational':
                return assetsBaseUrl + '/images/pins/secondary-selected-lhe.png';
            case 'pre-order-closed':
            case 'preview':
            case 'pre-listing':
            case 'pre-order':
                return assetsBaseUrl + '/images/pins/preorder-selected-lhe.png';
            case 'finished':
                return assetsBaseUrl + '/images/pins/secondary-selected-lhe.png';
            default:
                return assetsBaseUrl + '/images/pins/secondary-selected-lhe.png';
            }
        }

        function generateIcon(googleMapsApi, marketState, selected) {

            var iconUrl;
            if (selected) {
                iconUrl = getSelectedIconUrl(marketState);
            } else {
                iconUrl = getIconUrl(marketState);
            }

            return {
                url: iconUrl,
                size: new googleMapsApi.Size(44, 55),
                origin: new googleMapsApi.Point(0, 0),
                anchor: new googleMapsApi.Point(22, 55),
                scaledSize: new googleMapsApi.Size(44, 55)

            };
        }

        function isOnMap(property) {
            return !property.isMortgage && !property.isFund;
        }

        function setSelected(marker, googleMapsApi) {

            if (vm.selectedMarker) {
                var icon = generateIcon(googleMapsApi, vm.selectedMarker.marketState);
                vm.selectedMarker.setIcon(icon);
                vm.selectedMarker.setZIndex(1);
            }
            //set icon to selected
            var selectedIcon = generateIcon(googleMapsApi, marker.marketState, true);
            marker.setIcon(selectedIcon);
            marker.setZIndex(3000);
            vm.selectedMarker = marker;

            browserStoreService.setSessionStorageItem('map-view.marker', marker.symbol);

        }

        function setDeselected(marker, googleMapsApi) {

            //set icon to deselected
            var selectedIcon = generateIcon(googleMapsApi, marker.marketState, false);
            marker.setIcon(selectedIcon);
            marker.setZIndex(1);
            vm.selectedMarker = null;

            browserStoreService.setSessionStorageItem('map-view.marker', '');

        }

        function createClickEvent(marker, googleMapsApi, map) {
            googleMapsApi.event.addListener(marker, 'click', function (e) {

                setSelected(marker, googleMapsApi);

                ppTrack.action('pp.map-view.pin-clicked', {
                    symbol: marker.symbol,
                    location: marker.position
                });

                var action = vm.onSelectedProperty({
                    symbol: marker.symbol,
                    properties: vm.locations
                });

                if (action && typeof action.then === 'function') {
                    action.then(function () {
                        setDeselected(marker, googleMapsApi);
                    });
                }

            });
        }

        vm.clickInfoWindow = function (marker, googleMapsApi, symbol) {

            setSelected(marker, googleMapsApi);

            ppTrack.action('pp.map-view.info-window.clicked', {
                symbol: symbol
            });

            var action = vm.onSelectedProperty({
                symbol: symbol,
                properties: vm.locations
            });

            if (action && typeof action.then === 'function') {
                action.then(function () {
                    setDeselected(marker, googleMapsApi);
                });
            }
        };

        function createInfoWindowScope(property, scope, marker, googleMapsApi) {

            scope.location = $filter('propertyLocation')(property.location);
            scope.address = $filter('propertyAddress')(property);
            scope.image = assetsBaseUrl + '/images/properties/' + property.symbol + '/thumb/image1.jpg';
            scope.clickInfoWindow = vm.clickInfoWindow.bind(null, marker, googleMapsApi);
            scope.symbol = property.symbol;
            return scope;
        }

        function createHoverEvent(marker, googleMapsApi, map, property) {

            var infowindow = new google.maps.InfoWindow({
                content: '<div><div class="loading"></div></div>'
            });

            marker.addListener('mouseover', (function (scope, property) {
                return function () {
                    if (prev_infowindow) {
                        prev_infowindow.close();
                    }

                    var content = '<pp-property-map-info-window image="image" symbol="symbol" location="location" address="address" on-click="clickInfoWindow(symbol)"></pp-property-map-info-window>';

                    var compiledContent = $compile(content)(scope);

                    scope.$apply(function () {
                        infowindow.setContent(compiledContent[0]);
                        infowindow.open(map, marker);
                        prev_infowindow = infowindow;
                    });

                };
            })(createInfoWindowScope(property, $rootScope.$new(), marker, googleMapsApi), property));
        }

        function zoomChangedListener() {
            var zoom = vm.map.getZoom();
            browserStoreService.setSessionStorageItem('map-view.zoom', zoom);
            ppTrack.action('pp.map-view.zoom-changed', {
                zoomLevel: zoom
            });
        }

        function boundsChangedListener(googleMapsApi) {
            googleMapsApi.event.trigger(vm.map, 'resize');
        }

        function getMarkersAndCreateClickEvent(properties, googleMapsApi, map, selectedSymbol) {

            var bounds = new googleMapsApi.LatLngBounds();

            var markers = properties.map(function (data) {

                var icon = generateIcon(googleMapsApi, data.state.status);

                var marker = new googleMapsApi.Marker({
                    position: {
                        lat: data.location.geo.latitude,
                        lng: data.location.geo.longitude
                    },
                    map: map,
                    icon: icon,
                    symbol: data.symbol,
                    holding: data.holding,
                    firstHolding: data.firstHolding,
                    marketState: data.state.status,
                    optimized: false
                });

                if (marker.symbol === selectedSymbol) {
                    vm.selectedMarker = marker;
                }

                bounds.extend(marker.getPosition());

                //Attach click event to the marker.
                createClickEvent(marker, googleMapsApi, map);
                createHoverEvent(marker, googleMapsApi, map, data);

                return marker;
            });

            if (!vm.selectedMarker) {
                map.fitBounds(bounds);
            }

            map.addListener('zoom_changed', zoomChangedListener);
            map.addListener('bounds_changed', boundsChangedListener.bind(null, googleMapsApi));

            return markers;
        }

        function setFilter(filter, googleMapsApi) {
            vm.markers.forEach(function (marker) {
                if (filter === 'holdings' && !marker.holding) {
                    marker.setVisible(false);
                } else if ((filter === 'holdings' && marker.firstHolding) && (!vm.selectedMarker.holding)) {
                    //selects first holding when in my investements filter and selected marker is not part of holdings
                    setSelected(marker, googleMapsApi);
                    marker.setVisible(true);
                } else {
                    marker.setVisible(true);
                }
            });
        }

        function getSelectedPropertyAndFilter(googleMapsApi, marker) {

            var selectedIcon = generateIcon(googleMapsApi, marker.marketState, true);

            vm.map.setCenter(marker.getPosition());

            marker.setIcon(selectedIcon);
            marker.setZIndex(3000);
            vm.selectedMarker = marker;

            vm.onSelectedProperty({
                symbol: marker.symbol,
                properties: vm.locations
            });

            setFilter(vm.filter, googleMapsApi);
        }

        // -- api

        // -- scope bindings

        vm.$onChanges = function (changes) {
            if (changes.filter && changes.filter.currentValue && vm.map) {
                setFilter(changes.filter.currentValue, vm.googleMaps);
            }
        };

        // -- main

        vm.$onInit = function () {
            $q.all({
                googleMapsApi: googleMapsApiService.load(),
                properties: vm.loadPropertyData()
            }).then(function (data) {
                vm.locations = data.properties.filter(isOnMap);
                var googleMapsApi = data.googleMapsApi;
                var options;
                var icon;
                options = mapOptions;

                googleMapsService.renderDeferredMap(googleMapsApi, $element[0].childNodes[0], vm.type, vm.center, vm.locations, options, icon).then(function (map) {

                    setMapToReady();

                    var selectedSymbol = browserStoreService.getSessionStorageItem('map-view.marker');
                    vm.map = map;
                    vm.googleMaps = googleMapsApi;
                    vm.markers = getMarkersAndCreateClickEvent(vm.locations, googleMapsApi, vm.map, selectedSymbol);

                    var zoom = browserStoreService.getSessionStorageItem('map-view.zoom');

                    if (zoom) {
                        zoom = parseInt(zoom);
                        vm.map.setZoom(zoom);
                    }

                    if (!vm.disableAutoSelect) {
                        var selectedMarker = vm.selectedMarker || vm.markers[0];
                        getSelectedPropertyAndFilter(googleMapsApi, selectedMarker);
                    } else {
                        setFilter(vm.filter, googleMapsApi);
                        googleMapsApi.event.addListenerOnce(map, 'idle', setMapToReady);
                    }

                    googleMapsApi.event.addDomListener(window, 'resize', function () {
                        googleMapsApi.event.trigger(vm.map, 'resize');
                    });

                    map.addListener('click', function () {
                        if (prev_infowindow && prev_infowindow.close) {
                            prev_infowindow.close();
                        }
                    });
                });
            });
        };

    }]
});
})(window, window.angular);