(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.investor-settings', ['pp.services.investor']);

var API_BASE_PATH = '/feapi/r1';
var API_INVESTOR_CONTACT_DETAILS_ENDPOINT = API_BASE_PATH + '/investor/contact-details';
var API_INVESTOR_PASSWORD_ENDPOINT = API_BASE_PATH + '/auth/password';
var API_INVESTOR_ADDRESS_ENDPOINT = API_BASE_PATH + '/investor/address';

ngModule.service('investorSettingsService', ['$http', '$q', 'investorService', function ($http, $q, investorService) {

    var api = {};

    /**
     * @ngdoc method
     * @name investorSettingsService#updateContactDetails
     *
     * @description
     * Given the contact details posts to the backend to update the investors contact details
     *
     * Success response:
     *
     * true
     *
     * @param {object} contactDetails
     * @returns {Promise}
     */
    api.updateContactDetails = function (contactDetails) {
        var endpoint = API_INVESTOR_CONTACT_DETAILS_ENDPOINT;

        return $http.post(endpoint, contactDetails).then(function () {
            return true;
        }, function (err) {
            if (err.data && err.data.code) {
                return $q.reject({
                    reason: err.data.code
                });
            } else {
                return $q.reject({
                    reason: 'investor.contact-details-update.unexpected'
                });
            }
        });
    };

    /**
     * @ngdoc method
     * @name investorSettingsService#addAddress
     *
     * @description
     * Given an address adds an address to an investor and sets it as their default address
     *
     * Success response:
     *
     * true
     *
     * @param {object} addAddress
     * @returns {Promise}
     */
    api.addAddress = function (address) {
        var endpoint = API_INVESTOR_ADDRESS_ENDPOINT;

        // backend retruns 404 for empty sting, therefore we remove any lines from the address that are not truthy
        for (var line in address) {
            if (!address[line]) {
                delete address[line];
            }
        }

        return $http.post(endpoint, address).then(function () {
            return true;
        }, function (err) {
            if (err.data && err.data.code) {
                return $q.reject({
                    reason: err.data.code
                });
            } else {
                return $q.reject({
                    reason: 'investor.add-address.unexpected'
                });
            }
        });
    };

    /**
     * @ngdoc method
     * @name investorSettingsService#getAddress
     *
     * @description
     * Given an address adds an address to an investor and sets it as their default address
     *
     * Success response:
     *
     * true
     *
     * @param {object} addAddress
     * @returns {Promise}
     */
    api.getAddress = function (address) {
        var endpoint = API_INVESTOR_ADDRESS_ENDPOINT;

        return $http.get(endpoint).then(function (response) {
            return response.data;
        }, function (err) {
            if (err.data && err.data.code) {
                return $q.reject({
                    reason: err.data.code
                });
            } else {
                return $q.reject({
                    reason: 'investor.get-address.unexpected'
                });
            }
        });
    };

    /**
     * @ngdoc method
     * @name investorSettingsService#changePassword
     *
     *
     * Success response:
     *
     * true
     *
     * @param {string} newPassword
     * @param {string} oldPassword
     * @param {string} repeatOldPassword
     * @returns {Promise}
     */
    api.changePassword = function (currentPassword, newPassword, newPasswordRepeat) {
        var endpoint = API_INVESTOR_PASSWORD_ENDPOINT;

        var payload = {
            newPassword: angular.copy(newPassword),
            newPasswordRepeat: angular.copy(newPasswordRepeat)
        };

        var options = {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        };

        options = investorService.decorateHeadersWithPassword(options, currentPassword);

        return $http.post(endpoint, payload, options).then(function () {
            return true;
        }, function (err) {
            if (err.data && err.data.code) {
                return $q.reject({
                    reason: err.data.code
                });
            } else {
                return $q.reject({
                    reason: 'investor.change-password.unexpected'
                });
            }
        });
    };

    return api;

}]);
})(window, window.angular);