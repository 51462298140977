(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.expandable', ['ngSanitize', 'pp.widgets-templates', 'pp.services.core']);

ngModule.directive('ppExpandable', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/expandable/expandable.tpl.html',
        scope: {
            copy: '<ppExpandable',
            trackId: '@',
            collapsed: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$sce', '$timeout', '$element', 'ppTrack', 'ppComponentEmitter', function ($scope, $sce, $timeout, $element, ppTrack, ppComponentEmitter) {
            var vm = this;
            var tracked;

            // -- initial state

            // -- util functions

            function trackOnce() {
                if (!tracked) {
                    ppTrack.action('expand-content.' + vm.trackId);
                    tracked = true;
                }
            }

            vm.trustAsHtml = function (string) {
                return $sce.trustAsHtml(string);
            };

            // -- api

            vm.expand = function () {
                vm.collapsed = false;
                trackOnce();
            };

            vm.contract = function () {
                vm.collapsed = true;
            };

            // -- scope bindings

            vm.$onInit = function () {
                if (!angular.isDefined(vm.collapsed)) {
                    vm.collapsed = true;
                }

                if (vm.property && vm.property.symbol) {
                    ppComponentEmitter.emit(vm.trackId + '.controller.loaded.', vm);

                    $timeout(function () {
                        ppComponentEmitter.emit(vm.trackId + '.dom.loaded.', $element);
                    });
                }

            };

            // -- main

        }]
    };
}]);
})(window, window.angular);