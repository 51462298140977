(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.time-left', []);

ngModule.service('timeLeftService', ['$window', function ($window) {

    var api = {};

    var msPerDay = 86400000;
    var msPerHour = 3600000;
    var msPerMinute = 60000;

    var pp = $window.pp || {};

    var serverTimeNow = pp.serverTime.getTime();

    function calcuateTimeUnit(endDate, msPerUnit) {
        var msLeft = endDate - serverTimeNow;

        // Minimum unit number is zero
        return Math.max(Math.floor(msLeft / msPerUnit), 0);
    }

    api.getDaysLeft = function (endDate) {
        return calcuateTimeUnit(endDate, msPerDay);
    };

    api.getHoursLeft = function (endDate) {
        return calcuateTimeUnit(endDate, msPerHour);
    };

    api.getMinutesLeft = function (endDate) {
        return calcuateTimeUnit(endDate, msPerMinute);
    };

    return api;

}]);
})(window, window.angular);