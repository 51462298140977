(function(window, angular){'use strict';

var ngModule = angular.module('pp.controllers.portfolio-router', [
    'ui.router',
    'pp.services.auth',
    'pp.services.investor',
    'pp.services.receipt',
    'pp.widgets.dashboard',
    'pp.widgets.dashboard-pending',
    'pp.widgets.dashboard-total-return',
    'pp.widgets.dashboard-sold',
    'pp.widgets.dashboard-current-loans',
    'pp.widgets.dashboard-exited-loans',
    'pp.widgets.dashboard-pending-loans',
    'pp.widgets.dashboard-bid',
    'pp.widgets.dashboard-offer',
    'pp.widgets.tax-statement',
    'pp.widgets.investment-docs',
    'pp.widgets.account-history',
    'pp.widgets.withdrawal'
]);

ngModule.config(['$stateProvider', '$urlRouterProvider', '$windowProvider', function ($stateProvider, $urlRouterProvider, $windowProvider) {

    var $window = $windowProvider.$get();
    var pathname = $window.location.pathname;
    var hash = $window.location.hash;

    function switchInvestorAndRedirect(investorKind, trans) {
        var investorService = trans.injector().get('investorService');
        var $state = trans.injector().get('$state');
        return investorService.switchToInvestorByKind(investorKind).then(function () {
            if ($state.current.name.indexOf('portfolio.dashboard') > -1) {
                $state.reload();
            }

            return 'portfolio.dashboard';
        });
    }

    var switchToGeneralResolve = {
        investor: ['investorService', function (investorService) {
            return investorService.switchToInvestorByKind('general');
        }]
    };

    // @todo remove this logic by splitting up app into smaller submodules and move to frontend routing
    // This is so the redirect does not happen on all other routes within the app
    if (pathname.match(/^\/my-portfolio/)) {

        $stateProvider.decorator('data', function (state, parent) {
            var stateData = parent(state);
            state.resolve = state.resolve || {};

            state.resolve.security = ['$q', '$window', 'authService', 'receiptService', function ($q, $window, authService, receiptService) {
                authService.getSessionTimeLeft()
                    .then(receiptService.checkForReceipts)
                    .catch(function () {
                        $window.location.reload();
                    });
                return $q.when();
            }];
            return stateData;
        });

        $stateProvider
            .state('portfolio', {
                abstract: true
            })
            .state('portfolio.dashboard', {
                url: '/dashboard',
                component: 'ppDashboard'
            })
            .state('portfolio.dashboard.pending', {
                url: '/pending',
                redirectTo: 'portfolio.pending'
            })
            .state('portfolio.dashboard.current', {
                url: '/current',
                redirectTo: 'portfolio.current'
            })
            .state('portfolio.dashboard.listed', {
                url: '/listed',
                redirectTo: 'portfolio.offers'
            })
            .state('portfolio.dashboard.sold', {
                url: '/sold',
                redirectTo: 'portfolio.sold'
            })
            .state('portfolio.dashboard.bids', {
                url: '/bids',
                redirectTo: 'portfolio.bids'
            })
            .state('portfolio.dashboard-isa', {
                url: '/dashboard-isa',
                redirectTo: switchInvestorAndRedirect.bind(this, 'isa')
            })
            .state('portfolio.dashboard-general', {
                url: '/dashboard-general',
                redirectTo: switchInvestorAndRedirect.bind(this, 'general')
            })
            .state('portfolio.pending', {
                url: '/pending',
                component: 'ppDashboardPending',
                resolve: switchToGeneralResolve
            })
            .state('portfolio.bids', {
                url: '/bids',
                component: 'ppDashboardBid',
                resolve: switchToGeneralResolve
            })
            .state('portfolio.bids.open-property', {
                url: '/:propertySymbol',
                component: 'ppDashboardBid',
                resolve: switchToGeneralResolve
            })
            .state('portfolio.offers', {
                url: '/offers',
                component: 'ppDashboardOffer',
                resolve: switchToGeneralResolve
            })
            .state('portfolio.offers.open-property', {
                url: '/:propertySymbol',
                component: 'ppDashboardOffer',
                resolve: switchToGeneralResolve
            })
            .state('portfolio.sold', {
                url: '/sold',
                component: 'ppDashboardSold',
                resolve: switchToGeneralResolve
            })
            .state('portfolio.sold.open-property', {
                url: '/:propertySymbol',
                component: 'ppDashboardSold',
                resolve: switchToGeneralResolve
            })
            .state('portfolio.pending-loans', {
                url: '/pending-loans',
                component: 'ppDashboardPendingLoans'
            })
            .state('portfolio.current-loans', {
                url: '/current-loans',
                component: 'ppDashboardCurrentLoans'
            })
            .state('portfolio.exited-loans', {
                url: '/exited-loans',
                component: 'ppDashboardExitedLoans'
            })
            .state('portfolio.properties-current', {
                url: '/properties-current',
                component: 'ppDashboardTotalReturn',
                resolve: switchToGeneralResolve
            })
            .state('portfolio.properties-current.open-property', {
                url: '/:propertySymbol',
                component: 'ppDashboardTotalReturn',
                resolve: switchToGeneralResolve
            })
            .state('portfolio.documents', {
                url: '/documents',
                component: 'ppInvestmentDocs'
            })
            .state('portfolio.tax', {
                url: '/tax',
                component: 'ppTaxStatement'
            })
            .state('portfolio.account-history', {
                abstract: true,
                url: '/account/history',
                component: 'ppAccountHistory'
            })
            .state('portfolio.account-history.type', {
                url: '/{type:manual|autoinvest}/{page:[0-9]+}',
                component: 'ppAccountHistoryType'
            });

        $urlRouterProvider.otherwise('/dashboard');
    }

}]);
})(window, window.angular);