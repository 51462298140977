(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.sell-form', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.ui.services.terms-dialog',
    'pp.services.investor',
    'pp.filters.numbers',
    'pp.components.bind-invalid-model',
    'pp.components.input-plus-minus',
    'pp.components.input-range',
    'pp.components.tooltip',
    'pp.components.checkbox',
    'pp.widgets.recaptcha',
    'pp.values.glossary',
    'pp.widgets.quote-breakdown',
    'pp.widgets.exit-mechanic-trade-warning'
]);

var MINIMUM_SHARES_SALE = 50;
var PAGE_NAME = 'sell-form';
var AGREEMENT_TO_TRANSFER_DOCS_TYPE = 'transfer-agreement';

var NUMBERS_REGEX = /^-?[0-9]+$/;

ngModule.component('ppSellForm', {
    templateUrl: 'widgets/_angular/sell-form/sell-form.tpl.html',
    bindings: {
        user: '<',
        holdings: '<',
        property: '<',
        loadingError: '<?',
        onSubmit: '&',
        reCaptchaKey: '<',
        reCaptchaEnabled: '<',
        alwaysToggleGraphs: '<',
        hideGraphs: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$timeout', 'ppTrack', 'ppConfig', 'ppUtil', 'termsDialogService', 'glossary', 'investorService', function ($scope, $timeout, ppTrack, ppConfig, ppUtil, termsDialogService, glossary, investorService) {
        var vm = this;

        // -- initial state

        var __trackedDetails;

        vm.inputRegexpWholeNumbers = NUMBERS_REGEX;

        vm.results = {};
        vm.shares = {};
        vm.pPrice = {};

        vm.glossary = glossary;

        vm.processing = false;
        vm.disabled = true;
        vm.valid = false;
        vm.error = false;

        // -- util functions

        function setAllowedOfferLimits() {
            vm.lowestAllowedOffer = Math.ceil(vm.property.market.secondary.banding.lowerBound * 100);
            vm.highestAllowedOffer = Math.floor(vm.property.market.secondary.banding.upperBound * 100);
        }

        function setupSellData(user) {
            if (!vm.holdings) {
                return;
            }

            var sharesAvailableForListing = vm.holdings.units - vm.holdings.offered;

            vm.totalShares = vm.holdings.units;

            // round to nearest int because js float maths is not exact
            if (ppUtil.hasPathIn(vm.property, 'market.secondary.bestOffer')) {
                vm.startingPrice = Math.round(100 * vm.property.market.secondary.bestOffer.price);
            } else if (ppUtil.hasPathIn(vm.property, 'valuation')) {
                vm.startingPrice = Math.ceil(100 * vm.property.valuation.share);
            }

            if (sharesAvailableForListing <= 0) {
                vm.loadingError = 'sell.error.all-listed';
                vm.shares.value = 0;
                vm.shares.floor = 0;
                vm.shares.ceil = 0;
                vm.pPrice.value = null;
                vm.sharesDisabled = true;
                vm.disabled = true;
                return;
            } else if (sharesAvailableForListing < MINIMUM_SHARES_SALE) {
                vm.shares.value = sharesAvailableForListing;
                vm.shares.floor = sharesAvailableForListing - 1; // pushes disabled slider all the way to the right
                vm.shares.ceil = sharesAvailableForListing;
                vm.disabled = !user.permissions.canSellSecondary;
                vm.sharesDisabled = true;
            } else {
                vm.shares.value = sharesAvailableForListing;
                vm.shares.sliderValue = sharesAvailableForListing;
                vm.shares.floor = MINIMUM_SHARES_SALE;
                vm.shares.ceil = sharesAvailableForListing;
                vm.disabled = !user.permissions.canSellSecondary;
            }

            setAllowedOfferLimits();

            vm.pPrice = {
                value: vm.startingPrice,
                floor: vm.lowestAllowedOffer,
                ceil: vm.highestAllowedOffer,
            };

            ppTrack.setContext({
                'sell.sharesOwned': vm.holdings.units,
                'sell.sharesOffered': vm.holdings.offered,
                'sell.shares': vm.shares.value,
                'sell.price': vm.pPrice.value,
                'property.upper-bound': vm.highestAllowedOffer,
                'property.lower-bound': vm.lowestAllowedOffer
            });
        }

        function pPerShareOf(value) {
            return (value / vm.holdings.units) * 100;
        }

        function totalAmount(value) {
            return (pPerShareOf(value) * vm.shares.value) / 100;
        }

        function getProfitPerShare(pPrice) {
            var price = pPrice / 100;
            var incomePerShare = vm.holdings.incomeReceived / vm.holdings.units;
            var feesPerShare = vm.holdings.aggregateFeesPaid / vm.holdings.units;
            var taxPerShare = vm.holdings.aggregateTaxPaid / vm.holdings.units;
            return price + incomePerShare - feesPerShare - taxPerShare - vm.holdings.avgUnitPurchasePrice;
        }

        function getRealisedGainsPerShare(pPrice) {
            var price = pPrice / 100;
            return price - vm.holdings.avgUnitPurchasePrice;
        }

        function resetRecaptcha() {
            $scope.$broadcast('recaptcha.reload');
        }

        function updateResults() {
            if (!vm.form) {
                return;
            }

            var shares = vm.shares.value;
            var pPrice = vm.pPrice.value;

            var premium;

            vm.valid = vm.form.shares.$valid && vm.form.pPrice.$valid && !vm.loadingError;

            if (!vm.valid) {
                vm.results = {};
                return;
            }

            if (ppUtil.hasPathIn(vm.property, 'valuation')) {
                premium = 100 * (pPrice / 100 - vm.property.valuation.share) / vm.property.valuation.share;
            }
            var profitPerShare = getProfitPerShare(pPrice);

            var profit = {
                pPerShare: profitPerShare * 100,
                total: profitPerShare * shares
            };
            var averagePricePaid = {
                pPerShare: vm.holdings.avgUnitPurchasePrice * 100,
                total: vm.holdings.avgUnitPurchasePrice * shares
            };
            var feesPaid = {
                pPerShare: pPerShareOf(vm.holdings.aggregateFeesPaid),
                total: totalAmount(vm.holdings.aggregateFeesPaid)
            };
            var taxesPaid = {
                pPerShare: pPerShareOf(vm.holdings.aggregateTaxPaid),
                total: totalAmount(vm.holdings.aggregateTaxPaid)
            };
            var averageTotalCost = {
                pPerShare: taxesPaid.pPerShare + feesPaid.pPerShare + averagePricePaid.pPerShare,
                total: taxesPaid.total + feesPaid.total + averagePricePaid.total
            };
            var incomeReceived = {
                pPerShare: pPerShareOf(vm.holdings.incomeReceived),
                total: totalAmount(vm.holdings.incomeReceived)
            };

            var realisedGains = {
                pPerShare: getRealisedGainsPerShare(pPrice) * 100,
                total: getRealisedGainsPerShare(pPrice) * shares
            };

            var totalReturns = {
                pPerShare: realisedGains.pPerShare + incomeReceived.pPerShare,
                total: realisedGains.total + incomeReceived.total,
            };

            vm.results = {
                premium: premium,
                proceeds: shares * (pPrice / 100),
                profit: profit,
                averagePricePaid: averagePricePaid,
                feesPaid: feesPaid,
                taxesPaid: taxesPaid,
                averageTotalCost: averageTotalCost,
                incomeReceived: incomeReceived,
                realisedGains: realisedGains,
                totalReturns: totalReturns
            };
        }

        function setPriceLimitMessage(sellPrice) {

            setAllowedOfferLimits();

            if (!sellPrice) {
                vm.priceLimitMessage = null;
                vm.priceMessage = null;
                return;
            }

            if (sellPrice >= vm.highestAllowedOffer) {
                vm.priceLimitMessage = 'upper-bound';
                return;
            } else if (sellPrice <= vm.lowestAllowedOffer) {
                vm.priceLimitMessage = 'lower-bound';
                return;
            } else {
                vm.priceLimitMessage = null;
            }
        }

        // -- api

        vm.showTermsAndConditions = function () {
            if (!vm.processing) {
                ppTrack.pageAction(PAGE_NAME, 'terms-and-conditions.show');
                termsDialogService.show(AGREEMENT_TO_TRANSFER_DOCS_TYPE);
            }
        };

        vm.toggleDetails = function () {
            if (!__trackedDetails) {
                ppTrack.pageAction(PAGE_NAME, 'showDetails');
                __trackedDetails = true;
            }
            vm.showDetails = !vm.showDetails;
        };

        vm.setRecaptchaWidgetId = function (widgetId) {
            vm.recaptchaWidgetId = widgetId;
        };

        vm.submitSellOrder = function () {
            ppTrack.setContext({
                'sell.shares': vm.invalidShares,
                'sell.price': vm.invalidPrice
            });

            if (!vm.form.$valid) {
                vm.feedbackEnabled = true;
                vm.processing = false;
                ppTrack.ngFormValidation(PAGE_NAME, vm.form);
            } else {
                vm.processing = true;
                var grecaptchaValue = vm.reCaptchaEnabled ? vm.recaptchaResponse : null;
                vm.onSubmit({
                    numberOfShares: vm.shares.value,
                    sharePrice: vm.pPrice.value,
                    grecaptchaValue: grecaptchaValue
                }).catch(function (error) {
                    vm.error = error.reason;

                    // to show banding price limits error message
                    if (error.priceLimits) {
                        vm.priceLimits = error.priceLimits;
                    }

                    if (error.reason === 'secondary.offer.insufficient.holdings') {
                        $timeout(function () {
                            setupSellData(vm.user);
                            updateResults();
                        });
                    }

                    resetRecaptcha();

                    vm.feedbackEnabled = false;
                    vm.processing = false;
                });
            }
        };

        // -- scope bindings

        $scope.$watch('vm.shares.value', function (newValue, oldValue) {
            if ((newValue === oldValue) || vm.loadingError) {
                return;
            }
            updateResults();
        });

        $scope.$watch('vm.invalidPrice', function (value) {
            setPriceLimitMessage(value);
            updateResults();
        });

        investorService.on('investor-updated', function (user) {
            setupSellData(user);
        });

        // -- main
        vm.$onInit = function () {
            setupSellData(vm.user);
        };
    }]
});
})(window, window.angular);