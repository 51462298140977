(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.auth-nav', [
    'pp.widgets-templates',
    'pp.services.investor',
    'pp.widgets.auth-nav-user',
    'pp.widgets.auth-nav-anon',
    'pp.services.core'
]);

ngModule.directive('ppAuthNav', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/auth-nav/auth-nav.tpl.html',
        scope: true,
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$q', 'investorService', function ppAuthNavCtrl($scope, $q, investorService) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

            $q.all({
                investor: investorService.getInvestor(),
                investors: investorService.getUserInvestors()
            }).then(function (res) {
                vm.investor = res.investor;
                vm.investors = res.investors;
                vm.ready = true;
            });

            investorService.on('investor-updated', function (investor) {
                vm.investor = investor;
            });

            investorService.on('user-updated', function (investors) {
                vm.investors = investors;
            });
        }]
    };
});
})(window, window.angular);