(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.isa-usage-card', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.services.route',
    'pp.services.date',
    'pp.values.numbers',
    'pp.widgets.isa-fill-bar'
]);

ngModule.component('ppIsaUsageCard', {
    templateUrl: 'widgets/_angular/isa-usage-card/isa-usage-card.tpl.html',
    bindings: {
        isaAmountUsed: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', 'dateService', 'isaMaxPerYear', function ($scope, routeService, dateService, isaMaxPerYear) {
        var vm = this;

        // -- initial state

        vm.marketplaceIsaPath = routeService.marketplaceIsaFilter;

        vm.investmentMax = isaMaxPerYear;

        vm.taxYear = dateService.getTaxYearFormatted('YYYY', 'YY', '/');

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);