(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.isa-declaration', [
    'pp.widgets-templates',
    'pp.components.input-ni-number',
    'pp.components.checkbox',
    'pp.components.focus-first-error',
    'pp.services.waypoint',
    'pp.services.core',
    'pp.services.route'
]);

var PAGE_NAME = 'isa-declaration';

ngModule.component('ppIsaDeclaration', {
    templateUrl: 'widgets/_angular/isa-declaration/isa-declaration.tpl.html',
    bindings: {
        onSubmit: '&',
        niNumber: '<',
        isProcessing: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', 'waypointService', 'routeService', 'ppTrack', function ($scope, $element, waypointService, routeService, ppTrack) {
        var vm = this;

        // -- initial state

        vm.isa = {
            declaration: false
        };

        vm.isaTermsLink = routeService.isaTermsLink;

        // -- util functions

        function cleanWaypoint() {
            if (vm.waypoint && vm.waypoint.destroy) {
                vm.waypoint.destroy();
            }
        }

        function onScrollToBottom(direction) {
            $scope.$evalAsync(function () {
                vm.unstuck = direction === 'down';
            });
        }

        function setupWaypoint() {
            cleanWaypoint();

            waypointService.stickyBottomAction(
                $element,
                '#waypoint-id-for-declaration',
                '.actions',
                onScrollToBottom,
                50
            ).then(function (waypoint) {
                vm.waypoint = waypoint;
            });
        }

        function handleSuccess() {
            ppTrack.deferAction(PAGE_NAME + '.success');
        }

        function handleError(error) {
            vm.error = error.reason;
            ppTrack.formError(PAGE_NAME, {
                reason: error.reason
            });
        }

        // -- api

        vm.submit = function () {
            vm.error = null;
            if (vm.form.$invalid) {
                vm.feedbackEnabled = true;
                ppTrack.ngFormValidation(PAGE_NAME, vm.form, ['niNumber', 'niValue']);
                return;
            }

            ppTrack.formSubmit(PAGE_NAME);

            vm.onSubmit({
                niNumber: vm.niNumber
            }).then(handleSuccess, handleError);
        };

        // -- lifecycle

        vm.$postLink = function () {
            setupWaypoint();
        };

        vm.$onDestroy = function () {
            ppTrack.pageClose(PAGE_NAME);
        };

        // -- main

        ppTrack.pageLoad(PAGE_NAME);

    }]
});
})(window, window.angular);