(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.action-banner', [
    'ngSanitize',
    'pp.components-templates',
    'pp.widgets-templates',
    'pp.components.toggle-on-off'
]);

ngModule.component('ppActionBanner', {
    templateUrl: 'widgets/_angular/action-banner/action-banner.tpl.html',
    bindings: {
        data: '<',
        isEnabled: '<',
        processing: '<',
        toggleUpdate: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        var data = vm.data || {};
        vm.extendedDetails = data.extendedDetails || data.details;

        // -- util functions

        // -- api
        vm.update = function () {
            vm.isEnabled = !vm.isEnabled;
            vm.toggleUpdate({
                enabled: vm.isEnabled
            });
        };

        vm.toggleDetails = function () {
            vm.showDetails = !vm.showDetails;
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);