(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.expandable-content', ['pp.widgets-templates']);

ngModule.component('ppExpandableContent', {
    templateUrl: 'widgets/_angular/expandable-content/expandable-content.tpl.html',
    transclude: true,
    bindings: {
        heading: '<',
        active: '=?',
        theme: '@',
        onToggle: '&?'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.toggleExpandable = function () {
            vm.active = !vm.active;

            if (vm.onToggle) {
                vm.onToggle({
                    open: vm.active
                });
            }
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);