(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.business-summary-track', [
    'pp.widgets-templates',
    'pp.widgets.auth-signup-cta',
    'pp.services.route'
]);

var TRACK_TITLE = 'Track investments';

ngModule.component('ppBusinessSummaryTrack', {
    templateUrl: 'widgets/_angular/business-summary-track/business-summary-track.tpl.html',
    bindings: {
        user: '<',
        s3Endpoint: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', function ($scope, routeService) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$onInit = function () {
            vm.marketplacePath = routeService.marketplacePath;
            vm.ipadImage = vm.s3Endpoint + '/images/homepage/ipadhomepage-rebrand.png';
            vm.ipadVideoMp4 = vm.s3Endpoint + '/images/homepage/dashboard-ipad.mp4';
            vm.trackTitle = TRACK_TITLE;
            vm.trackImage = vm.s3Endpoint + '/images/homepage/track-mobile.jpg';
        };

        // -- main

    }]
});
})(window, window.angular);