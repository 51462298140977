(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.isa-blocked-retail', [
    'pp.widgets-templates',
    'pp.ui.services.classification-dialog',
    'pp.services.route'
]);

ngModule.component('ppIsaBlockedRetail', {
    templateUrl: 'widgets/_angular/isa-blocked-retail/isa-blocked-retail.tpl.html',
    bindings: {
        investor: '<',
        onReclassification: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'classificationDialogService', 'routeService', function ($scope, classificationDialogService, routeService) {
        var vm = this;

        // -- initial state

        vm.marketplacePath = routeService.marketplacePath;

        // -- util functions

        // -- api

        vm.reclassify = function () {
            classificationDialogService.reclassifyUser(vm.investor)
                .then(vm.onReclassification);
        };

        // -- lifecycle

        // -- main

    }]
});
})(window, window.angular);