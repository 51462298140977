(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.event', []);

ngModule.service('eventService', ['$http', '$q', function ($http, $q) {

    var api = {};

    var API_BASE_PATH = '/feapi/r1';

    var GENERIC_EVENT_ENDPOINT = '/event/generic';

    api.sendGenericEvent = function (eventName, props) {
        var endpoint = API_BASE_PATH + GENERIC_EVENT_ENDPOINT;

        var payload = {
            eventName: eventName,
            props: props
        };

        return $http.post(endpoint, payload).then(function () {
            return true;
        }, function () {
            return $q.reject({
                reason: 'generic-event.error.unexpected'
            });
        });
    };

    return api;
}]);
})(window, window.angular);