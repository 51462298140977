(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.radio-button', ['pp.components-templates']);

ngModule.component('ppRadioButton', {
    templateUrl: 'components/_angular/radio-button/radio-button.tpl.html',
    bindings: {
        title: '@',
        active: '<',
        hasCircle: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);