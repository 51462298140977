(function(window, angular){'use strict';

var ALLOWED_TABS = {
    pending: true,
    current: true,
    listed: true,
    sold: true,
    bids: true
};
var DEFAULT_TAB = 'pending';
var INVESTOR_BETA_ENABLED_PREFERENCE = 'investor.beta.enabled';

var MAP_EVENT_ATTRIBUTES = {
    amountFunded: 'fund.amount',
    cashback: 'cashback',
    cashbackPercentage: 'cashback',
    fees: 'fees',
    investment: 'amount',
    sharePrice: 'sharePrice',
    shares: 'shares',
    total: 'total',
    symbol: 'symbol'
};

var PAGE_NAME = 'portfolio.dashboard';

var ngModule = angular.module('pp.widgets.dashboard', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.property',
    'pp.services.dashboard',
    'pp.values.dashboard',
    'pp.services.receipt',
    'pp.services.apo',
    'pp.services.route',
    'pp.services.marketplace',
    'pp.components.time-left',
    'pp.widgets.action-banner',
    'pp.widgets.performance-graph',
    'pp.widgets.dashboard-figure-box',
    'pp.widgets.isa-usage-card',
    'pp.widgets.isa-transfer-in-card',
    'pp.widgets.recaptcha',
    'pp.widgets.help',
    'pp.widgets.refer-a-friend-banner',
    'pp.widgets.aum-dividend-notice',
    'pp.components.tooltip',
    'pp.components.toggle-on-off',
    'pp.filters.numbers'
]);

ngModule.component('ppDashboard', {
    templateUrl: 'widgets/_angular/dashboard/dashboard.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$window', '$rootScope', '$scope', '$q', '$timeout', '$filter', '$state', 'investorService', 'propertyService', 'preferenceService', 'dashboardService', 'receiptService', 'apoService', 'routeService', 'ppTrack', 'marketplaceService', 'dashboard', 'R', function ($window, $rootScope, $scope, $q, $timeout, $filter, $state, investorService, propertyService, preferenceService, dashboardService, receiptService, apoService, routeService, ppTrack, marketplaceService, dashboard, R) {
        // -- initial state

        var vm = this;

        vm.showGraph = false;

        vm.downloadDashboardPath = routeService.downloadDashboardPath;
        vm.fundPath = routeService.fundPath;
        vm.withdrawPath = routeService.withdrawPath;
        vm.transferPath = routeService.transferFundsPath;

        vm.reinvestDividendsTermsLink = routeService.reinvestDividendsTermsLink;
        vm.reinvestDividendsAbout = routeService.reinvestDividendsAbout;

        vm.portfolioCurrentPropertiesPath = routeService.portfolioCurrentPropertiesPath;

        vm.glossary = dashboard;

        // -- util functions

        function purgeCache() {
            dashboardService.purgeCache('dashboard.graph');
            dashboardService.purgeCache('income-by-month');
            // investor totals promise cache
            investorService.purgeCache('dashboard.graph');
        }

        function handleDashboardSuccess() {
            vm.processingDashboard = false;
        }

        function handleDashboardError(err) {
            console.log(err);
            vm.loadDashboardError = true;
            vm.processingDashboard = false;
        }

        function getIsaAmountUsed() {
            return investorService.getInvestor().then(function (investor) {
                if (investor.isIsa) {
                    return investorService.getIsaAmountUsed();
                }
            });
        }

        function getIncomeByMonth() {
            return investorService.getInvestor()
                .then(dashboardService.getIncomeByMonth);
        }

        function loadDashboardData() {
            var promises = {
                investor: investorService.getInvestor(),
                investors: investorService.getUserInvestors(),
                totals: dashboardService.getInvestmentTotals(),
                graph: dashboardService.getDashboardGraph(),
                apos: apoService.getAdvancedPreOrderForInvestor(),
                apoAmount: apoService.getAdvancedPreOrderAmount(),
                reinvestingDividends: preferenceService.isInvestorReinvestingDividends(),
                incomeByMonth: getIncomeByMonth(),
                currentHoldings: dashboardService.getTotalReturn(),
                soldInvestments: investorService.getHoldingsSold(),
                currentLoansOverview: dashboardService.getCurrentLoansOverview(),
                valuationMethod: preferenceService.getDashboardOverviewValuationMethod(),
                allProperties: marketplaceService.getPropertiesList()
            };

            $q.all({
                investor: investorService.getInvestor()
            }).then(function (res) {
                vm.investor = res.investor;
            });

            getIsaAmountUsed().then(function (isaAmountUsed) {
                vm.isaAmountUsed = isaAmountUsed;
            });

            return $q.all(promises).then(function (data) {

                var investor = data.investor;
                var totals = data.totals;
                vm.graphData = data.graph;
                vm.apos = data.apos;
                vm.apoAmount = data.apoAmount;

                if (R.not(investor.isIsa)) {
                    vm.valuationMethod = data.valuationMethod;
                }

                vm.data = data;

                var hasMoreThanOneInvestor = data.investors.length > 1;
                vm.contributions = dashboardService.normaliseNetContributions(investor, hasMoreThanOneInvestor);
                vm.isIsaSetup = investorService.isIsaSetup(data.investors);

                vm.performance = dashboardService.normalisePerformance(totals, investor, data.soldInvestments.totals);
                vm.portfolio = dashboardService.normalisePortfolio(
                    investor,
                    totals,
                    data.apos,
                    data.apoAmount,
                    data.currentLoansOverview,
                    data.currentHoldings.totals,
                    data.valuationMethod
                );

                vm.numberOfRightsIssueHoldings = data.currentHoldings.collection.filter(function (item) {
                    return R.path(['currentHoldings', 'properties', item.symbol, 'isRightsIssueParent'], data) && R.path(['currentHoldings', 'properties', item.symbol, 'rightsIssueOpen'], data);
                }).length;

                vm.equityFundraisingData = marketplaceService.createEquityFundraiseList(R.defaultTo({}, data.currentHoldings.holdings), marketplaceService.filterRightsIssues(data.allProperties), {});

                vm.totalYourProportionOfEquityFundraises = R.sum(vm.equityFundraisingData.filter(function (item) {
                    return !item.hardCodedFundedPct;
                }).map(function (item) {
                    return item.yourProportionOfRaise;
                }));

                vm.reinvestingDividends = data.reinvestingDividends;

                vm.showGraph = !!vm.graphData[3];

                vm.totals = totals;
                vm.summarydata = totals;
                vm.summarydata.cashback = investor.financials.totalCashback;

                vm.user = investor;
                vm.investor = investor;
                vm.investors = data.investors;
                vm.isIsaSetup = investorService.isIsaSetup(vm.investors);

                vm.incomeByMonth = data.incomeByMonth;
                vm.currentHoldings = data.currentHoldings.totals;
                vm.currentLoansOverview = data.currentLoansOverview;
            });
        }

        function loadDashboard() {
            return loadDashboardData().then(handleDashboardSuccess, handleDashboardError);
        }

        // -- scope api

        vm.setReinvestDividends = function () {
            vm.reinvestingDividendsUpdating = true;

            ppTrack.pageAction(PAGE_NAME, 'reinvest-dividends.toggle', !vm.reinvestingDividends);
            return preferenceService.setReinvestDividends(!vm.reinvestingDividends).then(function () {
                return preferenceService.isInvestorReinvestingDividends();
            }).then(function (reinvestingDividends) {
                vm.reinvestingDividends = reinvestingDividends;
                ppTrack.event(PAGE_NAME + '.reinvest-dividends.toggle.success', {
                    reinvesting: reinvestingDividends
                });
            }, function (error) {
                ppTrack.event(PAGE_NAME + '.reinvest-dividends.toggle.error', {
                    reason: error.reason
                });
            }).finally(function () {
                vm.reinvestingDividendsUpdating = false;
            });
        };

        vm.onUpdateValuationMethod = function (valuationMethod) {

            vm.valuationMethod = valuationMethod;

            vm.portfolio = dashboardService.normalisePortfolio(
                vm.data.investor,
                vm.data.totals,
                vm.data.apos,
                vm.data.apoAmount,
                vm.data.currentLoansOverview,
                vm.data.currentHoldings.totals,
                valuationMethod
            );

            return preferenceService.setDashboardOverviewValuationMethod(vm.valuationMethod);
        };

        vm.onStartKyc = function () {
            $window.location.href = routeService.fundPath;
        };

        // -- scope bindings

        // -- main

        // -- main

        vm.$onInit = function () {
            purgeCache();

            loadDashboard().then(function () {
                ppTrack.pageLoad(PAGE_NAME);
                vm.ready = true;
            });
        };

    }]
});
})(window, window.angular);