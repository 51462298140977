(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-plans-closed-period-warning', [
    'pp.widgets-templates',
    'pp.services.preference',
    'pp.services.dialog',
    'pp.widgets.terms-apply-badge'
]);

ngModule.component('ppInvestmentPlansClosedPeriodWarning', {
    templateUrl: 'widgets/_angular/investment-plans-closed-period-warning/investment-plans-closed-period-warning.tpl.html',
    bindings: {
        onAccept: '&',
        onReject: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'preferenceService', 'dialogService', function ($scope, preferenceService, dialogService) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.accept = function () {
            preferenceService.agreeToInvestmentPlanClosedPeriods().then(function () {
                vm.onAccept();
                $scope.$parent.dialogDefer.resolve();
            }, vm.onReject);
        };

        vm.reject = function () {
            $scope.$parent.dialogDefer.resolve();
            vm.onReject();
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);