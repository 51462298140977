(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.nationality-lei', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.widgets.nationality-list-form',
    'pp.widgets.lei-form',
    'pp.values.regex',
    'pp.widgets.lei-validation-message'
]);

ngModule.directive('ppNationalityLei', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/nationality-lei/nationality-lei.tpl.html',
        scope: {
            onSubmitNationality: '&',
            onSubmitLei: '&',
            user: '<',
            nationalitiesList: '<',
            isProcessing: '<',
            leiValue: '<',
            isLeiExpired: '<',
            identifierError: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', 'ppTrack', 'leiRegex', function ($scope, $timeout, ppTrack, leiRegex) {
            var vm = this;

            // -- initial state

            vm.nationalityData = [{}];

            vm.copy = {
                lei: 'LEI number',
                nationality: 'nationality'
            };

            vm.leiRegex = leiRegex;

            // -- util functions

            function handleFailure(error) {
                vm.processingError = true;
                vm.processing = false;
            }

            // -- api

            vm.submit = function (type) {
                vm.feedbackEnabled = true;
                ppTrack.action('auth-login.' + type + '.submit');
                if (vm.form.$valid) {
                    vm.processing = true;
                    vm.processingError = false;
                    ppTrack.event('auth-login.' + type + '.success');
                    if (type === 'nationality') {
                        vm.onSubmitNationality({
                            nationalityData: vm.nationalityData
                        }).catch(handleFailure);
                    } else if (type === 'lei') {
                        vm.onSubmitLei({
                            lei: vm.lei
                        }).catch(handleFailure);
                    }
                } else {
                    ppTrack.event('auth-login.' + type + '.validation');
                }
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                vm.type = vm.user.kyc.business ? 'lei' : 'nationality';
                if (vm.leiValue) {
                    vm.lei = vm.leiValue;
                }
            };

        }]
    };
}]);
})(window, window.angular);