(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.settings', []);

ngModule.service('settingsService', ['$http', '$q', function ($http, $q) {

    var api = {};

    //settings: primaryEmail, secondaryEmail, phoneNumber

    api.setContactDetails = function (settings) {

        var payload = settings;

        var endpoint = '/feapi/r1/investor/contact-details';

        return $http.post(endpoint, payload).then(
            function () {
                return true;
            },
            function () {
                return $q.reject({
                    reason: 'settings.error'
                });
            }
        );

    };

    return api;

}]);
})(window, window.angular);