(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-docs', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.route'
]);

ngModule.component('ppInvestmentDocs', {
    templateUrl: 'widgets/_angular/investment-docs/investment-docs.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['ppConfig', 'routeService', function (ppConfig, routeService) {
        var vm = this;
        var __config = ppConfig.get('pp.external.endpoints');

        // -- initial state
        vm.s3Endpoint = __config ? __config.assets : null;

        vm.investorTermsAndConditions = routeService.investorTermsAndConditions;
        vm.deedOfAmendment = routeService.deedOfAmendment;

        // -- main
    }]
});
})(window, window.angular);