(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.isa-investment-list', [
    'pp.widgets-templates',
    'pp.widgets.property-card-list',
    'pp.services.marketplace',
    'pp.services.investor'
]);

var ISA_FILTER = 'isa';

ngModule.component('ppIsaInvestmentList', {
    templateUrl: 'widgets/_angular/isa-investment-list/isa-investment-list.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'marketplaceService', 'investorService', function ($scope, marketplaceService, investorService) {
        var vm = this;

        // -- initial state
        vm.isLoading = true;
        vm.loadingCardCount = 4;
        var cardCount = 3;

        // -- util functions

        // -- api

        // -- lifecycle

        // -- main

        marketplaceService.getPropertiesList({
            filter: ISA_FILTER,
            limit: cardCount,
            showPrimaryInformational: true
        }).then(function (properties) {
            vm.properties = [{
                upcomingIsa: true
            }].concat(properties);
            vm.isLoading = false;
        });

        investorService.getInvestor().then(function (user) {
            vm.user = user;
        });

    }]
});
})(window, window.angular);