(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.table', ['pp.components-templates', 'pp.components.table-row', 'pp.components.table-sort', 'pp.components.toggle-tick']);

ngModule.directive('ppTable', [function (track) {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/table/table.tpl.html',
        scope: {
            data: '=ppTable',
            columns: '=',
            idKey: '=',
            sortAttribute: '=',
            sortOrder: '=',
            showToggles: '=',
            selectedItems: '=',
            onSortChange: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', function ($scope, $timeout) {
            var vm = this;

            // -- initial state

            var __showTogglesDelayedTimeout;

            // -- util functions

            function updateSelectAll() {
                var data = vm.data || [];
                for (var ix = 0; ix < data.length; ix++) {
                    var id = data[ix][vm.idKey];
                    if (!vm.selectedItems[id]) {
                        vm.selectedAll = false;
                        return;
                    }
                }
                vm.selectedAll = true;
            }

            // -- scope bindings

            // -- api

            vm.rowClick = function (id) {
                if (vm.showToggles) {
                    vm.selectedItems[id] = !vm.selectedItems[id];
                }
            };

            // update individual items when user clicks on "selectAll" toggle-tick in header row
            vm.selectAllClick = function (selected) {
                var data = vm.data || [];
                for (var ix = 0; ix < data.length; ix++) {
                    var id = data[ix][vm.idKey];
                    vm.selectedItems[id] = selected;
                }
            };

            vm.updateSort = function (attr, order) {
                vm.onSortChange({
                    attr: attr,
                    order: order
                });
            };

            // -- scope bindings

            vm.$onInit = function () {
                $scope.$watch('vm.showToggles', function () {
                    $timeout.cancel(__showTogglesDelayedTimeout);
                    __showTogglesDelayedTimeout = $timeout(function () {
                        vm.showTogglesDelayed = vm.showToggles;
                    });
                });

                $scope.$watch('vm.selectedItems', updateSelectAll, true);
            };

            // -- main

        }]
    };
}]);
})(window, window.angular);