(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.ways-to-invest', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.route',
    'pp.services.config',
    'pp.ui.services.schedule-call-dialog'
]);

ngModule.component('ppWaysToInvest', {
    templateUrl: 'widgets/_angular/ways-to-invest/ways-to-invest.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', 'routeService', 'configService', 'scheduleCallDialogService', function ($scope, ppConfig, routeService, configService, scheduleCallDialogService) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints') || {};
        var s3Endpoint = __config.assets;

        vm.iconHouseOutline = s3Endpoint + '/images/icons/crowdfunding-lp/icon-house-outline.png';
        vm.iconHousesOutline = s3Endpoint + '/images/icons/crowdfunding-lp/icon-houses-outline.png';
        vm.iconHousesStarOutline = s3Endpoint + '/images/icons/crowdfunding-lp/icon-houses-star-outline.png';

        vm.marketplacePath = routeService.marketplacePath;
        vm.investmentPlansPath = routeService.investmentPlansPath;
        vm.premiumServicesPath = routeService.premiumServicesPath;
        configService.minimumInvestmentPlanAmount().then(function (amount) {
            vm.investmentPlanMinAmount = amount;
        });

        // -- util functions

        // -- api

        vm.scheduleACallPremium = function () {
            scheduleCallDialogService.show();
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);