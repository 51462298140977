(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.google-maps', []);

ngModule.service('googleMapsService', ['$q', function ($q) {

    var api = {};

    // Attaches the MapDiv to the new Map Container
    function createContainerDiv(element) {
        // create element that holds the Map
        var mapDiv = document.createElement('div');
        mapDiv.className = 'map-canvas';
        element.appendChild(mapDiv);
        return mapDiv;
    }

    // Create the base map with center
    function createMap(googleMapsApi, element, options) {
        return new googleMapsApi.Map(element, options);
    }

    /**
     * @description
     * Instatiates the Map with markers, options and center
     *
     * @param {object} googleMapsApi
     * @param {object} element
     * @param {string} type
     * @param {object} options
     * @param {object} icon
     * @param {objet} center with latitude and longitude attributes
     * @param {array} of objects with latitude and longitude
     * @returns {object} instance of google.api.Map

     */

    api.renderMap = function (googleMapsApi, element, type, center, locations, options, icon) {

        var centerPoint;

        // Find map_canvas and attach map
        var containerDiv = createContainerDiv(element);

        //set center
        locations = locations || [];
        centerPoint = center || locations[0];
        options.center = new googleMapsApi.LatLng(centerPoint.latitude, centerPoint.longitude);

        // create map
        var map = createMap(googleMapsApi, containerDiv, options, icon);

        // render markers
        for (var ix = 0; ix < locations.length; ix++) {
            new googleMapsApi.Marker({
                position: {
                    lat: locations[ix].latitude,
                    lng: locations[ix].longitude
                },
                map: map,
                icon: icon
            });
        }

        // trigger resize (fixes: problem with map not always rendering)
        googleMapsApi.event.trigger(map, 'resize');

        return map;
    };

    //for map view data object only
    api.renderDeferredMap = function (googleMapsApi, element, type, center, locations, options, icon) {
        var mapsDefer = $q.defer();

        var centerPoint;

        // Find map_canvas and attach map
        var containerDiv = createContainerDiv(element);

        //set center
        locations = locations || [];
        centerPoint = center || locations[0];
        options.center = new googleMapsApi.LatLng(centerPoint.location.geo.latitude, centerPoint.location.geo.longitude);

        // create map
        var promise = mapsDefer.resolve(createMap(googleMapsApi, containerDiv, options, icon));

        // trigger resize (fixes: problem with map not always rendering)

        return mapsDefer.promise;

    };

    return api;
}]);
})(window, window.angular);