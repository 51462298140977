(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.table-row', ['pp.components-templates', 'pp.components.toggle-tick']);

ngModule.directive('ppTableRow', function () {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/table-row/table-row.tpl.html',
        scope: {
            row: '=ppTableRow',
            sortAttribute: '=',
            columns: '=',
            idKey: '=',
            selectedItems: '=',
            showToggles: '='
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {

            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };

});
})(window, window.angular);