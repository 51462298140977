(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.disallowed-value-validation', [
    'pp.services.core'
]);

ngModule.service('disallowedValueService', [function () {
    var api = {};

    function getList(disallowedValues) {
        try {
            if (angular.isArray(JSON.parse(disallowedValues))) {
                return disallowedValues;
            } else {
                return [];
            }
        } catch (err) {
            return [];
        }

    }

    api.isAllowed = function (disallowedList, modelValue) {
        if (!modelValue) {
            return true;
        } else if (getList(disallowedList).indexOf(modelValue) > -1) {
            return false;
        } else {
            return true;
        }
    };

    return api;
}]);

/**
 * @ngdoc directive
 * @name ppExpiryDateValidation
 * @description
 *
 * @restrict A
 * @requires ngModel
 *
 * @param {string} ngModel Assignable angular expression to data-bind to.
 */
ngModule.directive('ppDisallowedValueValidation', ['disallowedValueService', function (disallowedValueService) {

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, $elem, $attrs, $ctrl) {

            $ctrl.$validators.disallowed = function (modelValue, viewValue) {
                return disallowedValueService.isAllowed($attrs.ppDisallowedValueValidation, modelValue);
            };

        },
    };
}]);
})(window, window.angular);