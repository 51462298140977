(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.isa-transfer-in', [
    'pp.widgets-templates',
    'pp.services.isa'
]);

ngModule.config(['$sceDelegateProvider', function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
        'https://s3-eu-west-1.amazonaws.com/goji-static-assets/**'
    ]);
}]);

ngModule.component('ppIsaTransferIn', {
    templateUrl: 'widgets/_angular/isa-transfer-in/isa-transfer-in.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$timeout', 'isaService', function ($scope, $timeout, isaService) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        isaService.getTransferInWidgetDetails().then(function (details) {
            vm.details = details;
            vm.ready = true;

            isaService.loadGojiScripts(details.scriptSrc, details.styleSrc);
        });

    }]
});
})(window, window.angular);