(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.quote', [
    'pp.widgets-templates',
    'ui.router',
    'pp.services.core',
    'pp.services.auth',
    'pp.services.investor',
    'pp.services.property',
    'pp.services.config',
    'pp.services.nationality',
    'pp.services.receipt',
    'pp.services.card-payment',
    'pp.services.suitability',
    'pp.services.route',
    'pp.services.preference',
    'pp.services.investor-compliance',
    'pp.ui.services.nationality-lei-dialog',
    'pp.ui.services.classification-dialog',
    'pp.ui.services.authenticate-investor',
    'pp.ui.services.invest-error',
    'pp.ui.services.terms-dialog',
    'pp.ui.services.restricted-investor-dialog',
    'pp.ui.services.suitability-quiz-dialog',
    'pp.ui.services.risk-warning',
    'pp.ui.services.investment-permission-dialog',
    'pp.widgets.faqs-box',
    'pp.widgets.quote-form',
    'pp.widgets.shares-order-book-chart',
    'pp.widgets.shares-traded-chart',
    'pp.widgets.property-details-address',
    'pp.widgets.help',
    'pp.widgets.property-info-tags',
    'pp.widgets.investor-context-switcher',
    'pp.widgets.recaptcha',
    'pp.widgets.property-notice-banner',
    'pp.widgets.bid',
    'pp.widgets.exit-mechanic-trade-warning',
    'pp.components.checkbox',
    'pp.components.focus-first-error',
    'pp.components.tabs',
    'pp.values.glossary',
    'pp.values.numbers',
    'pp.filters.numbers'
]);

var INVESTMENT_DOCS_TYPE = 'investment-articles';

var MARKET_ORDER_TYPE = 'marketOrder';
var BID_ORDER_TYPE = 'bidOrder';
var DEFAULT_ORDER_TYPE = BID_ORDER_TYPE;

var PAGE_NAME = 'quote';
var MAP_EVENT_ATTRIBUTES = {
    amountFunded: 'fund.amount',
    cashback: 'invest.cashback',
    cashbackPercentage: 'invest.cashback',
    fees: 'invest.fees',
    investment: 'invest.amount',
    sharePrice: 'invest.sharePrice',
    shares: 'invest.shares',
    total: 'invest.total',
    investmentId: 'invest.investmentId'
};

ngModule.component('ppQuote', {
    templateUrl: 'widgets/_angular/quote/quote.tpl.html',
    bindings: {
        onContinue: '&?',
        property: '<',
        removeLayout: '<',
        alwaysToggleGraphs: '<',
        onBidPlaced: '&?',
        onBuyComplete: '&?',
        isSidebar: '<',
        escapeUser: '&'
    },
    controllerAs: 'vm',
    controller: ['$rootScope', '$scope', '$q', '$window', '$timeout', '$attrs', '$state', 'ppConfig', 'ppPubSub', 'ppTrack', 'logService', 'authService', 'propertyService', 'receiptService', 'cardPaymentService', 'investorService', 'configService', 'nationalityService', 'nationalityLeiDialogService', 'dialogService', 'termsDialogService', 'investErrorService', 'glossary', 'ppUtil', 'authenticateInvestor', 'classificationDialogService', 'restrictedInvestorDialog', 'isaMaxPerYear', 'suitabilityQuizDialog', 'routeService', 'riskWarningService', 'preferenceService', 'R', 'investorComplianceService', 'investmentPermissionDialog', function ($rootScope, $scope, $q, $window, $timeout, $attrs, $state, ppConfig, ppPubSub, ppTrack, logService, authService, propertyService, receiptService, cardPaymentService, investorService, configService, nationalityService, nationalityLeiDialogService, dialogService, termsDialogService, investErrorService, glossary, ppUtil, authenticateInvestor, classificationDialogService, restrictedInvestorDialog, isaMaxPerYear, suitabilityQuizDialog, routeService, riskWarningService, preferenceService, R, investorComplianceService, investmentPermissionDialog) {
        var vm = this;

        // -- initial state

        var DASHBOARD_URL = routeService.dashboardPath;
        var INVESTMENT_BUY_SUCCESS_PATH_SECONDARY = routeService.portfolioCurrentPropertiesPath;
        var INVESTMENT_BUY_SUCCESS_PATH_PRIMARY = routeService.portfolioPendingPropertiesPath;
        var INVESTMENT_BUY_SUCCESS_PATH_PRIMARY_LOAN = routeService.portfolioPendingLoansPath;

        vm.rightsIssueKnowledgebaseLink = routeService.rightsIssueKnowledgebaseLink;
        vm.imHomeNow = routeService.imHomeNow;
        vm.kidHomeNow = routeService.kidHomeNow;

        var symbol;

        // will become true if the user performs a login/signup
        // @todo post-conversion-phase-2 discard this once app/ui state refreshes on the fly after a login (including header)
        var __refreshRequired = false;
        var __cardPaymentTransactionCache;

        vm.quoteEnabled = false; // not until we established the user's state
        vm.quoteEditable = false; // not until we established if there are any shares available
        vm.showCharts = true;
        vm.form = {};

        vm.memorandumReadUnderstood = false;

        var __authConfig = ppConfig.get('pp.modules.auth') || {};

        var __endpointConfig = ppConfig.get('pp.external.endpoints') || {};

        var assetsPath = __endpointConfig.assets;

        vm.reCaptchaKey = __authConfig.reCaptchaKey;

        // focus quote amount on load
        vm.focusQuoteOn = 'auto';

        vm.minimumInvestment = 0;

        vm.isaMaxPerYear = isaMaxPerYear;

        var sessionExpired = $window.location.search.indexOf('e=t') > -1;

        // -- util functions

        function redirect(href) {
            ppTrack.closeAll().then(function () {
                $window.location.href = href;
            });
        }

        function redirectBlockedUser() {
            ppTrack.pageEvent(PAGE_NAME, 'requiredPermissions');
            if (vm.isSidebar) {
                vm.escapeUser();
            } else {
                $state.go('property');
            }

        }

        function reload() {
            return ppTrack.closeAll().then(function () {
                $window.location.reload();
            });
        }

        // returns a promise resolved when the investor is loaded
        function loadUser() {
            return investorService.getInvestor().then(function (user) {
                vm.user = user;
                return user;
            });
        }

        function loadInvestorUnits() {
            if (!vm.user.anon) {
                return investorService.getInvestorPropertyUnits(propertyService.isPrimaryProperty(vm.property), symbol);
            } else {
                return $q.when(0);
            }
        }

        function initializeQuote(defaultQuote, feeRate, taxRate) {
            var quote = {};

            if (!investorService.isUserAllowedToQuote(vm.user, propertyService.isPrimaryProperty(vm.property))) {
                quote = angular.copy(defaultQuote);
                quote.error = 'quote.error.permissions';
                return $q.when(quote);
            }
            if (propertyService.isPrimaryProperty(vm.property)) {
                return $q.when({
                    budget: defaultQuote.budget
                });
            } else {
                return propertyService.getSecondaryQuote(vm.property, defaultQuote.budget, feeRate, taxRate).then(function (quote) {
                    var totalCostPlusOneShare = quote.totalCost + quote.weightedAveragePrice * (1 + feeRate + taxRate);
                    if (totalCostPlusOneShare < defaultQuote.budget) {
                        quote.budget = quote.totalCost;
                    }
                    return $q.when(quote);
                }, function (error) {
                    quote = angular.copy(defaultQuote);
                    quote.error = error.reason;
                    return $q.when(quote);
                });
            }
        }

        function onLogout() {
            $window.location.href = '/properties/' + symbol + '#/quote?amount=' + vm.quote.budget + '&e=t';
        }

        function goToFundAccount() {
            $window.location.href = routeService.fundPath;
        }

        function classifyUserOnRestrictedInvestments(user) {

            if ((!vm.property.isDevelopmentLoan && !vm.property.isHighRisk) || vm.property.isMortgage) {
                return $q.when(user);
            }

            return classificationDialogService.classifyUser(user, redirectBlockedUser);
        }

        function reclassifyUserOnRestricetedInvestment(user) {
            if ((!vm.property.isDevelopmentLoan && !vm.property.isHighRisk) || vm.property.isMortgage) {
                return $q.when(user);
            }

            if (vm.property.isDevelopmentLoan) {
                return restrictedInvestorDialog.reclassifyUserForBond(user, vm.property);
            } else {
                return restrictedInvestorDialog.reclassifyUserForRestrictedInvestment(user);
            }
        }

        function testUserSuitability(property, user) {
            if (property.isFund && property.isPrimary) {
                return suitabilityQuizDialog.showForPassAnyQuizzes(['fund_1', 'fund_2'], !!vm.isSidebar, user.classification);
            } else if (property.isMortgage) {
                return suitabilityQuizDialog.showForPassAnyQuizzes(['mortgage_1', 'mortgage_2'], !!vm.isSidebar);
            } else if (property.isDebtFund) {
                return suitabilityQuizDialog.show('debt-fund', user.classification, !!vm.isSidebar);
            } else {
                return $q.when();
            }
        }

        // don't track submit when refresh is required after classification is dismissed
        function submit(trackSubmit) {
            propertyService.storeQuote(symbol, vm.quote);

            if (trackSubmit) {
                ppTrack.formSubmit('quote');
            }

            loadUser().then(function () {
                ppTrack.closeAll().then(function () {
                    if (vm.shouldBuyImmediately()) {
                        $state.reload();
                    } else {
                        if (vm.isSidebar) {
                            vm.onContinue();
                        } else {
                            goToFundAccount();
                        }
                    }
                });
            });
        }

        function handleSubmit() {
            return authenticateInvestor().then(function (user) {
                if (!user.anon) {
                    submit(true);
                    vm.quoteSubmittable = false;
                    vm.quoteEditable = false;
                    vm.feedbackEnabled = false;
                }
            });
        }

        function handleLoad(user, property) {
            return classifyUserOnRestrictedInvestments(user)
                .then(reclassifyUserOnRestricetedInvestment)
                .then(testUserSuitability.bind(null, property, user))
                .catch(function (err) {
                    redirectBlockedUser();
                    return $q.reject();
                });
        }

        function onAuthCloseCallback(property) {
            ppTrack.formStart('quote', null, true, true);
            ppTrack.pageAction(PAGE_NAME, 'authenticationDismissed');
            nationalityLeiDialogService.close();
            if (!propertyService.isPrimaryProperty(property) || property.isBlockTrade) {
                redirectBlockedUser();
            }
        }

        function setTrackingContext(property) {
            ppTrack.setContext({
                'property.symbol': property.symbol,
                'offer.status': property.state.status
            });

            if (propertyService.isPreorderProperty(property)) {
                ppTrack.setContext('offer.preorder', true);
            }

            if (propertyService.isPrimaryOfferedProperty(property)) {
                ppTrack.setContext('offer.percentFunded', propertyService.getPropertySharesAvailablePct(property));
            }
        }

        function onNationalityLeiSuccess() {
            investorService.purgeCache('investor$');
            nationalityLeiDialogService.close();
        }

        function showNationalityLeiDialog(user, property) {
            return nationalityService.getNationalityList().then(function (nationalityList) {
                return nationalityLeiDialogService.show(user, nationalityList, onNationalityLeiSuccess, onAuthCloseCallback.bind(this, property));
            });
        }

        function createUserPromise(property) {
            if (property.isMortgage) {
                return authenticateInvestor;
            }
            return propertyService.isPrimaryProperty(property) ? loadUser : authenticateInvestor;
        }

        function doesInvestorOwnSharesInEquityProperty(property) {
            var equityPropertySymbol = property.equityPropertySymbol;
            return investorService.getInvestorPropertyUnits(false, equityPropertySymbol).then(function (shares) {
                if (!!shares && shares > 0) {
                    return;
                } else {
                    return investmentPermissionDialog.showMortgageBlockedNoShares(property);
                }
            });
        }

        function checkPermissions(initialLoad) {

            return propertyService.getPropertyData(symbol).then(function (property) {
                    var promises = $q.all({
                        user: createUserPromise(property)(),
                        property: $q.when(property)
                    });
                    if (property.isMortgage && initialLoad) {
                        return promises.then(function (res) {
                            return $q.all({
                                user: investorService.escapeFromIsa(res.user),
                                property: $q.when(property)
                            });
                        });
                    } else {
                        return promises;
                    }
                })
                .then(function (responses) {
                    var property = responses.property || {};
                    if (R.path(['property', 'isMortgage'], responses) && initialLoad && (property.isFundraisingForShareholdersOnly || investorService.isRestrictedInvestor(responses.user))) {
                        return doesInvestorOwnSharesInEquityProperty(property).then(
                            function () {
                                return responses;
                            },
                            function () {
                                redirectBlockedUser();
                                return $q.reject();
                            });
                    } else {
                        return responses;
                    }
                })
                .then(function (responses) {
                    var compliancePromise = responses.user.kyc.business ? investorComplianceService.isLeiSet() : nationalityService.isNationalitySet();
                    return $q.all({
                        user: $q.when(responses.user),
                        property: $q.when(responses.property),
                        isIdentifierRequired: compliancePromise.then(R.equals(false))
                    });
                })
                .then(function (responses) {
                    var user = responses.user;

                    var property = responses.property;
                    if (!user.anon && ((!user.permissions.canInvestSecondary && user.flags.identifiersRequired) || responses.isIdentifierRequired) && user.kyc.status && (!propertyService.isPrimaryProperty(property) || property.isBlockTrade)) {
                        return showNationalityLeiDialog(user, property);
                    }
                });
        }

        function isQuoteValid(property, quote) {
            var isPrimaryValid = (propertyService.isPrimaryProperty(property) && property.isRightsIssue) || (propertyService.isPrimaryProperty(property) && property.isPreorder) || (propertyService.isPrimaryProperty(property) && propertyService.hasPrimarySharesAvailable(property));
            var isSecondaryValid = propertyService.isSecondaryProperty(property) && !quote.error;
            return isPrimaryValid || isSecondaryValid;
        }

        function init(property) {
            var propertyPromise = property ? $q.when(property) : propertyService.getPropertyData(symbol);

            return propertyPromise.then(function (property) {
                    var defaultBudgetPromise = property.isFundingRound ? preferenceService.getDefaultQuoteFundingRound() : configService.getDefaultBudget();

                    var promises = {
                        property: $q.when(property),
                        user: loadUser(),
                        investors: investorService.getUserInvestors(),
                        minimumFundingManual: configService.getFundingManualMinimum(),
                        rates: configService.getRates(),
                        defaultBudget: defaultBudgetPromise,
                        minimumPreorderAmount: configService.getMinimumPreorderAmount(),
                        maxOwnershipPerProperty: configService.getMaxOwnershipPerProperty(),
                        maximumFunding: investorService.getInvestorMaximumFundingLimit()
                    };

                    return $q.all(promises);
                })
                .then(function (data) {
                    vm.property = data.property;
                    vm.maximumFunding = data.maximumFunding;
                    vm.isPrimaryProperty = propertyService.isPrimaryProperty(vm.property);
                    vm.isPreorderProperty = propertyService.isPreorderProperty(vm.property);
                    vm.isPreviewProperty = propertyService.isPreviewProperty(vm.property);
                    vm.isPreorderClosedProperty = propertyService.isPreorderClosedProperty(vm.property);
                    vm.reCaptchaEnabled = __authConfig.reCaptchaEnabled && vm.property && !vm.isPrimaryProperty;

                    vm.orderType = R.path(['isSecondaryTrading'], vm.property) ? DEFAULT_ORDER_TYPE : MARKET_ORDER_TYPE;

                    var user = data.user;
                    vm.user = user;
                    var funds = ppUtil.hasPathIn(vm.user, 'financials.funds') ? vm.user.financials.funds : undefined;
                    vm.isaFull = !funds && !vm.maximumFunding && vm.user.isIsa;

                    vm.showContextSwitch = investorService.isIsaSetup(data.investors) && vm.property.isDevelopmentLoan;

                    vm.minimumTransaction = data.minimumFundingManual;

                    vm.maxOwnershipPerProperty = vm.property.isDevelopmentLoan ? 1 : data.maxOwnershipPerProperty;

                    vm.defaultBudget = data.defaultBudget;

                    var defaultQuote = propertyService.retrieveQuote(symbol) || {
                        budget: data.defaultBudget,
                        error: 'quote.error.unexpected'
                    };

                    var rates = propertyService.getRatesForProperty(data.rates, vm.isPrimaryProperty);
                    vm.taxRate = rates.taxRate;
                    vm.feeRate = rates.feeRate;

                    if (vm.property.isDevelopmentLoan && !vm.property.notMiniBond) {
                        riskWarningService.showDevLoanRiskWarning();
                    }

                    if (vm.property.isFundingRound) {
                        riskWarningService.showFundRaisingRiskWarning();
                    }

                    if ((vm.property.isDevelopmentLoan && !vm.property.isMortgage) || vm.property.isFundingRound) {
                        vm.termsUrl = assetsPath + '/images/properties/' + vm.property.symbol + '/' + 'investor_terms_' + vm.property.symbol + '.pdf';
                    } else {
                        vm.termsUrl = routeService.investorTermsAndConditions;
                    }

                    if (vm.property.isMortgage) {
                        vm.bondTermsUrl = routeService.mortgageBondTerms;
                    }

                    if (vm.isPreorderProperty) {
                        vm.minimumInvestment = data.minimumPreorderAmount;
                    }

                    if (vm.property.minInvestment) {
                        vm.minimumInvestment = vm.property.minInvestment;
                    }

                    setTrackingContext(vm.property);

                    return loadInvestorUnits().then(function (units) {
                        vm.userUnits = units;

                        authService.onLogout(onLogout);

                        return initializeQuote(defaultQuote, vm.feeRate, vm.taxRate, {}).then(function (quote) {
                            vm.quote = quote;

                            ppTrack.pageLoad(PAGE_NAME, null, true);
                            // track submit but do not generate an event now
                            ppTrack.formStart('quote', null, true, true);

                            var quoteValid = isQuoteValid(vm.property, quote);

                            if (!propertyService.isPropertyInvestmentActive(vm.property)) {
                                vm.quoteEnabled = false;
                                vm.quoteEditable = false;
                                vm.quoteSubmittable = false;
                                vm.feedbackEnabled = true;
                                vm.supressLimitValidation = true;
                                // quote is always enabled in primary, unless user can not invest
                            } else if (investorService.isUserAllowedToQuote(user, vm.isPrimaryProperty)) {
                                vm.quoteEnabled = true;
                                vm.quoteEditable = quoteValid || vm.quote.error === 'secondary.generate.quote.holding.exceeded';
                                vm.quoteSubmittable = quoteValid || vm.quote.error === 'secondary.generate.quote.holding.exceeded';
                                vm.feedbackEnabled = !quoteValid;
                            }
                            // for primary quotes or secondary quotes (as long as user is logged in and classified)
                            // they can't submit the quote, but they can always play with it
                            // let's turn feedback on by default, but suppress funding limit validation
                            else if (vm.isPrimaryProperty || !user.anon && user.classification) {
                                vm.quoteEnabled = true;
                                vm.quoteEditable = quoteValid;
                                vm.quoteSubmittable = false;
                                vm.feedbackEnabled = true;
                                vm.supressLimitValidation = true;
                            }

                            return handleLoad(user, vm.property);
                        });
                    });
                })
                .then(function (res) {
                    return investorService.escapeFromIsaForNonIsaInvestments(vm.user, vm.property);
                })
                .then(function (res) {
                    vm.ready = true;
                });
        }

        function fetchPropertyData() {
            propertyService.purgeCache(symbol + '.*');

            return propertyService.getPropertyData(symbol).then(function (property) {
                vm.property = property;
                return property;
            });
        }

        function onClassificationSuccess() {
            investorService.purgeCache('investor$');
            loadUser().then(classificationDialogService.close);
        }

        function onClassificationFail() {
            redirect(DASHBOARD_URL);
        }

        function onClassificationDismiss(quizPassed) {
            if (quizPassed) {
                investorService.purgeCache('investor$');
                loadUser();
            }
        }

        function getSuccessPath(property) {
            if (propertyService.isPrimaryProperty(property)) {
                return property.isDevelopmentLoan ? INVESTMENT_BUY_SUCCESS_PATH_PRIMARY_LOAN : INVESTMENT_BUY_SUCCESS_PATH_PRIMARY;
            } else {
                return INVESTMENT_BUY_SUCCESS_PATH_SECONDARY;
            }
        }

        /**
         * performs checks before submitting the transaction
         * on primary, checks
         * on secondary, acquires a new quote and passes if it is same or cheaper than the current one
         */
        function preTransaction() {
            if (!investorService.isClassifiedUser(vm.user)) {
                return $q.reject({
                    reason: 'investor.not.classified'
                });
            } else if (propertyService.isPreorderProperty(vm.property)) {
                return $q.when();
            } else if (propertyService.isPrimaryProperty(vm.property)) {
                return propertyService.checkPrimaryAvailableShares(symbol, vm.quote.shares);
            } else {
                return propertyService.getSecondaryReQuote(vm.property, vm.quote.budget, vm.feeRate, vm.taxRate, vm.quote.totalCost, vm.quote.weightedAveragePrice);
            }
        }

        function createReceipt(data) {
            var d = data;
            d.availableFunds = vm.user.availableFunds;

            if (!vm.property || !vm.property.symbol) {
                return $q.when(d);
            }

            return propertyService.getPropertyStatic(vm.property.symbol).then(function (property) {
                d.property = property;
                return d;
            });
        }

        function handleMakePaymentSuccess(data) {
            return createReceipt(data).then(function (d) {
                propertyService.deleteQuote(vm.property.symbol);
                investorService.purgeCache('investor.holdings.current');

                for (var key in data) {
                    if (MAP_EVENT_ATTRIBUTES[key]) {
                        ppTrack.setContext(MAP_EVENT_ATTRIBUTES[key], data[key]);
                    }
                }

                if (vm.isSidebar) {
                    ppTrack.event('invest.success');
                    vm.onBuyComplete({
                        receipt: d
                    });
                } else {
                    receiptService.pushReceipt(d);
                    ppTrack.deferEvent('invest.success');
                    return ppTrack.closeAll().then(function () {
                        var successPath = getSuccessPath(vm.property);
                        window.location = successPath;
                    });
                }

            });
        }

        function purgeCardSubmitCache() {
            __cardPaymentTransactionCache = null;
        }

        function resetRecaptcha() {
            $scope.$broadcast('recaptcha.reload');
        }

        function reEnableForm(oldQuoteState) {
            vm.quoteSubmittable = oldQuoteState.submittable;
            vm.quoteEditable = oldQuoteState.editable;
            vm.processing = false;
        }

        function handleMakePaymentRejection(error) {
            if (vm.reCaptchaEnabled) {
                resetRecaptcha();
            }

            purgeCardSubmitCache();
            var code = error.code;
            return investErrorService.handleTransactionFail(code, vm.quote, vm.user, propertyService.getSharesAvailablePrimary(vm.property), false).then(function () {
                switch (code) {
                case 'reserve.failure.no.units':
                    ppTrack.pageError(PAGE_NAME, 'transactionFailed', code);
                    break;
                case 'reserve.failure.no.funds':
                    ppTrack.pageError(PAGE_NAME, 'transactionFailed', code);
                    return reload();
                case 'update.failed':
                case 'reserve.failure.account':
                case 'reserve.failure.pep':
                case 'reserve.failure.prohibited':
                case 'reserve.failure':
                case 'reserve.prohibited.holding.exceeded':
                    ppTrack.pageException(PAGE_NAME, 'transactionFailed', code);
                    logService.exception(code);
                    return reload();
                case 'requote.required':
                    ppTrack.pageError(PAGE_NAME, 'transactionFailed', code);
                    break;
                case 'secondary.buy.insufficient.funds':
                case 'secondary.buy.investor-not-allowed':
                    ppTrack.pageError(PAGE_NAME, 'transactionFailed', code);
                    return reload();
                case 'secondary.buy.quote.not.found':
                case 'secondary.buy.pep':
                case 'secondary.buy.prohibited':
                case 'secondary.buy.failed':
                case 'secondary.buy.holding.exceeded':
                    ppTrack.pageException(PAGE_NAME, 'transactionFailed', code);
                    logService.exception(code);
                    return reload();

                case 'error.unexpected':
                case 'pp.payment-process.error.parse-response':
                    /*jshint -W086*/
                default:
                    redirect(DASHBOARD_URL);
                }
            });
        }

        function handlePreTransactionPass(result) {
            var params = {
                mustFund: false,
                acceptedTermsAndConditions: vm.agreeToInvestmentDocs,
                isAnniversaryBlockListing: vm.property.isFundingForBlockListing
            };

            if (!propertyService.isPrimaryProperty(vm.property)) {
                params.cheaperQuoteReference = result;
            }

            // to protect against double submission of payment unlocked using the purgeCardSubmitCache() function
            if (!__cardPaymentTransactionCache) {
                cardPaymentService.prepareInvestTransaction(params, vm.property, vm.quote, vm.recaptchaResponse);
                var endpoint = cardPaymentService.constructPaymentEndpoint(propertyService.isPrimaryProperty(vm.property), symbol);
                var payload = cardPaymentService.constructPaymentPayload(params);
                __cardPaymentTransactionCache = cardPaymentService.makePayment(endpoint, payload).then(handleMakePaymentSuccess, handleMakePaymentRejection);
            }

            return __cardPaymentTransactionCache;
        }

        function handlePreTransactionFail(error) {

            var reason = error && error.reason || error || 'pp.invest.unexepectedError';
            var handleError = investErrorService.handlePreTransactionFail.bind(undefined, error, symbol, vm.quote, vm.user);

            switch (reason) {
            case 'investor.not.classified':
                ppTrack.pageError(PAGE_NAME, 'transactionCancelled', reason);
                classificationDialogService.show(vm.user, onClassificationFail, onClassificationSuccess, onClassificationDismiss);
                break;
            case 'primary.shares.unavailable':
            case 'primary.shares.partially-available':
                ppTrack.pageError(PAGE_NAME, 'transactionCancelled', reason);
                return fetchPropertyData().then(function (property) {
                    ppTrack.setContext('offer.sharesAvailable', propertyService.getSharesAvailablePrimary(property));
                    // arguments for the dialog - !postTransaction, fundsUpdated, sharesUpdated
                    return handleError(propertyService.getSharesAvailablePrimary(property)).then(function () {
                        reload();
                    });
                });
            case 'property.currently.not.tradable':
                ppTrack.pageException(PAGE_NAME, 'transactionCancelled', reason);
                return handleError(propertyService.getSharesAvailablePrimary(vm.property)).then(function () {
                    reload();
                });
            case 'secondary.requote.expired':
                ppTrack.pageError(PAGE_NAME, 'transactionCancelled', reason);
                return handleError(propertyService.getSharesAvailablePrimary(vm.property)).then(function () {
                    reload();
                });

            default:
                vm.error = true;
                ppTrack.pageException(PAGE_NAME, 'transactionCancelled', reason);
                return handleError(propertyService.getSharesAvailablePrimary(vm.property));
            }

        }

        function buy() {
            var oldQuoteState = {
                submittable: vm.quoteSubmittable,
                editable: vm.quoteEditable
            };

            vm.processing = true;
            vm.quoteSubmittable = false;
            vm.quoteEditable = false;

            var reEnableFormWithOldState = reEnableForm.bind(null, oldQuoteState);
            preTransaction().then(
                    handlePreTransactionPass,
                    function (error) {
                        reEnableFormWithOldState();
                        handlePreTransactionFail(error);
                    })
                .catch(reEnableFormWithOldState);
        }

        function onInvestorUpdate(user) {
            vm.user = user;

            if (vm.orderType !== MARKET_ORDER_TYPE) {
                return; //@note early return
            }

            checkPermissions()
                .then(init.bind(null, vm.property));
        }

        // -- api

        vm.changeOrderType = function (orderType) {

            if (vm.orderType === orderType) {
                return; // @note early return
            }

            vm.orderType = orderType;
            vm.ready = false;
            var defaultQuote = propertyService.retrieveQuote(symbol) || {
                budget: vm.defaultBudget,
                error: 'quote.error.unexpected'
            };

            return initializeQuote(defaultQuote, vm.feeRate, vm.taxRate, {})
                .then(function (quote) {
                    vm.quote = quote;
                    vm.ready = true;
                });
        };

        vm.bidPlaced = function (receipt) {
            vm.onBidPlaced({
                receipt: receipt
            });
        };

        vm.switchContext = function (kind) {
            ppTrack.action(PAGE_NAME + '.investor-switch.' + kind);
            vm.ready = false;
            return investorService.switchToInvestorByKind(kind);
        };

        vm.shouldBuyImmediately = function () {
            var funds = ppUtil.hasPathIn(vm.user, 'financials.funds') ? vm.user.financials.funds : undefined;
            var totalCost = ppUtil.hasPathIn(vm.quote, 'totalCost') ? vm.quote.totalCost : undefined;
            return vm.quoteValid && funds >= totalCost;
        };

        vm.onSubmit = function () {
            if (!vm.quoteEnabled || !vm.quoteSubmittable || vm.quote.processing) {
                return; //@note early return
            }

            vm.feedbackEnabled = true;
            if (vm.form.$valid && vm.quoteValid) {
                if (vm.shouldBuyImmediately()) {
                    buy();
                } else {
                    handleSubmit();
                }
            } else {
                var trackData = {
                    budget: vm.quote.budget
                };
                var trackErrors = {
                    budget: vm.quoteInvalidReason
                };
                ppTrack.formValidation('quote', trackData, vm.quoteInvalidReason ? trackErrors : null);
            }
        };

        vm.showTermsAndConditions = function () {
            var spv = vm.property.spv || {};

            var data = {
                spvName: spv.name,
                shareholdersDate: spv.shareholdersDate,
                spvNumber: spv.number,
                spvOffice: spv.officeAddress,
                articlesDate: spv.articlesDate
            };

            ppTrack.pageAction(PAGE_NAME, 'terms-and-conditions.show');
            termsDialogService.show(INVESTMENT_DOCS_TYPE, data);
        };

        // acquire the quote validity
        vm.onQuoteValidation = function (valid, reason) {
            vm.quoteValid = valid;
            vm.quoteInvalidReason = reason; // used for tracking (see below)
        };

        vm.onTabChange = function (tab) {
            if (tab === 'buy') {
                checkPermissions()
                    .then(init.bind(null, vm.property));
            }
        };

        vm.setBudgetToAvailableFunds = function () {
            ppTrack.action('quote.set-budget-to-available-funds');
            $scope.$broadcast('pp.quote.updateAmount', {
                amount: vm.user.financials.funds
            });
        };

        // -- scope bindings

        vm.$onDestroy = function () {
            investorService.off('investor-updated', onInvestorUpdate);
        };

        // -- main

        vm.$onInit = function () {
            symbol = ppUtil.path(vm, 'property.symbol') || ppUtil.getDataFromUrl($window.location.href, 'properties');

            vm.propertyKeyRisksSection = routeService.getIndividualPropertyPath(symbol) + routeService.propertyKeyRisksUIPath;

            checkPermissions(true)
                .then(init.bind(null, vm.property));

            investorService.on('investor-updated', onInvestorUpdate);
        };

    }]
});
})(window, window.angular);