(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.bid-card', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.filters.numbers',
    'pp.services.investor'
]);

ngModule.component('ppBidCard', {
    templateUrl: 'widgets/_angular/bid-card/bid-card.tpl.html',
    bindings: {
        bid: '<',
        onCancel: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', 'investorService', function ($scope, ppTrack, investorService) {
        var vm = this;

        // -- initial state

        vm.step = 'initial';

        // -- util functions

        // -- api

        vm.startCancel = function (symbol) {
            ppTrack.action('bid-card.start-cancel', {
                symbol: symbol
            });

            vm.step = 'confirm';
        };

        vm.stopCancel = function (symbol) {

            ppTrack.action('bid-card.stop-cancel', {
                symbol: symbol
            });

            vm.step = 'initial';
        };

        vm.confirmCancel = function (symbol, bidId) {
            vm.processing = true;

            ppTrack.action('bid-card.confirm-cancel.submit', {
                symbol: symbol
            });

            investorService.cancelBidOrder(symbol, bidId, null).then(function () {
                vm.onCancel();

                ppTrack.event('bid-card.confirm-cancel.success', {
                    symbol: symbol
                });
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);