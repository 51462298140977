(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.nationality-list-form', ['pp.widgets-templates', 'pp.widgets.nationality-form']);

ngModule.directive('ppNationalityListForm', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/nationality-list-form/nationality-list-form.tpl.html',
        scope: {
            nationalityData: '=ppNationalityListForm',
            nationalitiesList: '<',
            isDisabled: '<',
            feedbackEnabled: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', 'ppComponentEmitter', function ($scope, $element, ppComponentEmitter) {
            var vm = this;
            vm.nationalityData = vm.nationalityData || [{}];

            var userIdentifierValuesList;

            // -- initial state

            // -- util functions

            // -- api

            vm.addNationality = function () {
                vm.nationalityData.push({});
            };

            vm.removeNationalityAtIndex = function (index) {
                vm.nationalityData.splice(index, 1);
            };

            // -- scope bindings

            // -- main

            vm.$postLink = function () {
                ppComponentEmitter.emit('nationality-list-form.dom.loaded', $element);
            };

            vm.$onInit = function () {
                userIdentifierValuesList = angular.copy(vm.nationalityData.identifiers);
                ppComponentEmitter.emit('nationality-list-form.controller.loaded', vm);
            };

        }]
    };
}]);
})(window, window.angular);