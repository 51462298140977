(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.input-card-expiry-date', [
    'pp.components-templates',
    'pp.components.auto-tab',
    'pp.components.auto-tab-reverse-delete',
    'pp.components.month-validation',
    'pp.components.expiry-date-validation',
    'pp.services.core'
]);

ngModule.component('ppInputCardExpiryDate', {
    templateUrl: 'components/_angular/input-card-expiry-date/input-card-expiry-date.tpl.html',
    bindings: {
        feedbackEnabled: '<',
        isSubmitted: '<',
        ppId: '<',
        isRequired: '<',
        expiryMonth: '=',
        expiryYear: '='
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppMoment', function ($scope, ppMoment) {
        var vm = this;

        // -- initial state

        vm.form = {};

        vm.futureYear = ppMoment().add(2, 'years').format('YY');

        vm.yearRegex = /[0-9]{2}/;

        // -- util functions

        function invalidDate() {
            return vm.form.expiryDate.$error.invalidDate;
        }

        function invalidMonth() {
            return vm.form.month.$error.invalidMonth;
        }

        function invalidYear() {
            return vm.form.year.$invalid;
        }

        function monthRequired() {
            return vm.form.month.$error.required;
        }

        function yearRequired() {
            return vm.form.year.$error.required;
        }

        function monthFilled() {
            return !monthRequired() && !vm.form.month.$error.minlength;
        }

        function yearFilled() {
            return !yearRequired() && !vm.form.year.$error.minlength;
        }

        function bothFieldsFilled() {
            return monthFilled() && yearFilled();
        }

        function monthTouched() {
            return vm.form.month.$touched;
        }

        function yearTouched() {
            return vm.form.year.$touched;
        }

        function bothFieldsTouched() {
            return monthTouched() && yearTouched();
        }

        function invalidMonthMinLength() {
            return vm.form.month.$error.minlength;
        }

        function isSubmitted() {
            return vm.isSubmitted;
        }

        // -- api

        vm.invalidDate = function () {
            if (bothFieldsFilled() || bothFieldsTouched()) {
                if (invalidMonth() && invalidYear()) {
                    return true;
                } else if (invalidYear() && yearFilled()) {
                    return true;
                }
            }
        };

        vm.showEnterADate = function () {
            return monthRequired() && yearRequired() && (monthTouched() || yearTouched() || isSubmitted());
        };

        vm.showInvalidMonth = function () {

            if (vm.invalidDate() || vm.showEnterADate()) {
                // @note early return
                return;
            }

            if (invalidMonth() && !invalidMonthMinLength() && invalidYear()) {
                return true;
            } else if (invalidMonth() && !invalidMonthMinLength() && !invalidYear() && yearTouched()) {
                return true;
            } else if (invalidMonth() && !invalidMonthMinLength() && !yearTouched()) {
                return true;
            } else if (invalidMonthMinLength() && monthTouched() && !yearTouched()) {
                return true;
            } else if (invalidMonthMinLength() && monthTouched() && yearTouched()) {
                return true;
            } else if (!monthFilled() && !monthTouched() && yearTouched()) {
                return true;
            }

        };

        vm.showYearRequired = function () {

            if (vm.invalidDate() || vm.showEnterADate()) {
                // @note early return
                return;
            }

            if (!yearFilled() && monthFilled() && !invalidMonth() && yearTouched()) {
                if (!invalidMonthMinLength()) {
                    return true;
                }
            }
        };

        // -- scope bindings

        vm.$onInit = function () {
            $scope.$watch(function () {
                return vm.expiryMonth + vm.expiryYear;
            }, function () {
                vm.dateConcatenated = vm.expiryMonth + '/' + vm.expiryYear;
            });
        };

        // -- main

    }]
});
})(window, window.angular);