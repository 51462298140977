(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.transfer-funds', []);

var API_BASE_PATH = '/feapi/r1';
var API_TRANSFER_FUNDS_ENDPOINT = '/investor/accounts/transfer';

ngModule.service('transferFundsService', ['$http', '$q', function ($http, $q) {

    var api = {};

    api.makeTransfer = function (amount) {

        var endpoint = API_BASE_PATH + API_TRANSFER_FUNDS_ENDPOINT;
        var payload = {
            amount: amount
        };
        return $http.post(endpoint, payload).then(function (response) {
            return true;
        }, function (error) {
            switch (error.status) {
            case 401:
                return $q.reject({
                    reason: 'auth.error.not.authenticated'
                });
            case 403:
                return $q.reject({
                    reason: 'transfer.error.unauthorised'
                });
            default:
                return $q.reject({
                    reason: 'transfer.error.unexpected'
                });
            }
        });
    };

    return api;
}]);
})(window, window.angular);