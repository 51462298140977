(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.random', []);

ngModule.service('random', [function () {

    function ridGenerator(len) {
        var S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        var str = '';
        while (str.length < len) {
            str += S4();
        }
        return str.substring(0, len);
    }

    return {
        id: ridGenerator
    };
}]);
})(window, window.angular);