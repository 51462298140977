(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-carousel', ['pp.services.core', 'pp.widgets-templates', 'pp.components.carousel', 'pp.components.carousel-multi-item']);

ngModule.directive('ppPropertyCarousel', ['ppBrowser', function (ppBrowser) {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-carousel/property-carousel.tpl.html',
        scope: {
            symbol: '<',
            numberOfImages: '<',
        },
        link: function ($scope, $element) {
            var isModalVisible;
            var $parent = $element.find('.pp-property-carousel');
            var $modal = $element.find('.property-carousel-modal');
            $scope.$on('pp.carousel.click', function () {
                if (ppBrowser.isMobile || isModalVisible) {
                    return;
                }
                var height = $parent.css('height');
                $parent.css('height', height);
                isModalVisible = true;
                var $contents = $element.find('.carousel-contents');
                $contents.appendTo($modal);
                var modalInstance = $modal.modal({
                    showClose: true,
                    escapeClose: true,
                    clickClose: true
                });
                $scope.$broadcast('pp.carousel.refresh');
                modalInstance.on($.modal.CLOSE, function () {
                    isModalVisible = false;
                    $contents.appendTo($parent);
                    $parent.css('height', '');
                    $scope.$broadcast('pp.carousel.refresh');
                    modalInstance.off($.modal.CLOSE);
                });
            });
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', '$timeout', 'ppConfig', 'ppComponentEmitter', function ($scope, $element, $timeout, ppConfig, ppComponentEmitter) {
            var __endpoints = ppConfig.get('pp.external.endpoints') || {};
            var vm = this;

            // -- initial state
            vm.isMobile = ppBrowser.isMobile;
            var assetsEndpoint = __endpoints.assets;

            var baseUrl;

            // -- util functions

            function createUrls() {
                vm.imageUrls = [];

                if (vm.numberOfImages <= 1) {
                    vm.imageUrl = baseUrl.replace(':imageNumber', 1);
                    return;
                }

                for (var ix = 0; ix < vm.numberOfImages; ix++) {
                    vm.imageUrls.push(baseUrl.replace(':imageNumber', ix + 1));
                }
            }

            function bindKeyboard(ev) {
                if (ev.keyCode === 37) {
                    $scope.$broadcast('pp.carousel.prev');
                } else if (ev.keyCode === 39) {
                    $scope.$broadcast('pp.carousel.next');
                }
            }

            // -- api

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                vm.numberOfImages = vm.numberOfImages || 0;
                vm.currentSlide = 0;

                vm.flagImageEndpoint = assetsEndpoint + '/images/icons/flags/US-flag-small.png';
                baseUrl = assetsEndpoint + '/images/properties/' + vm.symbol + '/image:imageNumber.jpg';

                if (vm.property && vm.property.symbol) {
                    ppComponentEmitter.emit('individual-property.property-carousel.controller.loaded.' + vm.symbol, vm);

                    $timeout(function () {
                        ppComponentEmitter.emit('individual-property.property-carousel.dom.loaded.' + vm.symbol, $element);
                    });
                }

                createUrls();
                $(document).on('keyup', bindKeyboard);
                vm.ready = true;
            };

            vm.$onDestroy = function () {
                $(document).off('keyup', bindKeyboard);
            };
        }]
    };
}]);
})(window, window.angular);