(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.withdrawal-receipt', ['pp.widgets-templates']);

ngModule.directive('ppWithdrawalReceipt', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/withdrawal-receipt/withdrawal-receipt.tpl.html',
        scope: {
            receipt: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util

            // -- api

            // -- main

        }]
    };
});
})(window, window.angular);