(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.info-card', ['pp.widgets-templates', 'pp.services.core']);

ngModule.component('ppInfoCard', {
    templateUrl: 'widgets/_angular/info-card/info-card.tpl.html',
    bindings: {
        data: '<',
        ctaClick: '&?onCtaClick'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$sce', '$window', 'ppConfig', 'ppUtil', function ($scope, $sce, $window, ppConfig, ppUtil) {
        var vm = this;
        var __config = ppConfig.get('pp.external.endpoints') || {};
        var assetsUrl = __config.assets;

        // -- initial state

        // -- util functions

        // -- api
        vm.ctaActionClick = function () {
            if (vm.data.cta.link) {
                if (vm.data.cta.newTab) {
                    $window.open(vm.data.cta.link);
                } else {
                    $window.location.href = vm.data.cta.link;
                }
            }

            if (vm.ctaClick) {
                vm.ctaClick();
            }
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            vm.data = vm.data || {};
            vm.image = ppUtil.hasPathIn(vm.data, 'image') ? assetsUrl + '/' + vm.data.image : null;
            vm.data.description = vm.data.description ? $sce.trustAsHtml(vm.data.description) : null;
        };

    }]
});
})(window, window.angular);