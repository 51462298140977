(function(window, angular){'use strict';

var ngModule = angular.module('pp.values.faqs', []);

ngModule.value('faqs', {
    taxInvestment: {
        title: 'How am I taxed on my investment?',
        desc: '<p>The dividends you receive can be included in your <b>£5,000 tax-free dividend allowance</b> each year. Anything beyond this will be taxed at the marginal rate of income tax. </p>' +
            '<p>Capital gains can be included in your <b>£11,300 tax-free capital gains allowance</b> each year. Anything beyond this will be taxed at the marginal rate of capital gains tax.</p>' +
            '<p>Tax treatment depends on individual circumstances and is subject to change.</p>'
    },
    scaleBack: {
        title: 'What is scale-back?',
        desc: '<p>Scale-back may be applied if pre-orders are oversubscribed. When investor commitments exceed the Total Investment Required in a New Listing, your commitment may be reduced on a pro-rata basis. Please note that investments made by Auto-Invest will not be subject to scale-back unless there is oversubscription via Auto-invest alone.</p>'
    },
    regulatedByFCA: {
        title: 'Is Property Partner authorised and regulated by the FCA?',
        desc: '<p>Yes, Property Partner is the trading name of London House Exchange Limited. London House Exchange Limited is authorised and regulated by the Financial Conduct Authority (No. 613499).</p>'
    },
    dividendsPaid: {
        title: 'When are dividends paid?',
        desc: '<p>Dividends for any given month will be paid on the 5th of following month, directly into your Property Partner account. If the 5th of the month falls on the weekend or a bank holiday, dividends will be paid on the next business day.</p>'
    },
    investAsCorporate: {
        title: 'Can I invest as a corporate?',
        desc: '<p>Yes, you will have the opportunity to set up a corporate account when completing your account set up.</p>'
    },
    fees: {
        title: 'What are Property Partner’s fees?',
        desc: '<p>A one-off 2% fee per investment.</p>'
    }
});
})(window, window.angular);