(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-plan-restricted-investor-blocked-dialog', [
    'pp.widgets-templates',
    'pp.ui.services.classification-dialog',
    'pp.services.route'
]);

ngModule.component('ppInvestmentPlanRestrictedInvestorBlockedDialog', {
    templateUrl: 'widgets/_angular/investment-plan-restricted-investor-blocked-dialog/investment-plan-restricted-investor-blocked-dialog.tpl.html',
    bindings: {
        user: '<'
    },
    controllerAs: 'vm',
    controller: ['routeService', 'classificationDialogService', function (routeService, classificationDialogService) {
        var vm = this;

        vm.marketplacePath = routeService.marketplacePath;
        vm.learnMoreLink = routeService.retailInvestorBlockedInvestmentPlans;
        vm.contactUsPath = routeService.contactUsPath;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$onInit = function () {
            vm.reclassify = classificationDialogService.reclassifyUser.bind(null, vm.user);
        };

        // -- main

    }]
});
})(window, window.angular);