(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.home-now-reit-disclaimer-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.services.investor',
    'pp.widgets.home-now-reit-warning',
    'pp.widgets.home-now-non-uk-blocked'
]);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/extra-user-data`.
 */
ngModule.service('homeNowReitDisclaimerDialog', ['$window', '$rootScope', '$q', 'ppTrack', 'dialogService', 'investorService', function ($window, $rootScope, $q, ppTrack, dialogService, investorService) {

    // -- initial state

    // -- util functions

    var dialogInstance;

    // create a scope, show the dialog
    function showDialog(user, property) {

        ppTrack.event('user.home-now-reit-warning.shown');

        var scope = $rootScope.$new();

        scope.user = user;
        scope.property = property;

        scope.onBlock = function () {
            $window.location.href = '/';
        };

        scope.onContinue = function () {
            dialogService.resolve();
        };

        var options = {
            clickClose: false,
            escapeClose: false,
            showClose: false,
            classNames: 'pp-home-now-reit-warning-dialog',
            scope: scope
        };

        options.contents = '<pp-home-now-reit-warning user="user" property="property" on-block="onBlock()" on-continue="onContinue()"></pp-home-now-reit-warning>';

        dialogInstance = dialogService.show(options);

        return dialogInstance;
    }

    function showNonUKBlockedDialog() {
        ppTrack.event('user.home-now-reit.non-uk.blocked');

        var scope = $rootScope.$new();

        var options = {
            clickClose: true,
            escapeClose: true,
            showClose: true,
            classNames: 'pp-home-now-non-uk-blocked-dialog',
            scope: scope
        };

        options.contents = '<pp-home-now-non-uk-blocked></pp-home-now-non-uk-blocked>';

        dialogInstance = dialogService.show(options);

        return dialogInstance;
    }

    // -- api

    var api = {
        showDialog: showDialog,
        showNonUKBlockedDialog: showNonUKBlockedDialog
    };

    return api;
}]);
})(window, window.angular);