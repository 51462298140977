(function(window, angular){'use strict';

var ngModule = angular.module('pp.controllers.dashboard-table', [
    'ui.router',
    'pp.widgets-templates',
    'pp.services.table',
    'pp.values.dashboard',
    'pp.components.table-sort',
    'pp.components.tooltip',
    'pp.widgets.property-cell',
    'pp.services.dashboard',
    'pp.services.investor',
    'pp.services.core',
    'pp.services.preference',
    'pp.filters.property',
    'pp.ui.services.dashboard-individual-property-sidebar',
    'pp.ui.services.cancel-listing-dialog',
    'pp.widgets.valuation-method-select'
]);

var STICKY_TABLE_WRAPPER_CLASS = 'sticky-table-wrapper';
var STICKY_TABLE_HTML_CLASS = 'html-sticky-table-outer-wrapper';

ngModule.controller('ppDashboardTableCtrl', ['$scope', '$q', '$filter', '$state', 'tableService', 'dashboardService', 'investorService', 'dashboardIndividualPropertySidebarService', 'cancelListingDialogService', 'ppTrack', 'ppUtil', 'dashboard', 'R', 'preferenceService', function ($scope, $q, $filter, $state, tableService, dashboardService, investorService, dashboardIndividualPropertySidebarService, cancelListingDialogService, ppTrack, ppUtil, dashboard, R, preferenceService) {
    var vm = this;

    // -- initial state

    vm.tableConfig = {};
    vm.votedMap = {};

    vm.tradeActionMap = {
        'BUY': 'Buy',
        'SELL': 'Sell'
    };

    vm.glossary = dashboard;

    // -- util functions

    function sortData(data, attr, order) {

        if (!attr || !order) {
            return data.concat(); // more performant array copy compared with angular.copy
        }

        var sort = tableService.sortByAttr.bind(null, attr, order);
        return data.concat().sort(sort);
    }

    /* TODO when freeze col is introduced in the dashboard table
    function setStuck(direction) {
        if (direction === 'right') {
            vm.stuck = true;
        } else {
            vm.stuck = false;
        }
    }*/

    // -- api

    vm.defaultTo = R.defaultTo;

    vm.openProperty = function (symbol, id) {

        if (!vm.data.properties[symbol]) {
            return; // note early return
        }

        ppTrack.action('property.click', {
            symbol: symbol
        });

        vm.tableConfig.focussed = id || symbol;

        dashboardIndividualPropertySidebarService.show(vm.data.properties[symbol], vm.data.holdings[symbol], vm.onChange)
            .then(function (res) {
                if (R.path(['dismissed'], res) || R.path(['closed'], res)) {
                    $state.go($state.current.name.replace('.open-property', ''));
                }
            });
    };

    vm.goToPropertySidebar = function (symbol, id) {
        ppTrack.action('property.click', {
            symbol: symbol
        });

        vm.tableConfig.focussed = id || symbol;

        $state.go($state.current.name.replace('.open-property', '') + '.open-property', {
            propertySymbol: symbol
        });
    };

    vm.updateSort = function (attr, order) {
        vm.sortAttribute = attr;
        vm.sortOrder = order;
        vm.tableData = sortData(vm.data.collection, attr, order);

        ppTrack.action('dashboard-table.sort', {
            attribute: attr,
            order: order
        });
    };

    vm.updatePropertySort = function (attr, order) {
        vm.sortAttribute = attr;
        vm.sortOrder = order;

        var data = vm.data.collection.map(function (item) {
            var propertyAddress = $filter('propertyAddress')(vm.data.properties[item[attr]]);
            return ppUtil.extendHash({
                propertyAddress: propertyAddress
            }, item);
        });

        vm.tableData = sortData(data, 'propertyAddress', order);

        ppTrack.action('dashboard-table.sort', {
            attribute: 'property',
            order: order
        });
    };

    vm.cancelBid = function (symbol, orderId) {

        ppTrack.action('dashboard-table.cancel-bid.submit', {
            symbol: symbol
        });

        return cancelListingDialogService.show(symbol, orderId, true)
            .then(function () {

                ppTrack.action('dashboard-table.cancel-bid.success', {
                    symbol: symbol
                });

                vm.onChange();
            });
    };

    vm.cancelOffer = function (symbol, orderId) {

        ppTrack.action('dashboard-table.cancel-offer.submit', {
            symbol: symbol
        });

        return cancelListingDialogService.show(symbol, orderId)
            .then(function () {

                ppTrack.action('dashboard-table.cancel-offer.success', {
                    symbol: symbol
                });

                vm.onChange();
            });
    };

    vm.onSwitchValuationMethod = function (valuationMethod) {
        return vm.switchValuationMethod({
            valuationMethod: valuationMethod
        });
    };

    // -- scope bindings

    // -- main

    vm.$onChanges = function (changes) {

        var propertySymbolOpen = R.path(['openSidebarSymbol', 'currentValue'], changes);
        if (propertySymbolOpen) {
            vm.openProperty(propertySymbolOpen);
        }

        if (changes && changes.data && changes.data.currentValue) {
            var sortAttribute = ppUtil.path(changes, 'sortAttribute.currentValue') || vm.sortAttribute;
            var sortOrder = ppUtil.path(changes, 'sortOrder.currentValue') || vm.sortOrder;
            var collection = ppUtil.path(changes, 'data.currentValue.collection') || vm.data.collection;
            vm.tableData = sortData(collection, sortAttribute, sortOrder);
        }
    };

    vm.$postLink = function () {
        /* TODO when freeze col is introduced in the dashboard table
        __waypoint = new Waypoint({
            element: document.getElementById('horizontal-waypoint-table'),
            handler: function (direction) {
                $scope.$evalAsync(function () {
                    setStuck(direction);
                });
            },
            context: document.getElementById('overflow-scroll-table'),
            horizontal: true,
            offset: 220
        });*/

    };

    vm.$onInit = function () {
        $('body').addClass(STICKY_TABLE_WRAPPER_CLASS);
        $('html').addClass(STICKY_TABLE_HTML_CLASS);

        preferenceService.on('preferences-updated', function (preferences) {
            vm.votedMap = preferenceService.createVotingPreferenceMap(preferences);
        });

        preferenceService.getVotingPreferenceMap().then(function (votedMap) {
            vm.votedMap = votedMap;
        });
    };

    vm.$onDestroy = function () {
        /* TODO when freeze col is introduced in the dashboard table
        if (__waypoint && __waypoint.destroy) {
            __waypoint.destroy();
        }
        */

        $('body').removeClass(STICKY_TABLE_WRAPPER_CLASS);
        $('html').removeClass(STICKY_TABLE_HTML_CLASS);
    };

}]);
})(window, window.angular);