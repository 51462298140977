(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.toggle-tick', ['pp.components-templates']);

ngModule.directive('ppToggleTick', function () {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/toggle-tick/toggle-tick.tpl.html',
        scope: {
            selected: '=ppToggleTick',
            ignoreClicks: '=',
            isDisabled: '=',
            isProcessing: '=',
            flash: '=',
            onClick: '&?'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: [function () {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

            vm.toggleClick = function () {
                if (!vm.ignoreClicks && !vm.isDisabled) {
                    vm.selected = !vm.selected;
                    if (vm.onClick) {
                        vm.onClick({
                            selected: vm.selected
                        });
                    }
                }
            };
        }]
    };

});
})(window, window.angular);