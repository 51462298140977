(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.funding-round-key-points', [
    'pp.widgets-templates'
]);

ngModule.component('ppFundingRoundKeyPoints', {
    templateUrl: 'widgets/_angular/funding-round-key-points/funding-round-key-points.tpl.html',
    bindings: {
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);