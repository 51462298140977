(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.suspended-property-dialog', ['pp.widgets-templates']);

ngModule.directive('ppSuspendedPropertyDialog', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/suspended-property-dialog/suspended-property-dialog.tpl.html',
        scope: {
            showDashboardBtn: '=ppSuspendedPropertyDialog'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main
        }]
    };
}]);
})(window, window.angular);