(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.account-history', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.preference',
    'pp.widgets.account-history-type'
]);

var AUTO_INVEST_PREFERENCE = 'AUTO.INVEST.ACTIVE';
var ACCOUNT_TYPE_STATE = 'portfolio.account-history.type';
var MANUAL = 'manual';
var AUTO_INVEST = 'autoinvest';

ngModule.component('ppAccountHistory', {
    templateUrl: 'widgets/_angular/account-history/account-history.tpl.html',
    bindings: {},
    transclude: true,
    controllerAs: 'vm',
    controller: ['$q', '$state', '$transitions', '$stateParams', 'investorService', 'preferenceService', function ($q, $state, $transitions, $stateParams, investorService, preferenceService) {

        // -- initial state

        var vm = this;

        var isAutoInvestType = $stateParams.type === AUTO_INVEST;

        vm.state = isAutoInvestType ? AUTO_INVEST : MANUAL;

        vm.page = {
            auto: isAutoInvestType ? $stateParams.page : 0,
            manual: !isAutoInvestType ? $stateParams.page : 0
        };

        function redirectToTypePage(type) {
            vm.state = type;
            $state.go(ACCOUNT_TYPE_STATE, {
                type: type,
                page: type === MANUAL ? vm.page.manual : vm.page.auto
            }, {
                location: 'replace'
            });
        }

        vm.$onInit = function () {
            $transitions.onSuccess({
                to: ACCOUNT_TYPE_STATE
            }, function (transition) {
                var toParams = transition.params();

                //refresh state and keep page count for each state
                vm.state = $stateParams.type;
                vm.page = {
                    manual: toParams.type === MANUAL ? toParams.page : vm.page.manual,
                    auto: toParams.type === AUTO_INVEST ? toParams.page : vm.page.auto
                };

                if (toParams.type === AUTO_INVEST && !vm.isAutoInvestActive) {
                    redirectToTypePage(MANUAL);
                } else if ([MANUAL, AUTO_INVEST].indexOf($stateParams.type) === -1) {
                    redirectToTypePage(MANUAL);
                }
            });
        };

        // -- api

        vm.selectTab = function (type) {
            vm.state = type;
            vm.page = {
                manual: type === AUTO_INVEST ? $stateParams.page : vm.page.manual,
                auto: type === MANUAL ? $stateParams.page : vm.page.auto
            };

            $state.go(ACCOUNT_TYPE_STATE, {
                page: type === MANUAL ? vm.page.manual : vm.page.auto,
                type: type
            });
        };

        // -- main
        var promises = {
            autoInvest: preferenceService.getPreference(AUTO_INVEST_PREFERENCE),
            investor: investorService.getInvestor()
        };

        $q.all(promises).then(function (data) {
            vm.isAutoInvestActive = data.autoInvest;
            vm.user = data.investor;
            vm.ready = true;
        });

    }]
});
})(window, window.angular);