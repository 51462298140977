(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.toggle-button', [
    'pp.components-templates'
]);

ngModule.component('ppToggleButton', {
    templateUrl: 'components/_angular/toggle-button/toggle-button.tpl.html',
    transclude: true,
    bindings: {
        active: '=',
        isDisabled: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api
        vm.toggleActive = function () {
            vm.active = !vm.active;
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);