(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.awards-banner', [
    'pp.widgets-templates',
    'pp.services.core'
]);

ngModule.component('ppAwardsBanner', {
    templateUrl: 'widgets/_angular/awards-banner/awards-banner.tpl.html',
    bindings: {
        user: '<',
        cta: '<',
        ctaUrl: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppPubSub', 'ppConfig', function ($scope, ppPubSub, ppConfig) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints') || {};
        var s3Endpoint = __config.assets;
        vm.wreathIconUrl = s3Endpoint + '/images/icons/generic/awards-wreath.svg';

        // -- util functions

        // -- api

        vm.openSignupModal = function (email) {
            ppPubSub.publish('auth.modal.show', ['signup', null, null, null, null, email]);
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);