(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.config', []);

var BASE_ENDPOINT = '/feapi/r1';
var CONFIG_ENDPOINT = BASE_ENDPOINT + '/config';
var TTL = 3600000; // 1 hour

var MINIMUM_MANUAL_FUND_KEY = 'min_funding_amount';

var PRIMARY_MARKET_FEE_RATE = 'fees.primary_market.charge_rate';
var SECONDARY_MARKET_FEE_RATE = 'fees.secondary_market.charge_rate';
var PRIMARY_MARKET_TAX_RATE = 'tax.primary_market.rate';
var SECONDARY_MARKET_TAX_RATE = 'tax.secondary_market.rate';

var APO_STUDENT_CARD_POSITION_KEY = 'apo_student_card_position';
var APO_COMMERCIAL_CARD_POSITION_KEY = 'apo_commercial_card_position';
var APO_RESIDENTIAL_CARD_POSITION_KEY = 'apo_residential_card_position';
var APO_DEVELOPMENT_LOAN_CARD_POSITION_KEY = 'apo_development_loan_card_position';

var BOND_CARD_CONFIG_KEY = 'bond_card_config';

var ISA_PUBLIC = 'isa_public';

// estimated returns keys
var ESTIMATED_RETURNS_RENTAL_INCOME = 'estimated_returns_rental_income';
var ESTIMATED_RETURNS_CAPITAL_GAINS = 'estimated_returns_capital_gains';
var ESTIMATED_RETURNS_TOTAL_RETURNS = 'estimated_returns_total_returns';
var ESTIMATED_RETURNS_UPDATED_DATE = 'estimated_returns_updated_date';

// total returns table
var PAST_TOTAL_RETURNS_1_LABEL = 'past_total_returns_1_label';
var PAST_TOTAL_RETURNS_1_VALUE = 'past_total_returns_1_value';
var PAST_TOTAL_RETURNS_2_LABEL = 'past_total_returns_2_label';
var PAST_TOTAL_RETURNS_2_VALUE = 'past_total_returns_2_value';
var PAST_TOTAL_RETURNS_3_LABEL = 'past_total_returns_3_label';
var PAST_TOTAL_RETURNS_3_VALUE = 'past_total_returns_3_value';

//past returns capital gains
var PAST_1_RETURNS_CAPITAL_GAINS = 'past_1_returns_capital_gains';
var PAST_1_RETURNS_UPDATED_DATE = 'past_1_returns_updated_date';
var PAST_2_RETURNS_CAPITAL_GAINS = 'past_2_returns_capital_gains';
var PAST_2_RETURNS_UPDATED_DATE = 'past_2_returns_updated_date';
var PAST_3_RETURNS_CAPITAL_GAINS = 'past_3_returns_capital_gains';
var PAST_3_RETURNS_UPDATED_DATE = 'past_3_returns_updated_date';

var DEFAULT_MANUAL_FUND_AMOUNT = 250;
var DEFAULT_INVESTMENT_BUDGET = 'default_investment_budget';

var MAX_OWNERSHIP_PER_PROPERTY = 'max_ownership_per_property';

var MINIMUM_DEVELOPMENT_LOAN_AMOUNT = 'minimum_development_loan_amount';
var MINIMUM_PREORDER_AMOUNT = 'minimum_preorder_amount';
var MINIMUM_INVESTMENT_PLAN_AMOUNT = 'minimum_investment_plan_amount';

// promotional data
// we are growing fast
var ASSETS_UNDER_MANAGEMENT = 'assets_under_management';
var ASSETS_UNDER_MANAGEMENT_PCT_CHANGE = 'assets_under_management_pct_change';
var CAPITAL_RETURNED_TO_INVESTORS = 'capital_returned_to_investors';
var CAPITAL_RETURNED_TO_INVESTORS_PCT_CHANGE = 'capital_returned_to_investors_pct_change';
var RENTAL_INCOME_RETURNED_TO_INVESTORS = 'rental_income_returned_to_investors';
var RENTAL_INCOME_RETURNED_TO_INVESTORS_PCT_CHANGE = 'rental_income_returned_to_investors_pct_change';
var PERFORMANCE_FIGURES_DATE_CHANGE = 'performance_figures_date_change';

//average time to sell
var AVERAGE_TIME_TO_SELL = 'average_time_to_sell';
var AVERAGE_TIME_TO_SELL_DATE = 'average_time_to_sell_date';
var PAST_TIME_TO_SELL_1_LABEL = 'past_time_to_sell_1_label';
var PAST_TIME_TO_SELL_1_VALUE = 'past_time_to_sell_1_value';
var PAST_TIME_TO_SELL_2_LABEL = 'past_time_to_sell_2_label';
var PAST_TIME_TO_SELL_2_VALUE = 'past_time_to_sell_2_value';
var PAST_TIME_TO_SELL_3_LABEL = 'past_time_to_sell_3_label';
var PAST_TIME_TO_SELL_3_VALUE = 'past_time_to_sell_3_value';

// income opportunities
var ENHANCED_RENTAL_INCOME = 'enhanced_rental_income';
var AVERAGE_RESIDENTIAL_YIELD = 'average_residential_yield';
var AVERAGE_PBSA_YIELD = 'average_pbsa_yield';
var UK_DIVIDEND_ALLOWANCE = 'uk_dividend_allowance';

// capital growth
//@TODO remove these two keys and delete from fe_config PP-5055
var AVERAGE_CAPITAL_GROWTH = 'average_capital_growth';
var AVERAGE_CAPITAL_GROWTH_DATE = 'average_capital_growth_date';

var UK_CAPITAL_GAINS_ALLOWANCE = 'uk_capital_gains_allowance';

var REALISED_TOTAL_RETURNS = 'realised_total_returns';

var UNIT_VALUATION_DATE = 'unit_valuation_date';

// Footer urls
var BLOG_ENDPOINT = 'blog_endpoint';
var RECENT_TRADES_ENDPOINT = 'recent_trades_endpoint';

// growth figures note date
var GROWTH_FIGURES_NOTE_DATE = 'growth_figures_note_date';

var INVESTMENT_PLAN_DEPLOYMENT_CLOSED = 'investment_plan_deployment_closed';

/**
 * @ngdoc service
 * @name configService
 *
 * @description
 */
ngModule.service('configService', ['$http', '$q', function ($http, $q) {

    var api = {};

    var promiseCache = {};

    function getConfigMap() {
        var cacheKey = 'config';
        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(CONFIG_ENDPOINT).then(function (response) {
                var configData = angular.copy(response.data || {});
                return configData;
            }, function () {
                delete promiseCache[cacheKey];

                return $q.reject({
                    reason: 'fe-config.error.unexpected'
                });
            });
        }
        return promiseCache[cacheKey];
    }

    function stringToBoolean(val) {
        if (angular.isString(val)) {
            return val.toLowerCase() === 'true';
        } else {
            return false;
        }
    }

    api.getConfigItem = function (key, functor) {
        return getConfigMap().then(function (config) {
            if (functor) {
                try {
                    return functor(config[key]);
                } catch (err) {
                    return;
                }
            } else {
                return config[key];
            }
        });
    };

    api.getApoCardPositions = function () {
        var positionPromises = {
            student: api.getConfigItem(APO_STUDENT_CARD_POSITION_KEY),
            commercial: api.getConfigItem(APO_COMMERCIAL_CARD_POSITION_KEY),
            residential: api.getConfigItem(APO_RESIDENTIAL_CARD_POSITION_KEY),
            development_loan: api.getConfigItem(APO_DEVELOPMENT_LOAN_CARD_POSITION_KEY)
        };

        return $q.all(positionPromises).then(function (result) {
            var arrPositions = [{
                type: 'development_loan',
                position: Number(result.development_loan)
            }];

            return arrPositions.filter(function (p) {
                return p.position;
            }).sort(function (a, b) {
                return a.position - b.position;
            });
        });
    };

    api.getFundingManualMinimum = function () {
        return api.getConfigItem(MINIMUM_MANUAL_FUND_KEY).then(function (amount) {
            return Number(amount) || DEFAULT_MANUAL_FUND_AMOUNT;
        });
    };

    api.getEstimatedReturns = function () {
        var promises = [
            api.getConfigItem(ESTIMATED_RETURNS_RENTAL_INCOME),
            api.getConfigItem(ESTIMATED_RETURNS_CAPITAL_GAINS),
            api.getConfigItem(ESTIMATED_RETURNS_TOTAL_RETURNS),
            api.getConfigItem(ESTIMATED_RETURNS_UPDATED_DATE),
            api.getConfigItem(PAST_TOTAL_RETURNS_1_LABEL),
            api.getConfigItem(PAST_TOTAL_RETURNS_1_VALUE),
            api.getConfigItem(PAST_TOTAL_RETURNS_2_LABEL),
            api.getConfigItem(PAST_TOTAL_RETURNS_2_VALUE),
            api.getConfigItem(PAST_TOTAL_RETURNS_3_LABEL),
            api.getConfigItem(PAST_TOTAL_RETURNS_3_VALUE)
        ];

        return $q.all(promises).then(function (data) {
            return {
                rentalIncome: data[0],
                capitalGains: data[1],
                totalReturns: data[2],
                updatedDate: data[3],
                pastTotalReturns1Label: data[4],
                pastTotalReturns1Value: data[5],
                pastTotalReturns2Label: data[6],
                pastTotalReturns2Value: data[7],
                pastTotalReturns3Label: data[8],
                pastTotalReturns3Value: data[9],
            };
        });
    };

    api.getAutoInvestFigures = function () {
        var promises = {
            rentalIncome: api.getConfigItem(ESTIMATED_RETURNS_RENTAL_INCOME),
            ukDividendAllowance: api.getConfigItem(UK_DIVIDEND_ALLOWANCE),
            ukCapitalGainsAllowance: api.getConfigItem(UK_CAPITAL_GAINS_ALLOWANCE)
        };

        return $q.all(promises);
    };

    api.getPromotionalFigures = function () {
        var promises = {
            // we are growing fast
            assetsUnderManagement: api.getConfigItem(ASSETS_UNDER_MANAGEMENT),
            assetsUnderManagementPctChange: api.getConfigItem(ASSETS_UNDER_MANAGEMENT_PCT_CHANGE),
            capitalReturnedToInvestors: api.getConfigItem(CAPITAL_RETURNED_TO_INVESTORS),
            capitalReturnedToInvestorsPctChange: api.getConfigItem(CAPITAL_RETURNED_TO_INVESTORS_PCT_CHANGE),
            rentalIncomeReturnedToInvestors: api.getConfigItem(RENTAL_INCOME_RETURNED_TO_INVESTORS),
            rentalIncomeReturnedToInvestorsPctChange: api.getConfigItem(RENTAL_INCOME_RETURNED_TO_INVESTORS_PCT_CHANGE),
            peformanceFiguresDateChange: api.getConfigItem(PERFORMANCE_FIGURES_DATE_CHANGE),

            // income opportunities
            enhancedRentalIncome: api.getConfigItem(ENHANCED_RENTAL_INCOME),
            averageResidentialYield: api.getConfigItem(AVERAGE_RESIDENTIAL_YIELD),
            averagePbsaYield: api.getConfigItem(AVERAGE_PBSA_YIELD),
            ukDividendAllowance: api.getConfigItem(UK_DIVIDEND_ALLOWANCE),

            // capital growth
            averageCapitalGrowth: api.getConfigItem(ESTIMATED_RETURNS_CAPITAL_GAINS),
            averageCapitalGrowthDate: api.getConfigItem(ESTIMATED_RETURNS_UPDATED_DATE),
            ukCapitalGainsAllowance: api.getConfigItem(UK_CAPITAL_GAINS_ALLOWANCE),
            past1AverageCapitalGrowth: api.getConfigItem(PAST_1_RETURNS_CAPITAL_GAINS),
            past1AverageCapitalGrowthDate: api.getConfigItem(PAST_1_RETURNS_UPDATED_DATE),
            past2AverageCapitalGrowth: api.getConfigItem(PAST_2_RETURNS_CAPITAL_GAINS),
            past2AverageCapitalGrowthDate: api.getConfigItem(PAST_2_RETURNS_UPDATED_DATE),
            past3AverageCapitalGrowth: api.getConfigItem(PAST_3_RETURNS_CAPITAL_GAINS),
            past3AverageCapitalGrowthDate: api.getConfigItem(PAST_3_RETURNS_UPDATED_DATE),

            // growth figures note date
            growthFiguresNoteDate: api.getConfigItem(GROWTH_FIGURES_NOTE_DATE),

            realisedTotalReturns: api.getConfigItem(REALISED_TOTAL_RETURNS)

        };

        return $q.all(promises);
    };

    api.getAverageTimeToSell = function () {
        return $q.all({
            avgTimeToSell: api.getConfigItem(AVERAGE_TIME_TO_SELL),
            avgTimeToSellDate: api.getConfigItem(AVERAGE_TIME_TO_SELL_DATE),
            pastTimeToSell1Label: api.getConfigItem(PAST_TIME_TO_SELL_1_LABEL),
            pastTimeToSell1Value: api.getConfigItem(PAST_TIME_TO_SELL_1_VALUE),
            pastTimeToSell2Label: api.getConfigItem(PAST_TIME_TO_SELL_2_LABEL),
            pastTimeToSell2Value: api.getConfigItem(PAST_TIME_TO_SELL_2_VALUE),
            pastTimeToSell3Label: api.getConfigItem(PAST_TIME_TO_SELL_3_LABEL),
            pastTimeToSell3Value: api.getConfigItem(PAST_TIME_TO_SELL_3_VALUE)
        });
    };

    api.getFooterEndpoints = function () {
        return $q.all({
            recentTradesEndpoint: api.getConfigItem(RECENT_TRADES_ENDPOINT),
            blogEndpoint: api.getConfigItem(BLOG_ENDPOINT)
        });
    };

    api.getRates = function () {
        return $q.all({
            primaryMarketFeeRate: api.getConfigItem(PRIMARY_MARKET_FEE_RATE, Number),
            secondaryMarketFeeRate: api.getConfigItem(SECONDARY_MARKET_FEE_RATE, Number),
            primaryMarketTaxRate: api.getConfigItem(PRIMARY_MARKET_TAX_RATE, Number),
            secondaryMarketTaxRate: api.getConfigItem(SECONDARY_MARKET_TAX_RATE, Number)
        });
    };

    api.getBondConfig = function () {
        return api.getConfigItem(BOND_CARD_CONFIG_KEY, JSON.parse);
    };

    api.getDefaultBudget = function () {
        return api.getConfigItem(DEFAULT_INVESTMENT_BUDGET, Number);
    };

    api.getMinimumDevelopmentLoanAmount = function () {
        return api.getConfigItem(MINIMUM_DEVELOPMENT_LOAN_AMOUNT, Number) || 0;
    };

    api.getMinimumPreorderAmount = function () {
        return api.getConfigItem(MINIMUM_PREORDER_AMOUNT, Number) || 0;
    };

    api.getMaxOwnershipPerProperty = function () {
        return api.getConfigItem(MAX_OWNERSHIP_PER_PROPERTY, Number);
    };

    api.minimumInvestmentPlanAmount = function () {
        return api.getConfigItem(MINIMUM_INVESTMENT_PLAN_AMOUNT, Number);
    };

    api.getUnitValuationDate = function () {
        return api.getConfigItem(UNIT_VALUATION_DATE, function (res) {
            return new Date(res);
        });
    };

    api.isIsaPublic = function () {
        return api.getConfigItem(ISA_PUBLIC, function (isPublic) {
            return isPublic === 'true';
        });
    };

    api.isInvestmentPlanDeploymentClosed = function () {
        return api.getConfigItem(INVESTMENT_PLAN_DEPLOYMENT_CLOSED, stringToBoolean);
    };

    return api;
}]);
})(window, window.angular);