(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.auth-nav-user', ['pp.widgets-templates', 'pp.services.core', 'pp.widgets.auth-nav-dropdown']);

/**
 * @ngdoc directive
 * @name ppAuthNavUser
 * @description
 * Renders the dropdown and fund button
 *
 * @restrict A
 * @scope
 */

ngModule.directive('ppAuthNavUser', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/auth-nav-user/auth-nav-user.tpl.html',
        scope: {
            user: '=ppAuthNavUser',
            users: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$window', 'ppTrack', function ($window, ppTrack) {
            var vm = this;

            // -- initial state
            vm.isPortfolioRoute = $window.location.pathname.match(/^\/my-portfolio/);

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main
        }]
    };
});
})(window, window.angular);