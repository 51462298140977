(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketplace-data-view-table-primary', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.components.tooltip',
    'pp.widgets.aum-dividend-notice'
]);

var ID_KEY = 'symbol';

ngModule.directive('ppMarketplaceDataViewTablePrimary', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/marketplace-data-view-table-primary/marketplace-data-view-table-primary.tpl.html',
        scope: {
            data: '<ppMarketplaceDataViewTablePrimary',
            holdings: '<',
            viewPropertyDetail: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$element', 'ppTrack', 'ppComponentEmitter', function ($scope, $timeout, $element, ppTrack, ppComponentEmitter) {
            var vm = this;

            // -- initial state

            // TODO pull in map of {symbol: PropertyTileView} PP-5921
            // SEE template -> vm.properties[row.symbol].metadata.no_aum
            vm.properties = {};

            vm.idKey = ID_KEY;
            vm.labels = {
                location: 'Location',
                fundRaise: 'Funding Target',
                incomeYield: 'Yield',
                lastYearHpi: 'Last Year HPI',
                status: 'Status'
            };

            // -- util functions

            // -- scope bindings

            // -- api

            vm.investorHolding = function (symbol) {
                if (vm.holdings && vm.holdings.length > 0) {
                    return vm.holdings.indexOf(symbol) > -1;
                }
            };

            vm.propertyLinkClick = function (symbol) {
                ppTrack.deferAction('data-view.property.link.click', {
                    'property.symbol': symbol
                });

                vm.viewPropertyDetail({
                    symbol: symbol
                });
            };

            // -- scope bindings

            vm.$onInit = function () {
                $scope.$watch('vm.data', function () {
                    ppComponentEmitter.emit('marketplace-data-view-primary.controller.updated', vm);
                }, true);
            };

            vm.$postLink = function () {
                $timeout(function () {
                    ppComponentEmitter.emit('marketplace-data-view-primary.dom.loaded', $element);
                });
            };

            // -- main

        }]
    };
}]);
})(window, window.angular);