(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-hpi', ['pp.widgets-templates', 'pp.components.tooltip', 'pp.filters.numbers', 'pp.values.glossary']);

ngModule.directive('ppPropertyDetailsHpi', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-details-hpi/property-details-hpi.tpl.html',
        scope: {
            property: '<ppPropertyDetailsHpi'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'glossary', function ($scope, glossary) {
            var vm = this;

            // -- initial state
            vm.fiveYearHpiTooltip = glossary.fiveYearHpi;

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);