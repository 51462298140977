(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.upcoming-isa-card', [
    'pp.widgets-templates',
    'pp.ui.services.authenticate-investor',
    'pp.services.core'
]);

ngModule.component('ppUpcomingIsaCard', {
    templateUrl: 'widgets/_angular/upcoming-isa-card/upcoming-isa-card.tpl.html',
    bindings: {
        user: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'authenticateInvestor', function ($scope, authenticateInvestor) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.signup = function () {
            authenticateInvestor();
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);