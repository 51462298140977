(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.contact-us-view', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.auth',
    'pp.services.route',
    'pp.widgets.map',
    'pp.widgets.auth-signup-email-form'
]);

var CONTACT_US_PAGE = 'about.contactus';

ngModule.component('ppContactUsView', {
    templateUrl: 'widgets/_angular/contact-us-view/contact-us-view.tpl.html',
    bindings: {
        user: '<'
    },
    transclude: true,
    controllerAs: 'vm',
    controller: ['$scope', '$window', 'ppConfig', 'ppPubSub', 'ppTrack', 'authService', 'routeService', function ($scope, $window, ppConfig, ppPubSub, ppTrack, authService, routeService) {

        // -- initial state
        var vm = this;

        var __config = ppConfig.get('pp.contact-details');
        vm.address = __config.address;

        vm.knowledgeBaseLink = routeService.knowledgeBaseLink;

        vm.loc = [{
            latitude: parseFloat(vm.address.latitude),
            longitude: parseFloat(vm.address.longitude)
        }];

        vm.type = 'contactUs';

        vm.submitEmailSignup = function (email) {
            vm.processing = true;
            authService.emailSignup(email).finally(function () {
                vm.processing = false;
                ppPubSub.publish('auth.modal.show', ['signup', null, null, null, null, email]);
            });
        };

        vm.$onInit = function () {
            ppTrack.pageLoad(CONTACT_US_PAGE);
        };

        // -- api

    }]
});
})(window, window.angular);