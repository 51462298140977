(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketing-preferences-form', [
    'pp.widgets-templates',
    'pp.components-templates',
    'pp.components.white-space-only-validation',
    'pp.components.toggle-button',
    'pp.widgets.marketing-preference-card'
]);

var CONTACT_OPTION_SMS = 'SMS';
var CONTACT_OPTION_PHONE = 'Phone';

ngModule.component('ppMarketingPreferencesForm', {
    templateUrl: 'widgets/_angular/marketing-preferences-form/marketing-preferences-form.tpl.html',
    bindings: {
        preferenceOptions: '=',
        contactOptions: '=',
        contactPhone: '=',
        contactError: '=',
        telephoneInputDisabled: '<',
        feedbackEnabled: '<',
        processing: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$timeout', function ($scope, $timeout) {
        var vm = this;

        // -- initial state

        // -- util functions

        function shouldShowTelephoneInput(contactOption) {
            return (contactOption.type === CONTACT_OPTION_SMS || contactOption.type === CONTACT_OPTION_PHONE) && contactOption.enabled;
        }

        // -- api

        //TODO: Move this into an actual form validation and use pp-required-any (PP-5298)
        vm.showContactError = function () {
            var subscriptions = vm.preferenceOptions.filter(function (subscription) {
                return subscription.subscribed;
            });

            var options = vm.contactOptions.filter(function (contactOption) {
                return contactOption.enabled;
            });

            vm.contactError = subscriptions.length > 0 && options.length === 0;
            return vm.contactError;
        };

        vm.showTelephoneInput = function () {
            var options = vm.contactOptions || [];

            if (vm.telephoneInputDisabled) {
                return false;
            }

            return options.some(shouldShowTelephoneInput);
        };

        vm.toggleSelectAll = function () {
            vm.selectAll = !vm.selectAll;

            vm.preferenceOptions = vm.preferenceOptions.map(function (card) {
                card.subscribed = vm.selectAll;
                return card;
            });
        };

        // -- scope bindings

        // -- main
    }]
});
})(window, window.angular);