(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.address-kyc-non-uk', ['pp.widgets-templates', 'pp.services.core', 'pp.components.required-any']);

/**
 *
 * @param {object} ppAddressNonUk
 * @param {string} country
 * @param {boolean} feedbackEnabled
 *
 * @todo SOMEDAY rename module to pp.widgets.address-non-uk
 */
ngModule.directive('ppAddressNonUk', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/address-kyc-non-uk/address-kyc-non-uk.tpl.html',
        scope: {
            address: '=ppAddressNonUk',
            inputDisabled: '=',
            feedbackEnabled: '='
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', 'ppComponentEmitter', function ($scope, $element, ppComponentEmitter) {
            var vm = this;

            // -- main

            vm.$postLink = function () {
                ppComponentEmitter.emit('address-kyc-non-uk.dom.loaded', $element);
            };

            vm.$onInit = function () {
                ppComponentEmitter.emit('address-kyc-non-uk.controller.loaded', vm);
            };
        }]
    };
}]);
})(window, window.angular);