(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-events', ['pp.widgets-templates', 'pp.services.property', 'pp.filters.dates']);

ngModule.directive('ppPropertyEvents', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-events/property-events.tpl.html',
        scope: {
            symbol: '<ppPropertyEvents'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'propertyService', function ($scope, propertyService) {
            var vm = this;

            // -- initial state

            // -- util functions

            function init() {
                propertyService.getEvents(vm.symbol).then(function (data) {
                    vm.ready = true;
                    vm.entries = data;
                });
            }

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                init();
            };
        }]
    };
}]);
})(window, window.angular);