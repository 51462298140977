(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-ownership-markers', [
    'pp.widgets-templates',
    'pp.components.tooltip',
    'pp.values.glossary'
]);

ngModule.component('ppInvestmentOwnershipMarkers', {
    templateUrl: 'widgets/_angular/investment-ownership-markers/investment-ownership-markers.tpl.html',
    bindings: {
        holdings: '<',
        property: '<',
        bids: '<',
        offers: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'glossary', function ($scope, glossary) {
        var vm = this;

        // -- initial state

        vm.glossary = glossary;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);