(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.suitability', [
    'pp.widgets-templates',
    'pp.widgets.suitability-quiz',
    'pp.widgets.pre-suitability-quiz',
    'pp.widgets.pre-suitability-quiz-previously-failed',
    'pp.services.core'
]);

ngModule.component('ppSuitability', {
    templateUrl: 'widgets/_angular/suitability/suitability.tpl.html',
    bindings: {
        quizType: '<',
        suitability: '<',
        onSubmit: '&',
        unfailedQuizzesLeft: '<',
        allQuizzes: '<',
        onCancel: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'R', function ($scope, R) {
        var vm = this;

        // -- initial state

        // -- util functions

        var isArray = R.is(Array);

        function setStep(step) {
            vm.step = step;
        }

        function init(suitability, allQuizzes, unfailedQuizzesLeft) {
            var status = R.prop('status', suitability);
            if (status === 'failed') {
                setStep('suitability');
            } else if (status !== 'passed') {
                if (isArray(allQuizzes) && isArray(unfailedQuizzesLeft) && (R.length(unfailedQuizzesLeft) - R.length(allQuizzes) !== 0)) {
                    setStep('pre-suitability-previously-failed');
                } else {
                    setStep('pre-suitability');
                }

            }
        }

        // -- api

        vm.goToQuiz = function () {
            setStep('suitability');
        };

        vm.onEscapeBeforeQuiz = function () {
            vm.onCancel();
        };

        vm.submit = function (answers) {
            return vm.onSubmit({
                answers: answers
            }).then(function (res) {
                init(res.suitability, res.allQuizzes, res.unfailedQuizzesLeft);
            });
        };

        // -- scope bindings

        vm.$onInit = function () {
            init(vm.suitability, vm.allQuizzes, vm.unfailedQuizzesLeft);
        };

        // -- main

    }]
});
})(window, window.angular);