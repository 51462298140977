(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.live-item', ['pp.widgets-templates', 'yaru22.angular-timeago']);

ngModule.directive('ppLiveItem', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/live-item/live-item.tpl.html',
        scope: {
            propertyName: '@',
            symbol: '@',
            timeAgoPrefix: '@',
            timestamp: '<',
            details: '<',
            onClick: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            vm.trackLinkClicked = function (symbol) {
                vm.onClick({
                    symbol: symbol
                });
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);