(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.comparison-graph', [
    'pp.widgets-templates',
    'pp.services.core'
]);

ngModule.component('ppComparisonGraph', {
    templateUrl: 'widgets/_angular/comparison-graph/comparison-graph.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$element', 'ppComponentEmitter', function ($element, ppComponentEmitter) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        vm.$postLink = function () {
            ppComponentEmitter.emit('comparison-graph.dom.loaded', $element);
        };

    }]
});
})(window, window.angular);