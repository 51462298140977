(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-individual-property-figures', [
    'pp.widgets-templates',
    'pp.components.tooltip',
    'pp.services.marketplace',
    'pp.values.dashboard',
    'pp.values.glossary'
]);

ngModule.component('ppDashboardIndividualPropertyFigures', {
    templateUrl: 'widgets/_angular/dashboard-individual-property-figures/dashboard-individual-property-figures.tpl.html',
    bindings: {
        property: '<',
        holding: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'dashboard', 'glossary', 'marketplaceService', function ($scope, dashboard, glossary, marketplaceService) {
        var vm = this;

        // -- initial state

        vm.dashboard = dashboard;

        // -- util functions

        function setupTooltips(property) {

            vm.glossary = glossary;

            if (property.isPrimary) {
                vm.netIncomeYield = glossary.netIncomeYieldPrimary.desc;
            }

            if (property.isSecondaryTrading || property.isSecondaryInformational) {
                vm.netIncomeYield = glossary.netIncomeYieldSecondary.desc;
            }

        }

        // -- api

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            setupTooltips(vm.property);
            if (vm.property.isRightsIssueParent) {
                vm.yourProportionOfRaise = marketplaceService.calculateYourProportionOfRaise(vm.holding, vm.property);
                vm.yourProportionOfRaiseShares = marketplaceService.calculateYourProportionOfRaiseShares(vm.holding, vm.property);
            }

        };

    }]
});
})(window, window.angular);