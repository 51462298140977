(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.recaptcha-badge', ['pp.widgets-templates']);

ngModule.component('ppRecaptchaBadge', {
    templateUrl: 'widgets/_angular/recaptcha-badge/recaptcha-badge.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);