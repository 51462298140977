(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-sidenav', ['pp.widgets-templates']);

ngModule.component('ppDashboardSidenav', {
    templateUrl: 'widgets/_angular/dashboard-sidenav/dashboard-sidenav.tpl.html',
    bindings: {
        menuItems: '<',
        investor: '<',
        isIsaSetup: '<',
        investorKindMap: '<',
        switchAccounts: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api
        vm.switchAccountsClick = function (investor) {
            vm.switchAccounts({
                investor: investor
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);