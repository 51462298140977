(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.input-ni-number', [
    'pp.components-templates',
    'ng.cork.ui.focus-on'
]);

/**
 * @ngdoc directive
 * @name ppNiNumber
 * @description
 *
 * @restrict A
 * @requires ngModel
 *
 */
ngModule.component('ppInputNiNumber', {
    templateUrl: 'components/_angular/input-ni-number/input-ni-number.tpl.html',
    bindings: {
        niNumber: '='
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', function ($scope, $element) {
        var vm = this;

        // -- initial state

        vm.regex = /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/i;

        function formatNiNumber(niNumber) {
            if (niNumber && angular.isString(niNumber)) {
                return niNumber.replace(/\s/g, '').match(/.{0,2}/g).join(' ').trim();
            }
        }

        function uppercaseNiNumber() {
            if (vm.niNumber) {
                vm.niNumber = vm.niNumber.toUpperCase();
            }
        }

        // -- api

        vm.inputFocused = function () {
            vm.focused = true;
        };

        vm.inputBlurred = function () {
            uppercaseNiNumber();
            vm.niNumberFormatted = formatNiNumber(vm.niNumber);
            vm.focused = false;
        };

        vm.focusInput = function () {
            vm.focused = true;
            $scope.$broadcast('focus-ni-number');
        };

        // -- lifecycle

        vm.$postLink = function () {
            vm.focused = $($element.find()).is(':focus');
        };

        vm.$onInit = function () {
            uppercaseNiNumber();
            vm.niNumberFormatted = formatNiNumber(vm.niNumber);
        };

    }]
});
})(window, window.angular);