(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketing-preference-card', [
    'pp.widgets-templates',
    'pp.components.checkbox',
]);

ngModule.component('ppMarketingPreferenceCard', {
    templateUrl: 'widgets/_angular/marketing-preference-card/marketing-preference-card.tpl.html',
    bindings: {
        ppId: '@',
        data: '<',
        isDisabled: '<',
        isSelected: '=',
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);