(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.auth-signup-form', [
    'ng.cork.ui.focus-on',
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.auth',
    'pp.services.pin',
    'pp.services.marketing',
    'pp.services.preference',
    'pp.services.investor',
    'pp.widgets.risk-warning-badge',
    'pp.widgets.auth-pin-setup',
    'pp.widgets.welcome-signup',
    'pp.components.bind-invalid-model',
    'pp.components.focus-first-error',
    'pp.components.pin-validation',
    'pp.components.tabs',
    'pp.widgets.auth-signup-form-fields',
    'pp.widgets.recaptcha-badge',
    'pp.widgets.source-of-funds',
    'pp.widgets.take-time-to-learn-more'
]);

var SIGNUP_REDIRECT_DEFAULT = '/s';
var TAB_VALUE_PERSONAL = 'Personal';
var TAB_KEY_PERSONAL = 'personal';
var TAB_VALUE_BUSINESS = 'Business';
var TAB_KEY_BUSINESS = 'business';
var STEP_SIGNUP = 'signup';
var STEP_WELCOME = 'welcome-signup';
var STEP_TAKE_TIME_TO_LEARN_MORE = 'take-time-to-learn-more';
var STEP_SOURCE_OF_FUNDS = 'source-of-funds';
var STEP_PIN = 'pin-setup';
var FORM_NAME = 'auth.signup';
var PIN_ENTRY_FOCUS = 'pp.pin-setup.pin-entry.focus';

/**
 * @ngdoc directive
 * @name ppAuthSignupForm
 * @description
 * Displays a signup dialog.
 *
 * @restrict A
 * @scope
 * @param {string} rawEmail Shares between login and signup even when invalid
 * @param {function} onSuccess Invoked if signup is successful
 * @param {function} onToggleMode Invoked if user wishes to login instead
 */
ngModule.directive('ppAuthSignupForm', ['ppTrack', function (ppTrack) {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/auth-signup-form/auth-signup-form.tpl.html',
        scope: {
            rawEmail: '=',
            onSuccess: '&',
            onToggleMode: '&',
            onChangeStep: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$q', '$timeout', '$element', '$window', 'ppUtil', 'ppConfig', 'ppBrowser', 'ppCookie', 'authService', 'pinService', 'ppComponentEmitter', 'marketingService', 'R', 'preferenceService', 'investorService', function ($scope, $q, $timeout, $element, $window, ppUtil, ppConfig, ppBrowser, ppCookie, authService, pinService, ppComponentEmitter, marketingService, R, preferenceService, investorService) {
            var vm = this;

            // -- initial state

            var __config = ppConfig.get('pp.modules.auth') || {};
            var __destroyed;
            var urlParams = ppUtil.queryStringToObject($window.location.search);

            vm.activeTab = urlParams.type === TAB_KEY_BUSINESS ? TAB_KEY_BUSINESS : TAB_KEY_PERSONAL;

            vm.tabs = [{
                title: TAB_VALUE_PERSONAL,
                key: TAB_KEY_PERSONAL
            }, {
                title: TAB_VALUE_BUSINESS,
                key: TAB_KEY_BUSINESS
            }];

            // -- util functions

            function setStep(step) {
                ppTrack.flowStep('auth.signup', step);
                vm.step = step;
                vm.onChangeStep({
                    step: step
                });
            }

            function handleSignupSuccess() {
                // preload marketing preferences as this call can be slow ... marketing cloud
                marketingService.getMarketingPreference();

                var context = ppTrack.getContext() || {};
                preferenceService.storeSignupCountry(R.path(['client.country'], context));

                ppTrack.formSubmit(FORM_NAME);
                //disable close on signup modal
                try {
                    $.modal.disableClose();
                } catch (error) {}

                setStep(STEP_PIN);
                $timeout(function () {
                    $scope.$broadcast(PIN_ENTRY_FOCUS);
                });
            }

            function handleSignupFailure(error) {
                ppTrack.formError(FORM_NAME, error.reason);
                return $q.reject(error);
            }

            // -- api

            vm.toggleMode = function () {
                if (!vm.processing && !__destroyed) {
                    vm.onToggleMode({
                        mode: 'login'
                    });
                }
            };

            //check for valid form, get email password, name and and signup up user then set step to pin
            vm.submit = function (userData, recaptchaResponse) {
                if (!__destroyed && !vm.processing) {
                    vm.error = null;
                    if (vm.activeTab === TAB_KEY_BUSINESS) {
                        return authService.signupBusiness(userData.companyName, userData.email, userData.password, userData.firstName, userData.lastName, userData.phoneNumber, recaptchaResponse).then(handleSignupSuccess, handleSignupFailure);
                    } else {
                        return authService.signupIndividual(userData.email, userData.password, userData.firstName, userData.lastName, userData.phoneNumber, recaptchaResponse).then(handleSignupSuccess, handleSignupFailure);
                    }
                }
            };

            //after pin is valid send AJAX call to 'update' pin the set step to activated
            vm.onPinSetup = function () {
                vm.processing = true;
                pinService.updatePin(vm.pin).then(function () {
                    if (!__destroyed) {
                        ppTrack.action('auth.signup.pin-activated.continue');
                        return investorService.getInvestor().then(function (user) {
                            vm.user = user;
                            setStep(STEP_TAKE_TIME_TO_LEARN_MORE);
                        });
                    }
                }).catch(function (error) {
                    ppTrack.formError(FORM_NAME, error.reason);
                    vm.processing = false;
                    vm.error = error.reason || 'error.unexpected';
                });
            };

            vm.onTakeTimeToLearnMoreContinue = function () {
                return preferenceService.storeKeyRisksUnderstood().then(function () {
                    setStep(STEP_WELCOME);
                });
            };

            vm.onTakeTimeToLearnMoreCloseAccount = function () {
                return investorService.confirmPlatformUse(false).then(function () {
                    $window.location.href = '/logout';
                });
            };

            vm.welcomeSignupComplete = function () {
                ppTrack.action('auth.signup.welcome-signup.complete');
                setStep(STEP_SOURCE_OF_FUNDS);
            };

            vm.onSourceOfFundsComplete = function () {
                ppTrack.deferAction('auth.signup.source-of-funds.complete');
                vm.onSuccess({
                    fallbackPath: SIGNUP_REDIRECT_DEFAULT,
                    isSignup: true
                });
            };

            // -- scope bindings

            $scope.$on('$destroy', function () {
                ppTrack.formClose(FORM_NAME);
                __destroyed = true;
            });

            // -- main

            vm.$postLink = function () {
                ppComponentEmitter.emit('auth-signup-form.dom.loaded', $element);
            };

            vm.$onInit = function () {
                vm.email = vm.rawEmail;
                ppComponentEmitter.emit('auth-signup-form.controller.loaded', vm);
                setStep(STEP_SIGNUP);
            };

            ppTrack.formStart(FORM_NAME, null, true);

        }]
    };
}]);
})(window, window.angular);