(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-plan-detail-hero', ['pp.widgets-templates']);

ngModule.component('ppInvestmentPlanDetailHero', {
    templateUrl: 'widgets/_angular/investment-plan-detail-hero/investment-plan-detail-hero.tpl.html',
    bindings: {
        ppTitle: '<',
        description: '<',
        user: '<',
        icon: '<',
        link: '<',
        investmentPlan: '<',
        plan: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$anchorScroll', function ($scope, $anchorScroll) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.anchorScroll = $anchorScroll;

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);