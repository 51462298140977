(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.refer-a-friend-anon', [
    'pp.widgets-templates',
    'pp.components.tabs',
    'pp.widgets.refer-a-friend-reward-table',
    'pp.ui.services.authenticate-investor',
    'pp.services.route',
    'pp.services.core'
]);

var DEFAULT_TAB = 'wantToRefer';
var DEFAULT_REFER_PLACEHOLDER = 'e.g.christopher366';

var COMPONENT_NAME = 'refer-a-friend-anon';

ngModule.component('ppReferAFriendAnon', {
    templateUrl: 'widgets/_angular/refer-a-friend-anon/refer-a-friend-anon.tpl.html',
    bindings: {
        hasBeenReferred: '<',
        onSetReferralCode: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', 'ppTrack', 'authenticateInvestor', function ($scope, routeService, ppTrack, authenticateInvestor) {
        var vm = this;

        vm.tabs = [{
                title: 'I\'ve been referred',
                key: 'beenReferred'
            },
            {
                title: 'I want to refer',
                key: 'wantToRefer'
            }
        ];

        vm.activeTab = DEFAULT_TAB;

        vm.referralTermsLink = routeService.referralTermsLink;

        vm.referCodePlaceholder = DEFAULT_REFER_PLACEHOLDER;

        // -- initial state

        // -- util functions

        // -- api

        vm.setReferredByCode = function () {
            vm.setReferredByError = false;
            vm.submitted = false;

            if (vm.form.$valid) {
                vm.processing = true;

                var trackingCode = {
                    referCode: vm.referCode
                };

                ppTrack.action(COMPONENT_NAME + '.set-refer-code.submit', trackingCode);
                vm.onSetReferralCode({
                        code: vm.referCode
                    }).then(function () {
                        if (vm.hasBeenReferred) {
                            ppTrack.action(COMPONENT_NAME + '.set-refer-code.success', trackingCode);
                        } else {
                            ppTrack.action(COMPONENT_NAME + '.set-refer-code.refer-code-not-found', trackingCode);
                        }

                        vm.submitted = true;
                    })
                    .catch(function () {
                        vm.setReferredByError = true;
                    })
                    .finally(function () {
                        vm.processing = false;
                    });
            } else {
                ppTrack.ngFormValidation(vm.form);
            }
        };

        vm.onFocusReferCodeInput = function () {
            vm.referCodePlaceholder = '';
        };

        vm.onBlurReferCodeInput = function () {
            vm.referCodePlaceholder = DEFAULT_REFER_PLACEHOLDER;
        };

        vm.shareNow = function () {
            authenticateInvestor();
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);