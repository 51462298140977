(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.auth-signup-form-fields', [
    'vcRecaptcha',
    'ng.cork.ui.focus-on',
    'pp.widgets-templates',
    'pp.services.core',
    'pp.components.bind-invalid-model',
    'pp.components.input-password',
    'pp.components.focus-first-error',
    'pp.widgets.recaptcha-invisible'
]);

var AUTH_SIGNUP_FORM_NAME = 'auth.signup';
var SIGNUP_FORM_TYPE_PERSONAL = 'personal';
var SIGNUP_EXISTS_ERROR_CODE = 'auth.signup.exists';
var SIGNUP_INVALID_EMAIL_ERROR_CODE = 'auth.email.invalid';
var RECAPTCHA_RELOAD_EVENT_NAME = 'recaptcha.invisible.reload';
var FOCUS_ON_AUTO = 'auto';

ngModule.component('ppAuthSignupFormFields', {
    templateUrl: 'widgets/_angular/auth-signup-form-fields/auth-signup-form-fields.tpl.html',
    bindings: {
        rawEmail: '=',
        formType: '<',
        onSubmit: '&'
    },
    transclude: true,
    controllerAs: 'vm',
    controller: ['$scope', '$element', 'ppTrack', 'ppConfig', 'ppBrowser', 'ppComponentEmitter', 'vcRecaptchaService', function ($scope, $element, ppTrack, ppConfig, ppBrowser, ppComponentEmitter, vcRecaptchaService) {
        var vm = this;
        var __config = ppConfig.get('pp.modules.auth') || {};
        var __reCaptchaWidgetId;

        // -- initial state

        // -- util functions

        function clearErrors() {
            vm.feedbackEnabled = false;
            vm.generalError = false;
            vm.emailValidationError = false;
            vm.emailExists = false;
        }

        function resetRecaptcha() {
            $scope.$broadcast(RECAPTCHA_RELOAD_EVENT_NAME);
        }

        function handleError(error) {
            vm.generalError = error ? error.reason : null;
            vm.emailExists = (vm.generalError === SIGNUP_EXISTS_ERROR_CODE);
            vm.emailValidationError = (vm.generalError === SIGNUP_INVALID_EMAIL_ERROR_CODE);
        }

        function handleFinally() {
            vm.processing = false;
            resetRecaptcha();
        }

        // -- api

        vm.signUp = function (reCaptchaResponse) {
            vm.processing = true;
            vm.onSubmit({
                recaptchaResponse: reCaptchaResponse,
                userData: vm.data
            }).catch(handleError).finally(handleFinally);
        };

        vm.onCreateRecaptcha = function (widgetId) {
            __reCaptchaWidgetId = widgetId;
        };

        vm.submit = function () {
            if (!vm.processing) {
                if (vm.form.$valid && (angular.isDefined(__reCaptchaWidgetId) || !vm.reCaptchaEnabled)) {
                    clearErrors();
                    var next = vm.reCaptchaEnabled ? vcRecaptchaService.execute.bind(this, __reCaptchaWidgetId) : vm.signUp;
                    next();
                } else {
                    vm.feedbackEnabled = true;
                    ppTrack.ngFormValidation(AUTH_SIGNUP_FORM_NAME, vm.form, ['password', 'vm.ppInputPassword']);
                }
            }
        };

        // -- scope bindings

        $scope.$watch('vm.formType', function (newVal, oldVal) {
            if (newVal !== oldVal) {
                clearErrors();
            }
        });

        // -- main

        vm.$postLink = function () {
            ppComponentEmitter.emit('auth-signup-form-fields.dom.loaded', $element);
        };

        vm.$onInit = function () {
            vm.feedbackEnabled = false;
            vm.formType = vm.formType || SIGNUP_FORM_TYPE_PERSONAL;
            vm.reCaptchaKey = __config.reCaptchaInvisibleKey;
            vm.reCaptchaEnabled = __config.reCaptchaEnabled;
            vm.focusOn = ppBrowser.isMobile ? '' : FOCUS_ON_AUTO;
            vm.data = {
                email: vm.rawEmail
            };
            ppComponentEmitter.emit('auth-signup-form-fields.controller.loaded', vm);
        };

    }]
});
})(window, window.angular);