(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.wistia-inline-video', ['pp.widgets-templates']);

ngModule.component('ppWistiaInlineVideo', {
    templateUrl: 'widgets/_angular/wistia-inline-video/wistia-inline-video.tpl.html',
    bindings: {
        videoId: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$sce', function ($scope, $sce) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            vm.videoUrl = $sce.trustAsResourceUrl('https://fast.wistia.net/embed/iframe/' + vm.videoId + '?videoFoam=true');
        };

    }]
});
})(window, window.angular);