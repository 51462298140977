(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.hpi-range', ['pp.widgets-templates', 'pp.filters.numbers']);

ngModule.directive('ppHpiRange', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/hpi-range/hpi-range.tpl.html',
        scope: {
            range: '<ppHpiRange',
            hideAnnualised: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);