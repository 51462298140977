(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.data-view', [
    'pp.services.core',
    'pp.services.property'
]);

var DATA_VIEW_ENDPOINT = '/properties/data';
var TTL = 10000;

/**
 * @ngdoc service
 * @name dataService
 *
 * @description
 * Provides methods to invoke the `/resource/data` endpoints.
 */
ngModule.service('dataViewService', ['$http', '$q', 'propertyService', 'ppConfig', 'R', function ($http, $q, propertyService, ppConfig, R) {

    var promiseCache = {};

    var api = {};

    var __config = ppConfig.get('pp.services.data-view');
    var __endpoint = __config ? __config.json : DATA_VIEW_ENDPOINT;

    // -- util functions

    function freeze(data) {
        var ret = data.map(function (item) {
            return Object.freeze(item);
        });
        return Object.freeze(ret);
    }

    // -- api

    var indexBySymbol = R.indexBy(R.prop('symbol'));

    var normalisePropertyData = function (property) {
        var lastTradePrice = R.path(['market', 'secondary', 'lastTradePrice'], property);
        var lastTradePctChange = R.path(['market', 'lastTradePctChange'], property);

        return {
            symbol: property.symbol,
            lastTradePrice: lastTradePrice,
            lastTradePctChange: lastTradePctChange
        };
    };

    var preparePropertyData = R.compose(
        indexBySymbol,
        R.map(normalisePropertyData)
    );

    api.getData = function () {

        var cacheKey = 'data';
        var now;

        if (!promiseCache[cacheKey] || ((now = new Date() - promiseCache[cacheKey].timestamp) > TTL)) {

            var propertyPromise = propertyService.fetchPropertiesTile()
                .then(preparePropertyData);

            var datViewPromise = $http.get(__endpoint).then(function (response) {
                    return freeze(response.data);
                },
                function () {
                    return $q.reject({
                        reason: 'data-view.error.unexpected'
                    });
                });

            var promise = $q.all({
                dataView: datViewPromise,
                properties: propertyPromise
            }).then(function (res) {
                return res.dataView
                    .map(function (item) {
                        return R.mergeLeft(res.properties[item.symbol], item);
                    });
            });

            promiseCache[cacheKey] = {
                promise: promise,
                timestamp: now
            };
        }

        return promiseCache[cacheKey].promise;
    };

    api.transformDataAsPropertyMap = function (data) {
        var propertyMap = {};
        var ix;
        for (ix = 0; ix < data.length; ix++) {
            if (data[ix].symbol) {
                propertyMap[data[ix].symbol] = data[ix];
            }
        }
        return Object.freeze(propertyMap);
    };

    return api;
}]);
})(window, window.angular);