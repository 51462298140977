(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.isa-transfer-in-card', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.ui.services.isa-transfer-in-dialog',
    'pp.widgets.isa-transfer-in'
]);

ngModule.component('ppIsaTransferInCard', {
    templateUrl: 'widgets/_angular/isa-transfer-in-card/isa-transfer-in-card.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$timeout', 'ppTrack', 'isaTransferInDialogService', function ($scope, $timeout, ppTrack, isaTransferInDialogService) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.openForm = function () {
            ppTrack.action('transfer-isa-in-card.show-transfer-form.click');
            vm.startTransferIn = true;
            $timeout(function () {
                isaTransferInDialogService.show('#isa-transfer-in-goji-widget');
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);