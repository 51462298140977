(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.apo', ['pp.services.core', 'pp.services.preference']);

var API_BASE_PATH = '/feapi/r1';
var API_CONTENT_TYPE = 'application/json';
var API_ADD_APO_ENDPOINT = '/advanced-pre-order/add';
var API_GET_APO_ENDPOINT = '/advanced-pre-order/orders';
var API_INVESTMENT_PLAN_ORDERS = '/investor/investment-plan-orders';
var FUNDS_UPDATED_EVENT = 'apo-funds-updated';

ngModule.service('apoService', ['$http', '$q', 'ppEmitter', function ($http, $q, ppEmitter) {

    var api = {};
    var promiseCache = {};

    // stores callbacks per event and triggers them
    // trigger events via emitter.emit('name', arg1, ...)
    var emitter = ppEmitter.create();

    // adds .on() add off() methods to the service API, but bound to the internal emitter registry
    ppEmitter.mixin(api, emitter);

    function advancedPreOrderDataAccumulator(result, orderData) {
        result[orderData.assetType] = result[orderData.assetType] || 0;
        result[orderData.assetType] += orderData.amountCommitted;
        return result;
    }

    /*
     * @ngdoc method
     * @name setAdvancedPreOrderForInvestor
     *
     * @description
     * Sets an advanced preorder for investor
     *
     * Success response:
     * ```
     * {
     *     ?
     * }
     * ```
     *
     * @returns {promise}
     */
    function setAdvancedPreOrderForInvestor(data) {
        var endpoint = API_BASE_PATH + API_ADD_APO_ENDPOINT;
        var options = {};
        options.headers = {
            'Content-type': API_CONTENT_TYPE
        };

        return $http.post(endpoint, data, options).then(function (response) {
            return response.data;
        });
    }

    /*
     * @ngdoc method
     * @name insertApoCardInArrayPosition
     *
     * @description
     * Inserts apo card data into the given array and position
     *
     * Success response:
     * ```
     * {
     *     ?
     * }
     * ```
     *
     */
    function insertApoCardInArrayPosition(origArray, positionData) {
        origArray = origArray || [];
        if (!positionData.position || !positionData.type) {
            return origArray;
        }

        var idx = positionData.position - 1;
        var slice1 = origArray.slice(0, idx);
        var slice2 = origArray.slice(idx, origArray.length);
        return slice1.concat([{
            apo: true,
            type: positionData.type
        }]).concat(slice2);
    }

    /*
     * @ngdoc method
     * @name getAdvancedPreOrderForInvestor
     *
     * @description
     * Gets advanced preorder info for investor
     *
     * Success response:
     * ```
     * {
     *     ?
     * }
     * ```
     *
     * @returns {promise}
     */

    function getAdvancedPreOrderForInvestor() {
        var cacheKey = 'get-apo';
        var endpoint = API_BASE_PATH + API_GET_APO_ENDPOINT;
        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint).then(function (response) {
                var orders = angular.isArray(response.data.orders) ? response.data.orders : [];
                var result = orders.reduce(advancedPreOrderDataAccumulator, {});
                emitter.emit(FUNDS_UPDATED_EVENT, result);
                return result;
            }, function () {
                delete promiseCache[cacheKey];
                return $q.reject({
                    reason: 'apo.error.unexpected'
                });
            });
        }

        return promiseCache[cacheKey];
    }

    /*
     * @ngdoc method
     * @name getAdvancedPreOrderAmount
     *
     * @description
     * Gets advanced preorder committed amount for an investor
     *
     * Success response:
     * ```
     * int
     * ```
     *
     * @returns {promise}
     */
    function getAdvancedPreOrderAmount() {
        return getAdvancedPreOrderForInvestor().then(function (apoData) {
            return Object.keys(apoData).map(function (type) {
                return apoData[type];
            }).reduce(function (acc, elem) {
                return acc + elem;
            }, 0);
        }, function () {
            return 0;
        });
    }

    /**
     * @ngdoc method
     * @name apoService#purgeCache
     *
     * @description
     * Purges cached promises of requests made for apo.
     *
     * @param {string} pattern If provide with no arguments, an empty string or `.*`, purges everything.
     */
    function purgeCache(pattern) {
        if (pattern) {
            pattern = new RegExp(pattern);
        }
        for (var prop in promiseCache) {
            if (!pattern || pattern.test(prop)) {
                delete promiseCache[prop];
            }
        }
    }

    function createInvestmentPlanOrder(payload) {
        var endpoint = API_BASE_PATH + API_INVESTMENT_PLAN_ORDERS;

        return $http.post(endpoint, payload, {}).then(function (response) {
            return response.data;
        });
    }

    api.createInvestmentPlanOrder = createInvestmentPlanOrder;
    api.purgeCache = purgeCache;
    api.insertApoCardInArrayPosition = insertApoCardInArrayPosition;
    api.setAdvancedPreOrderForInvestor = setAdvancedPreOrderForInvestor;
    api.getAdvancedPreOrderForInvestor = getAdvancedPreOrderForInvestor;
    api.getAdvancedPreOrderAmount = getAdvancedPreOrderAmount;
    return api;
}]);
})(window, window.angular);