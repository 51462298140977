(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.date', [
    'pp.services.core'
]);

var DATE_FORMATS = [
    'DDMMYY',
    'DDMMYYYY',
    'D/M/YYYY',
    'DD/MM/YYYY',
    'DD/MM/YY',
    'D/MM/YY',
    'D/M/YY',
    'D/M/YYYY',
    'DD-MM-YYYY',
    'DD-MM-YY',
    'D-M-YYYY',
    'D-M-YY',
    'D.M.YYYY',
    'D.M.YY',
    'DD.MM.YYYY',
    'DD.MM.YY',
    'D.M.YYYY',
    'D.M.YY',
    'D M YYYY',
    'D M YY',
    'DD MM YYYY',
    'DD MM YY',
    'Do MMMM YYYY',
    'Do MMM YYYY'
];

/**
 * @ngdoc service
 * @name dateService
 *
 * @description
 * .
 */
ngModule.service('dateService', ['ppMoment', function (ppMoment) {

    var api = {};

    api.parseDateInThePast = function (date) {
        var originalParseTwoDigitYear = ppMoment.parseTwoDigitYear;
        ppMoment.parseTwoDigitYear = function (year) {
            var yearsSinceMillenium = ppMoment().year() - 2000;
            if (year >= yearsSinceMillenium) {
                return parseInt(year, 10) + 1900;
            } else {
                return parseInt(year, 10) + 2000;
            }
        };
        var result = ppMoment(date, DATE_FORMATS, true);
        ppMoment.parseTwoDigitYear = originalParseTwoDigitYear;
        return result;
    };

    function createTaxDates(endYear) {
        return [
            new Date(endYear - 1, 3, 6),
            new Date(endYear, 3, 5)
        ];
    }

    // LEGACY function only used in the tax statement component
    // TODO refactor to be similar to getCurrentTaxYear and return an array of dates
    api.getPreviousTaxYear = function () {
        var currentTime = new Date();
        var day = currentTime.getDate();
        var month = currentTime.getMonth() + 1;
        var year = currentTime.getFullYear();
        if (month < 4) {
            return ['06-04-' + (year - 2), '05-04-' + (year - 1)];
        } else if (month > 4) {
            return ['06-04-' + (year - 1), '05-04-' + year];
        } else {
            if (day < 5) {
                return ['06-04-' + (year - 2), '05-04-' + (year - 1)];
            } else {
                return ['06-04-' + (year - 1), '05-04-' + year];
            }
        }
    };

    api.getCurrentTaxYear = function () {
        var currentTime = new Date();
        var day = currentTime.getDate();
        var month = currentTime.getMonth() + 1;
        var year = currentTime.getFullYear();

        if (month < 4) {
            return createTaxDates(year);
        } else if (month > 4) {
            return createTaxDates(year + 1);
        } else {
            if (day < 5) {
                return createTaxDates(year);
            } else {
                return createTaxDates(year + 1);
            }
        }
    };

    api.getTaxYearFormatted = function (startYearFormat, endYearFormat, divider) {
        var taxYear = api.getCurrentTaxYear();
        return ppMoment(taxYear[0]).format(startYearFormat) + divider + ppMoment(taxYear[1]).format(endYearFormat);
    };

    return api;
}]);
})(window, window.angular);