(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-promo', ['pp.widgets-templates', 'pp.filters.numbers']);

ngModule.directive('ppPropertyDetailsPromo', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-details-promo/property-details-promo.tpl.html',
        scope: {
            property: '<ppPropertyDetailsPromo'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);