(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.auth-signup-cta', ['pp.widgets-templates', 'pp.services.core']);

var DEFAULT_LABEL = 'Sign up';
var DEFAULT_CLASS = 'btn pri';

/**
 * @ngdoc directive
 * @name ppAuthSignupCta
 * @description
 * Renders a signup button (if the user is anon) or transcluded content (if user is logged in)
 *
 * @restrict A
 * @scope
 */

ngModule.component('ppAuthSignupCta', {
    templateUrl: 'widgets/_angular/auth-signup-cta/auth-signup-cta.tpl.html',
    transclude: true,
    bindings: {
        btnLabel: '@?',
        btnClass: '@?',
        user: '<'
    },
    controllerAs: 'vm',
    controller: ['$timeout', 'ppPubSub', function ($timeout, ppPubSub) {
        var vm = this;

        // -- initial state

        // -- util functions

        function init(user) {
            if (!user || !angular.isObject(user)) {
                return;
            }

            vm.ready = true;
            vm.newUser = user.stage && user.stage === 'initial';
        }

        // -- api

        vm.signupClick = function () {
            ppPubSub.publish('auth.modal.show', ['signup']);
        };

        // -- scope bindings

        vm.$onChanges = function (bindings) {
            if (bindings.user) {
                init(bindings.user.currentValue);
            }
        };

        vm.$onInit = function () {
            vm.btnLabel = vm.btnLabel || DEFAULT_LABEL;
            vm.btnClass = vm.btnClass || DEFAULT_CLASS;
        };

        // -- main

    }]
});
})(window, window.angular);