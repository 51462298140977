(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.shares-order-book-chart', ['highcharts-ng', 'pp.widgets-templates', 'pp.ui.services.shares-order-liquidity-dialog', 'pp.services.property', 'pp.components.tooltip', 'pp.values.colours', 'pp.values.glossary']);

var MIN_ZOOM_LEVEL = 0;
var MAX_ZOOM_LEVEL = 3;
var MAX_POINTS_IN_OFFERS_BIDS_LEVEL_1 = 1;
var MAX_POINTS_IN_OFFERS_BIDS_LEVEL_2 = 4;
var MAX_POINTS_IN_OFFERS_BIDS_LEVEL_3 = 10;

/**
 * @ngdoc directive
 * @name sharesAvailableChart
 * @description
 * Render the shares available chart
 *
 * @restrict A
 * @scope
 * @param {String} symbol
 *
 * @todo controllerAs and vm
 * @todo use chart factory
 */
ngModule.directive('ppSharesOrderBookChart', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/shares-order-book-chart/shares-order-book-chart.tpl.html',
        scope: {
            symbol: '@',
            priceLimits: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$filter', '$q', 'propertyService', 'sharesOrderLiquidityDialogService', 'colours', 'glossary', function sharesAvailableChartCtrl($scope, $timeout, $filter, $q, propertyService, sharesOrderLiquidityDialogService, colours, glossary) {
            var vm = this;

            // -- initial state

            vm.ready = false;
            vm.visible = false;
            vm.loadingError = null;
            vm.sharesAvailable = null;
            vm.overlay = false;

            vm.zoomLevel = 1;
            vm.minZoomLevel = MIN_ZOOM_LEVEL;
            vm.maxZoomLevel = MAX_ZOOM_LEVEL;
            vm.glossary = glossary;
            var zoomLevelsData;

            // -- util functions

            function getMaxUnits(orders) {
                return orders.reduce(function (max, order) {
                    return Math.abs(order.y) > max ? Math.abs(order.y) : max;
                }, 0);
            }

            function getPointPadding() {
                if (vm.zoomLevel === 0) {
                    return -0.25;
                }

                return -0.15;
            }

            function getTickInterval() {
                if (!vm.zoomLevel) {
                    return 1;
                }
                return 1 / vm.zoomLevel * 2;
            }

            function setTicks(data) {
                if (vm.zoomLevel === 0) {
                    var tickPositions = [];
                    for (var ix = 0; ix < data.length; ix++) {
                        tickPositions.push(data[ix].x);
                    }
                    vm.chartOptions.xAxis.tickPositions = tickPositions;
                } else {
                    vm.chartOptions.xAxis.tickInterval = getTickInterval();
                }
            }

            function buildChart(chartData, max) {
                vm.chartOptions = {
                    chart: {
                        type: 'bar',
                        height: 175,
                        color: '#000',
                        backgroundColor: 'rgba(255,255,255,0)',
                        style: {
                            fontFamily: 'Proxima'
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            pointPadding: getPointPadding(),
                            point: {
                                events: {
                                    mouseOver: function (e) {
                                        var self = this;
                                        $scope.$evalAsync(function () {
                                            vm.selectedType = self.orderType;
                                            vm.selectedPrice = self.x + 'p';
                                            vm.selectedInLimits = self.inLimits;
                                            vm.selectedTotalAvailable = $filter('currency')(Math.abs(self.y), '£');
                                        });
                                    },
                                    mouseOut: function (e) {
                                        $scope.$evalAsync(function () {
                                            vm.selectedPrice = false;
                                            vm.selectedTotalAvailable = false;
                                        });
                                    }
                                }
                            }
                        }
                    },
                    title: {
                        text: null,
                        style: {
                            'color': '#FFF'
                        }
                    },
                    xAxis: {
                        title: {
                            text: null
                        },
                        reversed: false,
                        labels: {
                            formatter: function () {
                                return this.value.toFixed(0) + 'p';
                            }
                        },
                        tickLength: 0,
                        tickWidth: 0
                    },
                    yAxis: {
                        title: {
                            text: null
                        },
                        labels: {
                            formatter: function () {
                                var num = Math.abs(this.value);
                                if (num >= 1000000) {
                                    return '£' + (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                                }
                                if (num >= 1000) {
                                    return '£' + (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
                                }
                                return '£' + num;
                            }
                        },
                        lineWidth: 1,
                        minorGridLineWidth: 0,
                        gridLineWidth: 0,
                        tickLength: 0,
                        tickWidth: 0,
                        min: -max,
                        max: max,
                        plotLines: [{
                            color: '#BFBFBF',
                            width: 1,
                            value: 0,
                            zIndex: 10
                        }]
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        data: chartData,
                        showInLegend: false,
                        minPointLength: 4
                    }]
                };
                setTicks(chartData);
            }

            function isInLimits(order) {
                if (vm.priceLimits) {
                    if (order.askPrice > vm.priceLimits.upperBound || order.askPrice < vm.priceLimits.lowerBound) {
                        return false;
                    }
                }
                return true;
            }

            function getOrderColor(order) {
                if (!isInLimits(order)) {
                    return colours.$gry1_2;
                } else {
                    return order.orderType === 'ASK' ? colours.offer : colours.bid;
                }
            }

            function prepareOrderForGraph(order) {

                return {
                    x: Math.round(order.askPrice * 100),
                    y: order.orderType === 'ASK' ? order.units * order.askPrice : -order.units * order.askPrice,
                    color: getOrderColor(order),
                    orderType: order.orderType,
                    inLimits: isInLimits(order)
                };
            }

            function limitItems(items, limit, fromEndOfArray) {
                if (fromEndOfArray) {
                    return items.slice(limit * -1);
                }
                return items.slice(0, limit);
            }

            function loadData() {

                return propertyService.getSecondaryAvailableOffers(vm.symbol).then(function (offersAndBids) {

                    var offers = offersAndBids.filter(function (order) {
                        return order.orderType === 'ASK';
                    });

                    var bids = offersAndBids.filter(function (order) {
                        return order.orderType === 'BID';
                    });

                    offers = offers.sort(function (a, b) {
                        return a.askPrice - b.askPrice;
                    });

                    bids = bids.sort(function (a, b) {
                        return a.askPrice - b.askPrice;
                    });

                    if (offersAndBids.length > 0) {

                        var bidsLevel1 = limitItems(bids, MAX_POINTS_IN_OFFERS_BIDS_LEVEL_1, true);
                        var offersLevel1 = limitItems(offers, MAX_POINTS_IN_OFFERS_BIDS_LEVEL_1);
                        var ordersLevel1 = bidsLevel1.concat(offersLevel1).map(prepareOrderForGraph) || [];

                        var bidsLevel2 = limitItems(bids, MAX_POINTS_IN_OFFERS_BIDS_LEVEL_2, true);
                        var offersLevel2 = limitItems(offers, MAX_POINTS_IN_OFFERS_BIDS_LEVEL_2);
                        var ordersLevel2 = bidsLevel2.concat(offersLevel2).map(prepareOrderForGraph) || [];

                        var bidsLevel3 = limitItems(bids, MAX_POINTS_IN_OFFERS_BIDS_LEVEL_3, true);
                        var offersLevel3 = limitItems(offers, MAX_POINTS_IN_OFFERS_BIDS_LEVEL_3);
                        var ordersLevel3 = bidsLevel3.concat(offersLevel3).map(prepareOrderForGraph) || [];
                        var ordersLevel4 = bids.concat(offers).map(prepareOrderForGraph) || [];

                        zoomLevelsData = [ordersLevel1, ordersLevel2, ordersLevel3, ordersLevel4];

                        vm.sharesAvailable = true;

                    } else {
                        vm.sharesAvailable = false;
                    }
                    return $timeout(function () {
                        return vm.sharesAvailable;
                    });
                });
            }

            function reflowChart() {
                $timeout(function () {
                    vm.visible = true;
                }).then(function () {
                    try {
                        $scope.$broadcast('highchartsng.reflow');
                    } catch (err) {}
                });
            }

            function loadChart() {
                return loadData().then(function (sharesAvailable) {
                    if (sharesAvailable) {
                        buildChart(zoomLevelsData[vm.zoomLevel], getMaxUnits(zoomLevelsData[vm.zoomLevel]));
                    }
                    reflowChart();
                });
            }

            function bindDataEmitter(symbol) {
                propertyService.on(symbol + '.secondary-offers-updated', function () {
                    loadChart().then(function () {
                        reflowChart();
                    }, function () {
                        vm.loadingError = true;
                    });
                });
            }

            // -- api

            vm.viewLiquidityData = function () {
                sharesOrderLiquidityDialogService.show(vm.symbol);
            };

            vm.toggleOverlay = function () {
                vm.overlay = !vm.overlay;
            };

            vm.zoomGraphOut = function () {
                if (vm.zoomLevel < zoomLevelsData.length - 1) {
                    vm.zoomLevel++;
                    var data = zoomLevelsData[vm.zoomLevel];
                    $timeout(function () {
                        buildChart(data, getMaxUnits(data));
                        $scope.$broadcast('highchartsng.reflow');
                    });
                }
            };

            vm.zoomGraphIn = function () {
                if (vm.zoomLevel > 0) {
                    vm.zoomLevel--;
                    var data = zoomLevelsData[vm.zoomLevel];
                    $timeout(function () {
                        buildChart(data, getMaxUnits(data));
                        $scope.$broadcast('highchartsng.reflow');
                    });
                }
            };

            // -- scope binding

            $scope.$on('pp.widgets.shares-order-book-chart.refresh', loadChart);

            // -- main

            vm.$onInit = function () {
                loadChart().then(function () {
                    vm.ready = true;
                    reflowChart();
                    bindDataEmitter(vm.symbol);
                }, function () {
                    vm.ready = true;
                    vm.loadingError = true;
                });
            };

        }]
    };
}]);
})(window, window.angular);