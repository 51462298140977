(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.time-left-unit', [
    'pp.services.core'
]);

/**
 * @ngdoc directive
 * @name ppTimeLeftUnit
 * @description
 * Renders the time left into a span
 *
 * @restrict A
 * @scope
 * @param {string} ppTimeLeftUnit
 */

ngModule.directive('ppTimeLeftUnit', function () {
    return {
        restrict: 'A',
        template: '<span ng-bind="vm.timeLeftUnit"></span>',
        scope: {
            dueDate: '=ppTimeLeftUnit'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppMoment', function ($scope, ppMoment) {
            var vm = this;

            function calcuateTimeUnit(msLeft, msPerUnit) {
                return Math.floor(msLeft / msPerUnit);
            }

            // TODO refactor to use momentjs and write tests to cover
            function timeLeftUnit(startDate, endDate) {

                var msPerDay = 86400000;
                var msPerHour = 3600000;
                var msPerMinute = 60000;

                var timeUnit;

                var startTime = startDate.getTime();
                var endTime = Number(ppMoment(endDate).format('x'));

                if (!endTime) {
                    return '-';
                }

                var msLeft = endTime - startTime;

                if (msLeft <= 0) {
                    timeUnit = 0;
                } else if (msLeft <= msPerHour) {
                    timeUnit = calcuateTimeUnit(msLeft, msPerMinute);
                    //If there are only seconds left show one minute remaining
                    timeUnit = timeUnit || 1;
                } else if (msLeft <= msPerDay) {
                    timeUnit = calcuateTimeUnit(msLeft, msPerHour);
                } else {
                    timeUnit = calcuateTimeUnit(msLeft, msPerDay);
                }

                return timeUnit;
            }

            // -- initial state

            // -- api

            // -- main
            vm.$onInit = function () {
                $scope.$watch('vm.dueDate', function (value) {
                    if (angular.isDefined(value)) {
                        vm.timeLeftUnit = timeLeftUnit(window.pp.serverTime, vm.dueDate);
                    }
                });
            };

        }]
    };
});
})(window, window.angular);