(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.mfa-settings', ['pp.widgets-templates', 'pp.widgets.mfa-settings-dialog', 'pp.services.core', 'pp.services.investor', 'pp.services.auth', 'pp.services.mfa']);

/**
 * * **EVENTS**

 *
 * @todo
 */

ngModule.config(['$compileProvider', function ($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|otpauth):/);
}]);

ngModule.directive('ppMfaSettings', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/mfa-settings/mfa-settings.tpl.html',
        scope: {
            investor: '<',
            secret: '=',
            mfa: '='
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$q', 'mfaSettingsDialogService', 'ppConfig', 'ppBrowser', 'ppTrack', 'investorService', 'authService', 'mfaService', function ($scope, $q, mfaSettingsDialogService, ppConfig, ppBrowser, ppTrack, investorService, authService, mfaService) {
            var vm = this;

            // -- initial state
            var __endpoints = ppConfig.get('pp.external.endpoints') || {};
            var assetsEndpoint = __endpoints.assets;
            var secretUrlBase = 'otpauth://totp/PropertyPartner:{email}?secret={secret}&issuer=PropertyPartner';
            var email;

            vm.imageLogo = __endpoints.assets + '/images/mfa/ga-logo.png';
            vm.isMobile = ppBrowser.isMobile;

            // -- util functions

            function refreshSecret() {
                return mfaService.requestNewMfaSecret().then(function (secret) {
                    vm.secret = secret;
                    vm.secretUrl = secretUrlBase.replace('{email}', email).replace('{secret}', vm.secret);
                });
            }

            function getAuthMethod() {
                authService.getAuthMethods().then(function (methods) {
                    vm.mfa = methods.indexOf('TwoFactor') > -1;
                    if (!vm.mfa) {
                        refreshSecret();
                    }

                });
            }

            // -- api

            vm.startSetup = function () {
                ppTrack.action('pp-mfa.start-setup.clicked');
                vm.processing = true;
                refreshSecret().then(function () {
                    vm.processing = false;
                    mfaSettingsDialogService.showSetup(email, vm.isMobile, vm.secret).then(function (res) {
                        getAuthMethod();
                    });
                });
            };

            vm.startRemoval = function () {
                ppTrack.action('pp-mfa.start-remove.clicked');
                mfaSettingsDialogService.showRemove().then(function (res) {
                    getAuthMethod();
                });
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                email = vm.investor ? vm.investor.primaryEmail : null;

                if (email && !vm.mfa) {
                    vm.secretUrl = secretUrlBase.replace('{email}', email).replace('{secret}', vm.secret);
                }
            };

        }]
    };
}]);
})(window, window.angular);