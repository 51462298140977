(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.isa-blocked-business', [
    'pp.widgets-templates',
    'pp.services.route'
]);

ngModule.component('ppIsaBlockedBusiness', {
    templateUrl: 'widgets/_angular/isa-blocked-business/isa-blocked-business.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', function ($scope, routeService) {
        var vm = this;

        // -- initial state

        vm.marketplacePath = routeService.marketplacePath;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);