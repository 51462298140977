(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.nationality-lei-dialog', ['pp.widgets-templates', 'pp.services.nationality', 'pp.services.investor-compliance', 'pp.widgets.nationality-lei']);

ngModule.directive('ppNationalityLeiDialog', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/nationality-lei-dialog/nationality-lei-dialog.tpl.html',
        scope: {
            user: '<',
            nationalitiesList: '<',
            onSuccess: '&',
            onDismiss: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'nationalityService', 'investorComplianceService', function ($scope, nationalityService, investorComplianceService) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            vm.onSubmitNationality = function (nationalityData) {
                return nationalityService.replaceInvestorNationalitiesAndIdentifiers(nationalityData).then(function () {
                    vm.onSuccess();
                });
            };

            vm.onSubmitLei = function (lei) {
                return investorComplianceService.getLeiType().then(function (leiType) {
                    return investorComplianceService.postLei(leiType.id, lei).then(function () {
                        vm.onSuccess();
                    });
                });
            };

            vm.cancel = function () {
                vm.onDismiss();
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);