(function(window, angular){'use strict';

var ngModule = angular.module('pp.values.glossary', []);

ngModule.value('glossary', {
    grossRentalIncome: {
        title: 'Gross Rental Income',
        desc: 'Gross Rental Income is the forecast or actual rental income achievable before any deductions whatsoever. For example, if we estimate letting the property for £1,000 per month, then forecast Gross Rental Income is £12,000 per annum.'
    },
    shareValuation: {
        title: 'Share Valuation',
        desc: 'The Latest Valuation of any investment consists of the Latest Valuation of the property plus the Unamortised Purchase Costs less Deferred Tax less any other liabilities and is expressed on a per share basis.'
    },
    netIncomeYieldPrimary: {
        title: 'Dividend Yield',
        desc: 'This is the projected yield on the net investment amount after AUM fee, paid monthly.'
    },
    netIncomeYieldSecondary: {
        title: 'Dividend Yield',
        desc: 'This is the projected yield for the Last Traded Price on the net investment amount after AUM fee, paid monthly.'
    },
    fiveYearHpi: {
        title: 'HPI',
        desc: 'The average change in property prices for this area over the past 5 years, according to the Land Registry House Price Index. Past performance is not a reliable indicator of future performance.'
    },
    netIncome: {
        title: 'Net Income',
        desc: 'Net Income is the Gross Rental Income less deductions for (i) property related costs (including property management, maintenance and insurance), (ii) corporation tax, and (iii) estimated likely void periods (i.e. when there are no tenants paying rent).'
    },
    grossRentalYield: {
        title: 'Gross Rental Yield',
        desc: 'Gross Rental Yield is the forecast Gross Rental Income expressed as a percentage of the valuation of the property at the beginning of the year.'
    },
    deferredTax: {
        title: 'Deferred Tax',
        desc: 'Deferred Tax is the accumulated corporation tax within the SPV resulting from an increase in the value of the property since purchase (i.e. the corporation tax rate multiplied by the valuation increase). Deferred Tax only becomes payable if and when the property is sold, and is considered a potential “liability” until that point.'
    },
    amortisedPurchaseCosts: {
        title: 'Amortised Purchase Costs',
        desc: 'Purchase costs include Stamp Duty Land Tax, third party professional fees (legal and surveyor), pre-letting expenses (including furnishing) and other third party costs relating to the purchase of a property.'
    },
    stampDuty: {
        title: 'Stamp Duty',
        desc: 'Stamp Duty Land Tax is an amount payable to HM Revenue & Customs on the purchase of property or land in the UK where the amount paid is above a certain threshold.'
    },
    serviceCharges: {
        title: 'Service Charges',
        desc: 'Not Applicable to Freehold Properties.'
    },
    realisedCapitalGain: {
        title: 'Realised Capital Gains',
        desc: 'The difference between the sales proceeds from an investment and its Investment Cost.'
    },
    latestValuationGains: {
        title: 'Latest Valuation Gains',
        desc: 'The difference between the Latest Valuation and the valuation at the time of purchase.'
    },
    transactionsCosts: {
        title: 'Transactions Costs Paid',
        desc: 'This is not an additional cost. This is the total fees paid when you originally made your investment on the New Listing or Resale market.'
    },
    premium: {
        title: 'Premium on Purchase',
        desc: 'The difference between the amount paid for a Resale investment and its Latest Valuation at the time of purchase. When the amount paid is greater than the Latest Valuation at the time of purchase, it is a premium.'
    },
    promotions: {
        title: 'Promotions',
        desc: 'The total you have earned from cashback, referral, or other Property Partner promotions.'
    },
    discount: {
        title: 'Discount on Purchase',
        desc: 'The difference between the amount paid for a Resale investment and its Latest Valuation at the time of purchase. When the amount paid is lower than the Latest Valuation at the time of purchase, it is a discount.'
    },
    dividendsReceived: {
        title: 'Dividends Received',
        desc: 'The total Dividends received, over time, by an investor.'
    },
    forecastDividends: {
        title: 'Forecast Dividends',
        desc: 'Forecast Dividends are the expected monthly amounts that an investor will receive from their Property Partner investments.'
    },
    buyPrice: {
        title: 'Buy Price',
        desc: 'Lowest available price at which a share can be bought.'
    },
    sellPrice: {
        title: 'Sell Price',
        desc: 'Highest price based on existing bids at which a share can be sold.'
    },
    sharePrice: {
        title: 'Share Price',
        desc: 'The share price at which the property last traded; percentage change shown is the difference between the last traded share price and the 7-day weighted average share price.'
    },
    advancePreOrder: {
        title: 'Advance Pre-Order has improved',
        desc: 'You can now make your Advance Pre-Order on the website and payment will be taken immediately from your Wallet. Please ensure you have sufficient funds before placing your order.'
    },
    reinvestDividends: {
        title: 'Reinvest Dividends',
        desc: 'tooltip text here tooltip text here tooltip text here tooltip text here tooltip text here tooltip text here tooltip text here '
    },
    buyButtonNoShares: {
        title: 'No shares',
        desc: 'No shares available for sale.'
    },
    buyButtonNoBonds: {
        title: 'No bonds',
        desc: 'No bonds available for sale.'
    },
    buyButtonError: {
        title: 'No shares',
        desc: 'No shares available for sale.'
    },
    allSharesListed: {
        title: 'All shares listed',
        desc: 'All the shares you own in this property are already listed for sale.'
    },
    sellSharesNoShares: {
        title: 'No shares to sell',
        desc: 'You don\'t own any shares in this property.'
    },
    sellSharesError: {
        title: 'Sell shares error',
        desc: 'An error occurred while loading the holdings status'
    },
    premiumDiscount: {
        title: 'Premium / Discount',
        desc: 'Buy Price compared to the Latest Share Valuation'
    },
    premiumDiscountIv: {
        desc: 'Percentage discount or premium (%) on the Last Traded Price compared to Investment Value Value (IV) Share Price'
    },
    premiumDiscountVpv: {
        desc: 'Percentage discount or premium (%) on the Last Traded Price compared to Vacant Possession Value (VPV) Share Price'
    },
    premiumDiscountWithPlaceholder: {
        title: 'Premium / Discount',
        desc: 'You can buy shares at a ${type} to the Latest Valuation'
    },
    priceLimits: {
        title: 'Price Limits',
        desc: 'Price Limits are the current max and min prices that shares can be traded at for a property. We are required to do this by the FCA to protect the market from unwanted volatility. These limits update daily.'
    },
    accountDisplayName: {
        title: 'Account Display Name',
        desc: 'Give your account a name so you can recognise it in the future'
    },
    netProjectedReturn: {
        title: 'Net projected return',
        desc: 'The forecast total return on investment after fees and costs'
    },
    fundingTarget: {
        title: 'Funding target',
        desc: 'The total funds required to enable Property Partner to purchase the investment on behalf of investors'
    },
    loanToValue: {
        title: 'Loan to value',
        desc: 'Loan to Value (LTV) is the size of debt expressed as a percentage of the property’s valuation'
    },
    netInterestPa: {
        title: 'Net interest p.a.',
        desc: 'The annualised interest that will be earned after all fees and costs, before personal taxation'
    },
    annualisedTotalReturn: {
        title: 'Annualised Total Return',
        desc: 'The net average annual total return achieved by the investment between its acquisition and sale by Property Partner, including net capital gain and total dividends paid'
    },
    timeOnPlatform: {
        title: 'Time on platform',
        desc: 'The amount of time that the property was owned by Property Partner investors'
    },
    equityRaised: {
        title: 'Equity raised',
        desc: 'The total funds committed by investors to acquire the investment'
    },
    targetExitDate: {
        title: 'Target exit date',
        desc: 'The planned date when the asset will be sold'
    },
    isSelling: {
        desc: 'You have listed shares for sale in this property'
    },
    isBidding: {
        desc: 'You have bid for shares in this property'
    },
    ownsShares: {
        desc: 'You have this investment in your portfolio'
    },
    valuationMethod: {
        desc: '<div class="copy"><ul><li>Vacant Possession Value (VPV): assumes each unit within a residential block is sold individually, with vacant possession (i.e. no tenant in place). </li><li>Investment Value (IV): assumes a block of residential units is sold in a single transaction, to a single investor; these transactions attract a discount to VPV, because owner-occupiers are excluded and investors seek discounts for buying in bulk.</li></ul></div>'
    },
    vpv: {
        desc: 'Vacant Possession Value (VPV): assumes each unit within a residential block is sold individually, with vacant possession (i.e. no tenant in place).'
    },
    iv: {
        desc: 'Investment Value (IV): assumes a block of residential units is sold in a single transaction, to a single investor; these transactions attract a discount to VPV, because owner-occupiers are excluded and investors seek discounts for buying in bulk.'
    },
    equityRaise: {
        yourProportionOfRaise: {
            desc: 'This is the amount required to invest in the shares representing your proportion of the fundraise.'
        },
        yourProportionOfRaiseShares: {
            desc: 'This number of shares represents the % of total new shares being issued, that matches exactly your current % shareholding in the property i.e. if you acquire this number of shares, your shareholding will remain unchanged and you would be protected from dilution.'
        },
        propertyValue: {
            desc: 'Property value correct as of 30 September 2022'
        },
        mortgageValue: {
            desc: 'Mortgage value correct as of 30 September 2022'
        },
        loanToValue: {
            desc: 'The ratio (%) between the Mortgage value and Property value'
        },
        cashSurplus: {
            desc: 'Financial data shown at 30 September 2022'
        },
        equityValue: {
            desc: 'Current total value of equity in the property'
        },
        targetMortgageRepayment: {
            desc: 'Based on 1 year of mortgage servicing'
        },
        targetDefecitReduction: {
            desc: 'Amount required for a 50% reduction in the SPV’s cash deficits exceeding £10,000'
        },
        targetFundraise: {
            desc: 'Total targeted fundraise per property, made up of a combination of target deficit reduction and target mortgage repayment'
        },
        sharePriceValuation: {
            desc: 'Share price based on the independently assessed underlying property value'
        },
        sharePriceTrading: {
            desc: 'Weighted average share trading price on the Resale Market over the last 3 months'
        },
        sharePriceReference: {
            desc: 'Average of the independent valuation share price and trading share price'
        },
        sharePriceFundraise: {
            desc: 'This is the price at which new shares will be issued.  It is calculated at a 25% discount to the "Reference share price" to the left.'
        },
        newSharesIssued: {
            desc: 'Number of new shares issued to fulfill the targeted fundraise'
        },
        totalSharesInIssue: {
            desc: 'Total shares in issue following the Equity Fundraise'
        },
        valueOfEquity: {
            desc: 'Total value of equity in the property following the Equity Fundraise'
        },
        shareholderDilution: {
            desc: 'Percentage reduction in the value of shares, if shareholders do not participate in the Equity Fundraise. The dilution % assumes that the target fundraise is reached. Dilution will be less if the fundraise is partially subscribed.'
        },
        shareholderDilutionOwnershipShare: {
            desc: 'Percentage reduction in the percentage ownership share in a property, if shareholders do not participate in the Equity Fundraise. The dilution % assumes that the target fundraise is reached. Dilution will be less if the fundraise is partially subscribed.'
        },
        spvNameEquityRaise: {
            desc: 'The SPV in which investors are invited to subscribe for further shares via the Equity Fundraise.'
        },
        equityFundraiseDiscountToVpv: {
            desc: 'The percentage difference between the Equity Fundraise share price and the property’s Vacant Possession Value (VPV) share valuation, highlighting the discount available.'
        }
    }
});
})(window, window.angular);