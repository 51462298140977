(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.nationality', [
    'pp.services.core',
    'pp.services.private-nationality',
    'pp.services.preference'
]);

var TOP_NATIONALITIES_ISO_CODES = ['GB'];

/**
 * @ngdoc service
 * @name nationalityService
 *
 * @description
 * Provides methods to invoke the `/investor/nationalities`, `investor-identifiers` and `/investor/identifiers` endpoints.
 */

ngModule.service('nationalityService', ['$http', '$q', 'privateNationalityService', 'preferenceService', 'R', function ($http, $q, privateNationalityService, preferenceService, R) {

    var promiseCache = {};
    var dataCache = {};

    var api = {};

    var helperService = privateNationalityService;

    var API_BASE_PATH = '/feapi/r1';

    var API_NATIONALITIES = API_BASE_PATH + '/investor-identifiers/nationalities';
    var API_INVESTOR_IDENTIFIERS = API_BASE_PATH + '/investor/identifiers';

    var API_IDENTIFIERS_TYPES = API_BASE_PATH + '/investor-identifiers/types';

    var INVESTOR_NATIONALITY_DATA_CACHE_KEY = 'investorNationalitiesAndIdentifiers';

    var EMPTY_NATIONALITY_IDENTIFIER_OBJECT = [{
        nationality: null,
        identifiers: []
    }];

    var NATIONALITIES_WITH_NO_IDENTIFIERS_REQUIRED = ['ES', 'HU', 'IE', 'LU', 'DE', 'FR', 'AT', 'IS', 'IT', 'EE'];

    // -- util functions

    function isTopNationality(nationality) {
        return TOP_NATIONALITIES_ISO_CODES.indexOf(nationality.isoCode) > -1;
    }

    // -- api

    /**
     * @ngdoc method
     * @name nationalityService#getNationalityList
     *
     * @description
     * When queried returns all nationalities and top nationalities
     *
     */

    api.getNationalityList = function () {

        var cacheKey = 'getNationalityList';

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(API_NATIONALITIES).then(function (response) {
                    var nationalities = response.data.collection || [];
                    return {
                        top: nationalities.filter(isTopNationality),
                        all: nationalities
                    };
                },
                function (error) {
                    delete promiseCache[cacheKey];
                    switch (error.status) {
                    case 404:
                        return $q.reject({
                            reason: 'nationality.error.not-found'
                        });
                    default:
                        return $q.reject({
                            reason: 'nationality.error.unexpected'
                        });
                    }
                });
        }

        return promiseCache[cacheKey];
    };

    function createIsoIdentifierMap(identifiers) {
        return identifiers.reduce(function (map, identifier) {
            map[identifier.isoCode] = map[identifier.isoCode] || [];
            map[identifier.isoCode].push(identifier);
            return map;
        }, {});
    }

    api.getIdentifiersForNationality = function (nationality) {
        var endpoint = API_IDENTIFIERS_TYPES;

        if (!nationality) {
            return $q.when([]);
        }

        endpoint += '?iso=' + nationality;

        var cacheKey = 'getIdentifiersForNationality' + nationality;

        if (!promiseCache[cacheKey]) {

            promiseCache[cacheKey] = $http.get(endpoint).then(function (response) {
                return response.data.collection;
            }, function (error) {
                delete promiseCache[cacheKey];
                switch (error.status) {
                case 404:
                    return [];
                default:
                    return $q.reject({
                        reason: 'nationality-identifiers.error.unexpected'
                    });
                }
            });

        }

        return promiseCache[cacheKey];

    };

    api.getInvestorDataForNationality = function (investorData, nationality) {
        return investorData.reduce(function (match, item) {
            if (item.nationality === nationality) {
                return item;
            }

            return match;

        }, {});
    };

    function copyInvestorNationalitiesAndIdentifiers(data) {
        return data.map(function (item) {
            return {
                nationality: angular.copy(item.nationality),
                identifiers: item.identifiers.map(function (identifier) {
                    return angular.copy(identifier);
                })
            };
        });
    }

    /**
     * @ngdoc method
     * @name nationalityService#getInvestorNationalityAndIdentifiers
     *
     * @description
     * When queried returns all nationalities
     *
     *
     *  [{
     *      nationality: 'foo',
     *      identifiers: [{
     *          id: 1,
     *          isoCode: 'foo',
     *          identifierValue: 'bar'
     *      }]
     *  }, {
     *      nationality: 'bar',
     *      identifiers: [{
     *          id: 2,
     *          isoCode: 'bar',
     *          identifierValue: 'baz'
     *      }]
     *  }];
     *
     */

    api.getInvestorNationalitiesAndIdentifiers = function () {

        var nationalitiesPromise = helperService._getInvestorNationalities();
        var identifiersPromise = helperService._getInvestorIdentifiers();

        var dataCacheKey = INVESTOR_NATIONALITY_DATA_CACHE_KEY;

        return $q.all([nationalitiesPromise, identifiersPromise]).then(function (data) {

            var nationalities = data[0];
            var identifiers = data[1];

            var identifierMap = createIsoIdentifierMap(identifiers);

            dataCache[dataCacheKey] = nationalities.map(function (nationality) {
                return {
                    nationality: nationality,
                    identifiers: identifierMap[nationality] || []
                };
            });

            return copyInvestorNationalitiesAndIdentifiers(dataCache[dataCacheKey]);

        }, function () {
            return $q.reject({
                reason: 'investor-identifiers.error.unexpected'
            });

        });
    };

    api.getInvestorNationalitiesAndIdentifiersFromCache = function () {

        var dataCacheKey = INVESTOR_NATIONALITY_DATA_CACHE_KEY;

        if (dataCache[dataCacheKey]) {
            return $q.when(copyInvestorNationalitiesAndIdentifiers(dataCache[dataCacheKey]));
        }

        return api.getInvestorNationalitiesAndIdentifiers();
    };

    api.mergeInvestorIdentifiersWithNationalityIdentifiers = function (investorIdentifiers, nationalityIdentifiers) {

        var investorIdentifiersIdValueMap = investorIdentifiers.reduce(function (map, identifier) {
            map[identifier.identifierTypeId] = identifier.identifierValue;
            return map;
        }, {});

        return nationalityIdentifiers.map(function (identifier) {
            identifier.identifierValue = investorIdentifiersIdValueMap[identifier.id] || null;
            return identifier;
        });
    };

    api.constructInvestorNationalitiesAndIdentifiersForForm = function () {

        return api.getInvestorNationalitiesAndIdentifiersFromCache().then(function (cachedInvestorData) {

            if (!cachedInvestorData.length) {
                return EMPTY_NATIONALITY_IDENTIFIER_OBJECT;
            }

            var promises = [];

            cachedInvestorData.forEach(function (item) {
                promises.push(api.getIdentifiersForNationality(item.nationality).then(function (identifiers) {
                    return {
                        identifiers: identifiers,
                        nationality: item.nationality
                    };
                }));
            });

            return $q.all(promises).then(function (identifiers) {

                return identifiers.map(function (nationalityIdentifiers) {

                    var nationality = nationalityIdentifiers.nationality;
                    var investorIdentifiers = api.getInvestorDataForNationality(cachedInvestorData, nationality).identifiers;

                    return {
                        nationality: nationality,
                        identifiers: api.mergeInvestorIdentifiersWithNationalityIdentifiers(investorIdentifiers || [], nationalityIdentifiers.identifiers)
                    };
                });
            });

        });
    };

    api.constructInvestorNationalitiesAndIdentifiersForFormByNationality = function (nationality) {

        if (!nationality) {
            return $q.when([]);
        }

        var promises = [
            api.getIdentifiersForNationality(nationality),
            api.getInvestorNationalitiesAndIdentifiersFromCache()
        ];

        return $q.all(promises)
            .then(function (responses) {
                var nationalityIdentifiers = responses[0];
                var cachedInvestorData = responses[1];
                var identifiers = api.getInvestorDataForNationality(cachedInvestorData, nationality).identifiers;
                var data = api.mergeInvestorIdentifiersWithNationalityIdentifiers(identifiers || [], nationalityIdentifiers);
                return angular.copy(data);
            });
    };

    /**
     * @ngdoc method
     * @name nationalityService#replaceInvestorNationalitiesAndIdentifiers
     *
     * @description
     * Posts nationalities and then identifiers
     *
     *
     *  [{
     *      nationality: 'foo',
     *      identifiers: [{
     *          id: 1,
     *          isoCode: 'foo',
     *          identifierValue: 'bar'
     *      }]
     *  }, {
     *      nationality: 'bar',
     *      identifiers: [{
     *          id: 2,
     *          isoCode: 'bar',
     *          identifierValue: 'baz'
     *      }]
     *  }];
     *
     */

    function invalidPayloadRejection() {
        return $q.reject({
            reason: 'investor-identifiers.error.invalid-payload'
        });
    }

    function postInvestorNationalitiesAndIdentifiers(payload) {

        var promises = payload.map(function (item) {

            if (!item.identifiers || !angular.isArray(item.identifiers)) {
                return $q.when();
            }

            return helperService._postInvestorNationality(item.nationality).then(function () {

                if (!angular.isArray(item.identifiers)) {
                    return invalidPayloadRejection();
                }

                var identifierPromises = item.identifiers.map(function (identifier) {

                    if (!identifier || !identifier.identifierValue) {
                        return $q.when();
                    }

                    return helperService._postInvestorIdentifier(identifier).catch(function (err) {
                        switch (err) {
                        case 400:
                            return $q.reject({
                                status: 400,
                                reason: 'validation failed',
                                identifier: identifier
                            });
                        default:
                            return $q.reject(err);

                        }
                    });
                });
                return $q.all(identifierPromises);
            });
        });

        return $q.all(promises);
    }

    api.replaceInvestorNationalitiesAndIdentifiers = function (payload) {

        if (!angular.isArray(payload)) {
            return invalidPayloadRejection();
        }

        return helperService._deleteAllInvestorNationalities().then(function () {
                return postInvestorNationalitiesAndIdentifiers(payload);
            })
            //keep dataCache up to date on frontend
            .then(api.getInvestorNationalitiesAndIdentifiers)
            .catch(function (errors) {
                return helperService._deleteAllInvestorNationalities().finally(function () {
                    return $q.reject({
                        reason: 'investor-identifiers.error.unexpected',
                    });
                });

            });

    };

    function hasIdentifiers(nationality) {
        if (NATIONALITIES_WITH_NO_IDENTIFIERS_REQUIRED.indexOf(R.path(['nationality'], nationality)) > -1) {
            // Note early return
            return true;
        }

        var item = nationality || {};
        return item.identifiers &&
            angular.isArray(item.identifiers) &&
            item.identifiers.length &&
            item.identifiers.some(function (identifier) {
                return !!identifier.identifierValue;
            });
    }

    api.isNationalityDataSet = function (nationalityData) {
        return !!((nationalityData && angular.isArray(nationalityData) && nationalityData[0] && nationalityData[0].nationality) && hasIdentifiers(nationalityData[0]));
    };

    api.isNationalitySet = function () {
        return helperService._getInvestorNationalities().then(function (nationalities) {
            return !!nationalities.length;
        }, function () {
            return false;
        });
    };

    api.presetNationalitiesWithKycNationality = function () {
        return preferenceService.getKycNationalities().then(function (nationalities) {
            if (!nationalities.length) {
                // Note early return
                return [{
                    nationality: undefined
                }];
            }

            return nationalities.map(function (nationality) {
                return {
                    nationality: nationality
                };
            });
        });
    };

    return api;

}]);
})(window, window.angular);