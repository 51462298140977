(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.rff', []);

var API_BASE_PATH = '/feapi/r1';
var RFF_INVESTOR_ENDPOINT = API_BASE_PATH + '/investor/rff';

ngModule.service('rffService', ['$http', function ($http) {

    var api = {};
    var promiseCache = {};

    api.getInvestorCampaigns = function () {

        var endpoint = RFF_INVESTOR_ENDPOINT;
        var cacheKey = 'rff-investor-campaigns';

        if (!promiseCache[cacheKey]) {
            var promise = $http.get(endpoint).then(function (response) {
                return response.data;
            });
            promiseCache[cacheKey] = promise;
        }

        return promiseCache[cacheKey];
    };

    return api;
}]);
})(window, window.angular);