(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.crowdfunding-lp', [
    'pp.widgets-templates',
    'pp.services.config',
    'pp.services.route',
    'pp.services.core',
    'pp.services.investor',
    'pp.widgets.risk-warning-badge',
    'pp.widgets.trust-pilot-banner',
    'pp.widgets.ways-to-invest',
    'pp.widgets.awards-banner'
]);

var PAGE_NAME = 'property-crowdfunding';

ngModule.component('ppCrowdfundingLp', {
    templateUrl: 'widgets/_angular/crowdfunding-lp/crowdfunding-lp.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$window', 'configService', 'routeService', 'investorService', 'ppConfig', 'ppPubSub', 'ppTrack', function ($scope, $window, configService, routeService, investorService, ppConfig, ppPubSub, ppTrack) {
        var vm = this;

        // -- initial state
        var __config = ppConfig.get('pp.external.endpoints') || {};
        var s3Endpoint = __config.assets;

        vm.heroIcon = s3Endpoint + '/images/isa/isa-icon-lp.svg';
        vm.isaImg = s3Endpoint + '/images/isa/isa-mobile-image.png';

        vm.iconPeopleMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-people-marker.png';
        vm.iconLightbulbMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-lightbuld-marker.png';
        vm.iconCogMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-cog-marker.png';
        vm.propertyCardImage1 = s3Endpoint + '/images/marketing/property-cards/property-card-image1.jpg';
        vm.propertyCardImage2 = s3Endpoint + '/images/marketing/property-cards/property-card-image2.jpg';
        vm.propertyCardImage3 = s3Endpoint + '/images/marketing/property-cards/property-card-image3.jpg';

        vm.whatIsCrowdfundingPath = routeService.whatIsCrowdfundingPath;
        vm.howCrowdfundingWorksPath = routeService.howCrowdfundingWorksPath;
        vm.crowdfundingBenefitsPath = routeService.crowdfundingBenefitsPath;

        // -- util functions

        // -- api

        vm.openSignup = function () {
            ppPubSub.publish('auth.modal.show', ['signup', null, null, null, null]);
        };

        vm.openLogin = function () {
            ppPubSub.publish('auth.modal.show', ['login', null, null, null, null]);
        };

        // -- scope bindings

        // -- main

        ppTrack.pageLoad(PAGE_NAME);

        investorService.getInvestor().then(function (user) {
            vm.user = user;
        });

    }]
});
})(window, window.angular);