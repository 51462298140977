(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.live-marketplace-details', ['yaru22.angular-timeago', 'pp.widgets-templates', 'pp.widgets.live-recent-trades', 'pp.widgets.live-most-traded', 'pp.services.core', 'pp.services.marketplace', 'pp.components.tabs', 'pp.filters.numbers']);

var POLL_INTERVAL_MS = 20000;

ngModule.directive('ppLiveMarketplaceDetails', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/live-marketplace-details/live-marketplace-details.tpl.html',
        scope: {
            activeSection: '<',
            recentTrades: '<',
            mostTraded: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', 'marketplaceService', 'ppTrack', function ($scope, $timeout, marketplaceService, ppTrack) {
            var vm = this;

            // -- initial state

            vm.tabs = [{
                    title: 'Recent Trades',
                    key: 'recentTrades'
                },
                {
                    title: 'Most Traded (7 days)',
                    key: 'mostTraded'
                }
            ];

            // -- util functions

            function updateData() {
                return marketplaceService.refreshLatestTradingData(vm.recentTrades, vm.mostTraded).then(function (data) {
                    vm.recentTrades = data.trades;
                    vm.mostTraded = data.mostTraded;
                    $timeout(updateData, POLL_INTERVAL_MS);
                });
            }

            function getNextIndex(itemArray, currentItem) {
                var index;
                if (!currentItem || !currentItem.id) {
                    return 0;
                }
                for (var ix = 0; ix < itemArray.length; ix++) {
                    if (itemArray[ix].id === currentItem.id) {
                        if (ix + 1 === itemArray.length) {
                            index = 0;
                            return index;
                        } else {
                            index = ix + 1;
                            return index;
                        }
                        break;
                    }
                }

                // in case no item is found
                return 0;
            }

            function getPreviousIndex(itemArray, currentItem) {
                var index;
                for (var ix = 0; ix < itemArray.length; ix++) {
                    if (itemArray[ix].id === currentItem.id) {
                        if (ix - 1 < 0) {
                            index = itemArray.length - 1;
                            return index;
                        } else {
                            index = ix - 1;
                            return index;
                        }
                        break;
                    }
                }
                // in case no item is found
                return 0;
            }

            // -- api

            vm.slideRightTrades = function () {
                var nextTradeIndex = getNextIndex(vm.recentTrades, vm.currentTrade);
                vm.currentTrade = angular.copy(vm.recentTrades[nextTradeIndex]);
                $scope.$broadcast('pp.live-marketplace.recent-trades.slide-right', vm.currentTrade);
            };

            vm.slideLeftTrades = function () {
                var previousTradeIndex = getPreviousIndex(vm.recentTrades, vm.currentTrade);
                vm.currentTrade = angular.copy(vm.recentTrades[previousTradeIndex]);
                $scope.$broadcast('pp.live-marketplace.recent-trades.slide-left', vm.currentTrade);
            };

            vm.slideRightTraded = function () {
                var nextTradeIndex = getNextIndex(vm.mostTraded, vm.currentMostTraded);
                vm.currentMostTraded = vm.mostTraded[nextTradeIndex];
                $scope.$broadcast('pp.live-marketplace.most-traded.slide-right', vm.currentMostTraded);

            };

            vm.slideLeftTraded = function () {
                var previousTradeIndex = getPreviousIndex(vm.mostTraded, vm.currentMostTraded);
                vm.currentMostTraded = vm.mostTraded[previousTradeIndex];
                $scope.$broadcast('pp.live-marketplace.most-traded.slide-left', vm.currentMostTraded);
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                vm.currentTrade = angular.copy(vm.recentTrades[0]);
                vm.currentMostTraded = angular.copy(vm.mostTraded[0]);
                updateData().then(function () {
                    ppTrack.event('pp.live-marketplace-details.loaded');
                });
            };

        }]
    };
}]);
})(window, window.angular);