(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-actions-dropdown', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.widgets.more-actions-dropdown'
]);

ngModule.component('ppPropertyActionsDropdown', {
    templateUrl: 'widgets/_angular/property-actions-dropdown/property-actions-dropdown.tpl.html',
    bindings: {
        propertyState: '<?',
        isOpen: '=?',
        holding: '<',
        type: '@'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', function ($scope, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.trackClick = function (actionName) {
            ppTrack.deferAction('pp.property-actions-dropdown.' + actionName);
        };

        // -- scope bindings

        $scope.$on('freeze-cell.clicked', function (event, row) {
            if (vm.holding === row) {
                $scope.$broadcast('dropdown-open');
            }
        });

        // -- main

    }]
});
})(window, window.angular);