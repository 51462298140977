(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.header-nav-mobile', [
    'pp.services.core',
    'pp.services.investor',
    'pp.services.route',
    'pp.widgets-templates'
]);

var BID_QUOTE_REGEXP = /\/properties\/[\w#]+\/(bid|quote)/;
var INVESTOR_FUND_REGEXP = /\/investor\/account\/(manual|auto\-invest)\/fund/;

ngModule.component('ppHeaderNavMobile', {
    templateUrl: 'widgets/_angular/header-nav-mobile/header-nav-mobile.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$window', 'ppPubSub', 'ppTrack', 'investorService', 'routeService', function ($scope, $window, ppPubSub, ppTrack, investorService, routeService) {
        var vm = this;

        // -- initial state

        var path = $window.location.pathname;

        vm.inFundFlow = !!(path.match(BID_QUOTE_REGEXP) || path.match(INVESTOR_FUND_REGEXP));

        vm.fundPath = routeService.fundPath;

        // -- util functions

        function updateUser(user) {
            vm.user = user;
        }

        // -- api

        vm.signup = function () {
            ppTrack.action('menu.signup.click');
            ppPubSub.publish('auth.modal.show', ['signup']);
        };

        vm.login = function () {
            ppTrack.action('menu.login.click');
            ppPubSub.publish('auth.modal.show', ['login']);
        };

        vm.fund = function () {
            ppTrack.deferAction('menu.fund.click');
        };

        // -- scope bindings

        // -- main

        vm.$onDestroy = function () {
            investorService.off('investor-updated', updateUser);
        };

        investorService.getInvestor().then(function (user) {
            vm.user = user;
            vm.ready = true;

            investorService.on('investor-updated', updateUser);
        });

    }]
});
})(window, window.angular);