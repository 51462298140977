(function(window, angular){'use strict';

var ngModule = angular.module('pp.values.promo-banner', []);

var promoBanner = {
    commercial: {
        backgroundImage: '/images/homepage/promo-banner-sainsburys.jpg',
        card: {
            preTitle: 'Available now',
            title: 'Commercial property',
            description: 'Invest in commercial property like never before. Discover core commercial sub-sectors, handpick properties yourself, and sell whenever you like. Commercial property provides a strong, reliable income.',
            cta: {
                text: 'View property',
                link: '/properties/UKCV327JE001'
            }
        }
    },
    signupBonus: {
        backgroundImage: '/images/homepage/promo-banner-residential.jpg',
        card: {
            preTitle: 'Sign up offer',
            title: 'Receive £200',
            description: 'As a small gesture, receive £200 when you invest over £10,000. <a href="https://d2ofd11qqzygs0.cloudfront.net/files/terms-and-conditions-30-day-bonus.pdf" class="link" target="_blank">Terms Apply.</a> Receive rental income monthly, and access any capital growth when you sell.',
            cta: {
                text: 'Sign up'
            }
        }
    }
};

ngModule.value('promoBanner', promoBanner);
})(window, window.angular);