(function(window, angular){'use strict';

var PAGE_REFRESH_DELAY = 500;
var MANUAL = 'manual';
var AUTO_INVEST = 'auto-invest';

var ngModule = angular.module('pp.widgets.account-history-type', ['pp.widgets-templates', 'pp.services.account-history', 'pp.widgets.account-history-row']);

ngModule.component('ppAccountHistoryType', {
    templateUrl: 'widgets/_angular/account-history-type/account-history-type.tpl.html',
    bindings: {},
    transclude: true,
    controllerAs: 'vm',
    controller: ['$window', '$timeout', '$q', '$state', '$stateParams', 'accountHistoryService', function ($window, $timeout, $q, $state, $stateParams, accountHistoryService) {
        var vm = this;

        // -- initial state

        var dataType = $stateParams.type === MANUAL ? MANUAL : AUTO_INVEST;

        // -- util functions

        function scrollToTop() {
            $window.scroll(0, 0);
        }

        function handleSuccess(transactions) {
            vm.ready = true;
            vm.loadingError = false;
            vm.transactions = transactions;
        }

        function handleLoadingError() {
            vm.loadingError = true;
        }

        function createPropertyLink(transaction) {
            var maybeLink;
            if (transaction.symbol && transaction.propertyName) {
                maybeLink = '<a class="link" href="/properties/' + transaction.symbol + '">' + transaction.propertyName + '</a>';
            } else if (transaction.propertyName) {
                maybeLink = transaction.propertyName;
            } else {
                maybeLink = 'into your Property Partner Account.';
            }

            return maybeLink;
        }

        function normaliseTransaction(transaction) {
            if (transaction.metadata.message) {
                transaction.metadata.message = transaction.metadata.message.replace('<PROP-NAME>', createPropertyLink(transaction));
            }

            return transaction;
        }

        function loadData(type, page, handleSuccess, handleError) {
            var promises = [
                accountHistoryService.getAccountHistory(type, page),
                $timeout(angular.noop, PAGE_REFRESH_DELAY)
            ];

            return $q.all(promises).then(function (data) {
                handleSuccess(data[0].map(normaliseTransaction));
            }, handleError);
        }

        function goToPage(page) {
            $state.go($state.current.name, {
                type: $stateParams.type,
                page: page
            });
            scrollToTop();
        }

        // -- api

        vm.reload = function () {
            vm.ready = false;
            vm.loadingError = false;
            loadData(dataType, vm.page, handleSuccess, handleLoadingError);
        };

        vm.next = function () {
            vm.page++;
            goToPage(vm.page);
        };

        vm.previous = function () {
            vm.page = Math.max(vm.page - 1, 0);
            goToPage(vm.page);
        };

        // -- main

        vm.$onInit = function () {
            vm.page = parseInt($stateParams.page) || 0;

            loadData(dataType, vm.page, handleSuccess, handleLoadingError);
        };

    }]
});
})(window, window.angular);