(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.update-bank-account', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.widgets.withdrawal-bank-account'
]);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/suspended-property-dialog`.
 */
ngModule.service('updateBankAccountService', ['$rootScope', '$window', '$timeout', 'ppTrack', 'dialogService', function ($rootScope, $window, $timeout, ppTrack, dialogService) {

    // -- util functions

    function submitAndCloseModal(submit) {
        return function (account, password) {
            return submit(account, password).then(dialogService.close);
        };
    }

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(account, onSubmit) {
        ppTrack.action('update-bank-account.show');
        var scope = $rootScope.$new();

        scope.account = angular.copy(account);
        scope.onSubmit = submitAndCloseModal(onSubmit);

        var options = {
            clickClose: true,
            escapeClose: true,
            showClose: true,
            scope: scope
        };

        options.contents = '<div pp-withdrawal-bank-account account="account" hide-choose-country="true" on-submit="onSubmit(account, password)" action-label="update"></div>';

        return dialogService.show(options);
    }

    // -- api

    var api = {};

    api.show = function (account, onSubmit) {
        return showDialog(account, onSubmit);
    };

    return api;
}]);
})(window, window.angular);