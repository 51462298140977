(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.bank-transfer', ['pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.components.checkbox',
    'pp.components.tooltip',
    'pp.services.route',
    'pp.services.event'
]);
var GB_COUNTRY_CODE = 'GBR';

var EMAIL_SENDING_TIMEOUT_MILLIS = 1000;

/**
 * * **EVENTS**
 * - emits on `$scope` event `pp.bank-transfer.show-investment-docs` (consumed by autoInvestConfirmCtrl)
 *
 * @todo
 */
ngModule.directive('ppBankTransfer', ['$timeout', 'ppTrack', 'ppUtil', 'investorService', 'eventService', 'routeService', function ($timeout, ppTrack, ppUtil, investorService, eventService, routeService) {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/bank-transfer/bank-transfer.tpl.html',
        scope: {
            details: '<ppBankTransfer',
            user: '<',
            amount: '<',
            account: '@',
            context: '@',
            agreeToInvestmentDocs: '=?'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', 'ppComponentEmitter', function ($scope, $element, ppComponentEmitter) {
            var vm = this;

            // -- initial state

            var accountNameToKeyMap = {
                'manual': 'CF'
            };

            vm.sortCode = '20-32-30';
            vm.accountNumber = '03604578';
            vm.iban = 'GB59 BARC 203230 03604578';
            vm.swiftCode = 'BARCGB22';
            vm.paymentDetailsVisibility = {};

            vm.depositsWithdrawalHelp = routeService.depositsWithdrawalHelp;
            vm.marketplacePath = routeService.marketplacePath;

            vm.payToTooltip = 'Some banks have introduced a new security check that requires matching of the name entered in the payee field to the actual bank account name when making a transfer. To avoid receiving a \'variations in account details\' error, in the \'Pay to\' field please enter our registered name <b>\'London House Exchange Limited\'</b> instead of our trading name \'Property Partner\'.';

            // -- util functions

            // -- api

            vm.showInvestmentDocs = function () {
                $scope.$emit('pp.bank-transfer.show-investment-docs');
            };

            vm.togglePaymentDetails = function (type) {
                vm.paymentDetailsVisibility[type] = !vm.paymentDetailsVisibility[type];
                var action = vm.paymentDetailsVisibility[type] ? 'expand' : 'contract';
                ppTrack.action('bank-transfer.' + type + '.click.' + action);
            };

            vm.emailMeTheDetails = function () {
                var paymentReference = ppUtil.hasPathIn(vm.user, 'financials') ? vm.user.financials.paymentReference : null;

                var eventProps = {
                    'bankTransferDetails.paymentReference': paymentReference,
                    'bankTransferDetails.account': accountNameToKeyMap[vm.account],
                    'bankTransferDetails.sortCode': vm.sortCode,
                    'bankTransferDetails.accountNumber': vm.accountNumber,
                    'bankTransferDetails.iban': vm.iban,
                    'bankTransferDetails.swiftCode': vm.swiftCode,
                    'bankTransferDetails.amount': vm.amount
                };

                ppTrack.action('bankTransferDetails', eventProps);

                vm.processing = true;
                vm.sendingError = false;

                eventService.sendGenericEvent('BANK_TRANSFER_DETAILS', eventProps).then(function () {
                    vm.processing = false;
                    vm.bankDetailsSent = true;
                }, function () {
                    vm.processing = false;
                    vm.sendingError = true;
                });
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                investorService.getInvestorAddress().then(function (data) {
                    var countryCode = data.countryCode;
                    var isInternational = countryCode && countryCode !== GB_COUNTRY_CODE || false;
                    ppComponentEmitter.emit('bank-transfer.controller.loaded', vm);
                    $timeout(function () {
                        ppComponentEmitter.emit('bank-transfer.dom.loaded', $element);
                    });
                    vm.paymentDetailsVisibility = {
                        uk: !isInternational,
                        international: isInternational
                    };
                });
            };

        }]
    };
}]);
})(window, window.angular);