(function(window, angular){'use strict';

var ngModule = angular.module('pp.values.customers', []);

var customers = [{
    title: 'The investor who sold £80k in 30 minutes',
    description: 'Using the bids function, he managed to sell the whole amount in just 30 minutes, from listing to sale.',
    image: 'images/homepage/investor-sold-80k.jpg',
    cta: {
        newTab: true,
        link: 'https://www.londonhouseexchange.com/blog/the-investor-who-sold-80k-in-30-minutes/'
    }
}, {
    title: 'Jaye Cook',
    subTitle: 'He switched to us from Buy-to-let',
    description: '“I genuinely think this is the future of property investment.”',
    image: 'images/homepage/jaye-cook.jpg',
    cta: {
        newTab: true,
        link: 'https://www.londonhouseexchange.com/blog/meet-jaye-cook-he-switched-to-us-from-buy-to-let/'
    }
}];

ngModule.value('customers', customers);
})(window, window.angular);