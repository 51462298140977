(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.crowdfunding-benefits', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.config',
    'pp.services.route',
    'pp.widgets.ways-to-invest',
    'pp.widgets.awards-banner',
    'pp.widgets.risk-warning-badge',
    'pp.widgets.nav-card'
]);

var PAGE_NAME = 'crowdfunding-benefits';

ngModule.component('ppCrowdfundingBenefits', {
    templateUrl: 'widgets/_angular/crowdfunding-benefits/crowdfunding-benefits.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', 'ppPubSub', 'configService', 'investorService', 'routeService', 'ppTrack', function ($scope, ppConfig, ppPubSub, configService, investorService, routeService, ppTrack) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints') || {};
        var s3Endpoint = __config.assets;

        vm.iconPeopleMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-people-marker.png';
        vm.iconLightbulbMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-lightbuld-marker.png';
        vm.iconCogMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-cog-marker.png';

        vm.cashStack = s3Endpoint + '/images/marketing/property-crowdfunding/cash-stack.png';
        vm.fortPropertyCard = s3Endpoint + '/images/marketing/property-crowdfunding/benefits/access-specialist-deals.jpg';
        vm.propertyMap = s3Endpoint + '/images/marketing/property-crowdfunding/benefits/diversify-your-portfolio.jpg';
        vm.propertyTeam = s3Endpoint + '/images/marketing/property-crowdfunding/property-team.png';
        vm.sellForm = s3Endpoint + '/images/marketing/property-crowdfunding/benefits/liquidity.jpg';
        vm.sunbathe = s3Endpoint + '/images/marketing/property-crowdfunding/benefits/hassle-free.jpg';

        vm.marketplacePath = routeService.marketplacePath;

        vm.whatIsCrowdfundingPath = routeService.whatIsCrowdfundingPath;
        vm.crowdfundingBenefitsPath = routeService.crowdfundingBenefitsPath;
        vm.howCrowdfundingWorksPath = routeService.howCrowdfundingWorksPath;

        vm.investmentPlansPath = routeService.investmentPlansPath;
        vm.propertyTeamPath = routeService.propertyTeamPath;
        vm.blogAcquisitionStrategies = routeService.blogAcquisitionStrategies;

        vm.propertyGoldenHillFort = routeService.propertyGoldenHillFort;

        // -- util functions

        // -- api

        vm.openSignup = function () {
            ppPubSub.publish('auth.modal.show', ['signup', null, null, null, null]);
        };

        vm.openLogin = function () {
            ppPubSub.publish('auth.modal.show', ['login', null, null, null, null]);
        };

        // -- scope bindings

        // -- main

        ppTrack.pageLoad(PAGE_NAME);

        configService.getAverageTimeToSell().then(function (data) {
            vm.avgTimeToSell = data.avgTimeToSell || '';
            vm.avgTimeToSellDate = data.avgTimeToSellDate || '';
            vm.pastTimeToSell1Label = data.pastTimeToSell1Label || '';
            vm.pastTimeToSell1Value = data.pastTimeToSell1Value || '';
            vm.pastTimeToSell2Label = data.pastTimeToSell2Label || '';
            vm.pastTimeToSell2Value = data.pastTimeToSell2Value || '';
            vm.pastTimeToSell3Label = data.pastTimeToSell3Label || '';
            vm.pastTimeToSell3Value = data.pastTimeToSell3Value || '';
        });

        investorService.getInvestor().then(function (user) {
            vm.user = user;
        });

    }]
});
})(window, window.angular);