(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.vote', [
    'pp.services.core'
]);

var API_BASE_PATH = '/feapi/r1';
var API_VOTE_ENDPOINT = '/vote';

ngModule.service('voteService', ['$http', '$q', 'R', function ($http, $q, R) {

    var api = {};

    var cache = {};

    var indexByProperty = R.indexBy(R.prop('propertySymbol'));
    var indexByChoice = R.indexBy(R.prop('choice'));

    function indexVoteByChoice(vote) {
        return R.mergeLeft(vote, {
            choicesDict: indexByChoice(vote.choices)
        });
    }

    function tallyResultMajorityRule(vote) {
        var totalVotes = vote.choices.reduce(function (acc, choice) {
            return choice.sharesVoted + acc;
        }, 0);

        vote.choices = vote.choices.map(function (choice) {
            return R.mergeRight(choice, {
                pctVote: choice.sharesVoted / totalVotes
            });
        });

        return vote;
    }

    function tallyExitMechanicVote(vote) {
        vote.choices = vote.choices.map(function (choice) {
            return R.mergeRight(choice, {
                pctVote: choice.sharesVoted / vote.totalSharesToVoteOn
            });
        });

        return vote;
    }

    function tallyResult(vote) {
        if (vote.voteType === 'EXIT_MECHANIC_VOTE') {
            return tallyExitMechanicVote(vote);
        } else {
            return tallyResultMajorityRule(vote);
        }
    }

    var normaliseVote = R.compose(indexVoteByChoice, tallyResult);

    api.getVoteResults = function () {

        var cacheKey = 'vote-results';

        var endpoint = API_BASE_PATH + API_VOTE_ENDPOINT;

        if (!cache[cacheKey]) {
            cache[cacheKey] = $http.get(endpoint).then(function (res) {
                return indexByProperty(res.data.map(normaliseVote));
            }, function (error) {
                return {};
            });
        }

        return cache[cacheKey];
    };

    return api;
}]);
})(window, window.angular);