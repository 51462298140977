(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-primary', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.filters.dates',
    'pp.components.time-left-unit',
    'pp.components.time-left-value',
    'pp.components.tooltip',
    'pp.values.glossary',
    'pp.widgets.property-details-dividend-yield',
    'pp.widgets.property-details-projected-return',
    'pp.widgets.property-details-hpi'
]);

ngModule.directive('ppPropertyDetailsPrimary', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-details-primary/property-details-primary.tpl.html',
        scope: {
            property: '<ppPropertyDetailsPrimary'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'glossary', function ($scope, glossary) {
            var vm = this;

            // -- initial state

            vm.netIncomeYieldPrimary = glossary.netIncomeYieldPrimary;

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main
            vm.$onInit = function () {
                vm.closingDate = new Date(vm.property.state.offerClosing);
                vm.noTimeLeft = vm.closingDate < new Date();
            };

        }]
    };
}]);
})(window, window.angular);