(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.good-repute-statement-form', [
    'pp.widgets-templates',
    'pp.components.checkbox',
    'pp.services.core'
]);

var FORM_NAME = 'good-repute-statement';

ngModule.directive('ppGoodReputeStatementForm', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/good-repute-statement-form/good-repute-statement-form.tpl.html',
        scope: {
            user: '<',
            onSubmit: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppTrack', function ($scope, ppTrack) {
            var vm = this;

            // -- initial state

            // -- util functions
            function handleSuccess() {
                ppTrack.event(FORM_NAME + '.success');
                vm.processing = false;
                vm.feedbackEnabled = false;
            }

            function handleFailure(error) {
                vm.error = error.reason;
                vm.feedbackEnabled = true;
                vm.processing = false;
                ppTrack.formError(FORM_NAME, error.reason);
            }

            // -- api
            vm.submit = function () {

                ppTrack.setContext({
                    'good-repute.confirm': vm.agree
                });

                if (vm.form.$valid) {
                    ppTrack.formSubmit(FORM_NAME);
                    vm.processing = true;
                    vm.onSubmit()
                        .then(handleSuccess, handleFailure);
                } else {
                    vm.feedbackEnabled = true;
                    ppTrack.ngFormValidation(FORM_NAME, vm.form);
                }
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);