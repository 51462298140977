(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.vote-result-cell', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.filters.numbers'
]);

ngModule.component('ppVoteResultCell', {
    templateUrl: 'widgets/_angular/vote-result-cell/vote-result-cell.tpl.html',
    bindings: {
        choiceOne: '<',
        choiceTwo: '<',
        valueOfChoiceOne: '<',
        valueOfChoiceTwo: '<',
        flipColour: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'R', function ($scope, R) {
        var vm = this;

        // -- initial state

        // -- util functions

        function createBarStyle(choice) {
            return {
                width: String(R.defaultTo(0, R.prop('pctVote', choice)) * 100) + '%'
            };
        }

        // -- api

        // -- scope bindings

        // -- main
        vm.$onInit = function () {
            vm.choiceOneFillStyle = createBarStyle(vm.choiceOne);
            vm.choiceTwoFillStyle = createBarStyle(vm.choiceTwo);
        };

    }]
});
})(window, window.angular);