(function(window, angular){'use strict';

var ngModule = angular.module('pp.values.asset-types', []);

var assetTypes = [{
    title: 'Residential',
    description: 'The world’s most valuable asset classes.',
    image: 'images/homepage/asset-class-residential.jpg',
    cta: {
        newTab: true,
        text: 'View residential properties',
        link: '/s#/grid-view'
    }
}, {
    title: 'Purpose-Built Student',
    description: 'Characterised by high-yields and high demand.',
    image: 'images/homepage/asset-class-student.jpg',
    cta: {
        newTab: true,
        text: 'View student properties',
        link: '/s#/grid-view?filter=student'
    }
}, {
    title: 'Commercial',
    description: 'Strong, reliable income over the long-term.',
    image: 'images/homepage/asset-class-commercial.jpg',
    cta: {
        newTab: true,
        text: 'View commercial properties',
        link: '/s#/grid-view?filter=commercial'
    }
}];

ngModule.value('assetTypes', assetTypes);
})(window, window.angular);