(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.mfa-remove-dialog', ['pp.widgets-templates', 'ng.cork.ui.focus-on', 'pp.components.bind-invalid-model', 'pp.components.focus-first-error', 'pp.services.mfa', 'pp.services.core', 'pp.services.pin', 'pp.widgets.auth-pin-setup']);

ngModule.directive('ppMfaRemoveDialog', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/mfa-settings-dialog/mfa-remove-dialog.tpl.html',
        scope: {},
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', 'mfaService', 'ppConfig', 'ppTrack', 'pinService', function ($scope, $timeout, mfaService, ppConfig, ppTrack, pinService) {
            var vm = this;

            // -- initial state
            var __endpoints = ppConfig.get('pp.external.endpoints') || {};
            var assetsEndpoint = __endpoints.assets;

            vm.imageUrl = assetsEndpoint + '/images/mfa/ga-logo.png';
            vm.step = 'pin-setup';
            vm.patternMfa = '[0-9]*$';

            // -- util
            function setStep(step) {
                vm.step = step;
                vm.feedbackEnabled = false;
                ppTrack.event('pp-mfa.remove-step.' + vm.step);

            }

            // -- api
            vm.cancel = function () {
                $scope.$parent.dialogDefer.resolve(false);
            };

            vm.back = function () {
                setStep('pin-setup');
            };

            vm.confirm = function () {
                vm.feedbackEnabled = true;
                if (vm.form.$valid) {
                    mfaService.disableMfaAndActivatePin(vm.mfaCode, vm.pinObject).then(function () {
                        vm.processing = false;
                        $scope.$parent.dialogDefer.resolve(true);
                    }, function (error) {
                        vm.error = error.reason;
                        vm.processing = false;
                    });
                }
            };

            vm.next = function () {
                vm.feedbackEnabled = true;
                vm.pinObject = pinService.createPinObject(vm.pin);
                setStep('enter-mfa');
                $timeout(function () {
                    $scope.$broadcast('pp.enter-mfa-remove.setFocus');
                });
            };

            // -- main

            $timeout(function () {
                $scope.$broadcast('pp.pin-setup.pin-entry.focus');
            });

        }]
    };
}]);
})(window, window.angular);