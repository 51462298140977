(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.expiry-date-validation', [
    'pp.services.core'
]);

var DATE_FORMAT = 'MM/YY';

var YEAR_UPPER_THRESHOLD = 10;

ngModule.service('ppExpiryDateValidationService', ['ppMoment', function (ppMoment) {
    var api = {};

    api.invalidDate = function (value) {
        var date = ppMoment(value, DATE_FORMAT, true);
        return date.isValid();
    };

    api.expired = function (value) {
        var date = ppMoment(value, DATE_FORMAT, true);

        if (!date.isValid()) {
            return true;
        } else {
            var hasNotExpired = date.isSameOrAfter(ppMoment().startOf('month'));
            return hasNotExpired;
        }
    };

    api.overThreshold = function (value) {
        var date = ppMoment(value, DATE_FORMAT, true);

        if (!date.isValid()) {
            return true;
        } else {
            var isNotOverThreshold = date.isBefore(ppMoment().add(YEAR_UPPER_THRESHOLD, 'years'));
            return isNotOverThreshold;
        }
    };

    return api;
}]);

/**
 * @ngdoc directive
 * @name ppExpiryDateValidation
 * @description
 *
 * @restrict A
 * @requires ngModel
 *
 * @param {string} ngModel Assignable angular expression to data-bind to.
 */
ngModule.directive('ppExpiryDateValidation', ['ppExpiryDateValidationService', function (ppExpiryDateValidationService) {

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, $elem, $attrs, $ctrl) {

            $ctrl.$validators.invalidDate = function (modelValue, viewValue) {

                if ($ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                return ppExpiryDateValidationService.invalidDate(viewValue);

            };

            $ctrl.$validators.expired = function (modelValue, viewValue) {

                if ($ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                return ppExpiryDateValidationService.expired(viewValue);
            };

            $ctrl.$validators.overThreshold = function (modelValue, viewValue) {

                if ($ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                return ppExpiryDateValidationService.overThreshold(viewValue);

            };

        },
    };
}]);
})(window, window.angular);