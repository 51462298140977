(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-marketplace', ['pp.widgets-templates', 'pp.filters.numbers', 'pp.components.tooltip', 'pp.values.glossary', 'pp.widgets.premium-discount']);

ngModule.directive('ppPropertyDetailsMarketplace', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-details-marketplace/property-details-marketplace.tpl.html',
        scope: {
            property: '<ppPropertyDetailsMarketplace'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'glossary', function ($scope, glossary) {
            var vm = this;

            // -- initial state

            vm.shareValuationTooltip = glossary.shareValuation;
            vm.buyPriceTooltip = glossary.buyPrice;

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);