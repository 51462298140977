(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.dashboard-figure-box', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.services.core',
    'pp.components.tooltip',
    'pp.widgets.valuation-method-select'
]);

/**
 * @ngdoc directive
 * @name keyFigureBox
 * @description
 * Render the key figure box
 *
 * @restrict A
 * @scope
 * @param {Number} value
 * @param {Number} value
 * @param {Array[Object]} breakdown
 *
 */
ngModule.directive('ppDashboardFigureBox', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/dashboard-figure-box/dashboard-figure-box.tpl.html',
        scope: {
            value: '<',
            label: '@',
            breakdown: '<',
            subItem: '<',
            onToggleUpdate: '&',
            valuationMethod: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$timeout', '$q', 'R', function ($timeout, $q, R) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            vm.toggleUpdated = function (valuationMethod) {
                return vm.onToggleUpdate({
                    value: valuationMethod
                });
            };

            // -- main

        }]
    };
}]);
})(window, window.angular);