(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.market-price-movement-table', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.services.route',
    'pp.widgets.property-cell',
    'pp.values.glossary',
    'pp.components.tooltip'
]);

ngModule.component('ppMarketPriceMovementTable', {
    templateUrl: 'widgets/_angular/market-price-movement-table/market-price-movement-table.tpl.html',
    bindings: {
        properties: '<',
        heading: '@',
        holdings: '<',
        bids: '<',
        offers: '<',
        viewDetail: '&'
    },
    controllerAs: 'vm',
    controller: ['routeService', 'glossary', function (routeService, glossary) {
        var vm = this;

        // -- initial state

        vm.sharePriceGlossary = glossary.sharePrice.desc;

        // -- util functions

        // -- api

        vm.getIndividualPropertyPath = routeService.getIndividualPropertyPath;

        vm.onViewDetail = function (property) {
            vm.viewDetail({
                property: property
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);