(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-image', ['pp.widgets-templates', 'pp.services.core']);

ngModule.component('ppPropertyDetailsImage', {
    templateUrl: 'widgets/_angular/property-details-image/property-details-image.tpl.html',
    bindings: {
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', function ($scope, ppConfig) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            var __endpoints = ppConfig.get('pp.external.endpoints') || {};
            var assetsEndpoint = __endpoints.assets;

            if (vm.property) {
                vm.propertyImage = assetsEndpoint + '/images/properties/' + vm.property.symbol + '/image1-grid-list.jpg';
            }
        };

    }]
});
})(window, window.angular);