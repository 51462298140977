(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investor-context-switcher', [
    'pp.widgets-templates',
    'pp.services.core'
]);

ngModule.component('ppInvestorContextSwitcher', {
    templateUrl: 'widgets/_angular/investor-context-switcher/investor-context-switcher.tpl.html',
    bindings: {
        switchContext: '&',
        investor: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', function ($scope, ppConfig) {
        var vm = this;

        // -- initial state

        var _externalEndpoints = ppConfig.get('pp.external.endpoints') || {};

        var assetsEndpoint = _externalEndpoints.assets;

        vm.isaIcon = assetsEndpoint + '/images/icons/isa/isa-portfolio.svg';
        vm.generalIcon = assetsEndpoint + '/images/icons/isa/general-portfolio.svg';

        // -- util functions

        // -- api

        vm.switch = function (kind) {
            if (!vm.processing && kind !== vm.investor.kind) {
                vm.processing = true;
                vm.switchContext({
                    kind: kind
                }).then(function () {
                    vm.processing = false;
                });
            }
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);