(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.carousel-multi-item', ['pp.components-templates']);

ngModule.directive('ppCarouselMultiItem', [function () {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/carousel-multi-item/carousel-multi-item.tpl.html',
        scope: {
            slides: '=',
            currentSlide: '='
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$element', function ($scope, $timeout, $element) {
            var vm = this;

            // -- initial state

            var __carouselOptions = {
                items: 5,
                lazyLoad: true,
                pagination: false,
                rewind: false,
                responsiveRefreshRate: 500,
                autoHeight: true
            };

            // -- util functions

            function createCarousel() {
                vm.carousel = $($element).find('.owl-carousel').owlCarousel(__carouselOptions);
            }

            // -- api

            vm.setCurrentSlide = function (index) {
                var position = index;
                vm.currentSlide = position;
            };

            vm.goToSlide = function (position) {
                if (vm.carousel) {
                    vm.carousel.trigger('to.owl.carousel', [position, 500, true]);
                }

            };

            vm.isActiveSlide = function (index) {
                return vm.currentSlide === index;
            };

            // -- scope bindings

            $scope.$on('pp.carousel.refresh', function () {
                if (!vm.carousel || !vm.carousel.trigger) {
                    return;
                }

                vm.carousel.trigger('refresh.owl.carousel');
            });

            // -- main

            vm.$onDestroy = function () {
                if (vm.carousel) {
                    vm.carousel.trigger('destroy.owl.carousel');
                }
            };

            vm.$postLink = function () {

                $timeout(createCarousel).then(function () {
                    $scope.$watch('vm.currentSlide', function (position) {
                        if (angular.isDefined(position)) {
                            vm.goToSlide(position);
                        }
                    });
                });
            };

        }]
    };
}]);
})(window, window.angular);