(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.premium-discount', ['pp.widgets-templates', 'pp.filters.numbers', 'pp.values.glossary']);

ngModule.directive('ppPremiumDiscount', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/premium-discount/premium-discount.tpl.html',
        scope: {
            property: '<ppPremiumDiscount'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'glossary', function ($scope, glossary) {
            var vm = this;

            // -- initial state

            vm.premiumDiscount = glossary.premiumDiscount ? glossary.premiumDiscount.desc : null;

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);