(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-header', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.route',
    'pp.filters.property'
]);

/**
 * @ngdoc directive
 * @name ppPropertyCardHeader
 * @description
 * Renders a property card header
 *
 * @restrict A
 * @scope
 * @param {Object} ppPropertyCardHeader
 */
ngModule.directive('ppPropertyCardHeader', [function () {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-card-header/property-card-header.tpl.html',
        scope: {
            property: '<ppPropertyCardHeader',
            withImage: '<',
            withLink: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppConfig', 'routeService', function ($scope, ppConfig, routeService) {
            var vm = this;

            // -- initial state

            var __config = ppConfig.get('pp.external.endpoints') || {};
            var baseAssets = __config.assets;

            // -- util functions

            // -- lifecycle

            vm.$onChanges = function (changes) {
                if (changes.property && changes.property.currentValue && changes.property.currentValue.symbol) {
                    vm.propertyImage = baseAssets + '/images/properties/' + changes.property.currentValue.symbol + '/thumb/image1.jpg';
                    vm.propertyLink = routeService.getIndividualPropertyPath(changes.property.currentValue.symbol);
                }
            };

            // -- api

            // -- main
        }]
    };
}]);
})(window, window.angular);