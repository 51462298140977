(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.withdrawal-bank-account-iban', ['pp.widgets-templates', 'pp.services.investor', 'pp.components.tooltip', 'pp.values.form-lists']);

/**
 * @ngdoc directive
 * @name ppWithdrawalBankAccountIban
 * @description
 * Allows the user to register a bank account.
 *
 *
 * @restrict A
 */
ngModule.directive('ppWithdrawalBankAccountIban', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/withdrawal-bank-account-iban/withdrawal-bank-account-iban.tpl.html',
        scope: {
            account: '=ppWithdrawalBankAccountIban'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['formLists', 'investorService', function (formLists, investorService) {
            var vm = this;

            //-- initial state

            vm.formLists = formLists;

            vm.bicRegexp = investorService.bicRegexp;
            vm.ibanRegexp = investorService.ibanRegexp;
        }]
    };
}]);
})(window, window.angular);