(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.refer-a-friend', [
    'pp.widgets-templates',
    'pp.widgets.refer-a-friend-anon',
    'pp.widgets.refer-a-friend-share',
    'pp.services.investor',
    'pp.services.config',
    'pp.services.preference',
    'pp.services.core',
    'pp.services.referral',
    'pp.services.route'
]);

var PAGE_NAME = 'refer-a-friend';

ngModule.component('ppReferAFriend', {
    templateUrl: 'widgets/_angular/refer-a-friend/refer-a-friend.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$q', 'investorService', 'configService', 'preferenceService', 'referralService', 'ppTrack', 'routeService', function ($q, investorService, configService, preferenceService, referralService, ppTrack, routeService) {
        var vm = this;

        // -- initial state

        vm.termsUrl = routeService.referralTermsLink;

        // -- util functions

        function getIsInvestorGifting() {
            return preferenceService.isInvestorGiftingReferral().then(function (isInvestorGifting) {
                vm.isInvestorGifting = isInvestorGifting;
            });
        }

        function setIsInvestorReferredByIntroducer(investor) {
            return referralService.isInvestorReferredByIntroducer(investor).then(function (isReferredByIntroducer) {
                vm.isReferredByIntroducer = isReferredByIntroducer;
            });
        }

        function getReferralStats() {
            return investorService.getUserReferralStats().then(function (stats) {
                vm.referralStats = stats;
            });
        }

        function updateInvestor(investor) {
            vm.investor = investor;
            if (!investor.anon) {
                setIsInvestorReferredByIntroducer(investor);
                getReferralStats();
            }
        }

        function init() {
            return investorService.getInvestor().then(function (investor) {
                vm.investor = investor;
                vm.hasBeenReferred = !!vm.investor.referredBy;

                if (!investor.anon) {
                    return $q.when([
                        getIsInvestorGifting(),
                        setIsInvestorReferredByIntroducer(investor),
                        getReferralStats()
                    ]);
                } else {
                    return setIsInvestorReferredByIntroducer(investor);
                }
            });
        }

        // -- api

        vm.setInvestorAsGiftingReferral = function () {
            ppTrack.action(PAGE_NAME + '.set-as-gifting');
            return preferenceService.setInvestorAsGiftingReferral()
                .then(init);
        };

        vm.setReferralCode = function (code) {
            ppTrack.action(PAGE_NAME + '.set-referral-code');
            return referralService.setReferralCode(code).then(function () {
                return init();
            });
        };

        // -- scope bindings

        vm.$onDestroy = function () {
            investorService.off('investor-updated', updateInvestor);
        };

        // -- main

        init().then(function () {
            ppTrack.pageLoad(PAGE_NAME);

            investorService.on('investor-updated', updateInvestor);

            vm.ready = true;
        });

    }]
});
})(window, window.angular);