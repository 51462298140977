(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.run', [
    'pp.services.core',
    'pp.services.investor',
    'pp.services.auth',
    'pp.services.preference'
]);

/**
 * @ngdoc service
 * @name runService
 *
 * @description
 */
ngModule.service('runService', ['$window', '$document', '$q', 'ppConfig', 'ppTrack', 'investorService', 'authService', 'preferenceService', function ($window, $document, $q, ppConfig, ppTrack, investorService, authService, preferenceService) {

    var api = {};

    var promiseCache = {};

    function loadRecaptcha(document) {
        // @TODO put this in fe-config table
        var __reCaptchaEnabled = true;

        if (__reCaptchaEnabled) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://www.google.com/recaptcha/api.js?onload=vcRecaptchaApiLoaded&render=explicit';
            document.getElementsByTagName('head')[0].appendChild(script);
        }
        return $q.when();
    }

    // if a query string like "icta=aa-zz" is present (typically in our email comms)
    function trackContextInboundCtas(window) {
        var search = window.location.search || '';
        var matchInbound = search.match(/icta=([a-z\-_^&]+)/i);
        if (matchInbound) {
            ppTrack.setContext('hit.inbound-cta', matchInbound[1].toLowerCase());
        }

        return $q.when();
    }

    function onExtraUserDataSuccess(requiredUserDataDialogService) {
        requiredUserDataDialogService.close();
    }

    function checkUserExtraDataRequired(user, requiredUserDataDialogService) {
        return $q.all({
                isSourceOfFundsRequired: preferenceService.isSourceOfFundsRequired(),
                goodReputeAgreed: preferenceService.hasInvestorAgreedToGoodReputeStatement()
            })
            .then(function (res) {
                if (investorService.isExtraUserDataRequired(user) || !res.goodReputeAgreed || res.isSourceOfFundsRequired || investorService.platformUseConfirmationRequired(user)) {
                    return requiredUserDataDialogService.show(
                        user,
                        res.goodReputeAgreed,
                        res.isSourceOfFundsRequired,
                        onExtraUserDataSuccess.bind(null, requiredUserDataDialogService)
                    );
                }
            });
    }

    function setupUser(requiredUserDataDialogService, window) {
        // allows investor service to set the stage for anonymous users, based on the pp_funnel cookie
        investorService.setCookieData(ppTrack.getUserData());

        return investorService.getInvestor().then(function (user) {

            // used in core js, useful for third parties to know when investor is loaded;
            window.investorLoaded.resolve(user);

            if (!user.anon) {
                // start auth heartbeat to check if backend session is expired and force logout the page
                authService.startHeartbeat();
                return checkUserExtraDataRequired(user, requiredUserDataDialogService);
            }

        });
    }

    function investorListeners() {

        // subscribe to updates user data context for all tracked events
        // after the user is loaded AND after the user is updated
        // context is refreshed after ops that modify user attributes that are relevant for tracking (login/signup/logout)
        investorService.on('investor-updated', function (user) {
            if (user.uniqueIdentifier) {
                ppTrack.updateUniqueId(user.uniqueIdentifier);
            }
            ppTrack.setContext(investorService.getUserTrackContext(user));
        });

        // logout and reload if an investor service
        // @todo can me made more abstract to handle more cases (but careful with blanket solutions, like $http interceptors)
        // or replicated effortlessly across relevant services
        investorService.on('unauthorised', authService.handleUnauthorised);

        return $q.when();
    }

    api.init = function (requiredUserDataDialogService) {
        var cachKey = 'run';

        // Only run this once per app visit
        if (!promiseCache[cachKey]) {

            var promises = [
                setupUser(requiredUserDataDialogService, $window),
                investorListeners(),
                trackContextInboundCtas($window),
                loadRecaptcha($document[0])
            ];

            promiseCache[cachKey] = $q.all(promises);
        }

        return promiseCache[cachKey];
    };

    api.checkUserExtraDataRequired = checkUserExtraDataRequired;

    api._trackContextInboundCtas = trackContextInboundCtas;
    api._setupUser = setupUser;

    return api;
}]);
})(window, window.angular);