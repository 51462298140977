(function(window, angular){'use strict';

var ngModule = angular.module('pp.filters.dates', [
    'pp.filters.numbers',
    'pp.services.date'
]);

var DATE_SERVER_FORMAT = 'D-M-YYYY';
var DATE_OUTPUT_FORMAT = 'Do MMMM YYYY';
var DATE_INPUT_FORMAT = 'DDMMYYYY';

/**
 * @ngdoc filter
 * @name formatDateHuman
 * @description
 * Returns date in 27th February 2015 format. Can take date and alternative pattern.
 *
 */

ngModule.filter('formatDateHuman', ['$filter', 'ordinalService', function ($filter, ordinalService) {
    return function (dateInput, format) {
        if (!dateInput) {
            dateInput = Date.now();
        }
        if (!format) {
            format = 'd MMMM yyyy';
        }

        var dateOridinal = $filter('date')(dateInput, 'd') + ordinalService.ordinal($filter('date')(dateInput, 'd'));

        var formatOrdinalBinding = format.replace('d', '[--]');

        var dateOrdinalBinding = $filter('date')(dateInput, formatOrdinalBinding);
        var dateLowercaseTime = dateOrdinalBinding.replace(/AM/g, 'am').replace(/PM/g, 'pm');
        var dateHumanReadable = dateLowercaseTime.replace('[--]', dateOridinal);

        return dateHumanReadable;
    };
}]);

ngModule.filter('dateServerInThePast', ['$filter', 'dateService', function ($filter, dateService) {
    return function (dateInput) {
        var date = dateService.parseDateInThePast(dateInput);

        if (!date.isValid()) {
            return '';
        }

        return date.format(DATE_SERVER_FORMAT);
    };
}]);

ngModule.filter('dateInputTextInThePast', ['$filter', 'dateService', function ($filter, dateService) {
    return function (dateInput) {
        var date = dateService.parseDateInThePast(dateInput);

        if (!date.isValid()) {
            return '';
        }

        return date.format(DATE_INPUT_FORMAT);
    };
}]);

ngModule.filter('dateOutputTextInThePast', ['$filter', 'dateService', function ($filter, dateService) {
    return function (dateInput) {
        var date = dateService.parseDateInThePast(dateInput);

        if (!date.isValid()) {
            return '';
        }

        return date.format(DATE_OUTPUT_FORMAT);
    };
}]);

ngModule.filter('ppTimeLeft', [function () {

    function calcuateTimeUnit(msLeft, msPerUnit) {
        return Math.floor(msLeft / msPerUnit);
    }

    return function (startDate, endDate) {
        if (!startDate || !endDate) {
            return;
        }

        var msPerDay = 86400000;
        var msPerHour = 3600000;
        var msPerMinute = 60000;

        var timeValue;
        var timeUnit;
        var plural;

        var startTime = startDate.getTime();
        var endTime = endDate.getTime();

        if (!endTime) {
            return '-';
        }

        var msLeft = endTime - startTime;

        if (msLeft <= 0) {
            timeUnit = 0;
            timeValue = ' Day';
        } else if (msLeft <= msPerHour) {
            timeUnit = calcuateTimeUnit(msLeft, msPerMinute);
            //If there are only seconds left show one minute remaining
            timeUnit = timeUnit || 1;
            timeValue = ' Minute';
        } else if (msLeft <= msPerDay) {
            timeUnit = calcuateTimeUnit(msLeft, msPerHour);
            timeValue = ' Hour';
        } else {
            timeUnit = calcuateTimeUnit(msLeft, msPerDay);
            timeValue = ' Day';
        }

        plural = timeUnit !== 1 ? 's' : '';

        return timeUnit + timeValue + plural;

    };

}]);
})(window, window.angular);