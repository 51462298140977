(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-cell', [
    'pp.widgets-templates',
    'pp.filters.property',
    'pp.components.tooltip',
    'pp.services.core',
    'pp.services.route',
    'pp.values.glossary'
]);

ngModule.component('ppPropertyCell', {
    templateUrl: 'widgets/_angular/property-cell/property-cell.tpl.html',
    bindings: {
        property: '<',
        numberOfBids: '<',
        numberOfOffers: '<',
        holdings: '<',
        hideSuspension: '<',
        votedMap: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$filter', 'ppConfig', 'ppUtil', 'glossary', 'R', 'ppMoment', 'routeService', function ($scope, $filter, ppConfig, ppUtil, glossary, R, ppMoment, routeService) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints') || {};

        var baseAssets = __config.assets;

        // -- util functions

        function createVoteDescription(vote) {
            if (vote) {
                var voteDate = '';
                if (vote.votedOn) {
                    voteDate = ' on ' + $filter('date')(vote.votedOn, 'dd/MM/yyyy');
                }
                return 'Voted to <b>' + vote.choice.toUpperCase() + '</b>' + voteDate;
            } else {
                return '';
            }
        }

        // -- api

        vm.glossary = glossary;

        // -- lifecycle

        vm.$onChanges = function (changes) {
            if (changes.property && changes.property.currentValue) {
                var property = changes.property.currentValue;
                vm.propertyImage = baseAssets + '/images/properties/' + property.symbol + '/thumb/image1.jpg';
            }

            vm.voteDescription = createVoteDescription(R.path(['votedMap', 'currentValue', vm.property.symbol], changes));

            vm.beingSoldDescription = 'Trading is enabled, but buyers and sellers need to be aware that this property is in the process of being sold. <a class="link" href="' + routeService.fiveYearTradingHelp + '" target="_blank">Read more here</a>.';
        };

        // -- main

    }]
});
})(window, window.angular);