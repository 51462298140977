(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.radio-button-list', ['pp.widgets-templates', 'pp.components.radio-button']);

ngModule.component('ppRadioButtonList', {
    templateUrl: 'widgets/_angular/radio-button-list/radio-button-list.tpl.html',
    bindings: {
        buttons: '<',
        activeItem: '=',
        alignment: '@'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.setActive = function (key) {
            vm.activeItem = key;
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);