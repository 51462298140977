(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.form', []);

ngModule.service('formService', [function () {

    var api = {};

    api.getErrorValueList = function (ngForm) {

        var errorValueList = [];

        if (!ngForm) {
            return errorValueList;
        }

        var errorKeys = Object.keys(ngForm.$error);

        if (!errorKeys || !errorKeys.length) {
            return errorValueList;
        }

        for (var ix = 0; ix < errorKeys.length; ix++) {

            if (typeof ngForm.$error[errorKeys[ix]] === 'boolean') {
                errorValueList.push({
                    fieldName: ngForm.$name,
                    type: errorKeys[ix],
                    value: ngForm.$viewValue
                });
            }

            if (angular.isArray(ngForm.$error[errorKeys[ix]])) {

                for (var iy = 0; iy < ngForm.$error[errorKeys[ix]].length; iy++) {

                    if (angular.isObject(ngForm.$error[errorKeys[ix]][iy])) {
                        errorValueList = errorValueList.concat(this.getErrorValueList(ngForm.$error[errorKeys[ix]][iy]));
                    }
                }
            }

        }

        return errorValueList;

    };

    return api;
}]);
})(window, window.angular);