(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.shares-traded-chart', [
    'highcharts-ng',
    'pp.widgets-templates',
    'pp.widgets.performance-warning',
    'pp.components.tooltip',
    'pp.services.property',
    'pp.widgets.chart-date-options',
    'pp.filters.numbers',
    'pp.services.core',
    'pp.values.glossary'
]);

var INITIAL_TRADE_HISTORY_LIMIT = 25;
var HISTORY_TRADE_SMALL_STEP = 25;
var HISTORY_TRADE_REGULAR_STEP = 50;

var DEFAULT_CHART_HEIGHT = 135;
var CHART_MIN_MAX_SAFE_BUFFER = 1;

/**
 * @ngdoc directive
 * @name sharesTradedChart
 * @description
 * Render the shares traded chart
 *
 * @restrict A
 * @scope
 * @param {String} symbol
 *
 * @todo controllerAs and vm
 * @todo use chart factory
 */
ngModule.directive('ppSharesTradedChart', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/shares-traded-chart/shares-traded-chart.tpl.html',
        scope: {
            symbol: '@',
            chartHeight: '@?',
            shareValuation: '<',
            property: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$filter', 'ppBig', 'propertyService', 'glossary', 'R', function ($scope, $timeout, $filter, ppBig, propertyService, glossary, R) {
            var vm = this;

            // -- initial state

            vm.ready = false;
            vm.visible = false;
            vm.loadingError = null;
            vm.sharesTraded = null;

            vm.vpv = glossary.vpv;
            vm.iv = glossary.iv;

            vm.currentDate = new Date();
            var now = new Date();
            var nowZeroHours = now.setHours(0, 0, 0, 0);
            var dateLimitMap = {};
            var dateDiffMap = {};

            var oneDayAgo = new Date(nowZeroHours);
            dateLimitMap['one-day'] = oneDayAgo.setDate(oneDayAgo.getDate() - 1);

            var oneWeekAgo = new Date(nowZeroHours);
            dateLimitMap['one-week'] = oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

            var oneMonthAgo = new Date(nowZeroHours);
            dateLimitMap['one-month'] = oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);

            var threeMonthsAgo = new Date(nowZeroHours);
            dateLimitMap['three-months'] = threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

            var sixMonthsAgo = new Date(nowZeroHours);
            dateLimitMap['six-months'] = sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

            var oneYearAgo = new Date(nowZeroHours);
            dateLimitMap['one-year'] = oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

            var chartHeight;

            // -- util functions

            function getDefaultDateOption(oldestTradeTimestamp) {
                var now = vm.currentDate.valueOf();
                var diff = now - oldestTradeTimestamp;

                if (now - dateLimitMap['one-week'].valueOf() > diff) {
                    return 'one-day';
                } else if (now - dateLimitMap['one-month'] > diff) {
                    return 'one-week';
                } else if (now - dateLimitMap['three-months'] > diff) {
                    return 'one-month';
                } else if (now - dateLimitMap['six-months'] > diff) {
                    return 'three-months';
                } else if (now - dateLimitMap['one-year'] > diff) {
                    return 'six-months';
                } else {
                    return 'one-year';
                }
            }

            function getAverageTransactionPrice(data) {
                var len = data.length;
                var sharesBought = 0;
                var amountPaid = 0;
                for (var ix = 0; ix < len; ix++) {
                    sharesBought = sharesBought + data[ix].volume;
                    amountPaid = amountPaid + (data[ix].avgPrice * data[ix].volume);
                }
                return amountPaid / sharesBought;
            }

            function getVolumeTraded(chartData) {
                return chartData.reduce(function (volume, point) {
                    return volume + (point.volume * point.avgPrice);
                }, 0);
            }

            function getMinPrice(data) {
                return data.reduce(function (value, item) {
                    return (item.y < value) ? item.y : value;
                }, 9999);
            }

            function getMaxPrice(data) {
                return data.reduce(function (value, item) {
                    return (item.y > value) ? item.y : value;
                }, 0);
            }

            function createData(dateLimit) {
                var tradingHistory = vm.tradingHistory || [];
                var chartData = [];
                var len = tradingHistory.length;
                var point;
                var d;
                var ix = 0;
                var utcMilliseconds;

                if (tradingHistory[ix]) {
                    utcMilliseconds = Number(tradingHistory[ix].time);
                }

                while (utcMilliseconds && utcMilliseconds >= dateLimit) {
                    point = tradingHistory[ix];
                    if (!point) {
                        break;
                    }
                    utcMilliseconds = Number(point.time);
                    d = new Date(0);
                    d.setUTCMilliseconds(utcMilliseconds);

                    if (utcMilliseconds >= dateLimit) {
                        chartData.unshift({
                            x: d,
                            avgPrice: point.avgPrice,
                            y: point.avgPrice * 100,
                            volume: point.volume
                        });
                        ix++;
                    }
                }

                vm.firstDate = dateLimit;
                vm.volumeTraded = getVolumeTraded(chartData);
                vm.currentChartLength = chartData.length;
                vm.averageTransactionPrice = getAverageTransactionPrice(chartData) * 100;
                return chartData;
            }

            function getGraphMin(shareValuation, shareValuationVpv, minPrice) {
                return Number(
                    ppBig(R.min(shareValuation, R.defaultTo(shareValuation, shareValuationVpv)) < minPrice ? R.max(0, R.min(shareValuation, R.defaultTo(shareValuation, shareValuationVpv)) - CHART_MIN_MAX_SAFE_BUFFER) : R.max(0, minPrice - CHART_MIN_MAX_SAFE_BUFFER)).round(0, 0)
                );
            }

            function getGraphMax(shareValuation, shareValuationVpv, maxPrice) {
                return Number(
                    ppBig(R.max(shareValuation, R.defaultTo(shareValuation, shareValuationVpv)) > maxPrice ? R.max(shareValuation, shareValuationVpv) + CHART_MIN_MAX_SAFE_BUFFER : maxPrice + CHART_MIN_MAX_SAFE_BUFFER).round(0, 0)
                );
            }

            function createChart(chartData) {

                var minPrice = getMinPrice(chartData);
                var maxPrice = getMaxPrice(chartData);
                var shareValuation = vm.shareValuation * 100;
                var maybeShareValuationVpv = R.path(['valuation', 'shareVpv'], vm.property);
                var shareValuationVpv = maybeShareValuationVpv ? maybeShareValuationVpv * 100 : null;

                vm.hasShareValuationVpv = R.not(R.isNil(shareValuationVpv));

                vm.chartOptions = {
                    chart: {
                        type: 'spline',
                        height: chartHeight,
                        color: '#000',
                        backgroundColor: 'rgba(255,255,255,0)',
                        style: {
                            fontFamily: 'Proxima'
                        }
                    },
                    plotOptions: {
                        series: {
                            point: {
                                events: {
                                    mouseOver: function (e) {
                                        var self = this;
                                        $scope.$evalAsync(function () {
                                            vm.selectedPointPrice = Math.round(self.avgPrice * 100) + 'p';
                                            vm.selectedVolume = Math.round((self.avgPrice * self.volume) * 100) / 100;
                                            vm.selectedPointDate = self.x;
                                        });
                                    },
                                    mouseOut: function (e) {
                                        var self = this;
                                        $scope.$evalAsync(function () {
                                            vm.selectedPointPrice = null;
                                            vm.selectedVolume = null;
                                            vm.selectedPointDate = null;
                                        });
                                    }
                                }
                            }
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                    title: {
                        text: null,
                        style: {
                            'color': '#FFF'
                        }
                    },
                    xAxis: {
                        title: {
                            text: null
                        },
                        tickLength: 0,
                        labels: {
                            enabled: false
                        }
                    },
                    yAxis: {
                        allowDecimals: false,
                        title: {
                            text: null
                        },
                        lineWidth: 1,
                        minorGridLineWidth: 0,
                        gridLineWidth: 1,
                        tickLength: 0,
                        min: getGraphMin(shareValuation, shareValuationVpv, minPrice),
                        max: getGraphMax(shareValuation, shareValuationVpv, maxPrice),
                        plotLines: [{
                                color: 'rgba(42,174,245,' + (vm.currentChartLength ? '1' : '0.2') + ')',
                                dashStyle: 'LongDash',
                                width: 1,
                                value: shareValuation,
                                zIndex: 100
                            },
                            {
                                color: 'rgba(68, 211, 113,' + (vm.currentChartLength ? '1' : '0.2') + ')',
                                dashStyle: 'LongDash',
                                width: 1,
                                value: shareValuationVpv,
                                zIndex: 100
                            }
                        ],
                        labels: {
                            format: '{value}p'
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        data: chartData,
                        color: '#497CBC',
                        showInLegend: false,
                        marker: {
                            symbol: 'circle'
                        }
                    }]
                };
            }

            function buildChart(dateLimit) {
                $timeout(function () {
                    createChart(createData(dateLimit));
                }).then(function () {
                    $timeout(function () {
                        vm.visible = true;
                        $scope.$broadcast('highchartsng.reflow');
                    });
                });
            }

            function setUpTradingHistory(tradingHistory) {
                if (tradingHistory.length > 1) {
                    // Only display first eight share prices.
                    vm.tradingHistory = tradingHistory;
                    vm.oldestTradeTimestamp = angular.copy(tradingHistory[tradingHistory.length - 1].time || 0);
                    vm.dateOption = vm.dateOption || getDefaultDateOption(vm.oldestTradeTimestamp);
                    vm.sharesTraded = true;

                    // "All" starts with the first trade on the secondary market up until the current date.
                    dateLimitMap['all'] = new Date(vm.oldestTradeTimestamp);
                    dateLimitMap['all'] = dateLimitMap['all'].getTime();

                    buildChart(dateLimitMap[vm.dateOption]);

                } else {
                    vm.visible = true;
                    vm.sharesTraded = false;
                }
            }

            function loadData() {
                return propertyService.getSecondaryTradingHistory(vm.symbol);
            }

            function bindDataEmitter(symbol) {
                propertyService.on(symbol + '.secondary-trades-updated', function () {
                    loadData().then(function (tradingHistory) {
                        setUpTradingHistory(tradingHistory);
                    }, function () {
                        vm.loadingError = true;
                    });
                });
            }

            // -- api

            vm.toggleOverlay = function () {
                vm.overlay = !vm.overlay;
            };

            vm.onDateChange = function (dateOption) {
                if (dateLimitMap[dateOption] && dateLimitMap[dateOption] !== vm.dateOption) {
                    vm.dateOption = dateOption;
                    buildChart(dateLimitMap[vm.dateOption]);
                }
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                chartHeight = vm.chartHeight || DEFAULT_CHART_HEIGHT;
                chartHeight = Number(chartHeight);

                loadData().then(function (tradingHistory) {
                    setUpTradingHistory(tradingHistory);
                    buildChart(vm.dateOption);
                    bindDataEmitter(vm.symbol);
                }, function () {
                    vm.loadingError = true;
                })['finally'](function () {
                    vm.ready = true;
                });
            };

        }]
    };
}]);
})(window, window.angular);