(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.isa-setup', [
    'pp.widgets-templates',
    'pp.widgets.kyc-and-classification',
    'pp.widgets.isa-declaration',
    'pp.widgets.isa-blocked-business',
    'pp.widgets.isa-blocked-retail',
    'pp.services.investor',
    'pp.services.nationality',
    'pp.services.route',
    'pp.services.receipt',
    'pp.services.core',
    'pp.services.config'
]);

var PAGE_NAME = 'isa-setup';

ngModule.component('ppIsaSetup', {
    templateUrl: 'widgets/_angular/isa-setup/isa-setup.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$q', '$window', '$anchorScroll', 'investorService', 'routeService', 'receiptService', 'nationalityService', 'configService', 'ppTrack', function ($scope, $q, $window, $anchorScroll, investorService, routeService, receiptService, nationalityService, configService, ppTrack) {
        var vm = this;

        // -- initial state

        var declarationKeyPromiseCache;

        // -- util functions

        function loadIsaDeclarationKey() {
            if (!declarationKeyPromiseCache) {
                declarationKeyPromiseCache = investorService.getIsaDeclarationKey();
            }

            return declarationKeyPromiseCache;

        }

        function loadInvestorUser() {
            return $q.all({
                investor: investorService.getInvestor(),
                investors: investorService.getUserInvestors(),
                niNumber: investorService.getInvestorNiNumber(),
                isNationalitySet: nationalityService.isNationalitySet(),
                declarationKeys: loadIsaDeclarationKey()
            }).then(function (res) {
                vm.investor = res.investor;
                vm.investors = res.investors;
                vm.niNumber = res.niNumber;
                var declarationKeys = res.declarationKeys || {};
                vm.declarationToken = declarationKeys.declarationToken;
                vm.termsToken = declarationKeys.termsToken;

                return res;
            });
        }

        function handleSuccess() {

            var receipt = {
                receiptType: 'ISA_CREATED'
            };

            ppTrack.deferEvent(PAGE_NAME + '.isa-created.success');
            receiptService.pushReceipt(receipt);
            $window.location.href = routeService.dashboardPathIsa;
        }

        function handleError(error) {
            vm.processing = false;
            ppTrack.event(PAGE_NAME + '.submit.error', {
                error: error.reason
            });

            return $q.reject(error);
        }

        function getStep(isNationalitySet, investor) {
            if (investorService.hasPassedKyc(investor) && investorService.nationalityNotRequired(isNationalitySet, investor)) {
                if (investorService.isBusiness(investor)) {
                    return 'isa-blocked-business';
                } else if (investorService.isRestrictedInvestor(investor)) {
                    return 'isa-blocked-retail';
                } else {
                    return 'isa-declaration';
                }
            } else {
                return 'kyc';
            }
        }

        function setStep(step) {
            vm.step = step;
            ppTrack.event(PAGE_NAME + '.step.' + step);
            $anchorScroll();
        }

        function redirectEscape() {
            $window.location.href = routeService.marketplacePath;
        }

        function redirectToDashboard() {
            $window.location.href = routeService.dashboardPathIsa;
        }

        function handleIsaAlreadySetup() {
            ppTrack.deferEvent(PAGE_NAME + '.isa-investor.already-created.redirect');
            redirectToDashboard();
        }

        function handleKycFail() {
            ppTrack.deferEvent(PAGE_NAME + '.kyc-failure.redirect');
            redirectEscape();
        }

        function handleIsIsaPublic(isIsaPublic) {
            if (!isIsaPublic) {
                redirectEscape();
                ppTrack.deferEvent(PAGE_NAME + 'isa-not-public.redirect');
                return $q.reject();
            }
        }

        function init(res) {
            if (investorService.isIsaSetup(vm.investors)) {
                return handleIsaAlreadySetup();
            }

            setStep(getStep(res.isNationalitySet, vm.investor));
        }

        // -- api

        vm.submitIsaDeclaration = function (niNumber) {
            vm.processing = true;
            return investorService.createIsaInvestor(vm.declarationToken, vm.termsToken, niNumber)
                .then(handleSuccess, handleError);
        };

        vm.kycSuccess = function () {
            return loadInvestorUser()
                .then(init);
        };

        vm.kycFail = handleKycFail;

        vm.onReclassification = function () {
            return loadInvestorUser()
                .then(init);
        };

        // -- scope bindings

        vm.$onDestroy = function () {
            ppTrack.pageClose(PAGE_NAME);
        };

        // -- main

        configService.isIsaPublic()
            .then(loadInvestorUser)
            .then(function (res) {
                init(res);

                ppTrack.pageLoad(PAGE_NAME);
                vm.ready = true;

            }, function () {
                vm.loadError = true;
            });

    }]
});
})(window, window.angular);