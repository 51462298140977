(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.invest-dialog-primary-not-available', [
    'pp.widgets-templates',
    'pp.services.core'
]);

var DASHBOARD_URL = '/my-portfolio#/dashboard';
var MARKETPLACE_URL = '/s/uk';

ngModule.component('ppInvestDialogPrimaryNotAvailable', {
    templateUrl: 'widgets/_angular/invest-dialog-primary-not-available/invest-dialog-primary-not-available.tpl.html',
    bindings: {
        pageName: '<',
        sharesAvailable: '<',
        sharesRequested: '<',
        paymentAmount: '<',
        availableFunds: '<',
        fundsUpdated: '<',
        sharesUpdated: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', function ($scope, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions
        function redirect(href) {
            ppTrack.closeAll().then(function () {
                window.location.href = href;
            });
        }

        // -- api
        vm.retry = function () {
            ppTrack.pageAction(vm.pageName, 'primaryNotAvailabe.retry');
            $scope.$parent.dialogDefer.resolve();
        };

        vm.exitMarketplace = function () {
            ppTrack.pageAction(vm.pageName, 'primaryNotAvailabe.exitMarketplace');
            redirect(MARKETPLACE_URL);
        };

        vm.exitDashboard = function () {
            ppTrack.pageAction(vm.pageName, 'primaryNotAvailabe.exitDashboard');
            redirect(DASHBOARD_URL);
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);