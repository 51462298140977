(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-fund', [
    'pp.widgets-templates',
    'pp.components.time-left-unit',
    'pp.components.time-left-value',
    'pp.filters.numbers'
]);

ngModule.component('ppPropertyDetailsFund', {
    templateUrl: 'widgets/_angular/property-details-fund/property-details-fund.tpl.html',
    bindings: {
        property: '<',
        propertyPage: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);