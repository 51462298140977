(function(window, angular){'use strict';

var FORM_NAME = 'preferencesForm';

var ALLOWED_STEPS = {
    'marketing-preferences': 'marketing-preferences',
    'classification': 'classification'
};

var DEFAULT_STEP = 'marketing-preferences';
var CLASSIFICATION_STEP = 'classification';

var ngModule = angular.module('pp.widgets.welcome-signup', [
    'pp.widgets-templates',
    'pp.widgets.marketing-preferences-form-quick',
    'pp.widgets.classification',
    'pp.components-templates',
    'pp.components.focus-first-error',
    'pp.services.core',
    'pp.services.settings',
    'pp.services.marketing',
    'pp.services.investor'
]);

ngModule.component('ppWelcomeSignup', {
    templateUrl: 'widgets/_angular/welcome-signup/welcome-signup.tpl.html',
    bindings: {
        onComplete: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', '$timeout', '$q', 'ppTrack', 'marketingService', 'settingsService', 'investorService', function ($scope, $window, $timeout, $q, ppTrack, marketingService, settingsService, investorService) {
        var vm = this;
        vm.contactOptions = [];
        vm.subscriptions = [];
        vm.ready = false;
        vm.contactError = false;

        vm.quizPassed = false;

        // -- initial state

        // -- util functions

        function setStep(step) {
            vm.step = ALLOWED_STEPS[step] || DEFAULT_STEP;
            ppTrack.setContext({
                'welcome-signup-step': step
            });

            ppTrack.event('welcome-signup.step.' + vm.step);
        }

        function handleSuccess() {
            ppTrack.deferEvent('marketing-preferences.submit.success');
            vm.onComplete();
        }

        function handleError(error) {
            vm.processing = false;
            vm.apiError = true;
            ppTrack.event('marketing-preferences.submit.error');
            return $q.reject(error);
        }

        function formatSubscriberPreferences(canContact) {
            var subscriptionsObj = vm.subscriptions.map(function (subscription) {
                return {
                    id: subscription.id,
                    name: subscription.name,
                    subscribed: canContact
                };
            });

            var contactObj = vm.contactOptions.map(function (contactOption) {
                return {
                    type: contactOption.type,
                    enabled: canContact
                };
            });

            var preferencesObj = {
                subscriberPreferences: {
                    subscriptions: subscriptionsObj,
                    contactPreferences: contactObj
                }
            };

            return preferencesObj;
        }

        function init(user) {
            if (investorService.isClassifiedUser(user)) {
                setStep(DEFAULT_STEP);
            } else {
                setStep(CLASSIFICATION_STEP);
            }
        }

        // -- api

        vm.onClassificationFail = function () {
            ppTrack.event('welcome-signup.classification.fail');
            setStep(DEFAULT_STEP);
        };

        vm.onClassificationSuccess = function () {
            ppTrack.event('welcome-signup.classification.pass');
            setStep(DEFAULT_STEP);
        };

        vm.skip = function () {
            ppTrack.action('welcome-signup.skip');
            vm.onComplete();
        };

        vm.submit = function (canContact) {
            ppTrack.action('marketing-preferences.submit');

            vm.processing = true;
            vm.apiError = false;

            var preferencesObj = formatSubscriberPreferences(canContact);

            return marketingService.setMarketingPreference(preferencesObj)
                .then(handleSuccess, handleError);

        };

        // -- scope bindings

        // -- main

        var promises = {
            preferences: marketingService.getMarketingPreference(),
            user: investorService.getInvestor()
        };

        $q.all(promises).then(function (data) {
            var preferences = data.preferences || {};
            vm.subscriptions = preferences.subscriptions || [];
            vm.contactOptions = preferences.contactPreferences || [];
            vm.user = data.user;
            init(vm.user);
            vm.ready = true;

        }, function () {
            // unable to load data move on...
            vm.onComplete();

        });

    }]
});
})(window, window.angular);