(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.input-password', ['pp.components-templates', 'pp.widgets-templates', 'pp.widgets.password-rules', 'pp.components.password-validation']);

ngModule.directive('ppInputPassword', [function () {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/input-password/input-password.tpl.html',
        scope: {
            password: '=ppInputPassword',
            feedbackEnabled: '<',
            onSubmit: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state
            vm.shouldDisplayPasswordRules = false;
            vm.showPassword = false;

            // -- util functions

            // -- api

            vm.passwordFocus = function () {
                vm.shouldDisplayPasswordRules = true;
            };

            vm.showPasswordClick = function () {
                vm.showPassword = !vm.showPassword;
            };

            vm.submit = function () {
                vm.onSubmit();
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);