(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.live-marketplace-details-dialog', ['pp.services.core', 'pp.services.dialog', 'pp.widgets.live-marketplace-details']);

/**
 * @ndgoc service
 * @description
 * checks if we need to notify the user that they have been qualified/disqualified for an rff scheme
 * shows the dialog and sets a preference when the user dismisses it
 * so that the dialog is never shown again for the same scheme
 */
ngModule.service('liveMarketplaceDetailsDialogService', ['$rootScope', 'ppTrack', 'dialogService', function ($rootScope, ppTrack, dialogService) {

    // -- util functions

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(activeSection, recentTrades, mostTraded) {
        var scope = $rootScope.$new();
        var options = {
            clickClose: true,
            escapeClose: true,
            showClose: true,
            scope: scope,
            classNames: 'live-marketplace-dialog-modal',
        };
        scope.activeSection = activeSection;
        scope.recentTrades = recentTrades;
        scope.mostTraded = mostTraded;
        options.contents = '<div pp-live-marketplace-details active-section="activeSection" recent-trades="recentTrades" most-traded="mostTraded"></div>';

        return dialogService.show(options);
    }

    // -- api

    var api = {};

    api.show = function (activeSection, recentTrades, mostTraded) {
        showDialog(activeSection, recentTrades, mostTraded).then(function () {
            ppTrack.action('live-marketplace-details.dismiss');
        });
    };

    return api;
}]);
})(window, window.angular);