(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-list', [
    'pp.widgets-templates',
    'pp.widgets.property-card',
    'pp.widgets.property-card-simple',
    'pp.widgets.property-card-simple-link',
    'pp.widgets.advance-pre-order-card',
    'pp.widgets.upcoming-isa-card',
    'pp.widgets.loading-card',
    'pp.widgets.loading-card-simple'
]);

ngModule.directive('ppPropertyCardList', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-card-list/property-card-list.tpl.html',
        scope: {
            properties: '<ppPropertyCardList',
            isLoading: '<',
            loadingMore: '<',
            loadingCardCount: '<',
            user: '<',
            apoFunds: '<',
            bondCardConfig: '<',
            holdings: '<',
            offers: '<',
            bids: '<',
            newCards: '<',
            useLinks: '<',
            viewPropertyDetail: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            function createList(n) {
                return Array.apply(null, Array(n)).map(function () {
                    return {};
                });
            }

            // -- util functions

            // -- api

            vm.onViewDetail = function (property) {
                vm.viewPropertyDetail({
                    property: property
                });
            };

            // helper for ng-repeat on card-loading directive

            // -- scope bindings

            vm.$onInit = function () {
                if (vm.isLoading) {
                    vm.list = createList(vm.loadingCardCount);
                } else {
                    vm.list = vm.properties;
                }

                $scope.$watch('vm.isLoading', function (loading) {
                    if (loading) {
                        vm.list = createList(vm.loadingCardCount);
                    }
                });

                $scope.$watch('vm.properties', function (properties) {

                    if (properties && !vm.isLoading) {
                        vm.list = properties;
                    }
                });
            };

            // -- main
        }]
    };
}]);
})(window, window.angular);