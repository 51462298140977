(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.live-marketplace', [
    'yaru22.angular-timeago',
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.marketplace',
    'pp.services.number',
    'pp.widgets.live-ticker',
    'pp.widgets.live-recent-trades',
    'pp.widgets.live-most-traded',
    'pp.filters.numbers',
    'pp.ui.services.live-marketplace-details-dialog'
]);

var MINIMUM_DISPLAY_WIDTH = 1024;

var POLL_INTERVAL_MS = 20000;

var RID_LENGTH = 8;

var SHOW_MORE_STORAGE_KEY = 'pp.live-marketplace.show-more';

function ridGenerator(len) {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    var str = '';
    while (str.length < len) {
        str += S4();
    }
    return str.substring(0, len);
}

ngModule.controller('ppLiveMarketplaceCtrl', ['$scope', '$timeout', '$q', '$interval', '$filter', 'ppConfig', 'ppTrack', 'marketplaceService', 'numberService', 'liveMarketplaceDetailsDialogService', function ($scope, $timeout, $q, $interval, $filter, ppConfig, ppTrack, marketplaceService, numberService, liveMarketplaceDetailsDialogService) {
    var vm = this;

    // -- initial state

    // -- util functions

    function showMoreDetailsModal(activeSection) {
        liveMarketplaceDetailsDialogService.show(activeSection, vm.trades, vm.mostTraded);
    }

    function updateData() {
        return marketplaceService.refreshLatestTradingData(vm.trades, vm.mostTraded).then(function (data) {
            vm.trades = data.trades;
            vm.mostTraded = data.mostTraded;

            if (!vm.trades.length || !vm.mostTraded.length) {
                vm.noTrades = true;
                vm.noTradesLoaded();
            }

            $timeout(updateData, POLL_INTERVAL_MS);
        });
    }

    // -- api

    vm.showDetailsRecentTrades = function () {
        ppTrack.action('live-ticker.show-details.recent-trades');
        showMoreDetailsModal('recentTrades');
    };

    vm.showDetailsMostTraded = function () {
        ppTrack.action('live-ticker.show-details.most-traded');
        showMoreDetailsModal('mostTraded');
    };

    // -- main

    updateData().then(function () {
        vm.ready = true;
        ppTrack.event('pp.live-ticker.loaded');
    });
}]);

ngModule.directive('ppLiveMarketplace', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/live-marketplace/live-marketplace.tpl.html',
        scope: {
            noTradesLoaded: '&noTrades'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: 'ppLiveMarketplaceCtrl'
    };
}]);

var TICKER_DURATION_NUMERATOR_MS = 25000;
var TICKER_DURATION_NUMERATOR_MIN_LIMIT_MS = 10000;
var BASE_PAGE_WIDTH = 1296;
var TICKER_TIMEOUT_BEFORE_BIND = 400;
var TICKER_ELEMENT_ID = '#pp-live-ticker-bar-marquee';

ngModule.component('ppLiveTickerBar', {
    templateUrl: 'widgets/_angular/live-marketplace/live-ticker-bar.tpl.html',
    bindings: {
        trades: '<',
        viewDetail: '&'
    },
    controllerAs: 'vm',
    controller: ['$timeout', '$element', '$window', 'ppBrowser', 'ppTrack', function ($timeout, $element, $window, ppBrowser, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions

        function getTickerDuration(windowWidth) {
            if (windowWidth < 480) {
                return 60;
            } else {
                return 70;
            }
        }

        function attachTicker() {
            $($element.find(TICKER_ELEMENT_ID)).marquee({
                speed: getTickerDuration($window.innerWidth),
                direction: 'left',
                pauseOnHover: !ppBrowser.isMobile,
                duplicated: true
            });
        }

        // when it becomes ready

        vm.propertyLinkClick = function (symbol) {
            ppTrack.deferAction('live-ticker-bar.click', {
                symbol: symbol
            });
            vm.viewDetail({
                symbol: symbol
            });
        };

        vm.getChangePctClass = function (change) {
            if (change > 0) {
                return 'positive';
            }

            if (change < 0) {
                return 'negative';
            }
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            vm.tradesCopy = angular.copy(vm.trades);
        };

        vm.$postLink = function () {
            $timeout(attachTicker, TICKER_TIMEOUT_BEFORE_BIND);
        };

        vm.ready = true;

    }]
});
})(window, window.angular);