(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.investor-compliance', [
    'pp.services.core',
    'pp.services.private-nationality',
    'pp.values.regex'
]);

/**
 * @ngdoc service
 * @name investorComplianceService
 *
 * @description
 * Provides methods for investor complicance, primerily around KYC and investor identification services.
 */

ngModule.value('investorComplianceValues', {
    LEI_NOT_FOUND: 'LEI-NOT-FOUND',
    ENTITY_NOT_ACTIVE: 'ENTITY-NOT-ACTIVE',
    LEI_EXPIRED: 'LEI-EXPIRED',
    LEI_NOT_ISSUED: 'LEI-NOT-ISSUED',
    LEI_ISSUED: 'LEI-ISSUED',
    NOT_CORROBORATED: 'NOT-CORROBORATED'
});

ngModule.service('investorComplianceService', ['$http', '$q', '$timeout', 'privateNationalityService', 'R', 'ppMoment', 'ppServerTimeIso', 'investorComplianceValues', 'leiRegex', function ($http, $q, $timeout, privateNationalityService, R, ppMoment, ppServerTimeIso, investorComplianceValues, leiRegex) {

    var promiseCache = {};

    var api = {};

    var helperService = privateNationalityService;

    var API_BASE_PATH = '/feapi/r1';

    // LEI endpoints
    var API_INVESTOR_IDENTIFIERS = API_BASE_PATH + '/investor/identifiers';
    var API_INVESTOR_LEI_RECORDS = API_BASE_PATH + '/investor/lei/:lei';
    var API_INVESTOR_IDENTIFIERS_TYPES = API_BASE_PATH + '/investor-identifiers/types?investorType=corporate';

    var LEI_NOT_FOUND = investorComplianceValues.LEI_NOT_FOUND;
    var ENTITY_NOT_ACTIVE = investorComplianceValues.ENTITY_NOT_ACTIVE;
    var LEI_EXPIRED = investorComplianceValues.LEI_EXPIRED;
    var LEI_NOT_ISSUED = investorComplianceValues.LEI_NOT_ISSUED;
    var LEI_ISSUED = investorComplianceValues.LEI_ISSUED;
    var NOT_CORROBORATED = investorComplianceValues.NOT_CORROBORATED;
    // -- util functions

    /**
     * @ngdoc method
     * @name investorComplianceService#getLeiType
     *
     * @description
     * When queried returns the users LEI type for corporate investors
     *
     */

    api.getLeiType = function () {

        var cacheKey = 'leiTypeCache';

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(API_INVESTOR_IDENTIFIERS_TYPES).then(function (response) {
                if (!response) {
                    return undefined;
                }
                if (response.data.collection) {
                    return response.data.collection[0];
                } else {
                    return undefined;
                }
            }, function (error) {

                delete promiseCache[cacheKey];

                switch (error.status) {
                case 404:
                    return null;
                default:
                    return $q.reject({
                        reason: 'lei-identifiers.error.unexpected'
                    });
                }
            });
        }

        return promiseCache[cacheKey];
    };

    // -- api

    /**
     * @ngdoc method
     * @name investorComplianceService#getLei
     *
     * @description
     * When queried returns the users LEI identifier
     *
     */

    api.getLei = function (leiType) {

        return $http.get(API_INVESTOR_IDENTIFIERS).then(function (response) {
            if (!response) {
                return undefined;
            }
            if (angular.isArray(response.data.collection)) {
                return response.data.collection.filter(function (item) {
                    return item.identifierTypeId === leiType.id;
                })[0] || null;
            } else {
                return undefined;
            }
        }, function (error) {
            switch (error.status) {
            case 404:
                return null;
            default:
                return $q.reject({
                    reason: 'investor-lei.error.unexpected'
                });
            }
        });

    };

    api.getLeiWithoutType = function () {
        return api.getLeiType().then(function (type) {
            return api.getLei(type);
        });
    };

    function _getLeiRecordFromRegister(lei) {
        var path = API_INVESTOR_LEI_RECORDS.replace(':lei', lei);
        return $http.get(path).then(function (response) {
            return R.head(response.data.records);
        });
    }

    function isExpired(expiryDate) {
        return expiryDate && ppMoment(expiryDate).isSameOrBefore(ppMoment(ppServerTimeIso));
    }

    function isLeiRecordExpired(record) {
        return isExpired(R.prop('nextRenewalDate', record));
    }

    function isLeiExpired(lei) {
        return isExpired(R.prop('mifidExpiryDate', lei));
    }

    api.isLeiExpired = function (lei) {
        return isLeiExpired(lei);
    };

    var propNotEq = R.complement(R.propEq);

    function getLeiRecordValidity(record) {
        if (R.isNil(record)) {
            return LEI_NOT_FOUND;
        } else if (propNotEq('corroborationLevel', 'FULLY_CORROBORATED', record) && propNotEq('corroborationLevel', 'PARTIALLY_CORROBORATED', record)) {
            return NOT_CORROBORATED;
        } else if (isLeiRecordExpired(record)) {
            return LEI_EXPIRED;
        } else if (propNotEq('registrationStatus', 'ISSUED', record)) {
            return LEI_NOT_ISSUED;
        } else {
            return LEI_ISSUED;
        }

    }

    function createLeiRejectionReason(validity) {
        return 'investor-lei.error.' + validity;
    }

    api.createLeiRejectionReason = function (validity) {
        return createLeiRejectionReason(validity);
    };

    api.getLeiRecordFromRegister = function (lei) {
        return _getLeiRecordFromRegister(lei).then(function (record) {
            if (!R.isNil(record)) {
                return record;
            } else {
                // On no response try again in 1.5 secs in case of failure from third party service
                return $timeout(function () {
                    return _getLeiRecordFromRegister(lei);
                }, 1500);
            }
        }, function () {
            return;
        });
    };

    api.checkLeiValidity = function (lei) {
        return api.getLeiRecordFromRegister(lei).then(function (record) {
            return {
                record: record,
                validity: getLeiRecordValidity(record)
            };
        });
    };

    function checkLeiValidityAndNormalise(lei, typeId) {
        return api.checkLeiValidity(lei).then(function (res) {
            if (res.validity !== LEI_ISSUED) {
                return $q.reject({
                    reason: createLeiRejectionReason(res.validity)
                });
            }

            return {
                id: typeId,
                identifierValue: lei,
                mifidExpiryDate: res.record.nextRenewalDate
            };
        }, function (err) {
            return $q.reject({
                reason: 'investor-lei.error.unexpected'
            });
        });
    }

    function handleLeiSubmissionSuccess() {
        return true;
    }

    function handleLeiSubmissionError(error) {
        if (R.prop('reason', error)) {
            // Error has already been formed so just pass it through
            return $q.reject(error);
        } else {
            return $q.reject({
                reason: 'investor-lei.error.unexpected'
            });
        }
    }

    /**
     * @ngdoc method
     * @name investorComplianceService#postLei
     *
     * @description
     * Posts LEI identifier
     *
     *
     *  {
     *      identifierTypeId: 1,
     *      value: '123456'
     *  };
     *
     */
    api.postLei = function (typeId, lei) {
        return checkLeiValidityAndNormalise(lei, typeId)
            .then(helperService._postInvestorIdentifier)
            .then(handleLeiSubmissionSuccess)
            .catch(handleLeiSubmissionError);
    };

    api.updateLei = function (typeId, lei) {
        return checkLeiValidityAndNormalise(lei, typeId)
            .then(helperService._putInvestorIdentifier)
            .then(handleLeiSubmissionSuccess)
            .catch(handleLeiSubmissionError);
    };

    /**
     * @ngdoc method
     * @name investorComplianceService#deleteLei
     *
     * @description
     * Posts LEI identifier
     *
     *
     *  @params
     *  typeId: Int
     *
     */

    api.deleteLei = function (typeId) {

        var endpoint = API_INVESTOR_IDENTIFIERS + '/' + typeId;

        return $http.delete(endpoint).then(function () {
            return true;
        }, function (error) {
            switch (error.status) {
            case 404:
                return;
            default:
                return $q.reject({
                    reason: 'investor-lei.error.unexpected'
                });
            }
        });

    };

    api.isLeiSet = function () {
        return api.getLeiWithoutType().then(function (lei) {
                return !!lei;
            })
            .catch(function () {
                return false;
            });
    };

    api.isLeiValid = function (lei) {
        return R.test(leiRegex, lei);
    };

    return api;

}]);
})(window, window.angular);