(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.multiple-choice-question', [
    'pp.widgets-templates',
    'pp.components.checkbox'
]);

var TRANSITION_TIMEOUT = 500;

ngModule.component('ppMultipleChoiceQuestion', {
    templateUrl: 'widgets/_angular/multiple-choice-question/multiple-choice-question.tpl.html',
    bindings: {
        questionKey: '<',
        question: '<',
        onSelectAnswer: '&'
    },
    controllerAs: 'vm',
    controller: ['$timeout', function ($timeout) {
        var vm = this;

        // -- initial state

        vm.checkedModel = {};
        vm.transitioning = false;

        // -- util functions

        // -- api

        vm.setAnswer = function (questionKey, answerKey) {
            if (!vm.transitioning) {

                vm.checkedModel = {};
                vm.checkedModel[answerKey] = true;
                $timeout(function () {
                    vm.onSelectAnswer({
                        questionKey: questionKey,
                        answerKey: answerKey
                    });

                    vm.transitioning = false;

                }, TRANSITION_TIMEOUT);

            }

            vm.transitioning = true;

        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);