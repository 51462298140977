(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.homepage-hero-carousel', [
    'pp.widgets-templates',
    'pp.services.core'
]);

var SCREEN_SMALL = 480;
var SCREEN_IPAD = 769;
var LOAD_DEFER_MS = 2000;

ngModule.component('ppHomepageHeroCarousel', {
    templateUrl: 'widgets/_angular/homepage-hero-carousel/homepage-hero-carousel.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$window', '$element', '$timeout', '$sce', 'ppConfig', function ($scope, $window, $element, $timeout, $sce, ppConfig) {
        var vm = this;

        // -- initial state

        var __endpoints = ppConfig.get('pp.external.endpoints') || {};
        var assetsEndpoint = __endpoints.assets;

        var options = {
            items: 1,
            loop: true,
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            lazyLoad: true,
            autoplay: true,
            dots: false,
            autoplayTimeout: 4000,
            animateOut: 'fadeOut'
        };

        // -- util functions

        function createSuffix() {
            var innerWidth = $window.innerWidth;
            if (innerWidth <= SCREEN_SMALL) {
                return '-s';
            } else if (innerWidth <= SCREEN_IPAD) {
                return '-m';
            } else {
                return '';
            }
        }

        function createImageUrl(imageNumber) {
            return assetsEndpoint + '/images/homepage/carousel/image' + imageNumber + createSuffix() + '.jpg';
        }

        // -- api

        // -- scope bindings

        vm.$onInit = function () {
            vm.images = [
                createImageUrl(1),
                createImageUrl(2),
                createImageUrl(3),
                createImageUrl(4),
                createImageUrl(5)
            ].map($sce.trustAsResourceUrl);
        };

        vm.$postLink = function () {
            $($window).load(function () {
                $timeout(function () {
                    $($element).find('.owl-carousel').owlCarousel(options);
                }, LOAD_DEFER_MS);
            });

        };

        // -- main

    }]
});
})(window, window.angular);