(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.buy-loan-receipt', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.filters.property',
    'pp.services.core'
]);

ngModule.component('ppBuyLoanReceipt', {
    templateUrl: 'widgets/_angular/buy-loan-receipt/buy-loan-receipt.tpl.html',
    bindings: {
        receipt: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', function ($scope, ppConfig) {
        var vm = this;

        var __config = ppConfig.get('pp.external.endpoints');

        // -- initial state

        vm.s3Endpoint = __config ? __config.assets : null;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);