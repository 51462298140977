(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.receipt', ['pp.services.browser-store', 'pp.ui.services.receipt-dialog-service']);
var RECEIPT_STORE_KEY = 'receipt';

/**
 * @ngdoc service
 * @name receiptService
 *
 * @description
 */
ngModule.service('receiptService', ['$http', '$q', 'browserStoreService', 'receiptDialogService', function ($http, $q, browserStoreService, receiptDialogService) {

    var api = {};

    function saveReceipts(receipts) {
        try {
            browserStoreService.setSessionStorageItem(RECEIPT_STORE_KEY, JSON.stringify(receipts));
        } catch (e) {
            //log
        }
    }

    function getReceipts() {
        try {
            return JSON.parse(browserStoreService.getSessionStorageItem(RECEIPT_STORE_KEY)) || [];
        } catch (e) {
            return [];
        }
    }

    /**
     * @ngdoc method
     * @name receiptService#checkForReceipts
     *
     * @description
     * checks for receipt on the browser storage and displays them recursively
     */
    api.checkForReceipts = function () {
        var currentReceipt = api.popReceipt();
        var receiptPromise = currentReceipt ? receiptDialogService.show(currentReceipt) : null;

        if (!receiptPromise) {
            return $q.when();
        }

        return receiptPromise.then(function () {
            return api.checkForReceipts();
        });
    };

    /**
     * @ngdoc method
     * @name receiptService#pushReceipt
     *
     * @description
     * saves receipt on the browser storage and adds it to the existing array of receipts
     */
    api.pushReceipt = function (receipt) {
        var storedReceipts = getReceipts();
        storedReceipts.push(receipt);
        saveReceipts(storedReceipts);
    };

    /**
     * @ngdoc method
     * @name receiptService#popReceipt
     *
     * @description
     * pops the latest receipt from the browser storage and removes it
     */
    api.popReceipt = function () {
        var storedReceipts = getReceipts();
        var receipt = storedReceipts.length ? storedReceipts.pop() : null;
        saveReceipts(storedReceipts);
        return receipt;
    };

    return api;
}]);
})(window, window.angular);