(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.financials-share-valuation', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.widgets.expandable-toggle',
    'pp.values.glossary',
    'pp.components.tooltip'
]);

ngModule.directive('ppFinancialsShareValuation', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/financials-share-valuation/financials-share-valuation.tpl.html',
        scope: {
            property: '<ppFinancialsShareValuation',
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppUtil', 'glossary', function ($scope, ppUtil, glossary) {
            var vm = this;

            // -- initial state

            vm.purchaseCostsBreakdownVisible = false;
            vm.deferredTaxDesc = glossary.deferredTax;

            // -- util functions

            // -- api
            vm.toggleExpandBreakdown = function () {
                vm.purchaseCostsBreakdownVisible = !vm.purchaseCostsBreakdownVisible;
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                var keysExistInProperty = ppUtil.hasPathIn(vm.property, 'valuation.costs') && ppUtil.hasPathIn(vm.property, 'debt');

                if (keysExistInProperty) {
                    vm.latestPropertyValue = vm.property.valuation.latestValue + vm.property.valuation.costs.deferredTax +
                        vm.property.debt.amountRemaining - vm.property.valuation.costs.amortisedCosts - vm.property.valuation.costs.cashSurplus;
                    if (vm.property.valuation.latestValueVpv) {
                        vm.latestPropertyValueVpv = vm.property.valuation.latestValueVpv + vm.property.valuation.costs.deferredTaxVpv +
                            vm.property.debt.amountRemaining - vm.property.valuation.costs.amortisedCosts - vm.property.valuation.costs.cashSurplus;
                    }

                }
            };

        }]
    };
}]);
})(window, window.angular);