(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-team-lp', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.widgets.ways-to-invest',
    'pp.widgets.awards-banner',
    'pp.widgets.risk-warning-badge'
]);

var PAGE_NAME = 'property-team';

ngModule.component('ppPropertyTeamLp', {
    templateUrl: 'widgets/_angular/property-team-lp/property-team-lp.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', 'ppPubSub', 'investorService', 'ppTrack', function ($scope, ppConfig, ppPubSub, investorService, ppTrack) {
        var vm = this;

        // -- initial state
        var __config = ppConfig.get('pp.external.endpoints') || {};
        var s3Endpoint = __config.assets;

        vm.iconPeopleMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-people-marker.png';
        vm.iconLightbulbMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-lightbuld-marker.png';
        vm.iconCogMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-cog-marker.png';

        vm.propertyTeam = s3Endpoint + '/images/marketing/property-team/property-team-squarev2.jpg';
        vm.sourcingAndNegotiating = s3Endpoint + '/images/marketing/property-team/sourcing-and-negotiating.jpg';
        vm.dueDiligence = s3Endpoint + '/images/marketing/property-team/due-diligence.jpg';
        vm.activeManagement = s3Endpoint + '/images/marketing/property-team/active-management.jpg';
        vm.valuations = s3Endpoint + '/images/marketing/property-team/valuations.png';

        vm.skyline = s3Endpoint + '/images/marketing/skyline.png';
        vm.markWeedon = s3Endpoint + '/images/marketing/property-team/Mark.png';
        vm.swethaPatel = s3Endpoint + '/images/marketing/property-team/Swetha.png';

        vm.exampleHeading = 'Example of how property crowdfunding works';
        vm.popularHeading = 'How did property crowdfunding become popular?';
        vm.returnsHeading = 'Does property crowdfunding offer good returns?';
        vm.propertyTypesHeading = 'What types of property can you crowdfund?';
        vm.howToInvestHeading = 'How do you invest in property via crowdfunding?';

        // -- util functions

        // -- api

        vm.openSignup = function () {
            ppPubSub.publish('auth.modal.show', ['signup', null, null, null, null]);
        };

        vm.openLogin = function () {
            ppPubSub.publish('auth.modal.show', ['login', null, null, null, null]);
        };

        // -- scope bindings

        // -- main

        investorService.getInvestor().then(function (user) {
            vm.user = user;
            ppTrack.pageLoad(PAGE_NAME);
        });

    }]
});
})(window, window.angular);