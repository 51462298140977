(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.required-user-data', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.preference',
    'pp.widgets.classification',
    'pp.widgets.good-repute-statement-form',
    'pp.widgets.source-of-funds',
    'pp.widgets.take-time-to-learn-more',
    'pp.widgets.cooling-off-period-end'

]);

ngModule.directive('ppRequiredUserData', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/required-user-data/required-user-data.tpl.html',
        scope: {
            user: '<',
            goodReputeAgreed: '<',
            onSuccess: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$q', '$element', '$window', 'ppTrack', 'investorService', 'preferenceService', 'ppMoment', 'R', 'ppServerTimeIso', function ($scope, $q, $element, $window, ppTrack, investorService, preferenceService, ppMoment, R, ppServerTimeIso) {
            var vm = this;

            // -- initial state

            // -- util functions

            function purgeUser() {
                investorService.purgeCache('^investor$');
            }

            function purgePreferences() {
                preferenceService.purgeCache();
            }

            function getData() {
                var promises = {
                    goodRepute: preferenceService.hasInvestorAgreedToGoodReputeStatement(),
                    isSourceOfFundsRequired: preferenceService.isSourceOfFundsRequired(),
                    areKeyRisksUnderstood: preferenceService.areKeyRisksUnderstood(),
                    user: investorService.getInvestor()
                };

                return $q.all(promises);
            }

            function setStep(step) {
                ppTrack.event('required-user-data.step.' + step);
                vm.step = step;
            }

            function hasRightStageToViewLearnMore(stage) {
                return [
                    'initial',
                    'riComplianceRequired',
                    'failedQuiz',
                    'signup',
                    'classified'
                ].indexOf(stage) > -1;
            }

            function init() {
                return getData().then(function (responses) {
                    if (investorService.platformUseConfirmationRequired(responses.user)) {
                        $($element).parent('.modal').removeClass('large');
                        setStep('cooling-off-period-end');
                    } else if (hasRightStageToViewLearnMore(responses.user.stage) && !responses.areKeyRisksUnderstood) {
                        $($element).parent('.modal').removeClass('large');
                        setStep('take-time-to-learn-more');
                    } else if (responses.user.needsReclassification) {
                        $($element).parent('.modal').addClass('large');
                        setStep('reclassification');
                    } else if (!responses.goodRepute) {
                        setStep('good-repute');
                        // makes modal smaller version, hack because brad
                        $($element).parent('.modal').removeClass('large');
                    } else if (responses.isSourceOfFundsRequired) {
                        setStep('source-of-funds');
                    } else {
                        vm.finish();
                    }
                });
            }

            // -- api

            vm.onCloseAccount = function () {
                vm.processing = true;
                investorService.confirmPlatformUse(false).then(function () {
                    $window.location.href = '/logout';
                });
            };

            vm.onCoolingOffPeriodContinue = function () {
                vm.processing = true;
                investorService.confirmPlatformUse(true).then(function () {
                    purgeUser();
                    init();
                });
            };

            vm.onClassificationSuccess = function () {
                purgeUser();
                init();
            };

            vm.onGoodReputeSubmit = function () {
                return investorService.setInvestorGoodRepute().then(function () {
                    purgePreferences();
                    return init();
                });
            };

            vm.onCompleteSourceOfFunds = function () {
                purgePreferences();
                init();
            };

            vm.onClassificationFail = function () {
                purgeUser();
                init();
            };

            vm.onKeyRisksUnderstood = function () {
                return preferenceService.storeKeyRisksUnderstood().then(function () {
                    purgePreferences();
                    init();
                });
            };

            vm.finish = function () {
                vm.onSuccess();
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                init();
                ppTrack.event('required-user-data.start');
            };

        }]
    };
}]);
})(window, window.angular);