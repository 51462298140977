(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.mortgage-bond-list', [
    'pp.widgets-templates',
    'pp.widgets.property-card-list',
    'pp.widgets.expandable-content',
    'pp.widgets.auth-signup-cta',
    'pp.services.marketplace',
    'pp.services.investor',
    'pp.services.route',
    'pp.services.core',
    'pp.ui.services.risk-warning'
]);

var ISA_FILTER = 'isa';

ngModule.component('ppMortgageBondList', {
    templateUrl: 'widgets/_angular/mortgage-bond-list/mortgage-bond-list.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'marketplaceService', 'investorService', 'routeService', 'R', 'riskWarningService', function ($scope, marketplaceService, investorService, routeService, R, riskWarningService) {
        var vm = this;

        // -- initial state
        vm.isLoading = true;
        vm.loadingCardCount = 10;

        vm.expandedIntro = false;

        vm.faq1 = 'What is the value of the issuance?';
        vm.faq2 = 'What is the rate of return?';
        vm.faq3 = 'How is the bond secured?';
        vm.faq4 = 'Is the bond ISA eligible?';
        vm.faq5 = 'What are the risks of investment in Mortgage Bonds?';

        vm.mortgageBondKnowledgebase = routeService.mortgageBondKnowledgebase;
        vm.mortgageBondFaqs = routeService.mortgageBondFaqs;
        vm.isaLandingPage = routeService.isaLandingPage;
        vm.loginPath = routeService.loginPath;
        vm.bondKeyRisks = routeService.bondKeyRisks;
        vm.risksOverview = routeService.risksOverview;

        // -- util functions

        var mortgageBondFilter = R.filter(R.allPass([
            R.propEq('isMortgage', true)
        ]));

        var mortgageBondFilterComplete = R.filter(R.allPass([
            R.propEq('isMortgage', true),
            R.propEq('isPrimary', false)
        ]));

        // -- api

        vm.toggleReadMore = function () {
            vm.expandedIntro = !vm.expandedIntro;
        };

        // -- lifecycle

        // -- main

        marketplaceService.getPropertiesList({
            filter: ISA_FILTER,
            showPrimaryInformational: true
        }).then(function (properties) {
            vm.propertiesPrimary = mortgageBondFilter(properties);

            vm.isLoading = false;
        });

        investorService.getInvestor().then(function (user) {
            vm.user = user;
        });

        riskWarningService.showDevLoanRiskWarning();
    }]
});
})(window, window.angular);