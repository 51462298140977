(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.confirm-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.widgets.confirm-form'
]);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/extra-user-data`.
 */
ngModule.service('confirmDialogService', ['$rootScope', '$q', 'ppTrack', 'dialogService', function ($rootScope, $q, ppTrack, dialogService) {

    // -- initial state

    // -- util functions

    function onConfirm(defer, key) {
        return function () {
            ppTrack.action(key + '.confirm-dialog.confirmed');
            defer.resolve();
            dialogService.close();
        };
    }

    function onCancel(defer, key) {
        return function () {
            ppTrack.action(key + '.confirm-dialog.cancelled');
            defer.reject();
            dialogService.close();
        };
    }

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(key, message) {

        ppTrack.action('classification.dialog.show');

        var defer = $q.defer();
        var scope = $rootScope.$new();

        scope.onConfirm = onConfirm(defer, key);
        scope.onCancel = onCancel(defer, key);
        scope.message = message;

        var options = {
            clickClose: false,
            escapeClose: false,
            showClose: false,
            classNames: 'pp-confirm-dialog',
            scope: scope
        };

        options.contents = '<pp-confirm-form on-confirm="onConfirm()" on-cancel="onCancel()" message="message"></pp-confirm-form>';

        dialogService.show(options);
        return defer.promise;
    }

    // -- api

    var api = {};

    api.confirm = function (key, message) {
        return showDialog(key, message);
    };

    return api;
}]);
})(window, window.angular);