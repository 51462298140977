(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.button-bid', ['pp.widgets-templates', 'pp.services.core']);

ngModule.directive('ppButtonBid', ['ppTrack', function (ppTrack) {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/button-bid/button-bid.tpl.html',
        scope: {
            symbol: '<ppButtonBid'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            vm.trackButtonBidClick = function () {
                ppTrack.deferAction('property.bid.click');
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);