(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.isa-creation-receipt', [
    'pp.widgets-templates',
    'pp.services.route'
]);

ngModule.component('ppIsaCreationReceipt', {
    templateUrl: 'widgets/_angular/isa-creation-receipt/isa-creation-receipt.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', function ($scope, routeService) {
        var vm = this;

        // -- initial state

        vm.marketplaceIsaRoute = routeService.marketplaceIsaFilter;
        vm.fundIsaRoute = routeService.fundPathIsa;
        vm.transferRoute = routeService.transferFundsPath;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);