(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.expandable-figure-boxes', [
    'pp.widgets-templates',
    'pp.widgets.expandable-figure-box'
]);

ngModule.component('ppExpandableFigureBoxes', {
    templateUrl: 'widgets/_angular/expandable-figure-boxes/expandable-figure-boxes.tpl.html',
    bindings: {
        dashboardData: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            vm.dashboardData = vm.dashboardData || {};

            vm.forecastMonthlyDividends = vm.dashboardData.forecastMonthlyDividends;
            vm.currentValuation = vm.dashboardData.currentValuation;
            vm.totalReturn = vm.dashboardData.totalReturn;
        };

    }]
});
})(window, window.angular);