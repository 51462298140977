(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.referral-statistics', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.components.tooltip'
]);

ngModule.component('ppReferralStatistics', {
    templateUrl: 'widgets/_angular/referral-statistics/referral-statistics.tpl.html',
    bindings: {
        stats: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        vm.earningsTooltip = 'Including awards earned from referring customers as well as for being referred.';

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);