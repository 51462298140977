(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.refer-a-friend-share', [
    'pp.widgets-templates',
    'pp.widgets.copy-to-clipboard',
    'pp.widgets.referral-statistics',
    'pp.widgets.refer-a-friend-reward-table',
    'pp.services.route',
    'pp.services.referral',
    'pp.services.core'
]);

ngModule.config(['$compileProvider', function ($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|whatsapp):/);
}]);

var BASE_REFER_QUERYSTRING = '?r=';

ngModule.component('ppReferAFriendShare', {
    templateUrl: 'widgets/_angular/refer-a-friend-share/refer-a-friend-share.tpl.html',
    bindings: {
        investor: '<',
        isGifting: '<',
        referralStats: '<',
        onSwitchToGift: '&'

    },
    controllerAs: 'vm',
    controller: ['$window', 'routeService', 'referralService', 'ppTrack', function ($window, routeService, referralService, ppTrack) {
        var vm = this;

        // -- initial state

        vm.termsUrl = routeService.referralTermsLink;
        vm.contactUsPath = routeService.contactUsPath;

        // -- util functions

        function setReferUrl(investor) {
            var firstName = investor.firstName;
            var referUrl = referralService.getReferralUrl(investor);
            vm.referUrl = $window.encodeURI(referUrl);
            vm.shareLink = referUrl;
            vm.emailShareUrl = referralService.getEmailShareUrl(firstName, vm.referUrl, vm.isGifting);
            vm.whatsAppShareUrl = referralService.getWhatsAppShareUrl(firstName, vm.referUrl, vm.isGifting);
            vm.linkedinShareUrl = referralService.getLinkedinShareUrl(firstName, vm.referUrl, vm.isGifting);
            vm.twitterShareUrl = referralService.getTwitterShareUrl(firstName, vm.referUrl, vm.isGifting);
        }

        // -- api

        vm.switchToGift = function () {
            vm.onSwitchToGift();
        };

        vm.onCopy = function () {
            ppTrack.action('share-referral.share-link.copy');
        };

        vm.shareClick = function (medium) {
            ppTrack.action('share-referral.share.click', {
                shareMedium: medium
            });
        };

        // -- lifecycle

        vm.$onChanges = function (changes) {
            if (changes.investor && changes.investor.currentValue) {
                setReferUrl(changes.investor.currentValue);
            }
        };

        // -- main

    }]
});
})(window, window.angular);