(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.toggle-options', ['pp.components-templates']);

var BORDER_OFFSET = 2;

ngModule.directive('ppToggleOptions', function () {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/toggle-options/toggle-options.tpl.html',
        scope: {
            value: '=ppToggleOptions',
            options: '=',
            onClick: '&?'
        },
        controllerAs: 'vm',
        bindToController: true,
        link: function ($scope, $element) {
            // note: anti-pattern, do as I say, not as I do :-p
            $scope.$el = $element;
        },
        controller: ['$scope', '$window', '$timeout', function ($scope, $window, $timeout) {
            var vm = this;

            // -- initial state

            var __offsets = [];

            vm.shadowStyle = {
                display: 'none'
            };

            // -- util functions

            function getIndex(id) {
                for (var ix = 0; ix < vm.options.length; ix++) {
                    if (vm.options[ix].id === id) {
                        return ix;
                    }
                }
                return -1;
            }

            function updateOffsets() {
                if (vm.options && vm.options.length) {
                    __offsets = [];
                    $scope.$evalAsync(function () {
                        var parentLeft = $scope.$el.offset().left;
                        $scope.$el.find('button').each(function (ix, el) {
                            __offsets.push($(el).position().left - parentLeft);
                        });
                    });
                }
            }

            // -- api

            vm.click = function (id) {
                vm.value = id;
                if (vm.onClick) {
                    vm.onClick({
                        value: id
                    });
                }
            };

            // -- scope bindings
            vm.$onInit = function () {
                $scope.$watch('vm.options.length', updateOffsets);

                $scope.$watch('vm.value', function (newVal, oldVal) {
                    vm.shadowStyle = {
                        display: 'none',
                        left: -(BORDER_OFFSET) + 'px'
                    };

                    // fixes a problem with animation not happening when using pp-toggle-options within a ng-if
                    $scope.$evalAsync(function () {
                        vm.currentId = newVal;
                        // has a value?
                        if ('undefined' !== typeof newVal) {
                            vm.shadowStyle.display = 'block';
                            vm.shadowStyle.left = (-BORDER_OFFSET) + 'px';
                            // has an old value? animate
                            if ('undefined' !== typeof oldVal) {
                                var newValOffset = __offsets[getIndex(newVal)];
                                var oldValOffset = __offsets[getIndex(oldVal)];
                                // but only if already know the offsets
                                if (newValOffset && oldValOffset) {
                                    vm.shadowStyle.left = (oldValOffset - newValOffset - BORDER_OFFSET) + 'px';
                                    $timeout(function () {
                                        vm.shadowStyle.left = (-BORDER_OFFSET) + 'px';
                                    }, 1);
                                }
                            }
                        }
                    });
                });

                $window.addEventListener('resize', updateOffsets);

            };

            vm.$onDestroy = function () {
                $window.removeEventListener('resize', updateOffsets);
            };

            // -- main

        }]
    };

});
})(window, window.angular);