(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.route', [
    'pp.services.browser-store'
]);

/**
 * @ngdoc service
 * @name routeService
 *
 * @description
 * a central place to route to different pages within the app
 */
ngModule.service('routeService', ['$window', 'browserStoreService', function ($window, browserStoreService) {

    var api = {};

    var LOGO_PATH = '/images/logos/headerlogoblue.png';

    var LOGIN_PATH = '/login';
    var SIGNUP_PATH = '/signup';

    var ACCOUNT_SETTINGS_PATH = '/my-account';
    var ACCOUNT_SETTINGS_PERSONAL_DETAILS_PATH = '/my-account#/personal-details';

    var CONTACT_US_PATH = '/aboutus/contactus';
    var ABOUT_US_PATH = '/aboutus/ourstory';
    var HOW_IT_WORKS_PATH = '/howitworks';
    var DASHBOARD_PATH = '/my-portfolio#/dashboard';
    var PORTFOLIO_PENDING_PROPERTIES_PATH = '/my-portfolio#/pending';
    var PORTFOLIO_CURRENT_PROPERTIES_PATH = '/my-portfolio#/properties-current';

    var PORTFOLIO_BIDS_PATH = '/my-portfolio#/bids';
    var PORTFOLIO_SELL_PATH = '/my-portfolio#/offers';

    var PORTFOLIO_PENDING_LOANS_PATH = '/my-portfolio#/pending-loans';
    var DASHBOARD_PATH_ISA = '/my-portfolio#/dashboard-isa';
    var DASHBOARD_PATH_GENERAL = '/my-portfolio#/dashboard-general';

    var FUND_PATH = '/investor#/account/manual/fund';
    var FUND_PATH_ISA = '/investor#/account/manual/fund-isa';
    var FUND_PATH_GENERAL = '/investor#/account/manual/fund-general';

    var TRANSFER_FUNDS_PATH = '/investor#/move-funds';

    var WITHDRAW_PATH = '/my-account#/withdraw';

    var LEARN_MORE_BANK_ACCOUNT_VERIFICATION = 'https://help.londonhouseexchange.com/hc/en-us/articles/360004096693-Why-does-Property-Partner-verify-bank-accounts-';

    var INDIVIDUAL_PROPERTY_PATH = '/properties/:symbol';
    var INDIVIDUAL_PROPERTY_QUOTE_PATH = '/properties/:symbol#/quote';

    var PPX_KNOWLEDGE_BASE_LINK = 'https://help.londonhouseexchange.com/hc/en-us/articles/360008128178';

    var CLOUDFRONT_BASE = 'https://d2ofd11qqzygs0.cloudfront.net';

    var SELL_TO_US_PATH = '/supply';
    var BLOG_PATH = '/blog';

    var BLOG_EVENTS_PATH = '/blog/events/';

    var BLOG_CUSTOMER_STORY_PATH = '/blog/primary-category/customer-stories';

    var BLOG_ACQUISITION_STRATEGIES = '/blog/property_acquisition_strategy/';

    var BLOG_MEET_THE_TEAM = 'https://www.londonhouseexchange.com/property-team';

    var BLOG_ASSET_SALE = 'https://www.londonhouseexchange.com/blog/first-property-sale-43-total-return-achieved/';
    var BLOG_DIVERSIFY = 'https://www.londonhouseexchange.com/blog/five-ways-to-diversify-like-a-property-expert';

    var ISA_TERMS_LINK = 'https://s3-eu-west-1.amazonaws.com/propertypartner-app-public/files/lhe/isa-terms-and-conditions-lhe.pdf';
    var REINVEST_DIVIDENDS_TERMS_LINK = 'https://s3-eu-west-1.amazonaws.com/propertypartner-app-public/files/lhe/ReinvestDividends_TermsAndConditions-lhe.pdf';

    var REINVEST_DIVIDENDS_ABOUT = 'https://help.londonhouseexchange.com/hc/en-us/articles/360008232254-Reinvest-Dividends';

    var RETAIL_INVESTOR_BLOCKED_DEV_LOAN = 'https://help.londonhouseexchange.com/hc/en-us/articles/360008798014-FAQs-Regent-House-Basildon-SS14';
    var RETAIL_INVESTOR_BLOCKED_INVESTMENT_PLANS = 'https://help.londonhouseexchange.com/hc/en-us/articles/360007984934-Who-can-invest-using-Investment-Plans-';
    var RETAIL_INVESTOR_BLOCKED_INVESTMENT = 'https://help.londonhouseexchange.com/hc/en-us/articles/360011277333';

    var SELL_INTRO = 'https://help.londonhouseexchange.com/hc/en-us/articles/360003562074-Introduction-to-selling';
    var EXIT_MECHANIC = 'https://www.londonhouseexchange.com/blog/how-can-you-exit-your-investment/';

    var INVESTOR_TERMS_AND_CONDITIONS = 'https://d2ofd11qqzygs0.cloudfront.net/files/lhe/investor_terms_and_conditions_2022-05-01-lhe.pdf';

    var DEED_OF_AMENDMENT = 'https://propertypartner-app-public.s3.eu-west-1.amazonaws.com/files/lhe/deed-of-amendment-lhe.pdf';

    var KNOWLEDGE_BASE_LINK = 'https://help.londonhouseexchange.com';

    var AWARDS_WREATH_ICON = '/images/icons/generic/awards-wreath.svg';

    var MARKETPLACE_PATH = '/s#/featured-view';

    var MARKETPLACE_GRID_PATH = '/s#/grid-view';
    var MARKETPLACE_ISA_FILTER = '?filter=isa';

    var PROPERTY_GOLDEN_HILL_FORT = '/properties/UKPO409GD001';

    var PREMIUM_SERVICES_PATH = '/premiumservices';
    var INVESTMENT_PLANS_PATH = '/investmentplans';

    var ISA_LANDING_PAGE = '/isa';
    var MORTGAGE_BOND_PATH = '/mortgage-bond';

    var ISA_SETUP_PATH = '/investor#/isa-setup';

    var CROWDFUNDING_LP_PATH = '/property-crowdfunding';
    var WHAT_IS_CROWDFUNDING_PATH = '/what-is-property-crowdfunding';
    var CROWDFUNDING_BENEFITS_PATH = '/property-crowdfunding-benefits';
    var HOW_CROWDFUNDING_WORKS_PATH = '/how-property-crowdfunding-works';
    var PROPERTY_TEAM_PATH = '/property-team';

    var REFER_PATH = '/refer';
    var REFERRAL_TERMS_LINK = 'https://s3-eu-west-1.amazonaws.com/propertypartner-blog/wp-content/uploads/2018/09/21114945/refer-a-friend_2018-093.pdf';
    var OLD_WELCOME_BONUS_TERMS = 'https://d2ofd11qqzygs0.cloudfront.net/files/terms-and-conditions-30-day-bonus.pdf';
    var WELCOME_BONUS_TERMS = 'https://s3-eu-west-1.amazonaws.com/propertypartner-blog/wp-content/uploads/2019/02/21170751/New-Welcome-Bonus-Offer-TCs-19022019-.pdf';

    var DEPOSIT_WITHDRAWAL_HELP = 'https://help.londonhouseexchange.com/hc/en-us/sections/115001018634-Deposits-and-Withdrawals';

    var INVESTMENT_PLAN_TERMS = 'https://s3-eu-west-1.amazonaws.com/propertypartner-app-public/files/investor_terms_and_conditions_investmentplans_lhe.pdf';
    var INVESTMENT_PLANS_UNDEPLOYED_FUNDS_TERMS = 'https://s3-eu-west-1.amazonaws.com/propertypartner-app-public/files/investment_plan_terms_3pct-march_2019.pdf';

    var MARKET_INDEX_REPORT = 'https://opt.londonhouseexchange.com/Property-Partner-UK-Residential-Property-Market-Trends_June18.pdf';

    var KEY_RISKS_URL = 'https://help.londonhouseexchange.com/hc/en-us/articles/360004054894-Key-Risks';
    var RISKS_OVERVIEW = 'https://help.londonhouseexchange.com/hc/en-us/articles/8000154867357-Risks-overview';
    var BOND_KEY_RISKS = 'https://help.londonhouseexchange.com/hc/en-us/articles/10069946574365-Bond-specific-key-risks';

    var LOGOUT_PATH = '/logout';

    var FUND_REDIRECT_STORAGE_KEY = 'POST_FUND_DESTINATION';

    var AFFILIATE_CONTACT_FORM_URL = 'https://goo.gl/forms/dijdlDmQrVt6Cedh1';

    var ISA_FAQS = 'https://help.londonhouseexchange.com/hc/en-us/sections/360003108874-Innovative-Finance-ISA-IFISA-';
    var ISA_TRANSFER_HELP = 'https://help.londonhouseexchange.com/hc/en-us/articles/360016367254-Transferring-an-existing-ISA';
    var INVESTOR_CLASSIFICATION_HELP = 'https://help.londonhouseexchange.com/hc/en-us/articles/360016367254-Transferring-an-existing-ISA';
    var ISA_FEES_CHARGES_HELP = 'https://help.londonhouseexchange.com/hc/en-us/articles/360016491393-Fees-and-Charges';
    var FEES_CHARGES_HELP = 'https://help.londonhouseexchange.com/hc/en-us/articles/360001993793-What-are-the-fees-paid-by-investors-';

    var APP_DOWNLOAD = 'https://itunes.apple.com/gb/app/property-partner/id1382563575';

    var DOWNLOAD_DASHBOARD_PATH = '/feapi/r1/investor/dashboard.csv';

    var CLOSED_PERIOD_KNOWLEDGEBASE = 'https://help.londonhouseexchange.com/hc/en-us/articles/360013308073-Resale-Market-close-periods';
    var CLOSED_PERIOD_ANNOUNCEMENTS = 'https://www.londonhouseexchange.com/blog/q4-2023-performance-announcement';

    var INCOME_TAX_WITHHELD_HELP = 'https://help.londonhouseexchange.com/hc/en-us/articles/360002563417';

    var EXIT_MECHANIC_HELP = BLOG_PATH + '/5-year-exit-mechanic/';

    var EQUITY_FUNDRAISE_LINK = '/s#/equity-fundraise';

    var RIGHTS_ISSUE_KNOWLEDGE_BASE_LINK = 'https://help.londonhouseexchange.com/hc/en-us/articles/7460818589341';
    var RIGHTS_ISSUE_ADDITIONAL_INFO_LINK = 'https://s3-eu-west-1.amazonaws.com/propertypartner-blog/wp-content/uploads/2022/11/15164320/Equity-Fundraises-additional-information.pdf';

    var VPV_IV_EXPLAINER = 'https://help.londonhouseexchange.com/hc/en-us/articles/7963276113821';

    var INVESTMENT_SAFEGUARD = 'https://help.londonhouseexchange.com/hc/en-us/articles/360004904938-Investment-safeguards-';

    var MORTGAGE_BOND_KNOWLEDGEBASE = 'https://help.londonhouseexchange.com/hc/en-us/articles/10069894606365';
    var MORTGAGE_BOND_FAQS = 'https://help.londonhouseexchange.com/hc/en-us/articles/10201336843549-LHX-Mortgage-Bond-FAQs';
    var MORTGAGE_BOND_TERMS = 'https://propertypartner-app-public.s3.eu-west-1.amazonaws.com/files/LHX-Mortgage-Bond-Terms-and-Conditions-03_04_2023.pdf';

    var FIVE_YEAR_TRADING_HELP = 'https://help.londonhouseexchange.com/hc/en-us/articles/11292770590620-Trading-on-5-year-anniversary-properties';
    var IM_HOME_NOW = 'https://s3-eu-west-1.amazonaws.com/propertypartner-app-public/files/im-home-now.pdf';
    var KID_HOME_NOW = 'https://s3-eu-west-1.amazonaws.com/propertypartner-app-public/files/kid-home-now.pdf';

    var PROPERTY_KEY_RISKS_UI_PATH = '#/key-risks';

    function isAllowedPostFundPath(redirect) {
        var allowedPaths = {
            '/investmentplans/#/balanced/commit': true,
            '/investmentplans#/balanced/commit': true,
            '/investmentplans/#/growth/commit': true,
            '/investmentplans#/growth/commit': true,
            '/investmentplans/#/income/commit': true,
            '/investmentplans#/income/commit': true
        };

        return allowedPaths[redirect];
    }

    api.goToAccountSettings = function () {
        $window.pathname = ACCOUNT_SETTINGS_PATH;
    };

    api.setPostFundRedirect = function (location) {
        if (location) {
            browserStoreService.setSessionStorageItem(FUND_REDIRECT_STORAGE_KEY, location);
        }
    };

    api.popPostFundRedirect = function () {
        var redirect = browserStoreService.getSessionStorageItem(FUND_REDIRECT_STORAGE_KEY);
        browserStoreService.deleteSessionStorageItem(FUND_REDIRECT_STORAGE_KEY);
        return isAllowedPostFundPath(redirect) ? redirect : null;
    };

    api.getIndividualPropertyPath = function (symbol) {
        return INDIVIDUAL_PROPERTY_PATH.replace(':symbol', symbol);
    };

    api.loginPath = LOGIN_PATH;
    api.signupPath = SIGNUP_PATH;

    var location = $window.location || {};
    api.origin = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

    api.logoPath = LOGO_PATH;
    api.contactUsPath = CONTACT_US_PATH;
    api.aboutusPath = ABOUT_US_PATH;
    api.howItWorksPath = HOW_IT_WORKS_PATH;

    api.investmentPlansPath = INVESTMENT_PLANS_PATH;
    api.premiumServicesPath = PREMIUM_SERVICES_PATH;
    api.isaLandingPage = ISA_LANDING_PAGE;

    api.mortgageBondPath = MORTGAGE_BOND_PATH;

    api.crowdfundingLpPath = CROWDFUNDING_LP_PATH;
    api.whatIsCrowdfundingPath = WHAT_IS_CROWDFUNDING_PATH;
    api.crowdfundingBenefitsPath = CROWDFUNDING_BENEFITS_PATH;
    api.howCrowdfundingWorksPath = HOW_CROWDFUNDING_WORKS_PATH;
    api.propertyTeamPath = PROPERTY_TEAM_PATH;

    api.marketplacePath = MARKETPLACE_PATH;

    api.individualPropertyPath = INDIVIDUAL_PROPERTY_PATH;
    api.individualPropertyQuotePath = INDIVIDUAL_PROPERTY_QUOTE_PATH;

    api.cloudfrontBase = CLOUDFRONT_BASE;

    api.marketplaceGridPath = MARKETPLACE_GRID_PATH;
    api.marketplaceIsaFilter = MARKETPLACE_GRID_PATH + MARKETPLACE_ISA_FILTER;

    api.propertyGoldenHillFort = PROPERTY_GOLDEN_HILL_FORT;

    api.dashboardPath = DASHBOARD_PATH;
    api.dashboardPathIsa = DASHBOARD_PATH_ISA;
    api.dashboardPathGeneral = DASHBOARD_PATH_GENERAL;
    api.downloadDashboardPath = DOWNLOAD_DASHBOARD_PATH;

    api.portfolioPendingPropertiesPath = PORTFOLIO_PENDING_PROPERTIES_PATH;
    api.portfolioCurrentPropertiesPath = PORTFOLIO_CURRENT_PROPERTIES_PATH;
    api.portfolioPendingLoansPath = PORTFOLIO_PENDING_LOANS_PATH;

    api.portfolioBidsPath = PORTFOLIO_BIDS_PATH;
    api.portfolioSellPath = PORTFOLIO_SELL_PATH;

    api.fundPath = FUND_PATH;
    api.fundPathGeneral = FUND_PATH_GENERAL;
    api.fundPathIsa = FUND_PATH_ISA;

    api.isaSetupPath = ISA_SETUP_PATH;
    api.isaFaqs = ISA_FAQS;

    api.isaTransferHelp = ISA_TRANSFER_HELP;
    api.investorClassificationHelp = INVESTOR_CLASSIFICATION_HELP;
    api.isaFeesChargesHelp = ISA_FEES_CHARGES_HELP;
    api.feesChargesHelp = FEES_CHARGES_HELP;

    api.depositsWithdrawalHelp = DEPOSIT_WITHDRAWAL_HELP;
    api.investorTermsAndConditions = INVESTOR_TERMS_AND_CONDITIONS;

    api.deedOfAmendment = DEED_OF_AMENDMENT;
    api.isaTermsLink = ISA_TERMS_LINK;
    api.reinvestDividendsTermsLink = REINVEST_DIVIDENDS_TERMS_LINK;

    api.reinvestDividendsAbout = REINVEST_DIVIDENDS_ABOUT;

    api.transferFundsPath = TRANSFER_FUNDS_PATH;

    api.withdrawPath = WITHDRAW_PATH;

    api.learnMoreBankAccountVerification = LEARN_MORE_BANK_ACCOUNT_VERIFICATION;

    api.accoutSettingsPath = ACCOUNT_SETTINGS_PATH;
    api.accountSettingsPersonalDetailsPath = ACCOUNT_SETTINGS_PERSONAL_DETAILS_PATH;
    api.sellToUsPath = SELL_TO_US_PATH;

    api.blogPath = BLOG_PATH;
    api.blogEventsPath = BLOG_EVENTS_PATH;
    api.blogCustomerStoryPath = BLOG_CUSTOMER_STORY_PATH;
    api.knowledgeBaseLink = KNOWLEDGE_BASE_LINK;
    api.blogMeetTheTeam = BLOG_MEET_THE_TEAM;
    api.blogAssetSale = BLOG_ASSET_SALE;
    api.blogDiversify = BLOG_DIVERSIFY;
    api.blogAcquisitionStrategies = BLOG_ACQUISITION_STRATEGIES;

    api.awardsWreathIcon = AWARDS_WREATH_ICON;

    api.sellIntro = SELL_INTRO;
    api.exitMechanic = EXIT_MECHANIC;

    api.referPath = REFER_PATH;
    api.referralTermsLink = REFERRAL_TERMS_LINK;

    api.welcomeBonusTerms = WELCOME_BONUS_TERMS;
    api.oldWelcomeBonusTerms = OLD_WELCOME_BONUS_TERMS;

    api.investmentPlansTerms = INVESTMENT_PLAN_TERMS;
    api.investmentPlansUndeployedFundsTerms = INVESTMENT_PLANS_UNDEPLOYED_FUNDS_TERMS;

    api.retailInvestorBlockedDevLoan = RETAIL_INVESTOR_BLOCKED_DEV_LOAN;
    api.retailInvestorBlockedInvestmentPlans = RETAIL_INVESTOR_BLOCKED_INVESTMENT_PLANS;
    api.retailInvestorBlockedInvestment = RETAIL_INVESTOR_BLOCKED_INVESTMENT;

    api.logoutPath = LOGOUT_PATH;

    api.keyRisksUrl = KEY_RISKS_URL;
    api.risksOverview = RISKS_OVERVIEW;
    api.bondKeyRisks = BOND_KEY_RISKS;

    api.affiliateContactFormUrl = AFFILIATE_CONTACT_FORM_URL;

    api.appDownload = APP_DOWNLOAD;

    api.marketIndexReport = MARKET_INDEX_REPORT;

    api.closedPeriodKnowledgebase = CLOSED_PERIOD_KNOWLEDGEBASE;
    api.closedPeriodAnnouncements = CLOSED_PERIOD_ANNOUNCEMENTS;

    api.incomeTaxWithheldHelp = INCOME_TAX_WITHHELD_HELP;

    api.exitMechanicHelp = EXIT_MECHANIC_HELP;

    api.ppxKnowledgeBaseLink = PPX_KNOWLEDGE_BASE_LINK;

    api.equityFundraiseLink = EQUITY_FUNDRAISE_LINK;

    api.rightsIssueKnowledgebaseLink = RIGHTS_ISSUE_KNOWLEDGE_BASE_LINK;
    api.rightsIssueAdditionalInfoLink = RIGHTS_ISSUE_ADDITIONAL_INFO_LINK;

    api.vpvIvExplainer = VPV_IV_EXPLAINER;

    api.investmentSafeguard = INVESTMENT_SAFEGUARD;

    api.mortgageBondKnowledgebase = MORTGAGE_BOND_KNOWLEDGEBASE;
    api.mortgageBondFaqs = MORTGAGE_BOND_FAQS;
    api.mortgageBondTerms = MORTGAGE_BOND_TERMS;
    api.fiveYearTradingHelp = FIVE_YEAR_TRADING_HELP;
    api.imHomeNow = IM_HOME_NOW;
    api.kidHomeNow = KID_HOME_NOW;

    api.propertyKeyRisksUIPath = PROPERTY_KEY_RISKS_UI_PATH;

    return api;
}]);
})(window, window.angular);