(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.tabs', [
    'pp.components-templates',
    'ui.router'
]);

ngModule.controller('ppTabsCtrl', ['$state', function ($state) {
    var vm = this;

    // -- util functions

    function getActiveBarWidth() {
        return (100 / vm.tabs.length) + '%';
    }

    function getActiveIndex() {
        var active = vm.active || $state.current.name;
        for (var ix = 0; ix < vm.tabs.length; ix++) {
            if (vm.tabs[ix].key === active) {
                return ix;
            }
        }
        return 0;
    }

    function getActiveBarLeftPosition() {
        return ((100 / vm.tabs.length) * (getActiveIndex())) + '%';
    }

    // --  api

    vm.getActiveBarCss = function () {
        return {
            'width': getActiveBarWidth(),
            'margin-left': getActiveBarLeftPosition()
        };
    };

    vm.switchTab = function (tab) {
        vm.active = tab;
        if (vm.onChange) {
            vm.onChange({
                tab: tab
            });
        }
    };

    // -- main
}]);

ngModule.directive('ppTabs', [function () {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/tabs/tabs.tpl.html',
        scope: {
            tabs: '<ppTabs',
            active: '=?',
            onChange: '&?',
            justified: '@',
            panelTabs: '@',
            underlined: '@?'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: 'ppTabsCtrl'
    };

}]);

ngModule.component('ppTabsLinks', {
    templateUrl: 'components/_angular/tabs/tabs-links.tpl.html',
    bindings: {
        tabs: '<',
        onChange: '&?',
        justified: '@',
        panelTabs: '@',
        underlined: '@?'
    },
    controllerAs: 'vm',
    controller: 'ppTabsCtrl'
});
})(window, window.angular);