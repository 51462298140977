(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.input-range', ['pp.components-templates']);

ngModule.component('ppInputRange', {
    templateUrl: 'components/_angular/input-range/input-range.tpl.html',
    bindings: {
        ppValue: '=',
        ppName: '<',
        min: '<',
        max: '<',
        step: '<',
        isDisabled: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);