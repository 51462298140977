(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.mfa-settings-dialog', ['pp.widgets-templates', 'pp.services.dialog', 'pp.services.core', 'ng.cork.ui.focus-on', 'pp.components.bind-invalid-model', 'pp.components.focus-first-error', 'pp.widgets.mfa-remove-dialog', 'pp.services.mfa', 'monospaced.qrcode']);

/**
 * @ndgoc service
 * @description
 * Displays a modal to confirm cancelling a bid and invokes the service to cancel the bid.
 */
ngModule.service('mfaSettingsDialogService', ['$rootScope', 'dialogService', function ($rootScope, dialogService) {

    // -- util functions

    // create a scope, show the dialog
    // @tofdo change name
    function showDialogSetup(type, email, isMobile, secret) {
        //ppTrack.action('bid-cancel.dialog.show');
        var scope = $rootScope.$new();
        var options = {
            clickClose: true,
            escapeClose: true,
            showClose: true,
            scope: scope,
            classNames: 'mfa'
        };

        if (type === 'setup') {
            scope.email = email;
            scope.isMobile = isMobile;
            scope.secret = secret;

            options.contents = '<div pp-mfa-setup-dialog email="email" is-mobile="isMobile" secret="secret"></div>';
        }

        if (type === 'remove') {

            options.contents = '<div pp-mfa-remove-dialog></div>';
        }

        return dialogService.show(options).then(function (response) {
            return response;
        });
    }

    // -- api

    var api = {};

    api.showSetup = function (email, isMobile, secret) {
        return showDialogSetup('setup', email, isMobile, secret);
    };

    api.showRemove = function () {
        return showDialogSetup('remove');
    };

    return api;
}]);

ngModule.directive('ppMfaSetupDialog', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/mfa-settings-dialog/mfa-setup-dialog.tpl.html',
        scope: {
            email: '<',
            isMobile: '<',
            secret: '<'

        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'mfaService', 'ppConfig', 'ppTrack', '$timeout', function ($scope, mfaService, ppConfig, ppTrack, $timeout) {
            var vm = this;

            // -- initial state
            var __endpoints = ppConfig.get('pp.external.endpoints') || {};
            var assetsEndpoint = __endpoints.assets;

            vm.imagePrefix = __endpoints.assets + '/images/mfa/';

            var numberByStep = {
                'start': 1,
                'mfa-key': 2,
                'qr-code': 2,
                'enter-mfa': 3,
                'finish': 4
            };

            // -- util
            function setStep(step) {
                vm.step = step;
                vm.stepNumber = numberByStep[step];
                ppTrack.event('pp-mfa.setup-step.' + vm.step);
            }

            // -- api
            vm.cancel = function () {
                $scope.$parent.dialogDefer.resolve(false);
            };

            vm.next = function () {
                if (vm.isMobile) {
                    setStep('mfa-key');

                } else {
                    setStep('qr-code');
                }
            };

            vm.back = function () {
                vm.feedbackEnabled = false;
                switch (vm.step) {
                case ('qr-code'):
                    setStep('start');
                    break;
                case ('mfa-key'):
                    if (vm.isMobile) {
                        setStep('start');
                    } else {
                        setStep('qr-code');
                    }
                    break;
                case ('enter-mfa'):
                    if (vm.isMobile) {
                        setStep('mfa-key');
                    } else {
                        setStep('qr-code');
                    }
                    break;
                }
            };

            vm.authenticateAccount = function () {

                //call endpoint to set up mfa

                setStep('enter-mfa');
                $timeout(function () {
                    $scope.$broadcast('pp.enter-mfa.setFocus');
                });
            };

            vm.showMfaKey = function () {

                //call endpoint to set up mfa
                setStep('mfa-key');

            };

            vm.confirm = function () {
                vm.feedbackEnabled = true;
                if (vm.form.$valid) {
                    vm.processing = true;
                    mfaService.activateMfa(vm.mfaCode).then(function () {
                        vm.processing = false;
                        setStep('finish');
                    }, function (error) {
                        vm.feedbackEnabled = true;
                        vm.error = error.reason;
                        vm.processing = false;
                    });
                }

            };

            vm.finish = function () {
                $scope.$parent.dialogDefer.resolve(true);
            };

            // -- main

            vm.$onInit = function () {
                vm.step = 'start';
                vm.patternMfa = '[0-9]*$';
                vm.stepNumber = 1;
                vm.secretUrl = 'otpauth://totp/PropertyPartner:' + vm.email + '?secret=' + vm.secret + '&issuer=PropertyPartner';
                vm.focus = 'auto';
            };

        }]
    };
}]);
})(window, window.angular);