(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-plan-receipt', [
    'pp.widgets-templates',
    'pp.services.route',
    'pp.values.investment-plans',
    'pp.filters.numbers'
]);

ngModule.component('ppInvestmentPlanReceipt', {
    templateUrl: 'widgets/_angular/investment-plan-receipt/investment-plan-receipt.tpl.html',
    bindings: {
        receipt: '<'
    },
    controllerAs: 'vm',
    controller: ['routeService', 'investmentPlans', function (routeService, investmentPlans) {
        var vm = this;

        // -- initial state

        vm.blogPath = routeService.blogPath;
        vm.referPath = routeService.referPath;

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$onInit = function () {
            if (vm.receipt) {
                vm.plan = investmentPlans[vm.receipt.plan];
            }
        };

        // -- main

    }]
});
})(window, window.angular);