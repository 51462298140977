(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-current-loans', [
    'pp.widgets-templates',
    'pp.services.dashboard',
    'pp.widgets.dashboard-current-loans-table'
]);

ngModule.component('ppDashboardCurrentLoans', {
    templateUrl: 'widgets/_angular/dashboard-current-loans/dashboard-current-loans.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'dashboardService', function ($scope, dashboardService) {
        var vm = this;

        // -- initial state

        // -- util functions

        function init() {
            return dashboardService.getCurrentLoans().then(function (data) {
                vm.data = data;
                vm.loadError = false;
            }, function () {
                vm.loadError = true;
            });
        }

        // -- api

        // -- scope bindings

        // -- main

        init().then(function () {
            vm.ready = true;
        });

    }]
});
})(window, window.angular);