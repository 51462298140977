(function(window, angular){'use strict';

var ngModule = angular.module('pp.values.leadership-team', []);

var team = [{
    name: 'Warren Bath',
    position: 'CEO',
    workExperience: ['M&A Director at Sky Plc', 'Investment Banking Advisory at Morgan Stanley', 'Chartered Accountant'],
    image: 'bath',
    linkedIn: 'https://uk.linkedin.com/in/warren-bath-3a228811',
    bio: 'Warren is a Chartered Accountant with over 20 years of experience within financial services and investment. Before joining Property Partner, he spent seven years in mergers and acquisitions at Sky Plc, most recently as M&A Director, leading a significant number of acquisitions, disposals and strategic reviews. He also spent a year as Acting Director of Treasury, managing Sky’s capital structure, liquidity, bank and bond financing, etc. Prior to this he spent several years at Morgan Stanley as Vice President, Investment Banking in the Media and Communications group for Europe, Middle East & Africa. Warren’s other previous roles include, Corporate Finance Manager for Virgin Mobile (London), Corporate Development Senior Analyst for Vodafone (Sydney), and Audit & Business Advisory Senior for Arthur Andersen (Perth), where he qualified as a Chartered Accountant.'
}];

ngModule.value('team', team);
})(window, window.angular);