(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.sell-receipt', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.services.dialog',
    'pp.services.route'
]);

/**
 * @ngdoc directive
 * @name ppSellReceipt
 * @description
 * Renders the sell receipt dialog
 *
 * @restrict A
 * @scope
 * @param {obj} receipt
 */

ngModule.directive('ppSellReceipt', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/sell-receipt/sell-receipt.tpl.html',
        scope: {
            receipt: '<',
            onContinue: '&?'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$attrs', 'dialogService', 'routeService', function ($scope, $attrs, dialogService, routeService) {
            var vm = this;

            // -- initial state

            // -- api

            vm.continue = function () {
                if ($attrs.onContinue) {
                    vm.onContinue();
                } else {
                    dialogService.resolveParent($scope);
                }
            };

            // -- main

        }]
    };
});
})(window, window.angular);