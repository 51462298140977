(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.kyc-document-verification-form', [
    'pp.widgets-templates',
    'pp.services.core',
]);

ngModule.component('ppKycDocumentVerificationForm', {
    templateUrl: 'widgets/_angular/kyc-document-verification-form/kyc-document-verification-form.tpl.html',
    bindings: {
        onDocumentSend: '&',
        kycStatusDetails: '<'
    },
    controllerAs: 'vm',
    controller: ['$q', 'ppTrack', function ($q, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions

        function byteArrayToBase64(arrayBuffer) {
            return btoa(
                new Uint8Array(arrayBuffer)
                .reduce(function (data, byte) {
                    return data + String.fromCharCode(byte);
                }, '')
            );
        }

        // -- api

        vm.addFiles = function () {

            vm.feedbackEnabled = true;

            if (vm.form.$invalid) {
                return; // note early return
            }

            var fileOneElement = document.getElementById('kyc-id-file');
            var fileTwoElement = document.getElementById('kyc-address-file');

            var fileOne = fileOneElement ? document.getElementById('kyc-id-file').files[0] : null;
            var fileTwo = fileTwoElement ? document.getElementById('kyc-address-file').files[0] : null;

            var readerOne = new FileReader();
            var readerTwo = new FileReader();

            var fileOneName;
            var fileTwoName;

            var loadEndOne = $q.defer();
            var loadEndTwo = $q.defer();

            readerOne.onloadend = function (ev) {
                var data = ev.target.result;
                loadEndOne.resolve({
                    data: byteArrayToBase64(data),
                    fileName: fileOne.name
                });
            };

            readerTwo.onloadend = function (ev) {
                var data = ev.target.result;
                loadEndTwo.resolve({
                    data: byteArrayToBase64(data),
                    fileName: fileTwo.name
                });
            };

            if (fileOne) {
                readerOne.readAsArrayBuffer(fileOne);
            } else {
                loadEndOne.resolve();
            }

            if (fileTwo) {
                readerTwo.readAsArrayBuffer(fileTwo);
            } else {
                loadEndTwo.resolve();
            }

            vm.processing = true;
            vm.error = null;

            $q.all([
                loadEndOne.promise,
                loadEndTwo.promise
            ]).then(function (res) {
                return vm.onDocumentSend({
                    data: res.filter(function (item) {
                        return !!item;
                    })
                });
            }).then(function () {}, function (err) {
                vm.processing = false;
                vm.error = err.reason;
            });
        };

        // -- scope bindings

        // -- main

    }]
});

ngModule.directive('validFile', function () {
    return {
        require: 'ngModel',
        link: function (scope, el, attrs, ctrl) {
            ctrl.$setValidity('validFile', el.val() !== '');
            //change event is fired when file is selected
            el.bind('change', function () {
                ctrl.$setValidity('validFile', el.val() !== '');
                scope.$apply(function () {
                    ctrl.$setViewValue(el.val());
                    ctrl.$render();
                });
            });
        }
    };
});
})(window, window.angular);