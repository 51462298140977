(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.confirm-form', ['pp.widgets-templates']);

ngModule.component('ppConfirmForm', {
    templateUrl: 'widgets/_angular/confirm-form/confirm-form.tpl.html',
    bindings: {
        message: '<',
        onConfirm: '&',
        onCancel: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);