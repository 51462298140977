(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-sold', [
    'pp.widgets-templates',
    'ui.router',
    'pp.widgets.dashboard-sold-table',
    'pp.services.investor',
    'pp.services.core'
]);

ngModule.component('ppDashboardSold', {
    templateUrl: 'widgets/_angular/dashboard-sold/dashboard-sold.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$stateParams', '$transitions', 'dashboardService', 'investorService', 'ppUtil', function ($scope, $stateParams, $transitions, dashboardService, investorService, ppUtil) {
        var vm = this;

        // -- initial state

        vm.sortAttribute = 'totalReturnPct';
        vm.sortOrder = 'descending';

        // -- util functions

        function init() {
            return dashboardService.getSoldInvestments().then(function (data) {
                vm.data = data;
                vm.openSidebarSymbol = $stateParams.propertySymbol;
                vm.loadError = false;
            }, function () {
                vm.loadError = true;
            });
        }

        function updateBids(bids) {
            vm.data.bids = ppUtil.indexListBy(bids, 'symbol');
        }

        function updateOffers(offers) {
            vm.data.offers = ppUtil.indexListBy(offers, 'propertySymbol');
        }

        // -- api

        vm.onChange = function () {
            return init();
        };

        // -- lifecycle

        vm.$onDestroy = function () {
            investorService.off('investor.holdings.bid.updated', updateBids);
            investorService.off('investor.holdings.listed.updated', updateOffers);
        };

        $transitions.onSuccess({}, function (transition) {
            vm.openSidebarSymbol = $stateParams.propertySymbol;
        });

        // -- main

        init().then(function () {
            vm.ready = true;
            investorService.on('investor.holdings.bid.updated', updateBids);
            investorService.on('investor.holdings.listed.updated', updateOffers);
        });

    }]
});
})(window, window.angular);