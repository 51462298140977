(function(window, angular){'use strict';

var ngModule = angular.module('pp.controllers.account-router', [
    'ui.router',
    'pp.services.auth',
    'pp.widgets.account',
    'pp.widgets.withdrawal',
    'pp.widgets.marketing-preferences'
]);

ngModule.config(['$stateProvider', '$urlRouterProvider', '$windowProvider', function ($stateProvider, $urlRouterProvider, $windowProvider) {

    var $window = $windowProvider.$get();
    var pathname = $window.location.pathname;

    // @todo remove this logic by splitting up app into smaller submodules and move to frontend routing
    // This is so the redirect does not happen on all other routes within the app
    if (pathname.match(/^\/my-account/)) {

        $stateProvider.decorator('data', function (state, parent) {
            var stateData = parent(state);
            state.resolve = state.resolve || {};

            state.resolve.security = ['$q', '$window', 'authService', function ($q, $window, authService) {
                return authService.getSessionTimeLeft()
                    .catch(function () {
                        $window.location.reload();
                        return $q.reject();
                    });
            }];
            return stateData;
        });

        $stateProvider
            .state('account', {
                abstract: true
            })
            .state('account.withdraw', {
                url: '/withdraw',
                component: 'ppWithdrawal'
            })
            .state('account.settings', {
                abstract: true,
                component: 'ppAccount'
            })
            .state('account.settings.personal', {
                abstract: true,
                url: '/personal-details',
                templateUrl: 'controllers/settings/sections/personal-details.tpl.html'
            })
            .state('account.settings.personal.investor', {
                url: '/settings',
                templateUrl: 'controllers/settings/sections/personal-details-settings.tpl.html'
            })
            .state('account.settings.personal.address', {
                url: '/address',
                templateUrl: 'controllers/settings/sections/personal-details-address.tpl.html'
            })
            .state('account.settings.security', {
                abstract: true,
                url: '/security',
                templateUrl: 'controllers/settings/sections/security.tpl.html'
            })
            .state('account.settings.preferences', {
                url: '/preferences',
                component: 'ppMarketingPreferences'
            })
            .state('account.settings.security.ga', {
                url: '/ga',
                templateUrl: 'controllers/settings/sections/security-ga.tpl.html'
            })
            .state('account.settings.security.reset-password', {
                url: '/reset-password',
                templateUrl: 'controllers/settings/sections/security-reset-password.tpl.html'
            })
            .state('account.settings.security.reset-pin', {
                url: '/reset-pin',
                templateUrl: 'controllers/settings/sections/security-reset-pin.tpl.html'
            });

        $urlRouterProvider.otherwise('/personal-details/settings');
    }

}]);
})(window, window.angular);