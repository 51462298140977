(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.more-actions-dropdown', ['pp.widgets-templates']);

var DROPDOWN_BOTTOM_OFFSET_PX = 30;
var DROPDOWN_ICON_OFFSET_PX = 24;

ngModule.component('ppMoreActionsDropdown', {
    templateUrl: 'widgets/_angular/more-actions-dropdown/more-actions-dropdown.tpl.html',
    bindings: {
        isOpen: '=?'
    },
    controllerAs: 'vm',
    transclude: true,
    controller: ['$scope', '$element', '$document', '$timeout', function ($scope, $element, $document, $timeout) {
        var vm = this;

        // -- initial state

        var bodyElement = $document.find('body');
        var dropdownElement = $element.find('.dropdown-content');

        // -- util functions

        var onClickBody = function () {
            vm.isOpen = false;
            bodyElement.unbind('click', onClickBody);
            $scope.$apply();
        };

        var setDisplayDirection = function () {
            //reset css to initial values in order to make calculations from the original position
            dropdownElement.css({
                top: DROPDOWN_ICON_OFFSET_PX
            });

            var dropdownHeight = dropdownElement.outerHeight();
            var dropdownTopOffset = dropdownElement.offset().top;
            var triggerOffsetTop = dropdownTopOffset - $(window).scrollTop();
            var windowHeight = $(window).height();
            var bottomRemainingHeight = windowHeight - triggerOffsetTop - dropdownHeight - DROPDOWN_BOTTOM_OFFSET_PX;

            if (bottomRemainingHeight < 0) {
                dropdownElement.css({
                    top: -(dropdownHeight + (DROPDOWN_ICON_OFFSET_PX / 2))
                });
            }
        };

        // -- api

        vm.toggleVisibility = function () {
            vm.isOpen = !vm.isOpen;

            if (vm.isOpen) {
                // timeout because body binds event before click bubbles all the way up
                // otherwise will fire click event on initial toggleVisibility click
                $timeout(function () {
                    bodyElement.click(onClickBody);
                });

                //set direction for dropdown (up or down)
                setDisplayDirection();
            } else {
                bodyElement.unbind('click', onClickBody);
            }
        };

        vm.clickActions = function (event) {
            event.stopPropagation();
        };

        // -- scope bindings

        $scope.$on('dropdown-open', function () {
            vm.toggleVisibility();
        });

        vm.$onDestroy = function () {
            bodyElement.unbind('click', onClickBody);
        };

    }]
});
})(window, window.angular);