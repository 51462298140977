(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.log', []);

var track = window.pp.track;

var API_BASE_PATH = '/feapi/r1';

/**
 * @ngdoc service
 * @name logService
 *
 * @description
 * Provides methods to invoke the logging service.
 */
ngModule.service('logService', ['$http', function ($http) {

    var api = {};

    function normalizedLog(level, messageOrData) {
        var endpoint = API_BASE_PATH + '/error/generic';

        if (!angular.isString(messageOrData)) {
            messageOrData = JSON.stringify(messageOrData);
        }

        var message = messageOrData + ' CONTEXT (' + JSON.stringify(track.getContext()) + ')';

        var payload = {
            level: level,
            message: message
        };

        $http.post(endpoint, payload).then(angular.noop, function () {
            track.pageException('log', level.toLowerCase(), message);
        });
    }

    /**
     * @ngdoc method
     * @name logService#exception
     *
     * @description
     * Given a string message, will POST a message with level 'ERROR'.
     * Failure to POST will generate an attempt to track.
     *
     * @param {string} messageOrData
     */
    api.exception = function (messageOrData) {
        normalizedLog('ERROR', messageOrData);
    };

    /**
     * @ngdoc method
     * @name logService#error
     *
     * @description
     * Given a string message, will POST a message with level 'WARN'.
     * Failure to POST will generate an attempt to track.
     *
     * @param {string} messageOrData
     */
    api.error = function (messageOrData) {
        normalizedLog('WARN', messageOrData);
    };

    /**
     * @ngdoc method
     * @name logService#info
     *
     * @description
     * Given a string message, will POST a message with level 'INFO'.
     * Failure to POST will generate an attempt to track.
     *
     * @param {string} messageOrData
     */
    api.info = function (messageOrData) {
        normalizedLog('INFO', messageOrData);
    };

    /**
     * @ngdoc method
     * @name logService#debug
     *
     * @description
     * Given a string message, will POST a message with level 'DEBUG'.
     * Failure to POST will generate an attempt to track.
     *
     * @param {string} messageOrData
     */
    api.debug = function (messageOrData) {
        normalizedLog('DEBUG', messageOrData);
    };

    return api;
}]);
})(window, window.angular);