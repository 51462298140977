(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.performance-warning', ['pp.widgets-templates']);

ngModule.component('ppPerformanceWarning', {
    templateUrl: 'widgets/_angular/performance-warning/performance-warning.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);