(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.about-overview', [
    'pp.widgets-templates',
    'pp.services.route'
]);

ngModule.component('ppAboutOverview', {
    templateUrl: 'widgets/_angular/about-overview/about-overview.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$element', 'routeService', function ($scope, $element, routeService) {
        var vm = this;

        // -- initial state

        vm.howItWorksPath = routeService.howItWorksPath;

        var __carouselOptions = {
            items: 1,
            slideSpeed: 1000,
            navigation: true,
            nav: false,
            dots: true
        };

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$postLink = function () {
            vm.carousel = $($element).find('.owl-carousel').owlCarousel(__carouselOptions);
        };

        // -- main

    }]
});
})(window, window.angular);