(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.theme-list', ['pp.widgets-templates']);

/**
 * @ngdoc directive
 * @name ppThemeList
 * @description
 * Renders a list of theme (icons)
 *
 * @restrict A
 * @scope
 * @param {array} ppThemeList of theme strings
 */
ngModule.directive('ppThemeList', [function () {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/theme-list/theme-list.tpl.html',
        scope: {
            themes: '=ppThemeList',
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            vm.labels = {
                'UK': 'uk',
                'London': 'london',
                'Crossrail': 'crossrail',
                'Specialist': 'specialist',
                'Commuter Belt': 'commuter-belt',
                'Regeneration Areas': 'regeneration-areas',
                'Geared': 'geared',
                'Ungeared': 'ungeared',
                'Student': 'student',
                'Commercial': 'commercial',
            };

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);