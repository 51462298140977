(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketplace-data-view-table-secondary', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.widgets.property-cell',
    'pp.components.table-sort',
    'pp.components.toggle-tick',
    'pp.components.tooltip',
    'pp.services.route'
]);

var ID_KEY = 'symbol';

ngModule.directive('ppMarketplaceDataViewTableSecondary', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/marketplace-data-view-table-secondary/marketplace-data-view-table-secondary.tpl.html',
        scope: {
            data: '<ppMarketplaceDataViewTableSecondary',
            sortAttribute: '=',
            sortOrder: '=',
            showToggles: '=',
            selectedItems: '=',
            onSortChange: '&',
            holdings: '<',
            bids: '<',
            offers: '<',
            propertiesDict: '<',
            viewPropertyDetail: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$element', 'ppTrack', 'ppComponentEmitter', 'routeService', function ($scope, $timeout, $element, ppTrack, ppComponentEmitter, routeService) {
            var vm = this;

            // -- initial state

            vm.getIndividualPropertyPath = routeService.getIndividualPropertyPath;

            var __showTogglesDelayedTimeout;

            vm.idKey = ID_KEY;
            vm.labels = {
                location: 'Location',
                dividendAtLowestSharePrice: 'Dividend at Buy Price',
                premiumOnNewListingPrice: 'Premium on New Listing Price',
                premiumOnLatestValuation: 'Premium on Latest Valuation',
                valueAtLowestSharePrice: 'Available at Buy Price',
                spread: 'Sell/Bid Spread',
                amountOfHighestBid: 'Available at Highest Bid',
                totalTraded30Days: 'Volume Traded (30 days)',
                volumeWeighted30DayAveragePrice: 'Avg Traded Price (30 days)',
                newListingPrice: 'New Listing Price',
                lowestSharePrice: 'Buy Price',
                lastTradePrice: 'Share Price',
                lastTradePricePctChange: 'Current Share Price % +/-',
                latestValuation: 'Latest Valuation',
                netCashAsPctOfPropertyValue: 'Net Cash as a % of Property Value',
                exitMechanicDate: 'Exit Mechanic Date'
            };

            // -- util functions

            function updateSelectAll() {
                var data = vm.data || [];
                for (var ix = 0; ix < data.length; ix++) {
                    var id = data[ix][ID_KEY];
                    if (!vm.selectedItems[id]) {
                        vm.selectedAll = false;
                        return;
                    }
                }
                vm.selectedAll = true;
            }

            // -- scope bindings

            $scope.$watch('vm.showToggles', function () {
                $timeout.cancel(__showTogglesDelayedTimeout);
                __showTogglesDelayedTimeout = $timeout(function () {
                    vm.showTogglesDelayed = vm.showToggles;
                });
            });

            // -- api

            vm.rowClick = function (id) {
                if (vm.showToggles) {
                    vm.selectedItems[id] = !vm.selectedItems[id];
                }
            };

            // update individual items when user clicks on "selectAll" toggle-tick in header row
            vm.selectAllClick = function (selected) {
                var data = vm.data || [];
                for (var ix = 0; ix < data.length; ix++) {
                    var id = data[ix][ID_KEY];
                    vm.selectedItems[id] = selected;
                }
            };

            vm.updateSort = function (attr, order) {
                vm.onSortChange({
                    attr: attr,
                    order: order
                });
            };

            vm.propertyLinkClick = function (symbol) {
                ppTrack.deferAction('data-view.property.link.click', {
                    'property.symbol': symbol
                });

                vm.viewPropertyDetail({
                    symbol: symbol
                });
            };

            // -- scope bindings

            vm.$onInit = function () {
                $scope.$watch('vm.selectedItems', updateSelectAll, true);

                $scope.$watch('vm.data', function () {
                    ppComponentEmitter.emit('marketplace-data-view-secondary.controller.updated', vm);
                }, true);
            };

            vm.$postLink = function () {
                $timeout(function () {
                    ppComponentEmitter.emit('marketplace-data-view-secondary.dom.loaded', $element);
                });
            };

            // -- main

        }]
    };
}]);
})(window, window.angular);