(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.google-maps-api', ['pp.services.core']);

ngModule.service('googleMapsApiService', ['$q', 'ppConfig', function ($q, ppConfig) {

    var mapsDefer = $q.defer();
    var _config = ppConfig.get('googlemaps.api') || {};
    var apiEndpoint = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=' + _config.key + '&callback=';

    // Attach script to dom with endpoint
    function asyncLoad(endpoint, callbackName) {
        var script = document.createElement('script');
        script.src = endpoint + callbackName;
        document.body.appendChild(script);
    }

    // Callback function - resolving promise after maps successfully loaded
    window.googleMapsInitialized = function () {
        mapsDefer.resolve(window.google ? window.google.maps : null);
    };

    // Start loading google maps
    if (!window.google || !window.google.maps) {
        asyncLoad(apiEndpoint, 'googleMapsInitialized');
    }

    /**
     * @description
     * wraps google maps api loading
     * note that loading happens as soon as this service is injected, not when load() is invoked
     *
     * @usage: googleMapsApiService.done().then(...)
     *
     * @returns {Promise} resolved with the global `google.maps.api` object when it is fully loaded
     */
    return {
        load: function () {
            return mapsDefer.promise;
        }
    };

}]);
})(window, window.angular);