(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-map-embed', [
    'pp.widgets-templates',
    'pp.widgets.property-map',
    'pp.services.marketplace',
    'pp.services.investor',
    'pp.services.route',
    'pp.services.core',
    'pp.widgets.property-card',
    'pp.widgets.property-card-mobile'
]);

var PAGE_NAME = 'property-map-embed';

ngModule.component('ppPropertyMapEmbed', {
    templateUrl: 'widgets/_angular/property-map-embed/property-map-embed.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$timeout', 'marketplaceService', 'investorService', 'routeService', 'ppConfig', 'ppTrack', function ($scope, $timeout, marketplaceService, investorService, routeService, ppConfig, ppTrack) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints') || {};
        var assetsBaseUrl = __config ? __config.assets : '';

        vm.mapLocked = true;

        vm.mapReady = false;

        vm.logoImage = assetsBaseUrl + routeService.logoPath;

        // -- util functions

        function isNotAFund(property) {
            return !property.isFund;
        }

        function getSelectedPropertyOnMap(symbol, properties) {
            return properties.reduce(function (property, item) {
                if (property) {
                    return property;
                } else if (item.symbol === symbol) {
                    return item;
                }
            }, undefined);
        }

        // -- api

        //on selcting a pin change card
        vm.onSelectedProperty = function (symbol, properties) {
            $timeout(function () {
                vm.property = getSelectedPropertyOnMap(symbol, properties);
            });
        };

        vm.loadMapData = function () {
            return marketplaceService.getPropertiesList().then(function (properties) {
                vm.properties = properties.filter(isNotAFund);
                return vm.properties;
            });
        };

        vm.unlockMap = function () {
            vm.mapLocked = false;
            ppTrack.pageLoad(PAGE_NAME);
            ppTrack.action(PAGE_NAME + '.unlock-map');
        };

        // -- scope bindings

        // -- main

        investorService.getInvestor().then(function (investor) {

            vm.user = investor;
            vm.ready = true;
        });

    }]
});
})(window, window.angular);