(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.cancel-listing', [
    'pp.widgets-templates',
    'pp.services.investor',
    'pp.services.core',
    'pp.ui.services.suspended-property-dialog'
]);

var PAGE_NAME = 'cancel-listing';
var CANCEL_SUSPENDED_ERROR_CODE = 'secondary.sell.cancel.suspended';

/**
 * @ngdoc directive
 * @name ppCancelListing
 *
 * @description
 * A form that displays a yes no button to confirm the cancellation of a sell order or bid order
 *
 *
 */
ngModule.component('ppCancelListing', {
    templateUrl: 'widgets/_angular/cancel-listing/cancel-listing.tpl.html',
    bindings: {
        isBid: '<',
        symbol: '<',
        offer: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', 'suspendedPropertyDialogService', 'investorService', function ($scope, ppTrack, suspendedPropertyDialogService, investorService) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.close = function () {
            $scope.$parent.dialogDefer.resolve();
            if (vm.isBid) {
                ppTrack.pageAction(PAGE_NAME, 'bid-cancel.dismiss', vm.symbol);
            } else {
                ppTrack.pageAction(PAGE_NAME, 'sell-cancel.dismiss', vm.symbol);
            }
        };

        vm.confirm = function () {

            if (!vm.form.$valid) {
                vm.feedbackEnabled = true;
                vm.processing = false;
            } else {
                vm.processing = true;

                var promise;

                var revision = null;

                if (vm.isBid) {
                    promise = investorService.cancelBidOrder(vm.symbol, vm.offer);
                    ppTrack.pageAction(PAGE_NAME, 'bid-cancel.confirm', vm.symbol);
                } else {
                    promise = investorService.cancelSellOrder(vm.symbol, vm.offer);
                    ppTrack.pageAction(PAGE_NAME, 'sell-cancel.confirm', vm.symbol);
                }

                promise.then(function () {
                    vm.processing = false;
                    $scope.$parent.dialogDefer.resolve(true);
                }, function (data) {

                    if (data.reason === CANCEL_SUSPENDED_ERROR_CODE) {
                        $scope.$parent.dialogDefer.resolve();
                        suspendedPropertyDialogService.show();
                    }

                    vm.processing = false;
                    vm.error = data.reason;
                });
            }
        };

        // -- lifecycle

        vm.$onInit = function () {
            if (vm.isBid) {
                vm.message = 'this bid';
                ppTrack.pageAction(PAGE_NAME, 'bid-cancel', vm.symbol);
            } else {
                vm.message = 'these shares';
                ppTrack.pageAction(PAGE_NAME, 'sell-cancel', vm.symbol);
            }
        };

    }]
});
})(window, window.angular);