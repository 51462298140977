(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.affiliate-landing-page', [
    'pp.widgets-templates',
    'pp.widgets.trust-pilot-trust-hook',
    'pp.widgets.nav-card',
    'pp.widgets.awards-banner',
    'pp.services.core',
    'pp.services.route',
    'pp.services.investor',
    'pp.services.config'
]);

var PAGE_NAME = 'introducer-lp';

ngModule.component('ppAffiliateLandingPage', {
    templateUrl: 'widgets/_angular/affiliate-landing-page/affiliate-landing-page.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$rootScope', 'ppTrack', 'routeService', 'configService', 'ppConfig', 'investorService', function ($rootScope, ppTrack, routeService, configService, ppConfig, investorService) {
        var vm = this;

        // -- initial state

        vm.contactUsPath = routeService.affiliateContactFormUrl;

        vm.contactUsCta = 'Register your interest';
        vm.blogPath = routeService.blogPath;
        vm.knowledgeBaseLink = routeService.knowledgeBaseLink;

        var __endpoints = ppConfig.get('pp.external.endpoints') || {};
        var __assetsEndpoint = __endpoints.assets;

        vm.navCardOne = {
            link: vm.blogPath,
            title: 'Blog',
            description: 'Get the lastest news and analysis',
            iconUrl: __assetsEndpoint + '/images/icons/marketing/affiliate-blog-icon.svg'
        };

        vm.navCardTwo = {
            link: vm.knowledgeBaseLink,
            title: 'Knowledge base',
            description: 'The detail about how it all works',
            iconUrl: __assetsEndpoint + '/images/icons/marketing/affiliate-knowedge-base-icon.svg'
        };

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        investorService.getInvestor().then(function (user) {
            vm.user = user;
        });

        configService.getPromotionalFigures().then(function (data) {
            vm.data = data;
            vm.ready = true;
        }, function () {
            vm.ready = true;
            vm.loadingError = true;
        }).finally(function () {
            ppTrack.pageLoad(PAGE_NAME);
        });

    }]
});
})(window, window.angular);