(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.investor-funds-manual', [
    'pp.widgets-templates',
    'pp.services.investor',
    'pp.services.route'
]);

/**
 * @ngdoc directive
 * @name ppInvestorFundsManual
 * @description
 * Renders the tabs for AI and Manual funds on dashboard
 *
 * @restrict A
 * @scope
 */

ngModule.directive('ppInvestorFundsManual', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/investor-funds-manual/investor-funds-manual.tpl.html',
        scope: {},
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$q', 'investorService', 'routeService', function ($q, investorService, routeService) {
            var vm = this;

            // -- initial state

            vm.fundPath = routeService.fundPath;
            vm.withdrawPath = routeService.withdrawPath;
            vm.transferFundsPath = routeService.transferFundsPath;

            // -- util functions

            function updateInvestor(user) {
                vm.user = user;
            }

            function updateInvestors(investors) {
                vm.isaSetup = investorService.isIsaSetup(investors);
            }

            function loadUser() {
                return investorService.getInvestor().then(function (user) {
                    vm.user = user;
                    vm.ready = true;
                });
            }

            function loadInvestors() {
                return investorService.getUserInvestors().then(updateInvestors);
            }

            // -- api

            // -- scope binding

            // -- main

            vm.$onDestroy = function () {
                investorService.off('investor-updated', updateInvestor);
                investorService.off('user-updated', updateInvestors);
            };

            vm.$onInit = function () {
                loadUser().then(function () {
                    investorService.on('investor-updated', updateInvestor);

                });

                loadInvestors().then(function () {
                    investorService.on('user-updated', updateInvestors);
                });
            };

        }]
    };
});
})(window, window.angular);