(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-pre-order', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.filters.dates',
    'pp.components.tooltip',
    'pp.values.glossary',
    'pp.widgets.property-details-dividend-yield',
    'pp.widgets.property-details-hpi',
    'pp.widgets.property-details-projected-return'
]);

ngModule.directive('ppPropertyDetailsPreOrder', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-details-pre-order/property-details-pre-order.tpl.html',
        scope: {
            property: '<ppPropertyDetailsPreOrder'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'glossary', function ($scope, glossary) {
            var vm = this;

            // -- initial state

            vm.netIncomeYieldPrimary = glossary.netIncomeYieldPrimary;

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);