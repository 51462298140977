(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.components-templates',
    'pp.ui.services.shares-order-liquidity-dialog',
    'pp.widgets.property-details-address',
    'pp.widgets.property-details-pre-order',
    'pp.widgets.property-details-primary',
    'pp.widgets.property-details-secondary',
    'pp.widgets.property-details-loan',
    'pp.widgets.property-details-fund',
    'pp.widgets.funding-round-key-points',
    'pp.widgets.button-sell',
    'pp.widgets.button-buy',
    'pp.widgets.preorder-notes',
    'pp.widgets.button-bid',
    'pp.widgets.property-graphs',
    'pp.widgets.shares-order-liquidity-details',
    'pp.widgets.shares-order-book-chart',
    'pp.widgets.chart-date-options',
    'pp.widgets.shares-traded-chart',
    'pp.widgets.property-details-marketplace',
    'pp.widgets.premium-discount',
    'pp.services.property',
    'pp.filters.numbers',
    'pp.filters.dates',
    'pp.components.tooltip',
    'pp.components.time-left',
    'pp.widgets.property-details-image',
    'pp.widgets.property-details-promo'
]);

ngModule.directive('ppPropertyDetails', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-details/property-details.tpl.html',
        scope: {
            property: '<property',
            propertyPage: '<propertyPage',
            hideAddress: '<',
            user: '<',
            hideCharts: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', '$timeout', 'propertyService', 'ppTrack', 'ppConfig', 'ppComponentEmitter', function ($scope, $element, $timeout, propertyService, ppTrack, ppConfig, ppComponentEmitter) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            //open bids video
            vm.openVideoModal = function () {
                ppTrack.action('bids-video.open');
                $('#bids-video-modal').modal({
                    showClose: true,
                    escapeClose: true,
                    clickClose: true
                });
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                //charts are displayed by default, which is why we do this
                vm.showCharts = !vm.hideCharts;

                if (vm.property && vm.property.symbol) {
                    ppComponentEmitter.emit('individual-property.property-details.controller.loaded.' + vm.property.symbol, vm);

                    $timeout(function () {
                        ppComponentEmitter.emit('individual-property.property-details.dom.loaded.' + vm.property.symbol, $element);
                    });

                    vm.isSecondaryInformational = propertyService.isSecondaryInformationalProperty(vm.property);
                }
            };

        }]
    };
}]);
})(window, window.angular);