(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-projected-return', [
    'pp.widgets-templates',
    'pp.values.glossary'
]);

ngModule.component('ppPropertyDetailsProjectedReturn', {
    templateUrl: 'widgets/_angular/property-details-projected-return/property-details-projected-return.tpl.html',
    bindings: {
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['glossary', function (glossary) {
        var vm = this;

        // -- initial state

        vm.netProjectedReturn = glossary.netProjectedReturn;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);