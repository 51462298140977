(function(window, angular){'use strict';

var ngModule = angular.module('pp.values.form-lists', []);

// @todo JIRA check which are in use, move all date related to to consume the new date-directive service

var topCountries = [{
    'code': 'GBR',
    'iso': 'GB',
    'name': 'United Kingdom'
}];

var countries = [{
    'code': 'AFG',
    'iso': 'AF',
    'name': 'Afghanistan'
}, {
    'code': 'ALA',
    'iso': 'AX',
    'name': 'Åland Islands'
}, {
    'code': 'ALB',
    'iso': 'AL',
    'name': 'Albania'
}, {
    'code': 'DZA',
    'iso': 'DZ',
    'name': 'Algeria'
}, {
    'code': 'ASM',
    'iso': 'AS',
    'name': 'American Samoa'
}, {
    'code': 'AND',
    'iso': 'AD',
    'name': 'Andorra'
}, {
    'code': 'AGO',
    'iso': 'AO',
    'name': 'Angola'
}, {
    'code': 'AIA',
    'iso': 'AI',
    'name': 'Anguilla'
}, {
    'code': 'ATA',
    'iso': 'AQ',
    'name': 'Antarctica'
}, {
    'code': 'ATG',
    'iso': 'AG',
    'name': 'Antigua and Barbuda'
}, {
    'code': 'ARG',
    'iso': 'AR',
    'name': 'Argentina'
}, {
    'code': 'ARM',
    'iso': 'AM',
    'name': 'Armenia'
}, {
    'code': 'ABW',
    'iso': 'AW',
    'name': 'Aruba'
}, {
    'code': 'AUS',
    'iso': 'AU',
    'name': 'Australia'
}, {
    'code': 'AUT',
    'iso': 'AT',
    'name': 'Austria'
}, {
    'code': 'AZE',
    'iso': 'AZ',
    'name': 'Azerbaijan'
}, {
    'code': 'BHS',
    'iso': 'BS',
    'name': 'Bahamas'
}, {
    'code': 'BHR',
    'iso': 'BH',
    'name': 'Bahrain'
}, {
    'code': 'BGD',
    'iso': 'BD',
    'name': 'Bangladesh'
}, {
    'code': 'BRB',
    'iso': 'BB',
    'name': 'Barbados'
}, {
    'code': 'BLR',
    'iso': 'BY',
    'name': 'Belarus'
}, {
    'code': 'BEL',
    'iso': 'BE',
    'name': 'Belgium'
}, {
    'code': 'BLZ',
    'iso': 'BZ',
    'name': 'Belize'
}, {
    'code': 'BEN',
    'iso': 'BJ',
    'name': 'Benin'
}, {
    'code': 'BMU',
    'iso': 'BM',
    'name': 'Bermuda'
}, {
    'code': 'BTN',
    'iso': 'BT',
    'name': 'Bhutan'
}, {
    'code': 'BOL',
    'iso': 'BO',
    'name': 'Bolivia, Plurinational State of'
}, {
    'code': 'BES',
    'iso': 'BQ',
    'name': 'Bonaire, Sint Eustatius and Saba'
}, {
    'code': 'BIH',
    'iso': 'BA',
    'name': 'Bosnia and Herzegovina'
}, {
    'code': 'BWA',
    'iso': 'BW',
    'name': 'Botswana'
}, {
    'code': 'BVT',
    'iso': 'BV',
    'name': 'Bouvet Island'
}, {
    'code': 'BRA',
    'iso': 'BR',
    'name': 'Brazil'
}, {
    'code': 'IOT',
    'iso': 'IO',
    'name': 'British Indian Ocean Territory'
}, {
    'code': 'BRN',
    'iso': 'BN',
    'name': 'Brunei Darussalam'
}, {
    'code': 'BGR',
    'iso': 'BG',
    'name': 'Bulgaria'
}, {
    'code': 'BFA',
    'iso': 'BF',
    'name': 'Burkina Faso'
}, {
    'code': 'BDI',
    'iso': 'BI',
    'name': 'Burundi'
}, {
    'code': 'KHM',
    'iso': 'KH',
    'name': 'Cambodia'
}, {
    'code': 'CMR',
    'iso': 'CM',
    'name': 'Cameroon'
}, {
    'code': 'CAN',
    'iso': 'CA',
    'name': 'Canada'
}, {
    'code': 'CPV',
    'iso': 'CV',
    'name': 'Cape Verde'
}, {
    'code': 'CYM',
    'iso': 'KY',
    'name': 'Cayman Islands'
}, {
    'code': 'CAF',
    'iso': 'CF',
    'name': 'Central African Republic'
}, {
    'code': 'TCD',
    'iso': 'TD',
    'name': 'Chad'
}, {
    'code': 'CHL',
    'iso': 'CL',
    'name': 'Chile'
}, {
    'code': 'CHN',
    'iso': 'CN',
    'name': 'China'
}, {
    'code': 'CXR',
    'iso': 'CX',
    'name': 'Christmas Island'
}, {
    'code': 'CCK',
    'iso': 'CC',
    'name': 'Cocos (Keeling) Islands'
}, {
    'code': 'COL',
    'iso': 'CO',
    'name': 'Colombia'
}, {
    'code': 'COM',
    'iso': 'KM',
    'name': 'Comoros'
}, {
    'code': 'COG',
    'iso': 'CG',
    'name': 'Congo'
}, {
    'code': 'COD',
    'iso': 'CD',
    'name': 'Congo, the Democratic Republic of the'
}, {
    'code': 'COK',
    'iso': 'CK',
    'name': 'Cook Islands'
}, {
    'code': 'CRI',
    'iso': 'CR',
    'name': 'Costa Rica'
}, {
    'code': 'CIV',
    'iso': 'CI',
    'name': 'Côte d\'Ivoire'
}, {
    'code': 'HRV',
    'iso': 'HR',
    'name': 'Croatia'
}, {
    'code': 'CUB',
    'iso': 'CU',
    'name': 'Cuba'
}, {
    'code': 'CUW',
    'iso': 'CW',
    'name': 'Curaçao'
}, {
    'code': 'CYP',
    'iso': 'CY',
    'name': 'Cyprus'
}, {
    'code': 'CZE',
    'iso': 'CZ',
    'name': 'Czech Republic'
}, {
    'code': 'DNK',
    'iso': 'DK',
    'name': 'Denmark'
}, {
    'code': 'DJI',
    'iso': 'DJ',
    'name': 'Djibouti'
}, {
    'code': 'DMA',
    'iso': 'DM',
    'name': 'Dominica'
}, {
    'code': 'DOM',
    'iso': 'DO',
    'name': 'Dominican Republic'
}, {
    'code': 'ECU',
    'iso': 'EC',
    'name': 'Ecuador'
}, {
    'code': 'EGY',
    'iso': 'EG',
    'name': 'Egypt'
}, {
    'code': 'SLV',
    'iso': 'SV',
    'name': 'El Salvador'
}, {
    'code': 'GNQ',
    'iso': 'GQ',
    'name': 'Equatorial Guinea'
}, {
    'code': 'ERI',
    'iso': 'ER',
    'name': 'Eritrea'
}, {
    'code': 'EST',
    'iso': 'EE',
    'name': 'Estonia'
}, {
    'code': 'ETH',
    'iso': 'ET',
    'name': 'Ethiopia'
}, {
    'code': 'FLK',
    'iso': 'FK',
    'name': 'Falkland Islands (Malvinas)'
}, {
    'code': 'FRO',
    'iso': 'FO',
    'name': 'Faroe Islands'
}, {
    'code': 'FJI',
    'iso': 'FJ',
    'name': 'Fiji'
}, {
    'code': 'FIN',
    'iso': 'FI',
    'name': 'Finland'
}, {
    'code': 'FRA',
    'iso': 'FR',
    'name': 'France'
}, {
    'code': 'GUF',
    'iso': 'GF',
    'name': 'French Guiana'
}, {
    'code': 'PYF',
    'iso': 'PF',
    'name': 'French Polynesia'
}, {
    'code': 'ATF',
    'iso': 'TF',
    'name': 'French Southern Territories'
}, {
    'code': 'GAB',
    'iso': 'GA',
    'name': 'Gabon'
}, {
    'code': 'GMB',
    'iso': 'GM',
    'name': 'Gambia'
}, {
    'code': 'GEO',
    'iso': 'GE',
    'name': 'Georgia'
}, {
    'code': 'DEU',
    'iso': 'DE',
    'name': 'Germany'
}, {
    'code': 'GHA',
    'iso': 'GH',
    'name': 'Ghana'
}, {
    'code': 'GIB',
    'iso': 'GI',
    'name': 'Gibraltar'
}, {
    'code': 'GRC',
    'iso': 'GR',
    'name': 'Greece'
}, {
    'code': 'GRL',
    'iso': 'GL',
    'name': 'Greenland'
}, {
    'code': 'GRD',
    'iso': 'GD',
    'name': 'Grenada'
}, {
    'code': 'GLP',
    'iso': 'GP',
    'name': 'Guadeloupe'
}, {
    'code': 'GUM',
    'iso': 'GU',
    'name': 'Guam'
}, {
    'code': 'GTM',
    'iso': 'GT',
    'name': 'Guatemala'
}, {
    'code': 'GGY',
    'iso': 'GG',
    'name': 'Guernsey'
}, {
    'code': 'GIN',
    'iso': 'GN',
    'name': 'Guinea'
}, {
    'code': 'GNB',
    'iso': 'GW',
    'name': 'Guinea-Bissau'
}, {
    'code': 'GUY',
    'iso': 'GY',
    'name': 'Guyana'
}, {
    'code': 'HTI',
    'iso': 'HT',
    'name': 'Haiti'
}, {
    'code': 'HMD',
    'iso': 'HM',
    'name': 'Heard Island and McDonald Islands'
}, {
    'code': 'VAT',
    'iso': 'VA',
    'name': 'Holy See (Vatican City State)'
}, {
    'code': 'HND',
    'iso': 'HN',
    'name': 'Honduras'
}, {
    'code': 'HKG',
    'iso': 'HK',
    'name': 'Hong Kong'
}, {
    'code': 'HUN',
    'iso': 'HU',
    'name': 'Hungary'
}, {
    'code': 'ISL',
    'iso': 'IS',
    'name': 'Iceland'
}, {
    'code': 'IND',
    'iso': 'IN',
    'name': 'India'
}, {
    'code': 'IDN',
    'iso': 'ID',
    'name': 'Indonesia'
}, {
    'code': 'IRN',
    'iso': 'IR',
    'name': 'Iran, Islamic Republic of'
}, {
    'code': 'IRQ',
    'iso': 'IQ',
    'name': 'Iraq'
}, {
    'code': 'IRL',
    'iso': 'IE',
    'name': 'Ireland'
}, {
    'code': 'IMN',
    'iso': 'IM',
    'name': 'Isle of Man'
}, {
    'code': 'ISR',
    'iso': 'IL',
    'name': 'Israel'
}, {
    'code': 'ITA',
    'iso': 'IT',
    'name': 'Italy'
}, {
    'code': 'JAM',
    'iso': 'JM',
    'name': 'Jamaica'
}, {
    'code': 'JPN',
    'iso': 'JP',
    'name': 'Japan'
}, {
    'code': 'JEY',
    'iso': 'JE',
    'name': 'Jersey'
}, {
    'code': 'JOR',
    'iso': 'JO',
    'name': 'Jordan'
}, {
    'code': 'KAZ',
    'iso': 'KZ',
    'name': 'Kazakhstan'
}, {
    'code': 'KEN',
    'iso': 'KE',
    'name': 'Kenya'
}, {
    'code': 'KIR',
    'iso': 'KI',
    'name': 'Kiribati'
}, {
    'code': 'PRK',
    'iso': 'KP',
    'name': 'Korea, Democratic People\'s Republic of'
}, {
    'code': 'KOR',
    'iso': 'KR',
    'name': 'Korea, Republic of'
}, {
    'code': 'KWT',
    'iso': 'KW',
    'name': 'Kuwait'
}, {
    'code': 'KGZ',
    'iso': 'KG',
    'name': 'Kyrgyzstan'
}, {
    'code': 'LAO',
    'iso': 'LA',
    'name': 'Lao People\'s Democratic Republic'
}, {
    'code': 'LVA',
    'iso': 'LV',
    'name': 'Latvia'
}, {
    'code': 'LBN',
    'iso': 'LB',
    'name': 'Lebanon'
}, {
    'code': 'LSO',
    'iso': 'LS',
    'name': 'Lesotho'
}, {
    'code': 'LBR',
    'iso': 'LR',
    'name': 'Liberia'
}, {
    'code': 'LBY',
    'iso': 'LY',
    'name': 'Libya'
}, {
    'code': 'LIE',
    'iso': 'LI',
    'name': 'Liechtenstein'
}, {
    'code': 'LTU',
    'iso': 'LT',
    'name': 'Lithuania'
}, {
    'code': 'LUX',
    'iso': 'LU',
    'name': 'Luxembourg'
}, {
    'code': 'MAC',
    'iso': 'MO',
    'name': 'Macao'
}, {
    'code': 'MDG',
    'iso': 'MG',
    'name': 'Madagascar'
}, {
    'code': 'MWI',
    'iso': 'MW',
    'name': 'Malawi'
}, {
    'code': 'MYS',
    'iso': 'MY',
    'name': 'Malaysia'
}, {
    'code': 'MDV',
    'iso': 'MV',
    'name': 'Maldives'
}, {
    'code': 'MLI',
    'iso': 'ML',
    'name': 'Mali'
}, {
    'code': 'MLT',
    'iso': 'MT',
    'name': 'Malta'
}, {
    'code': 'MHL',
    'iso': 'MH',
    'name': 'Marshall Islands'
}, {
    'code': 'MTQ',
    'iso': 'MQ',
    'name': 'Martinique'
}, {
    'code': 'MRT',
    'iso': 'MR',
    'name': 'Mauritania'
}, {
    'code': 'MUS',
    'iso': 'MU',
    'name': 'Mauritius'
}, {
    'code': 'MYT',
    'iso': 'YT',
    'name': 'Mayotte'
}, {
    'code': 'MEX',
    'iso': 'MX',
    'name': 'Mexico'
}, {
    'code': 'FSM',
    'iso': 'FM',
    'name': 'Micronesia, Federated States of'
}, {
    'code': 'MDA',
    'iso': 'MD',
    'name': 'Moldova, Republic of'
}, {
    'code': 'MCO',
    'iso': 'MC',
    'name': 'Monaco'
}, {
    'code': 'MNG',
    'iso': 'MN',
    'name': 'Mongolia'
}, {
    'code': 'MNE',
    'iso': 'ME',
    'name': 'Montenegro'
}, {
    'code': 'MSR',
    'iso': 'MS',
    'name': 'Montserrat'
}, {
    'code': 'MAR',
    'iso': 'MA',
    'name': 'Morocco'
}, {
    'code': 'MOZ',
    'iso': 'MZ',
    'name': 'Mozambique'
}, {
    'code': 'MMR',
    'iso': 'MM',
    'name': 'Myanmar'
}, {
    'code': 'NAM',
    'iso': 'NA',
    'name': 'Namibia'
}, {
    'code': 'NRU',
    'iso': 'NR',
    'name': 'Nauru'
}, {
    'code': 'NPL',
    'iso': 'NP',
    'name': 'Nepal'
}, {
    'code': 'NLD',
    'iso': 'NL',
    'name': 'Netherlands'
}, {
    'code': 'NCL',
    'iso': 'NC',
    'name': 'New Caledonia'
}, {
    'code': 'NZL',
    'iso': 'NZ',
    'name': 'New Zealand'
}, {
    'code': 'NIC',
    'iso': 'NI',
    'name': 'Nicaragua'
}, {
    'code': 'NER',
    'iso': 'NE',
    'name': 'Niger'
}, {
    'code': 'NGA',
    'iso': 'NG',
    'name': 'Nigeria'
}, {
    'code': 'NIU',
    'iso': 'NU',
    'name': 'Niue'
}, {
    'code': 'NFK',
    'iso': 'NF',
    'name': 'Norfolk Island'
}, {
    'code': 'MNP',
    'iso': 'MP',
    'name': 'Northern Mariana Islands'
}, {
    'code': 'NOR',
    'iso': 'NO',
    'name': 'Norway'
}, {
    'code': 'OMN',
    'iso': 'OM',
    'name': 'Oman'
}, {
    'code': 'PAK',
    'iso': 'PK',
    'name': 'Pakistan'
}, {
    'code': 'PLW',
    'iso': 'PW',
    'name': 'Palau'
}, {
    'code': 'PAN',
    'iso': 'PA',
    'name': 'Panama'
}, {
    'code': 'PNG',
    'iso': 'PG',
    'name': 'Papua New Guinea'
}, {
    'code': 'PRY',
    'iso': 'PY',
    'name': 'Paraguay'
}, {
    'code': 'PER',
    'iso': 'PE',
    'name': 'Peru'
}, {
    'code': 'PHL',
    'iso': 'PH',
    'name': 'Philippines'
}, {
    'code': 'PCN',
    'iso': 'PN',
    'name': 'Pitcairn'
}, {
    'code': 'POL',
    'iso': 'PL',
    'name': 'Poland'
}, {
    'code': 'PRT',
    'iso': 'PT',
    'name': 'Portugal'
}, {
    'code': 'PRI',
    'iso': 'PR',
    'name': 'Puerto Rico'
}, {
    'code': 'QAT',
    'iso': 'QA',
    'name': 'Qatar'
}, {
    'code': 'REU',
    'iso': 'RE',
    'name': 'Réunion'
}, {
    'code': 'ROU',
    'iso': 'RO',
    'name': 'Romania'
}, {
    'code': 'RUS',
    'iso': 'RU',
    'name': 'Russian Federation'
}, {
    'code': 'RWA',
    'iso': 'RW',
    'name': 'Rwanda'
}, {
    'code': 'BLM',
    'iso': 'BL',
    'name': 'Saint Barthélemy'
}, {
    'code': 'SHN',
    'iso': 'SH',
    'name': 'Saint Helena, Ascension and Tristan da Cunha'
}, {
    'code': 'KNA',
    'iso': 'KN',
    'name': 'Saint Kitts and Nevis'
}, {
    'code': 'LCA',
    'iso': 'LC',
    'name': 'Saint Lucia'
}, {
    'code': 'MAF',
    'iso': 'MF',
    'name': 'Saint Martin (French part)'
}, {
    'code': 'SPM',
    'iso': 'PM',
    'name': 'Saint Pierre and Miquelon'
}, {
    'code': 'VCT',
    'iso': 'VC',
    'name': 'Saint Vincent and the Grenadines'
}, {
    'code': 'WSM',
    'iso': 'WS',
    'name': 'Samoa'
}, {
    'code': 'SMR',
    'iso': 'SM',
    'name': 'San Marino'
}, {
    'code': 'STP',
    'iso': 'ST',
    'name': 'Sao Tome and Principe'
}, {
    'code': 'SAU',
    'iso': 'SA',
    'name': 'Saudi Arabia'
}, {
    'code': 'SEN',
    'iso': 'SN',
    'name': 'Senegal'
}, {
    'code': 'SRB',
    'iso': 'RS',
    'name': 'Serbia'
}, {
    'code': 'SYC',
    'iso': 'SC',
    'name': 'Seychelles'
}, {
    'code': 'SLE',
    'iso': 'SL',
    'name': 'Sierra Leone'
}, {
    'code': 'SGP',
    'iso': 'SG',
    'name': 'Singapore'
}, {
    'code': 'SXM',
    'iso': 'SX',
    'name': 'Sint Maarten (Dutch part)'
}, {
    'code': 'SVK',
    'iso': 'SK',
    'name': 'Slovakia'
}, {
    'code': 'SVN',
    'iso': 'SI',
    'name': 'Slovenia'
}, {
    'code': 'SLB',
    'iso': 'SB',
    'name': 'Solomon Islands'
}, {
    'code': 'SOM',
    'iso': 'SO',
    'name': 'Somalia'
}, {
    'code': 'ZAF',
    'iso': 'ZA',
    'name': 'South Africa'
}, {
    'code': 'SGS',
    'iso': 'GS',
    'name': 'South Georgia and the South Sandwich Islands'
}, {
    'code': 'SSD',
    'iso': 'SS',
    'name': 'South Sudan'
}, {
    'code': 'ESP',
    'iso': 'ES',
    'name': 'Spain'
}, {
    'code': 'LKA',
    'iso': 'LK',
    'name': 'Sri Lanka'
}, {
    'code': 'SDN',
    'iso': 'SD',
    'name': 'Sudan'
}, {
    'code': 'SUR',
    'iso': 'SR',
    'name': 'Suriname'
}, {
    'code': 'SJM',
    'iso': 'SJ',
    'name': 'Svalbard and Jan Mayen'
}, {
    'code': 'SWZ',
    'iso': 'SZ',
    'name': 'Swaziland'
}, {
    'code': 'SWE',
    'iso': 'SE',
    'name': 'Sweden'
}, {
    'code': 'CHE',
    'iso': 'CH',
    'name': 'Switzerland'
}, {
    'code': 'SYR',
    'iso': 'SY',
    'name': 'Syrian Arab Republic'
}, {
    'code': 'TWN',
    'iso': 'TW',
    'name': 'Taiwan, Province of China'
}, {
    'code': 'TJK',
    'iso': 'TJ',
    'name': 'Tajikistan'
}, {
    'code': 'TZA',
    'iso': 'TZ',
    'name': 'Tanzania, United Republic of'
}, {
    'code': 'THA',
    'iso': 'TH',
    'name': 'Thailand'
}, {
    'code': 'TLS',
    'iso': 'TL',
    'name': 'Timor-Leste'
}, {
    'code': 'TGO',
    'iso': 'TG',
    'name': 'Togo'
}, {
    'code': 'TKL',
    'iso': 'TK',
    'name': 'Tokelau'
}, {
    'code': 'TON',
    'iso': 'TO',
    'name': 'Tonga'
}, {
    'code': 'TTO',
    'iso': 'TT',
    'name': 'Trinidad and Tobago'
}, {
    'code': 'TUN',
    'iso': 'TN',
    'name': 'Tunisia'
}, {
    'code': 'TUR',
    'iso': 'TR',
    'name': 'Turkey'
}, {
    'code': 'TKM',
    'iso': 'TM',
    'name': 'Turkmenistan'
}, {
    'code': 'TCA',
    'iso': 'TC',
    'name': 'Turks and Caicos Islands'
}, {
    'code': 'TUV',
    'iso': 'TV',
    'name': 'Tuvalu'
}, {
    'code': 'UGA',
    'iso': 'UG',
    'name': 'Uganda'
}, {
    'code': 'UKR',
    'iso': 'UA',
    'name': 'Ukraine'
}, {
    'code': 'ARE',
    'iso': 'AE',
    'name': 'United Arab Emirates'
}, {
    'code': 'GBR',
    'iso': 'GB',
    'name': 'United Kingdom'
}, {
    'code': 'USA',
    'iso': 'US',
    'name': 'United States'
}, {
    'code': 'UMI',
    'iso': 'UM',
    'name': 'United States Minor Outlying Islands'
}, {
    'code': 'URY',
    'iso': 'UY',
    'name': 'Uruguay'
}, {
    'code': 'UZB',
    'iso': 'UZ',
    'name': 'Uzbekistan'
}, {
    'code': 'VUT',
    'iso': 'VU',
    'name': 'Vanuatu'
}, {
    'code': 'VEN',
    'iso': 'VE',
    'name': 'Venezuela, Bolivarian Republic of'
}, {
    'code': 'VNM',
    'iso': 'VN',
    'name': 'Viet Nam'
}, {
    'code': 'VGB',
    'iso': 'VG',
    'name': 'Virgin Islands, British'
}, {
    'code': 'VIR',
    'iso': 'VI',
    'name': 'Virgin Islands, U.S.'
}, {
    'code': 'WLF',
    'iso': 'WF',
    'name': 'Wallis and Futuna'
}, {
    'code': 'ESH',
    'iso': 'EH',
    'name': 'Western Sahara'
}, {
    'code': 'YEM',
    'iso': 'YE',
    'name': 'Yemen'
}, {
    'code': 'ZMB',
    'iso': 'ZM',
    'name': 'Zambia'
}, {
    'code': 'ZWE',
    'iso': 'ZW',
    'name': 'Zimbabwe'
}];

var currencies = [{
    code: 'GBP',
    name: 'British pound'
}, {
    code: 'USD',
    name: 'United States dollar'
}, {
    code: 'AED',
    name: 'United Arab Emirates dirham'
}, {
    code: 'AFN',
    name: 'Afghan afghani'
}, {
    code: 'ALL',
    name: 'Albanian lek'
}, {
    code: 'AMD',
    name: 'Armenian dram'
}, {
    code: 'AOA',
    name: 'Angolan kwanza'
}, {
    code: 'ARS',
    name: 'Argentine peso'
}, {
    code: 'AUD',
    name: 'Australian dollar'
}, {
    code: 'AWG',
    name: 'Aruban florin'
}, {
    code: 'AZN',
    name: 'Azerbaijani manat'
}, {
    code: 'BAM',
    name: 'Bosnia and Herzegovina convertible mark'
}, {
    code: 'BBD',
    name: 'Barbadian dollar'
}, {
    code: 'BDT',
    name: 'Bangladeshi taka'
}, {
    code: 'BGN',
    name: 'Bulgarian lev'
}, {
    code: 'BHD',
    name: 'Bahraini dinar'
}, {
    code: 'BIF',
    name: 'Burundian franc'
}, {
    code: 'BMD',
    name: 'Bermudian dollar'
}, {
    code: 'BND',
    name: 'Brunei dollar'
}, {
    code: 'BOB',
    name: 'Bolivian boliviano'
}, {
    code: 'BRL',
    name: 'Brazilian real'
}, {
    code: 'BSD',
    name: 'Bahamian dollar'
}, {
    code: 'BTN',
    name: 'Bhutanese ngultrum'
}, {
    code: 'BWP',
    name: 'Botswana pula'
}, {
    code: 'BYR',
    name: 'Belarusian ruble'
}, {
    code: 'BZD',
    name: 'Belize dollar'
}, {
    code: 'CAD',
    name: 'Canadian dollar'
}, {
    code: 'CDF',
    name: 'Congolese franc'
}, {
    code: 'CHF',
    name: 'Swiss franc'
}, {
    code: 'CLP',
    name: 'Chilean peso'
}, {
    code: 'CNY',
    name: 'Chinese yuan'
}, {
    code: 'COP',
    name: 'Colombian peso'
}, {
    code: 'CRC',
    name: 'Costa Rican colón'
}, {
    code: 'CUP',
    name: 'Cuban convertible peso'
}, {
    code: 'CVE',
    name: 'Cape Verdean escudo'
}, {
    code: 'CZK',
    name: 'Czech koruna'
}, {
    code: 'DJF',
    name: 'Djiboutian franc'
}, {
    code: 'DKK',
    name: 'Danish krone'
}, {
    code: 'DOP',
    name: 'Dominican peso'
}, {
    code: 'DZD',
    name: 'Algerian dinar'
}, {
    code: 'EGP',
    name: 'Egyptian pound'
}, {
    code: 'ERN',
    name: 'Eritrean nakfa'
}, {
    code: 'ETB',
    name: 'Ethiopian birr'
}, {
    code: 'EUR',
    name: 'Euro'
}, {
    code: 'FJD',
    name: 'Fijian dollar'
}, {
    code: 'FKP',
    name: 'Falkland Islands pound'
}, {
    code: 'GBP',
    name: 'British pound'
}, {
    code: 'GEL',
    name: 'Georgian lari'
}, {
    code: 'GHS',
    name: 'Ghana cedi'
}, {
    code: 'GMD',
    name: 'Gambian dalasi'
}, {
    code: 'GNF',
    name: 'Guinean franc'
}, {
    code: 'GTQ',
    name: 'Guatemalan quetzal'
}, {
    code: 'GYD',
    name: 'Guyanese dollar'
}, {
    code: 'HKD',
    name: 'Hong Kong dollar'
}, {
    code: 'HNL',
    name: 'Honduran lempira'
}, {
    code: 'HRK',
    name: 'Croatian kuna'
}, {
    code: 'HTG',
    name: 'Haitian gourde'
}, {
    code: 'HUF',
    name: 'Hungarian forint'
}, {
    code: 'IDR',
    name: 'Indonesian rupiah'
}, {
    code: 'ILS',
    name: 'Israeli new shekel'
}, {
    code: 'IMP',
    name: 'Manx pound'
}, {
    code: 'INR',
    name: 'Indian rupee'
}, {
    code: 'IQD',
    name: 'Iraqi dinar'
}, {
    code: 'IRR',
    name: 'Iranian rial'
}, {
    code: 'ISK',
    name: 'Icelandic króna'
}, {
    code: 'JEP',
    name: 'Jersey pound'
}, {
    code: 'JMD',
    name: 'Jamaican dollar'
}, {
    code: 'JOD',
    name: 'Jordanian dinar'
}, {
    code: 'JPY',
    name: 'Japanese yen'
}, {
    code: 'KES',
    name: 'Kenyan shilling'
}, {
    code: 'KGS',
    name: 'Kyrgyzstani som'
}, {
    code: 'KHR',
    name: 'Cambodian riel'
}, {
    code: 'KMF',
    name: 'Comorian franc'
}, {
    code: 'KPW',
    name: 'North Korean won'
}, {
    code: 'KRW',
    name: 'South Korean won'
}, {
    code: 'KWD',
    name: 'Kuwaiti dinar'
}, {
    code: 'KYD',
    name: 'Cayman Islands dollar'
}, {
    code: 'KZT',
    name: 'Kazakhstani tenge'
}, {
    code: 'LAK',
    name: 'Lao kip'
}, {
    code: 'LBP',
    name: 'Lebanese pound'
}, {
    code: 'LKR',
    name: 'Sri Lankan rupee'
}, {
    code: 'LRD',
    name: 'Liberian dollar'
}, {
    code: 'LSL',
    name: 'Lesotho loti'
}, {
    code: 'LTL',
    name: 'Lithuanian litas'
}, {
    code: 'LVL',
    name: 'Latvian lats'
}, {
    code: 'LYD',
    name: 'Libyan dinar'
}, {
    code: 'MAD',
    name: 'Moroccan dirham'
}, {
    code: 'MDL',
    name: 'Moldovan leu'
}, {
    code: 'MGA',
    name: 'Malagasy ariary'
}, {
    code: 'MKD',
    name: 'Macedonian denar'
}, {
    code: 'MMK',
    name: 'Burmese kyat'
}, {
    code: 'MNT',
    name: 'Mongolian tögrög'
}, {
    code: 'MOP',
    name: 'Macanese pataca'
}, {
    code: 'MRO',
    name: 'Mauritanian ouguiya'
}, {
    code: 'MUR',
    name: 'Mauritian rupee'
}, {
    code: 'MVR',
    name: 'Maldivian rufiyaa'
}, {
    code: 'MWK',
    name: 'Malawian kwacha'
}, {
    code: 'MXN',
    name: 'Mexican peso'
}, {
    code: 'MYR',
    name: 'Malaysian ringgit'
}, {
    code: 'MZN',
    name: 'Mozambican metical'
}, {
    code: 'NAD',
    name: 'Namibian dollar'
}, {
    code: 'NGN',
    name: 'Nigerian naira'
}, {
    code: 'NIO',
    name: 'Nicaraguan córdoba'
}, {
    code: 'NOK',
    name: 'Norwegian krone'
}, {
    code: 'NPR',
    name: 'Nepalese rupee'
}, {
    code: 'NZD',
    name: 'New Zealand dollar'
}, {
    code: 'OMR',
    name: 'Omani rial'
}, {
    code: 'PAB',
    name: 'Panamanian balboa'
}, {
    code: 'PEN',
    name: 'Peruvian nuevo sol'
}, {
    code: 'PGK',
    name: 'Papua New Guinean kina'
}, {
    code: 'PHP',
    name: 'Philippine peso'
}, {
    code: 'PKR',
    name: 'Pakistani rupee'
}, {
    code: 'PLN',
    name: 'Polish złoty'
}, {
    code: 'PRB',
    name: 'Transnistrian ruble'
}, {
    code: 'PYG',
    name: 'Paraguayan guaraní'
}, {
    code: 'QAR',
    name: 'Qatari riyal'
}, {
    code: 'RON',
    name: 'Romanian leu'
}, {
    code: 'RSD',
    name: 'Serbian dinar'
}, {
    code: 'RUB',
    name: 'Russian ruble'
}, {
    code: 'RWF',
    name: 'Rwandan franc'
}, {
    code: 'SAR',
    name: 'Saudi riyal'
}, {
    code: 'SBD',
    name: 'Solomon Islands dollar'
}, {
    code: 'SCR',
    name: 'Seychellois rupee'
}, {
    code: 'SDG',
    name: 'Singapore dollar'
}, {
    code: 'SEK',
    name: 'Swedish krona'
}, {
    code: 'SGD',
    name: 'Singapore dollar'
}, {
    code: 'SHP',
    name: 'Saint Helena pound'
}, {
    code: 'SLL',
    name: 'Sierra Leonean leone'
}, {
    code: 'SOS',
    name: 'Somali shilling'
}, {
    code: 'SRD',
    name: 'Surinamese dollar'
}, {
    code: 'SSP',
    name: 'South Sudanese pound'
}, {
    code: 'STD',
    name: 'São Tomé and Príncipe dobra'
}, {
    code: 'SVC',
    name: 'Salvadoran colón'
}, {
    code: 'SYP',
    name: 'Syrian pound'
}, {
    code: 'SZL',
    name: 'Swazi lilangeni'
}, {
    code: 'THB',
    name: 'Thai baht'
}, {
    code: 'TJS',
    name: 'Tajikistani somoni'
}, {
    code: 'TMT',
    name: 'Turkmenistan manat'
}, {
    code: 'TND',
    name: 'Tunisian dinar'
}, {
    code: 'TOP',
    name: 'Tongan paʻanga'
}, {
    code: 'TRY',
    name: 'Turkish lira'
}, {
    code: 'TTD',
    name: 'Trinidad and Tobago dollar'
}, {
    code: 'TWD',
    name: 'New Taiwan dollar'
}, {
    code: 'TZS',
    name: 'Tanzanian shilling'
}, {
    code: 'UAH',
    name: 'Ukrainian hryvnia'
}, {
    code: 'UGX',
    name: 'Ugandan shilling'
}, {
    code: 'USD',
    name: 'United States dollar'
}, {
    code: 'UYU',
    name: 'Uruguayan peso'
}, {
    code: 'UZS',
    name: 'Uzbekistani som'
}, {
    code: 'VEF',
    name: 'Venezuelan bolívar'
}, {
    code: 'VND',
    name: 'Vietnamese đồng'
}, {
    code: 'VUV',
    name: 'Vanuatu vatu'
}, {
    code: 'WST',
    name: 'Samoan tālā'
}, {
    code: 'XAF',
    name: 'Central African CFA franc'
}, {
    code: 'XCD',
    name: 'East Caribbean dollar'
}, {
    code: 'XOF',
    name: 'West African CFA franc'
}, {
    code: 'XPF',
    name: 'CFP franc'
}, {
    code: 'YER',
    name: 'Yemeni rial'
}, {
    code: 'ZAR',
    name: 'South African rand'
}, {
    code: 'ZMW',
    name: 'Zambian kwacha'
}, {
    code: 'ZWL',
    name: 'Zimbabwean dollar'
}];

var euCountries = ['ALB', 'AND', 'AUT', 'BLR', 'BEL', 'BIH', 'BGR', 'HRV', 'CYP', 'CZE', 'DNK', 'EST', 'FRO', 'FIN', 'FRA', 'DEU', 'GIB', 'GRC', 'HUN', 'ISL', 'IRL', 'ITA', 'LVA', 'LIE', 'LTU', 'LUX', 'MKD', 'MLT', 'MDA', 'MCO', 'NLD', 'NOR', 'POL', 'PRT', 'ROU', 'RUS', 'SMR', 'SRB', 'SVK', 'SVN', 'ESP', 'SWE', 'CHE', 'UKR', 'VAT', 'RSB', 'IMN', 'XKX', 'MNE'];

// @todo JIRA deprecated
var months = [{
    value: '01',
    text: 'January'
}, {
    value: '02',
    text: 'February'
}, {
    value: '03',
    text: 'March'
}, {
    value: '04',
    text: 'April'
}, {
    value: '05',
    text: 'May'
}, {
    value: '06',
    text: 'June'
}, {
    value: '07',
    text: 'July'
}, {
    value: '08',
    text: 'August'
}, {
    value: '09',
    text: 'September'
}, {
    value: '10',
    text: 'October'
}, {
    value: '11',
    text: 'November'
}, {
    value: '12',
    text: 'December'
}];

var lists = {
    currencies: currencies,
    countries: countries,
    topCountries: topCountries,
    euCountries: euCountries,
    months: months
};

var d = new Date();
var currentYear = d.getFullYear();
var pastLimit = currentYear - 120;
var companyStartYear = 2014;
var futureLimit = currentYear + 23;

// @todo JIRA deprecated
lists.days = [];
for (var ix = 1; ix <= 31; ix++) {
    lists.days.push({
        value: ix,
        text: String(ix)
    });
}

// @todo JIRA deprecated
lists.daysString = [];
for (var ix = 1; ix <= 31; ix++) {
    var value = (ix < 10) ? '0' + String(ix) : String(ix);
    lists.daysString.push({
        value: value,
        text: String(ix)
    });
}

// @todo JIRA deprecated
lists.yearsPast = [];
for (var ix = 2000; ix >= pastLimit; ix--) {
    lists.yearsPast.push({
        value: ix,
        text: String(ix)
    });
}

// @todo JIRA deprecated
lists.yearsPastRecent = [];
for (var ix = currentYear; ix >= pastLimit; ix--) {
    lists.yearsPastRecent.push({
        value: ix,
        text: String(ix)
    });
}

// @todo JIRA deprecated
lists.yearsPastSinceCompanyStartString = [];
for (var ix = currentYear; ix >= companyStartYear; ix--) {
    lists.yearsPastSinceCompanyStartString.push({
        value: String(ix),
        text: String(ix)
    });
}

// @todo JIRA deprecated
lists.yearsFuture = [];
for (var ix = currentYear; ix <= futureLimit; ix++) {
    lists.yearsFuture.push({
        value: ix,
        text: String(ix)
    });
}

lists.getCountryBy3digit = function (code) {
    for (var ix = 0; ix < countries.length; ix++) {
        if (countries[ix].code === code) {
            return countries[ix];
        }
    }
};

lists.getCountryByIso = function (iso) {
    for (var ix = 0; ix < countries.length; ix++) {
        if (countries[ix].iso === iso) {
            return countries[ix];
        }
    }
};

lists.isEuCountry = function (code) {
    return lists.euCountries.indexOf(code) !== -1;
};

lists.findCountryLabelByISO = function (iso) {
    return countries.reduce(function (label, country) {
        if (label) {
            return label;
        }

        if (country.iso === iso) {
            return country.name;
        }
    }, undefined);
};

lists.monthsWithNumberAndText = months.map(function (month) {
    return {
        value: month.value,
        text: month.value + ' - ' + month.text
    };
});

ngModule.value('formLists', lists);
})(window, window.angular);