(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketplace-featured-view', [
    'pp.widgets-templates',
    'ui.router',
    'pp.widgets.property-set',
    'pp.widgets.advance-pre-order-card',
    'pp.widgets.investment-plan-card-carousel',
    'pp.widgets.market-price-movement-table',
    'pp.widgets.index-graph',
    'pp.services.core',
    'pp.services.route'
]);

var TITLE_MAP = {
    'development_loan': 'Development loan bond',
    mortgage: 'Mortgage bond',
    residential: 'Residential',
    commercial: 'Commercial',
    student: 'Student',
    fund: 'Opportunistic Fund',
    investmentplans: 'A property portfolio in five minutes'
};

var DESCRIPTION_MAP = {
    'development_loan': 'We\'ve recently launched development loan bonds on our platform - a new high-yielding, property-backed debt investment.',
    mortgage: 'Property Partner Mortgage Bond is our lowest risk investment product, paying an inflation beating fixed 3.0% interest p.a',
    residential: 'UK residential property is a defensive, total return asset class. It’s characterised by a combination of inflation beating capital growth and a growing income stream.',
    commercial: 'Commercial property can provide a strong, reliable income over the long-term offering opportunities for portfolio diversification.',
    student: 'Purpose-built student accommodation is the UK’s strongest-growing property sector, boasting high yields in a low interest environment.',
    UKSG180DX001: 'UK residential property is a defensive, total return asset class. It’s characterised by a combination of inflation beating capital growth and a growing income stream.',
    UKDT49BE001: 'Commercial property can provide a strong, reliable income over the long-term offering opportunities for portfolio diversification.',
    UKHD16BB001: 'Purpose-built student accommodation is the UK’s strongest-growing property sector, boasting high yields in a low interest environment.',
    UKPPOF001: 'Fund aiming to deliver a total return to investors well in excess of underlying market growth. Raising capital in advance empowers us as a fast-moving cash buyer, targeting discounted properties, from motivated sellers who need to sell quickly.',
    investmentplans: 'Build a diversified property portfolio with Investment Plans. Simply choose how much you want to invest and we will do the rest.'
};

ngModule.component('ppMarketplaceFeaturedView', {
    templateUrl: 'widgets/_angular/marketplace-featured-view/marketplace-featured-view.tpl.html',
    bindings: {
        sets: '<',
        ready: '<',
        apoFunds: '<',
        topRisers: '<',
        topFallers: '<',
        bondCardConfig: '<',
        user: '<',
        holdings: '<',
        bids: '<',
        offers: '<',
        newCards: '<',
        clearFilters: '&',
        currentIndex: '<',
        historicTradingIndex: '<',
        historicResidentialTradingIndex: '<',
        historicStudentTradingIndex: '<',
        historicPropertyInvestmentValueIndex: '<',
        historicShareInvestmentValueIndex: '<',
        historicPropertyResidentialInvestmentValueIndex: '<',
        historicPropertyResidentialVacantPossessionValueIndex: '<',
        historicPropertyStudentInvestmentValueIndex: '<',
        historicShareResidentialInvestmentValueIndex: '<',
        historicShareResidentialVacantPossessionValueIndex: '<',
        historicShareStudentInvestmentValueIndex: '<',
        historicPropertyVacantPossessionValueIndex: '<',
        historicShareVacantPossessionValueIndex: '<',
        viewPropertyDetail: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$anchorScroll', '$location', '$timeout', '$stateParams', 'ppTrack', 'routeService', function ($scope, $anchorScroll, $location, $timeout, $stateParams, ppTrack, routeService) {
        var vm = this;

        // -- initial state

        vm.investmentPlansPath = routeService.investmentPlansPath;

        // -- util functions

        function getAssetTypeKey(property) {
            if (property.isResidential) {
                return 'residential';
            }

            if (property.isCommercial) {
                return 'commercial';
            }

            if (property.isStudent) {
                return 'student';
            }

            if (property.isMortgage) {
                return 'mortgage';
            }

            if (property.isDevelopmentLoan) {
                return 'development_loan';
            }

            if (property.isFund) {
                return 'fund';
            }

            if (property.investmentPlans) {
                return 'investmentplans';
            }

            if (property.type) {
                return property.type;
            }
        }

        // -- api

        vm.getTitle = function (property) {
            return TITLE_MAP[getAssetTypeKey(property)];
        };

        vm.getDescription = function (property) {
            if (property.symbol && DESCRIPTION_MAP[property.symbol]) {
                return DESCRIPTION_MAP[property.symbol];
            } else {
                return DESCRIPTION_MAP[getAssetTypeKey(property)];
            }
        };

        vm.getPropertyClass = function (property) {
            if (property.symbol) {
                return property.symbol;
            } else {
                return getAssetTypeKey(property);
            }
        };

        vm.viewAllInvestmentsClick = function () {
            ppTrack.action('marketplace.featured-view.view-all-investments.click');
            $anchorScroll();
            vm.clearFilters();
        };

        vm.onViewPropertyDetail = function (property) {
            vm.viewPropertyDetail({
                property: property
            });
        };

        vm.onChartLoad = function () {
            $timeout(function () {
                $anchorScroll();
            });
        };

        // -- scope bindings

        vm.$onInit = function () {
            if ($stateParams.scrollTo) {
                $location.hash($stateParams.scrollTo);
                $timeout($anchorScroll);
            }
        };

        // -- main

    }]
});
})(window, window.angular);