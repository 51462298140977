(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.kyc-more-info', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.route'
]);
var PAGE_NAME = 'kyc.more.info';

ngModule.component('ppKycMoreInfo', {
    templateUrl: 'widgets/_angular/kyc-more-info/kyc-more-info.tpl.html',
    bindings: {
        hideLayout: '<',
        kycStatusDetails: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', 'investorService', 'ppComponentEmitter', 'ppTrack', 'routeService', function ($scope, $element, investorService, ppComponentEmitter, ppTrack, routeService) {
        var vm = this;

        // -- initial state

        vm.dashboardPath = routeService.dashboardPath;
        vm.marketplacePath = routeService.marketplacePath;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        vm.$postLink = function () {
            ppComponentEmitter.emit('kyc-more-info.dom.loaded', $element);
        };

        vm.$onInit = function () {
            ppComponentEmitter.emit('kyc-more-info.controller.loaded', vm);
            ppTrack.pageLoad(PAGE_NAME);
        };

        investorService.getInvestor().then(function (user) {
            vm.user = user;
            vm.ready = true;
        });
    }]
});
})(window, window.angular);