(function(window, angular){'use strict';
window.pp = window.pp || {};

var util = window.pp.util;

var ngModule = angular.module('pp.widgets.document-links', ['pp.widgets-templates', 'pp.services.core']);

/**
 * @ngdoc directive
 * @name ppDocumentLinks
 * @description
 * Renders the document links
 *
 * @restrict A
 * @scope
 *
 */

ngModule.directive('ppDocumentLinks', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/document-links/document-links.tpl.html',
        scope: {
            property: '<ppDocumentLinks'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppTrack', 'ppConfig', function ($scope, ppTrack, ppConfig) {
            var vm = this;

            // -- initial state

            var externalEndpoints = ppConfig.get('pp.external.endpoints') || {};

            var assetsEndpoint = externalEndpoints.assets;

            // -- util functions

            // -- api

            vm.documenLinkClick = function (document) {
                var documentSlug = util.camelCaseToSlug(document);
                ppTrack.action('property-documents.' + documentSlug + '.link.click');
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                var reportsBaseUrl = assetsEndpoint + '/images/properties/' + vm.property.symbol + '/reports/';
                vm.dueDiligenceReportUrl = reportsBaseUrl + 'acquisition_due_diligence_report.pdf';
                vm.brochureUrl = reportsBaseUrl + 'brochure.pdf';
            };

        }]
    };
});
})(window, window.angular);