(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.input-amount', ['ng.cork.ui.focus-on', 'pp.components-templates', 'pp.services.core', 'pp.components.bind-invalid-model', 'pp.services.number']);

var MAX_LENGTH = 8;
var MAXIMUM_AMOUNT = 99999999;

/**
 * @ngdoc directive
 * @name amount
 * @description
 * Amount input
 *
 * **EVENTS**
 * - emit on `$scope` event `pp.input-amount.onEnter` with no payload (consumed by (see quoteCtrl, fundCtrl, investCtrl)
 *
 * @restrict A
 * @scope
 * @param {object} ngModel
 * @param {number} invalidAmount
 * @param {number} minimum
 * @param {number} maximum
 * @param {string} pattern
 * @param {string} placeholder
 * @param {boolean} inputDisabled
 * @param {string} focusOn set to 'auto' to auto-focus input, or to a string to listen for on $scope in order to focus on demand
 */
ngModule.directive('ppInputAmount', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        templateUrl: 'components/_angular/input-amount/input-amount.tpl.html',
        scope: {
            amount: '=ngModel',
            invalidAmount: '=?',
            minimum: '=',
            maximum: '=',
            placeholder: '=',
            regexp: '=',
            inputDisabled: '=',
            focusOn: '@',
            tabindex: '<',
            allowZero: '='
        },
        link: function ($scope, $elem, $attr) {
            // because input number does not even update viewValues when number invalid (ex: 0..3)
            var $input = $elem.find('input');

            // update validity
            $scope.$watch(function () {
                return $input.val();
            }, function (value) {

                var numValue = Number(value);

                // validates pattern
                if ($scope.vm.regexp && !value.length || $scope.vm.regexp && !$scope.vm.regexp.test(value)) {
                    $scope.vm.ppInputAmount.$setValidity('regexp', false);
                    return; // @note early return
                }
                $scope.vm.ppInputAmount.$setValidity('regexp', true);

                // minimum
                if (angular.isDefined($scope.vm.minimum) && numValue < $scope.vm.minimum) {
                    $scope.vm.ppInputAmount.$setValidity('minimum', false);
                    $scope.vm.ppInputAmount.$setValidity('maximum', true);
                    //
                    if ($scope.vm.allowZero && numValue === 0) {
                        $scope.vm.ppInputAmount.$setValidity('minimum', true);
                        $scope.vm.ppInputAmount.amount.$setValidity('min', true);
                    }
                    return; // @note early return
                }
                $scope.vm.ppInputAmount.$setValidity('minimum', true);

                // maximum
                if (angular.isDefined($scope.vm.maximum) && numValue > $scope.vm.maximum) {
                    $scope.vm.ppInputAmount.$setValidity('maximum', false);
                } else {
                    $scope.vm.ppInputAmount.$setValidity('maximum', true);
                }
            });

        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$window', '$element', 'numberService', 'ppComponentEmitter', function ($scope, $timeout, $window, $element, numberService, ppComponentEmitter) {
            var vm = this;

            vm.maxlength = MAX_LENGTH;
            vm.symbol = '£';
            //vm.maximum = $scope.maximum || MAXIMUM_AMOUNT;

            // -- initial state

            // -- util functions

            function truncateInputIfOverMaxlength() {
                var viewValue = vm.ppInputAmount.amount.$viewValue;
                if (viewValue && viewValue.length > vm.maxlength && vm.ppInputAmount.$valid) {
                    var amount = Number(viewValue.slice(0, MAX_LENGTH));
                    vm.amount = numberService.comprehensiveIsNumber(amount, $window) ? amount : vm.amount;
                }
            }

            // -- api

            vm.onKeyPress = function ($ev) {
                if ($ev.keyCode === 13 || $ev.key === 13) {
                    $scope.$emit('pp.input-amount.onEnter');
                } else {
                    var viewValue = vm.ppInputAmount.amount.$viewValue;
                    if ($ev.key === 46 || $ev.keyCode === 46 || $ev.keyCode === 8 || $ev.key === 8) {
                        return; // @note early return
                    }

                    if (viewValue && viewValue.length >= vm.maxlength) {
                        $timeout(function () {
                            truncateInputIfOverMaxlength();
                        });
                    }

                }
            };

            // -- scope bindings

            // -- main

            vm.$postLink = function () {
                ppComponentEmitter.emit('input-amount.dom.loaded', $element);
            };

            vm.$onInit = function () {
                ppComponentEmitter.emit('input-amount.controller.loaded', vm);
            };
        }]
    };
}]);
})(window, window.angular);