(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.sell', [
    'ng.cork.ui.focus-on',
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.nationality',
    'pp.services.investor',
    'pp.services.property',
    'pp.services.receipt',
    'pp.ui.services.nationality-lei-dialog',
    'pp.ui.services.suspended-property-dialog',
    'pp.widgets.property-graphs',
    'pp.widgets.sell-form',
    'pp.widgets.help',
    'pp.widgets.property-details'
]);

var PAGE_NAME = 'sell';
var DASHBOARD_URL = '/my-portfolio#/dashboard:tab';
var BLOCKED_REDIRECT_URL = '/properties/:symbol';
var PROPERTY_NOT_TRADABLE_ERROR = 'property.currently.not.tradable';
var PROPERTY_INSUFFICIENT_HOLDINGS_ERROR = 'secondary.offer.insufficient.holdings';

ngModule.component('ppSell', {
    templateUrl: 'widgets/_angular/sell/sell.tpl.html',
    bindings: {
        hideSidebar: '<',
        removeLayout: '<',
        hideGraphs: '<',
        alwaysToggleGraphs: '<',
        propertyData: '<property',
        onOfferPlaced: '&?',
        isSidebar: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', '$q', 'ppTrack', 'ppConfig', 'ppUtil', 'investorService', 'nationalityService', 'propertyService', 'nationalityLeiDialogService', 'receiptService', 'suspendedPropertyDialogService', 'routeService', function ($scope, $window, $q, ppTrack, ppConfig, ppUtil, investorService, nationalityService, propertyService, nationalityLeiDialogService, receiptService, suspendedPropertyDialogService, routeService) {
        var vm = this;

        // -- initial state
        var __authConfig = ppConfig.get('pp.modules.auth') || {};
        var __endpoints = ppConfig.get('pp.external.endpoints') || {};

        vm.assetsEndpoint = __endpoints.assets;

        vm.reCaptchaKey = __authConfig.reCaptchaKey;
        vm.reCaptchaEnabled = __authConfig.reCaptchaEnabled;

        var property;
        var symbol;

        vm.ready = false;

        var successRedirect = routeService.portfolioSellPath;

        // -- util functions

        function redirect(href) {
            ppTrack.closeAll().then(function () {
                window.location.href = href;
            });
        }

        function redirectBlockedUser(symbol) {
            window.pp.overlay.show();
            ppTrack.pageEvent(PAGE_NAME, 'requiredPermissions');
            redirect(BLOCKED_REDIRECT_URL.replace(':symbol', symbol));
        }

        function init() {
            // will disable the UI if the user does not have permissions to sell
            return investorService.getInvestor().then(function (user) {
                ppTrack.setContext('property.symbol', symbol);
                // load investor holding and check and show the UI
                // if an error occurs OR the user does not own any shares OR all the shares are already listed
                // the UI will remain disabled
                var promises = {
                    holdings: investorService.getInvestorHoldingsByProperty(symbol),
                    property: propertyService.getPropertyData(symbol)
                };

                return $q.all(promises).then(function (responses) {
                    vm.holdings = responses.holdings;
                    vm.property = responses.property;
                    vm.user = user;
                    vm.ready = true;
                    vm.nationalityLeiText = vm.user.kyc && vm.user.kyc.business ? 'LEI Number' : 'nationality';

                    ppTrack.pageLoad(PAGE_NAME);
                }, function (error) {
                    ppTrack.pageLoad(PAGE_NAME);
                    ppTrack.pageError(PAGE_NAME, 'loadingError', error.reason);
                    vm.loadingError = error.reason;
                    vm.ready = true;
                });
            });
        }

        function onAuthClose() {
            redirectBlockedUser(symbol);
        }

        function onNationalityLeiSuccess() {
            investorService.purgeCache('investor$');
            nationalityLeiDialogService.close();
            init();
        }

        vm.showNationalityLeiDialog = function () {
            return nationalityService.getNationalityList().then(function (nationalityList) {
                return nationalityLeiDialogService.show(vm.user, nationalityList, onNationalityLeiSuccess, onAuthClose);
            });
        };

        // -- api

        vm.onSubmit = function (numberOfShares, sharePrice, grecaptchaValue) {
            ppTrack.setContext({
                'sell.shares': numberOfShares,
                'sell.price': sharePrice
            });

            var price = (sharePrice / 100).toFixed(2);
            return investorService.createSellOrder(symbol, numberOfShares, price, grecaptchaValue).then(function () {

                investorService.purgeCache('investor$');
                propertyService.purgeCache(symbol + '.secondary.offers');
                propertyService.purgeCache(symbol + '.trading.history');

                // post to dashboard
                var receiptData = {
                    receiptType: 'SELL',
                    sharePrice: price,
                    shares: numberOfShares
                };
                ppTrack.formSubmit(PAGE_NAME);
                ppTrack.closeAll().then(function () {

                    if (vm.isSidebar) {
                        vm.onOfferPlaced({
                            receipt: receiptData
                        });
                    } else {
                        receiptService.pushReceipt(receiptData);
                        $window.location.href = successRedirect;
                    }

                });
            }, function (error) {

                if (error.reason === PROPERTY_NOT_TRADABLE_ERROR) {
                    suspendedPropertyDialogService.show(true)
                        .then(function () {
                            $window.location.reload();
                        }, angular.noop);
                }

                if (error.reason === PROPERTY_INSUFFICIENT_HOLDINGS_ERROR) {
                    investorService.purgeCache('investor.holdings.' + vm.property.symbol);
                    // resolve and reject with error
                    var rejection = $q.reject.bind(this, error);
                    return init().then(rejection, rejection);
                }

                ppTrack.formError(PAGE_NAME, error.reason);

                return $q.reject(error);
            });
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            property = vm.propertyData || {};
            symbol = property.symbol || ppUtil.getDataFromUrl($window.location.href, 'properties');
            init();
        };
    }]
});
})(window, window.angular);