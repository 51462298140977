(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.input-date', []);
ngModule.service('inputDateService', function () {

    var api = {};

    var days = [];
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // -- util functions

    function populateDays() {
        for (var ix = 1; ix <= 31; ix++) {
            days.push({
                value: ix,
                text: String(ix)
            });
        }
    }

    function populateMonths() {
        for (var ix = 0; ix < months.length; ix++) {
            months[ix] = {
                value: api.stringifyMonth(ix),
                text: months[ix]
            };
        }
    }

    // -- api

    //check if date is valid
    api.isDate = function (year, month, day) {
        var newDate = new Date(year, month - 1, day);
        var date = Number(day) + '/' + Number(month) + '/' + Number(year);
        return (date === (newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear()));
    };

    api.stringifyMonth = function (month) {
        var monthString = (month + 1).toString();
        return (monthString.length > 1) ? monthString : '0' + monthString;
    };

    // do no use this within a digest cycle
    api.getDays = function () {
        return angular.copy(days);
    };

    // do no use this within a digest cycle
    api.getMonths = function () {
        return angular.copy(months);
    };

    // do no use this within a digest cycle
    api.getYears = function (from, to) {
        var years = [];

        var reversed = from > to;

        if (reversed) {
            var temp = from;
            from = to;
            to = temp;
        }

        for (var ix = from; ix <= to; ix++) {
            years.push({
                value: ix,
                text: String(ix)
            });
        }
        if (reversed) {
            years.reverse();
        }
        return years;
    };

    // -- main

    populateDays();
    populateMonths();

    return api;
});
})(window, window.angular);