(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.take-time-to-learn-more', [
    'pp.widgets-templates',
    'pp.services.core'
]);

ngModule.component('ppTakeTimeToLearnMore', {
    templateUrl: 'widgets/_angular/take-time-to-learn-more/take-time-to-learn-more.tpl.html',
    bindings: {
        investor: '<',
        onContinue: '&',
        onCloseAccount: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$anchorScroll', 'ppUtil', function ($scope, $anchorScroll, ppUtil) {
        var vm = this;

        // -- initial state
        vm.keyRisksStep = false;

        vm.scrollId = ppUtil.uuid();

        // -- util functions

        function scrollToTop() {
            $anchorScroll.yOffset = 200;
            $anchorScroll(vm.scrollId);
            $scope.$emit('auth-modal.scroll-top');
        }

        // -- api

        vm.moveToKeyRisksStep = function () {
            vm.keyRisksStep = true;
        };

        vm.continue = function () {
            vm.processing = true;
            vm.error = false;
            vm.onContinue().then(function () {
                scrollToTop();
            }, function () {
                vm.error = true;
                vm.processing = false;
            });
        };

        vm.continueToCloseAccount = function () {
            scrollToTop();
            vm.confirmCloseAccountStep = true;

        };

        vm.backFromCloseAccount = function () {
            scrollToTop();
            vm.confirmCloseAccountStep = false;
        };

        vm.confirmCloseAccount = function () {
            vm.processing = true;
            vm.error = false;
            vm.onCloseAccount().then(function () {
                vm.processing = false;
            }, function () {
                vm.error = true;
                vm.processing = false;
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);