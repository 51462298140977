(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketplace-grid-view', [
    'infinite-scroll',
    'pp.widgets-templates',
    'pp.services.marketplace',
    'pp.services.core',
    'pp.widgets.property-card-list',
    'pp.widgets.placeholder-promotion',
    'pp.components.input-search',
    'pp.widgets.marketplace-no-results',
    'pp.widgets.loading-card'
]);

var DEFAULT_FILTER = 'uk';
var CARD_RENDER_BATCH_SIZE = 6;
var LOADING_CARD_COUNT = 200;
var SEARCH_TIMEOUT_DEBOUNCE = 1000;

var GRID_VIEW_STATE_NAME = 'marketplace.grid';

ngModule.component('ppMarketplaceGridView', {
    templateUrl: 'widgets/_angular/marketplace-grid-view/marketplace-grid-view.tpl.html',
    bindings: {
        properties: '<',
        searchResultCount: '@',
        filterOptions: '<',
        sortOptions: '<',
        optionsReady: '<',
        onUpdate: '&',
        loading: '<',
        apoFunds: '<',
        bondCardConfig: '<',
        holdings: '<',
        bids: '<',
        offers: '<',
        sort: '<',
        filter: '<',
        search: '<',
        ready: '<',
        user: '<',
        newCards: '<',
        viewPropertyDetail: '&'
    },
    transclude: true,
    controllerAs: 'vm',
    controller: ['$scope', '$window', '$timeout', '$element', 'ppTrack', function ($scope, $window, $timeout, $element, ppTrack) {
        var vm = this;

        // -- initial state

        vm.defaultFilter = DEFAULT_FILTER;

        // -- util functions

        function getBottomEdge(el) {
            return $(el).scrollTop() + $(el).height();
        }

        function getTopEdge(el) {
            return $(el).offset().top;
        }

        function getNumberOfPropertiesInScrollPosition() {
            var loadingCards = $element.find('.loading-card-wrapper');
            var bottomEdgeOfWindow = getBottomEdge($window);

            var number = 0;
            angular.forEach(loadingCards, function (item) {
                var topEdge = getTopEdge(item);
                if (topEdge >= bottomEdgeOfWindow) {
                    return number;
                } else {
                    number++;
                }
            });

            return number;

        }

        function initPropertiesInView(properties) {
            vm.propertiesInView = properties.slice(0, getNumberOfPropertiesInScrollPosition() + CARD_RENDER_BATCH_SIZE);
        }

        // -- api

        vm.marketplaceChange = function (type) {

            var params = {};
            vm.loading = true;

            if (vm.filter) {
                params.filter = angular.copy(vm.filter);
            }

            if (vm.sort) {
                params.sort = angular.copy(vm.sort);
            }

            ppTrack.setContext('marketplace.sort', vm.sort);
            ppTrack.setContext('marketplace.filter', vm.filter);

            ppTrack.action('marketplace.' + type, {
                sort: vm.sort,
                filter: vm.filter,
                search: vm.search
            });

            return vm.onUpdate({
                params: params,
                search: angular.copy(vm.search)
            }).then(function (properties) {
                initPropertiesInView(properties);
                vm.loading = false;
            });

        };

        vm.clearFilter = function () {
            vm.filter = DEFAULT_FILTER;
            vm.marketplaceChange('filter');
        };

        vm.isMoreToLoad = function () {
            if (!angular.isArray(vm.propertiesInView) || !angular.isArray(vm.properties)) {
                return true;
            }
            return vm.propertiesInView.length < vm.properties.length;

        };

        vm.loadMoreProperties = function () {
            if (vm.isMoreToLoad() && angular.isArray(vm.propertiesInView) && angular.isArray(vm.properties)) {
                vm.propertiesInView = vm.properties.slice(0, vm.propertiesInView.length + CARD_RENDER_BATCH_SIZE);
            }
        };

        vm.clearSearch = function () {
            vm.search = null;
            $scope.$broadcast('marketplace.search.focus');
        };

        // helper for ng-repeat on card-loading directive
        vm.counter = function (n) {
            if (n) {
                return new Array(n);
            }
        };

        vm.onViewPropertyDetail = function (property) {
            vm.viewPropertyDetail({
                property: property
            });
        };

        // -- scope bindings

        vm.$onInit = function () {
            vm.filterOptions = vm.filterOptions || [];
            vm.loadingInitialCardCount = LOADING_CARD_COUNT;
            vm.loadingCardCount = CARD_RENDER_BATCH_SIZE;
            var searchTimeout;
            vm.propertiesInView = [];

            vm.loading = true;

            $scope.$watch('vm.search', function (search, oldSearch) {

                if (search !== oldSearch) {

                    vm.loading = true;

                    if (searchTimeout) {
                        $timeout.cancel(searchTimeout);
                    }

                    searchTimeout = $timeout(function () {
                        vm.marketplaceChange('search').then(function () {
                            vm.loading = false;
                        });
                    }, SEARCH_TIMEOUT_DEBOUNCE);
                }
            });

            $scope.$watch('vm.ready', function (ready) {
                if (ready && vm.properties) {
                    initPropertiesInView(vm.properties);
                    vm.loading = false;
                }
            });
        };

        // -- main

    }]
});
})(window, window.angular);