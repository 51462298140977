(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.live-ticker', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.ui.services.live-marketplace-details-dialog',
    'pp.filters.numbers'
]);

var TICKER_DURATION_NUMERATOR_MS = 25000;
var TICKER_DURATION_NUMERATOR_MIN_LIMIT_MS = 10000;
var BASE_PAGE_WIDTH = 1296;
var TICKER_TIMEOUT_BEFORE_BIND = 400;
var TICKER_ELEMENT_ID = '#pp-live-marquee';

ngModule.directive('ppLiveTicker', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/live-ticker/live-ticker.tpl.html',
        scope: {
            trades: '<',
            viewDetail: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', '$timeout', '$window', 'ppBrowser', 'ppTrack', function ($scope, $element, $timeout, $window, ppBrowser, ppTrack) {
            var vm = this;

            // -- initial state

            // -- util functions

            // - data and polling

            // -- api

            function getTickerDuration() {
                var __windowWidth = $window.innerWidth || 700;
                var __tickerDuration = Math.min((__windowWidth / BASE_PAGE_WIDTH) * TICKER_DURATION_NUMERATOR_MS, TICKER_DURATION_NUMERATOR_MS);
                return Math.max(__tickerDuration, TICKER_DURATION_NUMERATOR_MIN_LIMIT_MS);
            }

            function attachTicker() {
                $($element.find(TICKER_ELEMENT_ID)).marquee({
                    duration: getTickerDuration(),
                    direction: 'left',
                    duplicated: true,
                    pauseOnHover: !ppBrowser.isMobile
                });
            }

            // when it becomes ready

            vm.propertyLinkClick = function (symbol) {
                ppTrack.deferAction('live-ticker.click', {
                    symbol: symbol
                });

                vm.viewDetail({
                    symbol: symbol
                });
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                vm.tradesCopy = angular.copy(vm.trades);
            };

            vm.$postLink = function () {
                $timeout(attachTicker, TICKER_TIMEOUT_BEFORE_BIND);
            };

            vm.ready = true;
        }]
    };
}]);
})(window, window.angular);