(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.team', ['pp.services.core', 'pp.services.auth', 'pp.services.investor', 'pp.widgets-templates', 'pp.widgets.team-member', 'pp.values.leadership-team', 'pp.widgets.backers']);
var TEAM_PAGE = 'about.team';

ngModule.component('ppTeam', {
    templateUrl: 'widgets/_angular/team/team.tpl.html',
    bindings: {
        user: '<'
    },
    transclude: true,
    controllerAs: 'vm',
    controller: ['$scope', 'team', 'authService', 'ppTrack', 'ppPubSub', function ($scope, team, authService, ppTrack, ppPubSub) {

        // -- initial state
        var vm = this;

        // -- util functions

        // -- api
        vm.submitEmailSignup = function (email) {
            vm.processing = true;
            authService.emailSignup(email).finally(function () {
                vm.processing = false;
                ppPubSub.publish('auth.modal.show', ['signup', null, null, null, null, email]);
            });
        };

        // -- scope bindings

        // -- main

        vm.$onDestroy = function () {
            ppTrack.pageClose(TEAM_PAGE);
        };

        vm.$onInit = function () {
            vm.team = team;
            ppTrack.pageLoad(TEAM_PAGE);
        };

    }]
});
})(window, window.angular);