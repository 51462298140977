(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.fund-receipt', ['pp.widgets-templates', 'pp.filters.numbers']);

/**
 * @ngdoc directive
 * @name ppFundReceiptDialog
 * @description
 * Renders the funding receipt dialog
 *
 * @restrict A
 * @scope
 * @param {obj} receipt
 */

ngModule.directive('ppFundReceipt', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/fund-receipt/fund-receipt.tpl.html',
        scope: {
            receipt: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- api

            // -- main

        }]
    };
});
})(window, window.angular);