(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.input-copy', [
    'pp.components-templates'
]);

ngModule.component('ppInputCopy', {
    templateUrl: 'components/_angular/input-copy/input-copy.tpl.html',
    bindings: {
        ppValue: '<',
        onCopy: '&?'
    },
    controllerAs: 'vm',
    controller: ['$element', '$timeout', function ($element, $timeout) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.copyToClipboard = function () {

            if (vm.onCopy) {
                vm.onCopy();
            }

            var el = $element.find('#textarea-to-copy')[0];
            el.select();
            document.execCommand('copy');
            vm.copied = true;

            $timeout(function () {
                vm.copied = false;
            }, 3000);
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);