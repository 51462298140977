(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.toggle-on-off', ['pp.components-templates']);

/**
 * @ngdoc directive
 * @name toggleOnOff
 * @description
 * An IOS style switch that animates on and off and exposes wether it is set to on or off through a variable.
 *
 * @restrict A
 * @scope
 * @param {boolean} value
 */
ngModule.directive('ppToggleOnOff', [function () {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/toggle-on-off/toggle-on-off.tpl.html',
        scope: {
            value: '<ppToggleOnOff',
            update: '&',
            updating: '<',
            onWord: '<',
            offWord: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;
        }]
    };
}]);
})(window, window.angular);