(function(window, angular){'use strict';

var ngModule = angular.module('pp.apps.investment-plans', [
    'ui.router',
    'pp.services.run',
    'pp.ui.services.cookie-banner-dialog',
    'pp.ui.services.required-user-data-dialog',
    'pp.ui.services.investment-plans-closed-period-warning-dialog',
    'pp.widgets.auth-modal',
    'pp.widgets.auth-nav-mobile',
    'pp.widgets.header',
    'pp.widgets.footer',
    'pp.widgets.investment-plan-commitment',
    'pp.widgets.investment-plan-overview',
    'pp.widgets.investment-plan-detail',
    'pp.widgets.referral-banner',
    'pp.ui.services.authenticate-investor',
    'pp.ui.services.classification-dialog',
    'pp.ui.services.restricted-investor-dialog',
    'pp.services.route',
    'pp.services.receipt',
    'pp.services.investor',
    'pp.ui.services.risk-warning'
]);

ngModule.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', function ($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('investment-plans', {
            url: '/',
            redirectTo: {
                state: 'investment-plan.detail',
                params: {
                    investmentPlan: 'all_share'
                }
            },
            component: 'ppInvestmentPlanOverview',
            resolve: {
                user: ['investorService', function (investorService) {
                    return investorService.getInvestor();
                }]
            }
        })
        .state('investment-plan', {
            url: '/{investmentPlan:(?:all_share)}',
            abstract: true,
            resolve: {
                investmentPlan: ['$transition$', function ($transition$) {
                    return $transition$.params().investmentPlan;
                }],
                receipt: ['receiptService', function (receiptService) {
                    return receiptService.checkForReceipts();
                }]
            }
        })
        .state('investment-plan.detail', {
            url: '',
            component: 'ppInvestmentPlanDetail',
            resolve: {
                user: ['investorService', function (investorService) {
                    return investorService.getInvestor();
                }]
            }
        })
        .state('investment-plan.commit', {
            url: '/commit',
            redirectTo: {
                state: 'investment-plan.detail',
                params: {
                    investmentPlan: 'all_share'
                }
            },
            // component: 'ppInvestmentPlanCommitment',
            // resolve: {
            //     user: ['$q', '$state', 'authenticateInvestor', 'classificationDialogService', 'routeService', 'investorService', 'restrictedInvestorDialog', 'ppInvestmentPlansClosedPeriodWarningDialog', function ($q, $state, authenticateInvestor, classificationDialogService, routeService, investorService, restrictedInvestorDialog, ppInvestmentPlansClosedPeriodWarningDialog) {
            //         return authenticateInvestor()
            //             .then(function (user) {
            //                 if (user.anon) {
            //                     return $q.reject(user);
            //                 }
            //                 //return ppInvestmentPlansClosedPeriodWarningDialog.show().then(function () {

            //                 return classificationDialogService.classifyUser(user, routeService.goToAccountSettings).then(function (user) {}).catch(function () {
            //                     $state.go('investment-plans');
            //                 });

            //             }, function () {
            //                 $state.go('investment-plans');
            //             });
            //     }]
            // }
        });

    $urlRouterProvider.otherwise('/');

    $locationProvider.hashPrefix('');

}]);

ngModule.run(['$rootScope', '$anchorScroll', 'runService', 'requiredUserDataDialogService', 'cookieBannerDialogService', 'riskWarningService', function ($rootScope, $anchorScroll, runService, requiredUserDataDialogService, cookieBannerDialogService, riskWarningService) {
    runService.init(requiredUserDataDialogService, cookieBannerDialogService);

    $rootScope.$on('$locationChangeSuccess', function () {
        $anchorScroll();
    });

    riskWarningService.showMainRiskWarning();

}]);
})(window, window.angular);