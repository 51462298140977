(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investor-menu', [
    'pp.widgets-templates',
    'pp.components.list-menu',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.route'
]);

/**
 * @ngdoc directive
 * @name ppInvestorMenu
 * @description
 * Renders the dashboard menu
 *
 * @restrict A
 * @scope
 */

ngModule.component('ppInvestorMenu', {
    templateUrl: 'widgets/_angular/investor-menu/investor-menu.tpl.html',
    bindings: {
        showLogout: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', 'ppTrack', 'investorService', 'routeService', function ($scope, $window, ppTrack, investorService, routeService) {
        var vm = this;

        // -- initial state

        vm.menu = [{
            href: routeService.accountSettingsPersonalDetailsPath,
            label: 'Settings'
        }];

        // -- util functions

        function loadUser() {
            investorService.getInvestor().then(function (investor) {
                if (investor.anon) {
                    return;
                }

                vm.ready = true;
            });
        }

        // -- api

        vm.logout = function () {
            ppTrack.deferAction('desktop.logout.click'); // @todo deprecate
            ppTrack.deferAction('menu.logout.click');
            $window.location.href = routeService.logoutPath;
        };

        // -- scope bindings

        // -- main

        loadUser();

    }]
});
})(window, window.angular);