(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.invest-dialog-holding-exceeded', [
    'pp.widgets-templates',
    'pp.services.core'
]);

var DASHBOARD_URL = '/my-portfolio#/dashboard';

ngModule.component('ppInvestDialogHoldingExceeded', {
    templateUrl: 'widgets/_angular/invest-dialog-holding-exceeded/invest-dialog-holding-exceeded.tpl.html',
    bindings: {
        pageName: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', function ($scope, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions
        function redirect(href) {
            ppTrack.closeAll().then(function () {
                window.location.href = href;
            });
        }

        // -- api
        vm.retry = function () {
            ppTrack.pageAction(vm.pageName, 'holdingExceeded.retry');
            $scope.$parent.dialogDefer.resolve();
        };

        vm.exitDashboard = function () {
            ppTrack.pageAction(vm.pageName, 'holdingExceeded.exitDashboard');
            redirect(DASHBOARD_URL);
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);