(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.trust-hooks-lp', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.route',
    'pp.widgets.trust-pilot-trust-hook'
]);

ngModule.component('ppTrustHooksLp', {
    templateUrl: 'widgets/_angular/trust-hooks-lp/trust-hooks-lp.tpl.html',
    bindings: {
        assetsUnderManagement: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', 'routeService', function ($scope, ppConfig, routeService) {
        var vm = this;

        // -- initial state

        var __endpoints = ppConfig.get('pp.external.endpoints') || {};
        var assetsEndpoint = __endpoints.assets;

        vm.awardsWreathIcon = assetsEndpoint + '/images/homepage/awards-wreath.svg';
        vm.trustPilotIcon = assetsEndpoint + '/images/homepage/trustpilot_brandmark_stars.svg';
        vm.fcaImageIcon = assetsEndpoint + '/images/homepage/logo-fca-clipboard.svg';
        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);