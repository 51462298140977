(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.premium-services-banner', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.route',
    'pp.ui.services.schedule-call-dialog'
]);

ngModule.component('ppPremiumServicesBanner', {
    templateUrl: 'widgets/_angular/premium-services-banner/premium-services-banner.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$element', 'ppComponentEmitter', 'routeService', 'scheduleCallDialogService', function ($scope, $element, ppComponentEmitter, routeService, scheduleCallDialogService) {
        var vm = this;

        // -- initial state

        vm.contactUsPath = routeService.contactUsPath;

        // -- util functions

        // -- api

        vm.scheduleACallPremiumServices = function () {
            scheduleCallDialogService.show();
        };

        // -- scope bindings

        vm.$postLink = function () {
            ppComponentEmitter.emit('premium-services.dom.loaded', $element);
        };

        // -- main

    }]
});
})(window, window.angular);