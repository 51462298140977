(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.advance-pre-order-card', [
    'pp.widgets.investment-card-form',
    'pp.widgets-templates',
    'pp.components.input-amount',
    'pp.components.tooltip',
    'pp.components.checkbox',
    'pp.widgets.bond-card',
    'pp.services.core',
    'pp.services.apo',
    'pp.services.investor',
    'pp.services.preference',
    'pp.ui.services.required-user-data-dialog',
    'pp.ui.services.classification-dialog',
    'pp.ui.services.restricted-investor-dialog',
    'pp.values.glossary',
    'pp.filters.numbers'
]);

var PAGE_NAME = 'advance-pre-order';
var CURRENCY_GBP = 'GBP';
var INVESTOR_CACHE_KEY = 'investor';
var TRANSITION_DURATION_MS = 400;
var MIN_FUNDING = 1000;

var ASSET_TYPE_MAP = {
    student: 'student',
    development_loan: 'development_loan',
    residential: 'residential',
    commercial: 'commercial'
};

ngModule.directive('ppAdvancePreOrderCard', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/advance-pre-order-card/advance-pre-order-card.tpl.html',
        scope: {
            user: '<',
            reservedFunds: '<',
            bondConfig: '<',
            type: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', '$timeout', '$q', 'glossary', 'ppPubSub', 'ppTrack', 'apoService', 'investorService', 'preferenceService', 'requiredUserDataDialogService', 'classificationDialogService', 'restrictedInvestorDialog', function ($scope, $element, $timeout, $q, glossary, ppPubSub, ppTrack, apoService, investorService, preferenceService, requiredUserDataDialogService, classificationDialogService, restrictedInvestorDialog) {
            var vm = this;

            // -- initial state
            vm.formView = false;
            vm.confirmationView = false;
            vm.showShadow = false;
            vm.minFunding = MIN_FUNDING;

            // -- util functions

            function refreshInvestor() {
                investorService.purgeCache(INVESTOR_CACHE_KEY);
                return investorService.getInvestor();
            }

            function handleSuccess() {
                ppTrack.event(PAGE_NAME + '.invest.success', {
                    amount: vm.amount
                });

                vm.confirmationView = true;

                //refresh investor available funds - triggers parent listener
                refreshInvestor();
            }

            function handleFailure(err) {
                ppTrack.event(PAGE_NAME + '.invest.error');
                return $q.reject(err);
            }

            function doAfterTransition(callback) {
                $timeout(function () {
                    callback();
                }, TRANSITION_DURATION_MS);
            }

            function ensureUserLoggedIn() {
                if (!vm.user.anon) {
                    return $q.when(true);
                }

                var deferred = $q.defer();

                ppTrack.pageAction(PAGE_NAME, 'login.show');
                ppPubSub.publish('auth.modal.show', ['login', function () {
                    refreshInvestor().then(function () {
                        $.modal.close();
                        deferred.resolve(true);
                    });
                }]);

                return deferred.promise;
            }

            function ensureGoodReputeAgreed() {
                return preferenceService.hasInvestorAgreedToGoodReputeStatement()
                    .then(function (goodReputeAgreed) {
                        if (goodReputeAgreed) {
                            return true;
                        }
                        var deferred = $q.defer();
                        requiredUserDataDialogService.show(vm.user, goodReputeAgreed, function () {
                            requiredUserDataDialogService.close();
                            deferred.resolve(true);
                        });
                        return deferred.promise;

                    });
            }

            function ensureUserClassified() {
                if (investorService.isClassifiedUser(vm.user)) {
                    return $q.when(true);
                }
                var deferred = $q.defer();

                classificationDialogService.show(vm.user, function () {
                    deferred.reject(); // failure
                }, function () {
                    refreshInvestor() // success
                        .then(classificationDialogService.close())
                        .then(function () {
                            deferred.resolve(true);
                        });
                }, function () {
                    refreshInvestor() // dismiss
                        .then(function () {
                            deferred.reject();
                        });
                });

                return deferred.promise;
            }

            function ensureUserNotRetail() {
                if (investorService.isRestrictedInvestor(vm.user)) {
                    return restrictedInvestorDialog.reclassifyUserForBond(vm.user);
                } else {
                    return $q.when();
                }
            }

            // -- api

            vm.goToForm = function () {
                vm.agree = false;
                ppTrack.action(PAGE_NAME + '.show-apo-form');

                ensureUserLoggedIn()
                    .then(ensureGoodReputeAgreed)
                    .then(ensureUserClassified)
                    .then(ensureUserNotRetail)
                    .then(function () {
                        vm.confirmationView = false;
                        vm.formView = true;
                        vm.showShadow = true;

                        doAfterTransition(function () {
                            vm.showShadow = false;
                        });
                    });
            };

            vm.close = function () {
                ppTrack.action(PAGE_NAME + '.close');

                //when going from slide 3 to 1, we don't want to show slide 2 on the transition
                if (vm.confirmationView) {
                    vm.skipFormTransition = true;
                    doAfterTransition(function () {
                        vm.skipFormTransition = false;
                    });
                }

                vm.formView = false;
                vm.confirmationView = false;
                vm.showShadow = true;

                doAfterTransition(function () {
                    // input type number does not update viewValues when number invalid (ex: 0..3)
                    $element.find('input[type=number]').val('');
                    vm.amount = null;
                    vm.feedbackEnabled = false;
                    vm.showShadow = false;
                });
            };

            vm.submit = function (amount) {
                var data = {
                    currencyCode: CURRENCY_GBP,
                    assetType: ASSET_TYPE_MAP[vm.type],
                    amountCommitted: amount
                };

                return apoService.setAdvancedPreOrderForInvestor(data).then(handleSuccess, handleFailure);
            };

            vm.trackKeyRisksClick = function () {
                ppTrack.action('advance-pre-order.key-risks.click');
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);