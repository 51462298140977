(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.window', []);

/**
 * @ngdoc service
 * @name windowService
 *
 * @description
 * Provides methods to invoke the `/window` endpoints.
 */
ngModule.service('windowService', ['$window', function ($window) {

    var api = {};

    // workaround for IE10 not supporting window.location.origin
    api.getOrigin = function () {
        if ($window.location.origin) {
            return $window.location.origin;
        }
        return $window.location.protocol + '//' + $window.location.hostname + ($window.location.port ? ':' + $window.location.port : '');
    };

    return api;
}]);
})(window, window.angular);