(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.gbGroup', ['pp.services.core', 'pp.services.investor']);

var ADDRESS_LOOKUP_ENDPOINT = '/lookup/address';
var KYC_INDIVIDUAL_ENDPOINT = '/investor/kyc-primary';
var KYC_BUSINESS_ENDPOINT = '/investor/kyc-business';
var KYC_SECONDARY_ENDPOINT = '/investor/kyc-secondary';

var POSTCODE_REGEXP = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/i;

/**
 * @ngdoc service
 * @name gbGroupService
 *
 * @description
 * Provides methods to invoke the GB Group endpoints.
 */
ngModule.service('gbGroupService', ['$http', '$q', 'investorService', 'ppUtil', function ($http, $q, investorService, ppUtil) {

    var promiseCache = {};

    var api = {};

    function formatDate(date) {
        return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
    }

    function wordWithComma(word) {
        return word ? word + ', ' : '';
    }

    /**
     * @param {string} address
     * @returns {string}
     */
    function addAddressSentence(address) {
        var sentence = wordWithComma(address.lineOne) + wordWithComma(address.lineTwo) + wordWithComma(address.lineThree);

        return ppUtil.extendHash({
            sentence: sentence.substring(0, sentence.length - 2)
        }, address);
    }

    /**
     * @ngdoc method
     * @name gbGroupService#addressLookup
     *
     * @description
     * Given one `postcode` and `country` code greenwhich`) returns a promise that resolves with a list of addresses.
     * Decorates all addresses with a `sentence` property for ease of use in.
     *
     * ```
     * [
     *    {
     *      "street": "Fore Street",
     *      "buildingNumber": "1",
     *      ...
     *      "sentence": "1 Fore Street"
     *    }, ...
     * ]
     * ```
     *
     * Promises are cached, only one request ever made per area.
     * Invalid postcodes are validated before the request and the rejection is also cached.
     * Unexpected errors are not cached so once a promise is rejected due to an unexpected error, a subsquent call
     * to this method will originate a new request.
     *
     * @param {string} postcode
     * @param {string} country
     * @returns {Promise}
     */
    api.addressLookup = function (postcode, country) {

        var endpoint = ADDRESS_LOOKUP_ENDPOINT;
        var cacheKey = 'address.' + country + '.' + postcode;

        var payload = {
            postOrZipCode: postcode.toUpperCase(),
            countryCode: country
        };

        if (!promiseCache[cacheKey]) {

            var defer = $q.defer();
            promiseCache[cacheKey] = defer.promise;

            // validate post code first to save money (and cache the rejection)
            if (!api.validatePostcode(postcode)) {
                defer.reject({
                    reason: 'gbGroup.error.postcode.invalid'
                });
            } else {
                $http.post(endpoint, payload).then(function (response) {
                    var data = response.data || [];
                    // decorate addresses with `sentence` attribute
                    if (data.length) {
                        defer.resolve(data.map(addAddressSentence));
                    }
                    // transform
                    else if (!data.length) {
                        defer.reject({
                            reason: 'gbGroup.error.noMatches'
                        });
                    } else {
                        delete promiseCache[cacheKey];
                        defer.reject({
                            reason: 'gbGroup.error.unexpected'
                        });
                    }
                }, function () {
                    delete promiseCache[cacheKey];
                    defer.reject({
                        reason: 'gbGroup.error.unexpected'
                    });
                });
            }
        }
        return promiseCache[cacheKey];
    };

    /**
     * @ngdoc method
     * @name gbGroupService#submitKyc
     *
     * @description
     * Submits primary or business kyc data to the gbGroup service.
     *
     * @param {string} type
     * @param {object} data
     * @returns {Promise}
     */
    api.submitKyc = function (type, data) {

        var endpoint = type === 'individual' ? KYC_INDIVIDUAL_ENDPOINT : KYC_BUSINESS_ENDPOINT;

        data.gender = 'UNKNOWN';

        // @todo post-conversion-phase-2 over zealous endpoint
        var key;
        var ix;
        if (data.address) {
            for (key in data.address) {
                if (!data.address[key]) {
                    delete data.address[key];
                }
            }
        }

        return $http.put(endpoint, data).then(function (response) {
            investorService.purgeCache('^investor$');
            return true;
        }, function (error) {
            return $q.reject({
                reason: 'gbGroup.error.unexpected'
            });
        });
    };

    /**
     * @ngdoc method
     * @name gbGroupService#submitKycSecondary
     *
     * @description
     * Submits secondary kyc data to the gbGroup service.
     *
     * @param {string} type
     * @param {object} data
     * @returns {Promise}
     */
    api.submitKycSecondary = function (data) {

        var endpoint = KYC_SECONDARY_ENDPOINT;

        return $http.put(endpoint, {
            documents: data
        }).then(function (response) {
            return true;
        }, function (error) {
            if (error.data === 'file.max-size-exceeded') {
                return $q.reject({
                    reason: 'gbGroup.error.max-size-exceeded'
                });
            }
            return $q.reject({
                reason: 'gbGroup.error.unexpected'
            });
        });
    };

    /**
     * Validates a postcode
     *
     * @param {string} postcode
     * @returns {boolean}
     */
    api.validatePostcode = function (postcode) {
        return POSTCODE_REGEXP.test(postcode);
    };

    /**
     * exposes the postcode regexp
     *
     * @property {string}
     */
    api.postCodeRegexp = POSTCODE_REGEXP;
    api._addSentence = addAddressSentence;

    return api;
}]);
})(window, window.angular);