(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.auth-nav-dropdown', [
    'pp.services.core',
    'pp.widgets-templates',
    'pp.widgets.investor-funds',
    'pp.widgets.investor-menu',
    'pp.services.route'
]);

/**
 * @ngdoc directive
 * @name ppAuthNavDropdown
 * @description
 * Renders the dropdown with invesor-menu available funds
 *
 * @restrict A
 * @scope
 */

ngModule.directive('ppAuthNavDropdown', ['ppPubSub', function (ppPubSub) {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/auth-nav-dropdown/auth-nav-dropdown.tpl.html',
        scope: {
            user: '<ppAuthNavDropdown',
            users: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$window', '$scope', 'ppTrack', 'routeService', function ($window, $scope, ppTrack, routeService) {
            var vm = this;

            // -- initial state

            vm.fundPath = routeService.fundPath;

            // -- util functions

            function asyncHide() {

                if (vm.isMouseover) {
                    return;
                }

                $scope.$evalAsync(function () {
                    vm.show = false;
                });
            }

            function documentClick($ev) {
                if (!$($ev.target).hasClass('toggle') && !$($ev.target).closest('.toggle').length) {
                    asyncHide();
                }
            }

            function documentKeyDown(ev) {
                var key = ev.keyCode || ev.which;
                if (key === 27) {
                    asyncHide();
                }
            }

            // -- api

            vm.setMouseover = function () {
                vm.isMouseover = true;
            };

            vm.setMouseout = function () {
                vm.isMouseover = false;
            };

            vm.toggle = function ($ev) {
                // we can't stop propgration here, otherwise components like tooltips will not hide/collapse
                // documentClick() will chek for event target instead
                // $ev.stopPropagation();
                vm.show = !vm.show;
            };

            // -- scope bindings

            $scope.$on('list-menu-item.click', function (ev, label) {
                ppTrack.deferAction('desktop.dropdown-nav.' + label + '.click');
            });

            $scope.$on('$destroy', function () {
                $window.document.removeEventListener('click', documentClick);
                $window.document.removeEventListener('keydown', documentKeyDown);
            });

            ppPubSub.subscribe('pp.site-header.hide', asyncHide);

            // -- main

            $window.document.addEventListener('click', documentClick);
            $window.document.addEventListener('keydown', documentKeyDown);
        }]
    };
}]);
})(window, window.angular);