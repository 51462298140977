(function(window, angular){'use strict';

var ngModule = angular.module('pp.controllers.settings', [
    'pp.services.core',
    'pp.services.investor',
    'pp.services.investor-settings',
    'pp.services.nationality',
    'pp.services.investor-compliance',
    'pp.services.pin',
    'pp.services.auth',
    'pp.services.mfa',
    'pp.ui.services.classification-dialog',
    'pp.widgets.investor-contact-details-form',
    'pp.widgets.investor-address-form',
    'pp.widgets.investor-change-password-form',
    'pp.widgets.settings-pin-change',
    'pp.widgets.lei-validation-message',
    'pp.components.password-validation',
    'pp.components.match-against',
    'pp.components.white-space-only-validation',
    'pp.widgets.mfa-settings',
    'monospaced.qrcode',
    'pp.values.regex'
]);

/**
 * controller for the settings form
 */

var PIN_FORM_NAME = 'settings.security.pin-change';
var CONTACT_DETAILS_FORM_NAME = 'settings.contact-details';
var ADDRESS_FORM_NAME = 'settings.address';

var UPDATED_HIDE_DELAY = 5000;

ngModule.controller('settingsCtrl', ['$scope', '$window', '$timeout', '$q', '$transitions', '$state', 'ppTrack', 'investorService', 'investorSettingsService', 'authService', 'mfaService', 'nationalityService', 'investorComplianceService', 'pinService', 'classificationDialogService', 'leiRegex', 'ppMoment', 'R', 'investorComplianceValues', function ($scope, $window, $timeout, $q, $transitions, $state, ppTrack, investorService, investorSettingsService, authService, mfaService, nationalityService, investorComplianceService, pinService, classificationDialogService, leiRegex, ppMoment, R, investorComplianceValues) {

    //-- initial state

    var vm = this;

    vm.emailRegexp = authService.emailRegexp;
    vm.state = $state.current.name;
    vm.leiRegex = leiRegex;

    var cachedContactDetails;

    //-- util functions

    function reloadPage() {
        $window.location.reload();
    }

    function setupUser(user) {
        if (user.anon) {
            reloadPage();
            return;
        }

        vm.user = angular.copy(user);
        cachedContactDetails = angular.copy(user);
        vm.personal = angular.copy(user);

        if (user.classification) {
            vm.personal.classificationLabel = investorService.getClassificationMappedValue(user.classification, 'label') + ' Investor';
        }
    }

    function setupAddress() {
        // always resolve promise because backend returns a 404 when no address is set
        return investorSettingsService.getAddress().then(function (address) {
            vm.address = angular.copy(address);
        }, angular.noop);
    }

    function setupAuth(methods) {
        vm.mfa = methods.indexOf('TwoFactor') > -1;
    }

    function setupNationalityAndIdentifiers() {

        var promises = [
            nationalityService.constructInvestorNationalitiesAndIdentifiersForForm(),
            nationalityService.getNationalityList()
        ];

        return $q.all(promises).then(function (responses) {
            vm.nationalityData = responses[0];
            vm.nationalitiesList = responses[1];
            vm.nationalityDataSet = nationalityService.isNationalityDataSet(responses[0]);
            return;
        });

    }

    function setupLei() {
        var leiTypePromise = investorComplianceService.getLeiType();
        return leiTypePromise.then(function (leiType) {
                vm.leiType = leiType;
                return investorComplianceService.getLei(leiType);
            })
            .then(function (lei) {
                if (lei) {
                    var leiValue = R.prop('identifierValue', lei);
                    vm.lei = leiValue;
                    vm.isLeiExpired = investorComplianceService.isLeiExpired(lei);
                    vm.isLeiValid = investorComplianceService.isLeiValid(leiValue);
                    vm.leiSet = true;
                }
            });
    }

    function handleSuccess(formName) {
        ppTrack.formSubmit(formName);
        vm.updated = true;
        vm.processingError = false;
        cachedContactDetails = angular.copy(vm.personal);

        $timeout(function () {
            vm.updated = false;
        }, UPDATED_HIDE_DELAY);
    }

    function handleFailure(error, formName) {
        ppTrack.formError(formName, error.reason);
        vm.processingError = true;
    }

    function handleFinally() {
        vm.processing = false;
    }

    vm.onChangeLeiCopy = function () {
        vm.identifierError = undefined;
    };

    vm.refreshLeiExpiry = function (lei) {
        vm.processing = true;
        vm.identifierError = undefined;
        return investorComplianceService.checkLeiValidity(lei).then(function (res) {
                if (res.validity === investorComplianceValues.LEI_ISSUED) {
                    return investorComplianceService.updateLei(vm.leiType.id, lei);
                } else {
                    vm.identifierError = investorComplianceService.createLeiRejectionReason(res.validity);
                    return $q.reject({
                        reason: investorComplianceService.createLeiRejectionReason(res.validity)
                    });
                }
            }, function (err) {
                vm.identifierError = R.prop('reason', err);
                return $q.reject(err);
            })
            .then(setupLei)
            .then(handleSuccess, handleFailure)
            .finally(handleFinally);
    };

    function setupNationalityOrLei(user) {
        var promise = user.kyc.business ? setupLei() : setupNationalityAndIdentifiers();

        return promise;
    }

    // sets up nationality or LEI if not already set
    // always returns a promise
    function addIdentifersOrNationality() {
        if (!vm.user.kyc.business && !vm.nationalityDataSet && vm.user.permissions.canSetIdentifiers) {
            return nationalityService.replaceInvestorNationalitiesAndIdentifiers(vm.nationalityData).then(function () {
                vm.nationalityDataSet = true;
            });
        }

        if (vm.user.kyc.business && !vm.leiSet && vm.user.permissions.canSetIdentifiers && vm.lei) {
            return investorComplianceService.postLei(vm.leiType.id, vm.lei);
        }

        return $q.when();
    }

    function updateContactDetails() {
        return addIdentifersOrNationality().then(function () {
            return investorSettingsService.updateContactDetails(vm.personal);
        }, function (err) {
            vm.identifierError = R.prop('reason', err);
            return $q.reject({
                reason: R.prop('reason', err)
            });
        });
    }

    function reloadInvestor() {
        investorService.purgeCache('investor$');
        return investorService.getInvestor();
    }

    // When user changes their primary email logout after update
    // this is to handle a bug #PP-4001 user is still authenticated
    // after changing their primary email for a short period
    function handleSuccessWithLogout(formName) {
        vm.updated = true;
        ppTrack.formSubmit(formName);
        ppTrack.closeAll().then(function () {
            $window.location.reload();
        });
    }

    function chooseSuccessHandlerForSettingsUpdate(intialPrimaryEmail, currentPrimaryEmail) {
        return intialPrimaryEmail === currentPrimaryEmail ? handleSuccess : handleSuccessWithLogout;
    }

    //-- api

    vm.updateContactDetails = function () {

        vm.identifierError = undefined;

        if (vm.form.$invalid) {
            vm.feedbackEnabled = true;
            ppTrack.ngFormValidation(CONTACT_DETAILS_FORM_NAME, vm.form);
            return;
        }

        vm.processing = true;
        vm.processingError = false;

        var successHandler = chooseSuccessHandlerForSettingsUpdate(cachedContactDetails.primaryEmail, vm.personal.primaryEmail);

        return updateContactDetails()
            .then(reloadInvestor)
            .then(function () {
                return setupNationalityOrLei(vm.user);
            })
            .then(function () {
                successHandler(CONTACT_DETAILS_FORM_NAME);
            })
            .catch(function (error) {
                handleFailure(error);
            })
            .finally(handleFinally);
    };

    vm.updateAddress = function (address) {
        return investorSettingsService.addAddress(address);
    };

    vm.changePassword = function (auth) {
        return investorSettingsService.changePassword(auth.currentPassword, auth.newPassword, auth.newPasswordRepeat);
    };

    vm.updatePin = function (pin) {
        return pinService.updatePin(pin);
    };

    vm.updateClassification = function () {
        classificationDialogService.reclassifyUser(vm.user);
    };

    // -- scope bindings
    $transitions.onStart({}, function (trans) {
        vm.state = trans.$to().name;
    });

    investorService.on('investor-updated', function (user) {
        setupUser(user);
    });

    //-- main

    var investorPromise = investorService.getInvestor();
    var authMethodPromise = authService.getAuthMethods();
    var investorAddress = setupAddress();

    $q.all([investorPromise, authMethodPromise, investorAddress]).then(function (data) {

        setupUser(data[0]);
        setupAuth(data[1]);

        return setupNationalityOrLei(vm.user).then(function () {
            vm.ready = true;
        });
    }).catch(function (error) {
        vm.ready = true;
        vm.loadingError = true;
    });

}]);
})(window, window.angular);