(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.time-left-value', [
    'pp.services.core'
]);

/**
 * @ngdoc directive
 * @name ppTimeLeftValue
 * @description
 * Renders the time left into a span
 *
 * @restrict A
 * @scope
 * @param {string} ppTimeLeftValue
 */

ngModule.directive('ppTimeLeftValue', function () {
    return {
        restrict: 'A',
        template: '<span ng-bind="vm.timeLeftValue"></span>',
        scope: {
            dueDate: '=ppTimeLeftValue'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppMoment', function ($scope, ppMoment) {
            var vm = this;

            function calcuateTimeUnit(msLeft, msPerUnit) {
                return Math.floor(msLeft / msPerUnit);
            }

            // TODO refactor to use momentjs and write tests to cover
            function timeLeftValue(startDate, endDate) {

                var msPerDay = 86400000;
                var msPerHour = 3600000;
                var msPerMinute = 60000;

                var timeValue;
                var timeUnit;
                var plural;

                var startTime = startDate.getTime();
                var endTime = Number(ppMoment(endDate).format('x'));

                if (!endTime) {
                    return '-';
                }

                var msLeft = endTime - startTime;

                if (msLeft <= 0) {
                    timeUnit = 0;
                    timeValue = 'Day';
                } else if (msLeft <= msPerHour) {
                    timeUnit = calcuateTimeUnit(msLeft, msPerMinute);
                    //If there are only seconds left show one minute remaining
                    timeUnit = timeUnit || 1;
                    timeValue = 'Minute';
                } else if (msLeft <= msPerDay) {
                    timeUnit = calcuateTimeUnit(msLeft, msPerHour);
                    timeValue = 'Hour';
                } else {
                    timeUnit = calcuateTimeUnit(msLeft, msPerDay);
                    timeValue = 'Day';
                }

                plural = timeUnit !== 1 ? 's' : '';

                return timeValue + plural;
            }

            // -- initial state

            // -- api

            // -- main

            vm.$onInit = function () {
                $scope.$watch('vm.dueDate', function (value) {
                    if (angular.isDefined(value)) {
                        vm.timeLeftValue = timeLeftValue(window.pp.serverTime, vm.dueDate);
                    }
                });
            };

        }]
    };
});
})(window, window.angular);