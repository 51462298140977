(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-individual-property', [
    'pp.widgets-templates',
    'pp.services.investor',
    'pp.services.core',
    'pp.services.dialog',
    'pp.services.property',
    'pp.services.route',
    'pp.services.preference',
    'pp.components.tabs',
    'pp.widgets.dashboard-individual-property-figures',
    'pp.widgets.property-card-header',
    'pp.widgets.property-notice-banner',
    'pp.widgets.property-graphs',
    'pp.widgets.bid-card',
    'pp.widgets.offer-card',
    'pp.widgets.quote',
    'pp.widgets.sell',
    'pp.widgets.receipt',
    'pp.widgets.risk-warning-badge',
    'pp.widgets.property-vote-form',
    'pp.widgets.property-details-primary',
    'pp.widgets.property-details-loan',
    'pp.widgets.auth-modal'
]);

var STEPS = {
    overview: {
        buy: {
            receipt: true,
            terms: true
        },
        buyrights: {
            receipt: true,
            terms: true
        },
        buyblocktrade: {
            receipt: true,
            terms: true
        },
        bid: {
            receipt: true,
            terms: true
        },
        sell: {
            receipt: true,
            terms: true
        }
    }
};

var STEP_LOADING_TIMEOUT = 300;

var AUTH_STEP_SIGNUP = 'signup';
var UNIT_OFFER_SHAREHOLDER_VOTE_REASON = 'UNIT_OFFER_SHAREHOLDER_VOTE';
var UNIT_SALE_DEFER_VOTE_REASON = 'UNIT_SALE_DEFER_VOTE';
var PROPERTY_MUTATE_VOTE_REASON = 'PROPERTY_MUTATE_VOTE';
var REFURBISHMENT_VOTE_REASON = 'REFURBISHMENT_VOTE';

ngModule.component('ppDashboardIndividualProperty', {
    templateUrl: 'widgets/_angular/dashboard-individual-property/dashboard-individual-property.tpl.html',
    bindings: {
        slimProperty: '<property',
        holding: '<',
        user: '<',
        onChange: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', '$q', '$timeout', '$window', 'investorService', 'propertyService', 'preferenceService', 'routeService', 'dialogService', 'ppUtil', 'ppTrack', 'ppComponentEmitter', 'R', function ($scope, $element, $q, $timeout, $window, investorService, propertyService, preferenceService, routeService, dialogService, ppUtil, ppTrack, ppComponentEmitter, R) {
        var vm = this;

        // -- initial state

        vm.tabs = [{
                key: 'bid',
                title: 'Bid orders'
            },
            {
                key: 'sell',
                title: 'Sell orders'
            }
        ];

        vm.equityFundraiseLink = routeService.equityFundraiseLink;

        var authDefer;

        var stepMap = {
            'overview.buy': 'Buy [unit]s',
            'overview.buyrights': 'Invest in Equity Fundraise',
            'overview.buyblocktrade': 'Invest in Blocklisting',
            'overview.sell': 'Sell [unit]s',
            'overview.bid.receipt': 'Bid order placed',
            'overview.buy.receipt': '[unit]s purchased',
            'overview.sell.receipt': 'Sell order placed'
        };

        vm.activeTab = 'bid';

        // -- util functions

        var getStateReason = R.path(['state', 'reason']);

        function getVotingData(property) {
            if (!property.isVoting) {
                return $q.when();
            }

            var defaultChoice = 'hold';

            if (getStateReason(property) === UNIT_SALE_DEFER_VOTE_REASON) {
                defaultChoice = 'continue';
            } else if (getStateReason(property) === UNIT_OFFER_SHAREHOLDER_VOTE_REASON) {
                defaultChoice = 'sell';
            } else if (getStateReason(property) === PROPERTY_MUTATE_VOTE_REASON || getStateReason(property) === REFURBISHMENT_VOTE_REASON) {
                defaultChoice = 'yes';
            }

            return preferenceService.getPropertyVote(
                property.symbol,
                getStateReason(property),
                defaultChoice
            );

        }

        function init(property) {

            var promises = {
                property: propertyService.getPropertyData(property.symbol)
            };

            if (!vm.user.anon) {
                promises = R.mergeRight(promises, {
                    offers: investorService.getHoldingsListedForProperty(property.symbol),
                    bids: investorService.getBidsForProperty(property.symbol),
                    vote: getVotingData(property)
                });
            }

            return $q.all(promises).then(function (res) {
                vm.offers = res.offers || [];
                vm.bids = res.bids || [];
                vm.property = res.property;
                vm.voteChoice = res.vote;
                $scope.$broadcast('pp.widgets.shares-order-book-chart.refresh');
                ppComponentEmitter.emit('dashboard-individual-property.dom.loaded.' + vm.property.symbol, $element);
                ppComponentEmitter.emit('dashboard-individual-property.controller.loaded.' + vm.property.symbol, vm);
                vm.ready = true;
            });
        }

        function getStepTransform(position) {
            return {
                'transform': 'translateX(' + (position * -100) + '%)'
            };
        }

        function scrollTop() {
            $element.find('.step').scrollTop(0);
        }

        function setStep(step) {
            if (!step) {
                dialogService.resolveParent($scope, {
                    closed: true
                });
            }

            if (ppUtil.path(STEPS, step)) {
                var stepDepth = step.split('.') || [];
                if (stepDepth.length) {
                    vm.stepLoading = true;
                    vm.step = step;
                    vm.stepTransform = getStepTransform(stepDepth.length - 1);
                    scrollTop();

                    ppTrack.action('dashboard-individual-property.set-step', {
                        symbol: vm.slimProperty.symbol,
                        step: step
                    });

                    $timeout(function () {
                        vm.stepLoading = false;
                    }, STEP_LOADING_TIMEOUT);
                }
            }
        }

        function updateHolding(holdings) {
            vm.holding = ppUtil.indexBy(holdings, 'propertySymbol')[vm.slimProperty.symbol];
        }

        // -- api

        vm.getStepTitle = function (step) {
            var unit = vm.slimProperty.isShares ? 'share' : 'bond';
            return (stepMap[step] || '').replace('[unit]', unit);
        };

        vm.onOrderCancelled = function () {
            propertyService.purgeCache(vm.property.symbol + '.secondary.offers');
            init(vm.slimProperty);
        };

        vm.onOfferPlaced = function (receipt) {
            setStep(vm.step + '.receipt');
            vm.receipt = receipt;
            init(vm.slimProperty);
            investorService.reloadInvestor();
            vm.onChange();
        };

        // investor is not reloaded here
        // as it is done on the bid component when a bid is placed
        vm.onBidPlaced = function (receipt) {
            setStep('overview.bid.receipt');
            vm.receipt = receipt;
            init(vm.slimProperty);
            investorService.reloadInvestor();
            vm.onChange();
        };

        vm.onSharesBought = function (receipt) {
            if (vm.step === 'overview.buyrights') {
                setStep('overview.buyrights.receipt');
            } else {
                setStep('overview.buy.receipt');
            }
            vm.receipt = receipt;
            init(vm.slimProperty);
            investorService.reloadInvestor();
            vm.onChange();
        };

        vm.onQuoteContinue = function () {
            ppTrack.closeAll().then(function () {
                $window.location.href = routeService.fundPath;
            });
        };

        vm.showBuyForm = function () {
            if (vm.user.anon) {
                vm.authenticating = true;

                authDefer = $q.defer();
                authDefer.promise.then(function () {
                    setStep('overview.buy');
                }, angular.noop);
            } else {
                setStep('overview.buy');
            }

        };

        vm.showBuyRightsIssueForm = function () {
            if (vm.user.anon) {
                vm.authenticating = true;

                authDefer = $q.defer();
                authDefer.promise.then(function () {
                    setStep('overview.buyrights');
                }, angular.noop);
            } else {
                setStep('overview.buyrights');
            }
        };

        vm.showBuyBlockListingForm = function () {
            if (vm.user.anon) {
                vm.authenticating = true;

                authDefer = $q.defer();
                authDefer.promise.then(function () {
                    setStep('overview.buyblocktrade');
                }, angular.noop);
            } else {
                setStep('overview.buyblocktrade');
            }
        };

        vm.showSellForm = function () {
            if (vm.user.anon) {
                vm.authenticating = true;
                return;
            }
            setStep('overview.sell');
        };

        vm.goToStart = function () {
            setStep('overview');
        };

        vm.onUpdateVote = function (choice) {
            return preferenceService.setPropertyVote(
                vm.property.symbol,
                getStateReason(vm.property),
                choice
            ).then(function () {
                vm.voteChoice = choice;
            });
        };

        vm.goBack = function () {

            if (vm.authenticating) {
                vm.authenticating = false;
                return;
            }

            var stepComposition = vm.step.split('.');
            var previousStep = stepComposition.slice(0, stepComposition.length - 1).join('.');
            setStep(previousStep);
        };

        vm.onAuthSuccess = function () {
            if (R.path(['resolve'], authDefer)) {
                authDefer.resolve();
            }
            $scope.$evalAsync(function () {
                vm.authenticating = false;
                vm.hideBack = false;
            });
        };

        vm.onChangeOfLoginStep = function (step) {
            vm.hideBack = false;
        };

        vm.onChangeOfSignupStep = function (step) {
            if (step !== AUTH_STEP_SIGNUP) {
                vm.hideBack = true;
            } else {
                vm.hideBack = false;
            }
        };

        // -- lifecycle

        vm.$onDestroy = function () {
            investorService.off('investor.holdings.current.updated', updateHolding);
        };

        vm.$onChanges = function (changes) {
            if (changes && changes.slimProperty && changes.slimProperty.currentValue) {
                vm.ready = false;
                init(changes.slimProperty.currentValue);
            }
        };

        vm.$onInit = function () {
            setStep('overview');
            init(vm.slimProperty).then(function () {
                ppTrack.event('dashboard-individual-property.loaded', {
                    symbol: vm.slimProperty.symbol
                });
            });

            if (vm.user.anon) {
                vm.authMode = vm.user.stage === 'initial' ? 'signup' : 'login';
            }
        };

        investorService.on('investor.holdings.current.updated', updateHolding);
        investorService.on('investor-updated', function (user) {
            vm.user = user;
        });

        // -- main

    }]
});
})(window, window.angular);