(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.auto-tab', []);

var CHAR_START_CODE = 48;
var CHAR_END_CODE = 111;

ngModule.directive('ppAutoTab', function () {
    return {
        restrict: 'A',
        scope: {
            pauseTabbing: '<'
        },
        bindToController: true,
        controllerAs: 'vm',
        controller: ['$scope', '$element', function ($scope, $element) {

            var vm = this;

            // -- initial state

            var previousInput = '';
            var blurred;
            var maxLength = parseInt($element.attr('maxlength'));

            // NOTE: anti-pattern test specific code, but no other way to check if a transparent directive was instantiated correctly
            vm.ppTestHelperDirectiveName = 'pp-auto-tab';

            // -- util functions

            function isCharKeycode(keyCode) {
                return keyCode >= CHAR_START_CODE && keyCode <= CHAR_END_CODE;
            }

            function focusNextElement() {

                if (vm.pauseTabbing) {
                    // @note early return
                    return;
                }

                var $nextElement = $element.next();
                if ($nextElement.length && !blurred) {
                    $nextElement[0].focus();
                    blurred = true;
                }
            }

            // -- element bindings

            $element.on('input', function (e) {

                var value = $element.val();

                if (value.length === maxLength) {
                    focusNextElement();
                }

                previousInput = value;
            });

            $element.on('keyup', function (e) {

                var value = $element.val();
                if (value === previousInput && value.length === maxLength && isCharKeycode(e.keyCode) && !document.selection) {
                    focusNextElement();
                }
            });

            $element.on('focus', function (e) {
                blurred = false;
            });

        }]
    };
});
})(window, window.angular);