(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.receipt-dialog-service', [
    'pp.widgets-templates',
    'pp.widgets.receipt',
    'pp.services.dialog',
    'pp.filters.property'
]);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/receipt`.
 */
ngModule.service('receiptDialogService', ['$rootScope', '$filter', 'ppTrack', 'dialogService', function ($rootScope, $filter, ppTrack, dialogService) {

    function populateDataLayer(receipt) {
        if (window.dataLayer && window.dataLayer.push && receipt && receipt.property) {
            var property = receipt.property || {};
            var data = {
                'symbol': property.symbol,
                'amount': receipt.total,
                'name': $filter('propertyAddress')(property),
                'id': receipt.investmentId,
                'assetType': property.assetType
            };

            window.dataLayer.push({
                investment: data
            });
        }
    }

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(receipt) {
        ppTrack.action('receipt.notification.show');
        var scope = $rootScope.$new();
        var options = {
            clickClose: true,
            escapeClose: true,
            showClose: true,
            scope: scope
        };

        scope.receipt = receipt;
        options.contents = '<div pp-receipt="receipt"></div>';

        if (receipt.receiptType.toLowerCase() === 'buy_secondary' || receipt.receiptType.toLowerCase() === 'buy_primary') {
            populateDataLayer(receipt);
        }

        ppTrack.event('receipt.' + receipt.receiptType, receipt);

        return dialogService.show(options).then(function (response) {
            ppTrack.action('receipt.notitication.dismiss');
            return response;
        });
    }

    // -- api

    var api = {};

    api.show = function (receipt) {
        return showDialog(receipt);
    };

    return api;
}]);
})(window, window.angular);