(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-plan-commitment', [
    'pp.widgets-templates',
    'pp.widgets.investment-card-form',
    'pp.widgets.suitability-quiz',
    'pp.widgets.investment-plan-pre-suitability-quiz',
    'pp.widgets.risk-warning-badge',
    'pp.services.suitability',
    'pp.services.receipt',
    'pp.services.config',
    'pp.values.investment-plans',
    'pp.services.route'
]);

var PAGE_NAME = 'investment-plan-commitment';
var INVESTOR_CACHE_KEY = 'investor';

var QUIZ_TYPE = 'investmentPlans';

// TODO abstract receipt to a service
var RECEIPT_TYPE = 'INVESTMENT_PLAN';

var STEP_SUITABILITY_QUIZ = 'suitability';
var STEP_PRE_SUITABILITY_QUIZ = 'pre-suitability';
var STEP_COMMIT = 'commit';
var SUITABILITY_SUCCESS_TIMEOUT = 5000;

ngModule.component('ppInvestmentPlanCommitment', {
    templateUrl: 'widgets/_angular/investment-plan-commitment/investment-plan-commitment.tpl.html',
    bindings: {
        investmentPlan: '<',
        user: '<'
    },
    controllerAs: 'vm',
    bindToController: true,
    controller: ['$scope', '$q', '$window', '$timeout', '$element', 'ppTrack', 'investorService', 'configService', 'apoService', 'suitabilityService', 'receiptService', 'routeService', 'investmentPlans', 'ppComponentEmitter', function ($scope, $q, $window, $timeout, $element, ppTrack, investorService, configService, apoService, suitabilityService, receiptService, routeService, investmentPlans, ppComponentEmitter) {
        var vm = this;

        // -- initial state

        vm.quizType = 'investment-plans';

        // -- util functions

        var getSuitability = suitabilityService.getSuitability.bind(null, QUIZ_TYPE);

        function setStep(step) {
            ppTrack.event(PAGE_NAME + '.commitment.step.' + step);
            vm.step = step;
        }

        function refreshInvestor() {
            investorService.purgeCache(INVESTOR_CACHE_KEY);
            return investorService.getInvestor();
        }

        function createReceipt(amount) {
            return {
                receiptType: RECEIPT_TYPE,
                amount: amount,
                plan: vm.investmentPlan
            };
        }

        function handleSuccess(amount) {
            ppTrack.deferEvent(PAGE_NAME + '.invest.success', {
                amount: amount
            });
            receiptService.pushReceipt(createReceipt(amount));
            $window.location.href = routeService.dashboardPath;
        }

        function handleFailure(amount, error) {
            ppTrack.event(PAGE_NAME + '.invest.error', {
                error: JSON.stringify(error),
                amount: amount
            });
            return $q.reject(error);
        }

        function init() {
            setStep(STEP_COMMIT);
        }

        function escapeFromIsa() {
            return investorService.getInvestor().then(function (investor) {
                return investorService.escapeFromIsa(investor);
            });
        }

        // -- api

        vm.submit = function (amount) {

            var success = handleSuccess.bind(null, amount);
            var failure = handleFailure.bind(null, amount);
            ppTrack.event(PAGE_NAME + '.invest.submit', {
                amount: amount
            });
            return apoService.createInvestmentPlanOrder({
                amount: amount,
                assetType: vm.investmentPlan,
                currencyCode: 'GBP'
            }).then(success, failure);
        };

        vm.saveSuitabilityQuizAnswers = function (answers) {
            vm.suitabilityQuizTaken = true;
            vm.hideSuitabilitySuccess = false;
            return suitabilityService.submitAnswers(QUIZ_TYPE, answers)
                .then(getSuitability)
                .then(function (suitability) {
                    vm.suitability = suitability;
                    ppTrack.event(PAGE_NAME + '.suitability-quiz.submit.success', {
                        status: suitability.status
                    });
                    $timeout(function () {
                        vm.hideSuitabilitySuccess = true;
                    }, SUITABILITY_SUCCESS_TIMEOUT);
                    init(suitability);
                });
        };

        vm.goToQuiz = function () {
            setStep(STEP_SUITABILITY_QUIZ);
        };

        // -- scope bindings

        vm.$onChanges = function (changes) {
            if (changes.investmentPlan && changes.investmentPlan.currentValue) {
                vm.plan = investmentPlans[changes.investmentPlan.currentValue];

                ppTrack.setContext({
                    plan: changes.investmentPlan.currentValue
                });

                ppComponentEmitter.emit('investment-plan-commitment.' + changes.investmentPlan.currentValue + '.dom.loaded', vm);
            }
        };

        vm.$postLink = function () {
            ppComponentEmitter.emit('investment-plan-commitment.' + vm.investmentPlan + '.dom.loaded', $element);
        };

        vm.$onDestroy = function () {
            ppTrack.pageClose(PAGE_NAME);
            ppTrack.unsetContext('plan');
        };

        // -- main

        escapeFromIsa().then(function () {

            var promises = {
                user: investorService.getInvestor(),
                minAmount: configService.minimumInvestmentPlanAmount()
            };

            return $q.all(promises);
        }).then(function (res) {
            vm.user = res.user;
            ppTrack.pageLoad(PAGE_NAME);
            init();
            vm.minAmount = res.minAmount;
            vm.ready = true;
        });

    }]
});
})(window, window.angular);