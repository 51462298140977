(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.withdrawal', [
    'ui.router',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.preference',
    'pp.services.withdrawal',
    'pp.services.receipt',
    'pp.services.dialog',
    'pp.services.route',
    'pp.ui.services.receipt-dialog-service',
    'pp.ui.services.update-bank-account',
    'pp.widgets-templates',
    'pp.widgets.kyc-status-banner',
    'pp.widgets.trust-hooks',
    'pp.widgets.withdrawal-form',
    'pp.widgets.withdrawal-bank-account',
    'pp.widgets.receipt',
    'pp.widgets.investor-context-switcher',
    'pp.components.input-amount',
    'pp.components.tooltip',
    'pp.components.required-any',
    'pp.components.focus-first-error',
    'pp.values.form-lists'
]);

var PAGE_NAME = 'withdrawal';

var CREATEBANK_ACCOUNT_FORM_NAME = 'withdrawal.bank-account';
var UPDATEBANK_ACCOUNT_FORM_NAME = 'withdrawal.update-bank-account';
var DELETEBANK_ACCOUNT_FORM_NAME = 'withdrawal.delete-bank-account';
var FIRST_WITHDRAWAL_NOTIFIED = 'withdrawals.first.notified';
var INVESTOR_CACHE_KEY = 'investor';
var MIN_WITHDRAWAL = 50;
var MIN_WITHDRAWAL_NO_FUNDS = 0.01;

ngModule.component('ppWithdrawal', {
    templateUrl: 'widgets/_angular/withdrawal/withdrawal.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$window', '$anchorScroll', '$q', 'investorService', 'preferenceService', 'withdrawalService', 'receiptService', 'ppTrack', 'routeService', 'updateBankAccountService', function ($scope, $window, $anchorScroll, $q, investorService, preferenceService, withdrawalService, receiptService, ppTrack, routeService, updateBankAccountService) {
        var vm = this;

        // -- initial state

        var __isFirst;

        // -- util functions

        function isFirstWithdrawal() {
            return preferenceService.getPreference(FIRST_WITHDRAWAL_NOTIFIED).then(function (firstNotified) {
                return !firstNotified;
            });
        }

        function init() {

            return $q.all({
                user: investorService.getInvestor(),
                users: investorService.getUserInvestors(),
                accounts: investorService.getInvestorActiveAccounts(true),
                isFirstWithdrwal: isFirstWithdrawal()
            }).then(function (res) {

                //@TODO change this to use withdrawable funds when available
                vm.user = res.user;
                vm.funds = res.user.financials.manualFunds;

                vm.enabled = true;
                vm.accounts = res.accounts;

                vm.isIsaSetup = investorService.isIsaSetup(res.users);
                __isFirst = res.isFirstWithdrawal;

                if (vm.funds === 0) {
                    vm.minWithdrawal = MIN_WITHDRAWAL_NO_FUNDS;
                } else {
                    vm.minWithdrawal = (vm.funds < MIN_WITHDRAWAL) ? vm.funds : MIN_WITHDRAWAL;
                }

                ppTrack.pageLoad('account.withdrawal');
            });
        }

        function redirectToPortfolio() {
            $window.location.href = routeService.dashboardPath;
        }

        // -- api

        vm.toggleAddAccount = function () {
            vm.showAddAccount = true;
            var el = $('#add-bank-account-form');

            ppTrack.action(PAGE_NAME + '.add-bank-account.click');

            if (!el) {
                return;
            }

            $('html, body').animate({
                scrollTop: el.offset().top
            }, 500);
        };

        vm.switchContext = function (kind) {
            return investorService.switchToInvestorByKind(kind)
                .then(init);
        };

        vm.withdrawalRequest = function (amount, account, password) {
            return withdrawalService.withdraw(amount, 'GBP', account.id, account.bankAccountType, password).then(function () {
                // post to my-account
                var receiptData = {
                    receiptType: 'WITHDRAWAL',
                    amount: amount,
                    account: account,
                    isFirst: __isFirst
                };
                ppTrack.formSubmit(PAGE_NAME);
                ppTrack.closeAll().then(function () {
                    //force refresh investor data to update available funds
                    investorService.purgeCache(INVESTOR_CACHE_KEY);
                    preferenceService.purgeCache();
                    preferenceService.setPreference(FIRST_WITHDRAWAL_NOTIFIED, true);
                    receiptService.pushReceipt(receiptData);
                    redirectToPortfolio();
                });
            }, function (error) {
                ppTrack.formError(PAGE_NAME, error.reason);

                return $q.reject(error);
            });
        };

        vm.createBankAccount = function (account, password) {
            return investorService.createAccount(account, password).then(function () {
                ppTrack.formSubmit(CREATEBANK_ACCOUNT_FORM_NAME, 'success');
                investorService.purgeCache('investor.accounts');

                return init().then(function () {
                    vm.showAddAccount = false;
                    vm.toast = true;
                    $window.scrollTo(0, 0);
                });
            });
        };

        vm.updateBankAccount = function (account, password) {
            return investorService.updateBankAccount(account, password).then(function () {
                ppTrack.formSubmit(UPDATEBANK_ACCOUNT_FORM_NAME, 'success');
                investorService.purgeCache('investor.accounts');
                return init();
            });
        };

        // This is not used at the moment might be reinstated
        // need to resolve issues with readding bank accounts
        // with same display name and account number after deletion
        vm.deleteBankAccount = function (account) {
            ppTrack.formSubmit(DELETEBANK_ACCOUNT_FORM_NAME, 'submit');
            return investorService.deleteBankAccount(account).then(function () {
                ppTrack.formSubmit(DELETEBANK_ACCOUNT_FORM_NAME, 'success');
                investorService.purgeCache('investor.accounts');
                return init();
            });
        };

        vm.openUpdateForm = function (account) {
            updateBankAccountService.show(account, vm.updateBankAccount);
        };

        // -- scope bindings

        // -- main

        init().then(function () {
            vm.ready = true;
            ppTrack.pageLoad(PAGE_NAME);
        });

    }]
});
})(window, window.angular);