(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.pre-suitability-quiz', [
    'pp.widgets-templates',
    'pp.services.route'
]);

ngModule.component('ppPreSuitabilityQuiz', {
    templateUrl: 'widgets/_angular/pre-suitability-quiz/pre-suitability-quiz.tpl.html',
    bindings: {
        onContinue: '&',
        onCancel: '&',
        quizType: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', function ($scope, routeService) {
        var vm = this;

        // -- initial state

        vm.imHomeNow = routeService.imHomeNow;
        vm.kidHomeNow = routeService.kidHomeNow;
        vm.keyRisksUrl = routeService.keyRisksUrl;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);