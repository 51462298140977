(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-card-header', ['pp.widgets-templates', 'pp.services.core', 'pp.filters.property']);

ngModule.directive('ppDashboardCardHeader', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/dashboard-card-header/dashboard-card-header.tpl.html',
        scope: {
            property: '=ppDashboardCardHeader',
            market: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$filter', 'ppTrack', function ($scope, $filter, ppTrack) {
            var vm = this;

            // -- initial state

            // -- util functions

            function init() {
                vm.isGeared = vm.property.debtAmountRemaining > 0;
                vm.isBlock = vm.property.propertyType === 'B';
                var propertyTypeLabel = $filter('propertyType')(vm.property.propertyType);
                var propertyOwnershipLabel = $filter('ownershipType')(vm.property.ownershipType);
                var unitOrBedroomCount = $filter('unitOrBedroomCountSummary')(vm.property);
                vm.propertyType = unitOrBedroomCount + ' ' + propertyTypeLabel + ' | ' + propertyOwnershipLabel;
            }

            // -- api
            vm.goToProperty = function () {
                ppTrack.deferAction('property.click', {
                    symbol: vm.property.symbol
                });
            };

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                if (vm.property) {
                    init();
                }
            };

        }]
    };
}]);
})(window, window.angular);