(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.invest-error', [
    'pp.services.core',
    'pp.services.property',
    'pp.services.dialog',
    'pp.services.log',
    'pp.ui.services.suspended-property-dialog',
    'pp.widgets.invest-dialog-holding-exceeded',
    'pp.widgets.invest-dialog-primary-not-available',
    'pp.widgets.invest-dialog-secondary-expired',
    'pp.widgets.invest-dialog-investor-mismatch',
    'pp.widgets.invest-dialog-unexpected-error'
]);

ngModule.service('investErrorService', ['$rootScope', '$q', 'dialogService', 'logService', 'propertyService', 'suspendedPropertyDialogService', function ($rootScope, $q, dialogService, logService, propertyService, suspendedPropertyDialogService) {
    var copy = angular.copy;

    // defaults for the blocking dialogs when handling transaction edge cases
    var __dialogOptions = {
        clickClose: false,
        escapeClose: false,
        showClose: true
    };

    function handlePrimaryNotAvailable(postTransaction, fundsUpdateSuccess, quote, sharesAvailable, availableFunds) {

        var dialogOptions = copy(__dialogOptions);
        var dialogScope = $rootScope.$new();
        dialogOptions.scope = dialogScope;

        dialogScope.sharesRequested = quote.shares;
        dialogScope.sharesAvailable = sharesAvailable || 0;
        dialogScope.paymentAmount = postTransaction ? quote.paymentAmount : false;
        dialogScope.availableFunds = availableFunds;

        dialogScope.fundsUpdated = fundsUpdateSuccess;

        dialogOptions.contents = '<pp-invest-dialog-primary-not-available page-name="invest" shares-requested="sharesRequested" shares-available="sharesAvailable" payment-amount="paymentAmount" available-funds="availableFunds" funds-updated="fundsUpdated" shares-updated="sharesUpdated"></pp-invest-dialog-primary-not-available>';

        return dialogService.show(dialogOptions);
    }

    function handleSecondaryExpired(postTransaction, fundsUpdateSuccess, newQuote, quote, availableFunds) {

        var dialogOptions = copy(__dialogOptions);
        var dialogScope = $rootScope.$new();
        dialogOptions.scope = dialogScope;

        dialogScope.oldQuote = quote;
        dialogScope.paymentAmount = postTransaction ? quote.paymentAmount : false;
        dialogScope.availableFunds = availableFunds;

        dialogScope.fundsUpdated = fundsUpdateSuccess;

        dialogOptions.contents = '<pp-invest-dialog-secondary-expired page-name="invest" old-quote="oldQuote" payment-amount="paymentAmount" available-funds="availableFunds" funds-updated="fundsUpdated"></pp-invest-dialog-secondary-expired>';

        return dialogService.show(dialogOptions);
    }

    function handleTransactionUnexpectedError() {
        var dialogOptions = copy(__dialogOptions);
        dialogOptions.contents = '<pp-invest-dialog-unexpected-error></pp-invest-dialog-unexpected-error>';
        return dialogService.show(dialogOptions);
    }

    /**
     * handles edge cases that occur before submitting the transaction
     * may update the `offer.sharesAvailable` and the tracking context
     * are handled with modal dialogs OR inline message under the submit button in pp.payment-confirm
     */
    function handlePreTransactionFail(error, symbol, quote, user, sharesAvailable) {

        var reason = error && error.reason || error || 'pp.invest.unexepectedError';

        switch (reason) {

        case 'primary.shares.unavailable':
        case 'primary.shares.partially-available':
            return handlePrimaryNotAvailable(false, true, quote, sharesAvailable, user.financials.funds);
        case 'property.currently.not.tradable':
            return suspendedPropertyDialogService.show(true);
        case 'secondary.requote.expired':
            // arguments for the dialog - !postTransaction, fundsUpdated, newQuote
            return handleSecondaryExpired(false, true, error.newQuote, quote, user.financials.funds);
        default:
            return $q.resolve();
        }
    }

    function handleInvestorMismatch() {
        var dialogOptions = copy(__dialogOptions);
        dialogOptions.contents = '<pp-invest-dialog-investor-mismatch></pp-invest-dialog-investor-mismatch>';
        return dialogService.show(dialogOptions);
    }

    function handleTransactionFailSecondaryExpired(forceRefresh, newQuote, quote, availableFunds, fundsUpdateSuccess) {
        if (forceRefresh) {
            return handleSecondaryExpired(true, false, false, quote, availableFunds);
        } else {
            return handleSecondaryExpired(true, fundsUpdateSuccess, newQuote, quote, availableFunds);
        }
    }

    function handleTransactionFailPrimaryNotAvailable(forceRefresh, quote, sharesAvailable, availableFunds, fundsUpdateSuccess) {
        if (forceRefresh) {
            return handlePrimaryNotAvailable(true, false, quote, sharesAvailable, availableFunds);
        } else {
            return handlePrimaryNotAvailable(true, fundsUpdateSuccess, quote);
        }
    }

    // PRIMARY: 19.99% ownership limit exceeded
    function handleTransactionFailHoldingExceeded() {
        var dialogOptions = copy(__dialogOptions);
        var dialogScope = $rootScope.$new();
        dialogOptions.scope = dialogScope;

        dialogOptions.contents = '<pp-invest-dialog-holding-exceeded page-name="invest"></pp-invest-dialog-holding-exceeded>';

        return dialogService.show(dialogOptions);
    }

    function handleTransactionFail(code, quote, user, sharesAvailable, fundsUpdateSuccess) {

        switch (code) {

        case 'reserve.failure.no.units':

            // PRIMARY: post-funding (modal) card WAS charged (ERROR)
            return handleTransactionFailPrimaryNotAvailable(false, quote, sharesAvailable, user.financials.funds, fundsUpdateSuccess);

        case 'reserve.failure.no.funds':

            // race condition that the user can cause (using two tabs)
            // PRIMARY: post-funding (modal) card WAS charged (ERROR > force refresh)

            return handleTransactionFailPrimaryNotAvailable(true, quote, sharesAvailable, user.financials.funds, fundsUpdateSuccess);

        case 'update.failed':
        case 'reserve.failure.account':
        case 'reserve.failure.pep':
        case 'reserve.failure.prohibited':
        case 'reserve.failure':

            // PRIMARY: post-funding (modal) card WAS charged (EXCEPTION > force refresh)
            logService.exception(code);

            return handleTransactionFailPrimaryNotAvailable(true, quote, sharesAvailable, user.financials.funds, fundsUpdateSuccess);

        case 'reserve.prohibited.holding.exceeded':

            // PRIMARY: post-funding (modal) card WAS charged (EXCEPTION > force refresh)
            logService.exception(code);

            return handleTransactionFailHoldingExceeded();

        case 'requote.required':

            // SECONDARY: post-funding (modal) card WAS charged (ERROR)

            return handleTransactionFailSecondaryExpired();

        case 'secondary.buy.insufficient.funds':

            // race condition that the user can cause (using two tabs)
            // SECONDARY: post-funding (modal) card WAS charged (ERROR > force refresh)

            return handleTransactionFailSecondaryExpired(true, quote, user.financials.funds);

        case 'secondary.buy.quote.not.found':
        case 'secondary.buy.pep':
        case 'secondary.buy.prohibited':
        case 'secondary.buy.failed':
        case 'secondary.buy.holding.exceeded':

            // SECONDARY: post-funding (modal) card WAS charged (EXCEPTION > force refresh)

            // log exception (except for cases handled within ppPaymentProcess)
            if (code.indexOf('pp.payment-process') === -1) {
                logService.exception(code);
            }

            return handleTransactionFailSecondaryExpired(true, quote, user.financials.funds);

        case 'secondary.buy.investor-not-allowed':

            return handleInvestorMismatch();

        case 'error.unexpected':
            /*jshint -W086*/
        default:

            // log exception (except for cases handled within ppPaymentProcess)
            if (code.indexOf('pp.payment-process') === -1) {
                logService.exception(code);
            }

            return handleTransactionUnexpectedError();
        }
        /*jshint +W086*/
    }

    var api = {
        handlePreTransactionFail: handlePreTransactionFail,
        handleTransactionFail: handleTransactionFail
    };

    return api;
}]);
})(window, window.angular);