(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.isa-fill-bar', [
    'pp.widgets-templates',
    'pp.services.route',
    'pp.values.numbers'
]);

ngModule.component('ppIsaFillBar', {
    templateUrl: 'widgets/_angular/isa-fill-bar/isa-fill-bar.tpl.html',
    bindings: {
        isaAmountUsed: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', 'isaMaxPerYear', function ($scope, routeService, isaMaxPerYear) {
        var vm = this;

        // -- initial state

        vm.investmentMax = isaMaxPerYear;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);