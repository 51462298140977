(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investor-change-password-form', ['pp.widgets-templates', 'pp.components.focus-first-error', 'pp.services.core']);

var UPDATED_HIDE_DELAY = 5000;

var FORM_NAME = 'change-password';

ngModule.directive('ppInvestorChangePasswordForm', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/investor-change-password-form/investor-change-password-form.tpl.html',
        scope: {
            onSubmit: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', 'ppTrack', function ($scope, $timeout, ppTrack) {
            var vm = this;

            // -- initial state

            vm.auth = {};

            // -- util functions

            function handleSuccess() {
                ppTrack.formSubmit(FORM_NAME);

                vm.updated = true;
                vm.auth = {};
                $timeout(function () {
                    vm.updated = false;
                }, UPDATED_HIDE_DELAY);

                vm.processing = false;
            }

            function handleFailure(error) {
                ppTrack.formError(FORM_NAME, error.reason);
                vm.processingError = true;
            }

            function handleFinally() {
                vm.processing = false;
            }

            // -- api

            vm.submit = function () {

                if (vm.form.$invalid) {
                    // Do not use ngValidation method in case of tracking sensitive data
                    ppTrack.event('form.' + FORM_NAME + '.validation-error');
                    vm.feedbackEnabled = true;
                    return;
                }

                vm.processing = true;
                vm.processingError = false;
                vm.feedbackEnabled = false;

                var data = {
                    auth: vm.auth
                };

                vm.onSubmit(data)
                    .then(handleSuccess, handleFailure)
                    .finally(handleFinally);

            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);