(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.investment', []);

ngModule.service('investmentService', [function () {

    var INVESTMENT_PERIOD_YEARS = 5;
    var FORECAST_DIVIDEND_RETURN_PCT = 0.03;
    var FORECAST_CAPITAL_RETURN_PCT = 0.07;

    var api = {};

    api.forecastAnnualisedDividend = function (monthlyInvestment) {
        if (!angular.isNumber(monthlyInvestment)) {
            return null;
        }
        return ((monthlyInvestment * (INVESTMENT_PERIOD_YEARS * 12)) * FORECAST_DIVIDEND_RETURN_PCT) / INVESTMENT_PERIOD_YEARS;
    };

    api.forecastAnnualisedCapitalReturn = function (monthlyInvestment) {
        if (!angular.isNumber(monthlyInvestment)) {
            return null;
        }
        return ((monthlyInvestment * (INVESTMENT_PERIOD_YEARS * 12)) * FORECAST_CAPITAL_RETURN_PCT) / INVESTMENT_PERIOD_YEARS;
    };

    return api;
}]);
})(window, window.angular);