(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.nationality-lei-dialog', ['pp.services.core', 'pp.services.dialog', 'pp.widgets.nationality-lei-dialog']);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/extra-user-data`.
 */
ngModule.service('nationalityLeiDialogService', ['$rootScope', '$timeout', 'ppTrack', 'dialogService', function ($rootScope, $timeout, ppTrack, dialogService) {

    // -- initial state

    var promiseCache;

    // -- util functions

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(user, nationalitiesList, onSuccess, onDismiss) {

        ppTrack.action('nationality-lei.dialog.show');

        var scope = $rootScope.$new();
        scope.user = user;
        scope.nationalitiesList = nationalitiesList;
        scope.onSuccess = onSuccess;

        scope.onDismiss = onDismiss;
        var options = {
            clickClose: false,
            escapeClose: false,
            showClose: false,
            classNames: 'pp-nationality-lei-dialog',
            scope: scope
        };

        options.contents = '<div pp-nationality-lei-dialog user="user" nationalities-list="nationalitiesList" on-success="onSuccess()" on-dismiss="onDismiss()"></div>';

        return dialogService.show(options).then(function (response) {

            var checkResponse = response || {};
            if (checkResponse.dismissed) {
                ppTrack.pageEvent('nationality-lei', 'dismiss');

                // if modal is closed by user
                // notify controller (page may need to be refreshed to update state)
                onDismiss(scope.quizPassed);
            }
        });
    }

    // -- api

    var api = {};

    api.show = function (user, nationalitiesList, onSuccess, onDismiss) {
        promiseCache = showDialog(user, nationalitiesList, onSuccess, onDismiss);
        return promiseCache;
    };

    api.close = function () {
        if (promiseCache) {
            dialogService.close();
        }
    };

    return api;
}]);
})(window, window.angular);