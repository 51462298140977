(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.list-menu-expander-item', ['pp.components-templates']);

ngModule.component('ppListMenuExpanderItem', {
    templateUrl: 'components/_angular/list-menu-expander-item/list-menu-expander-item.tpl.html',
    bindings: {
        isOpen: '=',
        label: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.toggle = function () {
            vm.isOpen = !vm.isOpen;
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);