(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.trust-pilot-grid', ['pp.widgets-templates']);

ngModule.component('ppTrustPilotGrid', {
    templateUrl: 'widgets/_angular/trust-pilot-grid/trust-pilot-grid.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$element', function ($scope, $element) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$postLink = function () {
            if (window.Trustpilot) {
                window.Trustpilot.loadFromElement($element.find('.trustpilot-widget')[0]);
                vm.ready = true;
            }
        };

        // -- main

    }]
});
})(window, window.angular);