(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.toggle-total-return-valuation-methods', ['pp.widgets-templates']);

ngModule.component('ppToggleTotalReturnValuationMethods', {
    templateUrl: 'widgets/_angular/toggle-total-return-valuation-methods/toggle-total-return-valuation-methods.tpl.html',
    bindings: {
        latestValuationVPV: '<latestValuationVpv',
        latestValuationIV: '<latestValuationIv',
        tradingValuation: '<',
        onUpdateTradingValuation: '&',
        onUpdateLatestValuationVPV: '&onUpdateLatestValuationVpv',
        onUpdateLatestValuationIV: '&onUpdateLatestValuationIv',
        backToDashboard: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.updateTradingValuation = function () {
            vm.onUpdateTradingValuation();
        };

        vm.updateLatestValuationVPV = function () {
            vm.onUpdateLatestValuationVPV();
        };

        vm.updateLatestValuationIV = function () {
            vm.onUpdateLatestValuationIV();
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);