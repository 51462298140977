(function(window, angular){'use strict';

var ngModule = angular.module('pp.app.property', [
    'ui.router',
    'pp.widgets.quote',
    'pp.widgets.property',
    'pp.ui.services.authenticate-investor',
    'pp.services.core'
]);

// TODO replace this with a more generic close modal on fe route change
function closeModal() {
    $.modal.close();
}

ngModule.config(['$stateProvider', '$urlRouterProvider', '$windowProvider', function ($stateProvider, $urlRouterProvider, $windowProvider) {

    var $window = $windowProvider.$get();
    var pathname = $window.location.pathname;

    if (pathname.match(/properties\/[A-Z0-9]+/i)) {

        $stateProvider
            .state('property', {
                url: '/',
                component: 'ppProperty',
                onExit: closeModal
            })
            .state('property-key-risks', {
                url: '/key-risks',
                component: 'ppProperty',
                onExit: closeModal
            })
            .state('property.shareValuation', {
                url: 'share-valuation'
            })
            .state('property.forecastIncome', {
                url: 'rental-income-breakdown'
            })
            .state('property.unitsDisclosure', {
                url: 'individual-unit-details'
            })
            .state('quote', {
                url: '/quote',
                component: 'ppQuote',
                onExit: closeModal,
                resolve: {
                    user: ['$state', 'authenticateInvestorAnonReject', function ($state, authenticateInvestorAnonReject) {
                        return authenticateInvestorAnonReject().catch(function () {
                            $state.go('property');
                        });
                    }]
                }
            });

        $urlRouterProvider.when('', '/');

        $urlRouterProvider.otherwise('/');
    }

}]);

ngModule.run(['$window', '$transitions', 'R', function ($window, $transitions, R) {

    var pathname = $window.location.pathname;

    if (pathname.match(/properties\/[A-Z0-9]+/i)) {

        var tabTransitions = [
            'shareValuation',
            'forecastIncome',
            'unitsDisclosure'
        ];

        $transitions.onSuccess({}, function (transition) {
            var transitionTo = transition.to().name;
            var indexOfTransition = R.flip(R.indexOf)(transitionTo);
            var isTabTransition = R.any(R.gte(0))(R.map(indexOfTransition, tabTransitions));
            if (!isTabTransition) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        });
    }

}]);
})(window, window.angular);