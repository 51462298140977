(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.funding-round-details', [
    'pp.widgets-templates',
    'pp.components.checkbox',
    'pp.widgets.not-found',
    'pp.services.preference',
    'pp.services.investor',
    'pp.services.route'
]);

ngModule.component('ppFundingRoundDetails', {
    templateUrl: 'widgets/_angular/funding-round-details/funding-round-details.tpl.html',
    bindings: {
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', '$sce', '$q', 'preferenceService', 'investorService', 'routeService', function ($scope, $window, $sce, $q, preferenceService, investorService, routeService) {
        var vm = this;

        vm.ndaCheck = false;
        vm.memorandumUnderstood = false;

        var memorandumLinkId = 'pp-memorandum-link';

        vm.marketplacePath = routeService.marketplacePath;
        vm.mainImage = routeService.cloudfrontBase + '/images/marketing/fort-distance.jpg';

        // -- initial state

        // -- util functions

        function init() {
            return $q.all({
                hasAcceptedMemorandumNda: preferenceService.hasAcceptedMemorandumNda(),
                canAccess: preferenceService.canAccessFundingRound()
            }).then(function (res) {
                if (res.canAccess) {
                    vm.hasAcceptedMemorandumNda = !!res.hasAcceptedMemorandumNda;
                    vm.hasReadMemorandum = !!res.hasReadMemorandum;
                    vm.ready = true;
                } else {
                    vm.accessDenied = true;
                    $window.location.href = routeService.marketplacePath;
                }
            });
        }

        // -- api

        vm.trustAsHtml = function (string) {
            return $sce.trustAsHtml(string);
        };

        vm.generateMemorandum = function () {
            vm.memorandumFeedbackEnabled = true;
            vm.viewMemorandumError = false;
            if (vm.memorandumForm.$valid) {
                vm.processingMemorandum = true;
                return preferenceService.setAcceptedMemorandumNda()
                    .then(investorService.getLinkForInvestmentMemorandum)
                    .then(function (url) {
                        vm.memorandumLink = url;
                        return init();
                    })
                    .catch(function () {
                        vm.viewMemorandumError = true;
                    })
                    .finally(function () {
                        vm.processingMemorandum = false;
                    });
            }
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            init();
        };

    }]
});
})(window, window.angular);