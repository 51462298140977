(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.account-history', []);

// @todo conversion phase-2 request from cloudfront is CORS is enabled
var ACCOUNT_HISTORY_ENDPOINT = '/feapi/r1/investor/account/GBP/:type/history.json';

/**
 * @ngdoc service
 * @name account-historyService
 *
 */
ngModule.service('accountHistoryService', ['$http', '$q', function ($http, $q) {

    var api = {};

    // types = manual || auto-invest

    api.getAccountHistory = function (type, page) {

        var endpoint = ACCOUNT_HISTORY_ENDPOINT.replace(':type', type);
        endpoint = endpoint + '?page=' + page;

        return $http.get(endpoint).then(function (res) {
            return res.data;
        }, function (err) {
            return $q.reject({
                reason: 'account-history.error.unexpected'
            });
        });

    };

    return api;
}]);
})(window, window.angular);