(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.dialog', []);

ngModule.service('dialogService', ['$rootScope', '$document', '$compile', '$q', function ($rootScope, $document, $compile, $q) {

    var EVENT_NAME = $.modal.CLOSE + '.dialogService';

    var api = {};

    var deferCache;

    function promisePassThrough(promisePayload, modalInstance) {
        modalInstance.off(EVENT_NAME);

        if (!angular.isObject(promisePayload) || promisePayload.event !== 'modal:close') {
            $.modal.close();
        }

        // Delete modal from the dom
        // jquery-modal leaves on dom and creates new dom element every time api.show() is invoked
        modalInstance.remove();

        return promisePayload;
    }

    api.show = function (options) {
        var defer = $q.defer();
        deferCache = defer;

        // create a new scope and expose
        var childScope = options.scope ? options.scope.$new() : $rootScope.$new();
        childScope.dialogDefer = defer;

        // compile
        var classNames = 'jquery-modal ' + options.classNames;
        var contents = '<div class="' + classNames + '">' + options.contents + '</div>';
        var element = $compile(contents)(childScope);

        // append
        var container = options.container || $document.find('body');
        container.append(element);

        // and show modal
        var modalInstance = $(element).modal(options);

        // resolve the promise if the modal is closed from within
        modalInstance.on(EVENT_NAME, function (ev, modal) {
            modal.elm.off(EVENT_NAME);
            defer.resolve({
                event: $.modal.CLOSE,
                dismissed: true
            });
        });

        return defer.promise.then(function (payload) {
            return promisePassThrough(payload, modalInstance);
        }, function (payload) {
            return promisePassThrough(payload, modalInstance);
        });
    };

    api.resolve = function (payload) {
        if (deferCache && deferCache.resolve) {
            deferCache.resolve(payload);
        }
    };

    api.resolveParent = function (scope, value) {
        if (scope.$parent && scope.$parent.dialogDefer) {
            scope.$parent.dialogDefer.resolve(value);
        } else if (scope.$parent) {
            api.resolveParent(scope.$parent);
        }
    };

    api.close = function () {
        if (deferCache && deferCache.resolve) {
            deferCache.resolve();
        }
    };

    return api;
}]);
})(window, window.angular);