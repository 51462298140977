(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.form-hint', ['pp.widgets-templates', 'pp.services.core']);

/**
 * @ngdoc directive
 * @name tooltip
 * @description
 * Show a tooltip on hover
 *
 * @restrict A
 * @scope
 * @param {String} ppInfoBoxTooltip
 *
 */

ngModule.directive('ppFormHint', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/form-hint/form-hint.tpl.html',
        scope: {
            description: '@ppFormHint',
            label: '@',
            trackKey: '@'
        },
        bindToController: true,
        controllerAs: 'vm',
        controller: ['ppTrack', function (ppTrack) {
            var vm = this;

            // -- initial state

            vm.showDescription = false;
            var tracked;

            // -- api
            vm.toggleDescription = function () {
                vm.showDescription = !vm.showDescription;
                if (vm.showDescription && !tracked && vm.trackKey) {
                    ppTrack.action(vm.trackKey + '.expand');
                    tracked = true;
                }
            };

        }]
    };
}]);
})(window, window.angular);