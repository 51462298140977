(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.isa', []);

var API_BASE_PATH = '/feapi/r1';
var API_TRANSFER_IN_GOJI_WIDGET_DETAILS_ENDPOINT = API_BASE_PATH + '/investor/isa/transfer-in/ui';

ngModule.service('isaService', ['$http', '$q', function ($http, $q) {

    var api = {};

    var __scriptsLoaded;

    api.getTransferInWidgetDetails = function () {

        var endpoint = API_TRANSFER_IN_GOJI_WIDGET_DETAILS_ENDPOINT;

        return $http.get(endpoint).then(function (response) {
            return response.data;
        }, function (error) {
            return $q.reject({
                reason: 'transfer-in-widget.unexpected'
            });
        });
    };

    function loadScript(endpoint) {
        var script = document.createElement('script');
        script.src = endpoint;
        document.body.appendChild(script);
    }

    function loadCss(endpoint) {
        var link = document.createElement('link');
        link.href = endpoint;
        link.rel = 'stylesheet';
        document.body.appendChild(link);
    }

    api.loadGojiScripts = function (jsEndpoint, cssEndpoint) {
        if (!__scriptsLoaded) {
            loadScript(jsEndpoint);
            loadCss(cssEndpoint);
            __scriptsLoaded = true;
        }
    };

    return api;

}]);
})(window, window.angular);