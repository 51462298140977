(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.refer-a-friend-banner', [
    'pp.widgets-templates',
    'pp.widgets.referral-statistics',
    'pp.widgets.copy-to-clipboard',
    'pp.services.referral',
    'pp.services.route'
]);

ngModule.component('ppReferAFriendBanner', {
    templateUrl: 'widgets/_angular/refer-a-friend-banner/refer-a-friend-banner.tpl.html',
    bindings: {
        stats: '<',
        investor: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'referralService', 'routeService', function ($scope, referralService, routeService) {
        var vm = this;

        // -- initial state

        vm.referralTermsLink = routeService.referralTermsLink;
        vm.referPath = routeService.referPath;

        // -- util functions

        function init(investor) {
            var referUrl = referralService.getReferralUrl(investor);
            vm.shareLink = referUrl;
        }

        // -- api

        // -- lifecycle

        vm.$onChanges = function (changes) {
            if (changes.investor && !changes.investor.isFirstChange()) {
                init(changes.investor.currentValue);
            }
        };

        vm.$onInit = function () {
            init(vm.investor);
        };

        // -- main

    }]
});
})(window, window.angular);