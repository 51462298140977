(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.business-summary-ethics', [
    'pp.widgets-templates',
    'pp.widgets.auth-signup-cta',
    'pp.services.route'
]);

ngModule.component('ppBusinessSummaryEthics', {
    templateUrl: 'widgets/_angular/business-summary-ethics/business-summary-ethics.tpl.html',
    bindings: {
        user: '<',
        s3Endpoint: '<',
        withTitle: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', function ($scope, routeService) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$onInit = function () {
            vm.marketplacePath = routeService.marketplacePath;
        };

        // -- main

    }]
});
})(window, window.angular);