(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-quick-view', []);

ngModule.directive('ppPropertyQuickView', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-quick-view/property-quick-view.tpl.html',
        scope: {},
        controllerAs: 'vm',
        bindToController: true,
        controller: [function () {

            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);