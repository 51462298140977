(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.investor-funds', [
    'pp.widgets-templates',
    'pp.widgets.isa-fill-bar',
    'pp.services.route'
]);

/**
 * @ngdoc directive
 * @name ppAvailableFunds
 * @description
 * Renders the dropdown with invesor-menu investor funds
 *
 * @restrict A
 * @scope
 */

ngModule.directive('ppInvestorFunds', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/investor-funds/investor-funds.tpl.html',
        scope: {
            user: '<ppInvestorFunds',
            showInvestorType: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['routeService', function (routeService) {
            var vm = this;

            vm.types = {
                isa: {
                    label: 'ISA portfolio',
                    fundLink: routeService.fundPathIsa,
                    portfolioLink: routeService.dashboardPathIsa
                },
                general: {
                    label: 'General portfolio',
                    fundLink: routeService.fundPathGeneral,
                    portfolioLink: routeService.dashboardPathGeneral
                }
            };
        }]
    };
});
})(window, window.angular);