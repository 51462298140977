(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-secondary', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.services.core',
    'pp.filters.dates',
    'pp.components.tooltip',
    'pp.values.glossary',
    'pp.widgets.aum-dividend-notice',
    'pp.services.property'
]);

ngModule.directive('ppPropertyDetailsSecondary', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-details-secondary/property-details-secondary.tpl.html',
        scope: {
            property: '<ppPropertyDetailsSecondary',
            showViewCalcsLink: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$location', '$anchorScroll', 'glossary', 'propertyService', 'R', function ($scope, $location, $anchorScroll, glossary, propertyService, R) {
            var vm = this;

            // -- initial state
            vm.netIncomeYieldSecondary = glossary.netIncomeYieldSecondary;
            vm.fiveYearHpi = glossary.fiveYearHpi;
            vm.vpv = glossary.vpv;
            vm.iv = glossary.iv;

            // -- util functions

            // -- api

            vm.goToFinancialsSection = function () {
                $anchorScroll.yOffset = 100;
                $location.hash('pp-financials-property-page');
                $anchorScroll();
                $anchorScroll.yOffset = 0;
            };

            // -- scope bindings

            vm.$onChanges = function (changes) {
                var property = R.path(['property', 'currentValue'], changes);
                var vpvShareValuation = R.path(['valuation', 'shareVpv'], property);
                vm.vpvPremiumToBuyPrice = propertyService.calculateLastTradedPricePremiumToValuation(vpvShareValuation, property);
                vm.ivPremiumToBuyPrice = propertyService.calculateLastTradedPricePremiumToValuation(R.path(['valuation', 'share'], property), property);
                vm.discountPremiumTooltip = vpvShareValuation ? glossary.premiumDiscountVpv : glossary.premiumDiscountIv;

            };

            // -- main

        }]
    };
}]);
})(window, window.angular);