(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.day-picker', ['pp.components-templates', 'pp.filters.numbers']);

ngModule.directive('ppDayPicker', function () {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/day-picker/day-picker.tpl.html',
        scope: {
            day: '=ppDayPicker',
            tooltipDescription: '=',
            isRequired: '='
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ordinalService', function ($scope, ordinalService) {

            var vm = this;

            // -- initial state

            vm.today = (new Date()).getDate();

            // -- util functions

            function populateDays() {
                var days = [];
                for (var ix = 1; ix <= 28; ix++) {
                    days.push({
                        value: ix,
                        text: String(ix) + ordinalService.ordinal(ix)
                    });
                }
                return days;
            }

            // -- api

            vm.$onInit = function () {
                vm.days = populateDays();
            };

        }]
    };

});
})(window, window.angular);