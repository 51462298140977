(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.tick-product-list', ['pp.widgets-templates']);

ngModule.component('ppTickProductList', {
    templateUrl: 'widgets/_angular/tick-product-list/tick-product-list.tpl.html',
    bindings: {
        theme: '@',
        lists: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);