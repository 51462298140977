(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.offer-card', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.filters.numbers'
]);

ngModule.component('ppOfferCard', {
    templateUrl: 'widgets/_angular/offer-card/offer-card.tpl.html',
    bindings: {
        offer: '<',
        onCancel: '&'
    },
    controllerAs: 'vm',
    controller: ['ppTrack', 'investorService', function (ppTrack, investorService) {
        var vm = this;

        // -- initial state

        vm.step = 'initial';

        // -- util functions

        // -- api

        vm.startCancel = function (symbol) {
            vm.step = 'confirm';

            ppTrack.action('offer-card.start-cancel', {
                symbol: symbol
            });
        };

        vm.stopCancel = function (symbol) {
            vm.step = 'initial';

            ppTrack.action('offer-card.stop-cancel', {
                symbol: symbol
            });
        };

        vm.confirmCancel = function (symbol, offerId) {
            vm.processing = true;
            ppTrack.action('offer-card.confirm-cancel.submit', {
                symbol: symbol
            });

            investorService.cancelSellOrder(symbol, offerId).then(function () {
                vm.onCancel();

                ppTrack.event('offer-card.confirm-cancel.success', {
                    symbol: symbol
                });

                vm.cancelled = true;
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);