(function(window, angular){'use strict';

var ngModule = angular.module('pp.controllers.investor-router', [
    'ui.router',
    'pp.widgets.welcome-signup',
    'pp.widgets.kyc-page',
    'pp.widgets.kyc-more-info',
    'pp.widgets.isa-setup',
    'pp.widgets.transfer-funds-between-investors',
    'pp.widgets.fund',
    'pp.widgets.not-found',
    'pp.ui.services.authenticate-investor',
    'pp.services.investor',
    'pp.services.config',
    'pp.services.route'
]);

ngModule.config(['$stateProvider', '$urlRouterProvider', '$windowProvider', function ($stateProvider, $urlRouterProvider, $windowProvider) {

    var $window = $windowProvider.$get();
    var pathname = $window.location.pathname;

    function switchInvestorAndRedirectToFund(investorKind, trans) {
        var investorService = trans.injector().get('investorService');
        var $state = trans.injector().get('$state');
        return investorService.switchToInvestorByKind(investorKind).then(function () {
            return 'investor.fund';
        });
    }

    var isIsaPublic = ['$state', 'configService', function ($state, configService) {
        return configService.isIsaPublic().then(function (isaPublic) {
            if (!isaPublic) {
                $state.go('page-not-found');
            }
        });
    }];

    // @todo remove this logic by splitting up app into smaller submodules and move to frontend routing
    // This is so the redirect does not happen on all other routes within the app
    if (pathname.match(/^\/investor/)) {

        $stateProvider.decorator('data', function (state, parent) {
            var stateData = parent(state);
            state.resolve = state.resolve || {};

            state.resolve.security = ['$q', '$window', 'authenticateInvestorAnonReject', 'routeService', function ($q, $window, authenticateInvestorAnonReject, routeService) {
                return authenticateInvestorAnonReject()
                    .catch(function () {
                        if ($window.location.hash.indexOf('isa-setup') > -1) {
                            $window.location.href = routeService.isaLandingPage;
                        } else {
                            $window.location.href = '/';
                        }

                        return $q.reject();
                    });
            }];
            return stateData;
        });

        $stateProvider
            .state('investor', {
                abstract: true
            })
            .state('investor.kyc', {
                url: '/kyc',
                component: 'ppKycPage'
            })
            .state('investor.kyc-more-info', {
                url: '/kyc-more-info',
                component: 'ppKycPage'
            })
            .state('investor.isa-setup', {
                url: '/isa-setup',
                component: 'ppIsaSetup',
                resolve: {
                    isaPublic: isIsaPublic
                }
            })
            .state('investor.transfer-funds', {
                url: '/move-funds',
                component: 'ppTransferFundsBetweenInvestors',
                resolve: {
                    isaPublic: isIsaPublic
                }

            })
            .state('investor.fund', {
                url: '/account/manual/fund?amount',
                component: 'ppFund',
                params: {
                    account: 'manual'
                }
            })
            .state('investor.fund.select-card', {
                url: '/account/manual/fund?amount',
                component: 'ppFund',
                params: {
                    account: 'manual'
                }
            })
            .state('investor.fund-isa', {
                url: '/account/manual/fund-isa',
                redirectTo: switchInvestorAndRedirectToFund.bind(this, 'isa')
            })
            .state('investor.fund-general', {
                url: '/account/manual/fund-general',
                redirectTo: switchInvestorAndRedirectToFund.bind(this, 'general')
            })
            .state('page-not-found', {
                component: 'ppNotFound'
            });

        $urlRouterProvider.otherwise(function ($injector, $location) {
            var $state = $injector.get('$state');

            $state.go('page-not-found');
        });
    }

}]);
})(window, window.angular);