(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-pending', [
    'pp.widgets-templates',
    'pp.services.dashboard',
    'pp.services.property',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.number',
    'pp.ui.services.dashboard-individual-property-sidebar',
    'pp.widgets.dashboard-card-pending'
]);

ngModule.component('ppDashboardPending', {
    templateUrl: 'widgets/_angular/dashboard-pending/dashboard-pending.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$q', 'R', 'ppConfig', 'dashboardService', 'propertyService', 'investorService', 'ppUtil', 'ppBig', function ($scope, $q, R, ppConfig, dashboardService, propertyService, investorService, ppUtil, ppBig) {

        var vm = this;

        // -- initial state

        // -- util functions

        var addDataToHoldings = R.curry(function (properties, holding) {
            var property = properties[holding.symbol];
            holding.annualDividend = Number(ppBig(holding.units).times(holding.unitPrice).times(ppBig(property.income.net.pct).div(100)));
            return holding;
        });

        var isNotDevelopmentLoan = R.curry(function (properties, holding) {
            var property = properties[holding.symbol];
            return !property.isDevelopmentLoan;
        });

        var requiresFunding = R.propEq('isExcludedFromAvailableFunds', true);
        var doesNotRequireFunding = R.anyPass([
            R.propEq('isExcludedFromAvailableFunds', false),
            R.propEq('isExcludedFromAvailableFunds', undefined)
        ]);

        function loadHoldings() {
            return investorService.getHoldingsPending().then(function (holdings) {

                var symbols = holdings.map(function (holding) {
                    return holding.symbol;
                });

                return propertyService.getSummaries(symbols).then(function (properties) {
                    var propertyHoldings = holdings.filter(isNotDevelopmentLoan(properties));
                    vm.holdings = propertyHoldings
                        .filter(doesNotRequireFunding)
                        .map(addDataToHoldings(properties));
                    vm.holdingsThatRequireFunding = propertyHoldings
                        .filter(requiresFunding)
                        .map(addDataToHoldings(properties));

                    vm.properties = properties;
                    vm.holdingsLoaded = true;
                });
            });
        }

        // -- scope api

        // -- main

        loadHoldings().then(function () {
            vm.ready = true;
        });
    }]
});
})(window, window.angular);