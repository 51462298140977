(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.business-summary', [
    'pp.widgets-templates',
    'pp.widgets.performance-warning',
    'pp.services.core',
    'pp.services.config',
    'pp.ui.services.wistia-video-dialog',
    'pp.widgets.expandable-content',
    'pp.widgets.radio-button-list',
    'pp.widgets.business-summary-earn',
    'pp.widgets.business-summary-ethics',
    'pp.widgets.business-summary-hard-work',
    'pp.widgets.business-summary-invest',
    'pp.widgets.business-summary-sell',
    'pp.widgets.business-summary-track',
    'pp.values.glossary'
]);

var SIGNUP_CTA = 'Sign up';

var INVEST_KEY = 'invest';
var INVEST_TITLE = 'Invest in minutes';

var EARN_KEY = 'earn';
var EARN_TITLE = 'Earn rental income';

var TRACK_KEY = 'track';
var TRACK_TITLE = 'Track investments';

var SELL_KEY = 'sell';
var SELL_TITLE = 'Sell your investments';

var WORK_KEY = 'work';
var WORK_TITLE = 'We do all the hard work';

var ETHICS_KEY = 'ethics';
var ETHICS_TITLE = 'Our code of ethics';

var SELL_VIDEO_ID = 'sap6ykfpxq';

ngModule.component('ppBusinessSummary', {
    templateUrl: 'widgets/_angular/business-summary/business-summary.tpl.html',
    bindings: {
        user: '<',
        s3Endpoint: '<'
    },
    controllerAs: 'vm',
    controller: ['$element', 'wistiaVideoDialogService', 'glossary', 'ppComponentEmitter', 'configService', function ($element, wistiaVideoDialogService, glossary, ppComponentEmitter, configService) {
        var vm = this;

        // -- initial state

        vm.activeItem = INVEST_KEY;

        vm.signupCta = SIGNUP_CTA;

        vm.sellExpanded = true;

        // - section titles

        vm.investTitle = INVEST_TITLE;
        vm.earnTitle = EARN_TITLE;
        vm.trackTitle = TRACK_TITLE;
        vm.sellTitle = SELL_TITLE;
        vm.workTitle = WORK_TITLE;
        vm.ethicsTitle = ETHICS_TITLE;

        // - section keys

        vm.investKey = INVEST_KEY;
        vm.earnKey = EARN_KEY;
        vm.trackKey = TRACK_KEY;
        vm.sellKey = SELL_KEY;
        vm.workKey = WORK_KEY;
        vm.ethicsKey = ETHICS_KEY;

        // - section images

        vm.items = [{
                title: INVEST_TITLE,
                key: INVEST_KEY
            },
            {
                title: EARN_TITLE,
                key: EARN_KEY
            },
            {
                title: TRACK_TITLE,
                key: TRACK_KEY
            },
            {
                title: SELL_TITLE,
                key: SELL_KEY
            },
            {
                title: WORK_TITLE,
                key: WORK_KEY
            },
            {
                title: ETHICS_TITLE,
                key: ETHICS_KEY
            }
        ];

        // -- util functions

        function init(baseEnpoint) {
            vm.investImage = baseEnpoint + '/invest-mobile.jpg';
            vm.earnImage = baseEnpoint + '/income-mobile.jpg';
            vm.trackImage = baseEnpoint + '/track-mobile.jpg';
            vm.sellImage = baseEnpoint + '/sell-mobile.jpg';
            vm.investMediaImage = baseEnpoint + '/buy-video-desktop.jpg';
            vm.sellMediaImage = baseEnpoint + '/sell-desktop-laptop1.jpg';

            vm.iphoneVideoWebM = baseEnpoint + '/account-transactions.webm';
            vm.iphoneVideoMp4 = baseEnpoint + '/account-transactions.mp4';
            vm.iphoneVideoOgv = baseEnpoint + '/account-transactions.ogv';
            vm.iphoneImage = baseEnpoint + '/iphone-accounthistory.png';

            vm.ipadVideoMp4 = baseEnpoint + '/dashboard-ipad.mp4';
            vm.ipadVideoWebM = baseEnpoint + '/dashboard-ipad.webm';
            vm.ipadVideoOgv = baseEnpoint + '/dashboard-ipad.ogv';
            vm.ipadImage = baseEnpoint + '/ipadhomepage1.png';

            configService.getAverageTimeToSell().then(function (data) {
                vm.avgTimeToSell = data.avgTimeToSell || '';
                vm.avgTimeToSellDate = data.avgTimeToSellDate || '';
                vm.pastTimeToSell1Label = data.pastTimeToSell1Label || '';
                vm.pastTimeToSell1Value = data.pastTimeToSell1Value || '';
                vm.pastTimeToSell2Label = data.pastTimeToSell2Label || '';
                vm.pastTimeToSell2Value = data.pastTimeToSell2Value || '';
                vm.pastTimeToSell3Label = data.pastTimeToSell3Label || '';
                vm.pastTimeToSell3Value = data.pastTimeToSell3Value || '';
            });
        }

        // -- api

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            init(vm.s3Endpoint + '/images/homepage');
        };

        vm.$postLink = function () {
            ppComponentEmitter.emit('homepage.business-summary.dom.loaded', $element);
        };

    }]
});
})(window, window.angular);