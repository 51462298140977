(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.fund', [
    'pp.widgets-templates',
    'ui.router',
    'pp.services.investor',
    'pp.services.config',
    'pp.services.core',
    'pp.services.nationality',
    'pp.services.route',
    'pp.filters.numbers',
    'pp.components.input-amount',
    'pp.components.tabs',
    'pp.widgets.investor-context-switcher',
    'pp.widgets.fund-progress-bar',
    'pp.widgets.bank-transfer',
    'pp.widgets.kyc-and-classification',
    'pp.widgets.help',
    'pp.widgets.trust-hooks',
    'pp.components.time-left'
]);

var PAGE_NAME = 'fund';
var DEFAULT_ACCOUNT = 'manual';

ngModule.component('ppFund', {
    templateUrl: 'widgets/_angular/fund/fund.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$q', '$anchorScroll', '$stateParams', 'investorService', 'configService', 'nationalityService', 'routeService', 'ppConfig', 'ppTrack', function ($scope, $q, $anchorScroll, $stateParams, investorService, configService, nationalityService, routeService, ppConfig, ppTrack) {
        var vm = this;

        // -- initial state

        var __endpoints = ppConfig.get('pp.external.endpoints') || {};
        vm.assetsEndpoint = __endpoints.assets;

        vm.step = null;

        vm.account = $stateParams.account || DEFAULT_ACCOUNT;
        vm.transactionType = 'fund';

        vm.skipClassification = false;

        vm.submitLabels = {
            general: 'Fund account',
            isa: 'Fund ISA'
        };

        vm.hideBillingAddress = false;
        vm.hideCardPayment = false;

        // defaults for the blocking dialogs when handling transaction edge cases
        var __dialogOptions = {
            clickClose: false,
            escapeClose: false,
            showClose: true,
        };

        var copy = angular.copy;

        // -- util functions

        function redirect(href) {
            ppTrack.closeAll().then(function () {
                window.location.href = href;
            });
        }

        function reload() {
            ppTrack.closeAll().then(function () {
                window.location.reload();
            });
        }

        function redirectBlockedUser() {
            window.pp.overlay.show();
            ppTrack.pageEvent(PAGE_NAME, 'requiredPermissions');
            ppTrack.closeAll().then(function () {
                redirect(routeService.dashboardPath);
            });
        }

        // returns a promise resolved when both the investor and its billing address are loaded
        function loadUser() {
            return investorService.getInvestor().then(function (user) {
                vm.user = user;

                // TODO REMOVE THIS WAS USED FOR CARD FUNDING NOT NEEDED FOR BANK TRANSFERS
                if (investorService.hasAddress(user)) {
                    return investorService.getInvestorBillingAddress().then(function (address) {
                        user.billingAddress = address;
                        return user;
                    });
                } else {
                    return user;
                }
            });
        }

        function scrollToTopIfStepIsNot(steps) {
            steps = ('string' === typeof steps) ? [steps] : steps;
            if (steps.indexOf(vm.step) === -1) {
                $(window).scrollTop(0);
            }
        }

        // @param {string} step one of [kyc, fund, confirm]
        function setStep(step) {
            var user = vm.user;
            switch (step) {
            case 'payment':
                scrollToTopIfStepIsNot('payment');
                break;
            }
            // note: state change at the bottom
            vm.step = step;
            ppTrack.flowStep(PAGE_NAME, step);
        }

        // determines next step
        // invoked on initial user load and after user is reloaded post kyc
        function initStep(isNationalitySet) {
            var user = vm.user;
            // user is yet to be kyc'ed
            if (investorService.hasPassedKyc(user) && investorService.nationalityNotRequired(isNationalitySet, user) && investorService.hasConfirmedPlatformUse(user)) {
                setStep('payment');
            } else if (!investorService.hasPassedKyc(user) || !investorService.nationalityNotRequired(isNationalitySet, user)) {
                setStep('kyc');
            } else {
                setStep('cooling-of-period');
            }
        }

        function init(user, isNationalitySet) {
            initStep(isNationalitySet);
            vm.inputEnabled = true;
            vm.showProgressPersonalDetails = !user.kyc.status || !isNationalitySet;
            vm.ready = true;
        }

        // -- api

        vm.switchContext = function (kind) {
            ppTrack.action(PAGE_NAME + '.investor-context.switch-to.' + kind);
            return investorService.switchToInvestorByKind(kind);
        };

        vm.kycSuccess = function () {
            vm.ready = false;
            // scroll to top of page to avoid seeing flash of footer when changing step
            $anchorScroll();
            $q.all({
                isNationalitySet: nationalityService.isNationalitySet(),
                user: loadUser()
            }).then(function (responses) {
                init(responses.user, responses.isNationalitySet);
            });
        };

        vm.kycFail = function () {
            redirectBlockedUser();
        };

        // -- scope bindings

        investorService.on('investor-updated', function (user) {
            vm.user = user;
        });

        // -- main
        var promises = {
            user: loadUser(),
            users: investorService.getUserInvestors(),
            isNationalitySet: nationalityService.isNationalitySet()
        };

        $q.all(promises).then(function (responses) {
            var user = responses.user;
            vm.user = user;

            if (investorService.isIsaSetup(responses.users)) {
                vm.showInvestorContextSwitcher = true;
            }

            ppTrack.setContext('account', vm.account);
            ppTrack.pageLoad(PAGE_NAME);

            init(user, responses.isNationalitySet);

        });
    }]
});
})(window, window.angular);