(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.dashboard-sidenav-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.widgets.dashboard-sidenav'
]);

/**
 * @ndgoc service
 * @description
 * checks if we need to notify the user that they have been qualified/disqualified for an rff scheme
 * shows the dialog and sets a preference when the user dismisses it
 * so that the dialog is never shown again for the same scheme
 */
ngModule.service('dashboardSidenavDialog', ['$rootScope', 'ppTrack', 'dialogService', function ($rootScope, ppTrack, dialogService) {

    // -- util functions

    var __currentInstance;

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(menuItems, investor, isIsaSetup, investorKindMap, switchAccounts) {
        var scope = $rootScope.$new();
        var options = {
            clickClose: false,
            escapeClose: true,
            showClose: true,
            scope: scope,
            classNames: 'dashboard-sidenav-dialog',
        };
        scope.menuItems = menuItems;
        scope.investor = investor;
        scope.isIsaSetup = isIsaSetup;
        scope.investorKindMap = investorKindMap;
        scope.switchAccounts = switchAccounts;

        options.contents = '<pp-dashboard-sidenav menu-items="menuItems" investor="investor" is-isa-setup="isIsaSetup" investor-kind-map="investorKindMap" switch-accounts="switchAccounts(investor)"></pp-dashboard-sidenav>';

        return dialogService.show(options);
    }

    // -- api

    var api = {};

    api.show = function (menuItems, investor, isIsaSetup, investorKindMap, switchAccounts) {
        __currentInstance = showDialog(menuItems, investor, isIsaSetup, investorKindMap, switchAccounts);
        return __currentInstance;
    };

    api.close = function () {
        dialogService.close();
    };

    return api;
}]);
})(window, window.angular);