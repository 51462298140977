(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketplace-data-view', [
    'base64',
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.data-view',
    'pp.services.random',
    'pp.services.browser-store',
    'pp.widgets.marketplace-data-view-table-primary',
    'pp.widgets.marketplace-data-view-table-secondary',
    'pp.filters.dates'
]);

var HASH_PREFIX = 'data-view';
var POLLING_PERIOD = 600000; //; 10 minutes
var DEFAULT_SORT_ATTRIBUTE = null;
var ASCENDING = 'ascending';
var DESCENDING = 'descending';
var DEFAULT_SORT_ORDER = ASCENDING;
var ID_KEY = 'symbol';
var PROPERTY_URL = '/properties/:symbol';
var PAGE_NAME = 'data-view';
var HOLDINGS_TOOLTIP_TEXT = '<span class="data-view-tooltip">You have this investment in your portfolio</span>';
var STUDENT_THEME_NAME = 'Student';
var PRIMARY_INFORMATIONAL = 'PRIMARY_INFORMATIONAL';
var SECONDARY_MARKET = 'SECONDARY_MARKET';
var PRIMARY_PENDING = 'PRIMARY_PENDING';
var SUSPENDED = 'SUSPENDED';

ngModule.component('ppMarketplaceDataView', {
    templateUrl: 'widgets/_angular/marketplace-data-view/marketplace-data-view.tpl.html',
    bindings: {
        bids: '<',
        offer: '<',
        holdings: '<',
        propertiesDict: '<',
        viewPropertyDetail: '&'
    },
    transclude: true,
    controllerAs: 'vm',
    controller: ['$scope', '$window', '$interval', '$q', '$filter', '$base64', 'ppConfig', 'ppTrack', 'dataViewService', 'investorService', 'browserStoreService', 'random', 'ppBig', function ($scope, $window, $interval, $q, $filter, $base64, ppConfig, ppTrack, dataViewService, investorService, browserStoreService, random, ppBig) {
        var vm = this;

        // -- initial state

        var __ready = false;
        var __pollingInterval = null;
        var __rawData = [];
        var __selectedItems = {};
        var __appliedHash;

        var __config = ppConfig.get('pp.services.data-view');

        vm.downloadUrl = __config ? __config.csv : null;
        vm.ready = false;
        vm.filtering = false;
        vm.filtered = false;

        vm.selectedItems = {};
        vm.pending = [];

        var __columns = [
            'location',
            'dividendAtLowestSharePrice',
            'premiumOnNewListingPrice',
            'premiumOnLatestValuation',
            'valueAtLowestSharePrice',
            'spread',
            'amountOfHighestBid',
            'totalTraded30Days',
            'newListingPrice',
            'lowestSharePrice',
            'latestValuation'
        ];

        vm.sortAttribute = DEFAULT_SORT_ATTRIBUTE;
        vm.sortOrder = DEFAULT_SORT_ORDER;

        vm.limit = 10;
        vm.limits = [{
            val: 10,
            label: '10'
        }, {
            val: 20,
            label: '20'
        }, {
            val: 50,
            label: '50'
        }, {
            val: 0,
            label: 'all'
        }];

        var $currencyFilter = $filter('currency');
        var $penceFilter = $filter('ppPence');
        var $penceWholeFilter = $filter('ppPenceWhole');
        var $numberFilter = $filter('number');
        var $percentageFilter = function (value, signed) {
            var sign = signed ? (value < 0 ? '' : '+') : '';
            return sign + $numberFilter(value, 2) + '%';
        };
        var $dateFilter = $filter('formatDateHuman');

        // -- util functions

        function getFormattedStatus(item) {
            if (item.marketState === SECONDARY_MARKET || item.marketState === SUSPENDED) {
                return;
            } else if (item.isPreorder) {
                if (item.preorderCloseTime) {
                    return 'Early Access closes ' + $dateFilter(item.preorderCloseTime, 'h:mma d MMMM');
                } else {
                    return 'Preview';
                }
            } else {
                if (item.fundRaise && item.totalFunded) {
                    return $numberFilter(100 * (item.totalFunded || 0) / item.fundRaise, 2) + '% Funded';
                } else {
                    return 'Funding';
                }
            }
        }

        function getLinkText(item) {
            if (item.marketState === SECONDARY_MARKET || item.marketState === SUSPENDED) {
                return 'View property';
            } else if (item.marketState === PRIMARY_INFORMATIONAL) {
                return 'View Early Access';
            } else {
                return 'View New Listing';
            }
        }

        // - data and polling

        function cloneAndFormatData(data) {
            return data.map(function (item) {
                var themes = item.themes || '';
                var premiumOnNewListingPrice = item.premiumOnNewListingPrice;
                var formattedStatus = getFormattedStatus(item);
                return {
                    symbol: item.symbol,
                    location: item.location,
                    href: PROPERTY_URL.replace(':symbol', item.symbol),
                    linkText: getLinkText(item),
                    marketState: item.marketState,
                    tooltipText: HOLDINGS_TOOLTIP_TEXT,
                    assetType: item.assetType,
                    // primary
                    fundRaise: {
                        raw: item.fundRaise,
                        formatted: item.fundRaise !== null ? $currencyFilter(item.fundRaise, '£', 0) : 'N/A'
                    },
                    offerClosing: {
                        raw: item.offerClosing
                    },
                    isPreorder: {
                        raw: !!item.isPreorder
                    },
                    status: {
                        formatted: formattedStatus
                    },
                    incomeYield: {
                        raw: item.incomeYield,
                        formatted: item.incomeYield !== null && item.assetType !== 'fund' ? $percentageFilter(item.incomeYield) : 'N/A'
                    },
                    lastYearHpi: {
                        raw: item.lastYearHpi,
                        formatted: (item.lastYearHpi !== null && themes.indexOf(STUDENT_THEME_NAME) === -1 && item.assetType !== 'development_loan' && item.assetType !== 'fund') ? $percentageFilter(item.lastYearHpi, true) : 'N/A'
                    },
                    // secondary
                    dividendAtLowestSharePrice: {
                        raw: item.dividendAtLowestSharePrice,
                        formatted: item.dividendAtLowestSharePrice !== null ? $percentageFilter(item.dividendAtLowestSharePrice) : 'N/A'
                    },
                    premiumOnNewListingPrice: {
                        raw: item.premiumOnNewListingPrice !== null ? premiumOnNewListingPrice : item.premiumOnNewListingPrice,
                        formatted: item.premiumOnNewListingPrice !== null ? $percentageFilter(premiumOnNewListingPrice, true) : 'N/A'
                    },
                    premiumOnLatestValuation: {
                        raw: item.premiumOnLatestValuation,
                        formatted: item.premiumOnLatestValuation !== null ? $percentageFilter(item.premiumOnLatestValuation, true) : 'N/A'
                    },
                    valueAtLowestSharePrice: {
                        raw: item.valueAtLowestSharePrice,
                        formatted: item.valueAtLowestSharePrice !== null ? $currencyFilter(item.valueAtLowestSharePrice, '£', 2) : 'N/A'
                    },
                    spread: {
                        raw: item.spread,
                        formatted: item.spread !== null ? $percentageFilter(item.spread) : 'N/A'
                    },
                    amountOfHighestBid: {
                        raw: item.amountOfHighestBid,
                        formatted: item.amountOfHighestBid !== null ? $currencyFilter(item.amountOfHighestBid, '£', 2) : 'N/A'
                    },
                    totalTraded30Days: {
                        raw: item.totalTraded30Days,
                        formatted: item.totalTraded30Days !== null ? $currencyFilter(item.totalTraded30Days, '£', 0) : 'N/A'
                    },
                    volumeWeighted30DayAveragePrice: {
                        raw: item.volumeWeighted30DayAveragePrice,
                        formatted: item.volumeWeighted30DayAveragePrice !== null ? $penceFilter(item.volumeWeighted30DayAveragePrice, 'p') : 'N/A'
                    },
                    lowestSharePrice: {
                        raw: item.lowestSharePrice,
                        formatted: item.lowestSharePrice !== null ? $penceWholeFilter(item.lowestSharePrice, 'p') : 'N/A'
                    },
                    latestValuation: {
                        raw: item.latestValuation !== null ? item.latestValuation : '',
                        formatted: item.latestValuation !== null ? $penceFilter(item.latestValuation, 'p') : 'N/A'
                    },
                    newListingPrice: {
                        raw: item.newListingPrice,
                        formatted: item.newListingPrice !== null ? $penceFilter(item.newListingPrice, 'p', 0) : 'N/A'
                    },
                    lastTradePrice: {
                        raw: item.lastTradePrice,
                        formatted: item.lastTradePrice ? $penceWholeFilter(item.lastTradePrice, 'p') : 'N/A'
                    },
                    lastTradePctChange: {
                        raw: item.lastTradePctChange,
                        formatted: angular.isDefined(item.lastTradePctChange) ? $filter('ppSignedPercentage')(item.lastTradePctChange, 2) : 'N/A'
                    },
                    netCashAsPctOfPropertyValue: {
                        raw: item.netCashAsPctOfPropertyValue,
                        formatted: angular.isDefined(item.netCashAsPctOfPropertyValue) ? $filter('ppSignedPercentage')(Number(ppBig(item.netCashAsPctOfPropertyValue).times(100)), 2) : 'N/A'
                    },
                    exitMechanicDate: {
                        raw: item.exitMechanicDate !== null ? item.exitMechanicDate : '',
                        formatted: item.exitMechanicDate !== null ? $filter('date')(item.exitMechanicDate, 'dd/MM/yyyy') : 'N/A'
                    }
                };
            });
        }

        function primarySortAndFilter(data) {

            data = data.filter(function (item) {
                return item.marketState !== SECONDARY_MARKET && item.marketState !== PRIMARY_PENDING && item.marketState !== SUSPENDED;
            });

            data = data.sort(function (itemA, itemB) {

                // same state, compare closing dates
                if (itemA.marketState === itemB.marketState) {
                    if (itemA.marketState === PRIMARY_INFORMATIONAL) {
                        return itemA.offerClosing - itemB.offerClosing;
                    } else {
                        return itemA.preorderCloseTime - itemB.preorderCloseTime;
                    }
                }

                // different states, preview first, then offered
                else if (itemA.marketState === PRIMARY_INFORMATIONAL) {
                    return -1;
                } else {
                    return 1;
                }
            });

            return data;
        }

        function applySecondaryDataSortFilterAndLimit() {

            var data = angular.copy(__rawData);

            data = data.filter(function (item) {
                if (!vm.filtering && vm.filtered) {
                    return __selectedItems[item[ID_KEY]];
                } else {
                    return item.marketState === SECONDARY_MARKET || item.marketState === SUSPENDED;
                }
            });

            // if no sort attribute applied do not sort
            if (!vm.sortAttribute) {
                if (vm.limit) {
                    data = data.splice(0, vm.limit);
                }
                return data;
            }

            data.sort(function (item1, item2) {
                if (item1[vm.sortAttribute].raw === '') {
                    return 1;
                } else if (item2[vm.sortAttribute].raw === '') {
                    return -1;
                } else if (vm.sortOrder === ASCENDING) {
                    return item1[vm.sortAttribute].raw > item2[vm.sortAttribute].raw ? 1 : -1;
                } else {
                    return item1[vm.sortAttribute].raw < item2[vm.sortAttribute].raw ? 1 : -1;
                }
            });

            if (vm.limit) {
                data = data.splice(0, vm.limit);
            }

            // if currently editing filter, will clear selected items that become hidden because of sort+limit
            if (vm.filtering) {
                // @todo
            }

            return data;
        }

        // stores last request id in order to mute responses and errors if subsquent requests are made before this one resolves/rejects
        var __requestId;

        function loadData() {
            __requestId = random.id(4);
            var promise = dataViewService.getData().then(function (data) {
                // prevent a polling result from overriding data during a filtering operation
                // and prevent race conditions between requests
                if (vm.filtering || promise.requestId !== __requestId) {
                    return;
                }
                vm.ready = true;
                __rawData = cloneAndFormatData(data);
                vm.dataPrimary = primarySortAndFilter(__rawData);
                vm.dataSecondary = applySecondaryDataSortFilterAndLimit();
                vm.updateTs = new Date();
            });
            promise.requestId = __requestId;
        }

        function stopPolling() {
            $interval.cancel(__pollingInterval);
        }

        function startPolling() {
            stopPolling();
            loadData();
            __pollingInterval = $interval(loadData, POLLING_PERIOD);
        }

        // - filter

        function resetData() {
            __selectedItems = {};
            vm.filtered = false;
            startPolling();
        }

        function cancelFilter() {
            vm.filtering = false;
            startPolling(); // re-applies current filter, unmodified
        }

        // - hash

        function applyHashSelectedItems(value) {
            vm.filtered = false;
            var ids = value.split(',');
            ids.forEach(function (id) {
                if (id.match(/[a-zA-Z0-9]+/)) {
                    __selectedItems[id] = true;
                    vm.filtered = true;
                }
            });
            ppTrack.setContext('data-view.compare.length', Object.keys(vm.selectedItems).length);
        }

        function applyHashSort(value) {
            var parts = value.split(',');
            __columns.forEach(function (col) {
                if (col === parts[0]) {
                    vm.sortAttribute = parts[0];
                }
            });
            vm.sortOrder = (parts[1] === ASCENDING) ? ASCENDING : DESCENDING;
            ppTrack.setContext('data-view.sort.column', vm.sortAttribute);
            ppTrack.setContext('data-view.sort.order', vm.sortOrder);
        }

        function applyHashLimit(value) {
            var param = parseInt(value, 10);
            vm.limits.forEach(function (item) {
                if (param === item.val) {
                    vm.limit = param;
                }
            });
            ppTrack.setContext('data-view.limit', vm.limit);
        }

        function updateHash() {
            var parts = [];
            if (vm.filtered) {
                var ids = Object.keys(__selectedItems);
                parts.push('f=' + ids.join(','));
            }
            parts.push('s=' + vm.sortAttribute + ',' + vm.sortOrder);
            parts.push('l=' + vm.limit);
            var str = parts.join(';');

            //save to sessionStorage
            __appliedHash = str;
            browserStoreService.setSessionStorageItem('pp-store.data-view.sort', __appliedHash);
        }

        function applyHash() {
            var str = browserStoreService.getSessionStorageItem('pp-store.data-view.sort');

            try {
                __appliedHash = str;
                var parts = __appliedHash.split(';');
                parts.forEach(function (part) {
                    var nameValue = part.split('=');
                    if (nameValue.length === 2) {
                        switch (nameValue[0]) {
                        case 'f':
                            applyHashSelectedItems(nameValue[1]);
                            break;
                        case 's':
                            applyHashSort(nameValue[1]);
                            break;
                        case 'l':
                            applyHashLimit(nameValue[1]);
                            break;
                        }
                    }
                });
            } catch (err) {
                updateHash();
            }
        }

        // -- api

        vm.editFilter = function () {
            ppTrack.pageAction(PAGE_NAME, 'compare.start.click');
            stopPolling();
            vm.filtering = true;
            vm.dataSecondary = applySecondaryDataSortFilterAndLimit();
            vm.selectedItems = angular.copy(__selectedItems);
        };

        vm.canApplyFilter = function () {
            var ids = Object.keys(vm.selectedItems);
            var count = ids.reduce(function (count, id) {
                return count + vm.selectedItems[id];
            }, 0);
            return count >= 2;
        };

        vm.cancelFilter = function () {
            ppTrack.pageAction(PAGE_NAME, 'compare.cancel.click');
            cancelFilter();
        };

        vm.applyFilter = function () {
            if (vm.canApplyFilter()) {
                ppTrack.setContext('data-view.compare.length', Object.keys(vm.selectedItems).length);
                ppTrack.pageAction(PAGE_NAME, 'compare.finish.click');
                __selectedItems = angular.copy(vm.selectedItems);
                vm.filtered = true;
                vm.filtering = false;
                vm.dataSecondary = applySecondaryDataSortFilterAndLimit();
                updateHash();
            }
        };

        vm.resetFilter = function () {
            ppTrack.unsetContext('data-view.compare.length');
            ppTrack.pageAction(PAGE_NAME, 'compare.reset.click');
            __selectedItems = {};
            vm.filtered = false;
            vm.dataSecondary = applySecondaryDataSortFilterAndLimit();
            updateHash();
        };

        vm.updateSort = function (attr, order) {
            ppTrack.setContext('data-view.sort.column', attr);
            ppTrack.setContext('data-view.sort.order', order);
            ppTrack.pageAction(PAGE_NAME, 'sort', attr);
            vm.sortAttribute = attr;
            vm.sortOrder = order;
            vm.dataSecondary = applySecondaryDataSortFilterAndLimit(); // if currently editing filter, will clear selected items that become hidden because of sort+limit
            updateHash();
        };

        vm.trackDownload = function () {
            ppTrack.pageAction(PAGE_NAME, 'download.csv.click');
        };

        vm.onViewPropertyDetail = function (symbol) {
            vm.viewPropertyDetail({
                symbol: symbol
            });
        };

        // -- scope bindings

        $scope.$watch('vm.limit', function (newVal, oldVal) {
            if (newVal !== oldVal) {
                $window.scrollTo(0, 0);
                ppTrack.unsetContext('data-view.compare.length');
                ppTrack.setContext('data-view.limit', newVal);
                ppTrack.pageAction(PAGE_NAME, 'limit', newVal);
                cancelFilter(); // cancels filtering if currently editing filter
                updateHash();
                if (__ready) {
                    resetData();
                }
            }
        });

        vm.$onDestroy = function () {
            stopPolling();
        };

        // -- main

        vm.$onInit = function () {

            if ($window.location.hash.match(new RegExp('^#' + HASH_PREFIX))) {
                applyHash();
            }

            var holdingsPromises = [];
            investorService.getInvestor().then(function (user) {
                if (!user.anon) {
                    // load pending and current holdings for current investor
                    // and create an array of holdings (just the symbols)
                    // so that we can visually identify all of them (house icon + tooltip)
                    // note: rejected promises are ignored with angular.noop
                    holdingsPromises.push(investorService.getHoldingsPending().then(function (data) {
                        vm.pending = data.map(function (item) {
                            return item.symbol; // note: /investor/pending endpoint properties have symbol
                        });
                    }, angular.noop));

                    $q.all(holdingsPromises).then(function () {
                        __ready = true;
                        startPolling();
                    });
                } else {
                    __ready = true;
                    startPolling();
                }
            });
        };
    }]
});
})(window, window.angular);