(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.valuation-method-select', [
    'pp.widgets-templates',
    'pp.values.glossary',
    'pp.components.tooltip',
    'pp.services.route'
]);

ngModule.component('ppValuationMethodSelect', {
    templateUrl: 'widgets/_angular/valuation-method-select/valuation-method-select.tpl.html',
    bindings: {
        valuationMethod: '<',
        onChange: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$q', 'glossary', 'routeService', function ($scope, $q, glossary, routeService) {
        var vm = this;

        // -- initial state

        vm.valuationMethodDesc = glossary.valuationMethod.desc;

        vm.vpvIvExplainer = routeService.vpvIvExplainer;

        vm.toggleItems = [{
                value: 'vpv',
                label: 'VPV: Vacant Possession Value'
            },
            {
                value: 'iv',
                label: 'IV: Investment Value'
            }
        ];

        // -- util functions

        // -- api

        vm.valuationUpdated = function () {
            vm.updating = true;

            var promise = $q.all([
                $q.when(),
                vm.onChange({
                    valuationMethod: vm.valuationMethod
                })
            ]);

            promise.then(function () {
                vm.updating = false;
            }, function () {
                vm.updating = false;
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);