(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.performance-graph', [
    'pp.widgets-templates',
    'highcharts-ng',
    'pp.values.colours',
    'pp.services.core',
    'pp.filters.numbers'
]);

ngModule.directive('ppPerformanceGraph', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/performance-graph/performance-graph.tpl.html',
        scope: {
            graphData: '<',
            totals: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$filter', '$timeout', 'colours', 'ppMoment', 'ppBig', 'R', function ($scope, $filter, $timeout, colours, ppMoment, ppBig, R) {
            var vm = this;

            // -- initial state

            // -- util functions

            function createGraphPoint(value) {
                var total = ppBig(value.income)
                    .plus(value.valuationGains)
                    .plus(value.premium)
                    .plus(value.realisedGains)
                    .plus(value.interest)
                    .plus(value.promotions)
                    .minus(value.totalCharges);
                return {
                    y: Number(total),
                    x: new Date(value.datePoint),
                    name: ppMoment(value.datePoint).format('MM/YY'),
                    type: 'area',
                    shortName: 'IV',
                    realisedGains: value.realisedGains,
                    valuationGains: Number(ppBig(value.valuationGains).plus(value.premium)),
                    interest: value.interest,
                    income: value.income,
                    promotions: value.promotions,
                    totalCharges: value.totalCharges,
                    color: colours.$color_brand,
                    marker: {
                        radius: 3
                    }
                };
            }

            function createGraphPointForVpv(value) {
                var total = ppBig(value.income)
                    .plus(value.vpvValuationGains)
                    .plus(value.premium)
                    .plus(value.realisedGains)
                    .plus(value.interest)
                    .plus(value.promotions)
                    .minus(value.totalCharges);

                return {
                    y: Number(total),
                    x: new Date(value.datePoint),
                    name: ppMoment(value.datePoint).format('MM/YY'),
                    type: 'area',
                    shortName: 'VPV',
                    realisedGains: value.realisedGains,
                    valuationGains: Number(ppBig(value.vpvValuationGains).plus(value.premium)),
                    interest: value.interest,
                    income: value.income,
                    promotions: value.promotions,
                    totalCharges: value.totalCharges,
                    color: colours.residentialVpvSharesIndex,
                    marker: {
                        radius: 3
                    }
                };
            }

            function formatGraphData(graphData) {
                return graphData.map(createGraphPoint);
            }

            function formatVpvGraphData(graphData) {
                return graphData.map(createGraphPointForVpv);
            }

            function setupMinZero() {
                vm.chartConfig.yAxis.min = 0;
                vm.chartConfig.yAxis.minRange = 1;
            }

            function setupGraphData(graphData, vpvGraphData) {

                vm.chartConfig = {
                    chart: {
                        height: 300,
                        style: {
                            fontFamily: 'Proxima',
                            fill: 'rgba(255,255,255,0)',
                        },
                        backgroundColor: 'rgba(255,255,255,0)',
                        showAxes: true
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            borderWidth: 0
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        useHTML: true,
                        backgroundColor: '#FFF',
                        formatter: function () {
                            var html = '';
                            html += '<div class="date">' + ppMoment(this.x, 'MM/YY').format('5 MMMM YYYY') + '</div>';
                            html = html + '<div class="values">';
                            html = html + '<div class="entry dividends"><span class="label">Dividends received</span><span class="value">' + $filter('ppCurrency')(this.point.income, '£', 0) + '</span></div>';
                            html = html + '<div class="entry capital-gains"><span class="label">Realised capital gains</span><span class="value">' + $filter('ppCurrency')(this.point.realisedGains, '£', 0) + '</span></div>';
                            if (this.point.interest) {
                                html = html + '<div class="entry interest"><span class="label">Interest received</span><span class="value">' + $filter('ppCurrency')(this.point.interest, '£', 0) + '</span></div>';
                            }
                            if (this.point.promotions) {
                                html = html + '<div class="entry promotions"><span class="label">Promotions</span><span class="value">' + $filter('ppCurrency')(this.point.promotions, '£', 0) + '</span></div>';
                            }
                            html = html + '<div class="entry valuation-gains"><span class="label">Unrealised capital gains</span><span class="value">' + $filter('ppCurrency')(this.point.valuationGains, '£', 0) + '</span></div>';
                            html = html + '<div class="entry fees-and-taxes"><span class="label">Fees and tax</span><span class="value">-' + $filter('ppCurrency')(this.point.totalCharges, '£', 0) + '</span></div>';
                            html = html + '<div class="total"><span class="label">' + this.point.shortName + ' Performance</span><span class="value">' + $filter('ppCurrency')(this.point.y, '£', 0) + '</span></div>';
                            html = html + '</div>';
                            return html;
                        }
                    },
                    title: {
                        text: '',
                        style: {
                            fontSize: '0px'
                        }
                    },
                    subtitle: {
                        text: '',
                        style: {
                            fontSize: '0px'
                        }
                    },
                    xAxis: {
                        title: {
                            text: null,
                            style: {
                                color: colours.$gry1_8,
                                fontSize: '14px'
                            }
                        },
                        lineColor: colours.$gry1_8,
                        lineWidth: 1,
                        minorTickLength: 6,
                        tickLength: 10,
                        tickInterval: 1,
                        tickmarkPlacement: 'on',
                        tickWidth: 1,
                        tickColor: colours.$gry1_8,
                        align: 'center',
                        startOnTick: true,
                        labels: {
                            format: '{value:%m/%Y}',
                            enabled: true,
                            align: 'center',
                            style: {
                                fontSize: '10px',
                                align: 'center',
                                width: '40px',
                                'min-width': '40px'
                            },
                            y: 25
                        }
                    },
                    yAxis: {
                        title: {
                            text: null,
                            style: {
                                fontSize: '12px'
                            }
                        },
                        offset: 20,
                        lineWidth: 0,
                        minorGridLineWidth: 0,
                        gridLineWidth: 1,
                        gridLineColor: colours.$gry1_8,
                        minTickInterval: 1,
                        startOnTick: false,
                        endOnTick: false,
                        minRange: 3,
                        tickPositioner: function () {
                            if (this.dataMin === 0 && graphData.length > 0) {
                                setupMinZero();
                            }
                        },
                        tickLength: 20,
                        tickPosition: 'inside',
                        maxPadding: 0.1,
                        tickWidth: 1,
                        tickColor: colours.$gry1_8,
                        labels: {
                            style: {
                                fontSize: '12px'
                            },
                            align: 'left',
                            x: 0,
                            y: -8,
                            formatter: function () {
                                var minus = '';
                                if (this.value < 0) {
                                    minus = '-';
                                }
                                return minus + $filter('absCurrency')(this.value); // @todo conversion-phase-2 .formatMoney(0, '.', ',')
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                            name: 'Investment value performance',
                            shortName: 'IV',
                            data: graphData,
                            color: colours.$color_brand
                        },
                        {
                            name: 'Vacant possession value performance',
                            shortName: 'VPV',
                            color: colours.residentialVpvSharesIndex,
                            data: vpvGraphData
                        }

                    ]
                };

            }

            // -- api

            // -- scope bindings

            // -- main
            vm.$onInit = function () {
                setupGraphData(
                    vm.graphData.map(createGraphPoint),
                    vm.graphData.filter(function (item) {
                        return R.not(R.isNil(R.path(['vpvValuationGains'], item)));
                    }).map(createGraphPointForVpv)
                );
            };

        }]
    };
}]);
})(window, window.angular);