(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-map', [
    'pp.widgets-templates',
    'pp.services.dashboard',
    'pp.services.core',
    'pp.widgets.property-map',
    'pp.ui.services.dashboard-individual-property-sidebar'
]);

ngModule.component('ppDashboardMap', {
    templateUrl: 'widgets/_angular/dashboard-map/dashboard-map.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'dashboardService', 'dashboardIndividualPropertySidebarService', 'ppTrack', function ($scope, dashboardService, dashboardIndividualPropertySidebarService, ppTrack) {
        var vm = this;

        // -- initial state

        vm.mapReady = false;

        // -- util functions

        // -- api

        vm.onChange = angular.noop;

        vm.onSelectedProperty = function (symbol) {
            ppTrack.action('property.click', {
                symbol: symbol
            });

            return dashboardIndividualPropertySidebarService.show(vm.data.properties[symbol], vm.data.holdings[symbol], vm.onChange);
        };

        vm.init = function () {
            return dashboardService.getTotalReturn().then(function (res) {
                vm.data = res;
                return res.locations;
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);