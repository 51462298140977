(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.time-left', [
    'pp.filters.dates'
]);

/**
 * @ngdoc directive
 * @name ppTimeLeft
 * @description
 * Renders the time left into a span
 *
 * @restrict A
 * @scope
 * @param {string} ppTimeLeft
 */

ngModule.directive('ppTimeLeft', function () {
    return {
        restrict: 'A',
        template: '<span class="pp-time-left" ng-bind="vm.formatedTime"></span>',
        scope: {
            dueDate: '<ppTimeLeft'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$filter', function ($scope, $filter) {
            var vm = this;
            vm.ready = false;

            // -- initial state

            // -- api

            function formatTime(date, serverTime) {
                var jsDate = angular.isDate(date) ? date : new Date(date);
                return $filter('ppTimeLeft')(serverTime, jsDate);
            }

            // -- main

            vm.$onChanges = function (changes) {
                if (changes.dueDate && changes.dueDate.currentValue) {
                    vm.formatedTime = formatTime(changes.dueDate.currentValue, window.pp.serverTime);
                }
            };

        }]
    };
});
})(window, window.angular);