(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.what-to-expect', ['pp.services.config']);

var titles = {
    weAreGrowingFast: 'We are growing fast',
    incomeOpportunities: 'Income opportunities',
    capitalGrowth: 'Capital Growth'
};

var buttons = [{
        key: 'weAreGrowingFast',
        title: titles.weAreGrowingFast
    },
    {
        key: 'incomeOpportunities',
        title: titles.incomeOpportunities
    },
    {
        key: 'capitalGrowth',
        title: titles.capitalGrowth
    }
];

var content = {
    weAreGrowingFast: [{
            figure: '{{assetsUnderManagement}}',
            title: 'Assets under management',
            description: '<i class="icon i-arrow-up-large"></i><span class="growth-figure">{{assetsUnderManagementPctChange}}</span>{{peformanceFiguresDateChange}}',
        },
        {
            figure: '{{capitalReturnedToInvestors}}',
            title: 'Capital returned to investors',
            description: '<i class="icon i-arrow-up-large"></i><span class="growth-figure">{{capitalReturnedToInvestorsPctChange}}</span>{{peformanceFiguresDateChange}}'
        },
        {
            figure: '{{rentalIncomeReturnedToInvestors}}',
            title: 'Rental income returned to investors',
            description: '<i class="icon i-arrow-up-large"></i><span class="growth-figure">{{rentalIncomeReturnedToInvestorsPctChange}}</span>{{peformanceFiguresDateChange}}'
        }
    ],
    incomeOpportunities: [{
            figure: '{{averagePbsaYield}}',
            title: 'Average PBSA Yield',
            description: 'To date, this is the weighted average dividend yield for Purpose Built Student Accommodation (PBSA) based on their valuation when newly listed on Property Partner. It only includes fully funded properties.'
        },
        {
            figure: '{{ukDividendAllowance}}',
            title: 'UK Dividend Allowance',
            description: 'Customers who pay tax in the UK can use their annual tax free dividend allowance for income earned on Property Partner.'
        }
    ],
    capitalGrowth: [{
            figure: '{{averageCapitalGrowth}}',
            title: 'Average Capital Growth',
            description: 'Based on independent RICS valuations, the estimated weighted average annualised capital growth across all properties as at {{averageCapitalGrowthDate}}, since platform launch in January 2015 is {{averageCapitalGrowth}}. The weighted average annualised capital growth across all properties was: {{past1AverageCapitalGrowth}} for the 12-month period ending {{past1AverageCapitalGrowthDate}}; {{past2AverageCapitalGrowth}} for the 12-month period ending {{past2AverageCapitalGrowthDate}}; {{past3AverageCapitalGrowth}} for the 12-month period ending {{past3AverageCapitalGrowthDate}}.<br/><span class="highlighted">Past performance is not a reliable indicator of future performance.</span>'
        },
        {
            figure: '{{ukCapitalGainsAllowance}}',
            title: 'UK Capital Gains Allowance',
            description: 'Customers who pay tax in the UK can use their annual tax free capital gains allowance for capital gains earned on Property Partner.'
        }
    ]
};

var weAreGrowingFastNote = 'Figures in green represent a net change in the displayed figures up to {{growthFiguresNoteDate}}';

ngModule.service('whatToExpectService', ['$rootScope', '$interpolate', 'configService', function ($rootScope, $interpolate, configService) {

    var api = {};

    function createNewScope(data) {
        var scope = $rootScope.$new();
        for (var key in data) {
            scope[key] = data[key];
        }
        return scope;
    }

    function insertDataIntoItem(scope) {
        return function (item) {
            var newItem = angular.copy(item);
            newItem.figure = $interpolate(newItem.figure)(scope);
            newItem.description = $interpolate(newItem.description)(scope);
            return newItem;
        };
    }

    function insertDataIntoContent(content, scope) {

        var insertData = insertDataIntoItem(scope);

        return {
            weAreGrowingFast: content.weAreGrowingFast.map(insertData),
            incomeOpportunities: content.incomeOpportunities.map(insertData),
            capitalGrowth: content.capitalGrowth.map(insertData)
        };
    }

    function insertDataIntoNote(note, scope) {
        return $interpolate(note)(scope);
    }

    api.get = function () {
        return configService.getPromotionalFigures().then(function (data) {

            var scopedData = createNewScope(data);

            return {
                buttons: buttons,
                titles: titles,
                content: insertDataIntoContent(content, scopedData),
                weAreGrowingFastNote: insertDataIntoNote(weAreGrowingFastNote, scopedData)
            };
        });
    };

    return api;

}]);
})(window, window.angular);