(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.smart-input-date', ['pp.components-templates', 'pp.services.core', 'pp.filters.dates', 'pp.components.validation-date']);

var DATE_INPUT_CLASS = '.date-input';
var ENTER_KEY = 13;

ngModule.component('ppSmartInputDate', {
    templateUrl: 'components/_angular/smart-input-date/smart-input-date.tpl.html',
    bindings: {
        date: '=',
        dateMin: '<',
        dateMax: '<',
        isRequired: '<',
        isFocus: '=?',
        ppId: '@'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', '$filter', '$document', 'ppTrack', function ($scope, $element, $filter, $document, ppTrack) {
        var vm = this;
        var __input;
        // -- initial state

        // -- util functions

        var dayOutputFilter = $filter('dateOutputTextInThePast');
        var dayInputFilter = $filter('dateInputTextInThePast');

        function blurInput() {
            if (__input) {
                __input.blur();
            }
        }

        function init() {
            vm.date = dayOutputFilter(vm.date);
            vm.isFormatted = !!vm.date;
        }

        // -- api

        vm.onFocus = function () {
            vm.isFocus = true;
            vm.isFormatted = false;
            if (vm.ppSmartInputDate.$valid) {
                vm.date = vm.oldDate || dayInputFilter(vm.date);
            }
        };

        vm.formatDate = function () {
            vm.isFocus = false;
            if (vm.ppSmartInputDate.$valid) {
                vm.oldDate = vm.date;
                vm.date = dayOutputFilter(vm.date);
                vm.isFormatted = true;
            } else {
                ppTrack.formError('smart-input-date.invalid', vm.date);
            }
        };

        vm.onType = function (event) {
            vm.isFocus = true;
            if (event.which === ENTER_KEY && vm.ppSmartInputDate.$valid) {
                blurInput();
            }
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            init();
        };

        vm.$postLink = function () {
            __input = $element.find(DATE_INPUT_CLASS);
            vm.isFocus = $document.activeElement === __input;
        };
    }]
});
})(window, window.angular);