(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.financials-forecast-income', ['pp.widgets-templates', 'pp.filters.numbers', 'pp.widgets.expandable-toggle']);

ngModule.directive('ppFinancialsForecastIncome', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/financials-forecast-income/financials-forecast-income.tpl.html',
        scope: {
            property: '<ppFinancialsForecastIncome',
            incomeDisclosure: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppUtil', 'ppBig', 'R', 'ppMoment', function ($scope, ppUtil, ppBig, R, ppMoment) {
            var vm = this;

            // -- initial state

            // -- util functions

            vm.outgoingsBreakdownVisible = false;

            var defaultToZero = R.defaultTo(0);

            var defaultHistoricRentalDate = function (property) {
                if (property.isStudent) {
                    return '2023-11-30';
                } else {
                    return '2023-12-31';
                }
            };

            function sumOptionalItems(acc, item) {
                return acc.plus(defaultToZero(item));
            }

            function formatHistoricDate(date) {
                return ppMoment(date).format('D MMMM YYYY');
            }

            function createDisclosureDisplayItem(income, date) {

                var totalOutgoings = Number([
                    income.costs.interestPayment,
                    income.costs.lettingAndManagement,
                    income.costs.insurance,
                    income.costs.maintenanceAllowance,
                    income.costs.aumFee,
                    income.costs.corporationTax,
                    income.costs.dividendPayments
                ].reduce(sumOptionalItems, ppBig(0)));

                var normalisedTotalCosts = Number([
                    income.costs.allowanceForVoidPeriod,
                    income.costs.rentArrears
                ].reduce(sumOptionalItems, ppBig(totalOutgoings)));

                var rentReceived = income.rent - R.defaultTo(0, income.costs.allowanceForVoidPeriod) - R.defaultTo(0, income.costs.rentArrears);

                var dividendsPerYear = Number(ppBig(rentReceived).minus(income.serviceCharge).minus(totalOutgoings));

                return R.mergeRight(income, {
                    date: date,
                    totalOutgoings: totalOutgoings,
                    normalisedTotalCosts: normalisedTotalCosts,
                    dividendsPerYear: dividendsPerYear,
                    rentReceived: rentReceived
                });

            }

            var getHistoricRentalDate = function (property) {
                return R.compose(
                    R.defaultTo(formatHistoricDate(defaultHistoricRentalDate(property))),
                    R.path(['metadata', 'historicRentalDate'])
                )(property);
            };

            // -- api

            // -- scope bindings

            vm.toggleOutgoingBreakdown = function () {
                vm.outgoingsBreakdownVisible = !vm.outgoingsBreakdownVisible;
            };

            // -- main

            vm.$onInit = function () {

                vm.data = [
                    createDisclosureDisplayItem(vm.property.income, getHistoricRentalDate(vm.property))
                ].concat(vm.incomeDisclosure.map(function (item) {
                    return createDisclosureDisplayItem(item.income, formatHistoricDate(item.dateTime));
                }));

            };

        }]
    };
}]);
})(window, window.angular);