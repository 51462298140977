(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.validation-unique', []);

ngModule.directive('ppValidationUnique', [function () {

    // Hash for holding the state of each group
    var groups = {};

    function removeOwnValue(key, scopeId) {
        return String(key) !== String(scopeId);
    }

    // Helper function: Determines if another value
    //                  equals the value of this field
    function determineIfUnique(group, scopeId) {
        var value = group[scopeId];

        var otherKeysInGroup = Object.keys(group).filter(function (key) {
            return removeOwnValue(key, scopeId);
        });

        return otherKeysInGroup.every(function (key) {
            var valid = value !== group[key];
            return valid;
        });
    }

    return {
        restrict: 'A',
        require: '?ngModel',
        scope: {}, // an isolate $scope is used for easier/cleaner
        // $watching and cleanup (on destruction)
        link: function postLink($scope, elem, attrs, ctrl) {

            // If there is no `ngModel` or no groupName has been specified,
            // then there is nothing we can do
            if (!ctrl || !attrs.ppValidationUnique) {
                return;
            }

            // Updates the validity state for the 'unique' error-key
            // based on the group's status
            function updateValidity(isUnique) {
                ctrl.$setValidity('unique', isUnique);
            }

            // Get a hold on the group's state object
            // (if it doesn't exist, initialize it first)
            var groupName = attrs.ppValidationUnique;

            if (groups[groupName] === undefined) {
                updateValidity(true);
                groups[groupName] = {};
            }

            var group = $scope.group = groups[groupName];

            // -- util functions

            // Updates the group's state and this control's validity
            function validate(value) {

                group[$scope.$id] = value;

                if (ctrl.$isEmpty(value)) {
                    return value;
                }

                var isUnique = determineIfUnique(group, $scope.$id);
                updateValidity(isUnique);
                return value;
            }

            // -- scope bindings

            // Clean up when the element is removed
            $scope.$on('$destroy', function () {
                delete(group[$scope.$id]);
                if (Object.keys(group).length < 1) {
                    delete(groups[groupName]);
                }

                var isUnique = determineIfUnique(group, $scope.$id);
                updateValidity(isUnique);
            });

            $scope.$watchCollection('group', function () {
                var isUnique = determineIfUnique(group, $scope.$id);
                updateValidity(isUnique);
            });

            // Make sure re-validation takes place whenever:
            //   either the control's value changes
            //   or the group's `isUnique` property changes
            ctrl.$formatters.push(validate);
            ctrl.$parsers.unshift(validate);

        }

    };
}]);
})(window, window.angular);