(function(window, angular){'use strict';
var ngModule = angular.module('pp.services.pin', ['pp.services.property', 'pp.filters.numbers']);

var PIN_ORDINAL_KEY_MAP = {
    'first': 1,
    'second': 2,
    'third': 3,
    'fourth': 4,
    'fifth': 5,
    'sixth': 6
};

var PIN_KEY_SUFFIX = '-pin-char';

var API_CONTENT_TYPE = 'application/vnd.propertypartner.feapi.auth.v1+json';
var BASE_ENDPOINT = '/feapi/r1';
var PIN_UPDATE_ENDPOINT = BASE_ENDPOINT + '/auth/secondary/pin';

/**
 * @ngdoc service
 * @name pinService
 *
 * @description
 * Provides methods to get all the offer details.
 */
ngModule.service('pinService', ['$http', '$q', 'ordinalService', function ($http, $q, ordinalService) {

    var api = {};

    function findPinObjectInErrorDetails(details) {
        for (var ix = 0; ix < details.length; ix++) {
            if (details[ix].type === 'pin') {
                return details[ix].value;
            }
        }
    }

    //normalise pin for back end turn from sting into array of object with value as numbers
    api.createPinObject = function (pin) {

        var pinArray = pin.split('');

        return {
            first: parseInt(pinArray[0]),
            second: parseInt(pinArray[1]),
            third: parseInt(pinArray[2]),
            fourth: parseInt(pinArray[3]),
            fifth: parseInt(pinArray[4]),
            sixth: parseInt(pinArray[5])
        };
    };

    /**
     * @ngdoc method
     * @name pinService#createInputsArrayFromErrorDetails
     *
     * @description
     * Creates an array of objects for the pin-multi-input widget
     *
     * Sample input
     * ```
     * [{
     *   foo: 'bar'
     *  },
     * {
     *  type: 'pin',
     *  value: {first: ''}
     * }]
     * ```
     *
     * Sample output
     * ```
     * [{
     *   label: '1st',
     *   key: '1st-pin-char',
     *   value: '',
     *   index: 1,
     *   ordinal: 'first'
     * }]
     * ```
     **/
    api.createInputsArrayFromErrorDetails = function (details) {
        if (!details) {
            return;
        }

        var pinObject = findPinObjectInErrorDetails(details);

        var inputsRequired = Object.keys(pinObject);

        return inputsRequired.map(function (input) {
            var index = PIN_ORDINAL_KEY_MAP[input];
            var label = index + ordinalService.ordinal(index);
            var key = label + PIN_KEY_SUFFIX;
            return {
                label: label,
                key: key,
                value: '',
                index: index,
                ordinal: input
            };
        });
    };

    /**
     * @ngdoc method
     * @name pinService#createPinObjectFromInputs
     *
     * @description
     * Creates a pin object for posting to backend from the multi input box format
     *
     * Sample input
     * ```
     * [{
     *  ordinal: 'first',
     *  value: '3'
     * }]
     * ```
     *
     * Sample output
     * ```
     * {
     *  first: 3
     * }
     * ```
     **/
    api.createPinObjectFromInputs = function (pinInputs) {
        var object = {};
        if (pinInputs) {
            pinInputs.forEach(function (input) {
                object[input.ordinal] = parseInt(input.value);
            });
        } else {
            object = null;
        }

        return object;
    };

    api.createPinObjectFromPlainTextInput = function (pinPlainText, pinInputs) {
        var object = {};
        if (pinInputs) {
            pinInputs.forEach(function (input, index) {
                object[input.ordinal] = parseInt(pinPlainText[index]);
            });
        } else {
            object = null;
        }

        return object;
    };

    //supports pin setup as well as pin change
    //old pin is optiona;

    api.updatePin = function (pin) {
        var pinObject;

        if (typeof pin === 'object') {
            var oldPinObject = this.createPinObject(pin.oldPin);
            var newPinObject = this.createPinObject(pin.newPin);

            pinObject = {
                oldPin: oldPinObject,
                newPin: newPinObject
            };

        } else {

            pinObject = {
                newPin: this.createPinObject(pin)
            };
        }

        var options = {};
        options.headers = {
            'Content-type': API_CONTENT_TYPE
        };

        return $http.patch(PIN_UPDATE_ENDPOINT, pinObject, options).then(function (response) {
            return true;
        }, function (error) {

            var data = error.data || {};

            switch (error.status) {

            case 499: // when oldpin is wrong
                return $q.reject({
                    reason: data.code,
                });
            default:
                return $q.reject({
                    reason: data.code || 'update-pin.error.unexpected'
                });
            }
        });
    };

    return api;

}]);
})(window, window.angular);