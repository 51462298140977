(function(window, angular){'use strict';

var ngModule = angular.module('pp.values.colours', []);

var colours = {
    $grn1_2: '#44d371',
    $blu1_2: '#0c1d31',
    $blu2_1: '#8dd2fb',
    $blu2_2: '#2AAEF5',
    $black: '#373737',
    $gry1_8: '#f1f1f1',
    $gry1_2: '#dce5ef',
    $blu_hi: '#43C7FF',
    $color_body_fg: '#666A71',
    $color_grey: '#BFBFBF',
    $color_grey_hi_2: '#ECECEC',
    $color_blue_brand: '#2C3E50',
    $dark_shadow: 'rgba(0, 0, 0, 0.75)',
    $color_brand: '#3498DB',
    $color_blue: '#3498DB',
    $color_brand_dark: '#103f7d',
    $color_red: '#E96A60',
    $color_green_light: '#47db75',
    $color_green: '#44D371',
    $color_green_medium: '#2f924e',
    $color_green_dark: '#236c3a',
    $color_yellow: '#EDE762',
    $color_blue_dark_lo: '#06172D',
    $color_blue_dark_hi: '#0C2343',
    $color_violet: '#7862A8',
    $color_orange_dark: '#AB7120',
    $color_pink: '#E960AD',
    $color_pink_dark: '#823661',
    $color_primary: '#DB1F3D',
    $color_secondary: '#0B4583',
    $color_orange: '#F85919'
};

colours['ftseLineColour'] = colours.$color_grey;
colours['propertyLineColour'] = colours.$color_brand;
colours['cashLineColour'] = colours.$color_grey_hi_2;
colours['marketIndexPositive'] = colours.$color_green;
colours['marketIndexNegative'] = colours.$color_orange;
colours['valuationVpv'] = colours.$color_brand;
colours['valuationIndexIv'] = colours.$color_brand_dark;

colours['ppxResi'] = colours.$color_green_light;
colours['ppxResiIv'] = colours.$color_yellow;
colours['ppxStudent'] = colours.$blu_hi;
colours['ppxAll'] = colours.$color_orange;

colours['allIvPropertyIndex'] = colours.$gry1_2;
colours['allIvSharesIndex'] = colours.$black;

colours['studentIvPropertyIndex'] = colours.$color_pink;
colours['studentIvSharesIndex'] = colours.$color_pink_dark;

colours['residentialIvPropertyIndex'] = colours.$color_green_medium;
colours['residentialIvSharesIndex'] = colours.$color_green_dark;

colours['residentialVpvPropertyIndex'] = colours.$color_green;
colours['residentialVpvSharesIndex'] = colours.$color_green_light;

colours['bid'] = colours.$color_primary;
colours['offer'] = colours.$color_secondary;

colours['allIv'] = colours.$gry1_2;
colours['propertyVpvLine'] = colours.$color_orange;

ngModule.value('colours', colours);
})(window, window.angular);