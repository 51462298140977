(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketplace-list-view', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.values.glossary',
    'pp.ui.services.primary-order-list-dialog',
    'pp.widgets.take-up-rights',
    'pp.services.marketplace',
    'pp.services.route',
    'pp.services.card-payment',
    'pp.services.property',
    'pp.services.preference',
    'pp.widgets.property-cell',
    'pp.widgets.vote-result-cell',
    'pp.components.table-sort',
    'pp.filters.numbers'
]);

ngModule.component('ppMarketplaceListView', {
    templateUrl: 'widgets/_angular/marketplace-list-view/marketplace-list-view.tpl.html',
    bindings: {
        properties: '<',
        blockTradeProperties: '<',
        propertiesDict: '<',
        user: '<',
        ready: '<',
        holdingsReady: '<',
        holdings: '<',
        pendingInvestments: '<',
        pendingInvestmentsReady: '<',
        voteResults: '<',
        onUpdate: '&',
        viewPropertyDetail: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$q', '$window', 'marketplaceService', 'R', 'glossary', 'routeService', 'primaryOrderListDialogService', 'cardPaymentService', 'propertyService', 'preferenceService', 'ppPubSub', 'ppBig', function ($scope, $q, $window, marketplaceService, R, glossary, routeService, primaryOrderListDialogService, cardPaymentService, propertyService, preferenceService, ppPubSub, ppBig) {
        var vm = this;

        // -- initial state

        var __currentPrimaryOrderDialog;

        vm.glossary = glossary.equityRaise;

        vm.rightsIssueKnowledgebaseLink = routeService.rightsIssueKnowledgebaseLink;
        vm.rightsIssueAdditionalInfoLink = routeService.rightsIssueAdditionalInfoLink;

        vm.defaultSortAttribute = 'fundedPct';
        vm.defaultSortOrder = 'descending';

        vm.sortAttribute = vm.defaultSortAttribute;
        vm.sortOrder = vm.defaultSortOrder;

        vm.blockListingSortAttribute = vm.defaultSortAttribute;
        vm.blockListingSortOrder = vm.defaultSortOrder;

        vm.votedMap = {};

        vm.zeroVote = {
            pctVote: 0
        };

        vm.roundUpToPenny = function (value) {
            return Number(ppBig(value).round(2, 3));
        };

        var indexBySymbol = R.indexBy(R.prop('symbol'));

        var whereYourHoldingsAndNotCommitted;
        var notNil = R.complement(R.isNil);

        // -- util functions

        function sortBy(order) {
            if (order === 'ascending') {
                return R.ascend();
            } else if (order === 'descending') {
                return R.descend();
            }
        }

        function createPrimaryOrderFromDataItem(dataItem) {
            return {
                symbol: dataItem.rightsIssueFundingSymbol,
                parentSymbol: dataItem.symbol,
                shares: dataItem.yourProportionOfRaiseShares,
                sharePrice: dataItem.sharePriceFundraise,
                total: dataItem.yourProportionOfRaise
            };
        }

        var normaliseDataItemToOrder = R.map(createPrimaryOrderFromDataItem);

        function sumDataItem(acc, item) {
            return R.mergeWith(R.add, {
                yourProportionOfRaise: item.yourProportionOfRaise
            }, acc);
        }

        function sumOrder(acc, item) {
            return R.mergeWith(R.add, {
                total: item.total,
                shares: item.shares
            }, acc);
        }

        var sumDataItems = R.reduce(sumDataItem, {
            yourProportionOfRaise: 0
        });

        var sumOrders = R.reduce(sumOrder, {
            total: 0,
            shares: 0
        });

        function processOrders(quotes, agreeToInvestmentDocs) {

            return $q.all(quotes.map(function (quote) {

                var params = {
                    mustFund: false,
                    acceptedTermsAndConditions: agreeToInvestmentDocs,
                    isAnniversaryBlockListing: vm.propertiesDict[quote.propertySymbol].isFundingForBlockListing
                };

                cardPaymentService.prepareInvestTransaction(params, vm.propertiesDict[quote.propertySymbol], quote, vm.recaptchaResponse);
                var endpoint = cardPaymentService.constructPaymentEndpoint(propertyService.isPrimaryProperty(vm.propertiesDict[quote.propertySymbol]), quote.propertySymbol);
                var payload = cardPaymentService.constructPaymentPayload(params);
                return cardPaymentService.makePayment(endpoint, payload);

            })).then(function () {
                $window.location.replace(routeService.portfolioPendingPropertiesPath);
            }, function (err) {

            });
        }

        // -- api

        vm.onViewPropertyDetail = function (property) {
            if (R.path(['rightsIssueProperty', 'state', 'status'], property) === 'primary-closed') {
                // @note early return
                return;
            }

            if (R.path(['blockTradeProperty', 'state', 'status'], property) === 'primary-closed') {
                $window.location.replace(routeService.getIndividualPropertyPath(property.symbol));
            }

            vm.viewPropertyDetail({
                property: property
            });
        };

        vm.updateSort = function (attr, order) {
            if (R.isNil(order)) {
                vm.data = R.sort(sortBy(vm.defaultSortOrder)(R.prop(vm.defaultSortAttribute)), vm.data);
                vm.sortAttribute = vm.defaultSortAttribute;
                vm.sortOrder = vm.defaultSortOrder;
            } else {
                vm.data = R.sort(sortBy(order)(R.prop(attr)), vm.data);
                vm.sortAttribute = attr;
                vm.sortOrder = order;
            }
        };

        vm.updateSortBlockListings = function (attr, order) {
            if (R.isNil(order)) {
                vm.blockTradeData = R.sort(sortBy(vm.defaultSortOrder)(R.prop(vm.defaultSortAttribute)), vm.blockTradeData);
                vm.blockListingSortAttribute = vm.defaultSortAttribute;
                vm.blockListingSortOrder = vm.defaultSortOrder;
            } else {
                vm.blockTradeData = R.sort(sortBy(order)(R.prop(attr)), vm.blockTradeData);
                vm.blockListingSortAttribute = attr;
                vm.blockListingSortOrder = order;
            }
        };

        vm.filterYourProperties = function () {
            var yourHoldings = R.filter(whereYourHoldingsAndNotCommitted, vm.data);
            if (yourHoldings && yourHoldings.length) {
                vm.data = yourHoldings;
            }

        };

        vm.resetData = function () {
            vm.data = marketplaceService.createEquityFundraiseList(R.defaultTo({}, vm.holdings), vm.properties, R.defaultTo({}, R.indexBy(R.prop('symbol'), vm.pendingInvestments)));
        };

        vm.showPrimaryOrderDialog = function () {
            var orders = normaliseDataItemToOrder(R.filter(whereYourHoldingsAndNotCommitted, vm.data));
            primaryOrderListDialogService.show(orders, vm.propertiesDict, processOrders, vm.user);
        };

        vm.login = function () {
            ppPubSub.publish('auth.modal.show', ['login']);
        };

        // -- scope bindings

        // -- main

        vm.$onChanges = function (changes) {

            var maybeProperties = R.path(['properties', 'currentValue'], changes) || vm.properties;
            var maybeHoldings = R.path(['holdings', 'currentValue'], changes) || vm.holdings;
            var maybePending = R.path(['pendingInvestments', 'currentValue'], changes) || vm.pendingInvestments;
            var maybePendingReady = R.path(['pendingInvestmentsReady', 'currentValue'], changes) || vm.pendingInvestmentsReady;

            var maybeBlockListingProperties = R.path(['blockTradeProperties', 'currentValue'], changes) || vm.blockTradeProperties;
            var maybeVotedMap = R.path(['voteResults', 'currentValue'], changes) || vm.voteResults;
            if (notNil(maybeProperties) && (notNil(maybePending) || maybePendingReady)) {
                vm.data = marketplaceService.createEquityFundraiseList(R.defaultTo({}, maybeHoldings), maybeProperties, R.defaultTo({}, R.indexBy(R.prop('symbol'), R.defaultTo([], maybePending))));
                if (R.is(Array, vm.data)) {
                    vm.total = sumDataItems(vm.data);
                }
                vm.updateSort(vm.sortAttribute, vm.sortOrder);
                vm.rightsIssuesOpen = R.all(R.equals(true))(R.map(function (item) {
                    return item.rightsIssueOpen;
                }, vm.data));

                vm.efVotesLive = R.all(R.equals(true))(R.map(function (item) {
                    return item.efAuctionVoteInProgress;
                }, vm.data));
            }

            if (notNil(maybeBlockListingProperties) && notNil(maybeVotedMap) && (notNil(maybePending) || maybePendingReady)) {
                vm.blockTradeData = marketplaceService.createBlockTradeList(maybeBlockListingProperties, maybeVotedMap, maybePending);

                vm.blockTradeOpen = R.all(R.equals(true))(R.map(function (item) {
                    var hasBlockTradeProperty = R.complement(R.isNil)(item.blockTradeProperty);
                    return (item.blockTradeOnPropertyActive || item.blockTradeOnPropertyClosed) && hasBlockTradeProperty;
                }, vm.blockTradeData));

                vm.blockListingVotesLive = R.all(R.equals(true))(R.map(function (item) {
                    return item.isExitMechanicVote;
                }, vm.blockTradeData));

                vm.updateSortBlockListings(vm.blockListingSortAttribute, vm.blockListingSortOrder);
            }

            if (notNil(maybeProperties) && notNil(maybePending)) {

                var pendingOnlyReservations = indexBySymbol(maybePending.filter(function (item) {
                    return item.units > 0;
                }));

                var isSymbolInReservations = R.complement(R.flip(R.propIs(Object))(pendingOnlyReservations));

                whereYourHoldingsAndNotCommitted = R.where({
                    yourProportionOfRaise: R.flip(R.gt)(0),
                    rightsIssueFundingSymbol: isSymbolInReservations
                });

                vm.yourHoldings = R.filter(whereYourHoldingsAndNotCommitted, vm.data);
                vm.orders = normaliseDataItemToOrder(R.filter(whereYourHoldingsAndNotCommitted, vm.data));
            }

        };

        vm.$onInit = function () {
            preferenceService.on('preferences-updated', function (preferences) {
                vm.votedMap = preferenceService.createVotingPreferenceMap(preferences);
            });

            preferenceService.getVotingPreferenceMap().then(function (votedMap) {
                vm.votedMap = votedMap;
            });
        };

    }]
});
})(window, window.angular);