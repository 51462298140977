(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.buy-receipt', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.dialog',
    'pp.filters.numbers',
    'pp.filters.property',
    'pp.filters.dates'
]);

/**
 * @ngdoc directive
 * @name ppBuyReceiptDialog
 * @description
 * Renders the buy receipt dialog
 *
 * @restrict A
 * @scope
 * @param {obj} receipt
 * @param {obj} user
 * @param {obj} property
 */

ngModule.directive('ppBuyReceipt', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/buy-receipt/buy-receipt.tpl.html',
        scope: {
            receipt: '<',
            user: '<',
            property: '<',
            onContinue: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$attrs', 'ppConfig', 'ppTrack', 'dialogService', function ($scope, $attrs, ppConfig, ppTrack, dialogService) {
            var vm = this;
            var __config = ppConfig.get('pp.external.endpoints');

            // -- initial state

            vm.s3Endpoint = __config ? __config.assets : null;

            vm.continue = function () {
                if ($attrs.onContinue) {
                    vm.onContinue();
                } else {
                    dialogService.resolveParent($scope);
                    ppTrack.action('buy-receipt.dismiss');
                }

            };

            // -- api
        }]
    };
});
})(window, window.angular);