(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.cooling-off-period-end', ['pp.widgets-templates']);

ngModule.component('ppCoolingOffPeriodEnd', {
    templateUrl: 'widgets/_angular/cooling-off-period-end/cooling-off-period-end.tpl.html',
    bindings: {
        onContinue: '&',
        onCloseAccount: '&',
        processing: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.goToConfirm = function () {
            vm.confirm = true;
        };

        vm.goBack = function () {
            vm.confirm = false;
        };

        vm.continue = function () {
            vm.onContinue();
        };

        vm.closeAccount = function () {
            vm.onCloseAccount();
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);