(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-notice-banner', [
    'pp.widgets-templates',
    'pp.services.route',
    'pp.services.core'
]);

ngModule.component('ppPropertyNoticeBanner', {
    templateUrl: 'widgets/_angular/property-notice-banner/property-notice-banner.tpl.html',
    bindings: {
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', 'R', function ($scope, routeService, R) {
        var vm = this;

        vm.closedPeriodKnowledgebase = routeService.closedPeriodKnowledgebase;
        vm.closedPeriodAnnouncements = routeService.closedPeriodAnnouncements;
        vm.exitMechanicHelp = routeService.exitMechanicHelp;

        vm.fiveYearTradingHelp = routeService.fiveYearTradingHelp;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$onChanges = function (changes) {
            var blockTradeSymbol = R.path(['property', 'currentValue', 'metadata', 'block_trade_property_symbol'], changes);
            var mortgageBondPropertySymbol = R.path(['property', 'currentValue', 'mortgageBondPropertySymbol'], changes);
            vm.blockTradeUrl = blockTradeSymbol ? routeService.getIndividualPropertyPath(blockTradeSymbol) : null;
            vm.mortgageBondUrl = mortgageBondPropertySymbol ? routeService.getIndividualPropertyPath(mortgageBondPropertySymbol) : null;
        };

        // -- main

    }]
});
})(window, window.angular);