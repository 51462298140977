(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-dividend-yield', [
    'pp.widgets-templates',
    'pp.components.tooltip',
    'pp.filters.numbers',
    'pp.values.glossary',
    'pp.widgets.aum-dividend-notice'
]);

ngModule.component('ppPropertyDetailsDividendYield', {
    templateUrl: 'widgets/_angular/property-details-dividend-yield/property-details-dividend-yield.tpl.html',
    bindings: {
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'glossary', function ($scope, glossary) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            if (vm.property && vm.property.state && vm.property.state.status === 'secondary') {
                vm.netIncomeYield = glossary.netIncomeYieldSecondary;
            } else {
                vm.netIncomeYield = glossary.netIncomeYieldPrimary;
            }
        };

    }]
});
})(window, window.angular);