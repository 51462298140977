(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-details-pre-order', ['pp.widgets-templates', 'pp.filters.numbers', 'pp.filters.dates', 'pp.components.tooltip', 'pp.values.glossary']);

/**
 * @ngdoc directive
 * @name ppPropertyCardDetailsPreOrder
 * @description
 * Renders the details for a pre-order market property.
 *
 * @restrict A
 * @scope
 * @param {Object} ppPropertyCardDetailsPreOrder the property model
 */
ngModule.directive('ppPropertyCardDetailsPreOrder', function () {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-card-details-pre-order/property-card-details-pre-order.tpl.html',
        scope: {
            property: '<ppPropertyCardDetailsPreOrder'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'glossary', function ($scope, glossary) {
            var vm = this;

            // -- initial state

            vm.netIncomeYieldPrimary = glossary.netIncomeYieldPrimary;
            vm.fiveYearHpi = glossary.fiveYearHpi;

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
});
})(window, window.angular);