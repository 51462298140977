(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.auth-login-form', [
    'ng.cork.ui.focus-on',
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.auth',
    'pp.services.investor',
    'pp.services.pin',
    'pp.services.settings',
    'pp.services.nationality',
    'pp.services.investor-compliance',
    'pp.widgets.auth-pin-setup',
    'pp.widgets.contact-us',
    'pp.widgets.nationality-lei',
    'pp.widgets.kyc-and-classification',
    'pp.components.bind-invalid-model',
    'pp.components.input-pin',
    'pp.components.focus-first-error',
    'pp.components.checkbox'
]);

var REFRESH_REGEXP = /^\/(property\/|properties\/|s\/)/;

var LOGIN_REDIRECT_DEFAULT = '/s';

var IMAGE_BASE_URL = 'https://d2ofd11qqzygs0.cloudfront.net/images/icons/';

/**
 * @ngdoc directive
 * @name ppAuthLoginForm
 * @description
 * Displays a login dialog.
 *
 * **EVENTS**
 * - broadcasts on `$scope` event `pp.input-pin.setFocus` with no payload (consumed by ppInputPin)
 *
 * @restrict A
 * @scope
 * @param {string} rawEmail Shares between login and signup even when invalid
 * @param {function} onSuccess Invoked if login is successful
 * @param {function} onToggleMode Invoked if user wishes to signup instead
 */
ngModule.directive('ppAuthLoginForm', ['ppTrack', function (ppTrack) {

    var FORM_NAME = 'auth.login';

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/auth-login-form/auth-login-form.tpl.html',
        scope: {
            rawEmail: '=',
            sessionExpired: '=',
            onSuccess: '&',
            onToggleMode: '&',
            onChangeStep: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$q', 'R', 'ppBrowser', 'authService', 'pinService', 'investorService', 'settingsService', 'nationalityService', 'investorComplianceService', 'investorComplianceValues', function ($scope, $timeout, $q, R, ppBrowser, authService, pinService, investorService, settingsService, nationalityService, investorComplianceService, investorComplianceValues) {
            var vm = this;

            // -- initial state

            var __destroyed;
            var __email;
            var __loginPassword;

            vm.form = {};
            vm.formPinEntry = {};
            vm.formMfa = {};
            vm.formPinSetup = {};

            vm.pinPlainText = '';

            // -- util functions

            function isPinValid() {
                return vm.pinInputs && vm.pinInputs.reduce(function (valid, item) {
                    return valid && item.value && !!item.value.length;
                }, true);
            }

            // If Nationality is not set has a side effect of fetching the nationalitiesList for the nationality form
            function isNationalitySet() {

                return nationalityService.isNationalitySet().then(function (set) {
                    if (set) {
                        return set;
                    } else {
                        return nationalityService.getNationalityList().then(function (nationalitiesList) {
                            vm.nationalitiesList = nationalitiesList;
                            return set;
                        });
                    }
                });
            }

            function isLeiAvailable() {
                return investorComplianceService.getLeiWithoutType().then(function (lei) {
                    vm.lei = lei;
                    vm.isRefreshingLei = !!lei;
                    vm.isLeiExpired = investorComplianceService.isLeiExpired(lei);
                    return !!lei && !vm.isLeiExpired;
                });
            }

            function showLeiOrNationalityStep(user) {
                if (!user.kyc.status) {
                    return $q.when(false);
                }

                var promise = user.kyc.business ? isLeiAvailable() : isNationalitySet();
                return promise.then(function (isSet) {
                    return !isSet;
                });
            }

            function disableModalClose() {
                $.modal.disableClose();
            }

            function setStep(step) {
                ppTrack.event('auth.login.' + step + '-required');
                ppTrack.flowStep('auth.login', step);
                vm.step = step;
                vm.onChangeStep({
                    step: step
                });
            }

            function authenticate(email, loginPassword, pinObject) {
                return authService.login(email, loginPassword, pinObject, vm.mfacode, vm.mfaRememberMe).then(function (result) {
                    if (!__destroyed) {
                        ppTrack.event(FORM_NAME + '.success');
                        ppTrack.formSubmit(FORM_NAME);
                    }
                }, function (error) {
                    vm.processing = false;

                    switch (error.reason) {
                    case 'mfa.token.required':
                        vm.error = error.reason;
                        vm.mfaRequired = true;
                        $timeout(function () {
                            $scope.$broadcast('pp.input-mfa.setFocus');
                        });
                        setStep('mfa-start');
                        break;
                    case 'pin.required':
                        vm.pinInputs = pinService.createInputsArrayFromErrorDetails(error.details);
                        setStep('pin-entry');
                        ppTrack.event('auth.login.pin-entry.start');
                        // allows pp-input-pin to render and bind listener
                        $timeout(function () {
                            $scope.$broadcast('pp.input-pin.setFocus');
                        });
                        break;
                    case 'pin.setup.required':
                        setStep('pin-setup');
                        ppTrack.event('auth.login.pin-setup.start');
                        $timeout(function () {
                            $scope.$broadcast('pp.pin-setup.pin-entry.focus');
                        });
                        disableModalClose();
                        break;
                    case 'pin.invalid':
                        vm.pinInputs = pinService.createInputsArrayFromErrorDetails(error.details);
                        vm.error = error.reason;
                        ppTrack.formError('auth.login.pin-entry', vm.error);
                        break;
                    default:
                        vm.error = error.reason;
                        ppTrack.formError(FORM_NAME, error.reason);
                    }

                    return $q.reject(error);
                });
            }

            function redirect() {
                vm.onSuccess({
                    fallbackPath: LOGIN_REDIRECT_DEFAULT
                });
            }

            function handleLeiOrNationalityStep(user) {
                showLeiOrNationalityStep(user).then(function (showLeiNationalityStep) {
                    if (showLeiNationalityStep) {
                        disableModalClose();
                        vm.error = null;
                        setStep('nationality-lei');
                    } else {
                        redirect();
                    }
                }, redirect);
            }

            // -- api

            vm.skip = function () {
                //Handles slow redirect when skipping nationality-lei number
                ppTrack.deferAction('auth.login.nationality-lei.skip');
                redirect();
            };

            vm.complete = function () {
                investorService.getInvestor().then(function (user) {
                    vm.user = user;
                    if (!investorService.hasPassedKyc(user)) {
                        if (investorService.isClassifiedUser(user)) {
                            setStep('kyc');
                        } else {
                            setStep('classification');
                        }

                    } else {
                        handleLeiOrNationalityStep(user);
                    }
                }, redirect);
            };

            vm.noMoreKycAndClassificationInfoNeeded = function () {
                investorService.getInvestor().then(handleLeiOrNationalityStep, redirect);
            };

            vm.toggleMode = function () {
                if (!vm.processing && !__destroyed) {
                    vm.onToggleMode({
                        mode: 'signup'
                    });
                }
            };

            vm.onKycLoad = function () {
                vm.kycLoaded = true;
            };

            vm.authenticateWithPin = function () {
                if (!__destroyed && !vm.processing) {
                    if (vm.formPinEntry.$valid) {
                        vm.error = null;
                        vm.processing = true;
                        var pinObject = pinService.createPinObjectFromPlainTextInput(vm.pinPlainText, vm.pinInputs);
                        authenticate(__email, __loginPassword, pinObject).then(function () {
                            ppTrack.event(FORM_NAME + '.success');
                            vm.complete();
                        });
                    } else {
                        vm.feedbackEnabled = true;
                        if (!isPinValid()) {
                            vm.error = 'pin.required';
                        }
                        ppTrack.ngFormValidation(FORM_NAME, vm.formPinEntry, ['password']);
                    }
                }
            };

            vm.login = function () {
                if (!__destroyed && !vm.processing) {
                    if (vm.form.$valid) {
                        vm.error = null;
                        vm.processing = true;
                        __email = angular.copy(vm.email);
                        __loginPassword = angular.copy(vm.loginPassword);
                        authenticate(__email, __loginPassword).then(function () {
                            ppTrack.event(FORM_NAME + '.success');
                            vm.complete();
                        });
                    } else {
                        vm.feedbackEnabled = true;
                        ppTrack.ngFormValidation(FORM_NAME, vm.form, ['password']);
                    }
                }
            };

            vm.loginWithMfa = function () {
                if (!__destroyed && !vm.processing) {
                    if (vm.formMfa.$valid) {
                        vm.error = null;
                        vm.processing = true;
                        __email = angular.copy(vm.email);
                        __loginPassword = angular.copy(vm.loginPassword);
                        authenticate(__email, __loginPassword).then(function () {
                            ppTrack.event(FORM_NAME + '.success');
                            vm.complete();
                        });
                    } else {
                        vm.feedbackEnabled = true;
                        ppTrack.ngFormValidation(FORM_NAME, vm.formMfa, ['password']);
                    }
                }
            };

            vm.setupPin = function () {
                if (!__destroyed && !vm.processing) {
                    if (vm.formPinSetup.$valid) {
                        vm.error = null;
                        vm.processing = true;
                        var pinObject = pinService.createPinObject(vm.pin);
                        authenticate(__email, __loginPassword, pinObject).then(function () {
                            ppTrack.deferEvent('auth.login.pin-activated');
                            vm.complete();
                        }, function (error) {
                            vm.error = error.reason;
                            vm.processing = false;
                            //clear form to retry
                            vm.pinInputs = null;
                            ppTrack.formError('auth.login.pin-setup', vm.error);
                        });
                    } else {
                        vm.feedbackEnabled = true;
                        ppTrack.ngFormValidation(FORM_NAME, vm.formPinSetup, ['pin']);
                    }
                }
            };

            //show contact us widget
            vm.showContact = function () {
                vm.contactUs = !vm.contactUs;
            };

            function refreshLeiExpiry(lei) {
                vm.processing = true;
                vm.identifierError = undefined;
                vm.isLeiExpired = false;

                return investorComplianceService.checkLeiValidity(lei).then(function (res) {
                    if (res.validity === investorComplianceValues.LEI_ISSUED) {
                        return investorComplianceService.getLeiType().then(function (leiType) {
                            return investorComplianceService.updateLei(leiType.id, lei)
                                .then(redirect);
                        });
                    } else {
                        vm.processing = false;
                        vm.identifierError = investorComplianceService.createLeiRejectionReason(res.validity);
                        return $q.reject({
                            reason: vm.identifierError
                        });
                    }
                }, function (err) {
                    vm.processing = false;
                    vm.identifierError = R.prop('reason', err);
                    return $q.reject(err);
                });
            }

            vm.onSubmitNationality = function (nationalityData) {
                return nationalityService.replaceInvestorNationalitiesAndIdentifiers(nationalityData).then(function () {
                    redirect();
                });
            };

            vm.onSubmitLei = function (lei) {
                if (vm.isRefreshingLei) {
                    return refreshLeiExpiry(lei);
                } else {
                    return investorComplianceService.getLeiType().then(function (leiType) {
                        return investorComplianceService.postLei(leiType.id, lei).then(function () {
                            redirect();
                        }, function (err) {
                            vm.identifierError = R.prop('reason', err);
                            return $q.reject(err);
                        });
                    });
                }
            };

            // -- scope bindings

            $scope.$on('$destroy', function () {
                ppTrack.formClose(FORM_NAME);
                __destroyed = true;
            });

            investorService.on('investor-updated', function (user) {
                vm.user = user;
                if (!user.anon && !investorService.hasPassedKyc(user)) {
                    if (investorService.isClassifiedUser(user)) {
                        setStep('kyc');
                    } else {
                        setStep('classification');
                    }
                }
            });

            // -- main

            vm.$onInit = function () {
                vm.focusOn = ppBrowser.isMobile ? '' : 'auto';
                vm.email = vm.rawEmail;
                vm.emailRegexp = authService.emailRegexp;
                vm.patternMfa = '[0-9]*$';

                vm.step = 'password';
                vm.contactUs = false;
            };

            ppTrack.formStart(FORM_NAME, null, true);

        }]
    };
}]);
})(window, window.angular);