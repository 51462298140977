(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.figure-title-description', ['pp.widgets-templates', 'pp.components.tooltip']);

ngModule.component('ppFigureTitleDescription', {
    templateUrl: 'widgets/_angular/figure-title-description/figure-title-description.tpl.html',
    bindings: {
        data: '<'
    },
    controllerAs: 'vm',
    controller: ['$sce', '$interpolate', function ($sce, $interpolate) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$onChanges = function (changes) {
            if (!changes.data) {
                return;
            }

            var data = changes.data.currentValue || {};
            vm.description = $sce.trustAsHtml(data.description);
        };

        // -- main

    }]
});
})(window, window.angular);