(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-diagram', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.dialog'
]);

ngModule.component('ppPropertyDiagram', {
    templateUrl: 'widgets/_angular/property-diagram/property-diagram.tpl.html',
    bindings: {
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$rootScope', 'dialogService', 'ppConfig', 'ppBrowser', 'ppTrack', function ($rootScope, dialogService, ppConfig, ppBrowser, ppTrack) {

        var vm = this;

        // -- initial state

        var endpoints = ppConfig.get('pp.external.endpoints') || {};
        var __baseUrl = endpoints.assets;

        // -- util functions

        // -- api

        vm.openModal = function () {
            if (ppBrowser.isMobile) {
                return;
            }

            ppTrack.action('property-diagram.modal.open');

            var scope = $rootScope.$new();

            var options = {
                classNames: 'pp-property-diagram-dialog',
                scope: scope,
                showClose: true,
                escapeClose: true,
                clickClose: true
            };

            options.contents = '<div class="diagram"><img src="' + vm.imageUrl + '" width="100%"></div>';

            dialogService.show(options);
        };

        // -- api

        // -- lifecycle

        vm.$onInit = function () {
            vm.imageUrl = __baseUrl + '/images/properties/' + vm.property.symbol + '/diagram.png';
        };

        // -- main

    }]
});
})(window, window.angular);