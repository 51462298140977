(function(window, angular){'use strict';

var ngModule = angular.module('pp.values.dashboard', [
    'pp.values.glossary'
]);

ngModule.value('dashboard', {
    forecastMonthlyDividends: {
        desc: 'The expected monthly amount that you will receive from your investments.'
    },
    forecastAnnualDividends: {
        desc: 'The expected annual amount that you will receive from your investments.'
    },
    monthlyDividend: {
        desc: 'The total amount of dividends you have received in the month stated.'
    },
    forecastAverageYield: {
        desc: 'The expected weighted annual yield you will get from all of your investments.'
    },
    currentValuation: {
        desc: 'The total value of the shares you currently own in your portfolio based on the latest valuation of each property.'
    },
    costOfCurrentHoldings: {
        desc: 'The total cost of all shares you currently own in your portfolio. It includes any fees and taxes paid.'
    },
    totalReturn: {
        desc: 'The all time total return of all your activity on Property Partner. It includes unrealised and realised capital gains, dividends and promotions.'
    },
    unrealisedCapitalGains: {
        desc: 'An estimate of gains or losses based on the Current Valuation versus the Cost of Current Holdings.'
    },
    realisedCapitalGains: {
        desc: 'The actual gain or loss made when you sold shares in your portfolio. It compares the total cost, including taxes and fees against the sale proceeds that were achieved.'
    },
    totalDividends: {
        desc: 'The total amount of dividends you have received from all your investments, including any that have been sold.'
    },
    promotions: {
        desc: 'The total amount of cashback or interest that you have received from different promotions.'
    },
    costOfSoldShares: {
        desc: 'The total cost of all shares you used to own but have subsequently sold. It includes any fees and taxes paid.'
    },
    reinvestDividends: {
        desc: 'Automatically reinvest the dividends you earn from a property back into the same property'
    },
    aumRebate: {
        desc: 'Money returned on the AUM fee you paid for crossing the investment threshold.'
    },
    interestReceived: {
        desc: 'To learn about how we calculate interest repayments <a href="https://help.londonhouseexchange.com/hc/en-us/articles/360002563417" class="link" target="_blank">click here</a>.'
    },
    incomePromotions: {
        desc: 'Promotional money received that is linked to income from investments'
    },
    current: {
        exitMechanicDate: {
            desc: 'The date at which a property’s 5-year anniversary process commences, giving shareholders the option to exit at fair market value. Learn more about the process <a href="https://www.londonhouseexchange.com/blog/5-year-exit-mechanic/" class="link" target="_blank">here</a>.'
        },
        totalReturnPct: {
            desc: 'The Total Return (£) as a percentage of Total Cost.'
        },
        totalReturnAmt: {
            desc: 'The sum of the Unrealised Capital Gain and Dividends Received.'
        },
        forecastYield: {
            // PRE COVID-19 desc: 'The dividend you will earn per year as a percentage of your total cost before accounting for the AUM fee'
            desc: 'The current declared dividend you will earn per year as a percentage of your net investment.'
        },
        dividendsReceived: {
            desc: 'Total amount of dividends you have received for the shares you currently hold.'
        },
        capitalGain: {
            desc: 'The difference between Independent Valuation and Total Cost (will vary depending on the Valuation Method you select).'
        },
        totalCost: {
            desc: 'Total cost of the shares you own, including any transaction fees and taxes.'
        },
        daysHeld: {
            desc: 'The number of days you have held the shares you currently own. If you have bought shares over multiple dates, it is the weighted average.'
        },
        sharesOwned: {
            desc: 'The number of shares you currently own in this property'
        },
        avgPricePaidPerShare: {
            desc: 'The average price you paid for the shares you own in this property. It does not include transaction fees or tax, but reflects the capital value of your shares'
        },
        resaleMarketValuation: {
            desc: 'Your performance based on trading activity on the Resale Market. We compare the total cost you paid for shares, including any fee and tax, against the current 30 day average trading price.'
        },
        tradingPriceGainLoss: {
            desc: 'The sum of capital gain and dividends received. Capital gain is based on the total price you paid for shares, including any fee and tax, against the current 30 day average trading price.'
        },
        tradingPriceTotalReturn: {
            desc: 'The total return as a percentage of total cost based on the current 30 day average trading price.'
        },
        tradingPriceCapitalGain: {
            desc: 'The difference between the total price you paid for shares, including any fee and tax, and the current 30 day average trading price.'
        },
        latestValuation: {
            desc: 'The latest valuation of your shares, based on Allsop\'s valuation of the underlying property (will vary depending on the Valuation Method you select). This valuation takes account of mortgage finance, net cash surplus/deficit, potential tax on the gain, etc.'
        }
    },
    bids: {
        maxPricePerShare: {
            desc: 'The maximum price you will pay per share if the bid is matched. You may pay less if shares are available at a lower price'
        },
        maxTotalCost: {
            desc: 'The maximum total cost you will pay if the bid is fully matched, inlcuding fees and tax'
        },
        minForecastYield: {
            // PRE COVID-19 desc: 'The minimum forecast dividends you will earn, as a percentage of total cost, if the bid is matched at the max price per share. Please note, this is before the AUM fee'
            desc: 'The minimum forecast dividends you will earn, as a percentage of total cost, if the bid is matched at the max price per share'
        },
        estDividendsPerYear: {
            desc: 'An estimate of the dividends you will earn each year'
        }
    },
    offers: {
        totalReturn: {
            desc: 'Capital gain / loss + dividends received'
        },
        gainLoss: {
            desc: 'Sales proceeds minus total cost'
        },
        saleProceeds: {
            desc: 'This is the amount that you will make if you sell offer is fully matched'
        },
        totalCost: {
            desc: 'The total cost you paid for the shares you have listed for sale, including fees and tax'
        },
        dividendsReceived: {
            desc: 'The dividends you have received for the shares you have listed for sale'
        },
        avgPricePaidPerShare: {
            desc: 'The average price you paid for the shares you are trying to sell. It does not include transaction fees or tax, but reflects the capital value of your shares'
        },
        salePrice: {
            desc: 'The price you have listed your shares for'
        },
        pctOfHoldingListedForSale: {
            desc: 'The percentage of the total number of shares you hold that you have listed for sale'
        }
    },
    sold: {
        totalReturnAmt: {
            desc: 'Total return £ = dividends received + sale proceeds - total cost'
        },
        dividendsReceived: {
            desc: 'The total amount of dividends you have received for the shares you have sold'
        },
        gainLoss: {
            desc: 'The difference between the total cost you paid for shares, including any fees and tax, and the amount they were sold for'
        },
        saleProceeds: {
            desc: 'The capital amount returned to you after selling shares'
        },
        totalCost: {
            desc: 'The total cost you paid for the shares you have sold, including fees and tax'
        },
        lastSold: {
            desc: 'The date you last sold shares in a property'
        }
    },
    loans: {
        status: {
            desc: 'The status of the lifecycle of the loan'
        },
        netInterest: {
            desc: 'The interest per annum you will receive on your investment, including all fees and tax'
        },
        totalCost: {
            desc: 'The investment amount including fees and taxes'
        },
        termEnds: {
            desc: 'When the loan is due to be repaid'
        },
        interestPaid: {
            desc: 'When the interest is due to be paid on the loan'
        },
        type: {
            desc: 'The type of loan e.g. "Development Loan Bond" or "Mortgage Bond"'
        }
    },
    totalReturnPct: {
        desc: 'The total return as a percentage of total cost'
    },
    highestBidPrice: {
        desc: 'The highest price at which investors have placed bids to buy shares in a property. Current shareholders can match bids for an immediate sale of shares.'
    },
    lowestOfferPrice: {
        desc: 'The lowest price at which current shareholders have offered shares in a property for sale. Investors can match offers for an immediate purchase of shares.'
    },
    thirtyDayAvgPrice: {
        desc: 'The average trading price of shares in a property over a rolling 30 day period'
    },
    latestShareValuation: {
        desc: 'The latest valuation of your shares, based on Allsop\'s valuation of the underlying property (will vary depending on the Valuation Method). This valuation takes account of mortgage finance, net cash surplus/deficit, potential tax on the gain, etc.'
    }
});
})(window, window.angular);