(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.recaptcha-invisible', [
    'vcRecaptcha',
    'pp.widgets-templates',
    'pp.values.colours'
]);

var SIGNUP_TEXT = 'Sign up';
var RECAPTCHA_CLASSNAME = 'recaptcha-fixed-top';

ngModule.component('ppRecaptchaInvisible', {
    templateUrl: 'widgets/_angular/recaptcha-invisible/recaptcha-invisible.tpl.html',
    bindings: {
        recaptchaKey: '<',
        onCreate: '&?',
        onPass: '&?'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$timeout', 'vcRecaptchaService', 'colours', function ($scope, $timeout, vcRecaptchaService, colours) {
        var vm = this;
        // -- initial state

        var recaptchaWidgetId = null;
        // -- util functions

        function addStyle() {
            $timeout(function () {
                try {
                    var ifr = document.querySelectorAll('iframe[title="recaptcha challenge"]')[0];
                    if (ifr) {
                        var parent = ifr.parentElement.parentElement;
                        parent.children[0].style.backgroundColor = colours.$dark_shadow;
                        parent.children[0].style.opacity = null;
                        parent.className = RECAPTCHA_CLASSNAME;
                    }
                } catch (error) {}

            }, 1500);
        }

        // -- api
        vm.onCreateRecaptcha = function (widgetId) {
            recaptchaWidgetId = widgetId;

            vcRecaptchaService.reload(recaptchaWidgetId); //If no reload sometimes pop up an error
            addStyle();

            vm.onCreate({
                widgetId: widgetId
            });
        };

        vm.cbExpiration = function () {
            vcRecaptchaService.reload(recaptchaWidgetId);
        };

        vm.setResponse = function (response) {
            vm.onPass({
                response: response
            });
        };

        // -- scope bindings

        $scope.$on('recaptcha.invisible.reload', function () {
            if (recaptchaWidgetId || recaptchaWidgetId === 0) {
                vcRecaptchaService.reload(recaptchaWidgetId);
                addStyle();
            }
        });

        // -- main

    }]
});
})(window, window.angular);