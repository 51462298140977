(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.primary-trust-hooks', [
    'pp.widgets-templates',
    'pp.services.core'
]);

ngModule.component('ppPrimaryTrustHooks', {
    templateUrl: 'widgets/_angular/primary-trust-hooks/primary-trust-hooks.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['ppConfig', function (ppConfig) {
        var vm = this;

        // -- initial state
        var __endpoints = ppConfig.get('pp.external.endpoints') || {};
        var _assetsEndpoint = __endpoints.assets;
        vm.assetsEndpoint = _assetsEndpoint;
        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);