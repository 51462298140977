(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investor-address-form', ['pp.widgets-templates', 'pp.widgets.duo-group-dropdown', 'pp.services.core', 'pp.values.form-lists', 'pp.components.focus-first-error']);

var UPDATED_TIMEOUT_DELAY = 5000;

var FORM_NAME = 'investor-address';

var BLOCKED_COUNTRIES = ['USA', 'UMI'];

ngModule.directive('ppInvestorAddressForm', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/investor-address-form/investor-address-form.tpl.html',
        scope: {
            address: '<ppInvestorAddressForm',
            onSubmit: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', 'formLists', 'ppTrack', function ($scope, $timeout, formLists, ppTrack) {
            var vm = this;

            // -- initial state

            vm.form = {};

            vm.formData = formLists;

            vm.blockedCountry = false;

            // -- util functions

            function handleSuccess() {
                ppTrack.formSubmit(FORM_NAME);
                vm.updated = true;
                $timeout(function () {
                    vm.updated = false;
                }, UPDATED_TIMEOUT_DELAY);
            }

            function handleFailure(error) {
                ppTrack.formError(FORM_NAME, error.reason);
                vm.processingError = true;
            }

            function handleFinally() {
                vm.processing = false;
            }

            // -- api

            vm.submit = function () {

                if (vm.form.$invalid) {
                    ppTrack.ngFormValidation(FORM_NAME, vm.form);
                    vm.feedbackEnabled = true;
                    return;
                }

                vm.processing = true;
                vm.processingError = false;
                vm.feedbackEnabled = false;

                var data = {
                    address: vm.address
                };

                vm.onSubmit(data)
                    .then(handleSuccess, handleFailure)
                    .finally(handleFinally);

            };

            // -- scope bindings

            $scope.$watch('vm.address.countryCode', function (val) {
                var wasBlocked = vm.blockedCountry;
                vm.blockedCountry = BLOCKED_COUNTRIES.indexOf(val) !== -1;
                if (vm.blockedCountry) {
                    vm.form.$setValidity('blockedCountry', false);
                } else {
                    vm.form.$setValidity('blockedCountry', true);
                }
            });

            // -- main

        }]
    };
}]);
})(window, window.angular);