(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.auth-pin-setup', ['ng.cork.ui.focus-on', 'pp.widgets-templates', 'pp.services.core', 'pp.components.bind-invalid-model', 'pp.components.focus-first-error', 'pp.components.match-against', 'pp.components.pin-validation']);

var FORM_NAME = 'auth.pin-setup';

/**
 * @ngdoc directive
 * @name ppAuthPinSetup
 * @description
 *
 *
 * @restrict A
 * @scope
 */

ngModule.directive('ppAuthPinSetup', [function () {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/auth-pin-setup/auth-pin-setup.tpl.html',
        scope: {
            onComplete: '&',
            submitText: '<',
            error: '=',
            pin: '=',
            processing: '='
        },
        bindToController: true,
        controllerAs: 'vm',
        controller: ['$scope', '$element', 'ppTrack', 'ppComponentEmitter', function ($scope, $element, ppTrack, ppComponentEmitter) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            vm.onSubmit = function () {
                if (vm.formPin.$valid) {
                    ppTrack.formSubmit(FORM_NAME);
                    vm.onComplete();
                } else {
                    vm.feedbackEnabled = true;
                    ppTrack.ngFormValidation(FORM_NAME, vm.formPin, ['pin', 'repeatPin']);
                }
            };

            // -- scope bindings

            // -- main

            vm.$postLink = function () {
                ppComponentEmitter.emit('auth-pin-setup.dom.loaded', $element);
            };

            vm.$onInit = function () {
                ppComponentEmitter.emit('auth-pin-setup.controller.loaded', vm);
            };

            // @todo whenever/if this directive is rendered before the user actually sees the form
            // then, the form open will be out of order
            // e.g. rendering with ng-show OR just rendering all the time for purpose of horizontal animation
            ppTrack.formStart(FORM_NAME);
        }]
    };
}]);
})(window, window.angular);