(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.white-space-only-validation', []);

/**
 * @ngdoc directive
 * @name ppWhiteSpaceOnlyValidation
 * @description
 * Invalid if input just consists of spaces
 *
 * @restrict A
 * @requires ngModel
 *
 * @param {string} ngModel Assignable angular expression to data-bind to.
 */
ngModule.directive('ppWhiteSpaceOnlyValidation', [function () {

    var WHITE_SPACE_ONLY_REGEX = /^\s+$/;

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, $elem, $attrs, $ctrl) {

            // NOTE: anti-pattern test specific code, but no other way to check if a transparent directive was instantiated correctly
            $scope.ppTestHelperDirectiveName = 'pp-white-space-only-validation';

            $ctrl.$validators.allWhiteSpace = function (modelValue, viewValue) {
                if (WHITE_SPACE_ONLY_REGEX.test(modelValue)) {
                    return false;
                } else {
                    return true;
                }
            };
        }
    };
}]);
})(window, window.angular);