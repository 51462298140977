(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.footer', [
    'pp.services.core',
    'pp.services.browser-store',
    'pp.services.config',
    'pp.services.route',
    'pp.widgets.performance-warning',
    'pp.filters.dates'
]);

var HOME_PATH = '/';
var HOW_IT_WORKS_PATH = '/howitworks';

ngModule.component('ppFooter', {
    templateUrl: 'widgets/_angular/footer/footer.tpl.html',
    bindings: {
        isSlim: '<'
    },
    controllerAs: 'vm',
    controller: ['$window', '$q', 'configService', 'routeService', function ($window, $q, configService, routeService) {
        var vm = this;

        // -- initial state

        vm.knowledgeBaseUrl = routeService.knowledgeBaseLink;

        var path = angular.copy($window.location.pathname);

        vm.date = new Date();

        vm.showAsterisks = path === HOME_PATH || path === HOW_IT_WORKS_PATH;

        vm.propertyTeamPath = routeService.propertyTeamPath;
        vm.crowdfundingLpPath = routeService.crowdfundingLpPath;
        vm.isaLandingPage = routeService.isaLandingPage;
        vm.feesChargesHelp = routeService.feesChargesHelp;

        vm.keyRisksUrl = routeService.keyRisksUrl;

        vm.investmentSafeguard = routeService.investmentSafeguard;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        var promises = {
            endpoints: configService.getFooterEndpoints(),
            estimatedReturns: configService.getEstimatedReturns()
        };

        $q.all(promises).then(function (res) {
            vm.estimatedReturns = res.estimatedReturns;
            vm.endpoints = res.endpoints;
        });

    }]
});
})(window, window.angular);