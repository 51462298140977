(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.business-summary-invest', [
    'pp.widgets-templates',
    'pp.widgets.auth-signup-cta',
    'pp.ui.services.wistia-video-dialog',
    'pp.services.route'
]);

var INVEST_VIDEO_ID = '5o073i7mby';
var INVEST_TITLE = 'Invest in minutes';

ngModule.component('ppBusinessSummaryInvest', {
    templateUrl: 'widgets/_angular/business-summary-invest/business-summary-invest.tpl.html',
    bindings: {
        user: '<',
        s3Endpoint: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'wistiaVideoDialogService', 'routeService', function ($scope, wistiaVideoDialogService, routeService) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.openInvestVideo = function () {
            wistiaVideoDialogService.show(INVEST_VIDEO_ID);
        };

        // -- scope bindings

        vm.$onInit = function () {
            vm.investTitle = INVEST_TITLE;
            vm.investImage = vm.s3Endpoint + '/images/homepage/invest-mobile.jpg';
            vm.investMediaImage = vm.s3Endpoint + '/images/homepage/buy-how-it-works-desktop-rebrand.jpg';
            vm.feesChargesHelp = routeService.feesChargesHelp;
        };

        // -- main

    }]
});
})(window, window.angular);