(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.restricted-investor-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.services.investor',
    'pp.widgets.investment-plan-restricted-investor-blocked-dialog',
    'pp.widgets.investment-restricted-investor-blocked-dialog',
    'pp.widgets.bond-restricted-investor-blocked-dialog'
]);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/extra-user-data`.
 */
ngModule.service('restrictedInvestorDialog', ['$rootScope', '$q', 'ppTrack', 'dialogService', 'investorService', function ($rootScope, $q, ppTrack, dialogService, investorService) {

    // -- initial state

    // -- util functions

    var dialogInstance;

    // create a scope, show the dialog
    function showDialog(user, type, event, property) {

        ppTrack.event(event);

        var scope = $rootScope.$new();

        scope.user = user;
        scope.property = property;

        var options = {
            clickClose: true,
            escapeClose: true,
            showClose: true,
            classNames: 'pp-' + type + '-dialog',
            scope: scope
        };

        options.contents = '<pp-' + type + ' user="user" property="property"></pp-' + type + '>';

        dialogInstance = dialogService.show(options);

        return dialogInstance;
    }

    // -- api

    var api = {};

    api.showInvestmentPlanBlockedDialog = function (user) {
        return showDialog(
            user,
            'investment-plan-restricted-investor-blocked-dialog',
            'investment-plan.restricted-investor.blocked'
        );
    };

    api.showBondBlockedDialog = function (user, property) {
        return showDialog(
            user,
            'bond-restricted-investor-blocked-dialog',
            'bond.restricted-investor.blocked',
            property
        );
    };

    api.showInvestmentBlockedDialog = function (user) {
        return showDialog(
            user,
            'investment-restricted-investor-blocked-dialog',
            'investment-restricted.restricted-investor.blocked'
        );
    };

    function handleShowReclassifyDialog(res) {
        if (angular.isFunction(res)) {
            return res();
        } else {
            return $q.reject();
        }
    }

    function handleReclassifySuccess(user) {
        if (investorService.isRestrictedInvestor(user)) {
            return $q.reject();
        } else {
            return user;
        }
    }

    api.reclassifyUserForBond = function (user, property) {

        if (!investorService.isRestrictedInvestor(user)) {
            return $q.when(user);
        }

        return api.showBondBlockedDialog(user, property)
            .then(handleShowReclassifyDialog)
            .then(handleReclassifySuccess);

    };

    api.reclassifyUserForRestrictedInvestment = function (user) {

        if (!investorService.isRestrictedInvestor(user)) {
            return $q.when(user);
        }

        return api.showInvestmentBlockedDialog(user)
            .then(handleShowReclassifyDialog)
            .then(handleReclassifySuccess);

    };

    return api;
}]);
})(window, window.angular);