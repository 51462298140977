(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.account-investor-funds', [
    'pp.widgets-templates',
    'pp.widgets.investor-funds-manual'
]);

var AUTO_INVEST_PREFERENCE = 'AUTO.INVEST.ACTIVE';

/**
 * @ngdoc directive
 * @name ppAccountInvestorFunds
 * @description
 * Renders the tabs for AI and Manual funds on account
 *
 * @restrict A
 * @scope
 */

ngModule.directive('ppAccountInvestorFunds', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/account-investor-funds/account-investor-funds.tpl.html',
        scope: {},
        controllerAs: 'vm',
        bindToController: true,
        controller: [angular.noop]
    };
});
})(window, window.angular);