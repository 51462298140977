(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.checkbox-indeterminate', []);

ngModule.directive('ppIndeterminate', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attributes) {
            scope.$watch(attributes['ppIndeterminate'], function (value) {
                element.prop('indeterminate', !!value);
            });
        }
    };
});
})(window, window.angular);