(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.map', ['pp.widgets-templates', 'pp.services.core', 'pp.services.google-maps-api', 'pp.services.google-maps']);

var PROPERTY_ICON_PATH = 'M14.1617265,7.48469971 C14.1617265,3.70451782 10.991516,0.690656258 7.08086323,0.690656258 C3.17021045,0.690656258 1.79789144e-16,3.20939527 0,7.48469971 C1.84462616e-16,11.7600042 7.08086323,21.311225 7.08086323,21.311225 C7.08086323,21.311225 14.1617265,12.019591 14.1617265,7.48469971 Z M7.08086323,10.8817214 C9.03618962,10.8817214 10.6212948,9.36082301 10.6212948,7.48469971 C10.6212948,5.60857642 9.03618962,4.08767798 7.08086323,4.08767798 C5.12553684,4.08767798 3.54043161,5.60857642 3.54043161,7.48469971 C3.54043161,9.36082301 5.12553684,10.8817214 7.08086323,10.8817214 Z';

var PROPERTY_ZOOM_LEVEL = 12;
var CONTACT_ZOOM_LEVEL = 16;

var MAP_STYLE = [{
    'featureType': 'landscape',
    'stylers': [{
        'hue': '#FFBB00'
    }, {
        'saturation': 43.400000000000006
    }, {
        'lightness': 37.599999999999994
    }, {
        'gamma': 1
    }]
}, {
    'featureType': 'road.highway',
    'stylers': [{
        'hue': '#FFC200'
    }, {
        'saturation': -61.8
    }, {
        'lightness': 45.599999999999994
    }, {
        'gamma': 1
    }]
}, {
    'featureType': 'road.arterial',
    'stylers': [{
        'hue': '#FF0300'
    }, {
        'saturation': -100
    }, {
        'lightness': 51.19999999999999
    }, {
        'gamma': 1
    }]
}, {
    'featureType': 'road.local',
    'stylers': [{
        'hue': '#FF0300'
    }, {
        'saturation': -100
    }, {
        'lightness': 52
    }, {
        'gamma': 1
    }]
}, {
    'featureType': 'water',
    'stylers': [{
        'hue': '#0078FF'
    }, {
        'saturation': -13.200000000000003
    }, {
        'lightness': 2.4000000000000057
    }, {
        'gamma': 1
    }]
}, {
    'featureType': 'poi',
    'stylers': [{
        'hue': '#00FF6A'
    }, {
        'saturation': -1.0989010989011234
    }, {
        'lightness': 11.200000000000017
    }, {
        'gamma': 1
    }]
}];

var MAP_STYLE_HOW_IT_WORKS = [{
    'featureType': 'administrative',
    'elementType': 'labels.text.fill',
    'stylers': [{
        'color': '#444444'
    }]
}, {
    'featureType': 'landscape',
    'elementType': 'all',
    'stylers': [{
        'color': '#f2f2f2'
    }]
}, {
    'featureType': 'poi',
    'elementType': 'all',
    'stylers': [{
        'visibility': 'off'
    }]
}, {
    'featureType': 'road',
    'elementType': 'all',
    'stylers': [{
        'saturation': -100
    }, {
        'lightness': 45
    }]
}, {
    'featureType': 'road.highway',
    'elementType': 'all',
    'stylers': [{
        'visibility': 'simplified'
    }]
}, {
    'featureType': 'road.arterial',
    'elementType': 'labels.icon',
    'stylers': [{
        'visibility': 'off'
    }]
}, {
    'featureType': 'transit',
    'elementType': 'all',
    'stylers': [{
        'visibility': 'off'
    }]
}, {
    'featureType': 'water',
    'elementType': 'all',
    'stylers': [{
        'color': '#46bcec'
    }, {
        'visibility': 'on'
    }]
}];

ngModule.directive('ppMap', [function () {
    return {
        restrict: 'A',
        template: '<div class="pp-map"></div>',
        scope: {
            locations: '=',
            type: '=',
            center: '='
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', 'ppConfig', 'googleMapsApiService', 'googleMapsService', function ($scope, $element, ppConfig, googleMapsApiService, googleMapsService) {

            var vm = this;

            // -- initial state

            var __config = ppConfig.get('pp.external.endpoints') || {};
            var assetsBaseUrl = __config ? __config.assets : '';

            // -- util functions
            function getOptions(type) {
                switch (type) {
                case 'property':
                    return {
                        zoom: PROPERTY_ZOOM_LEVEL,
                            styles: MAP_STYLE,
                            scrollwheel: false
                    };
                case 'contactUs':
                    return {
                        zoom: CONTACT_ZOOM_LEVEL,
                            scrollwheel: false,
                            navigationControl: false,
                            mapTypeControl: false,
                            scaleControl: false,
                            draggable: false,
                            disableDefaultUI: true
                    };
                case 'howItWorks':
                    return {
                        scrollwheel: false,
                            navigationControl: false,
                            mapTypeControl: false,
                            scaleControl: false,
                            draggable: false,
                            disableDefaultUI: true,
                            zoom: PROPERTY_ZOOM_LEVEL,
                            styles: MAP_STYLE_HOW_IT_WORKS
                    };
                }
            }

            // icon based on type of map
            function getIcon(googleMapsApi, type) {
                switch (type) {
                case 'property':
                    return {
                        path: googleMapsApi.SymbolPath.CIRCLE,
                            scale: 20,
                            strokeColor: '#EEEEEE',
                            strokeOpacity: 0.8,
                            strokeWeight: 3,
                            fillColor: '#2AAEF5',
                            fillOpacity: 0.65
                    };
                case 'contactUs':
                    return {
                        url: assetsBaseUrl + '/images/pins/secondary-selected.png',
                            size: new googleMapsApi.Size(44, 55),
                            origin: new googleMapsApi.Point(0, 0),
                            anchor: new googleMapsApi.Point(22, 55),
                            scaledSize: new googleMapsApi.Size(44, 55)
                    };
                case 'howItWorks':
                    return {
                        url: assetsBaseUrl + '/images/pins/howitworkspin.png',
                            size: new googleMapsApi.Size(72, 102),
                            origin: new googleMapsApi.Point(0, 0),
                            anchor: new googleMapsApi.Point(17, 34),
                            scaledSize: new googleMapsApi.Size(36, 51)
                    };
                }
            }

            // -- api

            // -- scope bindings

            // -- main

            vm.$postLink = function () {
                googleMapsApiService.load().then(function (googleMapsApi) {
                    var options;
                    var icon;
                    options = getOptions(vm.type);
                    icon = getIcon(googleMapsApi, vm.type);
                    googleMapsService.renderMap(googleMapsApi, $element[0].childNodes[0], vm.type, vm.center, vm.locations, options, icon);
                });
            };

        }]
    };
}]);
})(window, window.angular);