(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.previous-next-stepper', ['pp.widgets-templates']);

ngModule.component('ppPreviousNextStepper', {
    templateUrl: 'widgets/_angular/previous-next-stepper/previous-next-stepper.tpl.html',
    bindings: {
        steps: '<',
        currentStep: '<',
        onStepBack: '&',
        onStepForward: '&',
        stepBackDisabled: '<',
        stepForwardDisabled: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.stepBack = function () {
            if (!vm.stepBackDisabled) {
                vm.onStepBack();
            }
        };

        vm.stepForward = function () {
            if (!vm.stepForwardDisabled) {
                vm.onStepForward();
            }
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);