(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-thumbnail', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.config',
    'pp.widgets.theme-list',
    'pp.widgets.property-status',
    'pp.widgets.property-sticker'
]);

/**
 * @ngdoc directive
 * @name ppPropertyThumbnail
 * @description
 * Renders the property thumbnail.
 *
 * @restrict A
 * @scope
 * @param {Object} ppPropertyThumbnail the property model
 */
ngModule.directive('ppPropertyThumbnail', ['ppConfig', function (ppConfig) {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-thumbnail/property-thumbnail.tpl.html',
        scope: {
            property: '<ppPropertyThumbnail',
            user: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'configService', function ($scope, configService) {
            var vm = this;

            // -- initial state

            var __endpoints = ppConfig.get('pp.external.endpoints') || {};
            var assetsEndpoint = __endpoints.assets;
            $scope.assetsEndpoint = assetsEndpoint;

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

            vm.getThumbnail = function () {
                if ((vm.property.isFund || vm.property.isNMPI) && !vm.user.isNonRetail) {
                    return {
                        'background-image': 'url(' + assetsEndpoint + '/images/properties/' + vm.property.symbol + '/image2-grid-list.jpg)'
                    };
                } else {
                    return {
                        'background-image': 'url(' + assetsEndpoint + '/images/properties/' + vm.property.symbol + '/image1-grid-list.jpg)'
                    };
                }

            };

            vm.$onInit = function () {
                if (vm.property.isDevelopmentLoan) {
                    configService.isIsaPublic().then(function (isaPublic) {
                        vm.isaPublic = isaPublic;
                    });
                }
            };

        }]
    };
}]);
})(window, window.angular);