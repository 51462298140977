(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.invest-dialog-secondary-expired', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.filters.numbers'
]);

var DASHBOARD_URL = '/my-portfolio#/dashboard';

ngModule.component('ppInvestDialogSecondaryExpired', {
    templateUrl: 'widgets/_angular/invest-dialog-secondary-expired/invest-dialog-secondary-expired.tpl.html',
    bindings: {
        pageName: '<',
        oldQuote: '<',
        paymentAmount: '<',
        availableFunds: '<',
        fundsUpdated: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', function ($scope, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions
        function redirect(href) {
            ppTrack.closeAll().then(function () {
                window.location.href = href;
            });
        }

        // -- api
        vm.retry = function () {
            ppTrack.pageAction(vm.pageName, 'secondaryExpired.retry');
            $scope.$parent.dialogDefer.resolve();
        };

        vm.exitDashboard = function () {
            ppTrack.pageAction(vm.pageName, 'secondaryExpired.exitDashboard');
            redirect(DASHBOARD_URL);
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);