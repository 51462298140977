(function(window, angular){'use strict';

var MAX_LENGTH = 1;

var ngModule = angular.module('pp.components.input-pin', [
    'ng.cork.ui.focus-on',
    'pp.components-templates',
    'pp.services.core'
]);

/**
 * @ngdoc directive
 * @name ppInputPin
 * @description
 * allows entry of PIN in number inputs (hidden) while it displays
 *
 * **EVENTS**
 * - listens on `$scope` event `pp.input-pin.setFocus` with no payload (broadcasted by ppAuthLoginForm)
 *
 * @restrict A
 * @scope
 * @param {array} inputs
 * ```
 * [
 *   {
 *     label: '1st',
 *     key: '1st-pin-char',
 *     value: '',
 *     index: 1,
 *     ordinal: 'first'
 *   },
 *   ...
 * ]
 * ```
 * @param {boolean} ppDisabled
 * @param {function} onSubmit
 */
ngModule.directive('ppInputPin', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/input-pin/input-pin.tpl.html',
        scope: {
            inputs: '=ppInputPin',
            pinPlainText: '=',
            isDisabled: '<ppDisabled'
        },
        controllerAs: 'vm',
        bindToController: true,

        controller: ['$scope', 'ppBrowser', function ($scope, ppBrowser) {
            var vm = this;

        }]
    };
}]);
})(window, window.angular);