(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.index-graph-small', [
    'pp.widgets-templates',
    'highcharts-ng',
    'pp.services.core',
    'pp.values.colours',
    'pp.filters.numbers',
    'pp.services.browser-store'
]);

ngModule.component('ppIndexGraphSmall', {
    templateUrl: 'widgets/_angular/index-graph-small/index-graph-small.tpl.html',
    bindings: {
        marketIndex: '<',
        currentIndex: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', '$timeout', 'colours', 'R', 'ppBig', 'ppMoment', function ($scope, $window, $timeout, colours, R, ppBig, ppMoment) {
        var vm = this;

        // -- initial state

        // -- util functions

        var normaliseData = R.map(function (item) {
            return item.indexValue;
        });

        var threeYearsAgo = ppMoment().subtract(1, 'years');

        var filterRange = R.filter(function (item) {
            return ppMoment(item.datePoint).isSameOrAfter(threeYearsAgo);
        });

        var getMinValue = R.reduce(R.min, 999999);
        var getMaxValue = R.reduce(R.max, 0);

        function createChart(marketIndex) {

            vm.chartOptions = {
                chart: {
                    type: 'spline',
                    height: $window.innerWidth >= 768 ? 88 : 95,
                    color: '#000',
                    backgroundColor: 'rgba(255,255,255,0)',
                    style: {
                        fontFamily: 'Proxima'
                    }
                },
                tooltip: {
                    enabled: false
                },
                title: {
                    text: null,
                    style: {
                        'color': '#FFF'
                    }
                },
                xAxis: {
                    title: {
                        text: null
                    },
                    tickLength: 0,
                    lineWidth: 0,
                    labels: {
                        enabled: false
                    }
                },
                labels: {
                    enabled: false
                },
                yAxis: {
                    allowDecimals: false,
                    title: {
                        text: null
                    },
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    tickLength: 0,
                    startOnTick: false,
                    endOnTick: false,
                    min: getMinValue(marketIndex),
                    max: getMaxValue(marketIndex),
                    labels: {
                        enabled: false
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    data: marketIndex,
                    color: vm.ptsChange < 0 ? colours.marketIndexPositive : colours.marketIndexPositive,
                    showInLegend: false,
                    enableMouseTracking: false,
                    name: 'Trading index',
                    boostThreshold: 1,
                    marker: {
                        symbol: 'circle',
                        radius: 0
                    }
                }]
            };
        }

        var init = R.compose(createChart, normaliseData);

        function setChangeNumbers(currentIndex, marketIndex) {
            if (currentIndex && marketIndex) {
                var mostRecentMarketIndex = R.path(['indexValue'], R.last(marketIndex));
                var ptsChange = ppBig(currentIndex.index).minus(mostRecentMarketIndex);
                vm.pctChange = Number(ptsChange.div(mostRecentMarketIndex).times(100));
                vm.ptsChange = Number(ptsChange);
            }

            return vm.ptsChange;
        }

        // -- api

        vm.$onChanges = function (changes) {
            var currentIndex = R.path(['currentIndex', 'currentValue'], changes);
            var marketIndex = R.path(['marketIndex', 'currentValue'], changes);

            if (currentIndex && marketIndex) {
                setChangeNumbers(currentIndex, marketIndex);
            }
        };

        vm.getIndexChangeClass = function (ptsChange) {
            if (ptsChange > 0) {
                return 'positive';
            }

            if (ptsChange < 0) {
                return 'negative';
            }
        };

        vm.$onInit = function () {
            setChangeNumbers(vm.currentIndex, vm.marketIndex);
            $timeout(function () {
                init(filterRange(vm.marketIndex));
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);