(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.total-return-valuation-methods-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.widgets.toggle-total-return-valuation-methods'
]);

ngModule.service('totalReturnValuationMethodsDialog', ['$rootScope', 'ppTrack', 'dialogService', function ($rootScope, ppTrack, dialogService) {

    // -- util functions

    var scope;

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(latestValuationVPV, latestValuationIV, tradingValuation, onUpdateLatestValuationVPV, onUpdateLatestValuationIV, onUpdateTradingValuation) {
        ppTrack.action('update-bank-account.show');
        scope = $rootScope.$new();

        scope.latestValuationVPV = latestValuationVPV;
        scope.latestValuationIV = latestValuationIV;
        scope.tradingValuation = tradingValuation;
        scope.onUpdateLatestValuationVPV = onUpdateLatestValuationVPV;
        scope.onUpdateLatestValuationIV = onUpdateLatestValuationIV;
        scope.onUpdateTradingValuation = onUpdateTradingValuation;

        scope.closeModal = dialogService.close;

        var options = {
            clickClose: true,
            escapeClose: true,
            showClose: true,
            scope: scope,
            classNames: 'small'
        };

        options.contents = '<pp-toggle-total-return-valuation-methods latest-valuation-vpv="latestValuationVPV" latest-valuation-iv="latestValuationIV"  trading-valuation="tradingValuation" on-update-latest-valuation-vpv="onUpdateLatestValuationVPV()" on-update-latest-valuation-iv="onUpdateLatestValuationIV()" on-update-trading-valuation="onUpdateTradingValuation()" back-to-dashboard="closeModal()"></pp-toggle-total-return-valuation-methods>';

        return dialogService.show(options);
    }

    // -- api

    var api = {};

    api.show = function (latestValuationVPV, latestValuationIV, tradingValuation, onUpdateLatestValuationVPV, onUpdateLatestValuationIV, onUpdateTradingValuation) {
        return showDialog(latestValuationVPV, latestValuationIV, tradingValuation, onUpdateLatestValuationVPV, onUpdateLatestValuationIV, onUpdateTradingValuation);
    };

    api.update = function (latestValuationVPV, latestValuationIV, tradingValuation) {
        scope.latestValuationVPV = latestValuationVPV;
        scope.latestValuationIV = latestValuationIV;
        scope.tradingValuation = tradingValuation;
    };

    return api;
}]);
})(window, window.angular);