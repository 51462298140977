(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-plan-overview', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.config',
    'pp.services.route',
    'pp.filters.numbers',
    'pp.ui.services.wistia-video-dialog',
    'pp.values.investment-plans',
    'pp.widgets.investment-plan-card',
    'pp.widgets.nav-card',
    'pp.widgets.risk-warning-badge',
    'pp.widgets.terms-apply-badge',
    'pp.widgets.premium-services-banner'
]);

var INVESTMENT_PLAN_CARDS_ID = 'pp-plan-overviews';
var PAGE_NAME = 'investment-plan.overview';
var SELL_VIDEO_ID = 'sap6ykfpxq';

ngModule.component('ppInvestmentPlanOverview', {
    templateUrl: 'widgets/_angular/investment-plan-overview/investment-plan-overview.tpl.html',
    bindings: {
        user: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', '$anchorScroll', 'ppConfig', 'ppTrack', 'investmentPlans', 'configService', 'investorService', 'wistiaVideoDialogService', 'ppComponentEmitter', 'routeService', function ($scope, $element, $anchorScroll, ppConfig, ppTrack, investmentPlans, configService, investorService, wistiaVideoDialogService, ppComponentEmitter, routeService) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints') || {};

        var assetsEndpoint = __config.assets;

        // plans data

        vm.income = angular.copy(investmentPlans.income);
        vm.balanced = angular.copy(investmentPlans.balanced);
        vm.growth = angular.copy(investmentPlans.growth);

        vm.navCardOne = investmentPlans.navCardOne;
        vm.navCardTwo = investmentPlans.navCardTwo;

        vm.sellIntro = routeService.sellIntro;
        vm.exitMechanic = routeService.exitMechanic;

        // -- util functions

        // -- api

        vm.openSellVideo = function () {
            ppTrack.action('investment-plans.overview.open-sell-video');
            wistiaVideoDialogService.show(SELL_VIDEO_ID);
        };

        vm.scrollToPlans = function () {
            ppTrack.action('investment-plans.overview.scroll-to-plans');
            $anchorScroll(INVESTMENT_PLAN_CARDS_ID);
        };

        // -- scope bindings

        investorService.on('investor-updated', function (user) {
            vm.user = user;
        });

        vm.$onInit = function () {
            vm.heroImageUrl = assetsEndpoint + '/images/heros/hero-investment-plan.jpg';
            ppTrack.pageLoad(PAGE_NAME);
            vm.icon = investmentPlans.createIconUrl(assetsEndpoint, vm.key);
            ppComponentEmitter.emit('investment-plan-overview.controller.loaded', vm);

            configService.minimumInvestmentPlanAmount().then(function (amount) {
                vm.minimumInvestmentPlan = amount;
            });
        };

        vm.$postLink = function () {
            ppComponentEmitter.emit('investment-plan-overview.dom.loaded', $element);
        };

        vm.$onDestroy = function () {
            ppTrack.pageClose(PAGE_NAME);
        };

        // -- main

    }]
});
})(window, window.angular);