(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.business-summary-sell', [
    'pp.widgets-templates',
    'pp.widgets.auth-signup-cta',
    'pp.ui.services.wistia-video-dialog',
    'pp.services.config'
]);

var SELL_VIDEO_ID = 'sap6ykfpxq';
var SELL_TITLE = 'Sell your investments';

ngModule.component('ppBusinessSummarySell', {
    templateUrl: 'widgets/_angular/business-summary-sell/business-summary-sell.tpl.html',
    bindings: {
        user: '<',
        s3Endpoint: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'wistiaVideoDialogService', 'configService', function ($scope, wistiaVideoDialogService, configService) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.openSellVideo = function () {
            wistiaVideoDialogService.show(SELL_VIDEO_ID);
        };

        // -- scope bindings

        vm.$onInit = function () {
            vm.sellTitle = SELL_TITLE;
            vm.sellImage = vm.s3Endpoint + '/images/homepage/sell-mobile.jpg';
            vm.sellMediaImage = vm.s3Endpoint + '/images/homepage/sell-how-it-works-desktop.jpg';
        };

        // -- main

        configService.getAverageTimeToSell().then(function (data) {
            vm.avgTimeToSell = data.avgTimeToSell || '';
            vm.avgTimeToSellDate = data.avgTimeToSellDate || '';
            vm.pastTimeToSell1Label = data.pastTimeToSell1Label || '';
            vm.pastTimeToSell1Value = data.pastTimeToSell1Value || '';
            vm.pastTimeToSell2Label = data.pastTimeToSell2Label || '';
            vm.pastTimeToSell2Value = data.pastTimeToSell2Value || '';
            vm.pastTimeToSell3Label = data.pastTimeToSell3Label || '';
            vm.pastTimeToSell3Value = data.pastTimeToSell3Value || '';
            vm.ready = true;
        });

    }]
});
})(window, window.angular);