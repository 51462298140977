(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.expandable-toggle', ['pp.widgets-templates', 'pp.services.core']);

ngModule.component('ppExpandableToggle', {
    templateUrl: 'widgets/_angular/expandable-toggle/expandable-toggle.tpl.html',
    bindings: {
        trackId: '@',
        collapsed: '=?'
    },
    transclude: true,
    controllerAs: 'vm',
    controller: ['ppTrack', function ppExpandableToggleController(ppTrack) {
        var vm = this;
        var tracked;

        // -- initial state

        // -- util functions

        function trackOnce() {
            if (!tracked) {
                ppTrack.action('expand-content.' + vm.trackId);
                tracked = true;
            }
        }

        // -- api

        vm.expand = function () {
            vm.collapsed = !vm.collapsed;
            trackOnce();
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            if (!angular.isDefined(vm.collapsed)) {
                vm.collapsed = true;
            }
        };
    }]
});
})(window, window.angular);