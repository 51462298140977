(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.expandable-figure-box', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.components.tooltip',
    'pp.values.dashboard'
]);

ngModule.component('ppExpandableFigureBox', {
    templateUrl: 'widgets/_angular/expandable-figure-box/expandable-figure-box.tpl.html',
    bindings: {
        header: '<',
        breakdown: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'dashboard', function ($scope, dashboard) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.toggleDetails = function () {
            vm.showDetails = !vm.showDetails;
        };

        vm.shouldDisplay = function (differenceFigure) {
            if (typeof differenceFigure !== 'object') {
                return false;
            }

            return differenceFigure.value !== null && differenceFigure.rawValue !== null &&
                differenceFigure.value !== undefined && differenceFigure.rawValue !== undefined;
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            vm.dashboardTooltips = dashboard;
        };
    }]
});
})(window, window.angular);