(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.blog-links', ['pp.widgets-templates', 'pp.services.core']);
var SCREEN_MOBILE_MAX_PX = 769;

ngModule.directive('ppBlogLinks', ['$timeout', 'ppComponentEmitter', function ($timeout, ppComponentEmitter) {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/blog-links/blog-links.tpl.html',
        scope: {
            firstPost: '<',
            secondPost: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                $scope.$evalAsync(function () {
                    ppComponentEmitter.emit('blog-links.controller.loaded', vm);
                });
            };

        }]
    };
}]);
})(window, window.angular);