(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.personal-details-form', ['pp.widgets-templates']);

ngModule.directive('ppPersonalDetailsForm', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/x-personal-details-form/personal-details-form.tpl.html',
        scope: {
            onSubmit: '&',
            personalDetails: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            vm.submit = function () {
                if (vm.form.$valid) {
                    vm.onSubmit();
                    vm.feedbackEnabled = false;
                } else {
                    vm.feedbackEnabled = true;
                }
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);