(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.toggle-bricks', ['pp.components-templates', 'pp.components.toggle-tick']);

var BORDER_OFFSET = 2;

ngModule.directive('ppToggleBricks', function () {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/toggle-bricks/toggle-bricks.tpl.html',
        scope: {
            value: '=ppToggleBricks',
            options: '=',
            onClick: '&?',
            disabled: '=ppDisabled'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            var __offsets = [];

            // -- util functions

            // -- api

            vm.click = function (id) {
                vm.value = id;
                if (vm.onClick) {
                    vm.onClick({
                        value: id
                    });
                }
            };

            // -- scope bindings

            // -- main

        }]
    };

});
})(window, window.angular);