(function(window, angular){'use strict';

var ngModule = angular.module('pp.values.investment-plans', []);

ngModule.value('investmentPlans', {
    createIconUrl: function (baseAssetUrl, planKey) {
        return baseAssetUrl + '/images/icons/investment-plans/' + planKey + '-plan.svg';
    },
    income: {
        key: 'income',
        title: 'Income plan',
        titleLowercase: 'income plan',
        planName: 'Income',
        tagLine: 'Plan summary',
        description: 'Invest in the 10 strongest dividend-yielding properties to earn attractive monthly dividends',
        summary: '<p>This plan aims to achieve a reliable income stream by investing in properties with the strongest dividend yields (net rental income).</p><p>All property types, residential, student and commercial, are eligible for the Income plan.</p><p>Looking for something more bespoke? Speak to our investment team and we\'ll create a property portfolio that\'s tailored to your specific investment goals. Minimum investment £25,000. <a href="/aboutus/contactus" class="link">Contact us</a>.</p>',
        investmentPrinciplesDescription: '<p>A strict logic determines when your funds are invested to ensure your portfolio is best placed to deliver on the investment strategy you choose. For example we won’t invest more than 40% of your funds in a single region and we have limits on when we can buy shares on the resale market to avoid unfair prices. They are designed to give you the confidence to sit back and let us find the best deals for you on our platform. <a class="link" target="_blank" href="https://help.londonhouseexchange.com/hc/en-us/articles/360007984854-Investment-Principles">Learn more</a></p>',
        investmentStrategyDescription: '<p>Your funds will be deployed across the top yielding properties available on our platform. Investments are made in such a way to diversify your funds across a number of properties with a minimum average 5.2% Dividend Yield required. Properties with the best yields, in excess of 5.2% p.a, are prioritised. <a class="link" target="_blank" href="https://help.londonhouseexchange.com/hc/en-us/articles/360007983754-How-do-you-choose-which-properties-to-invest-my-funds-in-">Learn more</a></p>',
        totalReturn: 'above 4%',
        dividendRatio: 70,
        capitalRatio: 30,
        dividendReturn: '5.2%',
        targetLabel: 'Dividend yield',
        capitalReturn: '2.0%',
        learnMoreUrl: '/investmentplans#/income',
        isYieldBased: true,
        productList: [
            [{
                    label: 'Student accommodation'
                },
                {
                    label: 'Commercial'
                },
                {
                    label: 'Residential'
                }
            ]
        ],
        navCardOne: 'navCardBalanced',
        navCardTwo: 'navCardGrowth'
    },
    balanced: {
        key: 'balanced',
        title: 'Balanced plan',
        planName: 'Balanced',
        titleLowercase: 'balanced plan',
        tagLine: 'Plan summary',
        targetLabel: 'Target total return',
        description: 'Build your portfolio across a range of properties for a balanced total return',
        summary: '<p>The balanced plan aims to deliver a strong total return through a more equal combination of monthly dividend yield (rental income) and potential capital gains.</p><p>Residential and some Commercial properties fit this return profile. Student properties will not be considered as the return for these is almost always driven by yield. The key metric that determines investment for this plan is total return which is the combination of forecast dividend yield and capital gain.</p>',
        investmentPrinciplesDescription: '<p>A strict logic determines when your funds are invested to ensure your portfolio is best placed to deliver on the investment strategy you choose. For example we won’t invest more than 40% of your funds in a single region and we have limits on when we can buy shares on the resale market to avoid unfair prices. They are designed to give you the confidence to sit back and let us find the best deals for you on our platform. <a class="link" target="_blank" href="https://help.londonhouseexchange.com/hc/en-us/articles/360007984854-Investment-Principles">Learn more</a></p>',
        investmentStrategyDescription: '<p>Your funds will be deployed across properties that have the highest potential total return. Investments are made in such a way to diversify your funds across a number of properties with a minimum average 11.0% forecast total return p.a. required. Properties with the strongest forecasts, in excess of 11.0% p.a, are prioritised. <a class="link" target="_blank" href="https://help.londonhouseexchange.com/hc/en-us/articles/360007983754-How-do-you-choose-which-properties-to-invest-my-funds-in-">Learn more</a></p>',
        totalReturn: '11.0%',
        dividendReturn: '3.2%',
        capitalReturn: '7.8%',
        dividendRatio: 30,
        capitalRatio: 70,
        learnMoreUrl: '/investmentplans#/balanced',
        productList: [
            [{
                    label: 'Residential'
                },
                {
                    label: 'Commercial'
                }
            ],
            [{
                label: 'Student accommodation',
                type: 'disabled'
            }]
        ],
        navCardOne: 'navCardIncome',
        navCardTwo: 'navCardGrowth'
    },
    growth: {
        key: 'growth',
        title: 'Investment plans',
        planName: 'Investment plans',
        titleLowercase: 'capital discount plan',
        tagLine: '\'Market Index\' Investment Plan',
        targetLabel: 'Investment Plans',
        description: 'Using our Investment Plans, with one step clients can invest in a highly diversified portfolio with a focus on shares trading at a discount to property valuation.',
        summary: '<p><b>Highly diversified portfolio launching 1 February 2023</b></p><p>Invest alongside Better as they undertake a programme of significant direct investment in the Exchange and build the most highly diversified portfolio available. </p><p>Better is investing to increase liquidity, thereby encouraging more efficient pricing and asset valuation across the portfolio. This portfolio will be generated at the same share prices and in the same shares that Better acquires. Read more about Better\'s programme <a href="/blog/better-resale-market-investment/" target="_blank">here</a>.</p><p>Benefits of this portfolio:</p><ul><li>Highly diversified with share numbers spread across the portfolio.</li><li>Buying strategy ensures shares are purchased at the best available price</li><li>Strategy seeks the best available share price discounts to property valuation </li><li>Low entry with the minimum investment of £1,000</li><li>Investors portfolios vary on a monthly basis</li></ul><br/><p>Investment Schedule:</p><p>Funds are required to be committed by midnight on the last day of the month preceding investment. Once your funds are committed to the investment plan, properties are acquired during the investment month for you on our Exchange according to Better\'s Investment Policy the plan rules. You will be allocated your portfolio on the first working day following the end of the investment month.  For example:</p><p>February: Clients commit funds to \'Market Index\' Investment Plan for March</p><p>March: Property investments are made according to Better\'s Investment Policy rules</p><p>April: First working day, clients are allocated their share of the portfolio acquired</p>',
        investmentPrinciplesDescription: '<p>A strict logic determines how your funds are invested to ensure your portfolio is best placed to deliver on the investment\'s Capital Discount strategy. For example we won’t invest more than 40% of your funds in a single region and we have limits on when we can buy shares on the Resale Market to avoid unfair prices. They are designed to give you the confidence to sit back and let us find the best deals for you on our platform. <a class="link" href="https://help.londonhouseexchange.com/hc/en-us/articles/360007984854-Investment-Principles" target="_blank">Learn more</a>.</p>',
        investmentStrategyDescription: '<p>Your funds will be deployed across across properties that have the highest potential for capital growth. Investments are made in such a way to diversify your funds across a number of properties with a minimum average 8.0% forecast capital growth p.a. required. Properties with the strongest forecasts, in excess of 8.0% p.a, are prioritised. <a class="link" target="_blank" href="https://help.londonhouseexchange.com/hc/en-us/articles/360007983754-How-do-you-choose-which-properties-to-invest-my-funds-in-">Learn more</a></p>',
        totalReturn: 'above 15%',
        dividendReturn: '2.5%',
        capitalReturn: '8.0%',
        dividendRatio: 25,
        capitalRatio: 75,
        learnMoreUrl: '/investmentplans#/growth',
        isDiscountBased: true,
        productList: [
            [{
                    label: 'Residential'
                },
                {
                    label: 'Commercial'
                }
            ],
            [{
                label: 'Student accommodation',
                type: 'disabled'
            }]
        ],
        navCardOne: 'navCardBalanced',
        navCardTwo: 'navCardIncome'
    },
    all_share: {
        key: 'all-share',
        title: 'LHX All-Share Investment Plan',
        planName: 'LHX All-Share',
        titleLowercase: 'LHX all-share',
        tagLine: 'Plan summary',
        targetLabel: 'All Shares',
        description: 'Using our Investment Plans, with one step clients can invest in a highly diversified portfolio with a focus on shares trading at a discount to property valuation.',
        summary: '<p>The LHX All-Share enables clients to target the capital discounts available across the Exchange, delivering the most diversified portfolio possible at the lowest share prices available.</p><h2 class="plan-title">LHX All-Share benefits:</h2><ul><li>Maximum diversification, targeting all trading properties</li><li>Bidding strategy ensures best execution at the best available price</li><li>All Share Index is trading at a significant discount to independent surveyors\' valuation</li><li>Investment from £1,000</li></ul>',
        investmentPrinciplesDescription: '<p>A strict logic determines how your funds are invested to ensure your portfolio is best placed to deliver on the investment\'s Capital Discount strategy. For example we won’t invest more than 40% of your funds in a single region and we have limits on when we can buy shares on the Resale Market to avoid unfair prices. They are designed to give you the confidence to sit back and let us find the best deals for you on our platform. <a class="link" href="https://help.propertypartner.co/hc/en-us/articles/360007984854-Investment-Principles" target="_blank">Learn more</a>.</p>',
        investmentStrategyDescription: '<p>Your funds will be deployed across across properties that have the highest potential for capital growth. Investments are made in such a way to diversify your funds across a number of properties with a minimum average 8.0% forecast capital growth p.a. required. Properties with the strongest forecasts, in excess of 8.0% p.a, are prioritised. <a class="link" target="_blank" href="https://help.propertypartner.co/hc/en-us/articles/360007983754-How-do-you-choose-which-properties-to-invest-my-funds-in-">Learn more</a></p>',
        totalReturn: 'above 15%',
        dividendReturn: '2.5%',
        capitalReturn: '8.0%',
        dividendRatio: 25,
        capitalRatio: 75,
        learnMoreUrl: '/investmentplans#/all_share',
        isDiscountBased: true,
        productList: [
            [{
                    label: 'Residential'
                },
                {
                    label: 'Student'
                },
                {
                    label: 'Commercial'
                }
            ],
            [{
                label: 'Student accommodation',
                type: 'disabled'
            }]
        ],
        navCardOne: 'navCardBalanced',
        navCardTwo: 'navCardIncome'
    },
    navCardIncome: {
        link: 'investmentplans#/income',
        title: 'Income Plan',
        description: 'Invest in high-yielding assets to earn primarily through monthly dividends',
        iconUrl: 'https://d2ofd11qqzygs0.cloudfront.net/images/icons/investment-plans/income-plan.svg'
    },
    navCardBalanced: {
        link: 'investmentplans#/balanced',
        title: 'Balanced Plan',
        description: 'Build your portfolio across a range of properties for a balanced total return',
        iconUrl: 'https://d2ofd11qqzygs0.cloudfront.net/images/icons/investment-plans/balanced-plan.svg'
    },
    navCardGrowth: {
        link: 'investmentplans#/growth',
        title: 'Growth Plan',
        description: 'Invest in properties forecast to achieve substantial capital growth',
        iconUrl: 'https://d2ofd11qqzygs0.cloudfront.net/images/icons/investment-plans/growth-plan.svg'
    },
    navCardOne: {
        link: 'https://help.londonhouseexchange.com/hc/en-us/articles/360008096933-Introduction-to-Investment-Plans',
        title: 'Why invest in an investment plan?',
        description: 'Learn more information why and what investment plan is best suited for you',
        iconUrl: 'https://d2ofd11qqzygs0.cloudfront.net/images/icons/investment-plans/floorplan-icon.svg'
    },
    navCardTwo: {
        link: 'https://help.londonhouseexchange.com/hc/en-us/articles/360007984854-Investment-Principles',
        title: 'Our investment principles',
        description: 'The rules our team follow to make the best investments for you',
        iconUrl: 'https://d2ofd11qqzygs0.cloudfront.net/images/icons/investment-plans/investment-principles-icon.svg'
    }
});
})(window, window.angular);