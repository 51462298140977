(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.fund-progress-bar', ['pp.widgets-templates', 'pp.services.core']);

/**
 * @ngdoc directive
 * @name ppFundProgressBar
 * @description
 * Renders the funding journey progress bar
 *
 * @restrict A
 * @scope
 * @param {boolean} showCurrentDetails
 * @param {string} currentStep
 */

ngModule.directive('ppFundProgressBar', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/fund-progress-bar/fund-progress-bar.tpl.html',
        scope: {
            showPersonalDetails: '=',
            currentStep: '='
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', 'ppComponentEmitter', function ($scope, $element, ppComponentEmitter) {
            var vm = this;

            // -- initial state

            // -- api

            // -- main

            vm.$postLink = function () {
                ppComponentEmitter.emit('fund-progress-bar.dom.loaded', $element);
            };

            vm.$onInit = function () {
                ppComponentEmitter.emit('fund-progress-bar.controller.loaded', vm);
            };

        }]
    };
});
})(window, window.angular);