(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-plan-detail', [
    'ngSanitize',
    'pp.widgets-templates',
    'pp.components.tooltip',
    'pp.widgets.expandable-content',
    'pp.widgets.tick-product-list',
    'pp.widgets.return-composition-bar',
    'pp.widgets.investment-plan-detail-hero',
    'pp.widgets.risk-warning-badge',
    'pp.widgets.terms-apply-badge',
    'pp.widgets.nav-card',
    'pp.widgets.premium-services-banner',
    'pp.services.core',
    'pp.services.config',
    'pp.services.route',
    'pp.ui.services.wistia-video-dialog',
    'pp.values.investment-plans'
]);

var PAGE_NAME = 'investment-plans.detail';
var SELL_VIDEO_ID = 'sap6ykfpxq';

ngModule.component('ppInvestmentPlanDetail', {
    templateUrl: 'widgets/_angular/investment-plan-detail/investment-plan-detail.tpl.html',
    bindings: {
        investmentPlan: '<',
        user: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', '$anchorScroll', 'ppConfig', 'ppTrack', 'investmentPlans', 'ppComponentEmitter', 'wistiaVideoDialogService', 'configService', 'routeService', function ($scope, $element, $anchorScroll, ppConfig, ppTrack, investmentPlans, ppComponentEmitter, wistiaVideoDialogService, configService, routeService) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints') || {};
        var assetsEndpoint = __config.assets;

        vm.investmentStrategy = 'Investment strategy';
        vm.investmentPrinciples = 'Investment principles';

        vm.exitMechanic = routeService.exitMechanic;

        vm.overviewDetailOpen = true;
        vm.strategyDetailOpen = true;
        vm.principlesDetailOpen = true;

        // -- util functions

        function trackExpandableContentToggle(type, open) {
            var action = open ? 'open' : 'close';
            ppTrack.action('investment-plans.detail.expandable-content.' + action, {
                contentType: type,
                plan: vm.investmentPlan
            });
        }

        // -- api

        vm.trackOverviewToggle = trackExpandableContentToggle.bind(null, 'overview');
        vm.trackStrategyToggle = trackExpandableContentToggle.bind(null, 'strategy');
        vm.trackPrinciplesToggle = trackExpandableContentToggle.bind(null, 'principles');

        vm.anchorScroll = $anchorScroll;

        // -- scope bindings

        vm.openSellVideo = function () {
            ppTrack.action('investment-plans.detail.open-sell-video');
            wistiaVideoDialogService.show(SELL_VIDEO_ID);
        };

        vm.$onChanges = function (changes) {

            if (changes.investmentPlan && changes.investmentPlan.currentValue) {
                vm.plan = investmentPlans[changes.investmentPlan.currentValue] || {};
                ppTrack.pageLoad(PAGE_NAME + '.' + vm.plan.key);
                vm.icon = investmentPlans.createIconUrl(assetsEndpoint, vm.plan.key);

                vm.navCardOne = investmentPlans[vm.plan.navCardOne];
                vm.navCardTwo = investmentPlans[vm.plan.navCardTwo];

                ppComponentEmitter.emit('investment-plan-detail.' + changes.investmentPlan.currentValue + '.controller.loaded', vm);
            }

        };

        vm.$postLink = function () {
            ppComponentEmitter.emit('investment-plan-detail.' + vm.investmentPlan + '.dom.loaded', $element);
        };

        vm.$onInit = function () {
            configService.minimumInvestmentPlanAmount().then(function (amount) {
                vm.minimumInvestmentPlan = amount;
            });
        };

        vm.$onDestroy = function () {
            var plan = vm.plan || {};
            ppTrack.pageClose(PAGE_NAME + '.' + plan.key);
        };

        // -- main

    }]
});
})(window, window.angular);