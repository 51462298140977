(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-details-primary', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.filters.dates',
    'pp.components.time-left',
    'pp.components.tooltip',
    'pp.widgets.aum-dividend-notice',
    'pp.values.glossary'
]);

/**
 * @ngdoc directive
 * @name ppPropertyCardDetailsPrimary
 * @description
 * Renders the details for a primary market property.
 *
 * @restrict A
 * @scope
 * @param {Object} ppPropertyCardDetailsPrimary the property model
 */
ngModule.directive('ppPropertyCardDetailsPrimary', function () {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-card-details-primary/property-card-details-primary.tpl.html',
        scope: {
            property: '<ppPropertyCardDetailsPrimary'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'glossary', function ($scope, glossary) {
            var vm = this;

            // -- initial state

            vm.netIncomeYieldPrimary = glossary.netIncomeYieldPrimary;
            vm.fiveYearHpi = glossary.fiveYearHpi;

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                vm.closingDate = new Date(vm.property.state.offerClosing);
            };

        }]
    };
});
})(window, window.angular);