(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.bond-restricted-investor-blocked-dialog', [
    'pp.widgets-templates',
    'pp.services.dialog',
    'pp.services.route',
    'pp.ui.services.classification-dialog'
]);

ngModule.component('ppBondRestrictedInvestorBlockedDialog', {
    templateUrl: 'widgets/_angular/bond-restricted-investor-blocked-dialog/bond-restricted-investor-blocked-dialog.tpl.html',
    bindings: {
        user: '<',
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', 'classificationDialogService', 'dialogService', function ($scope, routeService, classificationDialogService, dialogService) {
        var vm = this;

        // -- initial state

        vm.learnMoreLink = routeService.retailInvestorBlockedDevLoan;
        vm.marketplacePath = routeService.marketplacePath;

        // -- util functions

        // -- api
        vm.closeModal = function () {
            dialogService.close();
        };

        vm.reclassify = function () {
            dialogService.resolve(classificationDialogService.reclassifyUser.bind(null, vm.user));
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);