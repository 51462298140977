(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.index-graph', [
    'pp.widgets-templates',
    'pp.components.tabs',
    'pp.components.checkbox-indeterminate',
    'pp.components.tooltip',
    'pp.services.core',
    'pp.services.route',
    'pp.values.colours',
    'pp.filters.numbers',
    'pp.services.browser-store'
]);

var CHART_MIN_MAX_SAFE_BUFFER = 20;

ngModule.component('ppIndexGraph', {
    templateUrl: 'widgets/_angular/index-graph/index-graph.tpl.html',
    bindings: {
        marketIndex: '<',
        residentialMarketIndex: '<',
        studentMarketIndex: '<',
        currentIndex: '<',
        propertyInvestmentValueIndex: '<',
        shareInvestmentValueIndex: '<',
        historicPropertyVacantPossessionValueIndex: '<',
        historicShareVacantPossessionValueIndex: '<',
        historicPropertyResidentialInvestmentValueIndex: '<',
        historicPropertyResidentialVacantPossessionValueIndex: '<',
        historicPropertyStudentInvestmentValueIndex: '<',
        historicShareResidentialInvestmentValueIndex: '<',
        historicShareResidentialVacantPossessionValueIndex: '<',
        historicShareStudentInvestmentValueIndex: '<',
        onLoad: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$q', '$timeout', 'ppMoment', 'R', 'colours', 'ppBig', 'ppHighcharts', 'ppUtil', 'browserStoreService', 'routeService', function ($scope, $q, $timeout, ppMoment, R, colours, ppBig, ppHighcharts, ppUtil, browserStore, routeService) {
        var vm = this;

        // -- initial state

        var TWO_HOURS_MILLIS = 7200000;

        var TAB_BROWSER_STORE_KEY = 'index-graph-range-choice';

        var DEFAULT_ACTIVE_TAB = browserStore.getLocalStorageItem(TAB_BROWSER_STORE_KEY) || '1-year';

        var chart;

        var currentLoadedDefer = $q.defer();
        var currentLoadedPromise = currentLoadedDefer.promise;

        vm.chartId = ppUtil.uuid();

        vm.ppxLineActive = true;
        vm.shareValuationActive = false;
        vm.propertyValuationActive = false;

        vm.tooltips = {
            resiVpv: '<b>Residential vacant possession value</b>: Valuation if each unit within a residential block were sold individually, with vacant possession (no tenant in place)',
            resiIv: '<b>Residential investment value</b>: Valuation assuming a block of residential assets is sold in a single transaction to a single investor',
            student: 'Student accommodation properties (note, student property does not have a vacant possession value)',
            all: 'Includes residential (at VPV), student and commercial properties',
            ppx: 'Daily index of weighted average trading prices, where 100 represents share value at original purchase',
            share: 'Weighted average <u>share</u> prices based on independent valuation, where 100 represents <u>share</u> value at original purchase',
            property: 'Weighted average <u>property</u> prices based on independent valuation, where 100 represents original <u>property</u> purchase price'
        };

        var INDEX_SMOOTHED_STOP_DATE = new Date('2017-01-01').valueOf();

        var rangeMinDate = {
            '5-days': ppMoment().subtract(5, 'days'),
            '1-month': ppMoment().subtract(1, 'month'),
            '6-months': ppMoment().subtract(6, 'months'),
            'ytd': ppMoment().startOf('year'),
            '1-year': ppMoment().subtract(1, 'year'),
            '3-years': ppMoment().subtract(3, 'years'),
            'max': ppMoment().subtract(9999, 'years')
        };

        var getSmoothedPoints = function (items) {
            return items.filter(function (item) {
                return item[0] < INDEX_SMOOTHED_STOP_DATE;
            });
        };

        var getUnsmoothedPoints = function (items) {
            return items.filter(function (item) {
                return item[0] >= INDEX_SMOOTHED_STOP_DATE;
            });
        };

        var tooltipSeriesDict = {
            'LHX: Share Trading Index - All Shares': 'LHX',
            'Independent Share Valuation Index': 'Share Valuation',
            'Independent Property Valuation Index': 'Property Valuation'
        };

        var isNotNil = R.complement(R.isNil);

        vm.rangeOptions = [{
                title: '1 month',
                key: '1-month'
            },
            {
                title: '6 months',
                key: '6-months'
            },
            {
                title: 'YTD',
                key: 'ytd'
            },
            {
                title: '1 Year',
                key: '1-year'
            },
            {
                title: '3 Years',
                key: '3-years'
            },
            {
                title: 'Max',
                key: 'max'
            }
        ];

        vm.menuState = {

            marketIndex: {
                checked: false,
                indeterminate: true
            },
            share: {
                checked: false,
                indeterminate: false
            },
            property: {
                checked: false,
                indeterminate: false
            },
            residentialIv: {
                expanded: true,
                checked: false,
                indeterminate: false,
                marketIndex: true,
                share: {
                    checked: false
                },
                property: {
                    checked: false
                }
            },
            residentialVpv: {
                expanded: true,
                checked: true,
                indeterminate: false,
                marketIndex: true,
                share: {
                    checked: true
                },
                property: {
                    checked: true
                }
            },
            student: {
                expanded: true,
                checked: false,
                indeterminate: false,
                marketIndex: false,
                share: {
                    checked: false
                },
                property: {
                    checked: false
                }
            },
            all: {
                expanded: true,
                checked: false,
                indeterminate: false,
                marketIndex: false,
                share: {
                    checked: false
                },
                property: {
                    checked: false
                }
            }
        };

        vm.activeTab = DEFAULT_ACTIVE_TAB;

        vm.ppxKnowledgeBaseLink = routeService.ppxKnowledgeBaseLink;

        // -- util functions

        var normaliseData = R.map(function (item) {
            // add two hours to datePoint to avoid BST UTC issues of going to previous day
            return [item.datePoint + TWO_HOURS_MILLIS, item.indexValue];
        });

        var filterRange = R.curry(function (dateBase, item) {
            return ppMoment(item.datePoint).isSameOrAfter(dateBase);
        });

        var baseSetup = function (dateBase) {
            return R.compose(normaliseData, R.filter(filterRange(dateBase)));
        };

        var createDataSetup = function (baseDate) {
            return R.compose(baseSetup(baseDate));
        };

        var createGetStartDate = function (baseDate) {
            return R.compose(R.last, R.head, baseSetup(baseDate));
        };

        var getGraphLineColour = R.cond([
            [R.gt(0), R.always(colours.marketIndexPositive)],
            [R.T, R.always(colours.marketIndexPositive)]
        ]);

        var getStartDate = {
            '5-days': createGetStartDate(ppMoment().subtract(5, 'days')),
            '1-month': createGetStartDate(ppMoment().subtract(1, 'month')),
            '6-months': createGetStartDate(ppMoment().subtract(6, 'months')),
            'ytd': createGetStartDate(ppMoment().startOf('year')),
            '1-year': createGetStartDate(ppMoment().subtract(1, 'year')),
            '3-years': createGetStartDate(ppMoment().subtract(3, 'years')),
            'max': createGetStartDate(ppMoment().subtract(9999, 'years'))
        };

        var prepareData = {
            '5-days': createDataSetup(ppMoment().subtract(5, 'days')),
            '1-month': createDataSetup(ppMoment().subtract(1, 'month')),
            '6-months': createDataSetup(ppMoment().subtract(6, 'months')),
            'ytd': createDataSetup(ppMoment().startOf('year')),
            '1-year': createDataSetup(ppMoment().subtract(1, 'year')),
            '3-years': createDataSetup(ppMoment().subtract(3, 'years')),
            'max': createDataSetup(ppMoment().subtract(9999, 'years'))
        };

        var parentChildCheckboxes = [{
                key: 'residentialIv.checked',
                parent: R.lensPath(['residentialIv', 'checked']),
                parentIndeterminate: R.lensPath(['residentialIv', 'indeterminate']),
                children: [
                    R.lensPath(['residentialVpv', 'marketIndex']),
                    R.lensPath(['residentialIv', 'share', 'checked']),
                    R.lensPath(['residentialIv', 'property', 'checked'])
                ]
            },
            {
                key: 'residentialVpv.checked',
                parent: R.lensPath(['residentialVpv', 'checked']),
                parentIndeterminate: R.lensPath(['residentialVpv', 'indeterminate']),
                children: [
                    R.lensPath(['residentialVpv', 'marketIndex']),
                    R.lensPath(['residentialVpv', 'share', 'checked']),
                    R.lensPath(['residentialVpv', 'property', 'checked'])
                ]
            },
            {
                key: 'student.checked',
                parent: R.lensPath(['student', 'checked']),
                parentIndeterminate: R.lensPath(['student', 'indeterminate']),
                children: [
                    R.lensPath(['student', 'marketIndex']),
                    R.lensPath(['student', 'share', 'checked']),
                    R.lensPath(['student', 'property', 'checked'])
                ]
            },
            {
                key: 'all.checked',
                parent: R.lensPath(['all', 'checked']),
                parentIndeterminate: R.lensPath(['all', 'indeterminate']),
                children: [
                    R.lensPath(['all', 'marketIndex']),
                    R.lensPath(['all', 'share', 'checked']),
                    R.lensPath(['all', 'property', 'checked'])
                ]
            },
            {
                key: 'marketIndex.checked',
                parent: R.lensPath(['marketIndex', 'checked']),
                parentIndeterminate: R.lensPath(['marketIndex', 'indeterminate']),
                children: [
                    R.lensPath(['all', 'marketIndex']),
                    R.lensPath(['student', 'marketIndex']),
                    R.lensPath(['residentialVpv', 'marketIndex'])
                ]
            },
            {
                key: 'property.checked',
                parent: R.lensPath(['property', 'checked']),
                parentIndeterminate: R.lensPath(['property', 'indeterminate']),
                children: [
                    R.lensPath(['residentialVpv', 'property', 'checked']),
                    R.lensPath(['residentialIv', 'property', 'checked']),
                    R.lensPath(['student', 'property', 'checked']),
                    R.lensPath(['all', 'property', 'checked'])
                ]
            },
            {
                key: 'share.checked',
                parent: R.lensPath(['share', 'checked']),
                parentIndeterminate: R.lensPath(['share', 'indeterminate']),
                children: [
                    R.lensPath(['residentialVpv', 'share', 'checked']),
                    R.lensPath(['residentialIv', 'share', 'checked']),
                    R.lensPath(['student', 'share', 'checked']),
                    R.lensPath(['all', 'share', 'checked'])
                ]
            }

        ];

        var createArrayFromKey = R.split('.');

        var toggleSection = R.flip(R.over)(R.not);
        var updateToggleSection = R.compose(toggleSection, R.lensPath, createArrayFromKey);

        var updateSetSection = R.compose(R.set, R.lensPath, createArrayFromKey);

        var getChildrenLenses = R.compose(R.flatten, R.map(R.prop('children')));
        var getParentLenses = R.map(R.prop('parent'));

        var setChecked = R.set(R.lensProp('checked'));
        var setIndeterminate = R.set(R.lensProp('indeterminate'));

        var normaliseMenuState = R.curry(function (key, menuState) {

            var currentValue = R.path(createArrayFromKey(key), menuState);

            var setValue = R.flip(R.set)(currentValue);
            var setFalse = R.flip(R.set)(false);
            var equalsKey = R.pathEq(['key'], key);
            var notEqualsKey = R.compose(R.not, equalsKey);
            var childrenUpdates = R.compose(getChildrenLenses, R.filter(equalsKey))(parentChildCheckboxes);

            var allLenses = R.converge(R.concat, [getChildrenLenses, getParentLenses])(parentChildCheckboxes);

            var menuStateAllClear = R.reduce(function (sectionObj, lensUpdate) {
                return setFalse(lensUpdate, sectionObj);
            }, menuState, allLenses);

            var sectionsWithChildrenUpdates = R.reduce(function (sectionObj, lensUpdate) {
                return setValue(lensUpdate, sectionObj);
            }, menuStateAllClear, childrenUpdates);

            var viewValue = R.flip(R.view)(sectionsWithChildrenUpdates);

            return R.reduce(function (sectionObj, item) {

                var parentValue = R.view(item.parent, sectionObj);
                var childrenValue = item.children.map(function (childLens) {
                    return viewValue(childLens);
                });

                var equalsParent = R.equals(parentValue);

                if (R.all(equalsParent, childrenValue)) {
                    return R.compose(
                        R.set(item.parentIndeterminate, false)
                    )(sectionObj);
                } else if (R.any(equalsParent, childrenValue)) {
                    return R.compose(
                        R.set(item.parentIndeterminate, true),
                        R.set(item.parent, false)
                    )(sectionObj);
                } else {
                    return R.compose(
                        R.set(item.parentIndeterminate, false),
                        R.set(item.parent, R.head(childrenValue))
                    )(sectionObj);
                }

            }, sectionsWithChildrenUpdates, parentChildCheckboxes);
        });

        function updateChartData(
            chart,
            marketIndex,
            residentialMarketIndex,
            studentMarketIndex,
            historicPropertyVacantPossessionValueIndex,
            historicShareVacantPossessionValueIndex,
            historicPropertyResidentialInvestmentValueIndex,
            historicPropertyResidentialVacantPossessionValueIndex,
            historicPropertyStudentInvestmentValueIndex,
            historicShareResidentialInvestmentValueIndex,
            historicShareResidentialVacantPossessionValueIndex,
            historicShareStudentInvestmentValueIndex
        ) {
            chart.series[0].setData(marketIndex, false);
            chart.series[1].setData(residentialMarketIndex, false);
            chart.series[2].setData(studentMarketIndex, false);

            chart.series[3].setData(getSmoothedPoints(historicPropertyVacantPossessionValueIndex), false);
            chart.series[4].setData(getUnsmoothedPoints(historicPropertyVacantPossessionValueIndex), false);

            chart.series[5].setData(getSmoothedPoints(historicShareVacantPossessionValueIndex), false);
            chart.series[6].setData(getUnsmoothedPoints(historicShareVacantPossessionValueIndex), false);

            chart.series[7].setData(getSmoothedPoints(historicPropertyResidentialInvestmentValueIndex), false);
            chart.series[8].setData(getUnsmoothedPoints(historicPropertyResidentialInvestmentValueIndex), false);

            chart.series[9].setData(getSmoothedPoints(historicPropertyResidentialVacantPossessionValueIndex), false);
            chart.series[10].setData(getUnsmoothedPoints(historicPropertyResidentialVacantPossessionValueIndex), false);

            chart.series[11].setData(getUnsmoothedPoints(historicPropertyStudentInvestmentValueIndex), false);

            chart.series[12].setData(getSmoothedPoints(historicShareResidentialInvestmentValueIndex), false);
            chart.series[13].setData(getUnsmoothedPoints(historicShareResidentialInvestmentValueIndex), false);

            chart.series[14].setData(getSmoothedPoints(historicShareResidentialVacantPossessionValueIndex), false);
            chart.series[15].setData(getUnsmoothedPoints(historicShareResidentialVacantPossessionValueIndex), false);

            chart.series[16].setData(getUnsmoothedPoints(historicShareStudentInvestmentValueIndex), false);

            chart.redraw();
        }

        function updateTradingSeriesLineColour(chart, ptsChange) {
            chart.series[0].update({
                color: getGraphLineColour(ptsChange)
            });
        }

        function forwardFill(data, fullDataSet, marketIndex) {

            var lastDataItem = R.last(fullDataSet);
            var lastDataDate = R.path(['datePoint'], lastDataItem);
            var lastDataPoint = R.path(['indexValue'], lastDataItem);

            if (R.isNil(lastDataDate) || R.isNil(lastDataPoint)) {
                // @Note early return
                return data;
            }

            var setToLatestValue = R.set(R.lensIndex(1), lastDataPoint);

            var itemsToUpdate = R.filter(R.compose(R.lt(lastDataDate), R.path([0])))(marketIndex);

            return R.compose(
                R.concat(data),
                R.map(setToLatestValue)
            )(itemsToUpdate);

        }

        function createChart(
            marketIndex,
            residentialMarketIndex,
            studentMarketIndex,
            historicPropertyVacantPossessionValueIndex,
            historicShareVacantPossessionValueIndex,
            historicPropertyResidentialInvestmentValueIndex,
            historicPropertyResidentialVacantPossessionValueIndex,
            historicPropertyStudentInvestmentValueIndex,
            historicShareResidentialInvestmentValueIndex,
            historicShareResidentialVacantPossessionValueIndex,
            historicShareStudentInvestmentValueIndex
        ) {

            if (chart) {
                updateChartData(
                    chart,
                    marketIndex,
                    residentialMarketIndex,
                    studentMarketIndex,
                    historicPropertyVacantPossessionValueIndex,
                    historicShareVacantPossessionValueIndex,
                    historicPropertyResidentialInvestmentValueIndex,
                    historicPropertyResidentialVacantPossessionValueIndex,
                    historicPropertyStudentInvestmentValueIndex,
                    historicShareResidentialInvestmentValueIndex,
                    historicShareResidentialVacantPossessionValueIndex,
                    historicShareStudentInvestmentValueIndex
                );
            } else {
                chart = ppHighcharts.chart(vm.chartId, {
                    chart: {
                        type: 'spline',
                        height: 400,
                        color: '#000',
                        backgroundColor: 'rgba(255,255,255,0)',
                        style: {
                            fontFamily: 'Proxima'
                        }
                    },
                    tooltip: {
                        xDateFormat: '%d/%m/%Y',
                        backgroundColor: '#FFF',
                        valueDecimals: 2,
                        split: true,
                        style: {
                            fontSize: '14px'
                        },
                        formatter: function () {
                            return ['<b>' + ppMoment(this.x).format('DD/MM/YYYY') + '</b>'].concat(
                                this.points ?
                                this.points.map(function (point) {
                                    return (point.series.name) + ': <b>' + ppBig(point.y).toFixed(1) + '</b>';
                                }) : []
                            );
                        },
                    },
                    plotOptions: {
                        series: {
                            states: {
                                inactive: {
                                    opacity: 1
                                }
                            }
                        }
                    },
                    title: {
                        text: null,
                        style: {
                            'color': '#FFF'
                        }
                    },
                    xAxis: {
                        title: {
                            text: null
                        },
                        tickLength: 0,
                        type: 'datetime',
                        labels: {
                            style: {
                                fontSize: '14px'
                            }
                        }
                    },
                    yAxis: {
                        allowDecimals: false,
                        title: {
                            text: null
                        },
                        lineWidth: 0,
                        minorGridLineWidth: 0,
                        gridLineWidth: 1,
                        tickLength: 0,
                        labels: {
                            style: {
                                fontSize: '14px'
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    legend: {
                        enabled: false,
                        labelFormatter: function () {
                            if (this.visible) {
                                return this.name + ' [✓]';
                            } else {
                                return this.name + ' [-]';
                            }
                        },
                        itemStyle: {
                            fontSize: '14px'
                        }
                    },
                    series: [{
                            data: marketIndex,
                            color: colours.ppxAll,
                            showInLegend: false,
                            name: 'LHX all-share trading index',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.all.marketIndex
                        },
                        {
                            data: residentialMarketIndex,
                            color: colours.ppxResi,
                            showInLegend: false,
                            name: 'Resi trading index',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.residentialVpv.marketIndex
                        },
                        {
                            data: studentMarketIndex,
                            color: colours.ppxStudent,
                            showInLegend: false,
                            name: 'Student trading index',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.student.marketIndex
                        },

                        {
                            data: getSmoothedPoints(historicPropertyVacantPossessionValueIndex),
                            color: ppUtil.darken(colours.ppxAll, 60),
                            showInLegend: false,
                            dashStyle: 'dash',
                            name: 'All properties value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.all.property.checked
                        },
                        {
                            data: getUnsmoothedPoints(historicPropertyVacantPossessionValueIndex),
                            color: ppUtil.darken(colours.ppxAll, 60),
                            showInLegend: false,
                            name: 'All properties value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.all.property.checked
                        },
                        {

                            data: getSmoothedPoints(historicShareVacantPossessionValueIndex),
                            color: ppUtil.darken(colours.ppxAll, 30),
                            showInLegend: false,
                            dashStyle: 'dash',
                            name: 'All shares value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.all.share.checked
                        },
                        {
                            data: getUnsmoothedPoints(historicShareVacantPossessionValueIndex),
                            color: ppUtil.darken(colours.ppxAll, 30),
                            showInLegend: false,
                            name: 'All shares value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.all.share.checked
                        },
                        {
                            data: getSmoothedPoints(historicPropertyResidentialInvestmentValueIndex),
                            color: ppUtil.darken(colours.ppxResiIv, 60),
                            showInLegend: false,
                            dashStyle: 'dash',
                            name: 'Resi IV property value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.residentialIv.property.checked
                        },
                        {
                            data: getUnsmoothedPoints(historicPropertyResidentialInvestmentValueIndex),
                            color: ppUtil.darken(colours.ppxResiIv, 60),
                            showInLegend: false,
                            name: 'Resi IV property value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.residentialIv.property.checked
                        },
                        {
                            data: getSmoothedPoints(historicPropertyResidentialVacantPossessionValueIndex),
                            color: '#13612b',
                            showInLegend: false,
                            dashStyle: 'dash',
                            name: 'Resi VPV property value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.residentialVpv.property.checked
                        },
                        {
                            data: getUnsmoothedPoints(historicPropertyResidentialVacantPossessionValueIndex),
                            color: '#13612b',
                            showInLegend: false,
                            name: 'Resi VPV property value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.residentialVpv.property.checked
                        },
                        {
                            data: getUnsmoothedPoints(historicPropertyStudentInvestmentValueIndex),
                            color: '#005a81',
                            showInLegend: false,
                            name: 'Student property value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.student.property.checked
                        },
                        {
                            data: getSmoothedPoints(historicShareResidentialInvestmentValueIndex),
                            color: colours.ppxResiIv,
                            showInLegend: false,
                            dashStyle: 'dash',
                            name: 'Resi IV share value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.residentialIv.share.checked
                        },
                        {
                            data: getUnsmoothedPoints(historicShareResidentialInvestmentValueIndex),
                            color: colours.ppxResiIv,
                            showInLegend: false,
                            name: 'Resi IV share value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.residentialIv.share.checked
                        },
                        {
                            data: getSmoothedPoints(historicShareResidentialVacantPossessionValueIndex),
                            color: ppUtil.darken(colours.ppxResi, 30),
                            showInLegend: false,
                            dashStyle: 'dash',
                            name: 'Resi VPV share value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.residentialVpv.share.checked
                        },
                        {
                            data: getUnsmoothedPoints(historicShareResidentialVacantPossessionValueIndex),
                            color: ppUtil.darken(colours.ppxResi, 30),
                            showInLegend: false,
                            name: 'Resi VPV share value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.residentialVpv.share.checked
                        },
                        {
                            data: getUnsmoothedPoints(historicShareStudentInvestmentValueIndex),
                            color: ppUtil.darken(colours.ppxStudent, 30),
                            showInLegend: false,
                            name: 'Student share value',
                            boostThreshold: 1,
                            marker: {
                                symbol: 'circle',
                                radius: 0
                            },
                            visible: vm.menuState.student.share.checked
                        }
                    ]
                });
            }

            currentLoadedDefer.resolve(chart);
        }

        function setupChart(range) {
            var setupData = prepareData[range];
            var marketData = setupData(vm.marketIndex);
            var residentialMarketIndex = setupData(vm.residentialMarketIndex);
            var studentMarketIndex = setupData(vm.studentMarketIndex);

            createChart(
                marketData,
                residentialMarketIndex,
                studentMarketIndex,
                forwardFill(setupData(vm.historicPropertyVacantPossessionValueIndex), vm.historicPropertyVacantPossessionValueIndex, marketData),
                forwardFill(setupData(vm.historicShareVacantPossessionValueIndex), vm.historicShareVacantPossessionValueIndex, marketData),
                forwardFill(setupData(vm.historicPropertyResidentialInvestmentValueIndex), vm.historicPropertyResidentialInvestmentValueIndex, marketData),
                forwardFill(setupData(vm.historicPropertyResidentialVacantPossessionValueIndex), vm.historicPropertyResidentialVacantPossessionValueIndex, marketData),
                forwardFill(setupData(vm.historicPropertyStudentInvestmentValueIndex), vm.historicPropertyStudentInvestmentValueIndex, marketData),
                forwardFill(setupData(vm.historicShareResidentialInvestmentValueIndex), vm.historicShareResidentialInvestmentValueIndex, marketData),
                forwardFill(setupData(vm.historicShareResidentialVacantPossessionValueIndex), vm.historicShareResidentialVacantPossessionValueIndex, marketData),
                forwardFill(setupData(vm.historicShareStudentInvestmentValueIndex), vm.historicShareStudentInvestmentValueIndex, marketData)
            );
        }

        function setChangeNumbers(currentIndex, marketIndex) {
            if (currentIndex && marketIndex) {
                var mostRecentMarketIndex = R.path(['indexValue'], R.last(marketIndex));
                var ptsChange = ppBig(currentIndex.index).minus(mostRecentMarketIndex);
                vm.pctChange = Number(ptsChange.div(mostRecentMarketIndex).times(100));
                vm.ptsChange = Number(ptsChange);
            }

            return vm.ptsChange;
        }

        // -- api

        vm.onChangeActiveLine = function () {

            chart.series[0].update({
                visible: vm.menuState.all.marketIndex
            });

            chart.series[1].update({
                visible: vm.menuState.residentialVpv.marketIndex
            });

            chart.series[2].update({
                visible: vm.menuState.student.marketIndex
            });

            chart.series[3].update({
                visible: vm.menuState.all.property.checked
            });

            chart.series[4].update({
                visible: vm.menuState.all.property.checked
            });
            chart.series[5].update({
                visible: vm.menuState.all.share.checked
            });
            chart.series[6].update({
                visible: vm.menuState.all.share.checked
            });

            // historicPropertyResidentialInvestmentValueIndex,

            chart.series[7].update({
                visible: vm.menuState.residentialIv.property.checked
            });

            chart.series[8].update({
                visible: vm.menuState.residentialIv.property.checked
            });

            // historicPropertyResidentialVacantPossessionValueIndex,

            chart.series[9].update({
                visible: vm.menuState.residentialVpv.property.checked
            });

            chart.series[10].update({
                visible: vm.menuState.residentialVpv.property.checked
            });

            // historicPropertyStudentInvestmentValueIndex,

            chart.series[11].update({
                visible: vm.menuState.student.property.checked
            });

            // historicShareResidentialInvestmentValueIndex,

            chart.series[12].update({
                visible: vm.menuState.residentialIv.share.checked
            });

            chart.series[13].update({
                visible: vm.menuState.residentialIv.share.checked
            });

            // historicShareResidentialVacantPossessionValueIndex,

            chart.series[14].update({
                visible: vm.menuState.residentialVpv.share.checked
            });

            chart.series[15].update({
                visible: vm.menuState.residentialVpv.share.checked
            });

            // historicShareStudentInvestmentValueIndex

            chart.series[16].update({
                visible: vm.menuState.student.share.checked
            });

            chart.redraw();

        };

        vm.toggleExpandedForSection = function (section) {
            var currentValueForSection = R.path(createArrayFromKey(section), vm.menuState);
            vm.menuState = R.compose(
                updateSetSection(section)(R.not(currentValueForSection))
            )(vm.menuState);
        };

        vm.toggleLinesOnGraph = function (section) {
            vm.menuState = R.compose(
                normaliseMenuState(section)
            )(vm.menuState);
            vm.onChangeActiveLine();
        };

        vm.getIndexChangeClass = function (ptsChange) {
            if (ptsChange > 0) {
                return 'positive';
            }

            if (ptsChange < 0) {
                return 'negative';
            }
        };

        vm.onRangeChange = function (range) {
            browserStore.setLocalStorageItem(TAB_BROWSER_STORE_KEY, range);
            setupChart(range);
        };

        // -- scope bindings

        vm.$onDestroy = function () {
            if (chart) {
                chart.destroy();
            }
        };

        vm.$onChanges = function (changes) {
            var currentIndex = R.defaultTo(vm.currentIndex, R.path(['currentIndex', 'currentValue'], changes));
            var marketIndex = R.defaultTo(vm.marketIndex, R.path(['marketIndex', 'currentValue'], changes));
            setChangeNumbers(currentIndex, marketIndex);
        };

        vm.$onInit = function () {
            setChangeNumbers(vm.currentIndex, vm.marketIndex);
            $timeout(function () {
                return setupChart(vm.activeTab);
            }).then(function () {
                vm.onLoad();
            });
        };

        // -- main

    }]
});
})(window, window.angular);