(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.classification', [
    'pp.services.investor',
    'pp.services.core'
]);

var API_BASE_PATH = '/feapi/r1';

var POST_CLASSIFY_ENDPOINT = API_BASE_PATH + '/investor/classification';

var QUIZ_QUESTIONS_ENDPOINT = API_BASE_PATH + '/investor/suitability-quiz';

var CLASSIFY_CODES = {
    'high-net': 'HW',
    'sophisticated': 'SI',
    'regular': 'RI'
};

ngModule.service('classificationService', ['$http', '$q', 'investorService', 'ppMoment', function ($http, $q, investorService, ppMoment) {

    var api = {};

    api.selfDeclaredInvestorClassification = function (classification, description, questions, noneOfTheseApplyToMe, signature) {
        var payload = {
            type: CLASSIFY_CODES[classification],
            description: description,
            questions: questions,
            noneOfTheseApplyToMe: noneOfTheseApplyToMe,
            signature: signature,
            date: ppMoment().format('YYYY-MM-DD')
        };

        var endpoint = POST_CLASSIFY_ENDPOINT;

        return $http.post(endpoint, payload).then(
            function () {
                investorService.purgeCache('^investor$');
                return true;
            },
            function () {
                return $q.reject({
                    reason: 'classification.error'
                });
            }
        );
    };

    api.getQuizQuestions = function () {
        var endpoint = QUIZ_QUESTIONS_ENDPOINT;

        return $http.get(endpoint).then(function (res) {
            return res.data;
        }, function (error) {
            return $q.reject(error);
        });
    };

    api.postQuizAnswers = function (answers, quizName) {
        var endpoint = QUIZ_QUESTIONS_ENDPOINT;
        var payload = {
            answers: answers,
            quizName: quizName
        };

        return $http.post(endpoint, payload).then(function (res) {
            return res.data;
        }, function (error) {
            return $q.reject(error);
        });
    };

    api.submitQuiz = function (answers) {
        var payload = {
            classificationOrAnswers: answers
        };

        var endpoint = POST_CLASSIFY_ENDPOINT;

        return $http.post(endpoint, payload).then(
            function () {
                investorService.purgeCache('^investor$');
                return true;
            },
            function (error) {
                return $q.reject(error);
            }
        );

    };

    return api;
}]);
})(window, window.angular);