(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.what-is-crowdfunding-lp', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.route',
    'pp.widgets.ways-to-invest',
    'pp.widgets.awards-banner',
    'pp.widgets.risk-warning-badge',
    'pp.widgets.expandable-content',
    'pp.widgets.nav-card'
]);

var PAGE_NAME = 'what-is-crowdfunding';

ngModule.component('ppWhatIsCrowdfundingLp', {
    templateUrl: 'widgets/_angular/what-is-crowdfunding-lp/what-is-crowdfunding-lp.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', 'ppPubSub', 'investorService', 'routeService', 'ppTrack', function ($scope, ppConfig, ppPubSub, investorService, routeService, ppTrack) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints') || {};
        var s3Endpoint = __config.assets;

        vm.iconPeopleMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-people-marker.png';
        vm.iconLightbulbMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-lightbuld-marker.png';
        vm.iconCogMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-cog-marker.png';

        vm.exampleHeading = 'Example of how property crowdfunding works';
        vm.popularHeading = 'How did property crowdfunding become popular?';
        vm.returnsHeading = 'Does property crowdfunding offer good returns?';
        vm.propertyTypesHeading = 'What types of property can you crowdfund?';
        vm.howToInvestHeading = 'How do you invest in property via crowdfunding?';

        vm.whatIsCrowdfundingPath = routeService.whatIsCrowdfundingPath;
        vm.crowdfundingBenefitsPath = routeService.crowdfundingBenefitsPath;
        vm.howCrowdfundingWorksPath = routeService.howCrowdfundingWorksPath;

        vm.propertyTeamPath = routeService.propertyTeamPath;

        vm.blogAssetSale = routeService.blogAssetSale;
        vm.blogDiversify = routeService.blogDiversify;

        // -- util functions

        // -- api

        vm.openSignup = function () {
            ppPubSub.publish('auth.modal.show', ['signup', null, null, null, null]);
        };

        vm.openLogin = function () {
            ppPubSub.publish('auth.modal.show', ['login', null, null, null, null]);
        };

        // -- scope bindings

        // -- main

        vm.$onDestroy = function () {
            ppTrack.pageClose(PAGE_NAME);
        };

        investorService.getInvestor().then(function (user) {
            ppTrack.pageLoad(PAGE_NAME);
            vm.user = user;
        });

    }]
});
})(window, window.angular);