(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.kyc-status-banner', [
    'pp.widgets-templates',
    'pp.components.time-left'
]);

ngModule.directive('ppKycStatusBanner', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/kyc-status-banner/kyc-status-banner.tpl.html',
        scope: {
            user: '<',
            kycRequiredMessage: '@',
            startKyc: '&?'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);