(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.shares-order-liquidity-details', ['pp.widgets-templates', 'pp.components-templates', 'pp.components.table', 'pp.filters.numbers', 'pp.filters.property', 'pp.services.core', 'pp.services.property']);

var DATA_TYPE_BID = 'bid';
var DATA_TYPE_OFFER = 'offer';

ngModule.directive('ppSharesOrderLiquidityDetails', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/shares-order-liquidity-details/shares-order-liquidity-details.tpl.html',
        scope: {
            symbol: '<ppSharesOrderLiquidityDetails',
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['ppTrack', 'propertyService', '$q', '$filter', function (ppTrack, propertyService, $q, $filter) {
            var vm = this;

            // -- initial state

            vm.ready = false;
            vm.liquidityData = [];
            vm.selectedItems = {};
            vm.columns = [{
                key: 'askPrice',
                label: 'Price'
            }, {
                key: 'totalAmount',
                label: 'Available'
            }, {
                key: 'units',
                label: 'Shares'
            }, {
                key: 'orders',
                label: 'Orders'
            }];

            // -- util functions

            var formatDataForTable = function (data, type) {
                if (!data) {
                    return [];
                }

                return data.map(function (element) {
                    return {
                        class: type,
                        askPrice: element.askPrice ? $filter('ppPence')(element.askPrice) : '-',
                        totalAmount: element.totalAmount ? $filter('ppCurrency')(element.totalAmount) : '-',
                        units: element.units ? $filter('number')(element.units) : '-',
                        orders: element.orders ? $filter('number')(element.orders) : '-'
                    };
                });
            };

            // -- api

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                var promises = [
                    propertyService.getOrderSharesLiquidity(vm.symbol),
                    propertyService.getPropertyStatic(vm.symbol)
                ];

                $q.all(promises).then(function (data) {
                        var liquidityData = data[0] || {};
                        var bids = formatDataForTable(liquidityData.bids, DATA_TYPE_BID).reverse();
                        var offers = formatDataForTable(liquidityData.offers, DATA_TYPE_OFFER);
                        vm.liquidityData = bids.concat(offers);
                        vm.dataLength = Math.max(bids.length, offers.length);
                        vm.property = data[1];
                    })
                    .catch(function () {
                        vm.loadingError = true;
                    })
                    .finally(function () {
                        vm.ready = true;
                    });

            };

        }]
    };
}]);
})(window, window.angular);