(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.aum-dividend-notice', [
    'pp.widgets-templates',
    'pp.services.route'
]);

ngModule.component('ppAumDividendNotice', {
    templateUrl: 'widgets/_angular/aum-dividend-notice/aum-dividend-notice.tpl.html',
    bindings: {
        isInline: '<',
        isCentered: '<',
        equalFontSize: '<',
        smallFontSize: '<',
        lightColour: '<',
        hasHandle: '<',
        linkToFaqs: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', function ($scope, routeService) {
        var vm = this;

        // -- initial state

        vm.feesChargesHelp = routeService.feesChargesHelp;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);