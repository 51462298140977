(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.risk-warning-badge', [
    'pp.widgets-templates',
    'pp.widgets.terms-apply-badge',
    'pp.services.route'
]);

ngModule.component('ppRiskWarningBadge', {
    templateUrl: 'widgets/_angular/risk-warning-badge/risk-warning-badge.tpl.html',
    bindings: {
        openNewTab: '@',
        includeTerms: '<',
        type: '@',
        isNote: '<',
        theme: '@'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', function ($scope, routeService) {
        var vm = this;

        // -- initial state

        vm.keyRisksUrl = routeService.keyRisksUrl;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);