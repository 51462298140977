(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.withdrawal-bank-account-uk', ['pp.widgets-templates', 'pp.services.investor', 'pp.components.tooltip', 'pp.components.input-sort-code', 'pp.values.form-lists']);

/**
 * @ngdoc directive
 * @name ppWithdrawalBankAccountUk
 * @description
 * Allows the user to register a bank account.
 *
 *
 * @restrict A
 */
ngModule.directive('ppWithdrawalBankAccountUk', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/withdrawal-bank-account-uk/withdrawal-bank-account-uk.tpl.html',
        scope: {
            account: '=ppWithdrawalBankAccountUk'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['formLists', 'investorService', function (formLists, investorService) {
            var vm = this;

            //-- initial state

            vm.formLists = formLists;

            vm.accountNumberRegexp = investorService.accountNumberRegexp;
            vm.sortCodeRegexp = investorService.sortCodeRegexp;

        }]
    };
}]);
})(window, window.angular);