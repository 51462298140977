(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.bid', [
    'pp.widgets-templates',
    'ng.cork.ui.focus-on',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.property',
    'pp.services.nationality',
    'pp.services.route',
    'pp.ui.services.suspended-property-dialog',
    'pp.ui.services.terms-dialog',
    'pp.ui.services.receipt-dialog-service',
    'pp.ui.services.nationality-lei-dialog',
    'pp.components.input-amount',
    'pp.widgets.shares-order-book-chart',
    'pp.widgets.property-details',
    'pp.widgets.shares-traded-chart',
    'pp.widgets.kyc-and-classification',
    'pp.components.input-plus-minus',
    'pp.widgets.bid-form',
    'pp.widgets.help'
]);

var PAGE_NAME = 'bid';
var BLOCKED_REDIRECT_URL = '/properties/:symbol';
var PROPERTY_NOT_TRADABLE_ERROR = 'property.currently.not.tradable';
var STEP_KYC = 'kyc';
var STEP_BID = 'bid';

ngModule.controller('ppBidController', ['$window', '$q', '$attrs', 'ppConfig', 'ppTrack', 'ppUtil', 'ppPubSub', 'investorService', 'receiptDialogService', 'propertyService', 'nationalityService', 'termsDialogService', 'suspendedPropertyDialogService', 'nationalityLeiDialogService', 'configService', 'routeService', function ($window, $q, $attrs, ppConfig, ppTrack, ppUtil, ppPubSub, investorService, receiptDialogService, propertyService, nationalityService, termsDialogService, suspendedPropertyDialogService, nationalityLeiDialogService, configService, routeService) {
    var vm = this;

    // -- initial state
    var __default = ppConfig.get('pp.default-budget');
    var __globalConfig = ppConfig.get('pp.global') || {};
    var __authConfig = ppConfig.get('pp.modules.auth') || {};
    var property;
    var symbol;

    vm.reCaptchaKey = __authConfig.reCaptchaKey;
    vm.reCaptchaEnabled = __authConfig.reCaptchaEnabled;

    // @TODO someday move into a service that takes offer and percentage and gives back max shares per property per investor
    vm.maxSharesPerInvestorPerProperty = Math.floor((__globalConfig.maxOwnershipPerPropertyPct / 100) * 1000000);
    vm.defaultBudget = __default.budget;

    vm.disabled = true;
    vm.valid = false;
    vm.ready = false;
    vm.processing = false;
    vm.error = false;

    vm.fundPath = routeService.fundPath;

    vm.successAction = routeService.portfolioBidsPath;

    // -- util functions

    function redirect(href) {
        ppTrack.closeAll().then(function () {
            window.location.href = href;
        });
    }

    function redirectBlockedUser(symbol) {
        window.pp.overlay.show();
        ppTrack.pageEvent(PAGE_NAME, 'requiredPermissions');
        redirect(BLOCKED_REDIRECT_URL.replace(':symbol', symbol));
    }

    function onAuthClose() {
        redirectBlockedUser(symbol);
    }

    function onNationalityLeiSuccess() {
        investorService.purgeCache('investor$');
        nationalityLeiDialogService.close();
    }

    function showNationalityLeiDialog(user) {
        return nationalityService.getNationalityList().then(function (nationalityList) {
            return nationalityLeiDialogService.show(user, nationalityList, onNationalityLeiSuccess, onAuthClose);
        });
    }

    function checkPermissions() {
        return investorService.getGeneralInvestor().then(function (user) {
            if (!user.permissions.canInvestSecondary && user.flags.identifiersRequired && user.kyc.status) {
                return showNationalityLeiDialog(user);
            }
        });
    }

    function initialize() {

        var promises = [
            propertyService.getPropertyData(symbol),
            investorService.getGeneralInvestor(),
            propertyService.getSecondaryLowestBid(symbol),
            propertyService.getSecondaryHighestSellOrder(symbol),
            configService.getRates()
        ];

        // will disable the UI if the user does not have permissions to buy
        return $q.all(promises).then(function (values) {
            vm.property = values[0];
            vm.user = values[1];
            var rates = values[4];

            vm.feeRate = rates.secondaryMarketFeeRate;
            vm.taxRate = rates.secondaryMarketTaxRate;

            if (!vm.user.anon) {
                // round to nearest int because javascript float maths is not exact
                vm.lowestBidPrice = values[2] && Math.round(100 * values[2].askPrice);
                vm.highestSellPrice = values[3] && Math.round(100 * values[3].askPrice);
            } else {
                ppPubSub.publish('auth.modal.show', ['login', null, onAuthClose]);
            }
        });

    }

    function setStep(step) {
        ppTrack.action('bid.set-step.' + step);
        vm.step = step;
    }

    // -- api

    vm.changeOrderType = function (orderType) {
        vm.onChangeOrderType({
            orderType: orderType
        });
    };

    vm.onSubmit = function (numberOfShares, sharePrice, budget, totalCost, recaptchaResponse) {
        ppTrack.setContext({
            'bid.shares': numberOfShares,
            'bid.price': sharePrice,
            'bid.budget': budget
        });

        vm.totalCost = totalCost;
        vm.sharePrice = sharePrice;

        var price = (sharePrice / 100).toFixed(2);
        return investorService.createBidOrder(symbol, numberOfShares, price, recaptchaResponse).then(function () {

            ppTrack.formSubmit(PAGE_NAME);

            investorService.purgeCache('investor$');
            propertyService.purgeCache(symbol + '.secondary.offers');
            propertyService.purgeCache(symbol + '.trading.history');

            initialize();

            propertyService.getSecondaryTradingHistory(symbol);

            var receipt = {
                receiptType: PAGE_NAME,
                receiptPrice: price * 100,
                receiptTotal: totalCost
            };

            if (vm.isSidebar) {
                vm.onBidPlaced({
                    receipt: receipt
                });
            } else {
                receiptDialogService.show(receipt);
            }

        }, function (error) {

            if (error.reason === PROPERTY_NOT_TRADABLE_ERROR) {
                suspendedPropertyDialogService.show(true)
                    .then(function () {
                        $window.location.reload();
                    });
            }

            ppTrack.formError(PAGE_NAME, error.reason);
            return $q.reject(error);
        });
    };

    vm.startKyc = function () {
        setStep(STEP_KYC);
    };

    vm.onKycSuccess = function () {
        investorService.purgeCache('investor$');

        initialize().then(function () {
            setStep(STEP_BID);
        });
    };

    vm.onKycFail = function () {
        setStep(STEP_BID);
    };

    vm.onClassificationSuccess = function () {
        investorService.purgeCache('investor$');
        initialize();
    };

    // -- scope bindings

    // -- main

    vm.$onInit = function () {
        property = vm.propertyData || {};
        symbol = property.symbol || ppUtil.getDataFromUrl($window.location.href, 'properties');

        checkPermissions()
            .then(initialize)
            .then(function () {
                ppTrack.pageLoad(PAGE_NAME);
                vm.ready = true;
            });
    };

}]);

ngModule.component('ppBid', {
    templateUrl: 'widgets/_angular/bid/bid.tpl.html',
    bindings: {
        hideSidebar: '<',
        hideGraphs: '<',
        alwaysToggleGraphs: '<',
        propertyData: '<property',
        onBidPlaced: '&',
        isSidebar: '<',
        orderType: '<',
        onChangeOrderType: '&'
    },
    controllerAs: 'vm',
    controller: 'ppBidController'
});
})(window, window.angular);