(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.list-menu-switcher', ['pp.widgets-templates']);

ngModule.component('ppListMenuSwitcher', {
    templateUrl: 'widgets/_angular/list-menu-switcher/list-menu-switcher.tpl.html',
    bindings: {
        activeTitle: '<',
        investor: '<',
        onOpenMenu: '&'

    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        vm.portfolioTypeLabels = {
            isa: 'ISA Portfolio',
            general: 'General Portfolio'
        };

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);