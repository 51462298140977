(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.focus-first-error', []);

var OFFSET_PADDING = 45;

function link($scope, $elem) {
    // NOTE: anti-pattern test specific code, but no other way to check if a transparent directive was instantiated correctly
    $scope.ppTestHelperDirectiveName = 'pp-focus-first-error';

    var headerHeight = $('.site-header').height();

    // set up event handler on the form elements
    $elem.on('submit', function () {
        // all the invalid elements
        var invalids = $elem[0].querySelectorAll('input.ng-invalid, select.ng-invalid');
        // Set focus for first visible invalid item
        var i = 0;
        for (i = 0; i < invalids.length; i++) {
            if ($(invalids[i]).is(':visible')) {
                invalids[i].focus();
                // PP-830 fixes iphone not scrolling to email field
                $('html, body').scrollTop($(invalids[i]).offset().top - (headerHeight + OFFSET_PADDING));
                break;
            }
        }
    });
}

ngModule.directive('ppFocusFirstError', function () {
    return {
        restrict: 'A',
        link: link
    };
});

ngModule.directive('focusFirstError', function () {
    return {
        restrict: 'A',
        link: link
    };
});
})(window, window.angular);