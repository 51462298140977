(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.business-summary-hard-work', [
    'pp.widgets-templates',
    'pp.widgets.auth-signup-cta',
    'pp.services.route'
]);

var WORK_TITLE = 'We do all the hard work';

ngModule.component('ppBusinessSummaryHardWork', {
    templateUrl: 'widgets/_angular/business-summary-hard-work/business-summary-hard-work.tpl.html',
    bindings: {
        user: '<',
        s3Endpoint: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', function ($scope, routeService) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$onInit = function () {
            vm.workTitle = WORK_TITLE;
            vm.marketplacePath = routeService.marketplacePath;
            vm.feesChargesHelp = routeService.feesChargesHelp;
        };

        // -- main

    }]
});
})(window, window.angular);