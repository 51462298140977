(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.card-payment', [
    'pp.services.investor',
    'pp.services.property',
    'pp.services.core'
]);

var API_BASE = '/feapi/r1';

// For primary properties
var API_FUND_AND_RESERVE_ENDPOINT = API_BASE + '/properties/:symbol/reserve';

// For secondary market properties
var API_FUND_AND_INVEST_ENDPOINT = API_BASE + '/properties/:symbol/invest';

var DEFAULT_CURRENCY_CODE = 'GBP';

ngModule.service('cardPaymentService', ['$http', '$q', 'ppUtil', 'ppMoment', 'propertyService', function ($http, $q, ppUtil, ppMoment, propertyService) {

    var api = {};

    var promiseCache = {};

    api.constructPaymentEndpoint = function (isPrimary, propertySymbol) {
        if (isPrimary) {
            return API_FUND_AND_RESERVE_ENDPOINT.replace(':symbol', propertySymbol);
        } else {
            return API_FUND_AND_INVEST_ENDPOINT.replace(':symbol', propertySymbol);
        }
    };

    api.prepareInvestTransaction = function (params, property, quote, recaptchaResponse) {
        if (property && quote) {
            quote = quote || {};

            var investConf = {
                symbol: property.symbol,
                shares: quote.shares
            };

            if (!propertyService.isPrimaryProperty(property)) {
                investConf.quoteReference = quote.reference;
                investConf.recaptchaResponse = recaptchaResponse;
            }

            ppUtil.extend(params, investConf);
        }
    };

    api.constructPaymentPayload = function (params) {

        var payload = {
            currencyCode: DEFAULT_CURRENCY_CODE,
            adyenEncryptedData: null,
            amount: 0,
            postcode: null,
            country: null
        };

        ppUtil.extend(payload, params);

        return payload;
    };

    api.makePayment = function (endpoint, payload) {
        return $http.post(endpoint, payload).then(function (response) {

            // if resolves with a 200 but there was an error on the backend
            // backend currently always responds with 200 for invest and reserve for legacy iframe 3ds issues
            // issue to remove this switch #PP-3819
            if (response.data && response.data.code && response.data.code !== '3ds.redirect') {
                return $q.reject(response.data);
            } else {
                return response.data;
            }

        }, function (error) {
            var data = error.data || {};
            if (data.code) {
                return $q.reject(data);
            } else {
                return $q.reject({
                    code: 'payment.error.unexpected'
                });
            }
        });
    };

    api.reserveSharesWithoutFunds = function (symbol, shares, isAnniversaryBlockListing, balanceOverride, parentSymbol) {
        var endpoint = api.constructPaymentEndpoint(true, symbol);
        var payload = {
            shares: shares,
            amount: 0,
            symbol: symbol,
            isAnniversaryBlockListing: isAnniversaryBlockListing,
            mustFund: false,
            postcode: null,
            country: null,
            currencyCode: 'GBP',
            acceptedTermsAndConditions: true,
            adyenEncryptedData: null,
            balanceOverride: balanceOverride,
            parentSymbol: parentSymbol
        };
        return api.makePayment(endpoint, payload);
    };

    api.purgeCache = function (pattern) {
        if (pattern) {
            pattern = new RegExp(pattern);
        }
        for (var prop in promiseCache) {
            if (!pattern || pattern.test(prop)) {
                delete promiseCache[prop];
            }
        }
    };

    return api;
}]);
})(window, window.angular);