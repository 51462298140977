(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.core', []);

/**
 * @ngdoc value
 * @name serverTime
 * @description
 */
ngModule.value('ppServerTime', window.pp.serverTime);

/**
 * @ngdoc value
 * @name serverTimeIso
 * @description
 */
ngModule.value('ppServerTimeIso', window.pp.serverTimeIso);

/**
 * @ngdoc service
 * @name ppConfig
 * @description
 */
ngModule.service('ppConfig', [function () {
    return window.pp.config;
}]);

/**
 * @ngdoc service
 * @name ppBrowser
 * @description
 */
ngModule.service('ppBrowser', [function () {
    return window.pp.browser;
}]);

/**
 * @ngdoc service
 * @name ppTrack
 * @description
 */
ngModule.service('ppTrack', [function () {
    return window.pp.track;
}]);

/**
 * @ngdoc service
 * @name ppUtil
 * @description
 */
ngModule.service('ppUtil', [function () {
    return window.pp.util;
}]);

/**
 * @ngdoc service
 * @name ppCookie
 * @description
 */
ngModule.service('ppCookie', [function () {
    return window.pp.cookie;
}]);

/**
 * @ngdoc service
 * @name ppPubSub
 * @description
 */
ngModule.service('ppPubSub', [function () {
    return window.pp.pubsub;
}]);

/**
 * @ngdoc service
 * @name ppEmitter
 * @description
 */
ngModule.service('ppEmitter', [function () {
    return window.pp.emitter;
}]);

/**
 * @ngdoc service
 * @name ppComponent
 * @description
 */
ngModule.service('ppComponent', [function () {
    return window.pp.component || angular.noop;
}]);

/**
 * @ngdoc service
 * @name ppComponentEmitter
 * @description
 */
ngModule.service('ppComponentEmitter', [function () {
    return window.pp.componentEmitter || angular.noop;
}]);

/**
 * @ngdoc service
 * @name ppMoment
 * @description
 */
ngModule.factory('ppMoment', [function () {
    return window.moment;
}]);

/**
 * @ngdoc service
 * @name ppHammer
 * @description
 */
ngModule.factory('ppHammer', [function () {
    return window.Hammer;
}]);

/**
 * @ngdoc service
 * @name ppBig
 * @description
 */
ngModule.service('ppBig', [function () {
    return window.Big;
}]);

/**
 * @ngdoc service
 * @name ramda
 * @description
 */
ngModule.service('R', [function () {
    return window.R;
}]);

/**
 * @ngdoc service
 * @name highcharts
 * @description
 */
ngModule.service('ppHighcharts', [function () {
    return window.Highcharts;
}]);
})(window, window.angular);