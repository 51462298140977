(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.referral-banner', [
    'pp.widgets-templates',
    'ngSanitize',
    'pp.services.investor',
    'pp.services.core',
    'pp.services.route',
    'pp.services.browser-store',
    'pp.services.referral'
]);

var CLOSED_BANNER_STORAGE_KEY = 'referral-banner-closed-date';

var CLOSED_STATES = {
    'signed-up.not-referred.not-recent-signup.slow-investment': true,
    'signed-up.referred.not-recent-signup.slow-investment': true
};

var RECENT_REFERRAL_STATES = {
    'signed-up.referred.recent-signup': true,
    'initial.referred': true
};

ngModule.component('ppReferralBanner', {
    templateUrl: 'widgets/_angular/referral-banner/referral-banner.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$q', '$sce', 'investorService', 'ppMoment', 'routeService', 'ppConfig', 'browserStoreService', 'referralService', 'ppComponentEmitter', function ($scope, $q, $sce, investorService, ppMoment, routeService, ppConfig, browserStoreService, referralService, ppComponentEmitter) {
        var vm = this;

        // -- initial state

        var _config = ppConfig.get('pp.external.endpoints') || {};

        var _assetsEndpoint = _config.assets;

        vm.referPath = routeService.referPath;
        vm.referTermsUrl = routeService.referralTermsLink;
        vm.oldWelcomeBonusTerms = routeService.oldWelcomeBonusTerms;
        vm.welcomeBonusTerms = routeService.welcomeBonusTerms;

        vm.eventsPath = routeService.blogEventsPath;

        var maleHeadUrl = _assetsEndpoint + '/images/icons/referrals/refer-head-m.png';
        var femaleHeadUrl = _assetsEndpoint + '/images/icons/referrals/refer-head-f.png';

        var oldTermsDate = ppMoment('2019-02-22');

        vm.headUrl = Math.random() > 0.5 ? maleHeadUrl : femaleHeadUrl;

        // -- util functions

        function isInitial(investor) {
            return investor.stage === 'initial';
        }

        function isAuthed(investor) {
            return !investor.anon;
        }

        function isInvested(investor) {
            return investor.stage === 'invested';
        }

        function signupDate(investor) {
            var funnel = investor.funnel || {};
            return ppMoment(funnel.investorCreatedOn);
        }

        function firstInvestmentDate(investor) {
            if (!investor.anon && investor.funnel.statistics.first_investment_timestamp) {
                return ppMoment(investor.funnel.statistics.first_investment_timestamp);
            }
        }

        function daysBetweenSignupAndInvestment(investor) {
            var investmentDate = firstInvestmentDate(investor);

            if (!investmentDate) {
                return Infinity;
            } else {
                return investmentDate.diff(signupDate(investor), 'days');
            }
        }

        function signedUpLast30Days(investor) {
            var thirtyDaysAgo = ppMoment().subtract(30, 'days');
            return signupDate(investor).isSameOrAfter(thirtyDaysAgo);
        }

        function signedUpLast60Days(investor) {
            var sixtyDaysAgo = ppMoment().subtract(60, 'days');
            return signupDate(investor).isSameOrAfter(sixtyDaysAgo);
        }

        function isReferred(investor) {
            return !!investor.referredBy;
        }

        function closeBanner() {
            vm.closed = true;
            $('body').removeClass('pp-referral-banner-open');
        }

        function openBanner() {
            vm.closed = false;
            $('body').addClass('pp-referral-banner-open');
        }

        function isClosed() {
            var date = browserStoreService.getLocalStorageItem(CLOSED_BANNER_STORAGE_KEY);

            if (!date) {
                return false;
            }

            return ppMoment().subtract(14, 'days').isBefore(date);
        }

        function setReferreeFirstName(investor) {
            return referralService.getFirstNameByGuid(investor.referredBy).then(function (name) {
                vm.referreeFirstName = name;
            });
        }

        function getReferralBannerKey(investor, referredByIntroducer) {

            if (vm.overrideMessage) {
                return 'manual-message';
            }

            if (referredByIntroducer || investor.isIsa) {
                return;
            }

            if (isInitial(investor) && isReferred(investor)) {
                return 'initial.referred';
            } else if (isInitial(investor) && !isReferred(investor)) {
                return 'initial.not-referred';
            } else if (isAuthed(investor) && isReferred(investor) && signedUpLast60Days(investor)) {
                return 'signed-up.referred.recent-signup';
            } else if (isAuthed(investor) && isReferred(investor) && !signedUpLast60Days(investor)) {
                if (daysBetweenSignupAndInvestment(investor) <= 60) {
                    return 'signed-up.referred.not-recent-signup.quick-investment';
                } else {
                    return 'signed-up.referred.not-recent-signup.slow-investment';
                }
            } else if (isAuthed(investor) && !isReferred(investor) && signedUpLast30Days(investor)) {
                return 'signed-up.not-referred.recent-signup';
            } else if (isAuthed(investor) && !isReferred(investor) && !signedUpLast30Days(investor)) {

                if (daysBetweenSignupAndInvestment(investor) <= 30) {
                    return 'signed-up.not-referred.not-recent-signup.quick-investment';
                } else {
                    return 'signed-up.not-referred.not-recent-signup.slow-investment';
                }
            }
        }

        function init(investor, referredByIntroducer) {
            if (isClosed()) {
                closeBanner();
            } else {
                openBanner();
            }

            vm.bannerKey = getReferralBannerKey(investor, referredByIntroducer);

            if (RECENT_REFERRAL_STATES[vm.bannerKey]) {
                setReferreeFirstName(investor);
            }

            if (!vm.bannerKey || CLOSED_STATES[vm.bannerKey]) {
                closeBanner();
            }
        }

        function overrideBanner(message) {
            vm.overrideMessage = $sce.trustAsHtml(message);
            init();
        }

        // -- api

        // on api for test access

        vm.init = init;

        vm.getReferralBannerKey = getReferralBannerKey;

        vm.closeBanner = function () {
            var date = ppMoment().format('YYYY-MM-DD');
            browserStoreService.setLocalStorageItem(CLOSED_BANNER_STORAGE_KEY, date);
            closeBanner();
        };

        // -- scope bindings
        investorService.on('investor-updated', function (investor) {
            vm.isOldTerms = signupDate(investor).isBefore(oldTermsDate);
            referralService.isInvestorReferredByIntroducer(investor).then(function (referredByIntroducer) {
                init(investor, referredByIntroducer);
                vm.ready = true;
            });
        });

        var publicApi = {
            overrideBanner: overrideBanner
        };

        ppComponentEmitter.emit('pp.referral-banner.api', publicApi);

        // -- main

    }]
});
})(window, window.angular);