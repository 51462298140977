(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-status', ['pp.widgets-templates']);

/**
 * @ngdoc directive
 * @name ppPropertyStatus
 * @description
 * Renders a banner with the property status.
 *
 * @restrict A
 * @scope
 * @param {Object} ppPropertyStatus the property model
 */
ngModule.directive('ppPropertyStatus', function () {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-status/property-status.tpl.html',
        scope: {
            property: '<ppPropertyStatus',
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
});
})(window, window.angular);