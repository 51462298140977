(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.live-recent-trades', ['pp.widgets-templates', 'pp.services.core', 'pp.filters.numbers', 'pp.widgets.live-item']);

//@TODO --someday move into a reusable directive

ngModule.directive('ppLiveRecentTrades', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/live-recent-trades/live-recent-trades.tpl.html',
        scope: {
            trades: '<ppLiveRecentTrades',
            initialItem: '<',
            slideRight: '&',
            slideLeft: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$filter', '$window', 'ppTrack', function ($scope, $timeout, $filter, $window, ppTrack) {
            var vm = this;

            // -- initial state
            var currentItemKey = 'firstItem';

            vm.dummyTrade = {
                timestamp: 10000,
                details: [{
                    label: 'Amount Traded',
                    value: '100'
                }, {
                    label: 'Traded Price',
                    value: '100'
                }, {
                    label: '% Change',
                    value: '0.5'
                }]
            };

            // -- util functions

            function slideItemIn(newItem) {
                if (currentItemKey === 'firstItem') {
                    vm.secondItem = angular.copy(newItem);
                    vm.previousItem = vm.firstItem.id;
                    currentItemKey = 'secondItem';
                } else {
                    vm.firstItem = newItem;
                    vm.previousItem = vm.secondItem.id;
                    currentItemKey = 'firstItem';
                }

                vm.currentItem = newItem.id;
            }

            function slideLeft(newItem) {
                vm.slideType = 'slide-left';
                slideItemIn(newItem);
            }

            function slideRight(newItem) {
                vm.slideType = 'slide-right';
                slideItemIn(newItem);

            }

            // -- api

            vm.linkClicked = function (symbol) {
                ppTrack.deferAction('live-ticker.recent-trades.click', {
                    symbol: symbol
                });
            };

            // -- main

            vm.$onInit = function () {
                $scope.$on('pp.live-marketplace.recent-trades.slide-right', function (ev, newItem) {
                    slideRight(newItem);

                });

                $scope.$on('pp.live-marketplace.recent-trades.slide-left', function (ev, newItem) {
                    slideLeft(newItem);
                });

                vm.slideType = 'initial';
                vm.firstItem = angular.copy(vm.initialItem);
                vm.currentItem = vm.firstItem.id;
            };

        }]
    };
}]);
})(window, window.angular);