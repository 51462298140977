(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investor-contact-details-form', [
    'pp.widgets-templates',
    'pp.components.focus-first-error',
    'pp.services.core'
]);

ngModule.directive('ppInvestorContactDetailsForm', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/investor-contact-details-form/investor-contact-details-form.tpl.html',
        scope: {
            personal: '=ppInvestorContactDetailsForm',
            isDisabled: '<',
            feedbackEnabled: '<',
            processing: '<',
            updateClassification: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$window', 'ppTrack', function ($scope, $timeout, $window, ppTrack) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);