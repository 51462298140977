(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.primary-order-list', [
    'pp.widgets-templates',
    'pp.widgets.property-cell',
    'pp.filters.numbers',
    'pp.components.checkbox-indeterminate',
    'pp.services.core',
    'pp.services.route',
    'pp.services.property',
    'pp.components.checkbox'
]);

ngModule.component('ppPrimaryOrderList', {
    templateUrl: 'widgets/_angular/primary-order-list/primary-order-list.tpl.html',
    bindings: {
        orders: '<',
        properties: '<',
        onSubmitOrders: '&',
        investor: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$q', 'R', 'routeService', 'propertyService', 'ppBig', function ($scope, $q, R, routeService, propertyService, ppBig) {
        var vm = this;

        // -- initial state

        vm.selected = {};
        vm.quotes = {};

        // BigJs code for rounding Up
        var roundUp = 3;

        vm.investorTermsAndConditions = routeService.investorTermsAndConditions;
        vm.rightsIssueKnowledgebaseLink = routeService.rightsIssueKnowledgebaseLink;
        vm.fundPath = routeService.fundPath;

        // -- util functions

        var isSelected = R.curry(function (selected, item) {
            var symbol = R.prop('symbol', item) || R.prop('propertySymbol', item);
            return !!selected[symbol];
        });

        var filterSelected = R.compose(R.filter, isSelected);

        function areAllSelected(selected, orders) {
            return filterSelected(selected)(orders).length === orders.length;
        }

        function areSomeSelected(selected, orders) {
            var numberSelected = filterSelected(selected)(orders).length;
            return numberSelected > 0 && numberSelected < orders.length;
        }

        function selectAllItems() {
            vm.selected = R.fromPairs(R.map(function (item) {
                return [item.symbol, true];
            }, R.clone(vm.orders)));
        }

        function refreshQuote(propertySymbol, amount) {
            return propertyService.getPrimaryQuote({
                symbol: propertySymbol
            }, amount, 'GBP').then(function (res) {
                vm.quotes[propertySymbol] = res;
                return res;
            });
        }

        function refreshAllQuotes(orders) {
            return $q.all(orders.map(function (item) {
                return refreshQuote(item.symbol, item.total);
            }));
        }

        function updateTotalReservation(selected) {
            vm.totalReservation = R.sum(filterSelected(selected)(vm.orders).map(function (item) {
                return R.defaultTo(0, R.path([item.symbol, 'investmentCost'], vm.quotes));
            }));
        }

        // -- api

        vm.countSelected = function (selected) {
            return R.filter(function (item) {
                    return !!item;
                },
                R.values(vm.selected)).length;
        };

        vm.toggleSelect = function (symbol) {
            if (vm.processing) {
                // @note early return
                return;
            }

            vm.selected[symbol] = !vm.selected[symbol];
        };

        vm.userUpdateQuote = function (symbol) {
            vm.quotes[symbol] = vm.quotes[symbol] || {};
            vm.quotes[symbol].updating = true;
            refreshQuote(symbol, vm.quotes[symbol].budget).then(function () {
                vm.quotes[symbol].updating = false;
                updateTotalReservation(vm.selected);
            }, function () {
                vm.quotes[symbol].updating = false;
            });
        };

        vm.submitOrders = function () {

            vm.feedbackEnabled = true;

            if (vm.totalReservation > vm.investor.financials.funds) {
                vm.additionalFundsRequired = Number(ppBig(vm.totalReservation).minus(vm.investor.financials.funds).round(2, roundUp));
                return;
            }

            if (!vm.agreeToInvestmentDocs) {
                return;
            }

            var ordersToSubmit = filterSelected(vm.selected)(R.values(vm.quotes));

            if (ordersToSubmit.length === 0) {
                vm.noneSelected = true;
                return;
            } else {
                vm.noneSelected = false;
            }

            vm.processing = true;
            vm.error = false;

            vm.onSubmitOrders({
                orders: ordersToSubmit,
                agreeToInvestmentDocs: vm.agreeToInvestmentDocs
            }).then(function () {
                vm.processing = false;
            }, function () {
                vm.processing = false;
                vm.error = true;
            });
        };

        // -- scope bindings

        $scope.$watchCollection('vm.selected', function (selected) {
            updateTotalReservation(selected);

            vm.someSelected = areSomeSelected(selected, vm.orders);
            vm.allSelected = areAllSelected(selected, vm.orders);

            if (vm.someSelected || vm.allSelected) {
                vm.noneSelected = false;
            }

        });

        $scope.$watch('vm.allSelected', function (allSelected, previousAllSelected) {
            var someSelected = areSomeSelected(vm.selected, vm.orders);
            var allItemsSelected = areAllSelected(vm.selected, vm.orders);
            if (allItemsSelected && allSelected) {
                // Do nothing
                return;
            } else if (allSelected) {
                selectAllItems();
            } else if (!allSelected && !someSelected && previousAllSelected) {
                vm.selected = {};
            }
        });

        // -- main

        vm.$onInit = function () {
            refreshAllQuotes(vm.orders);
            vm.totalAllocation = R.sum(vm.orders.map(function (item) {
                return item.total;
            }));
        };

    }]
});
})(window, window.angular);