(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-card-pending', [
    'pp.widgets-templates',
    'pp.services.dashboard',
    'pp.services.core',
    'pp.services.route',
    'pp.filters.numbers',
    'pp.components.time-left',
    'pp.widgets.property-card-header',
    'pp.widgets.dashboard-card-marketplace-footer'

]);

ngModule.component('ppDashboardCardPending', {
    templateUrl: 'widgets/_angular/dashboard-card-pending/dashboard-card-pending.tpl.html',
    bindings: {
        holding: '<',
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', 'ppTrack', 'ppConfig', 'dashboardService', 'ppComponentEmitter', 'routeService', function ($scope, $element, ppTrack, ppConfig, dashboardService, ppComponentEmitter, routeService) {
        var vm = this;

        // -- initial state

        var __endpoints = ppConfig.get('pp.external.endpoints') || {};

        var assetsEndpoint = __endpoints.assets;

        // -- util functions

        function createPropertyImages(property) {
            return {
                desktop: assetsEndpoint + '/images/properties/' + property.symbol + '/thumb/img1.jpg',
                mobile: assetsEndpoint + '/images/properties/' + property.symbol + '/thumb/img2.jpg'
            };
        }

        // -- api

        vm.goToQuote = function () {

            ppTrack.deferAction('dashboard-pending-card.buy-more.click', {
                symbol: vm.holding.symbol
            });
        };

        // -- lifecycle

        vm.$postLink = function () {
            if (vm.holding) {
                ppComponentEmitter.emit('dashboard-card-pending.dom.loaded.' + vm.holding.symbol, $element);
            }
        };

        vm.$onInit = function () {
            if (vm.holding) {
                vm.ready = true;
            }

            if (vm.holding && vm.holding.symbol) {
                ppComponentEmitter.emit('dashboard-card-pending.controller.loaded.' + vm.holding.symbol, vm);
            }
        };

        vm.$onChanges = function (changes) {
            if (changes.property && changes.property.currentValue) {
                vm.images = createPropertyImages(changes.property.currentValue);
                vm.quotePath = routeService.individualPropertyQuotePath.replace(':symbol', changes.property.currentValue.symbol);
                vm.propertyPath = routeService.individualPropertyPath.replace(':symbol', changes.property.currentValue.symbol);
            }

            if (changes.holding && changes.holding.currentValue) {
                vm.closingDate = changes.holding.currentValue.closingDate ? new Date(changes.holding.currentValue.closingDate) : null;
            }
        };

        // -- main

    }]
});
})(window, window.angular);