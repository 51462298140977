(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.nav-card', ['pp.widgets-templates']);

ngModule.component('ppNavCard', {
    templateUrl: 'widgets/_angular/nav-card/nav-card.tpl.html',
    bindings: {
        link: '<',
        iconUrl: '<',
        title: '@',
        description: '<'

    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);