(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.lei-form', ['pp.widgets-templates', 'pp.services.core', 'pp.services.investor-compliance']);

var FORM_NAME = 'submit-lei';

/**
 * @ngdoc directive
 * @name ppLeiForm
 * @description
 *
 *
 * @restrict A
 * @scope
 * @param {string} error returns errors to form
 * @param {function} processing sets form to processing
 * @param {function} onSuccess invoked if LEI form submit is successful
 */

ngModule.directive('ppLeiForm', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/lei-form/lei-form.tpl.html',
        scope: {
            error: '<',
            processing: '<',
            onSuccess: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppTrack', 'investorComplianceService', function ($scope, ppTrack, investorComplianceService) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            vm.onSubmit = function () {
                vm.processing = true;
                investorComplianceService.postLei(vm.lei).then(function () {
                    vm.processing = false;
                    ppTrack.deferAction('submit-lei.submitted');
                    vm.onSuccess();
                }, function (error) {
                    ppTrack.formError(FORM_NAME, error.reason);
                    vm.processing = false;
                    vm.error = error.reason;
                });
            };

            // -- scope bindings

            // -- main

            ppTrack.formStart(FORM_NAME);
        }]
    };
}]);
})(window, window.angular);