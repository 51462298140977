(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-simple-link', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.route',
    'pp.widgets.property-card-simple'
]);

ngModule.component('ppPropertyCardSimpleLink', {
    templateUrl: 'widgets/_angular/property-card-simple-link/property-card-simple-link.tpl.html',
    bindings: {
        disableLink: '<',
        property: '<',
        user: '<',
        holdings: '<',
        bids: '<',
        offers: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', 'R', function ($scope, routeService, R) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings
        vm.$onChanges = function (changes) {
            var propertySymbol = R.path(['property', 'currentValue', 'symbol'], changes);
            if (R.path(['disableLink', 'currentValue'], changes)) {
                vm.path = undefined;
            } else if (propertySymbol) {
                vm.path = routeService.getIndividualPropertyPath(propertySymbol);
            }
        };

        // -- main

    }]
});
})(window, window.angular);