(function(window, angular){'use strict';

var ngModule = angular.module('pp.apps.about', [
    'ui.router',
    'pp.services.run',
    'pp.ui.services.cookie-banner-dialog',
    'pp.ui.services.required-user-data-dialog',
    'pp.widgets.auth-modal',
    'pp.widgets.auth-nav-mobile',
    'pp.widgets.header',
    'pp.widgets.footer',
    'pp.widgets.about',
    'pp.widgets.about-overview',
    'pp.widgets.our-story',
    'pp.widgets.team',
    'pp.widgets.referral-banner',
    'pp.widgets.contact-us-view',
    'pp.ui.services.risk-warning'
]);

ngModule.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$compileProvider', function ($stateProvider, $urlRouterProvider, $locationProvider, $compileProvider) {

    var base = '/aboutus';

    $stateProvider
        .state('about', {
            abstract: true,
            component: 'ppAbout'
        })
        .state('about.overview', {
            url: base,
            component: 'ppAboutOverview'
        })
        .state('about.ourstory', {
            url: base + '/ourstory',
            redirectTo: 'about.overview'
        })
        .state('about.team', {
            url: base + '/theteam',
            redirectTo: 'about.overview'
        })
        .state('about.contactus', {
            url: base + '/contactus',
            component: 'ppContactUsView',
            params: {
                title: {
                    type: 'string',
                    value: 'Contact us'
                }
            }
        });

    $locationProvider.html5Mode(true);
    $compileProvider.debugInfoEnabled(false);

}]);

ngModule.run(['runService', 'requiredUserDataDialogService', 'cookieBannerDialogService', 'riskWarningService', function (runService, requiredUserDataDialogService, cookieBannerDialogService, riskWarningService) {
    runService.init(requiredUserDataDialogService, cookieBannerDialogService);
    riskWarningService.showMainRiskWarning();
}]);
})(window, window.angular);