(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.kyc-and-classification', [
    'pp.widgets-templates',
    'pp.services.nationality',
    'pp.services.preference',
    'pp.services.log',
    'pp.services.core',
    'pp.widgets.classification',
    'pp.widgets.kyc-form',
    'pp.widgets.kyc-document-verification',
    'pp.widgets.kyc-more-info'
]);

var OUTCOME_ERROR = 'Error';
var OUTCOME_DENIED = 'Denied';
var OUTCOME_SECONDARY_INFORMATION = 'SecondaryDataRequired';

var CLASSIFICATION_STEP = 'classification';
var KYC_PRIMARY_STEP = 'kyc';
var KYC_SECONDARY_STEP = 'kyc-secondary';
var KYC_MORE_INFO_STEP = 'kyc-more-info';
var NATIONALITY_STEP = 'nationality';

ngModule.component('ppKycAndClassification', {
    templateUrl: 'widgets/_angular/kyc-and-classification/kyc-and-classification.tpl.html',
    bindings: {
        user: '<',
        onFail: '&',
        onSuccess: '&',
        noMoreInformationRequired: '&?',
        onClassificationSuccess: '&?',
        onLoad: '&?',
        skipClassification: '<',
        isPage: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$timeout', '$q', '$window', 'nationalityService', 'preferenceService', 'investorService', 'logService', function ($scope, $timeout, $q, $window, nationalityService, preferenceService, investorService, logService) {

        var vm = this;

        // -- initial state

        // -- util functions

        function setStep(step) {

            if (step === KYC_MORE_INFO_STEP && angular.isFunction(vm.noMoreInformationRequired)) {
                vm.noMoreInformationRequired();
                return $q.when();
            }

            if (step !== vm.step) {
                $($window).scrollTop(0, 0);
            }
            vm.step = step;
            vm.ready = true;

            if (angular.isFunction(vm.onLoad)) {
                vm.onLoad();
            }

            return $q.when();
        }

        function loadUser() {
            investorService.purgeCache('investor$');
            return investorService.getInvestor();
        }

        // invoked on initial user load and after user is reloaded post kyc
        function initStep(user, nationalityData) {
            var permissions = user.permissions;
            vm.ready = false;
            vm.step = undefined;
            // user is yet to be classified
            if (!vm.skipClassification && (!user.classification || permissions.complianceRequired)) {
                return setStep(CLASSIFICATION_STEP);
            }
            // user is yet to be kyc'ed
            else if (investorService.hasNotDoneKyc(user)) {
                return setStep(KYC_PRIMARY_STEP);
            } else if (!nationalityService.isNationalityDataSet(nationalityData) && !investorService.isBusiness(user)) {
                return nationalityService.presetNationalitiesWithKycNationality(vm.nationalityData).then(function (nationalityData) {
                    vm.nationalityData = nationalityData;
                    return setStep(NATIONALITY_STEP);
                });
            } else if (investorService.kycMoreInformationRequired(user)) {
                return investorService.getUsetKycStatusDetails().then(function (kycStatusDetails) {
                    vm.kycStatusDetails = kycStatusDetails;

                    if (kycStatusDetails.isPassed) {
                        logService.error(user.userId + ' userId has passed kyc from GOJI but is not stored as passed in DB');
                        return setStep(KYC_MORE_INFO_STEP);
                    } else if (kycStatusDetails.isPendingApproval) {
                        return setStep(KYC_MORE_INFO_STEP);
                    } else if (kycStatusDetails.hasDocumentsRequired) {
                        return setStep(KYC_SECONDARY_STEP);
                    } else {
                        return setStep(KYC_MORE_INFO_STEP);
                    }
                }, function () {
                    // in case of not being able to get a response from GOJI
                    // Show catchall kyc more info screen
                    return setStep(KYC_MORE_INFO_STEP);
                });

            } else if (investorService.hasPassedKyc(user)) {
                vm.onSuccess();
                return $q.when();
            } else {
                return setStep(KYC_MORE_INFO_STEP);
            }
        }

        function loadUserAndInitStep() {
            return loadUser().then(function (user) {
                vm.user = user;
                initStep(user);
            });
        }

        // -- api

        vm.onClassificationDismiss = function () {
            vm.onFail();
        };

        vm.onClassificationFail = function () {
            vm.onFail();
        };

        vm.classificationSuccess = function () {
            loadUserAndInitStep().then(function () {
                if (angular.isFunction(vm.onClassificationSuccess)) {
                    vm.onClassificationSuccess();
                }
            });
        };

        vm.submitNationality = function () {
            if (vm.nationalityForm.$valid) {
                vm.processing = true;
                return nationalityService.replaceInvestorNationalitiesAndIdentifiers(vm.nationalityData)
                    .then(nationalityService.constructInvestorNationalitiesAndIdentifiersForForm)
                    .then(function (nationalityData) {
                        vm.nationalityData = nationalityData;
                        return loadUser();
                    })
                    .then(function (user) {
                        vm.user = user;
                        initStep(user, vm.nationalityData);
                        vm.processing = false;
                    })
                    .catch(function (error) {
                        vm.nationalityError = error;
                        vm.processing = false;
                    });
            }
        };

        vm.kycSecondaryComplete = loadUserAndInitStep;

        // -- scope bindings

        // Possible outcomes are
        // Passed, PassWithPepSanctionsFlag, Rejected, Referred, ReferOnError, SecondaryDataRequired, Denied, Error
        $scope.$on('pp.kyc-form.onComplete', loadUserAndInitStep);

        // -- main

        var promises = [
            nationalityService.constructInvestorNationalitiesAndIdentifiersForForm(),
            nationalityService.getNationalityList(),
            preferenceService.getPreDefinedKycType(),
            preferenceService.getCompanyName(),
            investorService.getInvestor()
        ];

        vm.$onInit = function () {
            $q.all(promises).then(function (responses) {

                vm.nationalityData = responses[0];
                vm.nationalityList = responses[1];
                vm.kycType = responses[2];
                vm.companyName = responses[3];

                vm.investor = responses[4];

                initStep(vm.user, vm.nationalityData).then(function () {
                    vm.ready = true;
                });
            });
        };

    }]
});
})(window, window.angular);