(function(window, angular){'use strict';

var ngModule = angular.module('pp.values.numbers', []);

var numbers = {
    feeRate: 0.02,
    taxRate: 0.005
};

ngModule.value('ppNumbers', numbers);

ngModule.value('isaMaxPerYear', 20000);
})(window, window.angular);