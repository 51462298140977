(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.password-reset-request-confirmation', ['pp.widgets-templates', 'pp.services.core', 'pp.ui.services.authenticate-investor']);

ngModule.component('ppPasswordResetRequestConfirmation', {
    templateUrl: 'widgets/_angular/password-reset-request-confirmation/password-reset-request-confirmation.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', 'authenticateInvestor', function ($scope, ppTrack, authenticateInvestor) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.onClickLogin = function () {
            ppTrack.action('reset-request.login.click');
            authenticateInvestor();
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);