(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.bid-form', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.property',
    'pp.services.number',
    'pp.services.route',
    'pp.services.investor',
    'pp.ui.services.terms-dialog',
    'pp.filters.numbers',
    'pp.components.input-plus-minus',
    'pp.components.input-amount',
    'pp.components.tooltip',
    'pp.components.checkbox',
    'pp.widgets.recaptcha',
    'pp.widgets.aum-dividend-notice',
    'pp.values.glossary',
    'pp.widgets.quote-breakdown',
    'pp.widgets.order-type-select',
    'pp.widgets.exit-mechanic-trade-warning'
]);

var PAGE_NAME = 'bid';

var MINIMUM_SHARES_BID = 1;
var MINIMUM_BUDGET_BID = 0.01;

var FOCUS_ON_LOAD = 'pp.bid.amount.setFocus';
var INVESTMENT_DOCS_TYPE = 'investment-articles';

var INPUT_REGEXP = /^\d*(\.\d{1,2})?$/;
var INPUT_REGEXP_WHOLE_NUMBERS = /^[0-9]+$/;

ngModule.component('ppBidForm', {
    templateUrl: 'widgets/_angular/bid-form/bid-form.tpl.html',
    bindings: {
        user: '<',
        property: '<',
        maxSharesPerInvestorPerProperty: '<',
        defaultBudget: '<',
        lowestBidPrice: '<',
        highestSellPrice: '<',
        reCaptchaKey: '<',
        reCaptchaEnabled: '<',
        alwaysToggleGraphs: '<',
        feeRate: '<',
        taxRate: '<',
        onSubmit: '&',
        hideGraphs: '<',
        orderType: '<',
        onChangeOrderType: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$timeout', 'ppTrack', 'propertyService', 'numberService', 'investorService', 'routeService', 'termsDialogService', 'glossary', 'ppBig', function ($scope, $timeout, ppTrack, propertyService, numberService, investorService, routeService, termsDialogService, glossary, ppBig) {
        var vm = this;

        // -- initial state

        vm.inputRegexp = INPUT_REGEXP;
        vm.inputRegexpWholeNumbers = INPUT_REGEXP_WHOLE_NUMBERS;

        vm.results = {};
        vm.shares = {};
        vm.budget = {};
        vm.pPrice = {};

        vm.showCharts = false;

        vm.recaptchaWidgetId = undefined;

        vm.glossary = glossary;

        vm.fundPath = routeService.fundPath;

        vm.investorTermsAndConditions = routeService.investorTermsAndConditions;

        var __trackedDetails;

        // -- util functions

        // @TODO replace usage with ppUtil.hasPathIn(obj, path)
        function propertyHasSecondaryMarket(property) {
            return property && property.market && property.market.secondary && angular.isObject(property.market.secondary);
        }

        function updateResults() {
            var latestShareValuation = vm.property.valuation.share * 100;
            var netIncomeYield = vm.property.income.net.pct;

            vm.shares.value = Math.floor(propertyService.calculateMaxBidCost(vm.budget.value, vm.taxRate, vm.feeRate) / (vm.pPrice.value / 100));

            vm.valuationDiscount = ((latestShareValuation - vm.pPrice.value) / latestShareValuation) * 100;

            vm.results.maximumInvestment = vm.shares.value * (vm.pPrice.value / 100);

            vm.results.fees = numberService.ceil2(vm.results.maximumInvestment * vm.feeRate);

            vm.results.taxes = numberService.round2(vm.results.maximumInvestment * vm.taxRate);

            vm.results.totalCost = vm.results.maximumInvestment + vm.results.fees + vm.results.taxes;

            vm.results.annualDividend = Math.floor(netIncomeYield * vm.shares.value * latestShareValuation) / 10000;
            vm.results.minYield = (latestShareValuation / (vm.pPrice.value)) * netIncomeYield;

            vm.showSystemErrorMessages = false;

            vm.maxAmount = vm.budget.value;

            var bidPrice = ppBig(vm.pPrice.value).div(100);

            vm.quote = {
                weightedAveragePrice: Number(ppBig(vm.pPrice.value).div(100)),
                shares: vm.shares.value,
                yield: vm.results.minYield,
                investmentCost: vm.results.maximumInvestment,
                transactionCost: Number(ppBig(vm.results.fees).plus(vm.results.taxes)),
                totalCost: Number(ppBig(vm.results.maximumInvestment).plus(vm.results.fees).plus(vm.results.taxes)),
                premium: Number(ppBig(ppBig(bidPrice).minus(vm.property.valuation.share)).div(vm.property.valuation.share))
            };

            if (vm.user && vm.user.financials.funds > 0) {
                // Defensive because occasionally vm.quote.totalCost is not to 2 decimal places PP-2665
                // - 3907.03 (investment costs) + 78.15 (transaction costs) ->
                // - 3985.1800000000003
                // which results in
                // - 3985.1800000000003 (total investment) - 3985.18 (investor funds) ->
                // - leftToPay = 0.0000000000003
                vm.quote.paymentAmount = Number(ppBig(vm.quote.totalCost).minus(vm.user.financials.funds));
                if (vm.quote.paymentAmount < 0) {
                    vm.quote.paymentAmount = 0;
                }
                vm.leftToPay = vm.quote.paymentAmount;
            } else {
                vm.quote.paymentAmount = vm.quote.totalCost;
                vm.leftToPay = 0;
            }

            ppTrack.setContext({
                'bid.shares': vm.shares.value,
                'bid.price': vm.pPrice.value,
                'bid.budget': vm.budget.value
            });
        }

        function setAllowedOfferLimits() {
            if (propertyHasSecondaryMarket(vm.property) && vm.property.market.secondary.banding) {
                vm.lowestAllowedOffer = Math.ceil(vm.property.market.secondary.banding.lowerBound * 100);
                vm.highestAllowedOffer = Math.floor(vm.property.market.secondary.banding.upperBound * 100);

                ppTrack.setContext({
                    'property.upper-bound': vm.highestAllowedOffer,
                    'property.lower-bound': vm.lowestAllowedOffer
                });
            }
        }

        function getNextBestOffer(property) {
            if (propertyHasSecondaryMarket(property) && property.market.secondary.bestOffer) {
                return Math.round(property.market.secondary.bestOffer.price * 100) - 1;
            }
        }

        function getStartingPrice(nextBestOffer, previousBid, property) {
            if (nextBestOffer) {
                return previousBid || nextBestOffer;
            } else if (property && angular.isObject(property.valuation)) {
                return Math.floor(property.valuation.share * 100);
            }
        }

        function setupData(user) {
            vm.nextBestOffer = getNextBestOffer(vm.property);
            vm.startingPrice = getStartingPrice(vm.nextBestOffer, vm.previousBid, vm.property);

            if (!user.permissions.canInvestSecondary) {
                vm.disabled = true;
                vm.loadingError = 'investor.not.permitted.to.bid';
            } else {
                vm.disabled = false;
            }

            if (!vm.disabled) {
                vm.shares = {
                    floor: MINIMUM_SHARES_BID,
                    ceil: vm.maxSharesPerInvestorPerProperty
                    /* @todo validate on submit that the user has enough funds
                    function () {
                        var max = Math.floor((balanceAfterFeesAndTaxes * 100) / vm.pPrice.value);
                        if (vm.shares.value > max) {
                            vm.shares.value = max;
                            vm.shares.sliderValue = max;
                        }
                        return Math.floor((balanceAfterFeesAndTaxes * 100) / vm.pPrice.value);
                    }
                    */
                };

                var storedQuote = propertyService.retrieveQuote(vm.property.symbol) || {};

                vm.budget = {
                    value: vm.previousBudget || storedQuote.budget || vm.defaultBudget,
                    floor: MINIMUM_BUDGET_BID
                };

                vm.disabled = !user.permissions.canInvestSecondary;

                setAllowedOfferLimits();

                vm.pPrice = {
                    value: vm.startingPrice,
                    floor: vm.lowestAllowedOffer,
                    ceil: vm.highestAllowedOffer
                };

                updateResults();

                vm.valid = true;
                vm.disabled = false;
            }
        }

        function resetRecaptcha() {

            $scope.$broadcast('recaptcha.reload');
        }

        function resetForm() {
            vm.agree = false;
            $scope.$broadcast('pp.widgets.shares-order-book-chart.refresh');
            setupData(vm.user);
            resetRecaptcha();
            vm.form.$setPristine();
            vm.feedbackEnabled = false;

        }

        function setPriceMessage(bidPrice) {

            setAllowedOfferLimits();

            if (!bidPrice) {
                vm.priceLimitMessage = null;
                vm.priceMessage = null;
                return;
            }

            if (bidPrice >= vm.highestAllowedOffer) {
                vm.priceLimitMessage = 'upper-bound';
                vm.priceMessage = null;
                return;
            } else if (bidPrice <= vm.lowestAllowedOffer) {
                vm.priceLimitMessage = 'lower-bound';
                vm.priceMessage = null;
                return;
            } else {
                vm.priceLimitMessage = null;
            }

            if (bidPrice === vm.nextBestOffer) {
                vm.priceMessage = 'match-one-below-lowest-sell';
            } else if (bidPrice === vm.lowestBidPrice) {
                vm.priceMessage = 'match-lowest-bid';
            } else if (bidPrice === vm.highestSellPrice) {
                vm.priceMessage = 'match-highest-sell';
            } else {
                vm.priceMessage = null;
            }

            updateResults();
        }

        // -- api

        vm.changeOrderType = function (orderType) {
            vm.onChangeOrderType({
                orderType: orderType
            });
        };

        vm.showTermsAndConditions = function () {
            if (!vm.processing) {
                var data = (vm.property && vm.property.spv) ? vm.property.spv : {};
                ppTrack.pageAction(PAGE_NAME, 'terms-and-conditions.show');
                termsDialogService.show(INVESTMENT_DOCS_TYPE, data);
            }
        };

        vm.toggleDetails = function () {
            if (!__trackedDetails) {
                ppTrack.pageAction(PAGE_NAME, 'showDetails');
                __trackedDetails = true;
            }
            vm.showDetails = !vm.showDetails;
        };

        vm.submitBid = function () {
            vm.processing = true;

            ppTrack.setContext({
                'bid.price': vm.invalidPrice
            });

            if (!vm.form.$valid) {
                vm.feedbackEnabled = true;
                vm.processing = false;
                ppTrack.ngFormValidation(PAGE_NAME, vm.form);
            } else {
                vm.onSubmit({
                        numberOfShares: vm.shares.value,
                        sharePrice: vm.pPrice.value,
                        budget: vm.budget.value,
                        totalCost: vm.results.totalCost,
                        recaptchaResponse: vm.recaptchaResponse
                    })
                    .then(function () {
                        vm.previousBudget = vm.budget.value;
                        vm.previousBid = vm.pPrice.value;
                        resetForm();
                    })
                    .catch(function (error) {
                        vm.error = error.reason;

                        // to show banding price limits error message
                        if (error.priceLimits) {
                            vm.priceLimits = error.priceLimits;
                        }
                        vm.showSystemErrorMessages = true;
                        vm.feedbackEnabled = false;

                        resetRecaptcha();
                    })
                    .finally(function () {
                        vm.processing = false;
                    });
            }
        };

        vm.setRecaptchaWidgetId = function (widgetId) {
            vm.recaptchaWidgetId = widgetId;
        };

        // -- scope bindings

        $scope.$watch('vm.property.market.secondary.bestBid.price', function () {
            setPriceMessage(vm.invalidPrice);
        });

        $scope.$watch('vm.invalidPrice', function (value) {
            setPriceMessage(value);
        });

        $scope.$watch('vm.budget.value', function (value) {

            propertyService.storeQuote(vm.property.symbol, {
                budget: vm.budget.value
            });

            if (!value && value !== 0) {
                return;
            }

            updateResults();
        });

        // -- main

        vm.$onInit = function () {
            setupData(vm.user);

            $timeout(function () {
                $scope.$broadcast(FOCUS_ON_LOAD);
            });

            ppTrack.setContext({
                'property.symbol': vm.property.symbol
            });
        };

    }]
});
})(window, window.angular);