(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.selling-record-table', [
    'pp.widgets-templates',
    'pp.widgets.property-cell',
    'pp.components.table-sort',
    'pp.filters.numbers',
    'pp.services.core',
    'pp.services.route'
]);

ngModule.component('ppSellingRecordTable', {
    templateUrl: 'widgets/_angular/selling-record-table/selling-record-table.tpl.html',
    bindings: {
        total: '<',
        data: '<',
        propertiesDict: '<',
        holdings: '<',
        disableShowDetails: '<',
        offers: '<',
        bids: '<',
        title: '@',
        viewPropertyDetail: '&'
    },
    controllerAs: 'vm',
    controller: ['R', 'routeService', function (R, routeService) {
        var vm = this;

        // -- initial state

        vm.expanded = false;

        // -- util functions

        function peformSort(attr, order) {
            var sort = R.sortBy(R.prop(attr));
            if (order === 'ascending') {
                return sort(vm.data);
            } else if (order === 'descending') {
                return R.compose(R.reverse, sort)(vm.data);
            } else {
                return R.clone(vm.data);
            }
        }

        function peformPropertySort(attr, order) {
            var sort = R.sort(function (a, b) {
                var aName = R.path([a.symbol, 'location', 'location1'], vm.propertiesDict);
                var bName = R.path([b.symbol, 'location', 'location1'], vm.propertiesDict);
                if (aName < bName) {
                    return order === 'ascending' ? -1 : 1;
                }

                if (bName < aName) {
                    return order === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            return sort(vm.data);
        }

        // -- api

        vm.updatePropertySort = function (attr, order) {
            vm.tableData = peformPropertySort(attr, order);
            vm.sortOrder = order;
            vm.sortAttribute = attr;
        };

        vm.updateSort = function (attr, order) {
            vm.tableData = peformSort(attr, order);
            vm.sortOrder = order;
            vm.sortAttribute = attr;
        };

        vm.expandToggle = function () {
            vm.expanded = !vm.expanded;
        };

        vm.getIndividualPropertyPath = routeService.getIndividualPropertyPath;

        vm.onViewPropertyDetail = function (property) {
            vm.viewPropertyDetail({
                property: property
            });
        };

        // -- scope bindings

        // -- main

        vm.$onChanges = function (changes) {
            var data = R.path(['data', 'currentValue'], changes);
            if (data) {
                vm.tableData = R.clone(data);
            }
        };

    }]
});
})(window, window.angular);