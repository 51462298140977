(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.table-sort', [
    'pp.components-templates',
    'pp.components.tooltip'
]);

var ASCENDING = 'ascending';
var DESCENDING = 'descending';

ngModule.directive('ppTableSort', function () {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/table-sort/table-sort.tpl.html',
        transclude: {
            'notice': '?ppNotice'
        },
        scope: {
            label: '@',
            attribute: '@ppTableSort',
            currentSort: '<',
            currentOrder: '<',
            onChange: '&',
            tooltip: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$transclude', function ($scope, $transclude) {

            var vm = this;

            // -- initial state

            // -- util functions

            function updateIcon() {
                if (!vm.currentOrder) {
                    vm.iconClass = 'i-sort-nil';
                } else if (vm.currentSort !== vm.attribute) {
                    vm.iconClass = 'i-sort-nil';
                } else if (vm.currentOrder === ASCENDING) {
                    vm.iconClass = 'i-sort-up';
                } else {
                    vm.iconClass = 'i-sort-down';
                }
            }

            // -- api

            vm.onClick = function () {
                var order;
                if (!vm.currentOrder) {
                    order = DESCENDING;
                } else if (vm.currentSort === vm.attribute) {
                    order = vm.currentOrder === DESCENDING ? ASCENDING : null;
                } else {
                    order = DESCENDING;
                }
                vm.onChange({
                    attr: order && vm.attribute,
                    order: order
                });
            };

            vm.isNoticePresent = function () {
                return $transclude.isSlotFilled('notice');
            };

            // -- dom bindings

            vm.$onInit = function () {
                $scope.$watch('vm.currentSort', updateIcon);
                $scope.$watch('vm.currentOrder', updateIcon);
            };

            // -- main

        }]
    };

});
})(window, window.angular);