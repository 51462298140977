(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.authenticate-investor', [
    'pp.services.core',
    'pp.services.investor',
    'pp.services.run',
    'pp.ui.services.required-user-data-dialog',
    'pp.widgets.auth-modal'
]);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/extra-user-data`.
 */
ngModule.service('authenticateInvestor', ['$q', '$timeout', 'ppPubSub', 'investorService', 'runService', 'requiredUserDataDialogService', function ($q, $timeout, ppPubSub, investorService, runService, requiredUserDataDialogService) {

    // -- initial state

    // -- util functions

    function getInvestor() {
        investorService.getUserInvestors();
        return investorService.getInvestor();
    }

    function getExtraData(user) {
        return runService.checkUserExtraDataRequired(user, requiredUserDataDialogService).then(function () {
            return user;
        });
    }

    function handleSuccess() {
        return getInvestor()
            .then($timeout($.modal.close))
            .then(getExtraData);
    }

    function closeAndResolve(defer, user) {
        $.modal.close();
        defer.resolve(user);
    }

    function onAuthResolution(resolve) {
        handleSuccess()
            .then(resolve);
    }

    function onAuthCloseResolution(resolve) {
        getInvestor()
            .then(resolve);
    }

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(sessionExpired, investor) {
        var defer = $q.defer();
        var resolve = closeAndResolve.bind(undefined, defer);
        var onAuthCallback = onAuthResolution.bind(undefined, resolve);
        var onAuthCloseCallback = onAuthCloseResolution.bind(undefined, resolve);

        var authType = investor.stage === 'initial' ? 'signup' : 'login';

        ppPubSub.publish('auth.modal.show', [authType, onAuthCallback, onAuthCloseCallback, null, sessionExpired]);
        return defer.promise;
    }

    // -- api

    return function (sessionExpired) {
        return getInvestor().then(function (investor) {
            if (investor.anon) {
                return showDialog(sessionExpired, investor);
            } else {
                return getExtraData(investor);
            }
        });
    };
}]);

ngModule.service('authenticateInvestorAnonReject', ['$q', 'authenticateInvestor', function ($q, authenticateInvestor) {
    return function (sessionExpired) {
        return authenticateInvestor().then(function (investor) {
            if (investor.anon) {
                return $q.reject(investor);
            } else {
                return investor;
            }
        });
    };
}]);
})(window, window.angular);