(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.referral', [
    'pp.services.investor',
    'pp.services.core'
]);

var API_BASE_PATH = '/feapi/r1';
var RFF_INVESTOR_ENDPOINT = API_BASE_PATH + '/investor/rff';
var INVESTOR_FIRST_NAME_BY_GUID = API_BASE_PATH + '/investor/first-name/:guid';

var BASE_REFER_QUERYSTRING = '?r=';

ngModule.service('referralService', ['$http', '$q', '$window', 'investorService', 'ppTrack', 'ppCookie', function ($http, $q, $window, investorService, ppTrack, ppCookie) {

    var PING_ENDPOINT = '/ping';
    var IS_INTRODUCER_ENDPOINT = '/feapi/r1/introducer/:guid';

    var api = {};

    var promiseCache = {};

    api.getReferralUrl = function (investor) {
        var funnel = investor.funnel || {};
        var baseReferUrl = $window.location.protocol + '//' + $window.location.host;
        return baseReferUrl + BASE_REFER_QUERYSTRING + funnel.humanReadableReferralCode;
    };

    api.setReferralCode = function (code) {
        var endpoint = PING_ENDPOINT + '?r=' + code;

        var cookieData = investorService.getCookieData();

        return $http.get(endpoint).then(function () {
            investorService.setCookieData(ppTrack.getUserData(true));
        });
    };

    api.isGuidAnIntroducer = function (guid) {
        var endpoint = IS_INTRODUCER_ENDPOINT.replace(':guid', guid);
        var cacheKey = 'is-introducer';
        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint).then(function (res) {
                return res.data.isIntroducer;
            }, function () {
                delete promiseCache[cacheKey];
                return false;
            });
        }

        return promiseCache[cacheKey];
    };

    api.isInvestorReferredByIntroducer = function (investor) {
        if (investor.anon && investor.referredBy) {
            return api.isGuidAnIntroducer(investor.referredBy);
        } else {
            return $q.when(!!investor.referredByIntroducer);
        }
    };

    api.getEmailShareUrl = function (firstName, referUrl, isGifting) {
        if (isGifting) {
            return 'mailto:?subject=Your%20friend%20' +
                firstName + '%20is%20gifting%20you%20100%25%20of%20our%20refer%20a%20friend%20bonus.%20Join%20them%20on%20London%20House%20Exchange%2C%20the%20smarter%20way%20to%20invest%20in%20property.&body=Hello%2C%0A%0AYour%20friend%20' +
                firstName + '%20is%20gifting%20you%20100%25%20of%20our%20refer%20a%20friend%20bonus.%20Join%20them%20on%20London%20House%20Exchange%2C%20the%20smarter%20way%20to%20invest%20in%20property.%0A%0AJoin%20today%2C%20and%20you%20could%20earn%20up%20to%20%C2%A31%2C500%20when%20you%20invest.%20Terms%20apply.%0A%0A' +
                referUrl + '%0A%0ACapital%20at%20risk%0A%0AIf%20you%20have%20any%20questions%2C%20please%20don\'t%20hesitate%20to%20contact%20us%20on%20hello%40londonhouseexchange.com%20or%20give%20us%20a%20ring%20on%20%2B44(0)2036965600%0A%0AMany%20thanks%2C%0A%0AThe%20team%20at%20London%20House%20Exchange';
        } else {
            return 'mailto:?subject=Your%20friend%20' +
                firstName + '%20wants%20to%20introduce%20you%20to%20London%20House%20Exchange.%20The%20Best%20Property%20Investment%20Service%202018.&body=Hello%2C%0A%0AYour%20friend%20' +
                firstName + '%20wants%20to%20introduce%20you%20to%20London%20House%20Exchange.%20The%20Best%20Property%20Investment%20Service%202018.%0A%0AAs%20voted%20for%20in%20the%20Online%20Personal%20Wealth%20Awards%20%40prop_partner%20Terms%20apply.%0A%0A' +
                referUrl + '%0A%0ACapital%20at%20risk%0A%0AIf%20you%20have%20any%20questions%2C%20please%20don%27t%20hesitate%20to%20contact%20us%20on%20hello%40londonhouseexchange.com%20or%20give%20us%20a%20ring%20on%20%2B44(0)2036965600%0A%0AMany%20thanks%2C%0A%0AThe%20team%20at%20London%20House%20Exchange';
        }
    };

    api.getWhatsAppShareUrl = function (firstName, referUrl, isGifting) {
        if (isGifting) {
            return 'whatsapp://send?text=Your%20friend%20' +
                firstName + '%20is%20gifting%20you%20100%25%20of%20our%20refer%20a%20friend%20bonus.%20Join%20them%20on%20London%20House%20Exchange%2C%20the%20smarter%20way%20to%20invest%20in%20property.%20Join%20today%2C%20and%20earn%20up%20to%20%C2%A31%2C500%20cashback%20when%20you%20invest.%20Terms%20Apply.%20' +
                $window.encodeURIComponent(referUrl) + '%20Capital%20at%20Risk';
        } else {
            return 'whatsapp://send?text=Your%20friend%20' +
                firstName + '%20wants%20to%20introduce%20you%20to%20London%20House%20Exchange.%20The%20Best%20Property%20Investment%20Service%202018.%20As%20voted%20for%20in%20the%20Online%20Personal%20Wealth%20Awards.%20Terms%20Apply.%20' +
                $window.encodeURIComponent(referUrl) + '%20Capital%20at%20Risk';
        }
    };

    api.getLinkedinShareUrl = function (firstName, referUrl, isGifting) {
        if (isGifting) {
            return 'https://www.linkedin.com/shareArticle?mini=true&url=' +
                $window.encodeURIComponent(referUrl) + '&title=Your%20friend%20' +
                firstName + '%20is%20gifting%20you%20100%25%20of%20our%20refer%20a%20friend%20bonus.%20Join%20them%20on%20London%20House%20Exchange%2C%20the%20smarter%20way%20to%20invest%20in%20property.&summary=Join%20today%2C%20and%20earn%20up%20to%20%C2%A31%2C500%20when%20you%20invest.%20Terms%20Apply.%20' +
                $window.encodeURIComponent(referUrl) + '%20Capital%20at%20risk';
        } else {
            return 'https://www.linkedin.com/shareArticle?mini=true&url=' +
                $window.encodeURIComponent(referUrl) + '&title=Your%20friend%20' +
                firstName + '%20wants%20to%20introduce%20you%20to%20London%20House%20Exchange.%20The%20Best%20Property%20Investment%20Service%202018.&summary=As%20voted%20for%20in%20the%20Online%20Personal%20Wealth%20Awards.%20Terms%20Apply.%20' +
                $window.encodeURIComponent(referUrl) + '%20Capital%20at%20risk';
        }
    };

    api.getTwitterShareUrl = function (firstName, referUrl, isGifting) {
        if (isGifting) {
            return 'https://twitter.com/intent/tweet?text=Your%20friend%20' +
                firstName + '%20is%20gifting%20you%20100%25%20of%20our%20refer%20a%20friend%20bonus.%20Join%20them%20on%20London%20House%20Exchange%2C%20the%20smarter%20way%20to%20invest%20in%20property.%20%40prop_partner%20Join%20today%2C%20and%20you%20could%20earn%20up%20to%20%C2%A31%2C500%20when%20you%20invest.%20Terms%20apply.%20' +
                referUrl + '%20Capital%20at%20risk.';
        } else {
            return 'https://twitter.com/intent/tweet?text=Your%20friend%20' +
                firstName + '%20wants%20to%20introduce%20you%20to%20London%20House%20Exchange.%20The%20Best%20Property%20Investment%20Service%202018.%20As%20voted%20for%20in%20the%20Online%20Personal%20Wealth%20Awards%20%40prop_partner%20Terms%20apply.%20' +
                referUrl + '%20Capital%20at%20risk.';
        }
    };

    api.getFirstNameByGuid = function (guid) {

        var endpoint = INVESTOR_FIRST_NAME_BY_GUID.replace(':guid', guid);
        return $http.get(endpoint).then(function (res) {
            return res.data.firstName;
        }, function () {
            return;
        });
    };

    return api;
}]);
})(window, window.angular);