(function(window, angular){'use strict';

var ngModule = angular.module('pp.controllers.pin-reset', ['pp.services.core', 'pp.services.investor', 'pp.components.pin-validation']);

/**
 * controller for the pin form
 */
ngModule.controller('pinResetCtrl', ['$scope', '$timeout', 'ppTrack', 'investorService', function ($scope, $timeout, ppTrack, investorService) {
    var vm = this;

    // -- initial state

    // -- api

    // prevents formConfirmResetPin submission if there are errors
    vm.submit = function ($event) {
        $('#pinResetError').remove();
        if (!vm.formConfirmResetPin.$valid) {
            vm.feedbackEnabled = true;
            $event.preventDefault();
        }
    };

    // -- scope bindings

    // -- main

    // legacy
    $timeout(function () {
        vm.ready = true;
    });

    investorService.getInvestor().then(function (user) {
        ppTrack.pageLoad('auth.pin.reset');
    });
}]);
})(window, window.angular);