(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.how-it-works', [
    'ui.router',
    'pp.services.core',
    'pp.services.investor',
    'pp.widgets-templates',
    'pp.widgets.business-summary-how-it-works',
    'pp.widgets.ways-to-invest',
    'pp.widgets.premium-services-section',
    'pp.widgets.info-card',
    'pp.widgets.secure-banner',
    'pp.values.asset-types',
    'pp.components-templates'
]);

var PAGE_NAME = 'how-it-works';

ngModule.component('ppHowItWorks', {
    templateUrl: 'widgets/_angular/how-it-works/how-it-works.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$element', 'ppTrack', 'ppConfig', 'ppComponentEmitter', 'investorService', 'assetTypes', function ($scope, $element, ppTrack, ppConfig, ppComponentEmitter, investorService, assetTypes) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints');

        // -- initial state

        vm.s3Endpoint = __config ? __config.assets : null;

        vm.assets = assetTypes;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        vm.$postLink = function () {
            ppComponentEmitter.emit('how-it-works.dom.loaded', $element);
        };

        vm.$onInit = function () {
            ppComponentEmitter.emit('how-it-works.controller.loaded', vm);
        };

        investorService.getInvestor().then(function (investor) {
            vm.investor = investor;
            ppTrack.pageLoad(PAGE_NAME);
            vm.ready = true;
        });

    }]
});
})(window, window.angular);