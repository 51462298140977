(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.terms-dialog', [
    'pp.widgets-templates',
    'pp.services.dialog'
]);

ngModule.directive('ppTermsDialog', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/terms-dialog/terms-dialog.tpl.html',
        scope: {
            contents: '<ppTermsDialog',
            vars: '<',
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$sce', 'dialogService', function ($scope, $sce, dialogService) {
            var vm = this;

            // -- initial state

            // -- util functions

            function parseContents(contents) {
                if (!vm.vars) {
                    return contents;
                }
                var key;
                for (key in vm.vars) {
                    var regex = new RegExp('\{%' + key + '%\}', 'g');
                    contents = contents.replace(regex, vm.vars[key]);
                }
                return contents;
            }

            // -- api

            vm.closeDialog = function () {
                dialogService.resolveParent($scope);
            };

            vm.$onChanges = function (changes) {
                if (changes.contents && changes.contents.currentValue) {
                    vm.parsedContents = $sce.trustAsHtml(parseContents(changes.contents.currentValue));
                }
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);