(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.auth-nav-anon', ['pp.widgets-templates', 'pp.services.core']);

/**
 * @ngdoc directive
 * @name ppAuthNavAnon
 * @description
 * Renders the login and singup buttons
 *
 * @restrict A
 * @scope
 *
 * @todo JIRA auth-dialog service
 */

ngModule.directive('ppAuthNavAnon', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/auth-nav-anon/auth-nav-anon.tpl.html',
        scope: {
            user: '=ppAuthNavAnon'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['ppPubSub', 'ppTrack', function (ppPubSub, ppTrack) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            vm.signup = function () {
                ppTrack.action('menu.signup.click');
                ppPubSub.publish('auth.modal.show', ['signup']);
            };

            vm.login = function () {
                ppTrack.action('menu.login.click');
                ppPubSub.publish('auth.modal.show', ['login']);
            };

            // -- scope bindings

            // -- main
        }]
    };
});
})(window, window.angular);