(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.our-story', ['pp.widgets-templates', 'pp.services.core']);

var OUR_STORY_PAGE = 'about.ourstory';

ngModule.component('ppOurStory', {
    templateUrl: 'widgets/_angular/our-story/our-story.tpl.html',
    bindings: {},
    transclude: true,
    controllerAs: 'vm',
    controller: ['ppTrack', function (ppTrack) {

        var vm = this;

        // -- initial state

        vm.$onInit = function () {
            ppTrack.pageLoad(OUR_STORY_PAGE);
        };

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);