(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.tax-statement', [
    'pp.widgets-templates',
    'pp.services.investor',
    'pp.services.dashboard',
    'pp.services.date',
    'pp.values.form-lists'
]);

ngModule.component('ppTaxStatement', {
    templateUrl: 'widgets/_angular/tax-statement/tax-statement.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$timeout', 'investorService', 'dateService', 'formLists', function ($scope, $timeout, investorService, dateService, formLists) {
        var vm = this;

        vm.taxMethods = {};
        vm.tax = {};
        vm.tax.postInfo = {};
        vm.formData = formLists;

        vm.taxForm = {};

        // TODO refactor this component to use momentjs

        vm.taxMethods.calculateTaxYear = function () {
            var taxYearStartAndEnd = dateService.getPreviousTaxYear();
            var startDate = taxYearStartAndEnd[0].split('-');
            var endDate = taxYearStartAndEnd[1].split('-');
            vm.tax.startDay = startDate[0];
            vm.tax.startMonth = startDate[1];
            vm.tax.startYear = startDate[2];
            vm.tax.endDay = endDate[0];
            vm.tax.endMonth = endDate[1];
            vm.tax.endYear = endDate[2];
            vm.tax.postInfo.startDate = taxYearStartAndEnd[0];
            vm.tax.postInfo.endDate = taxYearStartAndEnd[1];
        };

        vm.taxMethods.updateDates = function () {
            vm.tax.postInfo.startDate = vm.tax.startDay + '-' + vm.tax.startMonth + '-' + vm.tax.startYear;
            vm.tax.postInfo.endDate = vm.tax.endDay + '-' + vm.tax.endMonth + '-' + vm.tax.endYear;
        };

        function isLeapYear(year) {
            if (year % 4 === 0) {
                return true;
            } else {
                return false;
            }
        }

        vm.taxMethods.isInvalidDate = function (day, month, year) {
            var months = {
                '01': 31,
                '02': 28,
                '03': 31,
                '04': 30,
                '05': 31,
                '06': 30,
                '07': 31,
                '08': 31,
                '09': 30,
                '10': 31,
                '11': 30,
                '12': 31
            };
            if (Number(day) > months[month]) {
                if (month === '02') {
                    if (isLeapYear(year)) {
                        if (day > 29) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                return false;
            }
        };

        vm.taxMethods.isFutureDate = function (day, month, year) {
            var testDate = new Date(year, (month - 1), day);
            var currentDate = new Date();
            if (testDate > currentDate) {
                return true;
            } else {
                return false;
            }
        };

        vm.taxMethods.anyTrue = function (testArray) {
            for (var i = 0; i < testArray.length; i++) {
                if (testArray[i]) {
                    return true;
                }
            }
        };

        vm.taxMethods.startDateIsBeforeEndDate = function () {
            vm.startDate = new Date(vm.tax.startYear, vm.tax.startMonth, vm.tax.startDay);
            vm.endDate = new Date(vm.tax.endYear, vm.tax.endMonth, vm.tax.endDay);
            if (vm.startDate < vm.endDate) {
                return true;
            } else {
                return false;
            }
        };

        vm.downloadTaxStatement = function () {
            if (vm.taxForm.$valid) {
                if (!vm.taxMethods.isFutureDate(vm.tax.endDay, vm.tax.endMonth, vm.tax.endYear)) {
                    if (!vm.taxMethods.isInvalidDate(vm.tax.startDay, vm.tax.startMonth, vm.tax.startYear)) {
                        if (!vm.taxMethods.isInvalidDate(vm.tax.endDay, vm.tax.endMonth, vm.tax.endYear)) {
                            if (vm.taxMethods.startDateIsBeforeEndDate()) {
                                vm.formattedStart = vm.tax.startDay + '-' + vm.tax.startMonth + '-' + vm.tax.startYear;
                                vm.formattedEnd = vm.tax.endDay + '-' + vm.tax.endMonth + '-' + vm.tax.endYear;
                                return '/investor/tax-statement-download?start=' + vm.formattedStart + '&stop=' + vm.formattedEnd;
                            }
                        }
                    }
                }
            }
        };

        // -- main

        vm.taxMethods.calculateTaxYear();

        investorService.getInvestor().then(function (user) {
            vm.ready = true;
        });

    }]
});
})(window, window.angular);