(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.secure-banner', [
    'pp.widgets-templates',
    'pp.services.core'
]);

var secureCopies = {
    'ring-fenced': {
        icon: 'i-fence',
        title: 'Investment structure',
        description: 'Each property listed on the exchange is ring-fenced.',
        longDescription: 'Each property listed on the exchange is ring-fenced from the assets and liabilities of London House Exchange, as well as any other property investments on the platform, meaning there is no risk of clients losing their shares.'
    },
    protected: {
        icon: 'i-my-holdings',
        title: 'Managed Sale',
        description: 'PricewaterhouseCoopers LLP has been pre-engaged to manage the sale of the property portfolio.',
        longDescription: 'In the unlikely event that LHX was facing financial difficulty and the decision was taken to wind down the platform, PricewaterhouseCoopers LLP has been pre-engaged to manage the sale of the property portfolio and return of capital to our clients.'
    },
    regulated: {
        icon: 'i-fca',
        title: 'Regulation',
        description: 'The Financial Conduct Authority (FCA) authorises and regulates London House Exchange.',
        longDescription: 'The Financial Conduct Authority (FCA) authorises and regulates London House Exchange and requires us to maintain a specified level of regulatory capital to help absorb routine losses, meet any redress claims made against us, and meet the costs of an orderly wind-down.'
    }
};

ngModule.component('ppSecureBanner', {
    templateUrl: 'widgets/_angular/secure-banner/secure-banner.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$element', 'ppComponentEmitter', function ($element, ppComponentEmitter) {
        var vm = this;

        // -- initial state

        vm.secureCopies = secureCopies;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        vm.$postLink = function () {
            ppComponentEmitter.emit('homepage.secure-banner.dom.loaded', $element);
        };

    }]
});
})(window, window.angular);