(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.return-composition-bar', [
    'pp.widgets-templates',
    'pp.components.tooltip',
    'pp.widgets.aum-dividend-notice'
]);

ngModule.component('ppReturnCompositionBar', {
    templateUrl: 'widgets/_angular/return-composition-bar/return-composition-bar.tpl.html',
    bindings: {
        plan: '<',
        hideReturnBreakdown: '<',
        isCentered: '<',
        showWarnings: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$anchorScroll', function ($scope, $anchorScroll) {
        var vm = this;

        // -- initial state

        vm.ratioClassMap = {
            25: 'ratio-25',
            30: 'ratio-30',
            35: 'ratio-35',
            40: 'ratio-40',
            45: 'ratio-45',
            50: 'ratio-50',
            55: 'ratio-55',
            60: 'ratio-60',
            65: 'ratio-65',
            70: 'ratio-70',
            75: 'ratio-75'
        };

        // -- util functions

        // -- api

        vm.anchorScroll = $anchorScroll;

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);