(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.quote-breakdown', [
    'pp.widgets-templates',
    'pp.components.minimum-funding-info',
    'pp.filters.numbers'
]);

ngModule.component('ppQuoteBreakdown', {
    templateUrl: 'widgets/_angular/quote-breakdown/quote-breakdown.tpl.html',
    bindings: {
        isPrimary: '<',
        quoteReady: '<',
        quoteValid: '<',
        hideContinueMessage: '<',
        quote: '<',
        user: '<',
        property: '<',
        leftToPay: '<',
        minimumTransaction: '<',
        orderType: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api
        vm.togglePremiumTooltip = function () {
            vm.premiumTooltipVisible = !vm.premiumTooltipVisible;
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);