(function(window, angular){'use strict';

$('.switch-label.switch-label-first').click(function () {
    $(this).addClass('checked');
    $('.switch-label.switch-label-last').removeClass('checked');
    $('.invest-container').addClass('manual');
    $('.invest-container').removeClass('auto');
});

$('.switch-label.switch-label-last').click(function () {
    $(this).addClass('checked');
    $('.switch-label.switch-label-first').removeClass('checked');
    $('.invest-container').addClass('auto');
    $('.invest-container').removeClass('manual');
});
})(window, window.angular);