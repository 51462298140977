(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.chart-date-options', [
    'pp.widgets-templates',
    'pp.services.random'
]);

ngModule.directive('ppChartDateOptions', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/chart-date-options/chart-date-options.tpl.html',
        scope: {
            option: '<ppChartDateOptions',
            oldestTradeTimestamp: '<',
            onChange: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'random', function ($scope, random) {
            var vm = this;

            // -- initial state
            vm.idPrefix = random.id(6);
            var now = new Date();
            var nowZeroHours = now.setHours(0, 0, 0, 0);

            // -- util functions

            // -- api

            // -- scope bindings

            vm.$onInit = function () {

                vm.oneDayAgo = new Date(nowZeroHours);
                vm.oneDayAgo = vm.oneDayAgo.setDate(vm.oneDayAgo.getDate() - 1);

                vm.oneWeekAgo = new Date(nowZeroHours);
                vm.oneWeekAgo = vm.oneWeekAgo.setDate(vm.oneWeekAgo.getDate() - 7);

                vm.oneMonthAgo = new Date(nowZeroHours);
                vm.oneMonthAgo = vm.oneMonthAgo.setDate(vm.oneMonthAgo.setDate() - 30);

                vm.threeMonthsAgo = new Date(nowZeroHours);
                vm.threeMonthsAgo = vm.threeMonthsAgo.setMonth(vm.threeMonthsAgo.getMonth() - 3);

                vm.sixMonthsAgo = new Date(nowZeroHours);
                vm.sixMonthsAgo = vm.sixMonthsAgo.setMonth(vm.sixMonthsAgo.getMonth() - 6);

                vm.oneYearAgo = new Date(nowZeroHours);
                vm.oneYearAgo = vm.oneYearAgo.setFullYear(vm.oneYearAgo.getFullYear() - 1);

                $scope.$watch('vm.option', function (newOption) {

                    // note early return
                    if (!newOption) {
                        return;
                    }

                    vm.onChange({
                        option: newOption
                    });
                });
            };

            // -- main

        }]
    };
}]);
})(window, window.angular);