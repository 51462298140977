(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.order-type-select', ['pp.widgets-templates']);

ngModule.component('ppOrderTypeSelect', {
    templateUrl: 'widgets/_angular/order-type-select/order-type-select.tpl.html',
    bindings: {
        selectedOrderType: '<',
        onOrderTypeChanged: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', function ($scope, $window) {
        var vm = this;

        // -- initial state

        vm.selectOpen = false;

        vm.labels = {
            marketOrder: 'Market order',
            bidOrder: 'Bid order'
        };

        // -- util functions

        function closeMenu() {
            $scope.$evalAsync(function () {
                vm.selectOpen = false;
                $($window).off('click', closeMenu);
            });
        }

        // -- api

        vm.toggleSelect = function (ev) {
            vm.selectOpen = !vm.selectOpen;

            if (vm.selectOpen) {
                $($window).on('click', closeMenu);
            } else {
                $($window).off('click', closeMenu);
            }

            ev.stopPropagation();

        };

        vm.switchOrder = function (orderType) {
            vm.onOrderTypeChanged({
                orderType: orderType
            });
            vm.selectOpen = false;
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);