(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.primary-order-list-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.widgets.primary-order-list'
]);

/**
 * @ndgoc service
 * @description
 * checks if we need to notify the user that they have been qualified/disqualified for an rff scheme
 * shows the dialog and sets a preference when the user dismisses it
 * so that the dialog is never shown again for the same scheme
 */
ngModule.service('primaryOrderListDialogService', ['$rootScope', 'ppTrack', 'dialogService', function ($rootScope, ppTrack, dialogService) {

    // -- util functions

    var __currentInstance;

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(orders, properties, onSubmitOrders, investor) {
        var scope = $rootScope.$new();
        var options = {
            clickClose: false,
            escapeClose: true,
            showClose: true,
            scope: scope,
            classNames: 'primary-order-list-modal large',
        };
        scope.orders = orders;
        scope.properties = properties;
        scope.onSubmitOrders = onSubmitOrders;
        scope.investor = investor;

        options.contents = '<pp-primary-order-list orders="orders" properties="properties" on-submit-orders="onSubmitOrders(orders, agreeToInvestmentDocs)" investor="investor"></pp-primary-order-list>';

        return dialogService.show(options);
    }

    // -- api

    var api = {};

    api.show = function (orders, properties, onSubmitOrders, investor) {
        __currentInstance = showDialog(orders, properties, onSubmitOrders, investor);
        return __currentInstance;
    };

    api.close = function () {
        dialogService.close();
    };

    return api;
}]);
})(window, window.angular);