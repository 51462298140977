(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.investment-permission-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.widgets.mortgage-blocked-no-shares'
]);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/extra-user-data`.
 */
ngModule.service('investmentPermissionDialog', ['$rootScope', '$q', 'ppTrack', 'dialogService', function ($rootScope, $q, ppTrack, dialogService) {

    // -- initial state

    // -- util functions

    function onBlockedContinue(defer, key) {
        return function () {
            ppTrack.action(key + '.blocked-continue');
            defer.reject();
            dialogService.close();
        };
    }

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showMortgageBlockedNoShares(property) {

        ppTrack.action('.dialog.show');

        var defer = $q.defer();
        var scope = $rootScope.$new();

        scope.onContinue = onBlockedContinue(defer, 'mortgage-blocked-no-shares');
        scope.property = property;

        var options = {
            clickClose: false,
            escapeClose: false,
            showClose: false,
            classNames: 'pp-mortgage-blocked-no-shares-dialog',
            scope: scope
        };

        options.contents = '<pp-mortgage-blocked-no-shares on-continue="onContinue()" property="property"></pp-mortgage-blocked-no-shares>';

        dialogService.show(options);
        return defer.promise;
    }

    // -- api

    var api = {};

    api.showMortgageBlockedNoShares = function (property) {
        return showMortgageBlockedNoShares(property);
    };

    return api;
}]);
})(window, window.angular);