(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.match-against', []);

/**
 * @ngdoc directive
 * @name ppMatchAgainst
 * @description
 * Checks if model matches another. Can be used in negative (`non-match`) and/or case-insensitive (`match-caseless`) modes.
 *
 * @restrict A
 *
 * @param {*} ngModel Assignable angular expression to data-bind to.
 * @param {*} ppMatchAgainst Compare against this expression.
 * @param {boolean=} matchCaseless Case-insensitive mode.
 * @param {boolean=} notMatch Negative mode.
 */
ngModule.directive('ppMatchAgainst', ['$parse', function ($parse) {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function ($scope, $elem, $attrs, $ctrl) {

            // NOTE: anti-pattern test specific code, but no other way to check if a transparent directive was instantiated correctly
            $scope.ppTestHelperDirectiveName = 'pp-match-against';

            // -- initial state

            // using getters because we don't want an isolate $scope
            var matchGetter = $parse($attrs.ppMatchAgainst);
            var isCaseless = $parse($attrs.matchCaseless);
            var isNegativeMatch = $parse($attrs.notMatch);

            // -- util functions/

            function getMatchValue() {
                var match = matchGetter($scope);
                if (angular.isObject(match) && match.hasOwnProperty('$viewValue')) {
                    match = match.$viewValue;
                }
                return match;
            }

            // -- $scope bindings

            $scope.$watch(getMatchValue, function () {
                $ctrl.$$parseAndValidate();
            });

            // -- main

            $ctrl.$validators.ppMatchAgainst = function (modelValue, viewValue) {
                if ($ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                var match = getMatchValue();
                var result;
                if (isCaseless($scope)) {
                    result = angular.lowercase(viewValue) === angular.lowercase(match);
                } else {
                    result = viewValue === match;
                }
                return isNegativeMatch($scope) ? !result : result;
            };
        }
    };
}]);
})(window, window.angular);