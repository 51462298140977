(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.input-search', ['ng.cork.ui.focus-on', 'pp.components-templates', 'pp.services.core']);

ngModule.directive('ppInputSearch', ['ppTrack', function (ppTrack) {

    return {
        restrict: 'A',
        templateUrl: 'components/_angular/input-search/input-search.tpl.html',
        scope: {
            searchTerm: '=ppInputSearch',
            placeholder: '@',
            focusOn: '@'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- api

            vm.clear = function () {
                vm.searchTerm = null;
                $scope.$broadcast(vm.focusOn);
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);