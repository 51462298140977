(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.selling-record', [
    'pp.widgets-templates',
    'pp.widgets.selling-record-table',
    'pp.widgets.property-cell',
    'pp.components.table-sort',
    'pp.filters.numbers',
    'pp.services.route',
    'pp.services.core'
]);

ngModule.component('ppSellingRecord', {
    templateUrl: 'widgets/_angular/selling-record/selling-record.tpl.html',
    bindings: {
        propertiesDict: '<',
        holdings: '<',
        offers: '<',
        bids: '<',
        viewPropertyDetail: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'R', 'routeService', function ($scope, R, routeService) {
        var vm = this;

        // -- initial state

        vm.sortAttribute = '';
        vm.sortOrder = 'ascending';

        var fiveYearUnitSaleData = [{
            'symbol': 'UKTN342RD001',
            'saleDate': 1639612800000,
            'purchasePrice': 166800,
            'ivValuation': 176358,
            'vpvValuation': 195000,
            'salePrice': 205000
        }, {
            'symbol': 'UKLN106SQ',
            'saleDate': 1648681200000,
            'purchasePrice': 103000,
            'ivValuation': 114580,
            'vpvValuation': 135000,
            'salePrice': 135000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1651186800000,
            'purchasePrice': 93000,
            'ivValuation': 96693,
            'vpvValuation': 110000,
            'salePrice': 112000
        }, {
            'symbol': 'UKTQ13TB001',
            'saleDate': 1653951600000,
            'purchasePrice': 136000,
            'ivValuation': 136111,
            'vpvValuation': 160000,
            'salePrice': 155000
        }, {
            'symbol': 'UKTQ13TB001',
            'saleDate': 1653606000000,
            'purchasePrice': 136000,
            'ivValuation': 136111,
            'vpvValuation': 160000,
            'salePrice': 153000
        }, {
            'symbol': 'UKTN342RD001',
            'saleDate': 1656543600000,
            'purchasePrice': 166800,
            'ivValuation': 176358,
            'vpvValuation': 195000,
            'salePrice': 238000
        }, {
            'symbol': 'UKTN342RD001',
            'saleDate': 1655679600000,
            'purchasePrice': 166800,
            'ivValuation': 176358,
            'vpvValuation': 195000,
            'salePrice': 220000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1655247600000,
            'purchasePrice': 92500,
            'ivValuation': 101088,
            'vpvValuation': 115000,
            'salePrice': 110000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1655247600000,
            'purchasePrice': 93000,
            'ivValuation': 101088,
            'vpvValuation': 115000,
            'salePrice': 110000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1656025200000,
            'purchasePrice': 79000,
            'ivValuation': 105483,
            'vpvValuation': 120000,
            'salePrice': 125250
        }, {
            'symbol': 'UKBN213YB001',
            'saleDate': 1657234800000,
            'purchasePrice': 108368,
            'ivValuation': 118912,
            'vpvValuation': 140000,
            'salePrice': 134000
        }, {
            'symbol': 'UKTN342RD001',
            'saleDate': 1659654000000,
            'purchasePrice': 166800,
            'ivValuation': 176358,
            'vpvValuation': 195000,
            'salePrice': 240000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1659999600000,
            'purchasePrice': 89000,
            'ivValuation': 96693,
            'vpvValuation': 110000,
            'salePrice': 107000
        }, {
            'symbol': 'UKDN212HZ001',
            'saleDate': 1660863600000,
            'purchasePrice': 51000,
            'ivValuation': 54831,
            'vpvValuation': 60000,
            'salePrice': 60000
        }, {
            'symbol': 'UKDN212HZ001',
            'saleDate': 1660863600000,
            'purchasePrice': 51000,
            'ivValuation': 50262,
            'vpvValuation': 55000,
            'salePrice': 55000
        }, {
            'symbol': 'UKBN213YB001',
            'saleDate': 1660777200000,
            'purchasePrice': 143201,
            'ivValuation': 157134,
            'vpvValuation': 185000,
            'salePrice': 185000
        }, {
            'symbol': 'UKPO119NQ001',
            'saleDate': 1663196400000,
            'purchasePrice': 118725,
            'ivValuation': 125173,
            'vpvValuation': 147500,
            'salePrice': 160000
        }, {
            'symbol': 'UKPO119NQ001',
            'saleDate': 1663887600000,
            'purchasePrice': 118725,
            'ivValuation': 129416,
            'vpvValuation': 152500,
            'salePrice': 165000
        }, {
            'symbol': 'UKLN106SQ',
            'saleDate': 1661900400000,
            'purchasePrice': 130000,
            'ivValuation': 148529,
            'vpvValuation': 175000,
            'salePrice': 167500
        }, {
            'symbol': 'UKBN213YB001',
            'saleDate': 1665961200000,
            'purchasePrice': 139331,
            'ivValuation': 152887,
            'vpvValuation': 180000,
            'salePrice': 175000
        }, {
            'symbol': 'UKBN213YB001',
            'saleDate': 1665961200000,
            'purchasePrice': 131590,
            'ivValuation': 144393,
            'vpvValuation': 170000,
            'salePrice': 178450
        }, {
            'symbol': 'UKTN342RD001',
            'saleDate': 1665702000000,
            'purchasePrice': 166800,
            'ivValuation': 176358,
            'vpvValuation': 195000,
            'salePrice': 210000
        }, {
            'symbol': 'UKPO119NQ001',
            'saleDate': 1664492400000,
            'purchasePrice': 118725,
            'ivValuation': 133659,
            'vpvValuation': 157500,
            'salePrice': 165000
        }, {
            'symbol': 'UKPO119NQ001',
            'saleDate': 1664751600000,
            'purchasePrice': 118725,
            'ivValuation': 120929,
            'vpvValuation': 142500,
            'salePrice': 167000
        }, {
            'symbol': 'UKCO11BA001',
            'saleDate': 1665356400000,
            'purchasePrice': 151750,
            'ivValuation': 136203,
            'vpvValuation': 160000,
            'salePrice': 170100
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1666911600000,
            'purchasePrice': 90867,
            'ivValuation': 101088,
            'vpvValuation': 115000,
            'salePrice': 120000
        }, {
            'symbol': 'UKTN342RD001',
            'saleDate': 1668384000000,
            'purchasePrice': 166800,
            'ivValuation': 176358,
            'vpvValuation': 195000,
            'salePrice': 230000
        }, {
            'symbol': 'UKB634DF001',
            'saleDate': 1671408000000,
            'purchasePrice': 142000,
            'ivValuation': 171000,
            'vpvValuation': 190000,
            'salePrice': 215501
        }, {
            'symbol': 'UKPO119NQ001',
            'saleDate': 1671580800000,
            'purchasePrice': 118725,
            'ivValuation': 120929,
            'vpvValuation': 142500,
            'salePrice': 175000
        }, {
            'symbol': 'UKB634DF001',
            'saleDate': 1671580800000,
            'purchasePrice': 128000,
            'ivValuation': 144000,
            'vpvValuation': 160000,
            'salePrice': 192000
        }, {
            'symbol': 'UKTQ13TB001',
            'saleDate': 1674432000000,
            'purchasePrice': 136000,
            'ivValuation': 136111,
            'vpvValuation': 160000,
            'salePrice': 167000
        }, {
            'symbol': 'UKDN212HZ001',
            'saleDate': 1674777600000,
            'purchasePrice': 69259,
            'ivValuation': 73108,
            'vpvValuation': 80000,
            'salePrice': 88000
        }, {
            'symbol': 'UKM34NH001',
            'saleDate': 1675987200000,
            'purchasePrice': 187000,
            'ivValuation': 166277,
            'vpvValuation': 185000,
            'salePrice': 185000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1675987200000,
            'purchasePrice': 90867,
            'ivValuation': 101088,
            'vpvValuation': 115000,
            'salePrice': 130000
        }, {
            'symbol': 'UKLN106SQ',
            'saleDate': 1675209600000,
            'purchasePrice': 127500,
            'ivValuation': 140042,
            'vpvValuation': 165000,
            'salePrice': 165000
        }, {
            'symbol': 'UKCO11BA001',
            'saleDate': 1675123200000,
            'purchasePrice': 161000,
            'ivValuation': 136203,
            'vpvValuation': 160000,
            'salePrice': 166000
        }, {
            'symbol': 'UKCO11BA001',
            'saleDate': 1675036800000,
            'purchasePrice': 147500,
            'ivValuation': 140459,
            'vpvValuation': 165000,
            'salePrice': 161000
        }, {
            'symbol': 'UKDN212HZ001',
            'saleDate': 1677542400000,
            'purchasePrice': 54737,
            'ivValuation': 54831,
            'vpvValuation': 60000,
            'salePrice': 57000
        }, {
            'symbol': 'UKTQ13TB001',
            'saleDate': 1677542400000,
            'purchasePrice': 136000,
            'ivValuation': 136111,
            'vpvValuation': 160000,
            'salePrice': 165000
        }, {
            'symbol': 'UKBN213YB001',
            'saleDate': 1679529600000,
            'purchasePrice': 150941,
            'ivValuation': 165628,
            'vpvValuation': 195000,
            'salePrice': 170000
        }, {
            'symbol': 'UKCO11BA001',
            'saleDate': 1677628800000,
            'purchasePrice': 151750,
            'ivValuation': 144715,
            'vpvValuation': 170000,
            'salePrice': 161500
        }, {
            'symbol': 'UKBN213YB001',
            'saleDate': 1680130800000,
            'purchasePrice': 108368,
            'ivValuation': 118912,
            'vpvValuation': 140000,
            'salePrice': 141000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1680044400000,
            'purchasePrice': 76644,
            'ivValuation': 85266,
            'vpvValuation': 97000,
            'salePrice': 97500
        }, {
            'symbol': 'UKTN342RD001',
            'saleDate': 1680217200000,
            'purchasePrice': 166800,
            'ivValuation': 176358,
            'vpvValuation': 195000,
            'salePrice': 215000
        }, {
            'symbol': 'UKTQ13TB001',
            'saleDate': 1679871600000,
            'purchasePrice': 136000,
            'ivValuation': 136111,
            'vpvValuation': 160000,
            'salePrice': 155000
        }, {
            'symbol': 'UKSK153AN001',
            'saleDate': 1680735600000,
            'purchasePrice': 93029,
            'ivValuation': 98697,
            'vpvValuation': 115000,
            'salePrice': 119000
        }, {
            'symbol': 'UKTQ13TB001',
            'saleDate': 1681426800000,
            'purchasePrice': 137000,
            'ivValuation': 136111,
            'vpvValuation': 160000,
            'salePrice': 172000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1682636400000,
            'purchasePrice': 102719,
            'ivValuation': 114274,
            'vpvValuation': 130000,
            'salePrice': 125000
        }, {
            'symbol': 'UKLN106SQ',
            'saleDate': 1682636400000,
            'purchasePrice': 144500,
            'ivValuation': 165504,
            'vpvValuation': 195000,
            'salePrice': 170000
        }, {
            'symbol': 'UKSN22PE001',
            'saleDate': 1682636400000,
            'purchasePrice': 123125,
            'ivValuation': 93571,
            'vpvValuation': 110000,
            'salePrice': 110000
        }, {
            'symbol': 'UKB634DF001',
            'saleDate': 1682290800000,
            'purchasePrice': 128000,
            'ivValuation': 144000,
            'vpvValuation': 160000,
            'salePrice': 185000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1683673200000,
            'purchasePrice': 123263,
            'ivValuation': 137129,
            'vpvValuation': 156000,
            'salePrice': 160000
        }, {
            'symbol': 'UKB634DF001',
            'saleDate': 1686265200000,
            'purchasePrice': 142000,
            'ivValuation': 171000,
            'vpvValuation': 190000,
            'salePrice': 210000
        }, {
            'symbol': 'UKTQ13TB001',
            'saleDate': 1686524400000,
            'purchasePrice': 136000,
            'ivValuation': 136111,
            'vpvValuation': 160000,
            'salePrice': 165000
        }, {
            'symbol': 'UKW149SA001',
            'saleDate': 1685660400000,
            'purchasePrice': 440000,
            'ivValuation': 365189,
            'vpvValuation': 395000,
            'salePrice': 395000
        }, {
            'symbol': 'UKWF29UF001',
            'saleDate': 1687734000000,
            'purchasePrice': 82618,
            'ivValuation': 89831,
            'vpvValuation': 100000,
            'salePrice': 100000
        }, {
            'symbol': 'UKTN342RD001',
            'saleDate': 1688079600000,
            'purchasePrice': 166800,
            'ivValuation': 176358,
            'vpvValuation': 195000,
            'salePrice': 195000
        }, {
            'symbol': 'UKM278BG001',
            'saleDate': 1690498800000,
            'purchasePrice': 92500,
            'ivValuation': 99558,
            'vpvValuation': 112500,
            'salePrice': 123000
        }, {
            'symbol': 'UKWA27TE001',
            'saleDate': 1689894000000,
            'purchasePrice': 102500,
            'ivValuation': 109155,
            'vpvValuation': 125000,
            'salePrice': 120000
        }, {
            'symbol': 'UKBA114EH001',
            'saleDate': 1689289200000,
            'purchasePrice': 234000,
            'ivValuation': 256500,
            'vpvValuation': 285000,
            'salePrice': 305000
        }, {
            'symbol': 'UKTN342RD001',
            'saleDate': 1687474800000,
            'purchasePrice': 166800,
            'ivValuation': 176358,
            'vpvValuation': 195000,
            'salePrice': 197000
        }, {
            'symbol': 'UKCR69DE001',
            'saleDate': 1688684400000,
            'purchasePrice': 215000,
            'ivValuation': 195652,
            'vpvValuation': 225000,
            'salePrice': 235000
        }, {
            'symbol': 'UKDE11NU001',
            'saleDate': 1688684400000,
            'purchasePrice': 127800,
            'ivValuation': 123560,
            'vpvValuation': 137500,
            'salePrice': 130000
        }, {
            'symbol': 'UKSK153AN001',
            'saleDate': 1691708400000,
            'purchasePrice': 90760,
            'ivValuation': 98697,
            'vpvValuation': 115000,
            'salePrice': 120000
        }, {
            'symbol': 'UKM34NH001',
            'saleDate': 1691708400000,
            'purchasePrice': 182000,
            'ivValuation': 166277,
            'vpvValuation': 185000,
            'salePrice': 185000
        }, {
            'symbol': 'UKBA114EH001',
            'saleDate': 1692745200000,
            'purchasePrice': 242000,
            'ivValuation': 265500,
            'vpvValuation': 295000,
            'salePrice': 300000
        }, {
            'symbol': 'UKB634DF001',
            'saleDate': 1693954800000,
            'purchasePrice': 142000,
            'ivValuation': 157500,
            'vpvValuation': 175000,
            'salePrice': 192000
        }, {
            'symbol': 'UKM34NH001',
            'saleDate': 1694732400000,
            'purchasePrice': 173000,
            'ivValuation': 202229,
            'vpvValuation': 225000,
            'salePrice': 210000
        }, {
            'symbol': 'UKS124WG001',
            'saleDate': 1694559600000,
            'purchasePrice': 93577,
            'ivValuation': 93462,
            'vpvValuation': 110000,
            'salePrice': 125000
        }, {
            'symbol': 'UKSE165AD004',
            'saleDate': 1694732400000,
            'purchasePrice': 562500,
            'ivValuation': 499638,
            'vpvValuation': 525000,
            'salePrice': 470000
        }, {
            'symbol': 'UKSM26BH001',
            'saleDate': 1694732400000,
            'purchasePrice': 243831,
            'ivValuation': 231343,
            'vpvValuation': 250000,
            'salePrice': 240000
        }, {
            'symbol': 'UKTN342RD001',
            'saleDate': 1694732400000,
            'purchasePrice': 166800,
            'ivValuation': 176358,
            'vpvValuation': 195000,
            'salePrice': 200000
        }, {
            'symbol': 'UKW149SA001',
            'saleDate': 1694646000000,
            'purchasePrice': 444000,
            'ivValuation': 365189,
            'vpvValuation': 395000,
            'salePrice': 400000
        }, {
            'symbol': 'UKTF93HP001',
            'saleDate': 1695942000000,
            'purchasePrice': 84000,
            'ivValuation': 90000,
            'vpvValuation': 100000,
            'salePrice': 105000
        }, {
            'symbol': 'UKSM26BH001',
            'saleDate': 1697583600000,
            'purchasePrice': 243831,
            'ivValuation': 231343,
            'vpvValuation': 250000,
            'salePrice': 237500
        }, {
            'symbol': 'UKTQ13TB001',
            'saleDate': 1698274800000,
            'purchasePrice': 136000,
            'ivValuation': 136111,
            'vpvValuation': 160000,
            'salePrice': 165000
        }, {
            'symbol': 'UKUB77JS001',
            'saleDate': 1697151600000,
            'purchasePrice': 290000,
            'ivValuation': 280000,
            'vpvValuation': 280000,
            'salePrice': 282500
        }, {
            'symbol': 'UKM278BG001',
            'saleDate': 1698969600000,
            'purchasePrice': 92500,
            'ivValuation': 106667,
            'vpvValuation': 125000,
            'salePrice': 130000
        }, {
            'symbol': 'UKNE52EX001',
            'saleDate': 1699488000000,
            'purchasePrice': 111000,
            'ivValuation': 103419,
            'vpvValuation': 115000,
            'salePrice': 115000
        }, {
            'symbol': 'UKPO119NQ001',
            'saleDate': 1699574400000,
            'purchasePrice': 118725,
            'ivValuation': 125173,
            'vpvValuation': 147500,
            'salePrice': 170000
        }, {
            'symbol': 'UKSK153AN001',
            'saleDate': 1699574400000,
            'purchasePrice': 88491,
            'ivValuation': 98697,
            'vpvValuation': 115000,
            'salePrice': 120000
        }, {
            'symbol': 'UKTF93HP001',
            'saleDate': 1701302400000,
            'purchasePrice': 84000,
            'ivValuation': 90000,
            'vpvValuation': 100000,
            'salePrice': 107000
        }, {
            'symbol': 'UKSL85BB001',
            'saleDate': 1700179200000,
            'purchasePrice': 340000,
            'ivValuation': 306667,
            'vpvValuation': 350000,
            'salePrice': 350000
        }, {
            'symbol': 'UKTF93HP001',
            'saleDate': 1695942000000,
            'purchasePrice': 84000,
            'ivValuation': 90000,
            'vpvValuation': 100000,
            'salePrice': 105000
        }, {
            'symbol': 'UKUB69BE001',
            'saleDate': 1701302400000,
            'purchasePrice': 360500,
            'ivValuation': 262500,
            'vpvValuation': 350000,
            'salePrice': 335000
        }, {
            'symbol': 'UKLN106SQ',
            'saleDate': 1700784000000,
            'purchasePrice': 139000,
            'ivValuation': 190966,
            'vpvValuation': 225000,
            'salePrice': 213750
        }, {
            'symbol': 'UKNN15AA001',
            'saleDate': 1701216000000,
            'purchasePrice': 134562,
            'ivValuation': 116941,
            'vpvValuation': 130000,
            'salePrice': 132500
        }, {
            'symbol': 'UKBN124FN001',
            'saleDate': 1702252800000,
            'purchasePrice': 332500,
            'ivValuation': 346250,
            'vpvValuation': 365000,
            'salePrice': 375000
        }, {
            'symbol': 'UKCO11BA001',
            'saleDate': 1702598400000,
            'purchasePrice': 216600,
            'ivValuation': 174509,
            'vpvValuation': 205000,
            'salePrice': 180000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1702252800000,
            'purchasePrice': 76644,
            'ivValuation': 85266,
            'vpvValuation': 97000,
            'salePrice': 105000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1702339200000,
            'purchasePrice': 86916,
            'ivValuation': 96693,
            'vpvValuation': 110000,
            'salePrice': 117000
        }, {
            'symbol': 'UKNE52EX001',
            'saleDate': 1702252800000,
            'purchasePrice': 111000,
            'ivValuation': 103419,
            'vpvValuation': 115000,
            'salePrice': 120000
        }, {
            'symbol': 'UKCH11AF001',
            'saleDate': 1702598400000,
            'purchasePrice': 176447,
            'ivValuation': 169737,
            'vpvValuation': 200000,
            'salePrice': 193000
        }, {
            'symbol': 'UKM278BG001',
            'saleDate': 1706054400000,
            'purchasePrice': 90000,
            'ivValuation': 106667,
            'vpvValuation': 125000,
            'salePrice': 123750
        }, {
            'symbol': 'UKNW43DH001',
            'saleDate': 1704240000000,
            'purchasePrice': 549000,
            'ivValuation': 600000,
            'vpvValuation': 600000,
            'salePrice': 500000
        }, {
            'symbol': 'UKSK153AN001',
            'saleDate': 1706486400000,
            'purchasePrice': 86406,
            'ivValuation': 85824,
            'vpvValuation': 100000,
            'salePrice': 112500
        }, {
            'symbol': 'UKTQ13TB001',
            'saleDate': 1706227200000,
            'purchasePrice': 136000,
            'ivValuation': 136111,
            'vpvValuation': 160000,
            'salePrice': 162000
        }, {
            'symbol': 'UKUB77JS001',
            'saleDate': 1704844800000,
            'purchasePrice': 305000,
            'ivValuation': 290000,
            'vpvValuation': 290000,
            'salePrice': 293000
        }, {
            'symbol': 'UKWA27TE001',
            'saleDate': 1705449600000,
            'purchasePrice': 100000,
            'ivValuation': 109155,
            'vpvValuation': 125000,
            'salePrice': 133000
        }, {
            'symbol': 'UKWS115BH001',
            'saleDate': 1706227200000,
            'purchasePrice': 77500,
            'ivValuation': 101754,
            'vpvValuation': 120000,
            'salePrice': 125000
        }, {
            'symbol': 'UKYO124BY001',
            'saleDate': 1705622400000,
            'purchasePrice': 133000,
            'ivValuation': 152000,
            'vpvValuation': 190000,
            'salePrice': 180000
        }, {
            'symbol': 'UKS124WG001',
            'saleDate': 1706659200000,
            'purchasePrice': 93577,
            'ivValuation': 93462,
            'vpvValuation': 110000,
            'salePrice': 120000
        }, {
            'symbol': 'UKCO11BA001',
            'saleDate': 1706832000000,
            'purchasePrice': 147500,
            'ivValuation': 131946,
            'vpvValuation': 155000,
            'salePrice': 155000
        }, {
            'symbol': 'UKE18JX001',
            'saleDate': 1708041600000,
            'purchasePrice': 490000,
            'ivValuation': 485000,
            'vpvValuation': 485000,
            'salePrice': 460850
        }, {
            'symbol': 'UKNN15AA001',
            'saleDate': 1708300800000,
            'purchasePrice': 134562,
            'ivValuation': 116941,
            'vpvValuation': 130000,
            'salePrice': 130000
        }, {
            'symbol': 'UKSN22PE001',
            'saleDate': 1708473600000,
            'purchasePrice': 123125,
            'ivValuation': 91792,
            'vpvValuation': 108000,
            'salePrice': 108000
        }, {
            'symbol': 'UKW149SA001',
            'saleDate': 1708041600000,
            'purchasePrice': 570000,
            'ivValuation': 462264,
            'vpvValuation': 500000,
            'salePrice': 530000
        }, {
            'symbol': 'UKBN14JF001',
            'saleDate': 1710201600000,
            'purchasePrice': 285824,
            'ivValuation': 262941,
            'vpvValuation': 300000,
            'salePrice': 300000
        }, {
            'symbol': 'UKCH11AF001',
            'saleDate': 1710460800000,
            'purchasePrice': 176447,
            'ivValuation': 169737,
            'vpvValuation': 200000,
            'salePrice': 195500
        }, {
            'symbol': 'UKE18JX003',
            'saleDate': 1709251200000,
            'purchasePrice': 490000,
            'ivValuation': 382500,
            'vpvValuation': 475000,
            'salePrice': 465000
        }, {
            'symbol': 'UKE18JX004',
            'saleDate': 1711497600000,
            'purchasePrice': 730000,
            'ivValuation': 509845,
            'vpvValuation': 600000,
            'salePrice': 600000
        }, {
            'symbol': 'UKLN58JT001',
            'saleDate': 1710374400000,
            'purchasePrice': 72693,
            'ivValuation': 80871,
            'vpvValuation': 92000,
            'salePrice': 90000
        }, {
            'symbol': 'UKSE165AD004',
            'saleDate': 1709856000000,
            'purchasePrice': 515200,
            'ivValuation': 485362,
            'vpvValuation': 510000,
            'salePrice': 405000
        }, {
            'symbol': 'UKSK153AN001',
            'saleDate': 1709251200000,
            'purchasePrice': 90760,
            'ivValuation': 98697,
            'vpvValuation': 115000,
            'salePrice': 117000
        }, {
            'symbol': 'UKSW112PF001',
            'saleDate': 1711324800000,
            'purchasePrice': 509441,
            'ivValuation': 403067,
            'vpvValuation': 450000,
            'salePrice': 440000
        }, {
            'symbol': 'UKUB34BQ001',
            'saleDate': 1711065600000,
            'purchasePrice': 205000,
            'ivValuation': 215000,
            'vpvValuation': 215000,
            'salePrice': 225000
        }, {
            'symbol': 'UKW149SA001',
            'saleDate': 1710806400000,
            'purchasePrice': 325000,
            'ivValuation': 277358,
            'vpvValuation': 300000,
            'salePrice': 290000
        }, {
            'symbol': 'UKWS115BH001',
            'saleDate': 1711584000000,
            'purchasePrice': 77500,
            'ivValuation': 101754,
            'vpvValuation': 120000,
            'salePrice': 130000
        }];

        vm.fiveYearUnitSaleTotal = {
            purchasePrice: R.compose(R.sum, R.map(R.prop(['purchasePrice'])))(fiveYearUnitSaleData),
            salePrice: R.compose(R.sum, R.map(R.prop(['salePrice'])))(fiveYearUnitSaleData),
            ivValuation: R.compose(R.sum, R.map(R.prop(['ivValuation'])))(fiveYearUnitSaleData),
            vpvValuation: R.compose(R.sum, R.map(R.prop(['vpvValuation'])))(fiveYearUnitSaleData)
        };

        vm.fiveYearCompleteData = [{
            'symbol': 'TW45BU001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 3.7,
            'latestValuation': 308750,
            'purchasePrice': 243000,
            'ivValuation': 308750,
            'vpvValuation': 325000,
            'salePrice': 322500,
            'dividendReturn': 0.12,
            'capitalReturn': 0.19,
            'totalReturn': 0.31,
            'annualisedTotalReturn': 0.083
        }, {
            'symbol': 'UKE125AH001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 3.6,
            'latestValuation': 213750,
            'purchasePrice': 165000,
            'ivValuation': 213750,
            'vpvValuation': 225000,
            'salePrice': 235000,
            'dividendReturn': 0.13,
            'capitalReturn': 0.3,
            'totalReturn': 0.43,
            'annualisedTotalReturn': 0.118
        }, {
            'symbol': 'UKSE182NL001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 3.6,
            'latestValuation': 308750,
            'purchasePrice': 260000,
            'ivValuation': 308750,
            'vpvValuation': 325000,
            'salePrice': 326000,
            'dividendReturn': 0.09,
            'capitalReturn': 0.18,
            'totalReturn': 0.27,
            'annualisedTotalReturn': 0.076
        }, {
            'symbol': 'UKRM13LW001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6,
            'latestValuation': 213750,
            'purchasePrice': 185000,
            'ivValuation': 213750,
            'vpvValuation': 225000,
            'salePrice': 230000,
            'dividendReturn': 0.14,
            'capitalReturn': 0.021,
            'totalReturn': 0.161,
            'annualisedTotalReturn': 0.027
        }, {
            'symbol': 'UKRM112EX001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6,
            'latestValuation': 261250,
            'purchasePrice': 225000,
            'ivValuation': 261250,
            'vpvValuation': 275000,
            'salePrice': 266000,
            'dividendReturn': 0.127,
            'capitalReturn': 0.081,
            'totalReturn': 0.208,
            'annualisedTotalReturn': 0.035
        }, {
            'symbol': 'UKSE181PN001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.9,
            'latestValuation': 166250,
            'purchasePrice': 165000,
            'ivValuation': 166250,
            'vpvValuation': 175000,
            'salePrice': 175000,
            'dividendReturn': 0.16,
            'capitalReturn': -0.069,
            'totalReturn': 0.091,
            'annualisedTotalReturn': 0.015
        }, {
            'symbol': 'UKGU227RL001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.2,
            'latestValuation': 251750,
            'purchasePrice': 280000,
            'ivValuation': 251750,
            'vpvValuation': 265000,
            'salePrice': 265000,
            'dividendReturn': 0.141,
            'capitalReturn': -0.153,
            'totalReturn': -0.013,
            'annualisedTotalReturn': -0.002
        }, {
            'symbol': 'UKSE280JX001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6,
            'latestValuation': 275500,
            'purchasePrice': 245000,
            'ivValuation': 275500,
            'vpvValuation': 290000,
            'salePrice': 305000,
            'dividendReturn': 0.168,
            'capitalReturn': 0.124,
            'totalReturn': 0.292,
            'annualisedTotalReturn': 0.049
        }, {
            'symbol': 'UKSE181PN002',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.9,
            'latestValuation': 166250,
            'purchasePrice': 165000,
            'ivValuation': 166250,
            'vpvValuation': 175000,
            'salePrice': 182500,
            'dividendReturn': 0.149,
            'capitalReturn': -0.059,
            'totalReturn': 0.09,
            'annualisedTotalReturn': 0.015
        }, {
            'symbol': 'UKIG14AZ001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.3,
            'latestValuation': 190000,
            'purchasePrice': 182500,
            'ivValuation': 190000,
            'vpvValuation': 200000,
            'salePrice': 210000,
            'dividendReturn': 0.156,
            'capitalReturn': -0.041,
            'totalReturn': 0.115,
            'annualisedTotalReturn': 0.018
        }, {
            'symbol': 'UKKT147RX001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.3,
            'latestValuation': 266000,
            'purchasePrice': 204540,
            'ivValuation': 266000,
            'vpvValuation': 280000,
            'salePrice': 257000,
            'dividendReturn': 0.177,
            'capitalReturn': 0.094,
            'totalReturn': 0.271,
            'annualisedTotalReturn': 0.043
        }, {
            'symbol': 'UKUB78AT001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.4,
            'latestValuation': 209000,
            'purchasePrice': 197500,
            'ivValuation': 209000,
            'vpvValuation': 220000,
            'salePrice': 230000,
            'dividendReturn': 0.14,
            'capitalReturn': 0.003,
            'totalReturn': 0.143,
            'annualisedTotalReturn': 0.022
        }, {
            'symbol': 'UKRM11BJ001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.3,
            'latestValuation': 242250,
            'purchasePrice': 250000,
            'ivValuation': 242250,
            'vpvValuation': 255000,
            'salePrice': 255000,
            'dividendReturn': 0.12,
            'capitalReturn': -0.151,
            'totalReturn': -0.03,
            'annualisedTotalReturn': -0.005
        }, {
            'symbol': 'UKUB77DL001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.4,
            'latestValuation': 247000,
            'purchasePrice': 265000,
            'ivValuation': 247000,
            'vpvValuation': 260000,
            'salePrice': 247000,
            'dividendReturn': 0.126,
            'capitalReturn': -0.181,
            'totalReturn': -0.055,
            'annualisedTotalReturn': -0.009
        }, {
            'symbol': 'UKSE288EU001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.5,
            'latestValuation': 237500,
            'purchasePrice': 230000,
            'ivValuation': 237500,
            'vpvValuation': 250000,
            'salePrice': 260000,
            'dividendReturn': 0.149,
            'capitalReturn': 0.053,
            'totalReturn': 0.202,
            'annualisedTotalReturn': 0.031
        }, {
            'symbol': 'UKRM12DX001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.6,
            'latestValuation': 180500,
            'purchasePrice': 161000,
            'ivValuation': 180500,
            'vpvValuation': 190000,
            'salePrice': 188000,
            'dividendReturn': 0.152,
            'capitalReturn': 0.038,
            'totalReturn': 0.19,
            'annualisedTotalReturn': 0.029
        }, {
            'symbol': 'UKSE288RJ001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.4,
            'latestValuation': 308750,
            'purchasePrice': 275000,
            'ivValuation': 308750,
            'vpvValuation': 325000,
            'salePrice': 338000,
            'dividendReturn': 0.149,
            'capitalReturn': 0.149,
            'totalReturn': 0.298,
            'annualisedTotalReturn': 0.047
        }, {
            'symbol': 'UKW73HT001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.3,
            'latestValuation': 836000,
            'purchasePrice': 800000,
            'ivValuation': 836000,
            'vpvValuation': 880000,
            'salePrice': 865000,
            'dividendReturn': 0.091,
            'capitalReturn': -0.016,
            'totalReturn': 0.075,
            'annualisedTotalReturn': 0.012
        }, {
            'symbol': 'UKW72HQ001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.6,
            'latestValuation': 631750,
            'purchasePrice': 560000,
            'ivValuation': 631750,
            'vpvValuation': 665000,
            'salePrice': 655000,
            'dividendReturn': 0.118,
            'capitalReturn': 0.085,
            'totalReturn': 0.202,
            'annualisedTotalReturn': 0.031
        }, {
            'symbol': 'UKE79RU001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.6,
            'latestValuation': 389500,
            'purchasePrice': 343000,
            'ivValuation': 389500,
            'vpvValuation': 410000,
            'salePrice': 395000,
            'dividendReturn': 0.127,
            'capitalReturn': 0.05,
            'totalReturn': 0.177,
            'annualisedTotalReturn': 0.027
        }, {
            'symbol': 'UKW36YE001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.7,
            'latestValuation': 285000,
            'purchasePrice': 335000,
            'ivValuation': 285000,
            'vpvValuation': 300000,
            'salePrice': 300000,
            'dividendReturn': 0.115,
            'capitalReturn': -0.187,
            'totalReturn': -0.072,
            'annualisedTotalReturn': -0.011
        }, {
            'symbol': 'UKRM11EZ001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 7,
            'latestValuation': 237000,
            'purchasePrice': 230000,
            'ivValuation': 237000,
            'vpvValuation': 250000,
            'salePrice': 255000,
            'dividendReturn': 0.132,
            'capitalReturn': 0.032,
            'totalReturn': 0.164,
            'annualisedTotalReturn': 0.023
        }, {
            'symbol': 'UKW73PA001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.9,
            'latestValuation': 530000,
            'purchasePrice': 560000,
            'ivValuation': 530000,
            'vpvValuation': 560000,
            'salePrice': 530000,
            'dividendReturn': 0.1,
            'capitalReturn': -0.169,
            'totalReturn': -0.069,
            'annualisedTotalReturn': -0.01
        }, {
            'symbol': 'UKRM25US001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 7.5,
            'latestValuation': 382500,
            'purchasePrice': 357000,
            'ivValuation': 382500,
            'vpvValuation': 425000,
            'salePrice': 463000,
            'dividendReturn': 0.133,
            'capitalReturn': 0.165,
            'totalReturn': 0.298,
            'annualisedTotalReturn': 0.04
        }, {
            'symbol': 'UKSE165AD003',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 7.6,
            'latestValuation': 460000,
            'purchasePrice': 506000,
            'ivValuation': 460000,
            'vpvValuation': 510000,
            'salePrice': 465000,
            'dividendReturn': 0.085,
            'capitalReturn': -0.245,
            'totalReturn': -0.16,
            'annualisedTotalReturn': -0.021
        }, {
            'symbol': 'UKTN355ED001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 8.2,
            'latestValuation': 1200000,
            'purchasePrice': 1045000,
            'ivValuation': 1200000,
            'vpvValuation': 1260000,
            'salePrice': 1281500,
            'dividendReturn': 0.138,
            'capitalReturn': 0.245,
            'totalReturn': 0.384,
            'annualisedTotalReturn': 0.047
        }, {
            'symbol': 'UKTQ13TB001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 8.1,
            'latestValuation': 1225000,
            'purchasePrice': 1225000,
            'ivValuation': 1225000,
            'vpvValuation': 1440000,
            'salePrice': 1459000,
            'dividendReturn': 0.115,
            'capitalReturn': 0.001,
            'totalReturn': 0.116,
            'annualisedTotalReturn': 0.014
        }, {
            'symbol': 'UKE18JX001',
            'sellingLevel': 'Whole property',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 8.6,
            'latestValuation': 485000,
            'purchasePrice': 490000,
            'ivValuation': 485000,
            'vpvValuation': 485000,
            'salePrice': 460850,
            'dividendReturn': 0.142,
            'capitalReturn': -0.189,
            'totalReturn': -0.047,
            'annualisedTotalReturn': -0.005
        }];

        vm.discretionarySalesData = [{
            'symbol': 'UKPO409GD001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 1,
            'latestValuation': 279774,
            'purchasePrice': 177000,
            'ivValuation': 279774,
            'vpvValuation': 400000,
            'salePrice': 399000,
            'dividendReturn': 0.023,
            'capitalReturn': 0.957,
            'totalReturn': 0.98,
            'annualisedTotalReturn': 0.98
        }, {
            'symbol': 'UKNN15AA001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 3,
            'latestValuation': 115686,
            'purchasePrice': 114000,
            'ivValuation': 115686,
            'vpvValuation': 125000,
            'salePrice': 135000,
            'dividendReturn': 0.066,
            'capitalReturn': 0.057,
            'totalReturn': 0.123,
            'annualisedTotalReturn': 0.041
        }, {
            'symbol': 'UKDN211JA001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.4,
            'latestValuation': 48609,
            'purchasePrice': 39000,
            'ivValuation': 48609,
            'vpvValuation': 55000,
            'salePrice': 57500,
            'dividendReturn': 0.234,
            'capitalReturn': 0.685,
            'totalReturn': 0.918,
            'annualisedTotalReturn': 0.169
        }, {
            'symbol': 'UKDN211JA001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.4,
            'latestValuation': 61865,
            'purchasePrice': 47750,
            'ivValuation': 61865,
            'vpvValuation': 70000,
            'salePrice': 70000,
            'dividendReturn': 0.234,
            'capitalReturn': 0.658,
            'totalReturn': 0.892,
            'annualisedTotalReturn': 0.165
        }, {
            'symbol': 'UKPO119NQ001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.4,
            'latestValuation': 116686,
            'purchasePrice': 118627,
            'ivValuation': 116686,
            'vpvValuation': 137500,
            'salePrice': 146000,
            'dividendReturn': 0.149,
            'capitalReturn': 0.275,
            'totalReturn': 0.423,
            'annualisedTotalReturn': 0.078
        }, {
            'symbol': 'UKSN22PE001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 4.3,
            'latestValuation': 100446,
            'purchasePrice': 123125,
            'ivValuation': 100446,
            'vpvValuation': 112500,
            'salePrice': 110000,
            'dividendReturn': 0.101,
            'capitalReturn': -0.344,
            'totalReturn': -0.243,
            'annualisedTotalReturn': -0.057
        }, {
            'symbol': 'UKDN212HZ001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 4.2,
            'latestValuation': 54831,
            'purchasePrice': 50500,
            'ivValuation': 54831,
            'vpvValuation': 60000,
            'salePrice': 55000,
            'dividendReturn': 0.133,
            'capitalReturn': -0.044,
            'totalReturn': 0.089,
            'annualisedTotalReturn': 0.021
        }, {
            'symbol': 'UKSK153AN001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 4.1,
            'latestValuation': 72950,
            'purchasePrice': 81500,
            'ivValuation': 72950,
            'vpvValuation': 85000,
            'salePrice': 95000,
            'dividendReturn': 0.113,
            'capitalReturn': 0.09,
            'totalReturn': 0.203,
            'annualisedTotalReturn': 0.05
        }, {
            'symbol': 'UKNN15AA001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 3.4,
            'latestValuation': 134196,
            'purchasePrice': 137000,
            'ivValuation': 134196,
            'vpvValuation': 145000,
            'salePrice': 144500,
            'dividendReturn': 0.066,
            'capitalReturn': -0.061,
            'totalReturn': 0.005,
            'annualisedTotalReturn': 0.002
        }, {
            'symbol': 'UKWS115BH001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 4.1,
            'latestValuation': 89444,
            'purchasePrice': 81718,
            'ivValuation': 89444,
            'vpvValuation': 105000,
            'salePrice': 105000,
            'dividendReturn': 0.228,
            'capitalReturn': 0.127,
            'totalReturn': 0.355,
            'annualisedTotalReturn': 0.087
        }, {
            'symbol': 'UKM34NH001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.4,
            'latestValuation': 121337,
            'purchasePrice': 133000,
            'ivValuation': 121337,
            'vpvValuation': 135000,
            'salePrice': 135000,
            'dividendReturn': 0.13,
            'capitalReturn': -0.1,
            'totalReturn': 0.03,
            'annualisedTotalReturn': 0.006
        }, {
            'symbol': 'UKTF93HP001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.6,
            'latestValuation': 87750,
            'purchasePrice': 84000,
            'ivValuation': 87750,
            'vpvValuation': 97500,
            'salePrice': 100000,
            'dividendReturn': 0.186,
            'capitalReturn': 0.182,
            'totalReturn': 0.368,
            'annualisedTotalReturn': 0.065
        }, {
            'symbol': 'UKB974JS',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5,
            'latestValuation': 105948,
            'purchasePrice': 86500,
            'ivValuation': 105948,
            'vpvValuation': 115000,
            'salePrice': 115000,
            'dividendReturn': 0.142,
            'capitalReturn': 0.287,
            'totalReturn': 0.429,
            'annualisedTotalReturn': 0.086
        }, {
            'symbol': 'UKSE165AD001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.7,
            'latestValuation': 432312,
            'purchasePrice': 515000,
            'ivValuation': 432312,
            'vpvValuation': 500000,
            'salePrice': 520000,
            'dividendReturn': 0.069,
            'capitalReturn': -0.107,
            'totalReturn': -0.038,
            'annualisedTotalReturn': -0.007
        }, {
            'symbol': 'UKBA114EH001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 4.3,
            'latestValuation': 245250,
            'purchasePrice': 234000,
            'ivValuation': 245250,
            'vpvValuation': 272500,
            'salePrice': 270100,
            'dividendReturn': 0.091,
            'capitalReturn': 0.083,
            'totalReturn': 0.174,
            'annualisedTotalReturn': 0.041
        }, {
            'symbol': 'UKB634DF001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 4.9,
            'latestValuation': 162000,
            'purchasePrice': 142000,
            'ivValuation': 162000,
            'vpvValuation': 180000,
            'salePrice': 195000,
            'dividendReturn': 0.116,
            'capitalReturn': 0.426,
            'totalReturn': 0.542,
            'annualisedTotalReturn': 0.111
        }, {
            'symbol': 'UKS11WF001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 4.5,
            'latestValuation': 94500,
            'purchasePrice': 95700,
            'ivValuation': 94500,
            'vpvValuation': 105000,
            'salePrice': 105000,
            'dividendReturn': 0.076,
            'capitalReturn': -0.02,
            'totalReturn': 0.056,
            'annualisedTotalReturn': 0.013
        }, {
            'symbol': 'UKCO11BA002',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6,
            'latestValuation': 175764,
            'purchasePrice': 174000,
            'ivValuation': 175764,
            'vpvValuation': 195000,
            'salePrice': 170000,
            'dividendReturn': 0.11,
            'capitalReturn': -0.205,
            'totalReturn': -0.095,
            'annualisedTotalReturn': -0.016
        }, {
            'symbol': 'UKLN58JT001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.8,
            'latestValuation': 137129,
            'purchasePrice': 108000,
            'ivValuation': 137129,
            'vpvValuation': 156000,
            'salePrice': 156000,
            'dividendReturn': 0.2,
            'capitalReturn': 0.532,
            'totalReturn': 0.732,
            'annualisedTotalReturn': 0.126
        }, {
            'symbol': 'UKLN58JT001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.6,
            'latestValuation': 85266,
            'purchasePrice': 87000,
            'ivValuation': 85266,
            'vpvValuation': 97000,
            'salePrice': 97000,
            'dividendReturn': 0.2,
            'capitalReturn': 0.085,
            'totalReturn': 0.285,
            'annualisedTotalReturn': 0.051
        }, {
            'symbol': 'UKLN58JT001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.6,
            'latestValuation': 101088,
            'purchasePrice': 77000,
            'ivValuation': 101088,
            'vpvValuation': 115000,
            'salePrice': 107500,
            'dividendReturn': 0.202,
            'capitalReturn': 0.457,
            'totalReturn': 0.658,
            'annualisedTotalReturn': 0.118
        }, {
            'symbol': 'UKTN342RD001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.6,
            'latestValuation': 248709,
            'purchasePrice': 248400,
            'ivValuation': 248709,
            'vpvValuation': 275000,
            'salePrice': 285000,
            'dividendReturn': 0.105,
            'capitalReturn': 0.128,
            'totalReturn': 0.233,
            'annualisedTotalReturn': 0.041
        }, {
            'symbol': 'UKDN211JA001',
            'sellingLevel': '2 units',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6,
            'latestValuation': 110474,
            'purchasePrice': 78000,
            'ivValuation': 110474,
            'vpvValuation': 125000,
            'salePrice': 130000,
            'dividendReturn': 0.233,
            'capitalReturn': 0.854,
            'totalReturn': 1.086,
            'annualisedTotalReturn': 0.18
        }, {
            'symbol': 'UKYO124BY001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.1,
            'latestValuation': 111915,
            'purchasePrice': 105250,
            'ivValuation': 111915,
            'vpvValuation': 160000,
            'salePrice': 170000,
            'dividendReturn': 0.136,
            'capitalReturn': 0.818,
            'totalReturn': 0.954,
            'annualisedTotalReturn': 0.188
        }, {
            'symbol': 'UKPO409GD001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 3.7,
            'latestValuation': 402175,
            'purchasePrice': 200000,
            'ivValuation': 402175,
            'vpvValuation': 575000,
            'salePrice': 575000,
            'dividendReturn': 0.123,
            'capitalReturn': 2.224,
            'totalReturn': 2.347,
            'annualisedTotalReturn': 0.636
        }, {
            'symbol': 'UKNN15AA001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 3.9,
            'latestValuation': 134196,
            'purchasePrice': 136000,
            'ivValuation': 134196,
            'vpvValuation': 145000,
            'salePrice': 145000,
            'dividendReturn': 0.066,
            'capitalReturn': -0.04,
            'totalReturn': 0.026,
            'annualisedTotalReturn': 0.007
        }, {
            'symbol': 'UKUB69BE001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.4,
            'latestValuation': 280500,
            'purchasePrice': 379950,
            'ivValuation': 280500,
            'vpvValuation': 330000,
            'salePrice': 349000,
            'dividendReturn': 0.085,
            'capitalReturn': -0.263,
            'totalReturn': -0.178,
            'annualisedTotalReturn': -0.028
        }, {
            'symbol': 'UKBN21QA001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5,
            'latestValuation': 209091,
            'purchasePrice': 236850,
            'ivValuation': 209091,
            'vpvValuation': 230000,
            'salePrice': 228500,
            'dividendReturn': 0.095,
            'capitalReturn': -0.207,
            'totalReturn': -0.112,
            'annualisedTotalReturn': -0.022
        }, {
            'symbol': 'UKB974JS',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.8,
            'latestValuation': 119767,
            'purchasePrice': 105000,
            'ivValuation': 119767,
            'vpvValuation': 130000,
            'salePrice': 135000,
            'dividendReturn': 0.161,
            'capitalReturn': 0.259,
            'totalReturn': 0.419,
            'annualisedTotalReturn': 0.073
        }, {
            'symbol': 'UKDN211JA001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.8,
            'latestValuation': 68494,
            'purchasePrice': 47750,
            'ivValuation': 68494,
            'vpvValuation': 77500,
            'salePrice': 80000,
            'dividendReturn': 0.295,
            'capitalReturn': 1.011,
            'totalReturn': 1.306,
            'annualisedTotalReturn': 0.193
        }, {
            'symbol': 'UKW149SA001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.4,
            'latestValuation': 454750,
            'purchasePrice': 570000,
            'ivValuation': 454750,
            'vpvValuation': 535000,
            'salePrice': 525000,
            'dividendReturn': 0.071,
            'capitalReturn': -0.257,
            'totalReturn': -0.186,
            'annualisedTotalReturn': -0.029
        }, {
            'symbol': 'UKTQ13PL001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.9,
            'latestValuation': 121688,
            'purchasePrice': 128000,
            'ivValuation': 121688,
            'vpvValuation': 135000,
            'salePrice': 170000,
            'dividendReturn': 0.13,
            'capitalReturn': 0.325,
            'totalReturn': 0.456,
            'annualisedTotalReturn': 0.078
        }, {
            'symbol': 'UKDN211JA001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 7,
            'latestValuation': 68494,
            'purchasePrice': 49902,
            'ivValuation': 68494,
            'vpvValuation': 77500,
            'salePrice': 77500,
            'dividendReturn': 0.286,
            'capitalReturn': 0.905,
            'totalReturn': 1.191,
            'annualisedTotalReturn': 0.17
        }, {
            'symbol': 'UKUB69BE001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.7,
            'latestValuation': 280500,
            'purchasePrice': 365703,
            'ivValuation': 280500,
            'vpvValuation': 330000,
            'salePrice': 335000,
            'dividendReturn': 0.08,
            'capitalReturn': -0.271,
            'totalReturn': -0.191,
            'annualisedTotalReturn': -0.029
        }, {
            'symbol': 'UKB974JS',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.3,
            'latestValuation': 119767,
            'purchasePrice': 105000,
            'ivValuation': 119767,
            'vpvValuation': 130000,
            'salePrice': 135000,
            'dividendReturn': 0.159,
            'capitalReturn': 0.26,
            'totalReturn': 0.418,
            'annualisedTotalReturn': 0.066
        }, {
            'symbol': 'UKL236UW001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.8,
            'latestValuation': 182536,
            'purchasePrice': 183500,
            'ivValuation': 182536,
            'vpvValuation': 215000,
            'salePrice': 245000,
            'dividendReturn': 0.14,
            'capitalReturn': 0.426,
            'totalReturn': 0.566,
            'annualisedTotalReturn': 0.097
        }, {
            'symbol': 'UKWF29UF001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.3,
            'latestValuation': 87585,
            'purchasePrice': 80553,
            'ivValuation': 87585,
            'vpvValuation': 97500,
            'salePrice': 97500,
            'dividendReturn': 0.183,
            'capitalReturn': 0.127,
            'totalReturn': 0.31,
            'annualisedTotalReturn': 0.058
        }, {
            'symbol': 'UKM278BG001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.2,
            'latestValuation': 99558,
            'purchasePrice': 90500,
            'ivValuation': 99558,
            'vpvValuation': 112500,
            'salePrice': 130000,
            'dividendReturn': 0.161,
            'capitalReturn': 0.476,
            'totalReturn': 0.636,
            'annualisedTotalReturn': 0.103
        }, {
            'symbol': 'UKSN22PE001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6,
            'latestValuation': 93571,
            'purchasePrice': 123125,
            'ivValuation': 93571,
            'vpvValuation': 110000,
            'salePrice': 110000,
            'dividendReturn': 0.119,
            'capitalReturn': -0.344,
            'totalReturn': -0.226,
            'annualisedTotalReturn': -0.037
        }, {
            'symbol': 'UKBN14JF001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 5.9,
            'latestValuation': 334302,
            'purchasePrice': 329797,
            'ivValuation': 334302,
            'vpvValuation': 375000,
            'salePrice': 375000,
            'dividendReturn': 0.171,
            'capitalReturn': 0.08,
            'totalReturn': 0.25,
            'annualisedTotalReturn': 0.043
        }, {
            'symbol': 'UKWS115BH001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6,
            'latestValuation': 93649,
            'purchasePrice': 77750,
            'ivValuation': 93649,
            'vpvValuation': 105000,
            'salePrice': 105000,
            'dividendReturn': 0.186,
            'capitalReturn': 0.328,
            'totalReturn': 0.514,
            'annualisedTotalReturn': 0.086
        }, {
            'symbol': 'UKBN32RH',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.7,
            'latestValuation': 246837,
            'purchasePrice': 250151,
            'ivValuation': 246837,
            'vpvValuation': 275000,
            'salePrice': 282500,
            'dividendReturn': 0.113,
            'capitalReturn': 0.04,
            'totalReturn': 0.153,
            'annualisedTotalReturn': 0.023
        }, {
            'symbol': 'UKWS115BH001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.2,
            'latestValuation': 93649,
            'purchasePrice': 77500,
            'ivValuation': 93649,
            'vpvValuation': 105000,
            'salePrice': 100000,
            'dividendReturn': 0.186,
            'capitalReturn': 0.247,
            'totalReturn': 0.433,
            'annualisedTotalReturn': 0.069
        }, {
            'symbol': 'UKWS115BH001',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 6.3,
            'latestValuation': 93649,
            'purchasePrice': 77500,
            'ivValuation': 93649,
            'vpvValuation': 105000,
            'salePrice': 105000,
            'dividendReturn': 0.186,
            'capitalReturn': 0.336,
            'totalReturn': 0.522,
            'annualisedTotalReturn': 0.082
        }, {
            'symbol': 'UKCO11BA002',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 7.4,
            'latestValuation': 161379,
            'purchasePrice': 232500,
            'ivValuation': 161379,
            'vpvValuation': 180000,
            'salePrice': 185000,
            'dividendReturn': 0.11,
            'capitalReturn': -0.52,
            'totalReturn': -0.41,
            'annualisedTotalReturn': -0.056
        }, {
            'symbol': 'UKBN32RH',
            'sellingLevel': '1 unit',
            'status': 'Sold',
            'isCompleted': true,
            'yearsOnPlatform': 7.1,
            'latestValuation': 246837,
            'purchasePrice': 250151,
            'ivValuation': 246837,
            'vpvValuation': 275000,
            'salePrice': 280000,
            'dividendReturn': 0.113,
            'capitalReturn': 0.034,
            'totalReturn': 0.148,
            'annualisedTotalReturn': 0.021
        }];

        vm.fiveYearCompleteTotal = {
            'totalReturn': 0.126,
            'dividendReturn': 0.126,
            'capitalReturn': 0,
            'yearsOnPlatform': 6.7,
            'annualisedTotalReturn': 0.019,
            'purchasePrice': 10149540,
            'salePrice': 11421350,
            'ivValuation': 10708750,
            'vpvValuation': 11445000
        };

        vm.discretionarySalesTotal = {
            'totalReturn': 0.247,
            'dividendReturn': 0.116,
            'capitalReturn': 0.13,
            'yearsOnPlatform': 5.5,
            'annualisedTotalReturn': 0.045,
            'purchasePrice': 7235251,
            'salePrice': 8642600,
            'ivValuation': 7286904,
            'vpvValuation': 8473000
        };

        vm.total = {
            'totalReturn': 0.163,
            'dividendReturn': 0.123,
            'capitalReturn': 0.04,
            'yearsOnPlatform': 6.3,
            'annualisedTotalReturn': 0.026,
            'purchasePrice': 17384781,
            'salePrice': 20063950,
            'ivValuation': 17845202,
            'vpvValuation': 19703000
        };

        // -- util functions

        function peformSort(attr, order) {
            var sort = R.sortBy(R.prop(attr));
            if (order === 'ascending') {
                return sort(fiveYearUnitSaleData);
            } else if (order === 'descending') {
                return R.compose(R.reverse, sort)(fiveYearUnitSaleData);
            } else {
                return R.clone(fiveYearUnitSaleData);
            }
        }

        function peformDateSort(attr, order) {
            var sort = R.sort(function (a, b) {
                var aDate = a.saleDate.getTime();
                var bDate = b.saleDate.getTime();
                return order === 'ascending' ? aDate - bDate : bDate - aDate;
            });
            return sort(fiveYearUnitSaleData);
        }

        function peformPropertySort(attr, order) {
            var sort = R.sort(function (a, b) {
                var aName = R.path([a.symbol, 'location', 'location1'], vm.propertiesDict);
                var bName = R.path([b.symbol, 'location', 'location1'], vm.propertiesDict);
                if (aName < bName) {
                    return order === 'ascending' ? -1 : 1;
                }

                if (bName < aName) {
                    return order === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            return sort(fiveYearUnitSaleData);
        }

        // -- api

        vm.updatePropertySort = function (attr, order) {
            vm.fiveYearUnitSaleData = peformPropertySort(attr, order);
            vm.sortOrder = order;
            vm.sortAttribute = attr;
        };

        vm.updateDateSort = function (attr, order) {
            vm.fiveYearUnitSaleData = peformDateSort(attr, order);
            vm.sortOrder = order;
            vm.sortAttribute = attr;
        };

        vm.updateSort = function (attr, order) {
            vm.fiveYearUnitSaleData = peformSort(attr, order);
            vm.sortOrder = order;
            vm.sortAttribute = attr;
        };

        vm.onViewPropertyDetail = function (property) {
            vm.viewPropertyDetail({
                property: property
            });
        };

        vm.getIndividualPropertyPath = routeService.getIndividualPropertyPath;

        // -- scope bindings

        // -- main
        vm.$onInit = function () {
            vm.fiveYearUnitSaleData = R.clone(fiveYearUnitSaleData);
        };

    }]
});
})(window, window.angular);