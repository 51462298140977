(function(window, angular){'use strict';

var ngModule = angular.module('pp.controllers.marketplace-router', [
    'ui.router',
    'pp.widgets.marketplace',
    'pp.widgets.marketplace-grid-view',
    'pp.widgets.marketplace-list-view',
    'pp.widgets.property-map',
    'pp.widgets.marketplace-data-view',
    'pp.widgets.marketplace-featured-view',
    'pp.widgets.selling-record'
]);

ngModule.config(['$stateProvider', '$urlRouterProvider', '$windowProvider', function ($stateProvider, $urlRouterProvider, $windowProvider) {

    var $window = $windowProvider.$get();
    var pathname = $window.location.pathname;

    // @todo remove this logic by splitting up app into smaller submodules and move to frontend routing
    // This is so the redirect does not happen on all other routes within the app
    if (pathname === '/s/uk' || pathname === '/s') {
        $stateProvider
            .state('marketplace', {
                abstract: true,
                component: 'ppMarketplace'
            })
            .state('marketplace.featured-view', {
                url: '/featured-view',
                views: {
                    'featured-view': {
                        component: 'ppMarketplaceFeaturedView'
                    }
                }
            })
            .state('marketplace.featured-view-scroll', {
                url: '/featured-view/sc/:scrollTo',
                views: {
                    'featured-view': {
                        component: 'ppMarketplaceFeaturedView'
                    }
                }
            })
            .state('marketplace.featured-view-scroll.open-property', {
                url: '/:propertySymbol',
                views: {
                    'featured-view': {
                        component: 'ppMarketplaceFeaturedView'
                    }
                }
            })
            .state('marketplace.featured-view.open-property', {
                url: '/:propertySymbol',
                views: {
                    'featured-view': {
                        component: 'ppMarketplaceFeaturedView'
                    }
                }
            })
            .state('marketplace.live-trading-view', {
                url: '/grid-view?filter',
                views: {
                    'grid-view': {
                        component: 'ppMarketplaceGridView'
                    }
                }
            })
            .state('marketplace.live-trading-view.open-property', {
                url: '/:propertySymbol',
                views: {
                    'featured-view': {
                        component: 'ppMarketplaceGridView'
                    }
                }
            })
            .state('marketplace.other-investments-view', {
                url: '/other-investments?filter',
                views: {
                    'grid-view': {
                        component: 'ppMarketplaceGridView'
                    }
                }
            })
            .state('marketplace.other-investments-view.open-property', {
                url: '/:propertySymbol',
                views: {
                    'grid-view': {
                        component: 'ppMarketplaceGridView'
                    }
                }
            })
            .state('marketplace.vote-fundraise', {
                url: '/vote-fundraise',
                views: {
                    'list-view': {
                        component: 'ppMarketplaceListView'
                    }
                }
            })
            .state('marketplace.vote-fundraise.open-property', {
                url: '/:propertySymbol',
                views: {
                    'list-view': {
                        component: 'ppMarketplaceListView'
                    }
                }
            })
            .state('marketplace.rights-issue', {
                url: '/equity-fundraise',
                redirectTo: 'marketplace.vote-fundraise'
            })
            .state('marketplace.map-view', {
                url: '/map-view',
                views: {
                    'map-view': {
                        component: 'ppPropertyMap'
                    }
                }
            })
            .state('marketplace.map-view.open-property', {
                url: '/:propertySymbol',
                views: {
                    'map-view': {
                        component: 'ppPropertyMap'
                    }
                }
            })
            .state('marketplace.data-view', {
                url: '/data-view',
                views: {
                    'data-view': {
                        component: 'ppMarketplaceDataView'
                    }
                }
            })
            .state('marketplace.data-view.open-property', {
                url: '/:propertySymbol',
                views: {
                    'data-view': {
                        component: 'ppMarketplaceDataView'
                    }
                }
            })
            .state('marketplace.selling-record-view', {
                url: '/selling-record',
                views: {
                    'selling-record-view': {
                        component: 'ppSellingRecord'
                    }
                }
            })
            .state('marketplace.selling-record-view.open-property', {
                url: '/:propertySymbol',
                views: {
                    'selling-record-view': {
                        component: 'ppSellingRecord'
                    }
                }
            });

        $urlRouterProvider.otherwise('/featured-view');
    }

}]);
})(window, window.angular);