(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.cookie-banner', ['pp.widgets-templates']);

ngModule.component('ppCookieBanner', {
    templateUrl: 'widgets/_angular/cookie-banner/cookie-banner.tpl.html',
    bindings: {
        onClose: '&?'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$document', function ($scope, $document) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.closeBanner = function () {
            if (vm.onClose) {
                vm.onClose();
            }
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);