(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.isa-transfer-in-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.widgets.isa-transfer-in'
]);

/**
 * @ndgoc service
 * @description
 * checks if we need to notify the user that they have been qualified/disqualified for an rff scheme
 * shows the dialog and sets a preference when the user dismisses it
 * so that the dialog is never shown again for the same scheme
 */
ngModule.service('isaTransferInDialogService', ['$rootScope', 'ppTrack', 'dialogService', function ($rootScope, ppTrack, dialogService) {

    // -- util functions

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(element) {
        window.pp.modal(element, {
            showClose: true,
            clickClose: true
        });
    }

    // -- api

    var api = {};

    api.show = function (element) {
        showDialog(element);
    };

    return api;
}]);
})(window, window.angular);