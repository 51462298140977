(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-sticker', ['pp.widgets-templates']);

/**
 * @ngdoc directive
 * @name ppPropertySticker
 * @description
 * Renders property geared/cashback stickers.
 *
 * @restrict A
 * @scope
 * @param {string} ppPropertySticker the sticker type (geared, cashabck)
 * @param {number} value the sticker value
 */
ngModule.directive('ppPropertySticker', function () {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-sticker/property-sticker.tpl.html',
        scope: {
            type: '@ppPropertySticker',
            value: '=',
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            vm.$onInit = function () {
                $scope.$watch('vm.type', function (val) {
                    vm.label = vm.type === 'geared' ? 'Geared' : 'Cashback';
                });
            };

            // -- main

        }]
    };
});
})(window, window.angular);