(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.bank-account-select', [
    'pp.widgets-templates',
    'pp.widgets.bank-account-display',
    'pp.ui.services.confirm-dialog',
    'pp.services.route'
]);

ngModule.component('ppBankAccountSelect', {
    templateUrl: 'widgets/_angular/bank-account-select/bank-account-select.tpl.html',
    bindings: {
        accounts: '<',
        openUpdateForm: '&',
        deleteAccount: '&',
        selectedAccount: '='
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', 'confirmDialogService', function ($scope, routeService, confirmDialogService) {
        var vm = this;

        // -- initial state

        vm.learnMoreBankAccountVerification = routeService.learnMoreBankAccountVerification;

        vm.config = {};

        // -- util functions

        function setConfig(id, key, value) {
            vm.config[id] = vm.config[id] || {};
            vm.config[id][key] = value;
        }

        // -- api

        vm.clickUpdate = function (account) {
            setConfig(account.id, 'deleteError', false);
            vm.openUpdateForm({
                account: account
            });
        };

        // This is not used at the moment might be reinstated
        // need to resolve issues with readding bank accounts
        // with same display name and account number after deletion
        vm.confirmDelete = function (account) {

            confirmDialogService.confirm(
                'delete-bank-account',
                'Are you sure you want to delete the bank account "' + account.accountDisplayName + '"?'
            ).then(function () {
                setConfig(account.id, 'processing', true);
                setConfig(account.id, 'deleteError', false);

                return vm.deleteAccount({
                    account: account
                });
            }, angular.noop).then(function () {
                setConfig(account.id, 'processing', false);
                setConfig(account.id, 'deleteError', false);
            }, function () {
                setConfig(account.id, 'processing', false);
                setConfig(account.id, 'deleteError', true);

            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);