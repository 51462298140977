(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-graphs', ['pp.widgets-templates', 'pp.widgets.shares-order-book-chart', 'pp.widgets.shares-traded-chart']);

var CHARTS_LOADING_TIMEOUT = 1000;

var PAST_PERFORMANCE_WARNING_HEIGHT = 40;

ngModule.directive('ppPropertyGraphs', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-graphs/property-graphs.tpl.html',
        scope: {
            symbol: '<ppPropertyGraphs',
            priceLimits: '<',
            shareValuation: '<',
            showCharts: '<',
            isFinished: '<',
            alwaysToggle: '<',
            property: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', '$element', '$window', function ($scope, $timeout, $element, $window) {
            var vm = this;

            // -- initial state

            // -- util

            function setChartHeight() {
                if (vm.showCharts) {
                    vm.chartsHeight = $($element.find('.charts')[0]).height() + PAST_PERFORMANCE_WARNING_HEIGHT;
                } else {
                    vm.chartsHeight = 0;
                }
            }

            function ngSetChartHeight() {
                $scope.$evalAsync(setChartHeight);
            }

            // -- api

            vm.toggleCharts = function () {

                // toggle in progress do not continue
                if (vm.chartsLoading || vm.overlay) {
                    return;
                }

                if (!vm.showCharts) {
                    vm.chartsLoading = true;
                    $timeout(function () {
                            vm.chartsLoading = false;
                            vm.showCharts = true;
                            vm.overlay = true;
                        }, CHARTS_LOADING_TIMEOUT)
                        .then(function () {
                            return $timeout(setChartHeight, 100);
                        }).then(function () {
                            $scope.$broadcast('highchartsng.reflow');

                            $timeout(function () {
                                vm.overlay = false;
                            }, 300);
                        });
                } else {

                    vm.showCharts = false;
                    setChartHeight();
                }
            };

            // -- scope bindings

            $($window).on('resize', ngSetChartHeight);

            // -- main

            vm.$onInit = function () {
                $timeout(function () {
                    $scope.$broadcast('highchartsng.reflow');
                }, 100).then(function () {
                    return $timeout(setChartHeight, 400);
                });
            };

            vm.$onDestroy = function () {
                $($window).off('resize', ngSetChartHeight);
            };

        }]
    };
}]);
})(window, window.angular);