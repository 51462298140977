(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.not-found', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.widgets.not-found-content'
]);

ngModule.component('ppNotFound', {
    templateUrl: 'widgets/_angular/not-found/not-found.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['ppTrack', 'investorService', function (ppTrack, investorService) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        investorService.getInvestor().then(function () {
            ppTrack.pageException('error', 'page-not-found');
        });
    }]
});
})(window, window.angular);