(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-details-secondary', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.filters.numbers',
    'pp.filters.dates',
    'pp.components.tooltip',
    'pp.widgets.aum-dividend-notice',
    'pp.values.glossary'
]);

var PREMIUM_LABEL = 'Premium';
var DISCOUNT_LABEL = 'Discount';

/**
 * @ngdoc directive
 * @name ppPropertyCardDetailsSecondary
 * @description
 * Renders the details for a secondary market property.
 *
 * @restrict A
 * @scope
 * @param {Object} ppPropertyCardDetailsSecondary the property model
 */
ngModule.directive('ppPropertyCardDetailsSecondary', function () {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-card-details-secondary/property-card-details-secondary.tpl.html',
        scope: {
            property: '<ppPropertyCardDetailsSecondary'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppUtil', 'glossary', 'ppComponentEmitter', function ($scope, ppUtil, glossary, ppComponentEmitter) {
            var vm = this;

            // -- initial state

            vm.netIncomeYieldSecondary = glossary.netIncomeYieldSecondary;

            vm.fiveYearHpi = glossary.fiveYearHpi;

            var __variantSet;

            // -- util functions

            function getDiscountPremiumLabel(property) {
                if (ppUtil.hasPathIn(property, 'market.premiumDiscount')) {
                    return property.market.premiumDiscount.premiumDiscountPct > 0 ? PREMIUM_LABEL : DISCOUNT_LABEL;
                } else {
                    return DISCOUNT_LABEL;
                }
            }

            function setVariant(showVariant) {

                if (__variantSet) {
                    return; // @note early return
                }

                $scope.$evalAsync(function () {
                    vm.showVariant = showVariant;
                });

                __variantSet = true;
            }

            vm.setVariant = setVariant;

            // -- api

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                ppComponentEmitter.emit('property-card-details-secondary.' + vm.property.symbol + '.loaded', vm);

                if (ppUtil.hasPathIn(glossary, 'premiumDiscountWithPlaceholder')) {
                    vm.premiumDiscountDesc = glossary.premiumDiscountWithPlaceholder.desc.replace('${type}', getDiscountPremiumLabel(vm.property));
                }
            };

        }]
    };
});
})(window, window.angular);