(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.button-buy', [
    'pp.widgets-templates',
    'pp.services.property',
    'pp.components.tooltip',
    'pp.values.glossary',
    'pp.services.core'
]);

var NO_SHARES_MESSAGE = 'buyButtonNoShares';
var NO_BONDS_MESSAGE = 'buyButtonNoBonds';
var ERROR_MESSAGE = 'buyButtonError';

ngModule.directive('ppButtonBuy', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/button-buy/button-buy.tpl.html',
        scope: {
            property: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$window', '$q', 'ppTrack', 'propertyService', 'glossary', 'R', function ($scope, $window, $q, ppTrack, propertyService, glossary, R) {
            var vm = this;

            // -- initial state

            vm.sharesAvailable = 0;
            vm.loading = true;
            vm.error = false;

            // -- util functions

            function areSharesAvailable() {
                if (propertyService.isPrimaryProperty(vm.property)) {
                    return $q.when(vm.property.market.primary.fundedPct < 100);
                } else {
                    return propertyService.getSecondaryAvailableShares(vm.property.symbol).then(function (sharesAvailable) {
                        return !!sharesAvailable;
                    });
                }
            }

            function handleLoaded() {
                vm.loading = false;
                if (!vm.sharesAvailable) {
                    if (vm.property.isDevelopmentLoan) {
                        vm.title = glossary[NO_BONDS_MESSAGE].desc;
                    } else {
                        vm.title = glossary[NO_SHARES_MESSAGE].desc;
                    }

                }
            }

            function loadAvailableShares() {
                vm.isPreorder = propertyService.isPreorderProperty(vm.property);
                vm.isPreorderClosed = propertyService.isPreorderClosedProperty(vm.property);
                vm.isPreview = propertyService.isPreviewProperty(vm.property);

                if (vm.isPreorder || vm.isPreview || vm.isPreorderClosed || vm.isSecondaryInformational) {
                    vm.sharesAvailable = 0;
                    handleLoaded();
                } else {
                    areSharesAvailable().then(function (sharesAvailable) {
                        vm.sharesAvailable = sharesAvailable;
                        handleLoaded();
                    }, handleLoaded);
                }
            }

            function update(property) {
                if (property) {
                    vm.isSecondaryInformational = propertyService.isSecondaryInformationalProperty(property);
                    loadAvailableShares();
                }
            }

            // -- api

            vm.trackButtonBuyClick = function () {
                ppTrack.closeAll();
            };

            // -- scope bindings

            vm.$onChanges = function (changes) {
                update(R.path(['property', 'currentValue'], changes));
            };

            // -- main

        }]
    };
}]);
})(window, window.angular);