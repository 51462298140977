(function(window, angular){'use strict';

var ngModule = angular.module('pp.controllers.student-lp', [
    'pp.services.core',
    'pp.services.investor',
    'pp.services.auth',
    'pp.widgets.what-to-expect',
    'pp.widgets.trust-hooks'
]);

var PAGE_NAME = 'student-lp';

ngModule.controller('studentLpCtrl', ['$scope', 'ppTrack', 'ppCookie', 'investorService', 'authService', 'ppPubSub', function ($scope, ppTrack, ppCookie, investorService, authService, ppPubSub) {
    var vm = this;

    vm.learnMoreUrl = '/blog/invest-purpose-built-student-accommodation/';

    // -- initial state

    // -- util functions

    function buildCookieString(cookie) {
        var result = '';
        if (cookie) {
            for (var key in cookie) {
                result += '%%' + key + '=' + cookie[key];
            }
        }

        return result;
    }

    // -- api

    vm.submitEmailSignup = function (email) {
        vm.processing = true;
        authService.emailSignup(email)
            .then(angular.noop, angular.noop)
            .finally(function () {
                vm.processing = false;
                ppPubSub.publish('auth.modal.show', ['signup', null, null, null, null, email]);
            });
    };

    vm.openVideoModal = function () {

        ppTrack.action('student-video.open');
        $('#student-video-modal').modal({
            showClose: true,
            escapeClose: true,
            clickClose: true
        });

    };

    // -- scope bindings

    // -- main

    var funnelCookie = ppCookie.get('pp_funnel');
    var parsedCookie = ppCookie.parse(funnelCookie);

    if (!parsedCookie.utm_data) {
        parsedCookie.utm_data = 'utm_source=property_partner,utm_medium=property_partner,utm_term=property_partner,utm_campaign=PP-LANDINGPAGE-STUDENT-PBSA';

        var returnCookie = buildCookieString(parsedCookie);

        if (returnCookie) {
            ppCookie.set('pp_funnel', returnCookie);
        }
    }

    investorService.getInvestor().then(function (user) {
        ppTrack.pageLoad(PAGE_NAME);
        vm.user = user;
        vm.ready = true;
    }, angular.noop);

}]);
})(window, window.angular);