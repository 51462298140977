(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.suspended-property-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.widgets.suspended-property-dialog'
]);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/suspended-property-dialog`.
 */
ngModule.service('suspendedPropertyDialogService', ['$rootScope', '$window', '$timeout', 'ppTrack', 'dialogService', function ($rootScope, $window, $timeout, ppTrack, dialogService) {

    // -- util functions

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(showDashboardBtn) {
        ppTrack.action('suspended-property.notification.show');
        var scope = $rootScope.$new();
        var options = {
            clickClose: true,
            escapeClose: true,
            showClose: true,
            scope: scope
        };

        scope.showDashboardBtn = showDashboardBtn;
        options.contents = '<div pp-suspended-property-dialog="showDashboardBtn"></div>';

        return $timeout(function () {
            return dialogService.show(options).then(function () {
                ppTrack.action('suspended-property.notification.dismiss');
                $window.location.reload();
            });
        });
    }

    // -- api

    var api = {};

    api.show = function (showDashboardBtn) {
        return showDialog(showDashboardBtn);
    };

    return api;
}]);
})(window, window.angular);