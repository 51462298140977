(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.schedule-call-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.widgets.schedule-a-call'
]);

var DIALOG_NAME = 'schedule-a-call';

/**
 * @ndgoc service
 * @description
 * checks if we need to notify the user that they have been qualified/disqualified for an rff scheme
 * shows the dialog and sets a preference when the user dismisses it
 * so that the dialog is never shown again for the same scheme
 */
ngModule.service('scheduleCallDialogService', ['$rootScope', 'ppTrack', 'dialogService', function ($rootScope, ppTrack, dialogService) {

    // -- util functions

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog() {
        ppTrack.action(DIALOG_NAME + '.open');

        var scope = $rootScope.$new();

        var options = {
            clickClose: true,
            escapeClose: true,
            showClose: true,
            classNames: 'pp-schedule-a-call-dialog',
            scope: scope
        };

        options.contents = '<pp-schedule-a-call></pp-schedule-a-call>';

        dialogService.show(options);
    }

    // -- api

    var api = {};

    api.show = function () {
        showDialog().then(function () {
            ppTrack.action(DIALOG_NAME + '.dismiss');
        });
    };

    return api;
}]);
})(window, window.angular);