(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.home-now-non-uk-blocked', ['pp.widgets-templates']);

ngModule.component('ppHomeNowNonUkBlocked', {
    templateUrl: 'widgets/_angular/home-now-non-uk-blocked/home-now-non-uk-blocked.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);