(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.bind-invalid-model', []);

/**
 * @ngdoc directive
 * @name ppBindInvalidModel
 * @description
 * Updates the the $viewValue of an `ng-model` to to
 *
 * This is yet another workaround to angular issue: https://github.com/angular/angular.js/issues/1412
 *
 * @restrict A
 * @scope
 * @priority 9999
 *
 * @param {string} ngModel Assignable angular expression to data-bind to.
 * @param {*} ppBindInvalidModelTo Update this binding when model's `$viewValue` change.
 *
 */
ngModule.directive('ppBindInvalidModel', ['$timeout', function ($timeout) {

    return {
        require: 'ngModel',
        priority: 9999,
        restrict: 'A',
        scope: {
            boundTo: '=ppBindInvalidModel',
            stringValue: '=?'
        },
        link: function ($scope, $elem, $attrs, $ctrl) {

            $ctrl.$validators.ppValidityBindFix = function (value) {
                if (!angular.isUndefined(value)) {
                    $scope.boundTo = value;
                }
                $scope.stringValue = $ctrl.$viewValue;
                return true;
            };
            $elem.on('keyup change blur', function () {
                $timeout(function () {
                    if ($ctrl.$viewValue === '') {
                        $scope.stringValue = $elem.val();
                    }
                });
            });
        }
    };
}]);
})(window, window.angular);