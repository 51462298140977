(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.private-nationality', [
    'pp.services.core',
    'pp.services.auth'
]);

/**
 * @ngdoc service
 * @name privateNationalityService
 *
 * @description
 * Provides priavte methods to invoke the `/investor/nationalities`, `investor-identifiers` and `/investor/identifiers` endpoints, used in pp.services.nationality.
 */

ngModule.service('privateNationalityService', ['$http', '$q', 'authService', 'ppMoment', function ($http, $q, authService, ppMoment) {

    var api = {};

    var API_BASE_PATH = '/feapi/r1';

    var API_INVESTOR_NATIONALITIES = API_BASE_PATH + '/investor/nationalities';
    var API_INVESTOR_NATIONALITY = API_BASE_PATH + '/investor/nationalities/:nationality';
    var API_INVESTOR_IDENTIFIER_TYPES = API_BASE_PATH + '/investor/identifiers';

    api._getInvestorNationalities = function () {

        return $http.get(API_INVESTOR_NATIONALITIES).then(function (response) {
                return response.data.collection.map(function (nationality) {
                    return nationality.isoCode;
                });
            },
            function (error) {
                switch (error.status) {
                case 404:
                    return [];
                case 303:
                    authService.handleUnauthorised();
                    return $q.reject({
                        reason: 'investor-nationalities.error.unauthorised'
                    });
                default:
                    return $q.reject({
                        reason: 'investor-nationalities.error.unexpected'
                    });
                }
            });
    };

    /**
     * @ngdoc method
     * @name privateNationalityService#_getInvestorIdentifiers
     *
     * @description
     * When queried returns investor identifiers
     *
     */

    api._getInvestorIdentifiers = function () {

        return $http.get(API_INVESTOR_IDENTIFIER_TYPES).then(function (response) {
                return response.data.collection;
            },
            function (error) {
                switch (error.status) {
                case 404:
                    return [];
                default:
                    return $q.reject({
                        reason: 'investor-identifiers.error.unexpected'
                    });
                }
            });

    };

    api._deleteAllInvestorNationalities = function () {

        return api._getInvestorNationalities().then(function (nationalities) {

            var promises = nationalities.map(function (nationality) {
                return api._deleteInvestorNationality(nationality);
            });

            return $q.all(promises);
        });

    };

    /**
     * @ngdoc method
     * @name privateNationalityService#_postInvestorIdentifiers
     *
     * @description
     * Given an investor identifier and nationality
     * This will be posted to the backend and stored
     *
     * @param {object} identifier
     *
     */

    api._postInvestorIdentifier = function (identifier) {

        var payload = {
            identifierTypeId: identifier.id,
            value: identifier.identifierValue
        };

        if (identifier.mifidExpiryDate) {
            payload['mifidExpiryDate'] = ppMoment(identifier.mifidExpiryDate).format('YYYY-MM-DD');
        }

        return $http.post(API_INVESTOR_IDENTIFIER_TYPES, payload).then(function () {
            return true;
        }, function (error) {
            switch (error.status) {
            case 403:
                return $q.reject({
                    reason: 'investor-identifiers.error.corresponding-nationality-missing',
                });
            default:
                return $q.reject({
                    reason: 'investor-identifiers.error.unexpected'
                });
            }
        });

    };

    /**
     * @ngdoc method
     * @name privateNationalityService#_postInvestorIdentifiers
     *
     * @description
     * Given an investor identifier and nationality
     * This will be posted to the backend and stored
     *
     * @param {object} identifier
     *
     */

    api._putInvestorIdentifier = function (identifier) {

        var payload = {
            identifierTypeId: identifier.id,
            value: identifier.identifierValue
        };

        var path = API_INVESTOR_IDENTIFIER_TYPES + '/' + identifier.id;

        if (identifier.mifidExpiryDate) {
            payload['mifidExpiryDate'] = ppMoment(identifier.mifidExpiryDate).format('YYYY-MM-DD');
        }

        return $http.put(path, payload).then(function () {
            return true;
        }, function (error) {
            switch (error.status) {
            case 403:
                return $q.reject({
                    reason: 'investor-identifiers.error.corresponding-nationality-missing',
                });
            default:
                return $q.reject({
                    reason: 'investor-identifiers.error.unexpected'
                });
            }
        });

    };

    /**
     * @ngdoc method
     * @name privateNationalityService#_postInvestorNationality
     *
     * @description
     * Given a nationality
     * This will be posted to the backend and stored
     *
     * @param {string} nationality
     *
     */

    api._postInvestorNationality = function (nationality) {

        var payload = {
            iso: nationality
        };

        return $http.post(API_INVESTOR_NATIONALITIES, payload).then(function () {
            return true;
        }, function (error) {
            switch (error.status) {
            default:
                return $q.reject({
                    reason: 'investor-nationalities.error.unexpected'
                });
            }
        });

    };

    /**
     * @ngdoc method
     * @name privateNationalityService#_deleteInvestorNationality
     *
     * @description
     * Given a nationality
     * This will be posted to the backend and stored
     *
     * @param {string} nationality
     *
     */

    api._deleteInvestorNationality = function (nationality) {

        var endpoint = API_INVESTOR_NATIONALITY.replace(':nationality', nationality);

        return $http.delete(endpoint).then(function (response) {
            return true;
        }, function (error) {
            switch (error.status) {
            case 404:
                return $q.reject({
                    reason: 'investor-nationality.error.not-found'
                });
            default:
                return $q.reject({
                    reason: 'investor-nationality.error.unexpected'
                });
            }
        });

    };

    return api;

}]);
})(window, window.angular);