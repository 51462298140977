(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.list-menu-item', [
    'ui.router',
    'pp.components-templates',
    'pp.services.core'
]);

ngModule.component('ppListMenuItem', {
    templateUrl: 'components/_angular/list-menu-item/list-menu-item.tpl.html',
    bindings: {
        item: '<',
        context: '@',
        onActive: '&?'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', '$transitions', '$state', 'ppTrack', 'ppUtil', function ($scope, $window, $transitions, $state, ppTrack, ppUtil) {
        var vm = this;

        // -- initial state

        // -- util functions

        function setActive(state) {
            var hash = $window.location.hash || '';

            if (state && vm.item.sref) {
                vm.isActive = vm.item.sref === state;
            }

            if (vm.item && angular.isString(vm.item.href)) {
                vm.isActive = vm.item.href === $window.location.pathname + hash;
            }

            if (vm.isActive && angular.isFunction(vm.onActive)) {
                vm.onActive();
            }
        }

        // -- api

        vm.onClick = function () {
            var context = vm.context ? vm.context + '.' : '';
            ppTrack.deferAction(context + 'click.' + ppUtil.slugify(vm.item.label));
            $scope.$emit('list-menu-item.click', vm.item.label);
        };

        // -- lifecycle

        $transitions.onSuccess({}, function (transition) {
            var state = transition.to();
            setActive(state.name);
        });

        vm.$onInit = function () {
            setActive($state.current.name);
        };

        // -- main

    }]
});
})(window, window.angular);