(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.bond-card', [
    'pp.widgets-templates',
    'pp.widgets.property-status',
    'pp.services.core'
]);

ngModule.component('ppBondCard', {
    templateUrl: 'widgets/_angular/bond-card/bond-card.tpl.html',
    bindings: {
        data: '<',
        user: '<',
        onSubmit: '&',
        isHidden: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', function ($scope, ppTrack) {
        var vm = this;

        // -- initial state

        // only used when property is in fully funded state
        // This is triggered through the fullyFunded flag on the data object
        vm.status = {
            state: {
                status: 'bond-funded'
            }
        };

        // -- util functions

        // -- api

        vm.trackLearnMoreClick = function () {
            ppTrack.action('bond-card.learn-more.click');
        };

        vm.trackKeyRisksClick = function () {
            ppTrack.action('bond-card.key-risks.click');
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            vm.invest = vm.onSubmit;
        };

    }]
});
})(window, window.angular);