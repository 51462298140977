(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.kyc-page', [
    'pp.widgets-templates',
    'pp.services.investor',
    'pp.services.core',
    'pp.services.route'
]);

var PAGE_NAME = 'kyc';

ngModule.component('ppKycPage', {
    templateUrl: 'widgets/_angular/kyc-page/kyc-page.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'investorService', 'ppTrack', 'routeService', function ($scope, investorService, ppTrack, routeService) {
        var vm = this;

        // -- initial state

        vm.marketplacePath = routeService.marketplacePath;
        vm.dashboardPath = routeService.dashboardPath;

        // -- util functions

        // -- api

        vm.onFail = angular.noop;

        vm.onSuccess = function () {
            vm.kycComplete = true;
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            investorService.getInvestor().then(function (user) {
                vm.user = user;
                vm.ready = true;
                ppTrack.pageLoad(PAGE_NAME);
            });
        };

    }]
});
})(window, window.angular);