(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.wistia-video', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor'
]);

ngModule.component('ppWistiaVideo', {
    templateUrl: 'widgets/_angular/wistia-video/wistia-video.tpl.html',
    bindings: {
        videoId: '<',
        autoPlay: '<?',
        overlay: '<?',
        videoName: '@?'
    },
    controllerAs: 'vm',
    controller: ['$sce', '$window', 'ppTrack', 'investorService', function ($sce, $window, ppTrack, investorService) {
        var vm = this;

        // -- initial state

        // -- util functions

        var removeVideo = function (video) {
            if (video) {
                video.remove();
            }
        };

        function init() {
            $window._wq = $window._wq || [];
            $window._wq.push({
                id: vm.videoId,
                onHasData: function (video) {
                    removeVideo = removeVideo.bind(null, video);
                }
            });

            var obj = {};
            obj[vm.videoId] = function (video) {
                video.bind('play', function () {
                    if (vm.overlay) {
                        document.getElementById('ai-video-overlay').className += ' hide';
                    }
                    if (vm.videoName) {
                        // were triggering before page load when slow network
                        investorService.getInvestor().then(function () {
                            ppTrack.pageAction('video.played', vm.videoName);
                        });
                    }

                });
                video.bind('pause', function () {
                    if (vm.videoName) {
                        // were triggering before page load when slow network
                        investorService.getInvestor().then(function () {
                            ppTrack.action('video.paused' + vm.videoName, {
                                'video.position.seconds': Math.round(video.time())
                            });
                        });
                    }
                });
            };
            $window._wq.push(obj);
        }

        // -- api

        // -- scope bindings

        vm.$onDestroy = function () {
            removeVideo();
        };

        // -- main

        vm.$onInit = function () {
            init();
        };

    }]
});
})(window, window.angular);