(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-set', [
    'pp.widgets-templates',
    'pp.widgets.property-card-simple',
    'pp.filters.numbers',
    'pp.services.core'
]);

var DEFAULT_SCROLL_PADDING = 36;

ngModule.component('ppPropertySet', {
    templateUrl: 'widgets/_angular/property-set/property-set.tpl.html',
    bindings: {
        properties: '<',
        user: '<',
        key: '<',
        showSummary: '<',
        holdings: '<',
        bids: '<',
        offers: '<',
        viewDetail: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$element', '$timeout', '$window', 'ppBig', function ($scope, $element, $timeout, $window, ppBig) {
        var vm = this;

        // -- initial state

        var __outerWrapper;
        var __innerWrapper;
        var __propertyElements;
        var __offsetLeft;
        var __offsetRight;

        var __waypointLeft;
        var __waypointRight;

        vm.leftInView = true;

        // -- util functions

        function init() {
            __outerWrapper = $($element).find('.set-outer').get(0);
            __innerWrapper = $($element).find('.set-inner').get(0);
            __offsetLeft = __innerWrapper.offsetLeft;
            __offsetRight = $($window).width() - __offsetLeft + __innerWrapper.outerWidth;
            __propertyElements = $($element).find('.property');

            var firstProperty = __propertyElements.get(0);
            var lastProperty = __propertyElements.get(vm.properties.length - 1);

            __waypointLeft = new Waypoint({
                element: firstProperty,
                handler: function (direction) {
                    $scope.$evalAsync(function () {
                        vm.leftInView = direction === 'left';
                    });
                },
                context: __outerWrapper,
                horizontal: true
            });

            __waypointRight = new Waypoint({
                element: lastProperty,
                handler: function (direction) {
                    $scope.$evalAsync(function () {
                        vm.rightInView = direction === 'right';
                    });
                },
                context: __outerWrapper,
                horizontal: true,
                offset: 'right-in-view'
            });
        }

        function getPropertyMarginRight(els) {
            return Number(els.first().css('marginRight').replace('px', ''));
        }

        function getPropertyWidth(els) {
            return els.first().width();
        }

        // -- api

        function getNextProperty(els, propertyWidthOuter) {
            var wrapperInnerWidth = $(__innerWrapper).width();
            return vm.properties.reduce(function (nextProperty, _, index) {
                if (angular.isDefined(nextProperty)) {
                    return nextProperty;
                }

                if ($(els.get(index)).position().left + propertyWidthOuter > wrapperInnerWidth) {
                    return index;
                }

                return nextProperty;
            }, undefined);
        }

        function getPrevProperty(els) {
            var wrapperInnerWidth = $(__innerWrapper).width();
            return vm.properties.reduceRight(function (nextProperty, _, index) {
                if (angular.isDefined(nextProperty)) {
                    return nextProperty;
                }

                if ($(els.get(index)).position().left < 0) {
                    return index;
                }

                return nextProperty;
            }, undefined);
        }

        vm.goToNextCard = function () {

            var currentScroll = __outerWrapper.scrollLeft;
            var propertyMargin = getPropertyMarginRight(__propertyElements);
            var propertyWidth = getPropertyWidth(__propertyElements);

            var lastIndex = vm.properties.length - 1;

            var nextProperty = Math.min(getNextProperty(__propertyElements, propertyWidth + propertyMargin) || lastIndex, vm.properties.length - 1);
            var propertyEl = $(__propertyElements.get(nextProperty));
            var scrollLeft = ppBig(currentScroll)
                .plus(propertyEl.offset().left)
                .plus($(__outerWrapper).outerWidth())
                .minus($($window).width());

            $(__outerWrapper).animate({
                scrollLeft: Number(scrollLeft)
            }, 300);
        };

        vm.goToPrevCard = function () {
            var currentScroll = __outerWrapper.scrollLeft;
            var propertyMargin = getPropertyMarginRight(__propertyElements);
            var propertyWidth = getPropertyWidth(__propertyElements);

            var lastIndex = vm.properties.length - 1;

            var nextProperty = Math.max(getPrevProperty(__propertyElements) || 0, 0);
            var propertyEl = $(__propertyElements.get(nextProperty));

            var scrollLeft = ppBig(currentScroll)
                .minus(propertyEl.offset().left)
                .minus($(__innerWrapper).width());

            $(__outerWrapper).animate({
                scrollLeft: Number(scrollLeft)
            }, 300);
        };

        vm.onViewDetail = function (property) {
            vm.viewDetail({
                property: property
            });
        };

        // -- scope bindings

        vm.$postLink = function () {
            $timeout(init);
        };

        // -- main

    }]
});
})(window, window.angular);