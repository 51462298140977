(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-plan-card', [
    'pp.widgets-templates',
    'pp.widgets.return-composition-bar',
    'pp.services.core',
    'pp.components.tooltip',
    'pp.values.investment-plans'
]);

ngModule.component('ppInvestmentPlanCard', {
    templateUrl: 'widgets/_angular/investment-plan-card/investment-plan-card.tpl.html',
    bindings: {
        plan: '<',
        hideReturnBreakdown: '<',
        planDescriptionAfter: '<',
        hideButtons: '<',
        alwaysExpanded: '<',
        user: '<',
        showWarnings: '<'
    },
    controllerAs: 'vm',
    controller: ['$element', '$anchorScroll', 'ppConfig', 'ppTrack', 'investmentPlans', 'ppComponentEmitter', function ($element, $anchorScroll, ppConfig, ppTrack, investmentPlans, ppComponentEmitter) {

        var vm = this;

        // -- initial state

        vm.ratioClassMap = {
            1: 'ratio-1',
            2: 'ratio-2',
            3: 'ratio-3',
            4: 'ratio-4'
        };

        var __config = ppConfig.get('pp.external.endpoints') || {};

        var assetsEndpoint = __config.assets;

        // -- util functions

        // -- api

        vm.anchorScroll = $anchorScroll;

        vm.expand = function () {
            var plan = vm.plan || {};
            ppTrack.event('investment-plan-card.expand', {
                plan: plan.key
            });
            vm.expanded = true;
        };

        // -- scope bindings

        vm.$onInit = function () {
            var plan = vm.plan || {};
            vm.icon = investmentPlans.createIconUrl(assetsEndpoint, plan.key);
            ppComponentEmitter.emit('investment-plan-card.controller.loaded.' + plan.key, vm);
        };

        vm.$postLink = function () {
            var plan = vm.plan || {};
            ppComponentEmitter.emit('investment-plan-card.dom.loaded.' + plan.key, $element);
        };

        // -- main

    }]
});
})(window, window.angular);