(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.business-summary-how-it-works', [
    'pp.widgets-templates',
    'pp.widgets.business-summary-earn',
    'pp.widgets.business-summary-ethics',
    'pp.widgets.business-summary-hard-work',
    'pp.widgets.business-summary-invest',
    'pp.widgets.business-summary-sell',
    'pp.widgets.business-summary-track',
    'pp.values.glossary'
]);

ngModule.component('ppBusinessSummaryHowItWorks', {
    templateUrl: 'widgets/_angular/business-summary-how-it-works/business-summary-how-it-works.tpl.html',
    bindings: {
        s3Endpoint: '<',
        user: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);