(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.recaptcha', [
    'vcRecaptcha',
    'pp.widgets-templates',
    'pp.services.core'
]);

ngModule.directive('ppRecaptcha', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/recaptcha/recaptcha.tpl.html',
        scope: {
            recaptchaResponse: '=',
            feedbackEnabled: '<',
            reCaptchaKey: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', 'vcRecaptchaService', 'ppBrowser', function ($scope, $timeout, vcRecaptchaService, ppBrowser) {
            var vm = this;

            // -- initial state

            vm.ready = false;
            var __offsetTop = 0;

            // -- util functions

            // Handles issues PP-5510 on ios recaptcha scrolls to bottom of page
            // https://github.com/google/recaptcha/issues/130
            function isScrollNotJump(offset, scrollY) {
                return (Math.abs(scrollY) - Math.abs(offset)) < 400;
            }

            // Handles issues PP-5510 on ios recaptcha scrolls to bottom of page
            // https://github.com/google/recaptcha/issues/130
            function recordScrollPosition() {
                if (isScrollNotJump(__offsetTop, window.scrollY)) {
                    __offsetTop = window.scrollY;
                }
            }

            // Handles issues PP-5510 on ios recaptcha scrolls to bottom of page
            // https://github.com/google/recaptcha/issues/130
            function unbindScrollListener() {
                window.removeEventListener('scroll', recordScrollPosition);
            }

            function reloadWidget(widgetId) {
                vcRecaptchaService.reload(widgetId);
            }

            // Handles issues PP-5510 on ios recaptcha scrolls to bottom of page
            // https://github.com/google/recaptcha/issues/130
            function iosScrollFix() {
                if (ppBrowser.isIOS && __offsetTop) {
                    document.body.scrollTop = document.documentElement.scrollTop = __offsetTop;
                }
            }

            // -- api

            vm.onCreate = function (widgetId) {
                vm.recaptchaWidgetId = widgetId;
            };

            vm.onSuccess = function (response) {
                vm.recaptchaResponse = response;

                // Handles issues PP-5510 on ios recaptcha scrolls to bottom of page
                // https://github.com/google/recaptcha/issues/130
                iosScrollFix();
            };

            vm.onExpire = function () {
                // Handles issues PP-5510 on ios recaptcha scrolls to bottom of page
                // https://github.com/google/recaptcha/issues/130
                if (ppBrowser.isIOS) {

                    $timeout(function () {
                        iosScrollFix();
                    }, 1000);
                }
            };

            // -- scope bindings

            $scope.$on('recaptcha.reload', function () {
                if (angular.isDefined(vm.recaptchaWidgetId)) {
                    reloadWidget(vm.recaptchaWidgetId);
                }
            });

            vm.$onDestroy = function () {
                // Handles issues PP-5510 on ios recaptcha scrolls to bottom of page
                // https://github.com/google/recaptcha/issues/130
                unbindScrollListener();
            };

            // -- main

            vm.$onInit = function () {
                // Handles issues PP-5510 on ios recaptcha scrolls to bottom of page
                // https://github.com/google/recaptcha/issues/130
                if (ppBrowser.isIOS) {
                    window.addEventListener('scroll', recordScrollPosition);
                }
            };

        }]
    };
}]);
})(window, window.angular);