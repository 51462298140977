(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-intent-form', [
    'pp.widgets-templates',
    'pp.services.core'
]);

ngModule.component('ppInvestmentIntentForm', {
    templateUrl: 'widgets/_angular/investment-intent-form/investment-intent-form.tpl.html',
    bindings: {
        onSubmit: '&',
        intent: '<',
        isYearly: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', function ($scope, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api
        vm.submit = function () {

            ppTrack.action('investment-intent-form.submit', {
                intent: vm.intent
            });

            vm.onSubmit({
                intent: vm.intent
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);