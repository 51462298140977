(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.mortgage-blocked-no-shares', ['pp.widgets-templates']);

ngModule.component('ppMortgageBlockedNoShares', {
    templateUrl: 'widgets/_angular/mortgage-blocked-no-shares/mortgage-blocked-no-shares.tpl.html',
    bindings: {
        onContinue: '&',
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.continue = function () {
            vm.onContinue();
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);