(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.auth-login', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.route'
]);

var PAGE_NAME = 'login-page';

var HASH_KEY = 'pp-se';

ngModule.component('ppAuthLogin', {
    templateUrl: 'widgets/_angular/auth-login/auth-login.tpl.html',
    bindings: {
        email: '@',
        isSessionExpired: '<',
        redirectUrl: '@'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', 'routeService', 'ppTrack', function ($scope, $window, routeService, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions

        function redirect(path) {
            ppTrack.closeAll().then(function () {
                if (vm.isSessionExpired) {
                    var redirectHash = $window.location.hash.replace(HASH_KEY, '');
                    var redirectUrl = vm.redirectUrl + redirectHash;
                    $window.location.href = redirectUrl;
                } else {
                    $window.location.href = path;
                }

            });
        }

        // -- api

        vm.goToSignup = function () {
            ppTrack.deferAction(PAGE_NAME + '.go-to-signup');
            $window.location.href = routeService.signupPath;
        };

        vm.onSuccess = function (fallbackPath) {
            ppTrack.deferEvent(PAGE_NAME + '.login.success');
            redirect(fallbackPath);
        };

        vm.onChangeLoginStep = function (step) {
            vm.loginStep = step;
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            ppTrack.pageLoad(PAGE_NAME);
        };

    }]
});
})(window, window.angular);