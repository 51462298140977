(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-total-return', [
    'ui.router',
    'pp.widgets-templates',
    'pp.widgets.dashboard-total-return-table',
    'pp.services.dashboard',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.preference',
    'pp.ui.services.total-return-valuation-methods-dialog'
]);

ngModule.component('ppDashboardTotalReturn', {
    templateUrl: 'widgets/_angular/dashboard-total-return/dashboard-total-return.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$q', '$stateParams', '$transitions', '$timeout', 'dashboardService', 'investorService', 'preferenceService', 'ppUtil', 'totalReturnValuationMethodsDialog', 'R', function ($scope, $q, $stateParams, $transitions, $timeout, dashboardService, investorService, preferenceService, ppUtil, totalReturnValuationMethodsDialog, R) {
        var vm = this;

        // -- initial state

        vm.data = {};

        vm.sortAttribute = undefined;
        vm.sortOrder = 'descending';

        // -- util functions

        function updateBids(bids) {
            vm.data.bids = ppUtil.indexListBy(bids, 'symbol');
        }

        function updateOffers(offers) {
            vm.data.offers = ppUtil.indexListBy(offers, 'propertySymbol');
        }

        function init() {
            return $q.all({
                data: dashboardService.getTotalReturn(),
                showLatestValuationVPV: preferenceService.showCurrentDashboardLatestValuationVPV(),
                showLatestValuationIV: preferenceService.showCurrentDashboardLatestValuationIV(),
                showTradingValuation: preferenceService.showCurrentDashboardTradingValuation(),
                valuationMethod: preferenceService.getDashboardOverviewValuationMethod()
            }).then(function (res) {
                vm.data = res.data;
                vm.showLatestValuationVPV = res.showLatestValuationVPV;
                vm.showLatestValuationIV = res.showLatestValuationIV;
                vm.showUpdateValuationMethodNote = res.showUpdateValuationMethodNote;
                vm.showTradingValuation = false;
                vm.tradingValuationAvailable = true;
                vm.openSidebarSymbol = $stateParams.propertySymbol;
                vm.showValuationTooltips = true;
                vm.valuationMethod = res.valuationMethod;
                vm.ready = true;
                vm.loadError = false;
            }, function () {
                vm.loadError = true;
                vm.ready = true;
            });
        }

        // -- api

        vm.onChange = function () {
            init();
        };

        vm.switchValuationMethod = function (method) {
            vm.valuationMethod = method;
            return preferenceService.setDashboardOverviewValuationMethod(method).then(function () {
                vm.valuationMethod = method;
                return method;
            });
        };

        // -- lifecycle

        $transitions.onSuccess({}, function (transition) {
            vm.openSidebarSymbol = $stateParams.propertySymbol;
        });

        vm.$onDestroy = function () {
            investorService.off('investor.holdings.bid.updated', updateBids);
            investorService.off('investor.holdings.listed.updated', updateOffers);
        };

        // -- main

        vm.$onInit = function () {
            init().then(function () {
                investorService.on('investor.holdings.bid.updated', updateBids);
                investorService.on('investor.holdings.listed.updated', updateOffers);

            });
        };

    }]
});
})(window, window.angular);