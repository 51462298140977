(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.blog', [
    'pp.services.core'
]);

/**
 * @ngdoc service
 * @name blogService
 *
 * @description
 */
ngModule.service('blogService', ['$http', '$q', 'ppConfig', function ($http, $q, ppConfig) {

    var api = {};

    var _config = ppConfig.get('pp.blog-posts') || {};

    var _endpoints = ppConfig.get('pp.external.endpoints') || {};

    var BLOG_BASE_ENDPOINT = _endpoints.blogApi;
    var BLOG_POST_ENDPOINT = '/wp/v2/posts/';

    var EVENT_KEYS = [
        'event_type',
        'event_title',
        'event_subtitle',
        'event_description',
        'event_date',
        'event_cta',
        'event_url'
    ];

    function getBlogPost(postId) {
        var endpoint = BLOG_BASE_ENDPOINT + BLOG_POST_ENDPOINT + postId;
        return $http.get(endpoint);
    }

    function extractEventData(eventNumber, data) {
        return EVENT_KEYS.reduce(function (event, key) {
            event[key] = data[key + '_' + eventNumber] || {};
            return event;
        }, {});
    }

    /**
     * @ngdoc method
     * @name getBlogEvents
     */

    api.getMarketingEvents = function () {

        if (!_config.events) {
            return $q.when([]);
        }

        return getBlogPost(_config.events).then(function (response) {
            var data = response.data.acf;
            return [
                extractEventData(1, data),
                extractEventData(2, data),
                extractEventData(3, data)
            ];
        }, function () {
            return $q.reject({
                reason: 'blog.error.unexpected'
            });
        });
    };

    return api;
}]);
})(window, window.angular);