(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.dashboard-individual-property-sidebar', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.services.investor',
    'pp.widgets.dashboard-individual-property'
]);

/**
 * @ndgoc service
 * @description
 * checks if we need to notify the user that they have been qualified/disqualified for an rff scheme
 * shows the dialog and sets a preference when the user dismisses it
 * so that the dialog is never shown again for the same scheme
 */
ngModule.service('dashboardIndividualPropertySidebarService', ['$rootScope', '$timeout', 'ppTrack', 'dialogService', 'investorService', function ($rootScope, $timeout, ppTrack, dialogService, investorService) {

    // -- util functions

    function addSidebarClassToBody() {
        $('body').addClass('pp-sidebar-open');
    }

    function removeSidebarClassToBody(res) {
        $('body').removeClass('pp-sidebar-open');
        return res;
    }

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(property, holding, user, onChange) {
        var scope = $rootScope.$new();
        var options = {
            clickClose: true,
            escapeClose: true,
            showClose: false,
            scope: scope,
            classNames: 'dashboard-individual-property-sidebar modal-sidebar sidebar-transition',
            blockerClass: 'sidebar-blocker'
        };
        scope.property = property;
        scope.holding = holding;
        scope.onChange = onChange;
        scope.user = user;

        options.contents = '<pp-dashboard-individual-property property="property" holding="holding" user="user" on-change="onChange()"></pp-dashboard-individual-property>';

        addSidebarClassToBody();

        return dialogService.show(options)
            .then(removeSidebarClassToBody);
    }

    // -- api

    var api = {};

    api.show = function (property, holding, onChange) {
        var promise = investorService.getInvestor().then(function (user) {
            var dialog = showDialog(property, holding, user, onChange);
            $timeout(function () {
                $('.dashboard-individual-property-sidebar').removeClass('sidebar-transition');
            }, 50);
            return dialog;
        });
        return promise;
    };

    return api;
}]);
})(window, window.angular);