(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.how-crowdfunding-works', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.route',
    'pp.widgets.ways-to-invest',
    'pp.widgets.awards-banner',
    'pp.widgets.risk-warning-badge',
    'pp.widgets.nav-card'
]);

var PAGE_NAME = 'how-crowdfunding-works';

ngModule.component('ppHowCrowdfundingWorks', {
    templateUrl: 'widgets/_angular/how-crowdfunding-works/how-crowdfunding-works.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', 'ppPubSub', 'investorService', 'routeService', 'ppTrack', function ($scope, ppConfig, ppPubSub, investorService, routeService, ppTrack) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints') || {};
        var s3Endpoint = __config.assets;

        vm.iconPeopleMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-people-marker.png';
        vm.iconLightbulbMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-lightbuld-marker.png';
        vm.iconCogMarker = s3Endpoint + '/images/icons/crowdfunding-lp/icon-cog-marker.png';

        vm.pendingPropertyCard = s3Endpoint + '/images/marketing/property-crowdfunding/how/funded.jpg';
        vm.fundingPropertyCard = s3Endpoint + '/images/marketing/property-crowdfunding/how/funding.jpg';
        vm.propertySearch = s3Endpoint + '/images/marketing/property-crowdfunding/property-search.jpg';
        vm.propertyMap = s3Endpoint + '/images/marketing/property-crowdfunding/how/diversify-your-portfolio.jpg';
        vm.accountHistory = s3Endpoint + '/images/marketing/property-crowdfunding/how/account-history.jpg';
        vm.sellForm = s3Endpoint + '/images/marketing/property-crowdfunding/how/exit-your-investment.jpg';

        vm.propertyTeamPath = routeService.propertyTeamPath;
        vm.blogAcquisitionStrategies = routeService.blogAcquisitionStrategies;

        vm.sellIntro = routeService.sellIntro;
        vm.exitMechanic = routeService.exitMechanic;

        vm.whatIsCrowdfundingPath = routeService.whatIsCrowdfundingPath;
        vm.crowdfundingBenefitsPath = routeService.crowdfundingBenefitsPath;
        vm.howCrowdfundingWorksPath = routeService.howCrowdfundingWorksPath;

        // -- util functions

        // -- api

        vm.openSignup = function () {
            ppPubSub.publish('auth.modal.show', ['signup', null, null, null, null]);
        };

        vm.openLogin = function () {
            ppPubSub.publish('auth.modal.show', ['login', null, null, null, null]);
        };

        // -- scope bindings

        // -- main

        ppTrack.pageLoad(PAGE_NAME);

        investorService.getInvestor().then(function (user) {
            vm.user = user;
        });

    }]
});
})(window, window.angular);