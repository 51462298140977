(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.validation-date', ['pp.services.date']);

ngModule.directive('ppValidationDate', ['dateService', function (dateService) {

    return {
        restrict: 'A',
        require: '?ngModel',
        scope: {
            dateMin: '<',
            dateMax: '<'
        },
        // an isolate $scope is used for easier/cleaner
        // $watching and cleanup (on destruction)
        link: function ($scope, elem, attrs, ctrl) {

            // -- util functions

            function updateMinValidity(min, date) {
                var isValid = true;
                if (angular.isDefined(min) && date.isValid()) {
                    isValid = date.isSameOrAfter(min);
                }
                ctrl.$setValidity('date-min', isValid);
                return isValid;
            }

            function updateMaxValidity(max, date) {
                var isValid = true;
                if (angular.isDefined(max) && date.isValid()) {
                    isValid = date.isSameOrBefore(max);
                }
                ctrl.$setValidity('date-max', isValid);
                return isValid;
            }

            var validateMin = updateMinValidity.bind(this, $scope.dateMin);
            var validateMax = updateMaxValidity.bind(this, $scope.dateMax);

            function validateDate(value) {
                var date = dateService.parseDateInThePast(value);

                ctrl.$setValidity('date-valid', date.isValid());
                validateMin(date);
                validateMax(date);

                return value;
            }

            // -- scope bindings

            // Make sure re-validation takes place whenever:
            //   either the control's value changes
            ctrl.$formatters.push(validateDate);
            ctrl.$parsers.unshift(validateDate);
        }

    };
}]);
})(window, window.angular);