(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketing-download-bar', [
    'pp.widgets-templates',
    'pp.services.route',
    'pp.services.core'
]);

ngModule.component('ppMarketingDownloadBar', {
    templateUrl: 'widgets/_angular/marketing-download-bar/marketing-download-bar.tpl.html',
    bindings: {
        user: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', 'ppConfig', 'routeService', function ($scope, $window, ppConfig, routeService) {
        var vm = this;

        // -- initial state

        var __endpoints = ppConfig.get('pp.external.endpoints') || {};
        var __assets = __endpoints.assets;

        vm.appDownloadLink = routeService.appDownload;

        vm.marketIndexReport = routeService.marketIndexReport;

        vm.appStoreDownloadIcon = __assets + '/images/icons/app/appstore-btn.jpg';

        // -- util functions

        // -- api

        vm.openMarketIndexDownloadForm = function () {
            $window.pp.modal('#mailchimp-signup-modal', {
                showClose: true,
                escapeClose: true,
                clickClose: true
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);