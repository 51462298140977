(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-loan', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.filters.dates',
    'pp.services.core',
    'pp.services.property'
]);

ngModule.component('ppPropertyDetailsLoan', {
    templateUrl: 'widgets/_angular/property-details-loan/property-details-loan.tpl.html',
    bindings: {
        property: '<',
        propertyPage: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$filter', 'ppTrack', 'ppUtil', 'propertyService', function ($scope, $filter, ppTrack, ppUtil, propertyService) {
        var vm = this;

        // -- initial state

        // -- util functions

        function createAmountAvailable(property) {
            if (!ppUtil.hasPathIn(property, 'valuation') || !ppUtil.hasPathIn(property, 'market.primary')) {
                return;
            }
            var available = property.valuation.latestValue - property.market.primary.fundedAmount;
            return $filter('ppCurrency')(available, '£', 0);
        }

        function createLoanSizePoint(property) {
            if (!ppUtil.hasPathIn(property, 'valuation')) {
                return;
            }

            return $filter('ppCurrency')(property.valuation.latestValue, '£', 0);
        }

        function createPreorderClose(property) {
            return $filter('date')(property.state.preorderCloses, 'd MMM yyyy');
        }

        function createTimeLeft(property) {
            var value = $filter('ppTimeLeft')(window.pp.serverTime, new Date(property.state.offerClosing)) || '';
            return value.toLowerCase();
        }

        function createNetInterest(property) {
            if (ppUtil.hasPathIn(property, 'income.net')) {
                return $filter('ppPercentage')(property.income.net.pct, 2);
            }
        }

        // -- api

        // -- scope bindings

        vm.$onChanges = function (changes) {

            if (!changes.property || !changes.property.currentValue) {
                return;
            }

            var property = changes.property.currentValue;

            vm.isPreviewProperty = propertyService.isPreviewProperty(property);
            vm.isPreorder = propertyService.isPreorderProperty(property);
            vm.isPrimaryOffered = propertyService.isPrimaryOfferedProperty(property);
            vm.isPrimaryClosedProperty = propertyService.isPrimaryClosedProperty(property);
            vm.isSecondaryInformational = propertyService.isSecondaryInformationalProperty(property);

            vm.netInterest = createNetInterest(property);
            vm.loanSize = createLoanSizePoint(property);

            if (vm.isPrimaryOffered) {
                vm.timeLeft = createTimeLeft(property);
                vm.amountAvailable = createAmountAvailable(property);
            }

            if (vm.isPreorder) {
                vm.preorderCloses = createPreorderClose(property);
            }

        };

        // -- main

    }]
});
})(window, window.angular);