(function(angular){ angular.module('pp.components-templates', []).run(['$templateCache', function ($templateCache) {  'use strict';

  $templateCache.put('components/_angular/carousel-multi-item/carousel-multi-item.tpl.html',
    "<div class=\"pp-carousel-multi-item\"><div class=\"owl-carousel\"><img ng-repeat=\"slide in vm.slides track by $index\" class=\"owl-lazy\" data-src=\"{{slide}}\" ng-click=\"vm.setCurrentSlide($index)\" ng-class=\"{'active': vm.isActiveSlide($index)}\"></div></div>"
  );


  $templateCache.put('components/_angular/carousel/carousel.tpl.html',
    "<div class=\"pp-carousel\"><div class=\"owl-carousel\"><img ng-repeat=\"slide in vm.slides track by $index\" class=\"owl-lazy\" data-src=\"{{slide}}\"></div></div>"
  );


  $templateCache.put('components/_angular/checkbox/checkbox.tpl.html',
    "<div class=\"pp-checkbox\" ng-class=\"[vm.isRound ? 'round':'', vm.isInverted ? 'inverted':'']\"><label class=\"label investment-docs\" for=\"{{vm.ppId}}\" ng-class=\"vm.align\"><input ng-if=\"vm.ppValue\" type=\"checkbox\" id=\"{{vm.ppId}}\" name=\"{{vm.ppName || 'agree'}}\" ng-model=\"vm.model\" ng-disabled=\"vm.isDisabled\" ng-required=\"vm.isRequired\" ng-true-value=\"'{{vm.ppValue}}'\" ng-false-value=\"''\"> <input ng-if=\"!vm.ppValue\" type=\"checkbox\" id=\"{{vm.ppId}}\" name=\"{{vm.ppName || 'agree'}}\" ng-model=\"vm.model\" ng-disabled=\"vm.isDisabled\" ng-required=\"vm.isRequired\"><pp-animated-tick is-checked=\"vm.model\" is-round=\"vm.isRound\" is-inverted=\"vm.isInverted\" is-disabled=\"vm.isDisabled\"></pp-animated-tick><ng-transclude class=\"content\"></ng-transclude></label></div>"
  );


  $templateCache.put('components/_angular/day-picker/day-picker.tpl.html',
    "<div class=\"pp-day-picker\" ng-class=\"{'with-tooltip': vm.tooltipDescription}\"><select name=\"day\" ng-model=\"vm.day\" ng-options=\"day.value as day.text for day in vm.days\" ng-disabled=\"vm.isDisabled\" ng-required=\"vm.isRequired\"><option value=\"\" selected>Day</option></select></div>"
  );


  $templateCache.put('components/_angular/input-amount/input-amount.tpl.html',
    "<div ng-form name=\"vm.ppInputAmount\" class=\"pp-input-amount\"><div class=\"input-amount\"><input type=\"number\" class=\"control\" name=\"amount\" ng-focus=\"vm.onAmountFocus()\" ng-blur=\"vm.onAmountBlur()\" ng-keypress=\"vm.onKeyPress($event)\" ng-model=\"vm.amount\" pp-bind-invalid-model=\"vm.invalidAmount\" cork-ui-focus-on=\"{{vm.focusOn}}\" ng-disabled=\"vm.inputDisabled\" required ng-attr-tabindex=\"{{vm.tabindex}}\" min=\"{{vm.minimum}}\" max=\"{{vm.maximum}}\" autocomplete=\"off\" placeholder=\"{{vm.placeholder}}\"> <span class=\"symbol\" ng-bind=\"vm.symbol\"></span></div></div>"
  );


  $templateCache.put('components/_angular/input-card-expiry-date/input-card-expiry-date.tpl.html',
    "<div class=\"pp-input-card-expiry-date\" ng-form name=\"vm.form\"><div class=\"field card-name\" ng-class=\"{'has-error': vm.feedbackEnabled && vm.form.$invalid}\"><label class=\"label\" for=\"{{vm.ppId}}-month-input\">Expiry Date<sup ng-if=\"vm.isRequired\">*</sup></label><div class=\"input-container-outer\"><div class=\"input-container\" ng-class=\"{'is-focused': monthFocus || yearFocus}\"><div class=\"slash\">/</div><input class=\"month\" name=\"month\" autocomplete=\"cc-exp-month\" id=\"{{vm.ppId}}-month-input\" pp-month-validation ng-model=\"vm.expiryMonth\" placeholder=\"MM\" type=\"tel\" maxlength=\"2\" minlength=\"2\" ng-required=\"vm.isRequired\" ng-focus=\"monthFocus = true\" ng-blur=\"monthFocus = false\" pp-auto-tab pause-tabbing=\"vm.form.month.$invalid\"> <input class=\"year\" name=\"year\" autocomplete=\"cc-exp-year\" id=\"{{vm.ppId}}-year-input\" ng-model=\"vm.expiryYear\" placeholder=\"YY\" type=\"tel\" maxlength=\"2\" minlength=\"2\" ng-required=\"vm.isRequired\" ng-pattern=\"vm.yearRegex\" ng-focus=\"yearFocus = true\" ng-blur=\"yearFocus = false\" pp-auto-tab-reverse-delete></div></div><ul class=\"validation-error\"><li ng-if=\"vm.showEnterADate()\">Please enter a date e.g. 01/<span ng-bind=\"vm.futureYear\"></span></li><li ng-if=\"vm.showInvalidMonth()\">Please enter a month between 01 - 12</li><li ng-if=\"vm.form.expiryDate.$error.expired\">This card has expired</li><li ng-if=\"vm.invalidDate()\">Please enter a valid date e.g. 01/<span ng-bind=\"vm.futureYear\"></span></li><li ng-if=\"vm.form.expiryDate.$error.overThreshold\">This date is too far into the future</li><li ng-if=\"vm.form.month.$error.required && !vm.form.year.$error.required && vm.form.month.$touched\">A month is required</li><li ng-if=\"vm.showYearRequired()\">A year is required</li></ul></div><input type=\"hidden\" ng-model=\"vm.dateConcatenated\" name=\"expiryDate\" pp-expiry-date-validation></div>"
  );


  $templateCache.put('components/_angular/input-copy/input-copy.tpl.html',
    "<div class=\"pp-input-copy\"><textarea id=\"textarea-to-copy\" class=\"textarea-to-copy\" ng-model=\"vm.ppValue\"></textarea><div class=\"input-wrapper\"><input class=\"input\" type=\"text\" ng-model=\"vm.ppValue\" readonly> <button type=\"button\" class=\"copy-button\" ng-class=\"{'copied': vm.copied}\" ng-click=\"vm.copyToClipboard()\"><span ng-hide=\"vm.copied\">Copy</span> <i ng-show=\"vm.copied\" class=\"i-tick icon\"></i></button></div></div>"
  );


  $templateCache.put('components/_angular/input-date/input-date.tpl.html',
    "<div ng-form name=\"{{vm.dateName}}\" class=\"pp-input-date\"><div class=\"input-date\"><select name=\"day\" class=\"day\" ng-model=\"vm.day\" ng-options=\"day.value as day.text for day in vm.days\" ng-disabled=\"vm.isDisabled\" ng-required=\"vm.isRequired\"><option value=\"\" selected>Day</option></select><select name=\"month\" class=\"month\" ng-model=\"vm.month\" ng-options=\"month.value as month.text for month in vm.months\" ng-disabled=\"vm.isDisabled\" ng-required=\"vm.isRequired\"><option value=\"\" selected>Month</option></select><select name=\"year\" class=\"year\" ng-model=\"vm.year\" ng-options=\"year.value as year.text for year in vm.years\" ng-disabled=\"vm.isDisabled\" ng-required=\"vm.isRequired\"><option value=\"\" selected>Year</option></select></div></div>"
  );


  $templateCache.put('components/_angular/input-ni-number/input-ni-number.tpl.html',
    "<div class=\"pp-input-ni-number\"><input class=\"input\" type=\"text\" cork-ui-focus-on=\"focus-ni-number\" ng-show=\"vm.focused || !vm.niNumber\" ng-model=\"vm.niNumber\" ng-focus=\"vm.inputFocused()\" ng-blur=\"vm.inputBlurred()\" name=\"niValue\" ng-pattern=\"vm.regex\" placeholder=\"AB 12 34 56 C\" required> <span class=\"input formatted-number-overlay\" ng-click=\"vm.focusInput()\" ng-show=\"!vm.focused && vm.niNumber\" ng-bind=\"vm.niNumberFormatted\"></span></div>"
  );


  $templateCache.put('components/_angular/input-password/input-password.tpl.html',
    "<div ng-form name=\"vm.ppInputPassword\" class=\"pp-input-password\"><div class=\"field password\" ng-class=\"{'has-error': vm.feedbackEnabled && vm.ppInputPassword.password.$invalid}\"><label class=\"a11y-sr\" for=\"tt-auth-signup-password\">Password</label><input class=\"text pp-no-rec\" ng-attr-type=\"{{ vm.showPassword ? 'text' : 'password' }}\" id=\"tt-auth-signup-password\" name=\"password\" ng-focus=\"vm.passwordFocus()\" ng-model=\"vm.password\" ng-disabled=\"vm.processing\" ng-keyup=\"$event.keyCode == 13 && vm.submit()\" required pp-password-validation placeholder=\"Create Password\"><label ng-click=\"vm.showPasswordClick()\" class=\"link\" for=\"password\" ng-bind=\"vm.showPassword ? 'Hide' : 'Show'\"></label><ul class=\"validation-error\" ng-show=\"vm.ppInputPassword.password.$error.required\"><li>Required field.</li></ul><pp-password-rules class=\"password-rules-wrapper\" ng-class=\"{'visible' : vm.shouldDisplayPasswordRules}\" password-field=\"vm.ppInputPassword.password\" feedback-enabled=\"vm.feedbackEnabled\"></pp-password-rules></div></div>"
  );


  $templateCache.put('components/_angular/input-pin/input-pin.tpl.html',
    "<ng-form name=\"vm.form\" class=\"pp-input-pin\"><div class=\"field\" ng-class=\"{'has-error': vm.form.$invalid}\"><label class=\"label\" for=\"input-pin\">Please enter the <span ng-repeat=\"input in vm.inputs\"><span ng-if=\"$first\"><strong ng-bind=\"input.label\"></strong><span>,</span></span> <span ng-if=\"!$first && !$last\"><strong ng-bind=\"input.label\"></strong></span> <span ng-if=\"$last\">& <strong ng-bind=\"input.label\"></strong></span></span> number from your pin</label><input class=\"input input-pin\" id=\"input-pin\" type=\"tel\" name=\"inputPin\" ng-model=\"vm.pinPlainText\" maxlength=\"3\" minlength=\"3\" required ng-disabled=\"vm.isDisabled\" autocomplete=\"off\" cork-ui-focus-on=\"pp.input-pin.setFocus\"></div></ng-form>"
  );


  $templateCache.put('components/_angular/input-plus-minus/input-plus-minus.tpl.html',
    "<ng-form class=\"pp-plus-minus-number-input\" name=\"vm.ppInputPlusMinus\"><div class=\"symbol-wrapper\"><div class=\"symbol-container\"><span class=\"value-copy\" ng-bind=\"vm.stringAmount\"></span><span ng-hide=\"vm.ppInputPlusMinus.amount.$error.number\" class=\"symbol\" ng-bind=\"::vm.templateSymbol\"></span></div></div><div class=\"input-container\"><button type=\"button\" class=\"icon minus\" ng-click=\"vm.decrement()\" tabindex=\"-1\"><i class=\"i-minus\"></i></button> <input class=\"input\" name=\"amount\" type=\"number\" ng-model=\"vm.amount\" pp-bind-invalid-model=\"vm.invalidAmount\" cork-ui-focus-on=\"{{vm.focusOn}}\" ng-disabled=\"vm.inputDisabled\" required step=\"{{vm.step}}\" min=\"{{vm.minimum}}\" max=\"{{vm.maximum}}\" autocomplete=\"off\" placeholder=\"{{vm.placeholder}}\"> <button type=\"button\" class=\"icon plus\" ng-click=\"vm.increment()\" tabindex=\"-1\"><i class=\"i-plus\"></i></button></div></ng-form>"
  );


  $templateCache.put('components/_angular/input-range/input-range.tpl.html',
    "<div class=\"pp-input-range\"><input type=\"range\" ng-input-range name=\"{{vm.ppName}}\" ng-model=\"vm.ppValue\" min=\"{{vm.min}}\" max=\"{{vm.max}}\" step=\"{{vm.step}}\" ng-disabled=\"vm.isDisabled\"></div>"
  );


  $templateCache.put('components/_angular/input-search/input-search.tpl.html',
    "<div class=\"pp-input-search animated-input\"><span class=\"btn-clear\" ng-show=\"vm.searchTerm\" ng-click=\"vm.clear()\">Clear</span> <input type=\"text\" ng-model=\"vm.searchTerm\" class=\"underline\" ng-class=\"{'with-content': vm.searchTerm}\" placeholder=\"{{vm.placeholder}}\" cork-ui-focus-on=\"{{vm.focusOn}}\"> <i class=\"i-search\"></i><label class=\"animated-label\"></label></div>"
  );


  $templateCache.put('components/_angular/input-sort-code/input-sort-code.tpl.html',
    "<div class=\"pp-input-sort-code\"><input ng-model=\"vm.sort.one\" type=\"tel\" maxlength=\"2\" pp-auto-tab> - <input class=\"middle\" ng-model=\"vm.sort.two\" type=\"tel\" maxlength=\"2\" pp-auto-tab pp-auto-tab-reverse> - <input class=\"last\" ng-model=\"vm.sort.three\" type=\"tel\" maxlength=\"2\" pp-auto-tab-reverse></div>"
  );


  $templateCache.put('components/_angular/list-menu-expandable-items/list-menu-expandable-items.tpl.html',
    "<div class=\"pp-list-menu-expandable-items\" ng-class=\"{'open': vm.open}\"><pp-list-menu-expander-item label=\"vm.data.label\" is-open=\"vm.open\"></pp-list-menu-expander-item><div class=\"list\"><pp-list-menu-item ng-repeat=\"item in vm.data.items\" ng-if=\"!item.hidden\" class=\"item individual-item\" item=\"item\" on-active=\"vm.onActive()\"></pp-list-menu-item></div></div>"
  );


  $templateCache.put('components/_angular/list-menu-expander-item/list-menu-expander-item.tpl.html',
    "<div class=\"pp-list-menu-expander-item\" ng-click=\"vm.toggle()\"><span ng-bind=\"vm.label\"></span> <i class=\"icon i-plus\" ng-class=\"{'open': vm.isOpen}\"></i></div>"
  );


  $templateCache.put('components/_angular/list-menu-item/list-menu-item.tpl.html',
    "<a ng-if=\"vm.item.href\" ng-href=\"{{vm.item.href}}\" target=\"_self\" class=\"pp-list-menu-item\" ng-class=\"{'active': vm.isActive}\" ng-click=\"vm.onClick()\"><span class=\"label-outer\"><span class=\"label\" ng-bind=\"vm.item.label\"></span> <span class=\"sub-label\" ng-if=\"vm.item.subLabel\" ng-bind=\"vm.item.subLabel\"></span></span></a> <a ng-if=\"vm.item.sref\" ui-sref=\"{{vm.item.sref}}\" ui-sref-active=\"active\" class=\"pp-list-menu-item\" ng-click=\"vm.onClick()\"><span class=\"label-outer\"><span class=\"label\" ng-bind=\"vm.item.label\"></span> <span class=\"sub-label\" ng-if=\"vm.item.subLabel\" ng-bind=\"vm.item.subLabel\"></span></span></a>"
  );


  $templateCache.put('components/_angular/list-menu/list-menu.tpl.html',
    "<div class=\"pp-list-menu\"><div ng-repeat=\"item in vm.items\" ng-if=\"!item.hidden\"><pp-list-menu-item ng-if=\"!item.items && !(item.authenticated && vm.user.anon)\" context=\"{{vm.context}}\" class=\"item individual-item\" item=\"item\"></pp-list-menu-item><pp-list-menu-expandable-items ng-if=\"item.items\" class=\"item list-item\" data=\"item\"></pp-list-menu-expandable-items></div></div>"
  );


  $templateCache.put('components/_angular/radio-button/radio-button.tpl.html',
    "<div class=\"pp-radio-button\" ng-class=\"{'active': vm.active, 'has-circle': vm.hasCircle}\"><div class=\"circle\" ng-if=\"vm.hasCircle\"><i class=\"icon i-regulation-tick\" ng-if=\"vm.active\"></i></div><div class=\"label\" ng-bind=\"vm.title\"></div></div>"
  );


  $templateCache.put('components/_angular/smart-input-date/smart-input-date.tpl.html',
    "<div ng-form name=\"vm.ppSmartInputDate\" class=\"pp-smart-input-date\"><input pp-validation-date id=\"{{vm.ppId}}smart-input-date\" date-min=\"vm.dateMin\" date-max=\"vm.dateMax\" class=\"date-input\" type=\"tel\" ng-focus=\"vm.onFocus()\" ng-blur=\"vm.formatDate(vm.date)\" ng-keydown=\"vm.onType($event)\" ng-model=\"vm.date\" ng-class=\"{'is-formatted': vm.isFormatted}\" ng-required=\"vm.isRequired\"><label class=\"link\" for=\"{{vm.ppId}}smart-input-date\" ng-show=\"vm.isFormatted\">Edit</label></div>"
  );


  $templateCache.put('components/_angular/table-row/table-row.tpl.html',
    "<td ng-repeat=\"column in ::vm.columns\" class=\"{{::vm.row.class}}\" ng-class=\"{'freeze-col': $first, 'is-sorted': vm.sortAttribute === column.key, 'highlighted': vm.row.highlighted}\"><span class=\"toggle\" ng-if=\"$first\" ng-class=\"{visible: vm.showToggles}\" pp-toggle-tick=\"vm.selectedItems[vm.row[vm.idKey]]\" ignore-clicks=\"true\"></span> <span class=\"cell {{::column.key}}\" ng-bind=\"::vm.row[column.key]\"></span></td>"
  );


  $templateCache.put('components/_angular/table-sort/table-sort.tpl.html',
    "<span class=\"pp-table-sort\" ng-class=\"{'with-notice': vm.isNoticePresent()}\" ng-click=\"vm.onClick()\"><span class=\"label with-tooltip\" ng-if=\"vm.tooltip\" pp-tooltip=\"{{vm.tooltip}}\" ng-bind=\"::vm.label\"></span> <span class=\"label\" ng-if=\"!vm.tooltip\" ng-bind=\"::vm.label\"></span><div class=\"notice\" ng-transclude=\"notice\"></div><i ng-class=\"vm.iconClass\" class=\"sort-icon\"></i></span>"
  );


  $templateCache.put('components/_angular/table/table.tpl.html',
    "<div class=\"pp-table\" ng-class=\"{'show-toggles': vm.showToggles, 'hide-toggles': !vm.showToggles}\"><div class=\"table-container\"><div class=\"table-scroll\"><table><thead><tr><th ng-repeat=\"column in ::vm.columns\" ng-class=\"{'freeze-col': $first, 'is-sorted': vm.sortAttribute === column.key}\"><span class=\"toggle\" ng-class=\"{visible: vm.showTogglesDelayed}\" ng-if=\"$first\" pp-toggle-tick=\"vm.selectedAll\" on-click=\"vm.selectAllClick(selected)\"></span> <span class=\"cell\" ng-class=\"{{::column.key}}\" pp-table-sort=\"{{::column.key}}\" current-sort=\"vm.sortAttribute\" current-order=\"vm.sortOrder\" label=\"{{::column.label}}\" on-change=\"vm.updateSort(attr, order)\"></span></th></tr></thead><tbody><tr ng-repeat=\"row in vm.data\" ng-click=\"vm.rowClick(row[vm.idKey])\" pp-table-row=\"row\" sort-attribute=\"vm.sortAttribute\" columns=\"::vm.columns\" id-key=\"vm.idKey\" selected-items=\"vm.selectedItems\" show-toggles=\"vm.showTogglesDelayed\"></tr></tbody></table></div></div></div>"
  );


  $templateCache.put('components/_angular/tabs/tabs-links.tpl.html',
    "<div class=\"pp-tabs\"><div class=\"tabs\" ng-class=\"{justified: vm.justified === 'true', 'panel-tabs': vm.panelTabs, 'underlined': vm.underlined}\"><a class=\"tab\" ng-if=\"!vm.underlined\" ui-sref-active=\"active\" ui-sref=\"{{tab.key}}\" ng-repeat=\"tab in vm.tabs\" ng-bind=\"tab.title\"></a> <span class=\"tabs-wrapper\" ng-if=\"vm.underlined\"><a class=\"tab\" ui-sref-active=\"active\" ui-sref=\"{{tab.key}}\" ng-repeat=\"tab in vm.tabs\" ng-bind=\"tab.title\"></a><hr ng-if=\"vm.underlined\" ng-hide=\"vm.justified === 'true'\" ng-style=\"vm.getActiveBarCss()\" class=\"ruler active-bar\"></span><hr ng-if=\"vm.underlined\" ng-show=\"vm.justified === 'true'\" ng-style=\"vm.getActiveBarCss()\" class=\"ruler active-bar\"></div></div>"
  );


  $templateCache.put('components/_angular/tabs/tabs.tpl.html',
    "<div class=\"pp-tabs\"><ul class=\"tabs\" ng-class=\"{justified: vm.justified === 'true', 'panel-tabs': vm.panelTabs, 'underlined': vm.underlined}\"><li class=\"tab\" ng-if=\"!vm.underlined\" ng-class=\"{active: tab.key === vm.active}\" ng-click=\"vm.switchTab(tab.key)\" ng-repeat=\"tab in vm.tabs\" ng-bind=\"tab.title\"></li><span class=\"tabs-wrapper\" ng-if=\"vm.underlined\"><li class=\"tab\" ng-class=\"{active: tab.key === vm.active}\" ng-click=\"vm.switchTab(tab.key)\" ng-repeat=\"tab in vm.tabs\" ng-bind=\"tab.title\"></li></span></ul></div>"
  );


  $templateCache.put('components/_angular/toggle-bricks/toggle-bricks.tpl.html',
    "<div class=\"pp-toggle-bricks\"><div class=\"wrapper\" ng-repeat=\"option in vm.options\"><button type=\"button\" class=\"option\" ng-class=\"{'selected': option.id === vm.value, 'disabled': vm.disabled}\" ng-click=\"vm.click(option.id)\" ng-disabled=\"vm.disabled\"><span class=\"tick\" pp-toggle-tick=\"option.id === vm.value\" ignore-clicks=\"true\"></span> <i ng-class=\"option.icon\" ng-if=\"option.icon\"></i> <span class=\"label\" ng-bind=\"option.label\"></span></button></div></div>"
  );


  $templateCache.put('components/_angular/toggle-button/toggle-button.tpl.html',
    "<div class=\"pp-toggle-button\"><button type=\"button\" class=\"btn sec\" ng-class=\"{'inactive': !vm.active}\" ng-click=\"vm.toggleActive()\" ng-disabled=\"vm.isDisabled\"><i class=\"icon i-checkbox-tick\" ng-show=\"vm.active\"></i> <i class=\"icon i-circle\" ng-hide=\"vm.active\"></i> <span ng-transclude></span></button></div>"
  );


  $templateCache.put('components/_angular/toggle-on-off/toggle-on-off.tpl.html',
    "<div class=\"pp-dot-loader pp-toggle-on-off\" ng-show=\"vm.updating\"><div class=\"loader-dot-left\"></div><div class=\"loader-dot-middle\"></div><div class=\"loader-dot-right\"></div></div><div class=\"toggle-on-off\" ng-hide=\"vm.updating\"><input type=\"checkbox\" name=\"toggle\" class=\"toggle\" ng-model=\"vm.value\"><label class=\"label\" ng-click=\"vm.update()\"><span class=\"value on\" ng-show=\"vm.value\">ON</span> <span class=\"value off\" ng-show=\"!vm.value\">OFF</span></label></div>"
  );


  $templateCache.put('components/_angular/toggle-options/toggle-options.tpl.html',
    "<div class=\"pp-toggle-options\"><button class=\"option\" ng-repeat=\"option in vm.options\" ng-class=\"{'selected': option.id === vm.currentId}\" ng-click=\"vm.click(option.id)\"><span class=\"value\"><i class=\"icon\" ng-class=\"option.icon\" ng-if=\"option.icon\"></i> <span class=\"label\" ng-bind=\"option.label\"></span></span> <span class=\"shadow\" ng-style=\"option.id === vm.currentId ? vm.shadowStyle : null\"><span class=\"value\"><i class=\"icon\" ng-class=\"option.icon\" ng-if=\"option.icon\"></i> <span class=\"label\" ng-bind=\"option.label\"></span></span></span></button></div>"
  );


  $templateCache.put('components/_angular/toggle-tick/toggle-tick.tpl.html',
    "<span class=\"pp-toggle-tick\" ng-click=\"vm.toggleClick()\" ng-class=\"{selected: vm.selected, disabled: vm.isDisabled, 'processing': vm.isProcessing, 'ignore-clicks': vm.ignoreClicks, 'flash': vm.flash}\"><i class=\"icon i-tick\"></i></span>"
  );
}]); })(angular); 