(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.number', []);

ngModule.service('numberService', [function () {
    var api = {};

    api.ceil2 = function (value) {
        return Math.ceil(value * 100) / 100;
    };

    api.round2 = function (value) {
        return Math.round(value * 100) / 100;
    };

    api.floor2 = function (value) {
        return Math.floor(value * 100) / 100;
    };

    api.isNaN = function (value) {
        return value !== value;
    };

    api.relativeValue = function (valueOne, valueTwo) {
        return valueTwo / valueOne;
    };

    api.percentageChange = function (comparison, base) {
        return (base - comparison) / base;
    };

    // Caveat will return false for strings that can be parsed into numbers
    // For example '999'
    api.comprehensiveIsNotANumber = function (input, $window) {
        return !$window.isFinite(input) ||
            angular.isArray(input) ||
            input === true ||
            input === false ||
            input === null;
    };

    // Caveat will return true for strings that can be parsed into numbers
    // For example '999'
    api.comprehensiveIsNumber = function (input, $window) {
        return $window.isFinite(input) &&
            !angular.isArray(input) &&
            input !== true &&
            input !== false &&
            input !== null;
    };

    return api;
}]);
})(window, window.angular);