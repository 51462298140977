(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.transfer-funds-between-investors', [
    'pp.widgets-templates',
    'pp.filters.numbers',
    'pp.services.investor',
    'pp.services.core',
    'pp.services.receipt',
    'pp.services.route',
    'pp.components.input-amount'
]);

var PAGE_NAME = 'move-funds-between-investors';

ngModule.component('ppTransferFundsBetweenInvestors', {
    templateUrl: 'widgets/_angular/transfer-funds-between-investors/transfer-funds-between-investors.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$q', '$window', 'investorService', 'ppTrack', 'ppConfig', 'receiptService', 'routeService', function ($scope, $q, $window, investorService, ppTrack, ppConfig, receiptService, routeService) {
        var vm = this;

        // -- initial state

        var _externalEndpoints = ppConfig.get('pp.external.endpoints') || {};

        vm.getMin = Math.min;

        // input amount is decoupled from quote.budget
        // quote.budget is updated to inputAmount OR zero if input is invald
        vm.inputRegexp = /^\d*(\.\d{1,2})?$/;

        var assetsEnpoint = _externalEndpoints.assets;

        vm.kinds = {
            general: {
                label: 'General',
                image: assetsEnpoint + '/images/icons/isa/general-portfolio.svg',
                maximum: Infinity
            },
            isa: {
                label: 'ISA',
                image: assetsEnpoint + '/images/icons/isa/isa-portfolio.svg'
            }
        };

        // -- util functions

        // always take investor that you are not currently mounted to transfer from
        // This is because action of going to transfer page from ISA portfolio
        // should be transfer to ISA by default
        function initFromInvestor(currentInvestor, investors) {
            return investors.reduce(function (fromInvestor, investor) {
                if (fromInvestor) {
                    return fromInvestor;
                }

                if (investor.investorId !== currentInvestor.investorId) {
                    return investor;
                }
            }, undefined);
        }

        // When user data is reloaded
        // select the same from investor as was previously selected
        function getFromInvestor(fromInvestor, investors) {
            return investors.reduce(function (newFromInvestor, investor) {
                if (newFromInvestor) {
                    return newFromInvestor;
                }

                if (investor.investorId === fromInvestor.investorId) {
                    return investor;
                }
            }, undefined);
        }

        function getToInvestor(fromInvestor, investors) {
            return investors.reduce(function (toInvestor, investor) {
                if (toInvestor) {
                    return toInvestor;
                }

                if (investor.investorId !== fromInvestor.investorId) {
                    return investor;
                }
            }, undefined);
        }

        function handleSuccess(amount) {
            var receipt = {
                receiptType: 'FUNDS_MOVED',
                amount: amount,
                fromInvestorLabel: vm.kinds[vm.fromInvestor.kind].label,
                toInvestorLabel: vm.kinds[vm.toInvestor.kind].label
            };

            receiptService.pushReceipt(receipt);
            $window.location.href = routeService.dashboardPath;
        }

        function loadUserInvestor() {
            return $q.all({
                investor: investorService.getInvestor(),
                investors: investorService.getUserInvestors(),
                remainingIsaAmount: investorService.getIsaAmountRemaining()
            }).then(function (res) {
                vm.investor = res.investor;
                vm.investors = res.investors;
                vm.fromInvestor = vm.fromInvestor ? getFromInvestor(vm.fromInvestor, vm.investors) : initFromInvestor(vm.investor, vm.investors);
                vm.toInvestor = getToInvestor(vm.fromInvestor || {}, vm.investors);
                vm.remainingIsaAmount = res.remainingIsaAmount;
                vm.kinds.isa.maximum = vm.remainingIsaAmount;
            });
        }

        // -- api

        vm.submit = function () {
            vm.feedbackEnabled = true;
            vm.error = false;

            var payload = {
                amount: vm.inputAmount,
                from: vm.fromInvestor.investorId,
                to: vm.toInvestor.investorId
            };

            ppTrack.event(PAGE_NAME + '.submit', payload);

            if (vm.form.$valid) {
                vm.processing = true;
                ppTrack.formSubmit(PAGE_NAME, payload);
                investorService.transferFundsToInvestor(payload.amount, payload.from, payload.to).then(function () {
                    ppTrack.event(PAGE_NAME + '.success', payload);
                    investorService.purgeCache(/investor|user/);
                    return handleSuccess(payload.amount);
                }).then(function () {
                    vm.feedbackEnabled = false;
                    vm.inputAmount = undefined;
                }).catch(function () {
                    vm.processing = false;
                    vm.error = true;
                });
            } else {
                ppTrack.ngFormValidation(PAGE_NAME, vm.form);
            }
        };

        // -- scope bindings

        $scope.$watch('vm.fromInvestor', function (fromInvestor) {
            if (!fromInvestor || !angular.isArray(vm.investors)) {
                return;
            }
            vm.toInvestor = getToInvestor(fromInvestor || {}, vm.investors);
        });

        // -- main

        loadUserInvestor().then(function (res) {
            ppTrack.pageLoad(PAGE_NAME);
            vm.ready = true;
        });

    }]
});
})(window, window.angular);