(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-social-sharing', [
    'pp.widgets-templates',
    'pp.services.route',
    'pp.services.core'
]);

var DEFAULT_FB_TITLE = 'Check out the latest property on London House Exchange';

ngModule.component('ppPropertySocialSharing', {
    templateUrl: 'widgets/_angular/property-social-sharing/property-social-sharing.tpl.html',
    bindings: {
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', 'routeService', 'ppTrack', function ($scope, $window, routeService, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions

        function createWhatsAppLink(propertyUrl) {
            var base = 'whatsapp://send?text=';
            var text = 'Check out the latest property on London House Exchange ' + propertyUrl + ' Capital at risk.';
            return base + encodeURIComponent(text);
        }

        function fbShare(url, title, descr) {
            return function () {
                var windowHeight = 350;
                var windowWidth = 520;
                var alignTop = (screen.height / 2) - (windowHeight / 2);
                var alignLeft = (screen.width / 2) - (windowWidth / 2);
                var facebookShareUrl = 'https://www.facebook.com/sharer.php?s=100&p[title]=' + title + '&p[summary]=' + descr + '&p[url]=' + url;

                ppTrack.action('property-social-sharing.facebook-share.click');
                $window.open(facebookShareUrl, '', 'top=' + alignTop + ',left=' + alignLeft + ',width=' + windowWidth + ',height=' + windowHeight);
            };
        }

        // -- api

        vm.onWhatsAppClick = function () {
            ppTrack.action('property-social-sharing.whatsapp-share.click');
        };

        vm.onEmailClick = function () {
            ppTrack.action('property-social-sharing.email-share.click');
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            vm.propertyUrl = routeService.origin + routeService.getIndividualPropertyPath(vm.property.symbol);
            vm.fbShare = fbShare(vm.propertyUrl, DEFAULT_FB_TITLE, '');
            vm.whatsAppShareLink = createWhatsAppLink(vm.propertyUrl);
        };

    }]
});
})(window, window.angular);