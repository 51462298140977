(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.month-validation', []);

var NUMBER_REGEX = /[0-9]{1,2}$/;

var MAX_MONTH = 12;
var MIN_MONTH = 1;

/**
 * @ngdoc directive
 * @name ppMonthValidation
 * @description
 *
 * @restrict A
 * @requires ngModel
 *
 * @param {string} ngModel Assignable angular expression to data-bind to.
 */
ngModule.directive('ppMonthValidation', [function () {

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, $elem, $attrs, $ctrl) {

            // NOTE: anti-pattern test specific code, but no other way to check if a transparent directive was instantiated correctly
            $scope.ppTestHelperDirectiveName = 'pp-month-validation';

            $ctrl.$validators.invalidMonth = function (modelValue, viewValue) {
                if ($ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                if (!NUMBER_REGEX.test(viewValue)) {
                    return false;
                }

                var value = parseInt(viewValue);

                if (value) {
                    return value <= MAX_MONTH && value >= MIN_MONTH;
                }

                return false;

            };

        },
    };
}]);
})(window, window.angular);