(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.wistia-video-dialog', ['pp.services.core', 'pp.services.dialog', 'pp.widgets.wistia-video']);

/**
 * @ndgoc service
 * @description
 * Displays a modal with a video embed from wistia.
 */
ngModule.service('wistiaVideoDialogService', ['$rootScope', 'ppTrack', 'dialogService', function ($rootScope, ppTrack, dialogService) {

    // -- util functions

    // create a scope, show the dialog
    function showDialog(videoId) {
        ppTrack.action('wistia-video-dialog.show', {
            videoId: videoId
        });
        var scope = $rootScope.$new();
        scope.videoId = videoId;
        var options = {
            clickClose: true,
            escapeClose: true,
            showClose: true,
            classNames: 'modal-video',
            scope: scope
        };
        options.contents = '<pp-wistia-video video-id="videoId"></pp-wistia-video>';

        return dialogService.show(options).then(function () {
            scope.$destroy();
        });
    }

    // -- api

    var api = {};

    api.show = function (videoId) {
        return showDialog(videoId);
    };

    return api;
}]);
})(window, window.angular);