(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.withdrawal-form', [
    'pp.widgets-templates',
    'pp.widgets.bank-account-select',
    'pp.components.input-amount',
    'pp.components.bind-invalid-model',
    'pp.services.core'
]);

var FORM_NAME = 'account.withdrawal';
var VERIFIED_STATUS = 'VERIFIED';

ngModule.directive('ppWithdrawalForm', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/withdrawal-form/withdrawal-form.tpl.html',
        scope: {
            user: '<',
            isIsaSetup: '<',
            funds: '<',
            accounts: '<',
            minWithdrawal: '<',
            onAddAccount: '&',
            onSubmit: '&',
            openUpdateForm: '&',
            deleteAccount: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppTrack', 'R', 'routeService', function ($scope, ppTrack, R, routeService) {
            var vm = this;

            // -- initial state

            vm.decimalNumberRegexp = /^\d+(\.\d{1,2})?$/;

            vm.learnMoreBankAccountVerification = routeService.learnMoreBankAccountVerification;

            vm.investorType = {
                general: {
                    label: 'General'
                },
                isa: {
                    label: 'ISA'
                }
            };

            vm.form = {};

            // -- util functions

            function handleSuccess() {
                ppTrack.formSubmit(FORM_NAME);
            }

            function handleFailure(error) {
                vm.feedbackEnabled = true;
                vm.error = error.reason;
                ppTrack.formError(FORM_NAME, error.reason);
            }

            function handleFinally() {
                vm.processing = false;
            }

            function setAccountValidity(account) {
                if (R.path(['verificationStatus'], account) !== VERIFIED_STATUS) {
                    vm.form.$setValidity('accountValid', false);
                } else {
                    vm.form.$setValidity('accountValid', true);
                }
            }

            // -- api

            vm.submitWithdrawal = function ($event) {
                if (vm.form.$valid) {
                    vm.processing = true;
                    vm.feedbackEnabled = false;

                    var data = {
                        amount: vm.amount,
                        account: vm.account,
                        password: vm.password
                    };

                    vm.onSubmit(data)
                        .then(handleSuccess, handleFailure)
                        .finally(handleFinally);
                } else {
                    vm.feedbackEnabled = true;
                    ppTrack.ngFormValidation('withdraw.withdraw-funds', vm.form);
                }

            };

            vm.onOpenUpdateForm = function (account) {
                vm.openUpdateForm({
                    account: account
                });
            };

            // This is not used at the moment might be reinstated
            // need to resolve issues with readding bank accounts
            // with same display name and account number after deletion
            vm.onDeleteAccount = function (account) {
                return vm.deleteAccount({
                    account: account
                });
            };

            vm.$onChanges = function (changes) {
                if (changes.user && changes.user.currentValue !== changes.user.previousValue) {
                    vm.feedbackEnabled = false;
                }
            };

            $scope.$watch('vm.account', function (account) {
                if (account) {
                    setAccountValidity(account);
                }
            });

            // -- scope bindings

            // -- main

            vm.ready = true;
        }]
    };
}]);
})(window, window.angular);