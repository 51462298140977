(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-mobile', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.route',
    'pp.filters.numbers',
    'pp.filters.dates',
    'pp.filters.property'
]);

/**
 * @ngdoc directive
 * @name ppPropertyCard
 * @description
 * Provides a color banner with the property Card.
 *
 * @restrict A
 * @scope
 * @param {Object} ppPropertyCard the property model
 */
ngModule.component('ppPropertyCardMobile', {
    templateUrl: 'widgets/_angular/property-card-mobile/property-card-mobile.tpl.html',
    bindings: {
        property: '<'
    },
    controllerAs: 'vm',
    bindToController: true,
    controller: ['$scope', '$window', '$timeout', '$element', '$filter', 'ppConfig', 'ppTrack', 'ppUtil', 'ppComponentEmitter', 'routeService', function ($scope, $window, $timeout, $element, $filter, ppConfig, ppTrack, ppUtil, ppComponentEmitter, routeService) {
        var vm = this;

        vm.ready = true;

        var __endpoints = ppConfig.get('pp.external.endpoints') || {};
        var assetsEndpoint = __endpoints.assets;

        // // -- initial state

        // -- util functions

        function createAmountAvailable(property) {
            if (!ppUtil.hasPathIn(property, 'valuation') || !ppUtil.hasPathIn(property, 'market.primary')) {
                return;
            }
            var available = property.valuation.latestValue - property.market.primary.fundedAmount;
            return $filter('ppCurrency')(available, '£', 0);
        }

        function createThumbnail(property) {
            return {
                'background-image': 'url(' + assetsEndpoint + '/images/properties/' + property.symbol + '/thumb/img1.jpg)'
            };
        }

        // -- api

        vm.goToProperty = function () {
            ppTrack.deferAction('pp.map-view.property-card-click', {
                symbol: vm.property.symbol
            });
        };

        // -- scope bindings

        vm.$onChanges = function (changes) {
            if (!changes.property || !changes.property.currentValue) {
                return;
            }

            var property = changes.property.currentValue;

            ppComponentEmitter.emit('property-card-mobile.dom.loaded.' + property.symbol, $element);
            ppComponentEmitter.emit('property-card-mobile.controller.loaded.' + property.symbol, vm);

            vm.amountAvailable = createAmountAvailable(property);

            vm.thumbnail = createThumbnail(property);

            vm.link = routeService.getIndividualPropertyPath(property.symbol);
        };

        // -- main

    }]
});
})(window, window.angular);