(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.cookie-banner-dialog', ['pp.services.browser-store', 'pp.widgets.cookie-banner']);

var COOKIE_BANNER_KEY = 'cookie-banner';
var COOKIE_BANNER_BODY_CLASS = 'has-bottom-banner';

ngModule.service('cookieBannerDialogService', ['$rootScope', '$document', '$compile', '$timeout', 'browserStoreService', function ($rootScope, $document, $compile, $timeout, browserStoreService) {

    function getDocumentBody() {
        return $document.find('body');
    }

    function close(element) {
        var container = getDocumentBody();
        container.removeClass(COOKIE_BANNER_BODY_CLASS);
        element.remove();
    }

    var api = {};

    api.show = function () {
        var scope = $rootScope.$new();

        // append banner to body
        var container = getDocumentBody();

        // compile the cookie banner template
        var contents = '<pp-cookie-banner on-close="onClose()"></pp-cookie-banner>';

        // timeout needed as this is being called from run block
        // Ensure angular does not compile in its own compile cycle in
        // addition to this manual compilation
        $timeout(function () {
            var element = $compile(contents)(scope);
            container.append(element);
            scope.onClose = close.bind(null, element);
        });

        // add class to body to indicate banner is present
        container.addClass(COOKIE_BANNER_BODY_CLASS);

        browserStoreService.setLocalStorageItem(COOKIE_BANNER_KEY, true);
    };

    api.hasBannerBeenSeen = function () {
        return !!browserStoreService.getLocalStorageItem(COOKIE_BANNER_KEY);
    };

    return api;
}]);
})(window, window.angular);