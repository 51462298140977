(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.auth-signup-email-form', ['pp.widgets-templates', 'pp.services.auth', 'pp.services.core']);

ngModule.directive('ppAuthSignupEmailForm', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/auth-signup-email-form/auth-signup-email-form.tpl.html',
        scope: {
            onSubmit: '&',
            ctaText: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'authService', 'ppTrack', function ($scope, authService, ppTrack) {
            var vm = this;

            // -- initial state

            vm.form = {};

            // -- util functions

            // -- api

            vm.submit = function () {
                vm.onSubmit({
                    email: vm.email
                });
                ppTrack.action('auth-signup-email-form.submit');
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);