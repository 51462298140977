(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.password-validation', []);

/**
 * @ngdoc directive
 * @name ppPasswordValidation
 * @description
 * Validates password against hard-coded and intrusive rules.
 *
 * @restrict A
 * @requires ngModel
 *
 * @param {string} ngModel Assignable angular expression to data-bind to.
 */
ngModule.directive('ppPasswordValidation', [function () {

    var PATTERN_LETTER = /[a-zA-Z]/;
    var PATTERN_NUM = /[0-9]/;
    var MIN_LENGTH = 8;

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, $elem, $attrs, $ctrl) {

            // NOTE: anti-pattern test specific code, but no other way to check if a transparent directive was instantiated correctly
            $scope.ppTestHelperDirectiveName = 'pp-password-validation';

            $ctrl.$validators.passwordLetters = function (modelValue, viewValue) {
                if ($ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                return PATTERN_LETTER.test(viewValue);
            };
            $ctrl.$validators.passwordNumbers = function (modelValue, viewValue) {
                if ($ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                return PATTERN_NUM.test(viewValue);
            };
            $ctrl.$validators.passwordLength = function (modelValue, viewValue) {
                if ($ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                return modelValue.length >= MIN_LENGTH;
            };
        },
    };
}]);
})(window, window.angular);