(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.suitability-quiz', [
    'pp.widgets-templates',
    'pp.widgets.multiple-choice-question',
    'pp.widgets.previous-next-stepper',
    'pp.services.suitability',
    'pp.services.route',
    'pp.services.core'
]);

ngModule.component('ppSuitabilityQuiz', {
    templateUrl: 'widgets/_angular/suitability-quiz/suitability-quiz.tpl.html',
    bindings: {
        suitability: '<',
        quizType: '<',
        onSubmit: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'suitabilityService', 'routeService', 'ppUtil', 'ppTrack', function ($scope, suitabilityService, routeService, ppUtil, ppTrack) {
        var vm = this;

        // -- initial state

        vm.answers = {};
        vm.currentQuestion = 0;

        vm.contactUsPath = routeService.contactUsPath;
        vm.marketplacePath = routeService.marketplacePath;

        // -- util functions

        function getQuestionTransform(position) {
            return {
                'transform': 'translateX(' + ((position) * -100) + '%)'
            };
        }

        function submit() {
            vm.submitError = false;
            vm.submitted = true;
            ppTrack.action('suitability-quiz.submit');
            vm.onSubmit({
                answers: vm.answers
            }).then(function () {
                vm.retake = false;
                ppTrack.event('suitability-quiz.submit.success');
            }, function (err) {
                vm.submitError = true;
                ppTrack.event('suitability-quiz.submit.error');
            });
        }

        function goToStart() {
            vm.currentQuestion = 0;
            vm.questionTransform = getQuestionTransform(vm.currentQuestion);
        }

        function goToNextQuestion() {
            if (vm.questions) {
                var numberOfQuestions = Object.keys(vm.questions).length;
                if (vm.currentQuestion < (numberOfQuestions - 1)) {
                    vm.currentQuestion++;
                    vm.questionTransform = getQuestionTransform(vm.currentQuestion);
                } else {
                    submit();
                }
            }
        }

        function goToPreviousQuestion() {
            if (vm.questions) {
                if (vm.currentQuestion > 0) {
                    vm.currentQuestion--;
                    vm.questionTransform = getQuestionTransform(vm.currentQuestion);
                }
            }
        }

        function createQuestionsArray(questions) {
            if (questions) {
                return Object.keys(questions).reduce(function (list, key) {
                    var questionKey = {
                        questionKey: key
                    };
                    var item = ppUtil.extend(questionKey, questions[key]);
                    return list.concat([item]);
                }, []);
            }
        }

        // -- api

        vm.goToPreviousQuestion = goToPreviousQuestion;

        vm.goToNextQuestion = goToNextQuestion;

        vm.hasQuestionBeenAnswered = function () {
            var question = vm.questions[vm.currentQuestion] || {};
            return vm.answers[question.questionKey];
        };

        vm.setAnswer = function (questionKey, answerKey) {
            vm.answers[questionKey] = answerKey;
            ppTrack.action('suitability-quiz.set-answer', {
                question: questionKey,
                questionNumber: vm.currentQuestion,
                answerKey: answerKey
            });
            goToNextQuestion();
        };

        vm.retakeQuiz = function () {
            goToStart();
            ppTrack.action('suitability-quiz.retake');
            vm.retake = true;
        };

        // -- scope bindings

        vm.$onChanges = function (changes) {
            if (changes.suitability && changes.suitability.currentValue && changes.suitability.currentValue.questions) {
                vm.currentQuestion = 0;
                vm.answers = {};
                vm.questions = createQuestionsArray(changes.suitability.currentValue.questions);
                goToStart();
                vm.ready = true;
            }
        };

        vm.$onInit = function () {
            ppTrack.event('suitability-quiz.start');
        };

        // -- main

    }]
});
})(window, window.angular);