(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.auth-signup', [
    'pp.widgets-templates',
    'pp.widgets.auth-signup-form',
    'pp.services.route',
    'pp.services.core'
]);

var PAGE_NAME = 'signup-page';

ngModule.component('ppAuthSignup', {
    templateUrl: 'widgets/_angular/auth-signup/auth-signup.tpl.html',
    bindings: {
        email: '@'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', 'routeService', 'ppTrack', function ($scope, $window, routeService, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions

        function redirect(path) {
            ppTrack.closeAll().then(function () {
                $window.location.href = path;
            });
        }

        // -- api

        vm.goToLogin = function () {
            ppTrack.deferAction(PAGE_NAME + '.go-to-signup');
            $window.location.href = routeService.loginPath;
        };

        vm.onSuccess = function (fallbackPath) {
            ppTrack.deferEvent(PAGE_NAME + '.signup.success');
            redirect(fallbackPath);
        };

        vm.onChangeSignupStep = function (step) {
            vm.signupStep = step;
        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            ppTrack.pageLoad(PAGE_NAME);
        };

    }]
});
})(window, window.angular);