(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-simple', [
    'pp.widgets-templates',
    'pp.widgets.property-card-simple-thumbnail',
    'pp.widgets.property-card-simple-figures',
    'pp.widgets.property-card-simple-cta',
    'pp.widgets.property-card-name',
    'pp.services.core',
    'pp.services.route'
]);

ngModule.component('ppPropertyCardSimple', {
    templateUrl: 'widgets/_angular/property-card-simple/property-card-simple.tpl.html',
    bindings: {
        property: '<',
        disableLink: '<',
        user: '<',
        holdings: '<',
        bids: '<',
        offers: '<',
        viewDetail: '&',
        isLink: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', 'ppComponentEmitter', function ($scope, routeService, ppComponentEmitter) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        vm.onViewDetail = function () {
            vm.viewDetail({
                property: vm.property
            });
        };

        vm.$onChanges = function (changes) {
            if (changes.disableLink && changes.disableLink.currentValue) {
                vm.path = undefined;
            } else if (changes.property && !changes.property.isFirstChange() && changes.property.currentValue) {
                vm.path = routeService.getIndividualPropertyPath(changes.property.currentValue.symbol);
            }
        };

        // -- main

        vm.$onInit = function () {
            if (vm.property && vm.property.symbol) {
                ppComponentEmitter.emit('property-card.controller.loaded.' + vm.property.symbol, vm);
            }

            if (!vm.disableLink) {
                vm.path = routeService.getIndividualPropertyPath(vm.property.symbol);
            }
        };

    }]
});
})(window, window.angular);