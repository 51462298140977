(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.floorplan', ['ngSanitize', 'pp.widgets-templates', 'pp.services.core']);

ngModule.directive('ppFloorplan', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/floorplan/floorplan.tpl.html',
        scope: {
            property: '<ppFloorplan'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'ppConfig', 'ppTrack', 'ppBrowser', function ($scope, ppConfig, ppTrack, ppBrowser) {
            var vm = this;

            // -- initial state

            var endpoints = ppConfig.get('pp.external.endpoints') || {};
            vm.baseUrl = endpoints.assets;

            // -- util functions

            // -- api

            vm.openFloorplanModal = function () {
                if (ppBrowser.isMobile) {
                    return;
                }

                ppTrack.action('floorplan-modal.open');
                // @TODO use dialogService
                $('#floorplan-modal').modal({
                    showClose: true,
                    escapeClose: true,
                    clickClose: true
                });
            };

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);