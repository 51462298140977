(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.dashboard', [
    'pp.services.investor',
    'pp.services.property',
    'pp.services.core',
    'pp.services.number',
    'pp.services.route',
    'pp.filters.property',
    'pp.filters.numbers',
    'pp.values.dashboard'
]);

var MAX_PROPERTY_SYMBOL_FILTER = 50;

ngModule.service('dashboardService', ['$http', '$q', '$filter', 'investorService', 'propertyService', 'numberService', 'ppTrack', 'ppUtil', 'ppBig', 'ppMoment', 'routeService', 'dashboard', 'R', function ($http, $q, $filter, investorService, propertyService, numberService, track, ppUtil, ppBig, ppMoment, routeService, dashboard, R) {

    var api = {};

    var promiseCache = {};

    var PAGE_NAME = 'dashboard';

    var FEAPI_BASE = '/feapi/r1';

    var DASHBOARD_DATA_ENDPOINT = '/investor/dashboard-data';
    var DASHBOARD_GRAPH_ENDPOINT = '/investor/dashboard-graph';

    var TOTAL_RETURN_ENDPOINT = FEAPI_BASE + '/investor/dashboard/current-data';

    var INCOME_BY_MONTH_ENDPOINT = FEAPI_BASE + '/investor/income-by-month';

    function normaliseForecastMonthlyDividends(investor, data) {
        var financials = investor.financials || {};
        data = data || {};

        return {
            header: {
                tooltipKey: 'forecastMonthlyDividends',
                label: 'Forecast monthly dividends',
                value: $filter('ppCurrency')(financials.totalRentalIncomePcm),
            },
            breakdown: [{
                tooltipKey: 'forecastAverageYield',
                label: 'Forecast average yield',
                value: $filter('ppPercentage')(data.currentAverageYield * 100, 2),
            }, {
                tooltipKey: 'forecastAnnualDividends',
                label: 'Forecast annual dividends',
                value: $filter('ppCurrency')(financials.totalRentalIncomePcm * 12)
            }]
        };
    }

    function normaliseCurrentValuation(investor, data) {
        var financials = investor.financials || {};
        var investmentTotals = data.investmentTotals || {};

        return {
            header: {
                tooltipKey: 'currentValuation',
                label: 'Current valuation',
                value: $filter('ppCurrency')(financials.totalInvestmentValuation),
            },
            breakdown: [{
                tooltipKey: 'costOfCurrentHoldings',
                label: 'Cost of current holdings',
                value: $filter('ppCurrency')(investmentTotals.costCurrentInvestments),
            }, {
                tooltipKey: 'unrealisedCapitalGains',
                label: 'Unrealised capital gains',
                value: $filter('ppCurrency')(investmentTotals.unrealisedGains),
                differenceFigure: {
                    value: investmentTotals.unrealisedGains ? $filter('ppPercentage')(((financials.totalInvestmentValuation - investmentTotals.costCurrentInvestments) / investmentTotals.costCurrentInvestments) * 100, 1) : null,
                    rawValue: investmentTotals.unrealisedGains ? ((financials.totalInvestmentValuation - investmentTotals.costCurrentInvestments) / investmentTotals.costCurrentInvestments) * 100 : null,
                }
            }]
        };
    }

    function normaliseTotalReturn(investor, data) {
        var investmentTotals = data.investmentTotals || {};
        var historicHoldingsStats = data.historicHoldingsStats || {};
        var financials = investor.financials || {};

        return {
            header: {
                tooltipKey: 'totalReturn',
                label: 'Total return',
                value: $filter('ppCurrency')(data.estimatedTotalReturn)
            },
            breakdown: [{
                tooltipKey: 'realisedCapitalGains',
                label: 'Realised capital gains',
                value: $filter('ppCurrency')(investmentTotals.realisedGains)
            }, {
                tooltipKey: 'totalDividends',
                label: 'Total dividends',
                value: $filter('ppCurrency')(historicHoldingsStats.totalDividends)
            }, {
                tooltipKey: 'promotions',
                label: 'Promotions',
                value: $filter('ppCurrency')(financials.totalCashback)
            }]
        };
    }

    function createPropertyListParamsForCollection(collection) {
        var symbolsAdded = {};

        var params = collection.map(function (item) {
            var symbol = item.symbol || item.propertySymbol;

            // so we can filter out duplicate symbols
            if (symbolsAdded[symbol]) {
                return;
            }

            symbolsAdded[symbol] = true;

            return {
                key: 'symbol',
                value: item.symbol || item.propertySymbol
            };
        }).filter(function (item) {
            return !!item;
        });

        // Fix for issue where url can get too long if too many symbols are in url
        if (params && params.length > MAX_PROPERTY_SYMBOL_FILTER) {
            return [];
        } else {
            return params;
        }
    }

    function isHoldingALoan(properties, item) {
        var symbol = item.symbol || item.propertySymbol;
        var property = properties[symbol] || {};
        return property.isDevelopmentLoan;
    }

    /**
     * @ngdoc method
     * @name dashboardService#getDashboardGraph
     *
     * @description
     * Returns data for the dashboard graph for an investor
     *
     * @returns {Promise}
     *
     * @todo handle rejections, purge rejections from cache
     */
    api.getDashboardGraph = function () {
        var cacheKey = 'dashboard.graph';
        var endpoint = DASHBOARD_GRAPH_ENDPOINT;

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint).then(function (response) {
                var graphData = response.data;
                return graphData;
            }, function (error) {
                promiseCache[cacheKey] = false;
                return $q.reject(error);
            });
        }

        return promiseCache[cacheKey];
    };

    api.getDashboardData = function () {
        var cacheKey = 'dashboard.data';
        var endpoint = DASHBOARD_DATA_ENDPOINT;

        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint).then(function (response) {
                return response.data;
            }, function (error) {
                promiseCache[cacheKey] = false;
                return $q.reject(error);
            });
        }

        return promiseCache[cacheKey];
    };

    api.getInvestmentTotals = function () {
        return investorService.getInvestorPerformanceTotals().then(function (response) {
            var extraData = {
                dividends: Number(ppBig(response.rentalIncome || 0).round(2)),
                realisedgains: Number(ppBig(response.realisedGains || 0)
                    .minus(response.aggregatedFeesPaid || 0)
                    .minus(response.aggregatedTaxPaid || 0)
                    .round(2)
                ),
                autoInvestInterest: Number(ppBig(response.autoInvestInterest || 0).round(2)),
                total: Number(ppBig(response.rentalIncome)
                    .plus(response.realisedgains || 0)
                    .plus(response.autoInvestInterest || 0)
                )
            };

            return R.mergeLeft(extraData, response);
        });
    };

    api.normaliseNetContributions = function (user, hasMoreThanOneInvestor) {
        var data = {};
        data.breakdown = [];
        data.title = 'Net contributions';
        data.value = user.financials.totalDeposited + user.financials.totalWithdrawn + (user.financials.totalTransferred || 0);
        data.breakdown.push({
            label: 'Deposits',
            value: user.financials.totalDeposited
        });

        if (user.financials.totalTransferred || hasMoreThanOneInvestor) {
            data.breakdown.push({
                label: 'Moved funds',
                value: user.financials.totalTransferred
            });
        }

        data.breakdown.push({
            label: 'Withdrawals',
            value: user.financials.totalWithdrawn
        });

        return data;

    };

    api.normalisePerformance = function (values, investor, sold) {
        var data = {};
        var promotions = R.path(['financials', 'totalCashback'], investor);
        data.breakdown = [];
        data.title = 'Total return';
        data.value = Number(ppBig(values.dividends)
            .plus(sold.gainLoss)
            .plus(promotions || 0)
            .plus(values.autoInvestInterest || 0));

        data.breakdown.push({
            label: 'Dividends received',
            value: values.dividends,
            desc: dashboard.totalDividends.desc
        });

        data.breakdown.push({
            label: 'Realised capital gains',
            value: sold.gainLoss,
            desc: dashboard.sold.gainLoss.desc
        });

        // #PP-2378 only display AI interest if it is not zero
        // Autoinvest interest is now actually for all interest paid on debt products as well
        if (values.autoInvestInterest) {
            data.breakdown.push({
                label: 'Interest received',
                value: values.autoInvestInterest,
                desc: dashboard.interestReceived.desc
            });
        }

        if (promotions) {
            data.breakdown.push({
                label: 'Promotions',
                value: promotions,
                desc: dashboard.promotions.desc
            });
        }

        return data;
    };

    function normaliseCurrentHoldings(properties, item) {
        item.daysHeld = ppMoment().diff(item.averagePurchaseDate, 'days');
        item.exitMechanicDate = properties[item.symbol].exitMechanicJsDate;
        item.totalReturnVpvFirst = R.defaultTo(item.totalReturn, item.totalReturnVpv);
        item.gainLossVpvFirst = R.defaultTo(item.gainLoss, item.gainLossVpv);
        item.latestValuationVpvFirst = R.defaultTo(item.latestValuation, item.latestValuationVpv);
        item.capitalGainVpvFirst = R.defaultTo(item.capitalGain, item.capitalGainVpv);

        return item;
    }

    function addDashboardDataToCollection(res, withMarketData) {
        var propertyListParams = createPropertyListParamsForCollection(res.collection);
        return $q.all({
            data: res,
            properties: res.collection && res.collection.length ? propertyService.getPropertiesList(propertyListParams) : $q.when([]),
            marketData: withMarketData && res.collection && res.collection.length ? propertyService.fetchPropertiesMarketdata(propertyListParams) : $q.when([]),
            holdings: investorService.getHoldings('current'),
            bids: investorService.getBids(),
            offers: investorService.getHoldingsListed()
        }).then(function (res) {
            return {
                totals: res.data.totals,
                collection: res.data.collection,
                properties: ppUtil.indexBy(res.properties, 'symbol'),
                marketData: ppUtil.indexBy(res.marketData.collection, 'symbol'),
                holdings: ppUtil.indexBy(res.holdings, 'propertySymbol'),
                bids: ppUtil.indexListBy(res.bids, 'symbol'),
                offers: ppUtil.indexListBy(res.offers, 'propertySymbol')
            };
        });
    }

    api.getTotalReturn = function () {
        var endpoint = TOTAL_RETURN_ENDPOINT;

        return $http.get(endpoint)
            .then(function (res) {
                return addDashboardDataToCollection(res.data);
            })
            .then(function (res) {
                res.collection = res.collection
                    .map(normaliseCurrentHoldings.bind(null, res.properties));
                res.collection.sort(function (a, b) {
                    return Number(res.properties[b.symbol].isRightsIssueParent && !res.properties[b.symbol].hardCodedFundedPct) - Number(res.properties[a.symbol].isRightsIssueParent && !res.properties[a.symbol].hardCodedFundedPct);
                });
                return res;
            });
    };

    function normaliseBid(properties, marketDatas, bid) {
        var property = properties[bid.symbol] || {};
        var marketData = marketDatas[bid.symbol];
        var shareValuation = ppUtil.path(property, 'valuation.share');
        var bidPriceValuationPercentageDiff = Number(ppBig(shareValuation).minus(bid.unitPrice).div(bid.unitPrice));
        var dividendYield = ppUtil.path(property, 'income.net.pct');
        var yieldAtBidPrice = ppBig(dividendYield).times(1 + bidPriceValuationPercentageDiff);
        var costExcludingFees = ppBig(bid.unitPrice).times(bid.units);

        var extraData = {
            buyPrice: ppUtil.path(property, 'market.secondary.bestOffer.price'),
            vWap30Days: ppUtil.path(marketData, 'secondary.banding.referencePrice'),
            latestShareValuation: ppUtil.path(property, 'valuation.share'),
            dividendYield: Number(yieldAtBidPrice),
            dividendAmount: Number(costExcludingFees.times(yieldAtBidPrice.div(100)))
        };

        return ppUtil.extend({}, bid, extraData);
    }

    function normaliseOffer(properties, marketDatas, offer) {
        var symbol = offer.propertySymbol;
        var property = properties[symbol] || {};
        var marketData = marketDatas[symbol];
        var shareValuation = ppUtil.path(property, 'valuation.share');

        var extraData = {
            symbol: symbol,
            vWap30Days: ppUtil.path(marketData, 'secondary.banding.referencePrice'),
            latestShareValuation: ppUtil.path(property, 'valuation.share'),
            pctListedForSale: offer.unitsHeld ? Number(ppBig(offer.unitsOffered).div(offer.unitsHeld)) : 0
        };

        return ppUtil.extend({}, offer, extraData);
    }

    api.getBids = function () {
        return investorService.getBids().then(function (bids) {
            return addDashboardDataToCollection({
                collection: bids
            }, true).then(function (res) {
                res.collection = res.collection.map(normaliseBid.bind(null, res.properties, res.marketData));
                return res;
            });
        });
    };

    api.getOffers = function () {
        return investorService.getHoldingsListed().then(function (offers) {
            return addDashboardDataToCollection({
                collection: offers
            }, true).then(function (res) {
                res.collection = res.collection.map(normaliseOffer.bind(null, res.properties, res.marketData));
                return res;
            });
        });
    };

    api.getSoldInvestments = function () {
        return investorService.getHoldingsSold()
            .then(function (res) {
                return addDashboardDataToCollection({
                    collection: res.collection,
                    totals: res.totals
                });
            });
    };

    api.getPendingLoans = function () {
        return investorService.getHoldingsPending().then(function (holdings) {
            var propertyListParams = createPropertyListParamsForCollection(holdings);
            return $q.all({
                collection: $q.when(holdings),
                properties: holdings && holdings.length ? propertyService.getPropertiesList(propertyListParams) : $q.when([])
            });
        }).then(function (res) {
            var properties = ppUtil.indexBy(res.properties, 'symbol');
            res.collection = res.collection.filter(isHoldingALoan.bind(null, properties));
            res.properties = properties;
            return res;
        });
    };

    function normaliseLoan(loans, holding) {
        var symbol = holding.propertySymbol;
        var loan = loans[symbol] || {};
        var loanDetails = loan.loanDetails || {};

        var LOAN_TYPES = {
            'development_loan': 'Development Loan Bond'
        };

        return {
            symbol: symbol,
            status: loanDetails.loanStatus,
            netInterest: ppUtil.path(loan, 'income.net.pct'),
            totalCost: holding.totalCost || holding.totalValue,
            interestReceived: holding.interestReceived,
            termEnds: loanDetails.termEnds,
            interestPaid: loanDetails.interestPaid,
            propertyUrl: routeService.getIndividualPropertyPath(symbol),
            type: loan.isMortgage ? 'Mortgage Bond' : LOAN_TYPES[loan.assetType] || ''
        };
    }

    api.getCurrentLoans = function () {
        return investorService.getHoldingsCurrent().then(function (holdings) {
            var propertyListParams = createPropertyListParamsForCollection(holdings);
            return $q.all({
                collection: $q.when(holdings),
                properties: holdings && holdings.length ? propertyService.getPropertiesList(propertyListParams) : $q.when([])
            });
        }).then(function (res) {
            var properties = ppUtil.indexBy(res.properties, 'symbol');
            res.collection = res.collection
                .filter(isHoldingALoan.bind(null, properties))
                .map(normaliseLoan.bind(null, properties));
            res.properties = properties;

            return res;
        });
    };

    api.getCurrentLoansOverview = function () {
        return api.getCurrentLoans().then(function (res) {
            var totalCost = res.collection.reduce(function (acc, item) {
                return acc + item.totalCost;
            }, 0);

            var averageInterest;

            if (totalCost) {
                averageInterest = Number(ppBig(res.collection.reduce(function (acc, item) {
                        return acc + Number(ppBig(item.totalCost).times(item.netInterest));
                    }, 0))
                    .div(totalCost));
            }

            return {
                totalCost: totalCost,
                averageInterest: averageInterest || 0,
                activeLoans: res.collection.length
            };
        });
    };

    api.getExitedLoans = function () {
        return investorService.getExitedLoans()
            .then(function (res) {
                return addDashboardDataToCollection({
                    collection: res.collection,
                    totals: res.totals
                }).then(function (res) {
                    res.collection = res.collection.map(normaliseLoan.bind(null, res.properties));
                    return res;
                });
            });
    };

    function getReservedFunds(user) {
        return user.financials.investorReservedFunds.reduce(function (amount, item) {
            return amount + item.investment;
        }, 0);
    }

    function normaliseApo(apos, apo) {

        var apoLabelMap = {
            developmentloan: 'Development Loan',
            balanced: 'Balanced Plan',
            growth: 'Index Plan',
            income: 'Income Plan',
            discretionary: 'Discretionary Managed Plan',
            allshare: 'PPX All-Share Plan'
        };

        return {
            label: apoLabelMap[apo],
            value: $filter('ppCurrency')(apos[apo])
        };
    }

    function createCommittedFundsSubItems(pendingInvestments, apos) {
        var items = [];
        if (pendingInvestments) {
            items.push({
                label: 'Pending investments',
                value: $filter('ppCurrency')(pendingInvestments)
            });
        }

        var normalise = normaliseApo.bind(null, apos);

        if (apos) {
            items = items.concat(Object.keys(apos).map(normalise));
        }
        return items;

    }

    function normaliseFinancialsByAssetType(financialsByAssetType, valuationMethod) {

        var bondItem = {
            label: 'Development loans',
            value: 0
        };

        var propertyItem = {
            label: 'Properties',
            value: 0
        };

        for (var key in financialsByAssetType) {
            if (key === 'Bonds') {
                bondItem.value += financialsByAssetType[key].investmentValuation;
            } else {
                propertyItem.value += valuationMethod === 'iv' ? financialsByAssetType[key].investmentValuation : financialsByAssetType[key].investmentValuationVpv;
            }
        }

        return {
            bond: bondItem,
            property: propertyItem
        };
    }

    var gtZeroOrUndefined = R.ifElse(R.gt(0), R.always(undefined));

    api.normalisePortfolio = function (user, totals, apos, apoAmount, currentLoansOverview, currentHoldings, valuationMethod) {
        var data = {};
        var committedFundsSubItems = createCommittedFundsSubItems(getReservedFunds(user), apos);
        var totalInvestmentSubItems = normaliseFinancialsByAssetType(user.financials.financialsByAssetType, valuationMethod);

        data.breakdown = [];
        data.title = 'Portfolio';
        user.financials.investorReservedFunds = user.financials.investorReservedFunds || [];

        var totalInvestmentValuation = valuationMethod === 'iv' ? user.financials.totalInvestmentValuation : user.financials.totalInvestmentValuationVpv;

        data.value = user.financials.funds + totalInvestmentValuation + getReservedFunds(user) + apoAmount;

        data.breakdown.push({
            label: 'Available funds',
            value: user.financials.funds || 0
        });

        data.breakdown.push({
            label: 'Pending investments',
            value: committedFundsSubItems.length ? null : 0,
            subItems: committedFundsSubItems
        });

        data.breakdown.push({
            label: 'Properties',
            value: totalInvestmentSubItems.property.value,
            isTotal: true,
            subItems: [{
                    value: $filter('ppCurrency')(currentHoldings.totalCost),
                    label: 'Total cost',
                    desc: dashboard.current.totalCost.desc
                },
                {
                    value: $filter('ppCurrency')(valuationMethod === 'iv' ? currentHoldings.capitalGain : currentHoldings.capitalGainVpv),
                    label: 'Unrealised capital gain',
                    desc: dashboard.current.capitalGain.desc
                }
            ]
        });

        var devLoansSubItems = gtZeroOrUndefined(
            R.always([{
                value: $filter('ppPercentage')(currentLoansOverview.averageInterest, 2),
                label: 'Average interest rate'
            }])
        )(currentLoansOverview.averageInterest);

        data.breakdown.push({
            label: 'Development loans',
            value: totalInvestmentSubItems.bond.value,
            subItems: devLoansSubItems
        });

        return data;
    };

    api.getIncomeByMonth = function (investor) {
        var endpoint = INCOME_BY_MONTH_ENDPOINT;
        var investorKind = R.path(['kind'], investor) || '';
        var cacheKey = investorKind + 'income-by-month';
        if (!promiseCache[cacheKey]) {
            promiseCache[cacheKey] = $http.get(endpoint).then(function (res) {
                var data = res.data;
                var defaultData = [{
                    dividends: 0,
                    promos: 0,
                    aumRebate: 0,
                    month: ppMoment().startOf('month').toDate()
                }];

                var result = data && data.length > 0 ? data : defaultData;
                return result.map(function (item) {
                    item.total = Number(ppBig(item.dividends).plus(item.promos).plus(item.aumRebate));
                    return item;
                });
            }, function () {
                return $q.reject({
                    reason: 'income-by-month.error.unexpected'
                });
            });
        }

        return promiseCache[cacheKey];
    };

    /**
     * @ngdoc method
     * @name dashboardService#purgeCache
     *
     * @description
     * Purges cached promises of requests made for the investor.
     * Ex: `dashboardService.purgeCache('dashboard.graph')` dashboard graph.
     *
     * @param {string} pattern If provide with no arguments, an empty string or `.*`, purges everything.
     */
    api.purgeCache = function (pattern) {
        if (pattern) {
            pattern = new RegExp(pattern);
        }
        for (var prop in promiseCache) {
            if (!pattern || pattern.test(prop)) {
                delete promiseCache[prop];
            }
        }
    };

    return api;
}]);
})(window, window.angular);