(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.team-member', ['pp.widgets-templates', 'pp.services.core', 'pp.services.dialog', 'pp.widgets.team-member-details']);

/**
 * @ngdoc directive
 * @name ppTeamMember
 * @description
 * Renders the dropdown and fund button
 *
 * @restrict A
 * @scope
 */

ngModule.directive('ppTeamMember', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/team-member/team-member.tpl.html',
        scope: {
            teamMember: '<ppTeamMember'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$rootScope', 'ppConfig', 'dialogService', function ($rootScope, ppConfig, dialogService) {
            var vm = this;

            var __config = ppConfig.get('pp.external.endpoints');

            // -- initial state

            vm.s3Endpoint = __config ? __config.assets : null;

            vm.showDetails = false;

            // -- util functions

            // -- scope bindings

            // -- api

            vm.openDetails = function () {
                var scope = $rootScope.$new();
                var options = {
                    scope: scope,
                    showClose: true,
                    escapeClose: true,
                    clickClose: true,
                    classNames: 'team-member-details'
                };
                scope.teamMember = vm.teamMember;
                scope.s3Endpoint = vm.s3Endpoint;
                options.contents = '<div pp-team-member-details="teamMember" s3-endpoint="s3Endpoint"></div>';
                dialogService.show(options);
            };

            // -- main
        }]
    };
});
})(window, window.angular);