(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.financials-hpi', ['pp.widgets-templates', 'pp.widgets.performance-warning', 'pp.filters.text', 'pp.filters.numbers', 'pp.widgets.hpi-range']);

ngModule.directive('ppFinancialsHpi', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/financials-hpi/financials-hpi.tpl.html',
        scope: {
            property: '<ppFinancialsHpi'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$filter', function ($scope, $filter) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);