(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.kyc-document-verification', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.gbGroup',
    'pp.services.preference',
    'pp.services.route',
    'pp.widgets.kyc-document-verification-form'
]);

var PAGE_NAME = 'kyc.auto';

var RELOAD_TIMEOUT = 5000;

ngModule.component('ppKycDocumentVerification', {
    templateUrl: 'widgets/_angular/kyc-document-verification/kyc-document-verification.tpl.html',
    bindings: {
        kycStatusDetails: '<'

    },
    controllerAs: 'vm',
    controller: ['$q', '$window', '$timeout', 'investorService', 'ppTrack', 'gbGroupService', 'preferenceService', 'routeService', 'R', function ($q, $window, $timeout, investorService, ppTrack, gbGroupService, preferenceService, routeService, R) {
        var vm = this;

        // -- util functions

        function redirect() {
            ppTrack.closeAll().then(function () {
                $window.location.href = routeService.marketplacePath;
            });
        }

        function sleepAndReload() {
            $timeout(function () {
                $window.location.reload(true);
            }, RELOAD_TIMEOUT);
        }

        // -- api

        vm.documentSend = function (data) {
            return gbGroupService
                .submitKycSecondary(data)
                .then(sleepAndReload);
        };

        // -- main

        $q.all({
            investor: investorService.getInvestor()
        }).then(function (res) {
            vm.ready = true;
            ppTrack.pageLoad(PAGE_NAME);
        });

    }]
});
})(window, window.angular);