(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.input-date', ['pp.components-templates', 'pp.services.core', 'pp.services.input-date']);

ngModule.directive('ppInputDate', ['inputDateService', function (inputDateService) {
    return {
        restrict: 'A',
        templateUrl: 'components/_angular/input-date/input-date.tpl.html',
        scope: {
            date: '=ppInputDate',
            dateName: '@',
            dateMin: '=',
            dateMax: '=',
            yearRangeFrom: '=',
            yearRangeTo: '=',
            isRequired: '=',
            isDisabled: '='

        },
        link: function ($scope, $elem, $attrs) {

            function validateAndUpdate() {
                var valid;
                var date;
                var $ctrl = $scope[$scope.vm.dateName];

                if ($scope.vm.isRequired && $ctrl.$error.required) {
                    $ctrl.$setValidity('date', true);
                    $ctrl.$setValidity('date-min', true);
                    $ctrl.$setValidity('date-max', true);
                } else {
                    valid = inputDateService.isDate($scope.vm.year, $scope.vm.month, $scope.vm.day);
                    $ctrl.$setValidity('date', valid);
                    if (valid) {
                        date = new Date($scope.vm.year, $scope.vm.month - 1, $scope.vm.day);
                        if ($scope.vm.dateMin) {
                            valid = date >= $scope.vm.dateMin;
                            $ctrl.$setValidity('date-min', valid);
                        }
                        if ($scope.vm.dateMax) {
                            valid = date <= $scope.vm.dateMax;
                            $ctrl.$setValidity('date-max', valid);
                        }
                    } else {
                        $ctrl.$setValidity('date-min', true);
                        $ctrl.$setValidity('date-max', true);
                    }
                }

                if ($ctrl.$valid) {
                    $scope.vm.date = date;
                }
            }

            // -- scope binding

            // from view model to ng model
            $scope.$watch(function () {
                return $scope.vm.day + '-' + $scope.vm.month + '-' + $scope.vm.year;
            }, validateAndUpdate);

        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', 'ppComponentEmitter', function ($scope, $element, ppComponentEmitter) {
            var vm = this;

            // initial state

            // -- util

            // -- main

            vm.$postLink = function () {
                ppComponentEmitter.emit('input-date.dom.loaded', $element);
            };

            vm.$onInit = function () {
                // @todo SOMEDAY this directive needs to watch for changes in the model
                if (vm.date) {
                    vm.day = vm.date.getDate();
                    vm.month = inputDateService.stringifyMonth(vm.date.getMonth());
                    vm.year = vm.date.getFullYear();
                }

                vm.days = inputDateService.getDays();
                vm.months = inputDateService.getMonths();
                vm.years = inputDateService.getYears(vm.yearRangeFrom, vm.yearRangeTo);
                ppComponentEmitter.emit('input-date.controller.loaded', vm);
            };

        }]
    };
}]);
})(window, window.angular);