(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-simple-cta', [
    'pp.widgets-templates',
    'pp.services.route',
    'pp.services.core'
]);

ngModule.component('ppPropertyCardSimpleCta', {
    templateUrl: 'widgets/_angular/property-card-simple-cta/property-card-simple-cta.tpl.html',
    bindings: {
        property: '<',
        user: '<',
        viewDetail: '&',
        bookmarkProperty: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'routeService', 'R', function ($scope, routeService, R) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        vm.$onChanges = function (changes) {
            var symbol = R.path(['property', 'currentValue', 'symbol'], changes);
            if (symbol) {
                vm.propertyLink = routeService.getIndividualPropertyPath(symbol);
            }
        };

        // -- main

    }]
});
})(window, window.angular);