(function(window, angular){'use strict';

var ngModule = angular.module('pp.controllers.reset', ['pp.services.core', 'pp.services.auth', 'pp.components.password-validation']);

var RESET_CONFIRM_MODAL_ID = '#auth-link-reset-confirm';

/**
 * controller for the password form
 */
ngModule.controller('resetCtrl', ['$scope', '$timeout', 'ppTrack', 'authService', function ($scope, $timeout, ppTrack, authService) {
    var vm = this;
    vm.ready = false;

    // -- initial state

    function showResetSuccessModal() {
        $(RESET_CONFIRM_MODAL_ID).modal({
            escapeClose: true,
            clickClose: true,
            showClose: true
        }).on($.modal.CLOSE, function () {
            window.location.replace('/?noredirect=true');
        });
    }

    function handleTokenNotFound() {
        ppTrack.pageLoad('auth.password.reset');
        vm.tokenError = true;
    }

    // -- api
    vm.token = window.location.hash.replace('#/', '');

    vm.requestNewLink = function () {
        vm.processing = true;
        vm.newLinkError = false;
        authService.requestNewToken(vm.token).then(function () {
            var track = window.pp.track;
            track.event('auth.reset-link.request.ok');
            showResetSuccessModal();
        }, function (error) {
            vm.newLinkError = true;
        }).then(function () {
            vm.processing = false;
        });
    };

    // prevents form submission if there are errors
    vm.submit = function ($event) {
        if (!vm.form.$valid) {
            vm.feedbackEnabled = true;
            $event.preventDefault();
        }
    };

    $scope.$watch('vm.form.$valid', function (value) {
        if (value) {
            vm.validOnce = true;
        }
    });

    // -- scope bindings

    // -- main
    authService.getTokenInfo(vm.token).then(function (info) {
        if (info && info.data) {
            vm.type = info.data.type;
            ppTrack.pageLoad('auth.' + vm.type + '.reset');

            var now = new Date();
            if (info.data.expiry < now.getTime()) {
                vm.tokenError = true;
            }

        } else {
            handleTokenNotFound();
        }
    }, function () {
        handleTokenNotFound();
    }).finally(function () {
        vm.ready = true;
    });
}]);
})(window, window.angular);