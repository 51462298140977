(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.bid-receipt', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.filters.numbers',
    'pp.services.dialog',
    'pp.services.route'
]);

/**
 * @ngdoc directive
 * @name ppBidReceipt
 * @description
 * Renders the funding receipt dialog
 *
 * @restrict A
 * @scope
 * @param {obj} receipt
 */

ngModule.directive('ppBidReceipt', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/bid-receipt/bid-receipt.tpl.html',
        scope: {
            receipt: '<',
            onContinue: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$attrs', 'ppTrack', 'dialogService', 'routeService', function ($scope, $attrs, ppTrack, dialogService, routeService) {
            var vm = this;

            // -- initial state

            vm.marketplacePath = routeService.marketplacePath;

            // -- api

            // -- main

            vm.continue = function () {
                if ($attrs.onContinue) {
                    vm.onContinue();
                } else {
                    dialogService.resolveParent($scope);
                    ppTrack.pageAction('bid', 'bid-receipt.dismiss');
                }

            };

        }]
    };
});
})(window, window.angular);