(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.premium-services-lp', [
    'pp.widgets-templates',
    'pp.widgets.expandable-content',
    'pp.widgets.tick-product-list',
    'pp.widgets.marketing-event-card',
    'pp.ui.services.schedule-call-dialog',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.blog',
    'pp.services.dialog',
]);

var PAGE_NAME = 'premium-services';

ngModule.component('ppPremiumServicesLp', {
    templateUrl: 'widgets/_angular/premium-services-lp/premium-services-lp.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$rootScope', 'ppConfig', 'ppTrack', 'blogService', 'investorService', 'scheduleCallDialogService', function ($rootScope, ppConfig, ppTrack, blogService, investorService, scheduleCallDialogService) {
        var vm = this;

        // -- initial state
        var __config = ppConfig.get('pp.external.endpoints') || {};

        var assetsEndpoint = __config.assets;

        vm.premiumEarlyAccess = 'Early Access to new properties and 0% transaction fees';
        vm.premiumEarlyAccessDescription = 'Receive details about new investment opportunities before they go live on our platform. Benefit from 0% transaction fees when you invest in Early Access, terms apply.';
        vm.premiumEarlyAccessDetailOpen = true;

        vm.premiumAccountManager = 'Personal Account Manager';
        vm.premiumAccountManagerDescription = 'Our account team are fully briefed on all new property investments, so you can have the right conversation with them about your investment goals. They can even deploy funds on your behalf.';
        vm.premiumAccountManagerDetailOpen = false;

        vm.premiumInvestmentPlans = 'Premium Investment Plans';
        vm.premiumInvestmentPlansDescription = 'Take advantage of our bespoke investment plans to tailor a portfolio to your individual goals.';
        vm.premiumInvestmentPlansDetailOpen = false;

        vm.productList = [
            [{
                    label: 'A tailored portfolio, for you',
                    type: 'alternate'
                },
                {
                    label: 'FCA accredited investment team',
                    type: 'alternate'
                },
                {
                    label: 'We’ll deploy funds on your behalf',
                    type: 'alternate'
                }
            ]
        ];

        // -- util functions

        // -- api

        vm.showScheduleCallDialog = function (buttonId) {

            ppTrack.action('premium-services.schedule-a-call.open', {
                buttonId: buttonId
            });

            scheduleCallDialogService.show();

        };

        // -- scope bindings

        // -- main

        vm.$onDestroy = function () {
            ppTrack.pageClose(PAGE_NAME);
        };

        investorService.getInvestor().then(function () {
            ppTrack.pageLoad(PAGE_NAME);
        });

        blogService.getMarketingEvents().then(function (events) {
            vm.marketingEvents = events;
        });

    }]
});
})(window, window.angular);