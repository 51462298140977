(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.move-funds-receipt', [
    'pp.widgets-templates',
    'pp.filters.numbers'
]);

ngModule.component('ppMoveFundsReceipt', {
    templateUrl: 'widgets/_angular/move-funds-receipt/move-funds-receipt.tpl.html',
    bindings: {
        receipt: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);