(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.invest-dialog-investor-mismatch', [
    'pp.widgets-templates',
    'pp.services.core'
]);

ngModule.component('ppInvestDialogInvestorMismatch', {
    templateUrl: 'widgets/_angular/invest-dialog-investor-mismatch/invest-dialog-investor-mismatch.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$window', 'ppTrack', function ($scope, $window, ppTrack) {

        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.reloadPage = function () {
            ppTrack.deferAction('invest.investorMismatch.retry');
            $window.location.reload();
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);