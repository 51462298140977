(function(window, angular){'use strict';

var ASCENDING = 'ascending';
var DESCENDING = 'descending';

var ngModule = angular.module('pp.services.table', ['pp.services.investor', 'pp.services.core']);

ngModule.service('tableService', [function () {

    var api = {};

    function getValue(item, attr) {
        if (angular.isDate(item[attr])) {
            return item[attr];
        }

        return angular.isObject(item[attr]) ? item[attr].raw : item[attr];
    }

    api.sortByAttr = function (attr, order, item1, item2) {
        var value1 = getValue(item1, attr);
        var value2 = getValue(item2, attr);
        if (value1 === '') {
            return 1;
        } else if (value2 === '') {
            return -1;
        } else if (order === ASCENDING) {
            return value1 > value2 ? 1 : -1;
        } else {
            return value1 < value2 ? 1 : -1;
        }
    };

    return api;
}]);
})(window, window.angular);