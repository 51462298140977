(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.classification-quiz', [
    'pp.widgets-templates',
    'pp.services.classification',
    'pp.widgets.animated-tick'
]);

var PAGE_NAME = 'classification';
var QUIZ_FORM_NAME = 'classification.quiz';
var ERROR_DISPLAY_MSECONDS = 30000;
var TICK_ANIMATION_TIMEOUT_DELAY = 700;

/**
 * @todo document
 */
ngModule.directive('ppClassificationQuiz', ['$window', '$timeout', 'ppTrack', 'classificationService', function ($window, $timeout, ppTrack, classificationService) {

    function redirect($window, href) {
        ppTrack.closeAll().then(function () {
            // @todo SOMEDAY this code does not check for window.location.href === href, will not reload page if they're the same
            href = href || $window.location.href;
            $window.location.href = href;
        });
    }

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/classification-quiz/classification-quiz.tpl.html',
        scope: {
            onFinish: '&',
            onCancel: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$http', function ($scope, $http) {
            var vm = this;

            // -- initial state

            // @todo conversion-phase-2 missing quiz questions
            vm.questions = [{
                text: 'Do property prices always go up in value?',
                responseOptions: [{
                    key: 0,
                    text: 'Yes, property prices only go up in value.'
                }, {
                    key: 1,
                    text: 'No, property prices can decrease in value.'
                }]
            }, {
                text: 'Are my investment returns solely derived from the movement in property prices?',
                responseOptions: [{
                    key: 1,
                    text: 'No, my return is based on both the property value and rental income.'
                }, {
                    key: 0,
                    text: 'Yes, my return is based on nothing other than the movement in property value.'
                }]
            }, {
                text: 'Should I put all my funds into one property?',
                responseOptions: [{
                    key: 0,
                    text: 'Yes, this is a good investment strategy.'
                }, {
                    key: 1,
                    text: 'No, this is a poor investment strategy.'
                }]
            }, {
                text: 'Will there always be a buyer for my investment at the price of my choice?',
                responseOptions: [{
                    key: 1,
                    text: 'No, there is not always a guaranteed buyer for my investment. I may have to wait for 5 years for an opportunity to exit.'
                }, {
                    key: 0,
                    text: 'Yes, there is always a guaranteed buyer for my investment at the price of my choice.'
                }]
            }];

            vm.questionIx = 0;
            vm.responses = [];

            var numQuestions = vm.questions.length;

            // -- util functions

            function clearError() {
                vm.hasError = false;
                vm.errorMsg = '';
            }

            function handleUnknownError() {
                vm.hasError = true;
                vm.errorMsg = 'An error occurred, please try again.';
                $timeout(function () {
                    clearError();
                }, ERROR_DISPLAY_MSECONDS);
            }

            function scoreAndFinish() {
                vm.isProcessing = true;
                clearError();

                // @todo conversion-phase-2 update when all 7 questions are loaded
                var answers = vm.responses.join('') + '111';

                classificationService.submitQuiz(answers).then(function () {
                    // @todo post-conversion-phase-2 .. this is  hacky, maybe use investorService to do this, or at least document better
                    ppTrack.setContext('user.stage', 'riComplianceRequired');
                    vm.onFinish({
                        result: true
                    });
                }, function (err) {
                    if (err.status !== 400) {
                        ppTrack.formError(QUIZ_FORM_NAME, err.status);
                    }
                    vm.isProcessing = false;
                    switch (err.status) {
                    case 400:
                        vm.onFinish({
                            result: false
                        });
                        break;
                    case 409:
                        redirect($window, window.location.href);
                        break;
                    default:
                        handleUnknownError();
                    }
                });
            }

            // -- api

            vm.hasResponse = function () {
                return 'undefined' !== typeof vm.response;
            };

            vm.setResponse = function (key) {
                if (!vm.isProcessing) {
                    vm.response = key;
                    vm.responses[vm.questionIx] = vm.response;
                    ppTrack.pageAction(PAGE_NAME, 'quiz.question', vm.questionIx + 1);
                    vm.answering = true;
                    if (vm.questionIx + 1 === numQuestions) {

                        $timeout(function () {
                            scoreAndFinish();
                            vm.answering = false;
                        }, TICK_ANIMATION_TIMEOUT_DELAY);
                    } else {
                        $timeout(function () {
                            vm.questionIx++;
                            vm.response = vm.responses[vm.questionIx];
                            vm.answering = false;
                        }, TICK_ANIMATION_TIMEOUT_DELAY);
                    }
                }
            };

            vm.back = function () {
                if (--vm.questionIx < 0) {
                    vm.onCancel();
                } else {
                    ppTrack.pageAction(PAGE_NAME, 'quiz.question', vm.questionIx + 1);
                    vm.response = vm.responses[vm.questionIx];
                }
            };

            vm.forward = function () {
                if (vm.questionIx + 1 < vm.questions.length) {
                    vm.questionIx++;
                    ppTrack.pageAction(PAGE_NAME, 'quiz.question', vm.questionIx + 1);
                    vm.response = vm.responses[vm.questionIx];
                }
            };

            vm.hasQuestionBeenAnswered = function () {
                return !vm.answering && angular.isDefined(vm.responses[vm.questionIx]);
            };

        }]
    };
}]);
})(window, window.angular);