(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.auth-modal', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.widgets.auth-login-form',
    'pp.widgets.auth-signup-form'
]);

var REFRESH_REGEXP = /^\/(property\/|properties\/|s)/;

/**
 * @ngdoc directive
 * @name ppAuthModal
 * @description
 * Displays a login or signup modal. On success, invokes a callback OR redirects/reloads the page.
 *
 * @restrict A
 * @scope
 */
ngModule.directive('ppAuthModal', ['ppTrack', 'ppPubSub', 'ppComponentEmitter', function (ppTrack, ppPubSub, ppComponentEmitter) {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/auth-modal/auth-modal.tpl.html',
        scope: {},
        controllerAs: 'vm',
        controller: ['$rootScope', '$window', '$scope', '$timeout', '$element', 'ppConfig', function ($rootScope, $window, $scope, $timeout, $element, ppConfig) {
            var vm = this;

            // -- initial state

            var $elem;
            var callback;

            // shared between the login and signup form
            vm.email = '';

            var endpoints = ppConfig.get('pp.external.endpoints') || {};
            var baseUrl = endpoints.assets;

            vm.imageSrcLogoWhite = baseUrl + '/images/logos/lhelogo.png';
            vm.imageSrcLogoBlue = baseUrl + '/images/logos/lhelogo.png';

            // -- util functions

            function redirect($window, path, isSignup) {
                ppTrack.closeAll().then(function () {
                    // in case the current path matches this, simply refresh the page
                    if (!isSignup && REFRESH_REGEXP.test($window.location.pathname)) {
                        $window.location.reload(true);
                    } else {
                        $window.location.href = path;
                    }
                });
            }

            /**
             * updates mode and re-centers (after render)
             */
            function setMode(mode, signupEmail) {
                if (signupEmail) {
                    vm.email = signupEmail;
                }
                vm.mode = mode;
                $elem.css('visibility', 'hidden');

                if (mode) {
                    $timeout(function () {
                        $elem.css('visibility', 'visible');
                        ppComponentEmitter.emit('auth-modal-' + mode + '.dom.loaded', $element);
                    });
                    ppComponentEmitter.emit('auth-modal-' + mode + '.controller.loaded', vm);
                }
            }

            // -- api

            vm.onToggleMode = function (mode) {
                setMode(mode);
            };

            vm.onSuccess = function (fallbackPath, isSignup) {

                var event = isSignup ? 'auth-modal.signup.success' : 'auth-modal.login.success';
                ppTrack.deferEvent(event);

                if (callback) {
                    callback();
                } else {
                    redirect($window, fallbackPath, isSignup);
                }
            };

            vm.onChangeLoginStep = function (step) {
                vm.loginStep = step;
            };

            vm.onChangeSignupStep = function (step) {
                vm.signupStep = step;
            };

            // -- scope bindings

            $scope.$on('auth-modal.scroll-top', function () {
                $elem.scroll(0);
            });

            // -- main

            // -- expose an API to the non-angular scope

            var publicApi = {
                setElement: function (el) {
                    $elem = el;
                },
                /**
                 * @param {string} Requested mode (login/signup)
                 * @param {Function} cb Optional success callback
                 * @param {string} stignupEmail Optional email to prepopulate signup email field
                 */
                setMode: function (mode, cb, signupEmail, sessionExpired) {
                    callback = cb;
                    vm.sessionExpired = sessionExpired;
                    $scope.$evalAsync(function () {
                        setMode(mode, signupEmail);
                        ppComponentEmitter.emit('auth-modal.dom.loaded', $element);
                    });
                }
            };

            ppPubSub.publish('auth.module.loaded', [publicApi]);
        }]
    };
}]);

ngModule.component('ppAuth', {
    templateUrl: 'widgets/_angular/auth-modal/auth-modal.tpl.html',
    bindings: {
        onAuthSuccess: '&',
        sessionExpired: '<',
        mode: '<',
        hideLogo: '<',
        inSidebar: '<',
        onChangeOfLoginStep: '&',
        onChangeOfSignupStep: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', 'investorService', function ($scope, ppTrack, investorService) {
        var vm = this;

        // -- initial state

        // Shared between login and signup
        vm.email = '';

        // -- util functions

        function setMode(mode, signupEmail) {
            if (signupEmail) {
                vm.email = signupEmail;
            }
            vm.mode = mode;
        }

        // -- api

        vm.onToggleMode = function (mode) {
            setMode(mode);
        };

        vm.onSuccess = function (fallbackPath, isSignup) {
            var event = isSignup ? 'auth-modal.signup.success' : 'auth-modal.login.success';
            ppTrack.deferEvent(event);
            vm.onAuthSuccess();

        };

        vm.onChangeLoginStep = function (step) {
            vm.loginStep = step;
            vm.onChangeOfLoginStep({
                step: step
            });
        };

        vm.onChangeSignupStep = function (step) {
            vm.signupStep = step;
            vm.onChangeOfSignupStep({
                step: step
            });
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);