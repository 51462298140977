(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.source-of-funds-form', [
    'pp.widgets-templates',
    'pp.components.checkbox',
    'pp.components.required-any',
    'pp.services.core'
]);

var OTHER_LABEL = 'OTHER::';

ngModule.component('ppSourceOfFundsForm', {
    templateUrl: 'widgets/_angular/source-of-funds-form/source-of-funds-form.tpl.html',
    bindings: {
        onSubmit: '&',
        isProcessing: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppTrack', function ($scope, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions

        function normaliseSource(sources, otherText) {
            var source = [];
            var key;
            for (key in sources || {}) {
                if (sources[key]) {
                    source.push(sources[key]);
                }
            }

            return source.join(', ') + (otherText ? ', ' + OTHER_LABEL + ' ' + otherText : '');
        }

        // -- api
        vm.submit = function () {
            vm.feedbackEnabled = true;

            ppTrack.action('source-of-funds-form.submit', {
                source: normaliseSource(vm.sources, vm.moreDetails)
            });

            if (vm.form.$valid) {
                vm.processing = true;
                vm.onSubmit({
                    source: normaliseSource(vm.sources, vm.moreDetails)
                });
            } else {
                ppTrack.ngFormValidation('source-of-funds-form', vm.form);
            }

        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);