(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-details-address', [
    'pp.widgets-templates',
    'pp.filters.property',
    'pp.services.config',
    'pp.widgets.property-info-tags'
]);

ngModule.directive('ppPropertyDetailsAddress', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/property-details-address/property-details-address.tpl.html',
        scope: {
            property: '<ppPropertyDetailsAddress',
            hideTags: '<',
            nameOnly: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'configService', function ($scope, configService) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

            vm.$onInit = function () {
                if (vm.property && vm.property.isDevelopmentLoan) {
                    configService.isIsaPublic().then(function (isaPublic) {
                        vm.isaPublic = isaPublic;
                    });
                }
            };

        }]
    };
}]);
})(window, window.angular);