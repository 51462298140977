(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.marketing', [
    'pp.services.core'
]);

var API_BASE_PATH = '/feapi/r1';
var API_CONTENT_TYPE = 'application/json';
var INVESTOR_PREFERENCE_ENDPOINT = '/user/subscriptions';
var RECOMMENDED = 'Recommended';

ngModule.service('marketingService', ['$http', '$q', function ($http, $q) {

    var api = {};
    var promiseCache = {};

    function formatMarketingPreferenceData(preferences) {
        var preferencesCopy = angular.copy(preferences);
        if (preferencesCopy && preferencesCopy.subscriptions) {
            preferencesCopy.subscriptions = preferencesCopy.subscriptions.map(function (item) {
                var subscription = angular.copy(item);
                subscription.header = subscription.recommended ? RECOMMENDED : '';
                return subscription;
            });
        }
        return preferencesCopy;
    }

    api.getMarketingPreference = function () {

        var endpoint = API_BASE_PATH + INVESTOR_PREFERENCE_ENDPOINT;
        var cacheKey = 'marketing.preference';

        if (!promiseCache[cacheKey]) {

            var options = {
                headers: {
                    'Content-type': API_CONTENT_TYPE
                }
            };

            promiseCache[cacheKey] = $http.get(endpoint, {}, options).then(function (response) {
                var data = response.data || {};
                var preferences = data.subscriberPreferences || {};
                return formatMarketingPreferenceData(preferences);
            }, function () {
                delete promiseCache[cacheKey];
                return $q.reject({
                    reason: 'marketing-preference.error.unexpected'
                });
            });
        }

        return promiseCache[cacheKey];
    };

    api.setMarketingPreference = function (investorPreference) {

        var endpoint = API_BASE_PATH + INVESTOR_PREFERENCE_ENDPOINT;
        var cacheKey = 'marketing.preference';
        var options = {
            headers: {
                'Content-type': API_CONTENT_TYPE
            }
        };

        return $http.put(endpoint, investorPreference, options).then(function (response) {
            delete promiseCache[cacheKey];
            return true;
        }, function () {
            return $q.reject({
                reason: 'marketing-preference.error.unexpected'
            });
        });

    };

    api._formatMarketingPreferenceData = formatMarketingPreferenceData;

    return api;
}]);
})(window, window.angular);