(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.trust-hooks', [
    'pp.services.core',
    'pp.widgets-templates',
    'pp.widgets.primary-trust-hooks',
    'pp.widgets.trust-hooks-lp'
]);

ngModule.component('ppTrustHooks', {
    templateUrl: 'widgets/_angular/trust-hooks/trust-hooks.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', function ($scope, ppConfig) {
        var vm = this;

        // -- initial state

        var __endpoints = ppConfig.get('pp.external.endpoints') || {};
        vm.assetsEndpoint = __endpoints.assets;

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);