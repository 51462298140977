(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.loading-card', ['pp.widgets-templates']);

ngModule.directive('ppLoadingCard', [function () {
    return {
        restrict: 'AE',
        templateUrl: 'widgets/_angular/loading-card/loading-card.tpl.html',
        scope: {},
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);