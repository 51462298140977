(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.receipt', [
    'pp.widgets-templates',
    'pp.widgets.fund-receipt',
    'pp.widgets.bid-receipt',
    'pp.widgets.buy-receipt',
    'pp.widgets.buy-loan-receipt',
    'pp.widgets.sell-receipt',
    'pp.widgets.withdrawal-receipt',
    'pp.widgets.investment-plan-receipt',
    'pp.widgets.isa-creation-receipt',
    'pp.widgets.move-funds-receipt'
]);

/**
 * @ngdoc directive
 * @name ppReceipt
 * @description
 * Renders the generic receipt dialog
 *
 * @restrict A
 * @scope
 * @param {obj} receipt
 */

ngModule.directive('ppReceipt', function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/receipt/receipt.tpl.html',
        scope: {
            receipt: '<ppReceipt',
            onContinue: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$attrs', function ($scope, $attrs) {
            var vm = this;

            // -- initial state

            // -- util functions

            // -- api

            // -- lifecycle

            vm.$onChanges = function (changes) {
                if (changes.receipt && changes.receipt.currentValue && changes.receipt.currentValue.receiptType) {
                    vm.receipt.receiptType = changes.receipt.currentValue.receiptType.toUpperCase();
                }
            };

            vm.$onInit = function () {
                vm.hasContinue = !!$attrs.onContinue;
            };

            // -- main

        }]
    };
});
})(window, window.angular);