(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.account', [
    'ui.router',
    'pp.components-templates',
    'pp.services.core',
    'pp.services.receipt',
    'pp.widgets-templates',
    'pp.widgets.account-investor-funds',
    'pp.components.secondary-nav-center'
]);

ngModule.component('ppAccount', {
    templateUrl: 'widgets/_angular/account/account.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$state', '$transitions', 'receiptService', 'ppTrack', function ($scope, $state, $transitions, receiptService, ppTrack) {
        var vm = this;

        // -- initial state

        vm.state = $state.current.name;

        // -- util functions

        // -- api

        // -- scope bindings

        $transitions.onStart({
            to: 'account.settings.**'
        }, function (trans) {
            var state = trans.$to().name;
            vm.state = state;
            ppTrack.pageLoad(state);
        });

        vm.ready = true;

        // -- main

        receiptService.checkForReceipts();

    }]
});
})(window, window.angular);