(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-simple-figures', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.components.tooltip',
    'pp.filters.numbers',
    'pp.widgets.aum-dividend-notice',
    'pp.values.glossary'
]);

var PREMIUM_LABEL = 'Premium';
var DISCOUNT_LABEL = 'Discount';

ngModule.component('ppPropertyCardSimpleFigures', {
    templateUrl: 'widgets/_angular/property-card-simple-figures/property-card-simple-figures.tpl.html',
    bindings: {
        property: '<',
        user: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'glossary', 'ppUtil', function ($scope, glossary, ppUtil) {
        var vm = this;

        // -- initial state

        // -- util functions

        function getDiscountPremiumLabel(property) {
            if (ppUtil.hasPathIn(property, 'market.premiumDiscount')) {
                return property.market.premiumDiscount.premiumDiscountPct > 0 ? PREMIUM_LABEL : DISCOUNT_LABEL;
            } else {
                return DISCOUNT_LABEL;
            }
        }

        function setupTooltips(property) {

            vm.glossary = glossary;

            if (property.isPrimary) {
                vm.netIncomeYield = glossary.netIncomeYieldPrimary.desc;
            }

            if (property.isSecondaryTrading || property.isSecondaryInformational) {
                vm.netIncomeYield = glossary.netIncomeYieldSecondary.desc;
            }

        }

        // -- api

        vm.pctDiffClass = function (pct) {
            if (pct > 0) {
                return 'positive';
            } else if (pct < 0) {
                return 'negative';
            }
        };

        // -- scope bindings

        vm.$onChanges = function (changes) {
            if (changes.property && changes.property.currentValue && !changes.property.isFirstChange()) {
                setupTooltips(changes.property.currentValue);
            }
        };

        // -- main

        vm.$onInit = function () {
            setupTooltips(vm.property);
        };

    }]
});
})(window, window.angular);