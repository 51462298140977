(function(window, angular){'use strict';
var ngModule = angular.module('pp.widgets.auth-nav-mobile', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.investor',
    'pp.services.route',
    'pp.widgets.investor-funds',
    'pp.widgets.auth-nav-mobile-menu'
]);

var URL_FUND = '/investor/account/manual/fund';
var URL_ACCOUNT = '/my-account';

/**
 * @ngdoc directive
 * @name ppAuthNavUser
 * @description
 * Renders the dropdown and fund button
 *
 * @restrict A
 * @scope
 */
ngModule.directive('ppAuthNavMobile', function () {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/auth-nav-mobile/auth-nav-mobile.tpl.html',
        scope: {
            path: '@',
            blogUrl: '@'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$window', '$q', 'ppPubSub', 'ppTrack', 'investorService', 'routeService', function ($scope, $window, $q, ppPubSub, ppTrack, investorService, routeService) {
            var vm = this;

            // -- initial state

            vm.fundPath = routeService.fundPath;

            // -- util functions

            // -- api

            vm.signup = function () {
                ppTrack.action('menu.signup.click');
                ppPubSub.publish('auth.modal.show', ['signup']);
            };

            vm.login = function () {
                ppTrack.action('menu.login.click');
                ppPubSub.publish('auth.modal.show', ['login']);
            };

            vm.logout = function () {
                ppTrack.deferAction('mobile.logout.click'); // @todo deprecate
                ppTrack.deferAction('menu.logout.click');
                $window.location.href = '/logout';
            };

            vm.closeNav = function () {
                ppPubSub.publish('mobile-nav-close');
            };

            // -- scope bindings

            $scope.$on('list-menu-item.click', function (item) {
                ppTrack.deferAction('mobile.' + item + '.click');
            });

            // -- main

            $q.all({
                investor: investorService.getInvestor(),
                investors: investorService.getUserInvestors()
            }).then(function (res) {
                vm.investor = res.investor;
                vm.investors = res.investors;
                vm.ready = true;
            });

            investorService.on('investor-updated', function (investor) {
                vm.investor = investor;
            });

            investorService.on('user-updated', function (investors) {
                vm.investors = investors;
            });
        }]
    };
});
})(window, window.angular);