(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.lei-validation-message', ['pp.widgets-templates']);

ngModule.component('ppLeiValidationMessage', {
    templateUrl: 'widgets/_angular/lei-validation-message/lei-validation-message.tpl.html',
    bindings: {
        patternError: '<',
        validitityError: '<',
        isExpired: '<',
        requiredError: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);