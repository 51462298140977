(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.cancel-listing-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.widgets.cancel-listing'
]);

/**
 * @ndgoc service
 * @description
 * checks if we need to notify the user that they have been qualified/disqualified for an rff scheme
 * shows the dialog and sets a preference when the user dismisses it
 * so that the dialog is never shown again for the same scheme
 */
ngModule.service('cancelListingDialogService', ['$rootScope', 'ppTrack', 'ppConfig', 'dialogService', function ($rootScope, ppTrack, ppConfig, dialogService) {

    // -- util functions

    var __authConfig = ppConfig.get('pp.modules.auth') || {};

    // create a scope, show the dialog
    // tracks dialog dismissed and sets the preference to never show the dialog again (for this scheme)
    function showDialog(symbol, offer, isBid) {
        var scope = $rootScope.$new();

        var options = {
            clickClose: false,
            escapeClose: false,
            showClose: false,
            scope: scope,
            classNames: 'cancel-listing-modal',
        };

        scope.isBid = isBid;
        scope.symbol = symbol;
        scope.offer = offer;
        scope.reCaptchaKey = __authConfig.reCaptchaKey;
        scope.reCaptchaEnabled = true;

        options.contents = '<pp-cancel-listing is-bid="isBid" symbol="symbol" offer="offer"></cancel-listing>';

        return dialogService.show(options);
    }

    // -- api

    var api = {};

    api.show = showDialog;

    api.close = function () {
        dialogService.close();
    };

    return api;
}]);
})(window, window.angular);