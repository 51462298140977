(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.auto-tab-reverse-delete', ['pp.components-templates']);

var BACKSPACE_KEY_CODE = 8;

ngModule.directive('ppAutoTabReverseDelete', function () {
    return {
        restrict: 'A',
        controller: ['$scope', '$element', function ($scope, $element) {

            var vm = this;

            // NOTE: anti-pattern test specific code, but no other way to check if a transparent directive was instantiated correctly
            $scope.ppTestHelperDirectiveName = 'pp-auto-tab-reverse-delete';

            var previousInput = '';

            // -- util functions

            // -- scope bindings

            $element.on('keyup', function (e) {
                var value = $element.val();
                if (value.length === previousInput.length && value.length === 0 && e.keyCode === BACKSPACE_KEY_CODE) {
                    var $prevElement = $element.prev();
                    if ($prevElement.length) {
                        $prevElement[0].focus();
                    }
                }

                previousInput = value;
            });

        }]
    };
});
})(window, window.angular);