(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.source-of-funds', [
    'pp.widgets-templates',
    'pp.services.investor',
    'pp.services.core',
    'pp.widgets.investment-intent-form',
    'pp.widgets.source-of-funds-form'
]);

var STEP_INTRO = 'intro';
var STEP_INVESTMENT_INTENT = 'intent';
var STEP_SOURCE_OF_FUNDS = 'source';
var SUBMIT_TIMEOUT = 300;

ngModule.component('ppSourceOfFunds', {
    templateUrl: 'widgets/_angular/source-of-funds/source-of-funds.tpl.html',
    bindings: {
        onComplete: '&',
        isYearly: '<',
        withIntro: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$timeout', 'ppTrack', 'investorService', function ($scope, $timeout, ppTrack, investorService) {
        var vm = this;

        // -- initial state
        var stepPosition = {
            intent: 1,
            source: 2
        };

        // -- util functions

        function getQuestionTransform(position) {
            return {
                'transform': 'translateX(' + ((position) * -100) + '%)'
            };
        }

        function concatIsYearlyTag(source, isYearly) {
            return isYearly ? source + ' [12M]' : source + ' [3M]';
        }

        function adjustIntentToYear(intent, isYearly) {
            if (isYearly) {
                return intent;
            } else {
                return Number(intent) * 2;
            }
        }

        function setStep(step) {
            ppTrack.flowStep('source-of-funds', step);
            vm.step = step;
            vm.questionTransform = getQuestionTransform(stepPosition[step]);
        }

        // -- api

        vm.continue = function () {
            setStep(STEP_INVESTMENT_INTENT);
        };

        vm.goBack = function () {
            vm.intent = null;
            setStep(STEP_INVESTMENT_INTENT);
        };

        vm.onSubmitIntent = function (intent) {
            vm.intent = intent;
            $timeout(function () {
                setStep(STEP_SOURCE_OF_FUNDS);
            }, SUBMIT_TIMEOUT);
        };

        vm.onSubmitSource = function (source) {
            vm.source = source;
            vm.processing = true;
            var sourceWithMonths = concatIsYearlyTag(source, vm.isYearly);
            var adjustedIntent = Number(adjustIntentToYear(vm.intent, vm.isYearly));

            var eventData = {
                intent: adjustedIntent,
                source: sourceWithMonths,
                isYearly: vm.isYearly
            };

            ppTrack.event('source-of-funds.submit', eventData);

            investorService.setSourceOfFunds(adjustedIntent, sourceWithMonths).then(function () {
                ppTrack.event('source-of-funds.submit.success', eventData);
                vm.onComplete();
            }, function () {
                ppTrack.event('source-of-funds.submit.error', eventData);
                vm.onComplete();
            });

        };

        // -- scope bindings

        // -- main

        vm.$onInit = function () {
            if (vm.withIntro) {
                setStep(STEP_INTRO);
            } else {
                setStep(STEP_INVESTMENT_INTENT);
            }
        };

    }]
});
})(window, window.angular);