(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.auto-tab-reverse', ['pp.components-templates']);

ngModule.directive('ppAutoTabReverse', function () {
    return {
        restrict: 'A',
        link: function ($scope, $elem, $attr) {

            // NOTE: anti-pattern test specific code, but no other way to check if a transparent directive was instantiated correctly
            $scope.ppTestHelperDirectiveName = 'pp-auto-tab-reverse';

            $elem.on('input', function (e) {
                if ($elem.val().length === 0) {
                    var $prevElement = $elem.prev();
                    if ($prevElement.length) {
                        $prevElement[0].focus();
                    }
                }
            });

        }
    };
});
})(window, window.angular);