(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.refer-a-friend-reward-table', ['pp.widgets-templates']);

ngModule.component('ppReferAFriendRewardTable', {
    templateUrl: 'widgets/_angular/refer-a-friend-reward-table/refer-a-friend-reward-table.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);