(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.preference', [
    'pp.services.core'
]);

var API_INVESTOR_PREFERENCES_ENDPOINT = '/investor/preference';
var API_INVESTOR_PREFERENCE_ENDPOINT = '/investor/preference/:key';
var API_USER_PREFERENCE_ENDPOINT = '/user/preference/:key';
var PREFERENCE_INVESTOR_GOOD_REPUTE_STATEMENT_AGREE = 'investor.good-repute-statement.agree';
var PREFERENCE_KYC_COMPANY_NAME = 'investor.company-name';
var PREFERENCE_KYC_INDIVIDUAL_NAME = 'investor.individual-name';
var PREFERENCE_IS_GIFTING_REFERRAL = 'investor.gifting-referral';
var PREFERENCE_FUNDING_SOURCE_DATE = 'user.funding-source-date';
var PREFERENCE_REINVEST_DIVIDENDS = 'investor.reinvest.dividends';
var PREFERENCE_AGREED_INVESTMENT_PLAN_CLOSED_PERIOD = 'user.investment-plan-closed-period.agreed';

var PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION = 'user.dashboard-current.show-latest-valuation';
var PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION_VPV = 'user.dashboard-current.show-latest-valuation-vpv';
var PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION_IV = 'user.dashboard-current.show-latest-valuation-iv';

var PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION_HAS_BEEN_UPDATED = 'user.dashboard-current.latest-valuation-updated';
var PREFERENCE_DASHBOARD_CURRENT_TRADING_VALUATION = 'user.dashboard-current.show-trading-valuation';
var PREFERENCE_DASHBOARD_OVERVIEW_VALUATION_METHOD = 'user.dashboard-overview.valuation-method';

var PREFERENCE_READ_UNDERSTOOD_MEMORANDUM = 'user.information-memorandum-read-understood';
var PREFERENCE_MEMORANDUM_NDA = 'user.information-memorandum-nda';
var PREFERENCE_KEYS_RISKS_UNDERSTOOD = 'user.key-risks-understood';

var PREFERENCE_CAN_ACCESS_FUNDING_ROUND = 'user.can_access_funding_round';
var PREFERENCE_DEFAULT_QUOTE_FUNDING_ROUND = 'user.default_quote_funding_round';
var PREFERENCE_SIGNUP_COUNTRY = 'user.signup_country';

var RESALE_VALUATION_FEATURE_AVAILABLE = 'user.resale-valuation-feature-available';

var KYC_NATIONALITIES_PREFERENCE = 'user.kyc-nationalities';

var KYC_BUSINESS_TYPE_KEY = 'business';
var KYC_INDIVIDUAL_TYPE_KEY = 'individual';

var HOMENOW_REIT_DISCLAIMER_AGREEMENT = 'user.homenow-reit-disclaimer.agree';

ngModule.service('preferenceService', ['$http', '$q', 'ppMoment', 'R', 'ppEmitter', function ($http, $q, ppMoment, R, ppEmitter) {

    var api = {};

    var __promiseCache;

    var defaultToTrue = R.defaultTo(true);
    var defaultToFalse = R.defaultTo(false);
    var defaultToZero = R.defaultTo(0);

    var emitter = ppEmitter.create();
    ppEmitter.mixin(api, emitter);

    function stringToBooleanOrDefault(value) {
        var lowerValue = angular.isString(value) && value.toLowerCase();
        if (lowerValue === 'true') {
            return true;
        } else if (lowerValue === 'false') {
            return false;
        } else {
            return value;
        }
    }

    function booleanToStringOrDefault(value) {
        if (value === true) {
            return 'TRUE';
        } else if (value === false) {
            return 'FALSE';
        } else {
            return value;
        }
    }

    var defaultToNumber = R.curry(function (defaultNumber, amount) {
        var numberAmount = Number(amount);
        var defaultAmt = R.always(defaultNumber);
        var isInvalidAmount = R.anyPass([R.isNil, R.isEmpty, R.equals(NaN), R.equals(0)]);

        return R.cond([
            [isInvalidAmount, defaultAmt],
            [R.is(Number), R.always(numberAmount)]
        ])(numberAmount);
    });

    /*
     * @ngdoc method
     * @name setPreference
     *
     * @description
     * Upserts an investor preference
     *
     * @param {string} key
     * @param {string} value
     */
    api.setPreference = function (key, value) {

        api.purgeCache();

        var endpoint = API_INVESTOR_PREFERENCE_ENDPOINT.replace(':key', key);
        var payload = {
            value: booleanToStringOrDefault(value)
        };

        return $http.put(endpoint, payload).then(
            function (response) {
                api.getPreferences();
                return true;
            },
            function (error) {
                switch (error.status) {
                case 401:
                    return $q.reject({
                        reason: 'auth.error.not.authenticated'
                    });
                case 405:
                    return $q.reject({
                        reason: 'preference.key.not-whitelisted'
                    });
                default:
                    return $q.reject({
                        reason: 'preference.error.unexpected'
                    });
                }
            });
    };

    api.setUserPreference = function (key, value) {

        api.purgeCache();

        var endpoint = API_USER_PREFERENCE_ENDPOINT.replace(':key', key);

        var payload = {
            value: booleanToStringOrDefault(value)
        };

        return $http.put(endpoint, payload).then(
            function (response) {
                return true;
            },
            function (error) {
                switch (error.status) {
                case 401:
                    return $q.reject({
                        reason: 'auth.error.not.authenticated'
                    });
                case 405:
                    return $q.reject({
                        reason: 'preference.key.not-whitelisted'
                    });
                default:
                    return $q.reject({
                        reason: 'preference.error.unexpected'
                    });
                }
            });
    };

    /*
     * @ngdoc method
     * @name getPreferences
     *
     * @description
     * Gets an investor preference from a key
     *
     * Success response:
     * ```
     * {
     *     "AUTO.INVEST.ACTIVE": "TRUE",
     *     "AUTO.INVEST.REINVEST.DIVIDENDS": "TRUE"
     * }
     * ```
     *
     * @returns {promise}
     */
    api.getPreferences = function () {

        var endpoint = API_INVESTOR_PREFERENCES_ENDPOINT;

        if (__promiseCache) {
            return __promiseCache;
        }

        __promiseCache = $http.get(endpoint).then(function (response) {
            var key;
            var preferences = {};
            for (key in response.data) {
                preferences[key] = stringToBooleanOrDefault(response.data[key]);
            }
            emitter.emit('preferences-updated', preferences);
            return preferences;
        }, function (error) {
            __promiseCache = null;
            switch (error.status) {
            case 401:
                return $q.reject({
                    reason: 'auth.error.not.authenticated'
                });
            default:
                return $q.reject({
                    reason: 'preference.error.unexpected'
                });
            }
        });

        return __promiseCache.then(function (data) {
            // data always cloned
            return angular.copy(data);
        });
    };

    /*
     * @ngdoc method
     * @name getPreference
     *
     * @description
     * Gets an investor preference from a key
     *
     * Success response:
     * ```
     * "dismissed"
     * ```
     *
     * @param {string} key
     * @returns {promise}
     */
    api.getPreference = function (key) {
        return api.getPreferences().then(function (preferences) {
            return preferences.hasOwnProperty(key) ? preferences[key] : null;
        });
    };

    api.hasUserAgreedToInvestmentPlanClosedPeriods = function () {
        return api.getPreference(PREFERENCE_AGREED_INVESTMENT_PLAN_CLOSED_PERIOD).then(function (agreed) {
            return !!agreed;
        });
    };

    api.agreeToInvestmentPlanClosedPeriods = function () {
        return api.setUserPreference(PREFERENCE_AGREED_INVESTMENT_PLAN_CLOSED_PERIOD, true);
    };

    api.hasInvestorAgreedToGoodReputeStatement = function () {
        return api.getPreference(PREFERENCE_INVESTOR_GOOD_REPUTE_STATEMENT_AGREE).then(function (agreed) {
            return !!agreed;
        });
    };

    api.getPreDefinedKycType = function () {

        var promises = {
            companyName: api.getPreference(PREFERENCE_KYC_COMPANY_NAME),
            individualName: api.getPreference(PREFERENCE_KYC_INDIVIDUAL_NAME)
        };

        return $q.all(promises).then(function (data) {
            if (data.companyName) {
                return 'business';
            }

            if (data.individualName) {
                return 'individual';
            }

            return undefined;
        });
    };

    api.getCompanyName = function () {
        return api.getPreference(PREFERENCE_KYC_COMPANY_NAME).then(function (companyName) {
            return companyName || undefined;
        });
    };

    api.isInvestorGiftingReferral = function () {
        return api.getPreference(PREFERENCE_IS_GIFTING_REFERRAL).then(function (isGifting) {
            return isGifting === true || isGifting === 'true';
        });
    };

    api.getLatestValuationMethodUpdated = function () {
        return api.getPreference(PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION_HAS_BEEN_UPDATED)
            .then(R.compose(Number, defaultToZero));
    };

    api.isInvestorReinvestingDividends = function () {
        return api.getPreference(PREFERENCE_REINVEST_DIVIDENDS);
    };

    api.setReinvestDividends = function (value) {
        return api.setPreference(PREFERENCE_REINVEST_DIVIDENDS, value);
    };

    api.setCurrentDashboardLatestValuation = function (value) {
        return api.setPreference(PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION, value);
    };

    api.setCurrentDashboardLatestValuationVPV = function (value) {
        return api.setPreference(PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION_VPV, value);
    };

    api.setCurrentDashboardLatestValuationIV = function (value) {
        return api.setPreference(PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION_IV, value);
    };

    api.setCurrentDashboardTradingValuation = function (value) {
        return api.setPreference(PREFERENCE_DASHBOARD_CURRENT_TRADING_VALUATION, value);
    };

    api.setDashboardOverviewValuationMethod = function (value) {
        return api.setPreference(PREFERENCE_DASHBOARD_OVERVIEW_VALUATION_METHOD, value);
    };

    api.hasAgreedToHomeNowDisclaimer = function () {
        return api.getPreference(HOMENOW_REIT_DISCLAIMER_AGREEMENT).then(function (agreed) {
            if (agreed && R.is(String, agreed)) {
                return agreed.toLowerCase() === 'yes';
            } else {
                return false;
            }

        });
    };

    api.setAgreedToHomeNowDisclaimer = function (value) {
        return api.setPreference(HOMENOW_REIT_DISCLAIMER_AGREEMENT, value);
    };

    api.incrementLatestValuationMethodUpdated = function () {
        return api.getLatestValuationMethodUpdated().then(function (currentUpdateCount) {
            return api.setPreference(
                PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION_HAS_BEEN_UPDATED,
                String(currentUpdateCount + 1)
            );
        });
    };

    api.setPropertyVote = function (propertySymbol, voteType, value) {
        var KEY = propertySymbol + '_' + voteType;
        var valueWithDate = value + '_' + ppMoment().format('YYYY-MM-DD');
        return api.setPreference(KEY, valueWithDate);
    };

    api.getPropertyVote = function (propertySymbol, voteType, defaultValue) {
        var KEY = propertySymbol + '_' + voteType;

        var dateReg = /_[0-9\-]*/;
        return api.getPreference(KEY).then(function (res) {
            return (res || defaultValue).replace(dateReg, '');
        });
    };

    function makeArrayUnique(list) {
        return Object.keys(list.reduce(function (acc, item) {
            acc[item] = true;
            return acc;
        }, {}));
    }

    api.setKycNationalities = function (nationalities) {
        if (angular.isArray(nationalities)) {
            return api.setUserPreference(KYC_NATIONALITIES_PREFERENCE, JSON.stringify(makeArrayUnique(nationalities)));
        } else {
            return $q.when();
        }
    };

    api.getKycNationalities = function (nationalities) {
        return api.getPreference(KYC_NATIONALITIES_PREFERENCE).then(function (res) {
            if (!res) {
                return [];
            } else {
                return JSON.parse(res);
            }

        });
    };

    api.showCurrentDashboardLatestValuation = function () {
        return api.getPreference(PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION).then(function (value) {
            return !!defaultToTrue(value);
        });
    };

    api.showCurrentDashboardLatestValuationVPV = function () {
        return api.getPreference(PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION_VPV).then(function (value) {
            return !!defaultToTrue(value);
        });
    };

    api.showCurrentDashboardLatestValuationIV = function () {
        return api.getPreference(PREFERENCE_DASHBOARD_CURRENT_LATEST_VALUATION_IV).then(function (value) {
            return !!defaultToFalse(value);
        });
    };

    api.showCurrentDashboardTradingValuation = function () {
        return api.getPreference(PREFERENCE_DASHBOARD_CURRENT_TRADING_VALUATION).then(function (value) {
            return !!value;
        });
    };

    api.getDashboardOverviewValuationMethod = function (value) {
        return api.getPreference(PREFERENCE_DASHBOARD_OVERVIEW_VALUATION_METHOD)
            .then(R.defaultTo('vpv'));
    };

    api.isSourceOfFundsRequired = function () {
        return api.getPreference(PREFERENCE_FUNDING_SOURCE_DATE).then(function (date) {
            var aYearAgo = ppMoment().subtract(1, 'year');
            if (date && ppMoment(date).isAfter(aYearAgo)) {
                return false;
            } else {
                return true;
            }
        }, function () {
            return false;
        });
    };

    api.setInvestorAsGiftingReferral = api.setUserPreference.bind(this, PREFERENCE_IS_GIFTING_REFERRAL, true);

    api.tradingValuationAvailable = function () {
        return api.getPreference(RESALE_VALUATION_FEATURE_AVAILABLE);
    };

    api.setReadMemorandum = function () {
        return api.setUserPreference(PREFERENCE_READ_UNDERSTOOD_MEMORANDUM, true);
    };

    api.hasReadMemorandum = function () {
        return api.getPreference(PREFERENCE_READ_UNDERSTOOD_MEMORANDUM);
    };

    api.hasAcceptedMemorandumNda = function () {
        return api.getPreference(PREFERENCE_MEMORANDUM_NDA);
    };

    api.setAcceptedMemorandumNda = function () {
        return api.setUserPreference(PREFERENCE_MEMORANDUM_NDA, true);
    };

    api.canAccessFundingRound = function () {
        return api.getPreference(PREFERENCE_CAN_ACCESS_FUNDING_ROUND).then(function (res) {
            return !!res;
        });
    };

    api.getDefaultQuoteFundingRound = function () {
        return api.getPreference(PREFERENCE_DEFAULT_QUOTE_FUNDING_ROUND)
            .then(defaultToNumber(5000));
    };

    api.storeSignupCountry = function (countryCode) {
        if (countryCode) {
            return api.setUserPreference(PREFERENCE_SIGNUP_COUNTRY, countryCode);
        } else {
            return $q.when();
        }
    };

    api.areKeyRisksUnderstood = function () {
        return api.getPreference(PREFERENCE_KEYS_RISKS_UNDERSTOOD).then(function (res) {
            return !!res;
        });
    };

    api.storeKeyRisksUnderstood = function () {
        return api.setPreference(PREFERENCE_KEYS_RISKS_UNDERSTOOD, ppMoment().format('YYYY-MM-DD'));
    };

    /*
     * @ngdoc method
     * @name deletePreference
     *
     * @description
     * deletes an investor preference
     *
     * @param {string} key
     */
    api.deletePreference = function (key) {

        var endpoint = API_INVESTOR_PREFERENCE_ENDPOINT.replace(':key', key);

        return $http.delete(endpoint).then(
            function (response) {
                return true;
            },
            function (error) {
                switch (error.status) {
                case 401:
                    return $q.reject({
                        reason: 'auth.error.not.authenticated'
                    });
                case 405:
                    return $q.reject({
                        reason: 'preference.key.not-whitelisted'
                    });
                default:
                    return $q.reject({
                        reason: 'preference.error.unexpected'
                    });
                }
            });
    };

    function isVote(item) {
        return /VOTE/.test(R.toString(item[0]));
    }

    var extractVotePart = R.curry(function (regex, index, text) {
        var res = text.match(regex);
        if (R.is(Array, res)) {
            if (!index) {
                return R.head(res);
            } else {
                return R.prop(index, res);
            }
        }
    });

    var extractVoteSymbol = extractVotePart(/[A-Za-z0-9]+/, 0);
    var extractVoteDescision = extractVotePart(/[A-Za-z]+/, 0);
    var extractVoteDate = extractVotePart(/[0-9\-]+/, 0);
    var extractVoteType = extractVotePart(/(_)([A-Za-z_]+)/, 2);
    var getVotedOn = R.path(['votedOn']);
    var isDate = R.is(Date);

    function voteDateAscending(voteA, voteB) {
        var votedOnA = getVotedOn(voteA);
        var votedOnB = getVotedOn(voteB);

        if (R.and(isDate(votedOnA), isDate(votedOnB))) {
            return voteA.votedOn.valueOf() - voteB.votedOn.valueOf();
        } else {
            return -1;
        }
    }

    function normaliseVote(item) {
        var descision = item[1];
        var vote = item[0];
        var dateM = ppMoment(new Date(extractVoteDate(descision)));

        return {
            symbol: extractVoteSymbol(vote),
            choice: extractVoteDescision(descision),
            votedOn: dateM.isValid() ? dateM.toDate() : undefined,
            voteType: extractVoteType(vote)
        };
    }

    // Sorting votes ascending by date means that if two votes on one property have been performed
    // The most recent vote is included in the preference map
    // which is what you want from the UI's perspective
    api.createVotingPreferenceMap = R.compose(
        R.indexBy(R.path(['symbol'])),
        R.sort(voteDateAscending),
        R.map(normaliseVote),
        R.filter(isVote),
        R.toPairs
    );

    api.getVotingPreferenceMap = function () {
        return api.getPreferences().then(api.createVotingPreferenceMap);
    };

    api.purgeCache = function () {
        __promiseCache = null;
    };

    return api;
}]);
})(window, window.angular);