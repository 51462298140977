(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.investment-plan-pre-suitability-quiz', [
    'pp.widgets-templates',
    'pp.widgets.return-composition-bar',
    'pp.values.investment-plans',
    'pp.services.core'
]);

ngModule.component('ppInvestmentPlanPreSuitabilityQuiz', {
    templateUrl: 'widgets/_angular/investment-plan-pre-suitability-quiz/investment-plan-pre-suitability-quiz.tpl.html',
    bindings: {
        investmentPlan: '<',
        onContinue: '&'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', 'investmentPlans', function ($scope, ppConfig, investmentPlans) {
        var vm = this;

        // -- initial state

        // -- util functions
        var __config = ppConfig.get('pp.external.endpoints') || {};

        var assetsEndpoint = __config.assets;
        // -- api

        // -- scope bindings
        vm.$onInit = function () {
            vm.plan = investmentPlans[vm.investmentPlan] || {};
            vm.icon = investmentPlans.createIconUrl(assetsEndpoint, vm.plan.key);
        };

        // -- main

    }]
});
})(window, window.angular);