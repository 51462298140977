(function(window, angular){'use strict';

var ngModule = angular.module('pp.services.data', []);

// @todo conversion phase-2 request from cloudfront is CORS is enabled
var RESOURCES_ENDPOINT = '/resource';

/**
 * @ngdoc service
 * @name dataService
 *
 * @description
 * Provides methods to invoke the `/resource/data` endpoints.
 */
ngModule.service('dataService', ['$http', function ($http) {

    var promiseCache = {};

    var api = {};

    /**
     * @ngdoc method
     * @name dataService#getAreaHpi
     *
     * @description
     * Given one area code (ex: `greenwhich`) returns a promise that resolves with a list of HPI points.
     * ```
     * [
     *    {
     *      "date": "January 1995",
     *      "index": 100
     *    }, ...
     * ]
     * ```
     * Loads data from the resources endpoint: `/resource/data/hpi/:area.json`.
     * Promises are cached, only one request ever made per area.
     *
     * @param {string} area
     * @returns {Promise}
     */
    api.getAreaHpi = function (area) {

        var endpoint = RESOURCES_ENDPOINT + '/data/hpi/' + area + '.json';
        var cacheKey = area + '.hpi';

        if (!promiseCache[cacheKey]) {
            var promise = $http.get(endpoint).then(function (response) {
                // payload is encapsulated in an array and an object with a hpiResults property
                // [{hpiResults: <ACTUAL_ARRAY_SERIES_HERE> }]
                return response.data[0].hpiResults;
            });
            promiseCache[cacheKey] = promise;
        }
        return promiseCache[cacheKey];
    };

    return api;
}]);
})(window, window.angular);