(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card', [
    'pp.widgets-templates',
    'pp.widgets.property-card-fund-header',
    'pp.widgets.property-card-header',
    'pp.widgets.property-thumbnail',
    'pp.widgets.property-card-details-pre-order',
    'pp.widgets.property-card-details-primary',
    'pp.widgets.property-card-details-secondary',
    'pp.widgets.property-details-loan',
    'pp.widgets.property-details-loan-blocked',
    'pp.widgets.property-details-fund',
    'pp.widgets.property-details-fund-blocked',
    'pp.services.core'
]);

/**
 * @ngdoc directive
 * @name ppPropertyCard
 * @description
 * Provides a color banner with the property Card.
 *
 * @restrict A
 * @scope
 * @param {Object} ppPropertyCard the property model
 */
ngModule.component('ppPropertyCard', {
    templateUrl: 'widgets/_angular/property-card/property-card.tpl.html',
    bindings: {
        property: '<',
        user: '<',
        holdings: '<',
        bids: '<',
        offers: '<',
        disableLink: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', '$window', '$timeout', '$element', 'ppComponentEmitter', 'ppTrack', function ($scope, $window, $timeout, $element, ppComponentEmitter, ppTrack) {
        var vm = this;

        // -- initial state
        vm.ready = true;

        // -- util functions

        // -- api

        vm.goToProperty = function () {
            ppTrack.deferAction('pp.map-view.property-card-click', {
                symbol: vm.property.symbol
            });
        };

        // -- lifecycle

        vm.$postLink = function () {
            $timeout(function () {
                if (vm.property && vm.property.symbol) {
                    ppComponentEmitter.emit('property-card.dom.loaded.' + vm.property.symbol, $element);
                }
            });
        };

        vm.$onChanges = function (changes) {
            if (changes.disableLink && changes.disableLink.currentValue) {
                vm.path = undefined;
            }
        };

        // -- main

        vm.$onInit = function () {
            if (vm.property && vm.property.symbol) {
                ppComponentEmitter.emit('property-card.controller.loaded.' + vm.property.symbol, vm);
            }

            if (!vm.disableLink) {
                vm.path = '/properties/' + vm.property.symbol;
            }
        };

    }]
});
})(window, window.angular);