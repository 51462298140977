(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketplace-no-results', ['pp.widgets-templates', 'pp.services.core']);

ngModule.directive('ppMarketplaceNoResults', ['ppTrack', function (ppTrack) {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/marketplace-no-results/marketplace-no-results.tpl.html',
        scope: {
            noResults: '<',
            search: '<',
            filter: '<',
            hasFilter: '<',
            clearFilter: '&',
            clearSearch: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            // -- initial state

            // -- api

            // -- scope bindings

            // -- main

        }]
    };
}]);
})(window, window.angular);