(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.input-plus-minus', ['ng.cork.ui.focus-on', 'pp.components-templates', 'pp.components.bind-invalid-model']);

var MAX_LENGTH = 8;
var MAXIMUM_AMOUNT = 99999999;
var DEFAULT_STEP = 1;
var DEFAULT_SYMBOL = 'p';

/**
 * @ngdoc directive
 * @name amount
 * @description
 * Amount input
 *
 * **EVENTS**
 * - emit on `$scope` event `pp.input-amount.onEnter` with no payload (consumed by (see quoteCtrl, fundCtrl, investCtrl)
 *
 * @restrict A
 * @scope
 * @param {object} ngModel
 * @param {number} invalidAmount
 * @param {number} minimum
 * @param {number} maximum
 * @param {string} pattern
 * @param {string} placeholder
 * @param {boolean} inputDisabled
 * @param {string} focusOn set to 'auto' to auto-focus input, or to a string to listen for on $scope in order to focus on demand
 */
ngModule.directive('ppInputPlusMinus', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        templateUrl: 'components/_angular/input-plus-minus/input-plus-minus.tpl.html',
        scope: {
            amount: '=ngModel',
            invalidAmount: '=?',
            inputId: '@',
            minimum: '@',
            maximum: '@',
            placeholder: '=',
            regexp: '=',
            inputDisabled: '=',
            formName: '@',
            step: '@',
            symbol: '@',
            focusOn: '@'
        },
        link: function ($scope, $elem, $attr) {
            // because input number does not even update viewValues when number invalid (ex: 0..3)
            var $input = $elem.find('input');

            // update validity
            //@TODO post-conversion-phase-2 refactor this watch to abstract nested if statements
            $scope.$watch(function () {
                return $input.val();
            }, function (value) {
                //For the invisible value that positions the pence symbol in the correct place
                $scope.vm.stringAmount = value;
                if ($scope.vm.regexp && !value.length || $scope.vm.regexp && !$scope.vm.regexp.test(value)) {
                    $scope.vm.ppInputPlusMinus.$setValidity('regexp', false);
                } else {
                    $scope.vm.ppInputPlusMinus.$setValidity('regexp', true);
                    if (angular.isDefined($scope.vm.minimum) && Number(value) < Number($scope.vm.minimum)) {
                        $scope.vm.ppInputPlusMinus.$setValidity('minimum', false);
                        $scope.vm.ppInputPlusMinus.$setValidity('maximum', true);
                    } else {
                        $scope.vm.ppInputPlusMinus.$setValidity('minimum', true);
                        if (angular.isDefined($scope.vm.maximum) && Number(value) > Number($scope.vm.maximum)) {
                            $scope.vm.ppInputPlusMinus.$setValidity('maximum', false);
                        } else {
                            $scope.vm.ppInputPlusMinus.$setValidity('maximum', true);
                        }
                    }
                }
            });

        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', function ($scope) {
            var vm = this;

            vm.maxlength = MAX_LENGTH;

            //vm.maximum = $scope.maximum || MAXIMUM_AMOUNT;

            // -- initial state

            var step;

            // -- util functions

            // -- api

            vm.increment = function () {
                vm.amount = Number((vm.invalidAmount + step).toFixed(2));
            };

            vm.decrement = function () {
                vm.amount = Number((vm.invalidAmount - step).toFixed(2));
            };

            vm.onKeyPress = function ($ev) {
                if ($ev.keyCode === 13 || $ev.key === 13) {
                    $scope.$emit('pp.input-plus-minus.onEnter');
                } else {
                    var viewValue = vm.ppInputPlusMinus.amount.$viewValue;
                    if (viewValue && viewValue.length >= vm.maxlength) {
                        $ev.preventDefault();
                    }
                }
            };

            // -- main

            vm.$onInit = function () {
                step = Number(vm.step) || DEFAULT_STEP;
                vm.templateSymbol = vm.symbol || DEFAULT_SYMBOL;
            };
        }]
    };
}]);
})(window, window.angular);