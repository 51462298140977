(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.what-to-expect', [
    'pp.widgets-templates',
    'pp.services.what-to-expect',
    'pp.services.core',
    'pp.widgets.figure-title-description'
]);

ngModule.component('ppWhatToExpect', {
    templateUrl: 'widgets/_angular/what-to-expect/what-to-expect.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$element', 'whatToExpectService', 'ppComponentEmitter', function ($element, whatToExpectService, ppComponentEmitter) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

        whatToExpectService.get().then(function (data) {
            vm.content = data.content || {};
            vm.buttons = data.buttons;
            vm.titles = data.titles;
            vm.weAreGrowingFastNote = data.weAreGrowingFastNote;
            vm.ready = true;
            ppComponentEmitter.emit('homepage.what-to-expect.dom.loaded', $element);
        }, function () {
            vm.ready = true;
            vm.loadingError = true;
        });

    }]
});
})(window, window.angular);