(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.risk-warning', [
    'pp.services.core'
]);

/**
 * @ndgoc service
 * @description
 * checks if we need to notify the user that they have been qualified/disqualified for an rff scheme
 * shows the dialog and sets a preference when the user dismisses it
 * so that the dialog is never shown again for the same scheme
 */
ngModule.service('riskWarningService', ['$rootScope', 'R', function ($rootScope, R) {

    // -- util functions

    // -- api

    var OVERRIDE_RISK_WARNINGS = [
        '.dev-loan-risk-warning',
        '.client-fundraising-risk-warning'
    ];

    var MAIN_RISK_WARNING_CLASS = '.main-risk-warning-fca';

    var api = {};

    var setAppContainerMargin = function (cssClassName) {
        return function () {
            var height = $(cssClassName).outerHeight();
            $('#app-container').css({
                marginTop: height
            });
        };
    };

    var showRiskWarning = function (cssClassName) {
        $(cssClassName).removeClass('ng-hide');
    };

    var showOverrideRiskWarning = R.once(function (cssClassName) {
        $(MAIN_RISK_WARNING_CLASS).addClass('ng-hide');
        showRiskWarning(cssClassName);
    });

    api.showDevLoanRiskWarning = function () {
        // Removed this risk warning as a result of a request from the FCA to always show the same risk warning
        // showOverrideRiskWarning('.dev-loan-risk-warning');
    };

    api.showFundRaisingRiskWarning = function () {
        // Removed this risk warning as a result of a request from the FCA to always show the same risk warning
        // showOverrideRiskWarning('.client-fundraising-risk-warning');
    };

    api.showMainRiskWarning = function () {
        showRiskWarning(MAIN_RISK_WARNING_CLASS);
    };

    return api;
}]);
})(window, window.angular);