(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.classification', [
    'pp.widgets-templates',
    'pp.services.core',
    'pp.services.classification',
    'pp.services.investor',
    'pp.services.route',
    'pp.widgets.classification-quiz',
    'pp.components.checkbox',
    'pp.widgets.previous-next-stepper',
    'pp.widgets.suitability-quiz'
]);

var CLASSIFICATION_FORM_NAME = 'classification.certify';
var ERROR_DISPLAY_MSECONDS = 10000;
var PAGE_NAME = 'classification';
var QUIZ_BLOCK_STEP = 'quiz-block';

ngModule.directive('ppClassification', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/classification/classification.tpl.html',
        scope: {
            user: '<',
            quizPassed: '=?',
            onFail: '&',
            onSuccess: '&',
            isReclassification: '<',
            isPage: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$q', '$timeout', '$element', '$anchorScroll', '$location', 'ppConfig', 'ppTrack', 'classificationService', 'investorService', 'ppUtil', 'R', function ($scope, $q, $timeout, $element, $anchorScroll, $location, ppConfig, ppTrack, classificationService, investorService, ppUtil, R) {
            var vm = this;

            // -- initial state

            var __ignoreDismiss = false;
            var __config = ppConfig.get('pp.modules.auth') || {};

            var __maxQuizRetries = __config.maxQuizRetries;

            vm.scrollId = ppUtil.uuid();

            vm.hnwForm = {};

            vm.classificationLabelMap = {
                'high-net': 'High Net Worth',
                'sophisticated': 'Self Certified Sophisticated',
                'regular': 'Restricted'
            };

            vm.hnw = {
                asset: {
                    question: 'net assets of £250,000 or more? Net assets do NOT include: your home (primary residence), your pension ( or any pension withdrawals) or any rights under qualifying contracts of insurance.',
                    answer: {
                        confirmed: undefined,
                        details: undefined
                    }
                },
                income: {
                    question: 'an annual income of £100,000 or more? Income does NOT include any one off pension withdrawals.',
                    answer: {
                        confirmed: undefined,
                        details: undefined
                    }
                }
            };

            vm.siForm = {};

            vm.si = {
                privateEquity: {
                    question: 'worked in private equity or in the provision of finance for small and medium enterprises?',
                    answer: {
                        confirmed: undefined,
                        details: undefined
                    }
                },
                director: {
                    question: 'been the director of a company with an annual turnover of at least £1million?',
                    answer: {
                        confirmed: undefined,
                        details: undefined
                    }
                },
                investments: {
                    question: 'made two or more investments in unlisted company?',
                    answer: {
                        confirmed: undefined,
                        details: undefined
                    }
                },
                syndicate: {
                    question: 'been a member of a network or syndicate of business angels for more than six months?',
                    answer: {
                        confirmed: undefined,
                        details: undefined
                    }
                }
            };

            vm.ri = {
                investment: {
                    question: 'In the past twelve months have you invested less than 10% of your net assets in high-risk investments (as defined above)?',
                    answer: {
                        confirmed: undefined,
                        details: undefined
                    }
                },
                intent: {
                    question: 'In the next twelve months do you intend to limit your investments in high-risk investments (as defined above) to less than 10% of your net assets?',
                    answer: {
                        confirmed: undefined,
                        details: undefined
                    }
                }
            };

            var hnwDetailsToString = R.compose(
                R.over(R.lensPath(['asset', 'answer', 'details']), R.toString()),
                R.over(R.lensPath(['income', 'answer', 'details']), R.toString())
            );

            var siDetailsToString = R.compose(
                R.over(R.lensPath(['investments', 'answer', 'details']), R.toString())
            );

            var riDetailsToString = R.compose(
                R.over(R.lensPath(['investment', 'answer', 'details']), R.toString()),
                R.over(R.lensPath(['intent', 'answer', 'details']), R.toString())
            );

            vm.hnwNoneApplyDisabled = false;
            vm.siNoneApplyDisabled = false;

            // -- util functions

            function hnwAnswers() {
                return [
                    R.path(['asset', 'answer', 'confirmed'], vm.hnw),
                    R.path(['income', 'answer', 'confirmed'], vm.hnw)
                ];
            }

            function siAnswers() {
                return [
                    R.path(['privateEquity', 'answer', 'confirmed'], vm.si),
                    R.path(['director', 'answer', 'confirmed'], vm.si),
                    R.path(['investments', 'answer', 'confirmed'], vm.si),
                    R.path(['syndicate', 'answer', 'confirmed'], vm.si)
                ];
            }

            function hnwSetActiveNoneApply(isActive) {
                vm.hnwNoneApplyDisabled = !isActive;
            }

            function hnwClearNoneApply() {
                vm.hnw.noneApply = false;
            }

            function hnwSetNoneApplyTrue() {
                vm.hnw.noneApply = true;
            }

            function hnwSetNoneApplyRequired(isRequired) {
                vm.hnwNoneApplyRequired = isRequired;
            }

            function siSetActiveNoneApply(isActive) {
                vm.siNoneApplyDisabled = !isActive;
            }

            function siClearNoneApply() {
                vm.si.noneApply = false;
            }

            function siSetNoneApplyTrue() {
                vm.si.noneApply = true;
            }

            function siSetNoneApplyRequired(isRequired) {
                vm.hnwNoneApplyRequired = isRequired;
            }

            function mustCertifyNoneApply(answers) {
                return answers.filter(function (answer) {
                    return answer === 'yes';
                }).length === 0;
            }

            function scrollToTop() {
                $anchorScroll.yOffset = 200;
                $anchorScroll(vm.scrollId);
                $scope.$emit('auth-modal.scroll-top');
            }

            function setStep(step) {
                ppTrack.flowStep(PAGE_NAME, step);
                if (step === 'quiz' || step === 'quiz-intro') {
                    ppTrack.setContext('classification.quiz.atempt', vm.user.classificationAttempts + 1);

                    return classificationService.getQuizQuestions().then(function (questions) {
                        vm.questions = questions;
                        vm.step = step;
                        scrollToTop();
                    }, function () {
                        vm.step = QUIZ_BLOCK_STEP;
                        scrollToTop();
                    });
                } else {
                    return $q.when().then(function () {
                        vm.step = step;
                        scrollToTop();
                    });
                }

            }

            function refreshUser() {
                investorService.purgeCache('^investor$');
                return investorService.getInvestor().then(function (user) {
                    vm.attemptsRemaining = __maxQuizRetries - user.classificationAttempts;
                    return user;
                });
            }

            function getFormErrors() {
                var values = {
                    'agree': false
                };
                var errors = {
                    'agree': 'required'
                };
                return {
                    values: values,
                    errors: errors
                };
            }

            function handleUnknownError() {
                vm.hasError = true;
                vm.errorMsg = 'An error occurred, please try again.';
                $timeout(function () {
                    vm.hasError = false;
                    vm.errorMsg = '';
                }, ERROR_DISPLAY_MSECONDS);
            }

            function certifyAndFinish() {
                vm.isProcessing = true;
                vm.hasError = false;

                var promise;

                //On reclassification all classifications post to the same endpoint
                if (vm.classification === 'regular' && !vm.reclassification) {
                    promise = classificationService.regularInvestorClassification();
                } else {
                    promise = classificationService.selfDeclaredInvestorClassification(vm.classification);
                }
                promise.then(function () {
                    // @todo post-conversion-phase-2 .. this is  hacky, maybe use investorService to do this, or at least document better
                    ppTrack.setContext('user.stage', 'classified');
                    ppTrack.setContext('user.classification', vm.classification);
                    ppTrack.unsetContext('step.classification');
                    ppTrack.formSubmit(CLASSIFICATION_FORM_NAME, 'success');
                    __ignoreDismiss = true;
                    vm.onSuccess();
                }, function () {
                    ppTrack.formError(CLASSIFICATION_FORM_NAME, 'unknown');
                    vm.isProcessing = false;
                    handleUnknownError();
                });
            }

            function init(user) {
                ppTrack.setContext('classification.quiz.atempt', user.classificationAttempts + 1);

                if (user.classification && vm.isReclassification && !vm.reclassificationComplete) {
                    ppTrack.setContext('classification.reclassify', true);
                    vm.previousClassification = investorService.getClassificationMappedValue(user.classification, 'key');
                    vm.userClass = vm.previousClassification;
                    vm.reclassification = true;
                    //if this a reclassification the step is always start because there is no quiz
                    return setStep('start');
                } else if (user.classification && user.permissions.complianceRequired) {
                    vm.classification = investorService.getClassificationMappedValue(user.classification, 'key');
                    ppTrack.setContext('classification.choice', vm.classification);
                    return setStep('quiz-intro');
                } else if (user.classification && !user.permissions.complianceRequired) {
                    return $q.when().then(function () {
                        vm.onSuccess();
                    });
                } else {
                    return setStep('start');
                }
            }

            // -- api

            vm.restartClassification = function () {
                setStep('start');
            };

            vm.submitHNW = function () {
                if (vm.hnwForm.$valid) {
                    vm.processing = true;
                    var questions = hnwDetailsToString(vm.hnw);
                    return classificationService.selfDeclaredInvestorClassification(
                        vm.classification,
                        '',
                        [questions.asset, questions.income],
                        vm.hnw.noneApply ? vm.hnw.noneApply : undefined,
                        vm.hnw.agree ? 'yes' : 'no'
                    ).then(function (res) {
                        if (vm.reclassification) {
                            vm.reclassificationComplete = true;
                        }
                        return refreshUser().then(init);
                    }, function () {
                        return setStep('classification-blocked');
                    }).then(function () {
                        vm.processing = false;
                    }, function () {
                        vm.processing = false;
                    });
                }

            };

            vm.submitSI = function () {
                if (vm.siForm.$valid) {
                    vm.processing = true;
                    var questions = siDetailsToString(vm.si);
                    return classificationService.selfDeclaredInvestorClassification(
                        vm.classification,
                        '',
                        [questions.privateEquity, questions.director, questions.investments, questions.syndicate],
                        vm.si.noneApply ? vm.si.noneApply : undefined,
                        vm.si.agree ? 'yes' : 'no'
                    ).then(function (res) {
                        if (vm.reclassification) {
                            vm.reclassificationComplete = true;
                        }
                        return refreshUser().then(init);
                    }, function () {
                        return setStep('classification-blocked');
                    }).then(function () {
                        vm.processing = false;
                    }, function () {
                        vm.processing = false;
                    });
                }
            };

            vm.submitRI = function () {
                if (vm.riForm.$valid) {
                    vm.processing = true;
                    var questions = riDetailsToString(vm.ri);
                    return classificationService.selfDeclaredInvestorClassification(
                        vm.classification,
                        '',
                        [questions.investment, questions.intent],
                        undefined,
                        vm.ri.agree ? 'yes' : 'no'
                    ).then(function (res) {
                        if (vm.reclassification) {
                            vm.reclassificationComplete = true;
                        }
                        return refreshUser().then(init);
                    }, function () {
                        return setStep('classification-blocked');
                    }).then(function () {
                        vm.processing = false;
                    }, function () {
                        vm.processing = false;
                    });
                }
            };

            vm.startQuiz = function () {
                ppTrack.action('classification.assessment.start');
                setStep('quiz');
            };

            vm.retryQuiz = function () {
                ppTrack.action('classification.assessment.retry');
                setStep('quiz');
            };

            vm.onSubmitQuiz = function (answers) {
                return classificationService.postQuizAnswers(answers, vm.questions.name).then(function (res) {
                    if (R.prop('status', res) === 'failed') {
                        return refreshUser().then(function () {
                            setStep('quiz-retry');
                        });
                    } else {
                        return refreshUser().then(init);
                    }
                });
            };

            vm.setUserClass = function (classification) {
                vm.userClass = classification;
                ppTrack.setContext('classification.choice', vm.userClass);
                vm.classification = vm.userClass;
                setStep('certify');
                scrollToTop();
            };

            vm.quizCancelled = function () {
                setStep('start');
                vm.userClass = '';
            };

            vm.exit = function () {
                vm.onFail();
            };

            vm.back = function () {
                vm.classification = null;
                setStep('start');
                vm.agree = false;
                vm.hasError = false;
            };

            vm.certify = function () {
                if (vm.agree) {
                    certifyAndFinish();
                    return null;
                } else {
                    vm.hasError = true;
                    var errors = getFormErrors();
                    ppTrack.formValidation(CLASSIFICATION_FORM_NAME, errors.values, errors.errors);
                }
            };

            // -- scope bindings

            function normaliseNoneApply(getAnswers, setActive, clearValue, setNoneApply, setNoneApplyRequired) {
                return function () {
                    if (mustCertifyNoneApply(getAnswers())) {
                        setActive(true);
                        setNoneApplyRequired(true);
                    } else {
                        setActive(false);
                        clearValue();
                        setNoneApplyRequired(false);
                    }
                };
            }

            var hnwNormaliseNoneApply = normaliseNoneApply(
                hnwAnswers,
                hnwSetActiveNoneApply,
                hnwClearNoneApply,
                hnwSetNoneApplyTrue,
                hnwSetNoneApplyRequired
            );

            var siNormaliseNoneApply = normaliseNoneApply(
                siAnswers,
                siSetActiveNoneApply,
                siClearNoneApply,
                siSetNoneApplyTrue,
                siSetNoneApplyRequired
            );

            $scope.$watch('vm.hnw.income.answer.confirmed', hnwNormaliseNoneApply);
            $scope.$watch('vm.hnw.asset.answer.confirmed', hnwNormaliseNoneApply);

            $scope.$watch('vm.si.privateEquity.answer.confirmed', siNormaliseNoneApply);
            $scope.$watch('vm.si.director.answer.confirmed', siNormaliseNoneApply);
            $scope.$watch('vm.si.investments.answer.confirmed', siNormaliseNoneApply);
            $scope.$watch('vm.si.syndicate.answer.confirmed', siNormaliseNoneApply);

            // -- main

            vm.$onInit = function () {
                vm.quizPassed = vm.quizPassed || false;
                vm.userClass = '';
                vm.currentDate = new Date();
                vm.reclassification = false;
                init(vm.user);
            };

        }]
    };
}]);

/**
 * @ngdoc directive
 * @name ppClassificationIntro
 * @description
 *
 * @restrict A
 */
ngModule.directive('ppClassificationIntro', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/classification/classification-intro.tpl.html'
    };
}]);

/**
 * @ngdoc directive
 * @name ppClassificationBlocked
 * @description
 *
 * @restrict A
 */
ngModule.directive('ppClassificationBlocked', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/classification/classification-blocked.tpl.html'
    };
}]);

/**
 * @ngdoc directive
 * @name ppClassificationQuizIntro
 * @description
 *
 * @restrict A
 */
ngModule.directive('ppClassificationQuizIntro', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/classification/classification-quiz-intro.tpl.html'
    };
}]);

/**
 * @ngdoc directive
 * @name ppClassificationQuizRetry
 * @description
 *
 * @restrict A
 */
ngModule.directive('ppClassificationQuizRetry', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/classification/classification-quiz-retry.tpl.html'
    };
}]);

/**
 * @ngdoc directive
 * @name ppClassificationQuizBlock
 * @description
 *
 * @restrict A
 */
ngModule.directive('ppClassificationQuizBlock', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/classification/classification-quiz-block.tpl.html',
        scope: {
            user: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', 'routeService', function ($scope, routeService) {
            var vm = this;
            vm.keyRisksUrl = routeService.keyRisksUrl;
        }]
    };
}]);

/**
 * @ngdoc directive
 * @name ppClassificationCertifyRegular
 * @description
 *
 * @restrict A
 */
ngModule.directive('ppClassificationCertifyRegular', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/classification/classification-certify-regular.tpl.html'
    };
}]);

/**
 * @ngdoc directive
 * @name ppClassificationCertifyHighNet
 * @description
 *
 * @restrict A
 */
ngModule.directive('ppClassificationCertifyHighNet', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/classification/classification-certify-high-net.tpl.html'
    };
}]);

/**
 * @ngdoc directive
 * @name ppClassificationCertifySophisticated
 * @description
 *
 * @restrict A
 */
ngModule.directive('ppClassificationCertifySophisticated', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/classification/classification-certify-sophisticated.tpl.html'
    };
}]);
})(window, window.angular);