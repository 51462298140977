(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.pin-validation', []);

/**
 * @ngdoc directive
 * @name ppPasswordValidation
 * @description
 * Validates pin against hard-coded and intrusive rules.
 *
 * @restrict A
 * @requires ngModel
 *
 * @param {string} ngModel Assignable angular expression to data-bind to.
 */
ngModule.directive('ppPinValidation', [function () {

    var PATTERN_NUM = /^[0-9]*$/;
    var PIN_LENGTH = 6;

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, $elem, $attrs, $ctrl) {

            // NOTE: anti-pattern test specific code, but no other way to check if a transparent directive was instantiated correctly
            $scope.ppTestHelperDirectiveName = 'pp-pin-validation';

            $ctrl.$validators.pinNumbers = function (modelValue, viewValue) {
                if ($ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                return PATTERN_NUM.test(viewValue);
            };

            $ctrl.$validators.pinLength = function (modelValue, viewValue) {
                if ($ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                return modelValue.length === PIN_LENGTH;
            };

            $scope.$watch(function () {
                return $ctrl.$viewValue;
            }, function (pin) {
                if (!pin) {
                    return;
                }
                if (pin.length > PIN_LENGTH) {
                    $ctrl.$setViewValue($ctrl.$viewValue.substr(0, PIN_LENGTH));
                    $ctrl.$render();
                }
            });
        }
    };
}]);
})(window, window.angular);