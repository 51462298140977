(function(window, angular){'use strict';

var ngModule = angular.module('pp.ui.services.suitability-quiz-dialog', [
    'pp.services.core',
    'pp.services.dialog',
    'pp.services.suitability',
    'pp.widgets.suitability'
]);

/**
 * @ndgoc service
 * @description
 * Displays a modal with the contents of `styleguide/widgets/_angular/extra-user-data`.
 */
ngModule.service('suitabilityQuizDialog', ['$rootScope', '$q', 'ppTrack', 'dialogService', 'suitabilityService', 'R', function ($rootScope, $q, ppTrack, dialogService, suitabilityService, R) {

    // -- initial state

    // -- util functions

    var dialogInstance;

    var __scope = {};
    var __defer;

    function getSuitabilityStatus(quizType, classification) {
        return suitabilityService.getSuitability(quizType, classification).then(function (res) {
            return R.mergeRight(res, {
                quizType: quizType
            });
        });
    }

    function createScope() {
        __scope = $rootScope.$new();
        return __scope;
    }

    function submitSuitability(quizType, getSuitability, defer, classification, answers) {
        return suitabilityService.submitAnswers(quizType, answers, classification)
            .then(getSuitability)
            .then(function (res) {

                var suitability = res.suitability || {};

                // keep suitability up to date in child components
                __scope.suitability = suitability;

                __scope.quizType = suitability.quizType;

                __scope.allQuizzes = res.allQuizzes;
                __scope.allNotFailed = res.allNotFailed;

                var onSubmitSuitabilityCall = __scope.customCallback || getSuitabilityStatus.bind(null, suitability.quizType, classification);
                __scope.onSubmit = submitSuitability.bind(null, suitability.quizType, onSubmitSuitabilityCall, __defer, classification);

                if (suitability.status === 'passed') {
                    defer.resolve();
                }
                return {
                    suitability: suitability,
                    allQuizzes: res.allQuizzes,
                    unfailedQuizzesLeft: res.allNotFailed
                };
            });
    }

    function statusNotEqualFailed(suitability) {
        return R.prop('status', suitability) !== 'failed';
    }

    function propEqPassed(suitability) {
        return R.prop('status', suitability) === 'passed';
    }

    function getSuitabilityStatusForQuizzes(quizzes, classification) {
        return function () {
            var getAllPassed = R.filter(propEqPassed);
            var getAllNotPassed = R.filter(
                statusNotEqualFailed
            );
            var firstNotFailedQuiz = R.compose(
                R.head,
                getAllNotPassed
            );
            return $q.all(quizzes.map(function (quiz) {
                return getSuitabilityStatus(quiz, classification);
            })).then(function (res) {
                var firstPassed = R.head(getAllPassed(res));
                if (firstPassed) {
                    return {
                        suitability: firstPassed
                    };
                } else {
                    var firstNotFailed = firstNotFailedQuiz(res);
                    var allNotFailed = getAllNotPassed(res);
                    if (firstNotFailed) {
                        return {
                            suitability: firstNotFailed,
                            allNotFailed: allNotFailed,
                            allQuizzes: res
                        };

                    } else {
                        var firstQuiz = R.head(res);
                        return {
                            suitability: firstQuiz
                        };

                    }
                }
            });
        };
    }

    // create a scope, show the dialog
    function showDialog(suitability, quizType, classification, onSubmitSuitability, allQuizzes, allNotFailed) {

        ppTrack.event('suitability-quiz-dialog.' + quizType + '.show');

        __defer = $q.defer();

        var scope = createScope();

        scope.suitability = suitability;
        scope.quizType = quizType;
        scope.customCallback = onSubmitSuitability;
        var onSubmitSuitabilityCall = scope.customCallback || getSuitabilityStatus.bind(null, quizType, classification);
        scope.onSubmit = submitSuitability.bind(null, scope.quizType, onSubmitSuitabilityCall, __defer, classification);
        scope.allQuizzes = allQuizzes;
        scope.allNotFailed = allNotFailed;
        scope.onCancel = function () {
            __defer.reject();
            dialogService.close();
        };

        var options = {
            classNames: 'pp-suitability-dialog',
            scope: scope,
            showClose: true
        };

        options.contents = '<pp-suitability suitability="suitability" quiz-type="quizType" all-quizzes="allQuizzes" unfailed-quizzes-left="allNotFailed" on-submit="onSubmit(answers)" on-cancel="onCancel()"></pp-suitability>';

        dialogInstance = dialogService.show(options).then($q.reject);

        return $q.race({
            dialog: dialogInstance,
            result: __defer.promise
        });
    }

    // -- api

    var api = {};

    api.showForPassAnyQuizzes = function (quizzes, isSidebar, classification) {
        var getSuitabilityStatusForQuizzesCall = getSuitabilityStatusForQuizzes(quizzes, classification);

        return getSuitabilityStatusForQuizzesCall().then(function (res) {
            if (res.suitability.status === 'passed') {
                return 'passed';
            }

            return showDialog(res.suitability, res.suitability.quizType, classification, getSuitabilityStatusForQuizzesCall, res.allQuizzes, res.allNotFailed);
        }).then(function (res) {
            if (res === 'passed' && isSidebar) {
                return;
            }
            dialogService.close();
        });
    };

    api.show = function (quizType, classification, isSidebar) {
        return getSuitabilityStatus(quizType, classification).then(function (suitability) {

            if (suitability.status === 'passed') {
                return 'passed';
            }

            return showDialog(suitability, quizType, classification);
        }).then(function (res) {
            if (res === 'passed' && isSidebar) {
                return;
            }
            dialogService.close();
        });

    };

    return api;
}]);
})(window, window.angular);