(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.tooltip', []);

/**
 * @ngdoc directive
 * @name tooltip
 * @description
 * Show a tooltip on hover
 *
 * @restrict A
 * @scope
 * @param {String} title
 * @param {Boolean} disable
 *
 */

// @todo use this directive for remaining angular toolips
// @todo use vm
ngModule.directive('ppTooltip', [function () {
    return {
        restrict: 'A',
        scope: {
            ppTitle: '@',
            ppTooltip: '@',
            position: '@',
            theme: '@',
            width: '@',
            sticky: '<',
            disable: '<',
            delay: '<'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', '$timeout', function ($scope, $element, $timeout) {

            var vm = this;

            // --- initial state

            var TOOLTIP_TIMEOUT;
            var $tooltip;

            // -- util functions

            function handleClick(ev) {
                ev.stopPropagation();
            }

            function updateTooltipContent(description) {
                return function (title) {
                    if (!$tooltip) {
                        return;
                    }
                    if (description) {
                        var content = '';
                        if (title) {
                            content += '<h3 class="label">' + title + '</h3>';
                        }
                        content += '<p class="value">' + description + '</p>';
                        $tooltip.tooltipster('content', content);
                        $element.addClass('copy');

                        if (vm.isSticky) {
                            $tooltip.tooltipster('show');
                        }
                    }
                };
            }

            function changeTooltipContent(changes) {
                if (!$tooltip) {
                    return;
                }
                var makeChanges;
                if (changes.ppTooltip && !changes.ppTooltip.isFirstChange()) {
                    makeChanges = updateTooltipContent(changes.ppTooltip.currentValue);
                } else {
                    makeChanges = updateTooltipContent(vm.ppTooltip);
                }

                if (changes.ppTitle && !changes.ppTitle.isFirstChange()) {
                    makeChanges(changes.ppTitle.currentValue);
                } else {
                    makeChanges(vm.ppTitle);
                }
            }

            function handleTooltipDisable(disabled) {
                if (!$tooltip) {
                    return;
                }
                if (disabled) {
                    $tooltip.tooltipster('disable');
                } else {
                    $tooltip.tooltipster('enable');
                    if (vm.sticky) {
                        $tooltip.tooltipster('open');
                    }
                }
            }

            function handleTooltipSticky(isSticky) {
                if (isSticky && !vm.disable) {
                    $tooltip.tooltipster('open');
                } else {
                    $tooltip.tooltipster('close');
                }

            }

            // -- scope bindings

            vm.$onChanges = function (changes) {
                if ((changes.ppTooltip && !changes.ppTooltip.isFirstChange()) || (changes.ppTitle && !changes.ppTitle.isFirstChange())) {
                    changeTooltipContent(changes);
                }

                if (changes.disable && !changes.disable.isFirstChange()) {
                    handleTooltipDisable(changes.disable.currentValue);
                }

                if (changes.sticky && !changes.sticky.isFirstChange()) {
                    handleTooltipSticky(changes.sticky.currentValue);
                }

            };

            vm.$onDestroy = function () {
                if ($element) {
                    $element.off('click', handleClick);
                    // No need to actually destroy the tooltip
                    // as tooltip library has selfDestruct capabilities
                }
            };

            vm.$onInit = function () {
                TOOLTIP_TIMEOUT = vm.delay || 500;

                var options = {
                    maxWidth: vm.width || 500,
                    contentAsHTML: true,
                    position: vm.position || 'top',
                    zIndex: 15,
                    IEmin: 9
                };

                if (vm.theme) {
                    options.theme = [vm.theme];
                    options.theme = [vm.theme];
                }

                if (vm.sticky) {
                    options.trigger = 'custom';
                    options.autoClose = false;
                }

                $tooltip = $element.tooltipster(options);

                updateTooltipContent(vm.ppTooltip)(vm.ppTitle);

                $element.on('click', handleClick);

                if (vm.disable) {
                    $element.tooltipster('disable');
                }

                if (vm.sticky && !vm.disable) {
                    $tooltip.tooltipster('open');
                }
            };

        }]
    };
}]);
})(window, window.angular);