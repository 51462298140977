(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.checkbox', [
    'pp.components-templates',
    'pp.widgets.animated-tick'
]);

ngModule.component('ppCheckbox', {
    templateUrl: 'components/_angular/checkbox/checkbox.tpl.html',
    transclude: true,
    bindings: {
        model: '=',
        isRequired: '<?',
        isDisabled: '<',
        ppName: '@',
        ppValue: '@',
        ppId: '@',
        align: '@'
    },
    controllerAs: 'vm',
    controller: [function () {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);