(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.settings-pin-change', ['pp.widgets-templates', 'pp.services.core', 'pp.components.focus-first-error', 'pp.components.bind-invalid-model', 'pp.components.focus-first-error', 'pp.components.match-against', 'pp.components.pin-validation']);

var FORM_NAME = 'settings.security.pin-change';

var UPDATED_HIDE_DELAY = 5000;

ngModule.directive('ppSettingsPinChange', [function () {

    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/settings-pin-change/settings-pin-change.tpl.html',
        scope: {
            onSubmit: '&'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$timeout', 'ppTrack', function ($scope, $timeout, ppTrack) {
            var vm = this;

            function formReset() {
                vm.oldPin = undefined;
                vm.newPin = undefined;
                vm.repeatNewPin = undefined;
                vm.error = null;
            }

            // -- util functions

            function handleSuccess() {
                ppTrack.formSubmit(FORM_NAME);
                vm.updated = true;
                formReset();
                $timeout(function () {
                    vm.updated = false;
                }, UPDATED_HIDE_DELAY);
            }

            function handleFailure(error) {
                vm.error = error.reason;
            }

            function handleFinally() {
                vm.processing = false;
            }

            // -- api

            vm.submit = function () {

                if (vm.form.$invalid) {
                    // Do not use ngValidation method in case of tracking sensitive data
                    ppTrack.event('form.' + FORM_NAME + '.validation-error');
                    vm.feedbackEnabled = true;
                    return;
                }

                vm.processing = true;
                vm.feedbackEnabled = false;
                vm.processingError = false;

                var data = {
                    pin: {
                        oldPin: vm.oldPin,
                        newPin: vm.newPin
                    }
                };

                vm.onSubmit(data)
                    .then(handleSuccess, handleFailure)
                    .finally(handleFinally);

            };
        }]
    };
}]);
})(window, window.angular);