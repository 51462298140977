(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.preorder-notes', ['pp.widgets-templates', 'pp.filters.dates']);

/**
 * @ngdoc directive
 * @name ppPreorderNotes
 * @description
 * Shows a note if the property is currently allowing preorders or
 *
 *
 * @restrict A
 * @scope
 * @param {Boolean} isPreorder
 * @param {Boolean} wasPreorder
 * @param {String | timestamp} preorderClosingDate
 */
ngModule.directive('ppPreorderNotes', [function () {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/preorder-notes/preorder-notes.tpl.html',
        scope: {
            property: '<ppPreorderNotes'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: [function () {
            var vm = this;
        }]
    };
}]);
})(window, window.angular);