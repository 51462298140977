(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.premium-services-section', [
    'pp.widgets-templates',
    'pp.ui.services.schedule-call-dialog',
    'pp.services.core'
]);

var WIDGET_NAME = 'premium-services-section';

ngModule.component('ppPremiumServicesSection', {
    templateUrl: 'widgets/_angular/premium-services-section/premium-services-section.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'scheduleCallDialogService', 'ppTrack', function ($scope, scheduleCallDialogService, ppTrack) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        vm.scheduleACall = function () {
            ppTrack.action(WIDGET_NAME + '.schedule-call-dialog.show');
            scheduleCallDialogService.show();
        };

        vm.trackContactUsClick = function () {
            ppTrack.deferAction(WIDGET_NAME + '.contact-us.click');
        };

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);