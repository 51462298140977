(function(window, angular){'use strict';

var ngModule = angular.module('pp.components.minimum-funding-info', [
    'pp.widgets-templates',
    'pp.filters.numbers'
]);

ngModule.component('ppMinimumFundingInfo', {
    templateUrl: 'widgets/_angular/minimum-funding-info/minimum-funding-info.tpl.html',
    bindings: {
        user: '<',
        quote: '<',
        minimumTransaction: '<',
        leftToPay: '<',
        hideContinueMessage: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);