(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.isa-lp', [
    'pp.widgets-templates',
    'pp.services.investor',
    'pp.services.config',
    'pp.services.route',
    'pp.services.core',
    'pp.ui.services.risk-warning',
    'pp.widgets.risk-warning-badge',
    'pp.widgets.expandable-content',
    'pp.widgets.terms-apply-badge',
    'pp.widgets.isa-investment-list',
    'pp.widgets.awards-banner'
]);

var PAGE_NAME = 'isa-lp';

var FAQ1_KEY = 'invest';
var FAQ1_TITLE = 'What is an Innovative Finance ISA?';

var FAQ2_KEY = 'earn';
var FAQ2_TITLE = 'What type of investments can I make with the LHX ISA?';

var FAQ3_KEY = 'track';
var FAQ3_TITLE = 'How much can I invest through the LHX ISA?';

var FAQ4_KEY = 'sell';
var FAQ4_TITLE = 'When will I be able to make investments with the LHX ISA?';

var FAQ5_KEY = 'work';
var FAQ5_TITLE = 'Am I eligible for ISA investing?';

var FAQ6_KEY = 'charge';
var FAQ6_TITLE = 'Are there any charges?';

var FAQ7_KEY = 'risk';
var FAQ7_TITLE = 'Does ISA eligibility mean this is a less risky investment?';

ngModule.component('ppIsaLp', {
    templateUrl: 'widgets/_angular/isa-lp/isa-lp.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$window', '$q', 'configService', 'investorService', 'routeService', 'ppTrack', 'ppConfig', 'riskWarningService', function ($scope, $window, $q, configService, investorService, routeService, ppTrack, ppConfig, riskWarningService) {
        var vm = this;

        vm.faq1Expanded = true;

        // -- initial state

        vm.faq1 = FAQ1_TITLE;
        vm.faq2 = FAQ2_TITLE;
        vm.faq3 = FAQ3_TITLE;
        vm.faq4 = FAQ4_TITLE;
        vm.faq5 = FAQ5_TITLE;
        vm.faq6 = FAQ6_TITLE;
        vm.faq7 = FAQ7_TITLE;

        vm.openIsaCta = 'Open an ISA portfolio';

        var __config = ppConfig.get('pp.external.endpoints') || {};
        var s3Endpoint = __config.assets;

        vm.heroIcon = s3Endpoint + '/images/isa/isa-icon-lp.svg';
        vm.isaImg = s3Endpoint + '/images/isa/isa-mobile-image.png';

        vm.isaSetupPath = routeService.isaSetupPath;

        vm.mortgageBondKnowledgebase = routeService.mortgageBondKnowledgebase;

        vm.isaFaqs = routeService.isaFaqs;

        vm.isaTransferHelp = routeService.isaTransferHelp;
        vm.investorClassificationHelp = routeService.investorClassificationHelp;
        vm.isaFeesChargesHelp = routeService.isaFeesChargesHelp;

        // - section keys

        vm.faq1Key = FAQ1_KEY;
        vm.faq2Key = FAQ2_KEY;
        vm.faq3Key = FAQ3_KEY;
        vm.faq4Key = FAQ4_KEY;
        vm.faq5Key = FAQ5_KEY;
        vm.faq6Key = FAQ6_KEY;
        vm.faq7Key = FAQ7_KEY;

        // -- util functions

        function escapeRedirect() {
            $window.location.href = routeService.marketplacePath;
        }

        function redirectToDashboard() {
            $window.location.href = routeService.dashboardPathIsa;
        }

        function isIsaSetup() {
            return investorService.getUserInvestors().then(investorService.isIsaSetup);
        }

        // -- api

        riskWarningService.showDevLoanRiskWarning();

        // -- scope bindings

        // -- main
        $q.all({
            isIsaPublic: configService.isIsaPublic(),
            isIsaSetup: isIsaSetup()
        }).then(function (res) {
            ppTrack.pageLoad(PAGE_NAME);
            if (!res.isIsaPublic) {
                ppTrack.deferEvent(PAGE_NAME + '.isa-not-public.redirect');
                return escapeRedirect();
            } else if (res.isIsaSetup) {
                ppTrack.deferEvent(PAGE_NAME + '.isa-not-already-setup.redirect');
                redirectToDashboard();
            } else {
                vm.ready = true;
            }
        });
    }]
});
})(window, window.angular);