(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketing-preferences', [
    'pp.widgets-templates',
    'pp.widgets.marketing-preferences-form',
    'pp.components-templates',
    'pp.components.focus-first-error',
    'pp.services.core',
    'pp.services.settings',
    'pp.services.investor',
    'pp.services.marketing'
]);

var FORM_NAME = 'preferencesForm';
var INVESTOR_CACHE_KEY = 'investor';

ngModule.component('ppMarketingPreferences', {
    templateUrl: 'widgets/_angular/marketing-preferences/marketing-preferences.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', '$q', 'ppTrack', 'investorService', 'marketingService', 'settingsService', function ($scope, $q, ppTrack, investorService, marketingService, settingsService) {
        var vm = this;

        // -- initial state
        vm.contactOptions = [];
        vm.subscriptions = [];
        vm.contactError = false;
        vm.ready = false;

        // -- util functions
        function handleSuccess() {
            vm.updated = true;
            vm.processing = false;
            ppTrack.event('marketing-preferences.submit.success');
        }

        function handleError() {
            vm.processing = false;
            vm.apiError = true;
            ppTrack.event('marketing-preferences.submit.error');
        }

        function formatSubscriberPreferences() {
            var subscriptionsObj = vm.subscriptions.map(function (subscription) {
                return {
                    id: subscription.id,
                    name: subscription.name,
                    subscribed: subscription.subscribed
                };
            });

            var contactObj = vm.contactOptions.map(function (contactOption) {
                return {
                    type: contactOption.type,
                    enabled: contactOption.enabled
                };
            });

            var preferencesObj = {
                subscriberPreferences: {
                    subscriptions: subscriptionsObj,
                    contactPreferences: contactObj
                }
            };

            return preferencesObj;
        }

        // -- api
        vm.submit = function () {
            vm.feedbackEnabled = true;
            ppTrack.action('marketing-preferences.submit');

            if (vm.preferencesForm.$valid && !vm.contactError) {
                var promises = [];
                vm.processing = true;
                vm.apiError = false;
                vm.updated = false;

                if (vm.phoneNumber) {
                    promises.push(settingsService.setContactDetails({
                        phoneNumber: vm.phoneNumber
                    }).then(function () {
                        investorService.purgeCache(INVESTOR_CACHE_KEY);
                    }, angular.noop));
                }

                var preferencesObj = formatSubscriberPreferences();
                promises.push(marketingService.setMarketingPreference(preferencesObj));

                $q.all(promises).then(handleSuccess, handleError);
            } else {
                ppTrack.ngFormValidation(FORM_NAME, vm.preferencesForm);
            }
        };

        // -- scope bindings

        // -- main

        //get investor preferences data
        var promises = {
            investor: investorService.getInvestor(),
            preferences: marketingService.getMarketingPreference()
        };

        $q.all(promises).then(function (data) {
            var preferences = data.preferences || {};
            vm.subscriptions = angular.copy(preferences.subscriptions) || [];
            vm.contactOptions = angular.copy(preferences.contactPreferences) || [];
            vm.phoneNumber = data.investor.phoneNumber;
            vm.ready = true;
        }, function () {
            vm.displayAPIWarning = true;
            vm.ready = true;
        });

    }]
});
})(window, window.angular);