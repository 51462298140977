(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-card-embed', [
    'pp.widgets-templates',
    'pp.widgets.property-card',
    'pp.widgets.loading-card',
    'pp.services.marketplace',
    'pp.services.core'
]);

var PAGE_NAME = 'property-card-embed';

ngModule.component('ppPropertyCardEmbed', {
    templateUrl: 'widgets/_angular/property-card-embed/property-card-embed.tpl.html',
    bindings: {},
    controllerAs: 'vm',
    controller: ['$scope', 'marketplaceService', 'ppConfig', 'ppTrack', function ($scope, marketplaceService, ppConfig, ppTrack) {
        var vm = this;

        // -- initial state

        var property = ppConfig.get('pp.widgets.property') || {};

        vm.user = {
            anon: true
        };

        // -- util functions

        function getPromise() {
            if (property.symbol) {
                return marketplaceService.getPropertiesList({
                    symbol: property.symbol
                });
            } else {
                return marketplaceService.getPropertiesList({
                    limit: 3
                });
            }
        }

        // -- api

        function isNotFund(property) {
            return !property.isFund;
        }

        // -- scope bindings

        // -- main

        var promise = getPromise();

        promise.then(function (property) {
            vm.property = property[0];

            if (vm.property) {
                vm.ready = true;
            }
        });

    }]
});
})(window, window.angular);