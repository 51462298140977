(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.address-kyc-uk', ['ng.cork.ui.focus-on', 'pp.widgets-templates', 'pp.services.core', 'pp.services.gbGroup', 'pp.components.required-any', 'pp.values.form-lists']);

// @todo general purpose, lift
function ridGenerator(len) {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    var str = '';
    while (str.length < len) {
        str += S4();
    }
    return str.substring(0, len);
}

/**
 * @ngdoc directive
 * @name ppAddressUk
 * @description
 * UK Address form, tied to the kyc form to render the repeatable section of UK address with postcode lookup
 * Requires the helper service to process outcomes from backend and manipulate oddities in address object
 * Requires gbGroupService to do post code validations and address lookups.
 * Requires ppBrowser service to show different select boxes
 *
 * @restrict A
 * @scope
 * @param {object} address
 * @param {string} country
 * @param {boolean} feedbackEnabled
 * @param {boolean} lookupAvailable
 *
 * @todo SOMEDAY rename module to pp.widgets.address-uk
 */
ngModule.directive('ppAddressUk', ['$http', 'gbGroupService', 'ppBrowser', function ($http, gbGroupService, ppBrowser) {
    return {
        restrict: 'A',
        templateUrl: 'widgets/_angular/address-kyc-uk/address-kyc-uk.tpl.html',
        scope: {
            address: '=ppAddressUk',
            country: '@',
            inputDisabled: '=',
            feedbackEnabled: '=',
            lookupAvailable: '@',
            index: '@'
        },
        controllerAs: 'vm',
        bindToController: true,
        controller: ['$scope', '$element', 'ppTrack', 'ppComponentEmitter', function ($scope, $element, ppTrack, ppComponentEmitter) {
            var vm = this;

            // -- initial state

            vm.rid = ridGenerator(4);

            vm.mobile = ppBrowser.isMobile;
            vm.enterManually = false;
            vm.postcodeRegexp = gbGroupService.postcodeRegexp;
            vm.lookupInProgress = false;
            vm.inlineNotices = {};

            // -- util functions

            function clearAddress() {
                for (var prop in vm.address) {
                    delete vm.address[prop];
                }
            }

            // -- api

            // mocked postcode SE14YH
            // enables postcode validation messages
            // if the postcode the results where generated for changes the results become hidden
            vm.lookupAddress = function () {
                vm.lookeduppostcode = vm.postcode;
                clearAddress();
                if (vm.form.postcode.$invalid) {
                    vm.lookupFeedbackEnabled = true;
                } else {
                    vm.lookupInProgress = true;
                    vm.lookupError = null;
                    gbGroupService.addressLookup(vm.postcode, vm.country).then(function (addresses) {
                        vm.addressResults = addresses;
                    }, function (error) {
                        vm.addressResults = [];
                        vm.lookupError = error.reason;
                        vm.lookupFeedbackEnabled = true;
                        if (error.reason === 'gbGroup.error.noMatches' || error.reason === 'gbGroup.error.postcode.invalid') {
                            vm.postcodeNotMatched = vm.postcode;
                        }
                    })['finally'](function () {
                        vm.lookupInProgress = false;
                    });
                }
            };

            // lookup when user presses enter
            vm.lookupOnKeyPress = function (event) {
                if (event.keyCode === 13 && !vm.enterManually) {
                    event.preventDefault();
                    vm.lookupAddress();
                }
            };

            // empty address properties and repopulate from address
            // because if we use ng-model="vm.address" directly in the `addressResults` select
            // this will cause the ng-repeat in kyc-form to re-render and therefore this directive will be destroyed and created again
            vm.addressListOnChange = function () {

                // fixes PP-1701 address not being selected because addressListOnChange() is now triggered twice
                // first when the user selects, second the results are cleared and the ng-model is nulled
                // this was not the case with angular 1.3, this 1.5 behaviour and it actually makes more sense
                if (vm.selectedAddress) {
                    var prop;
                    clearAddress();
                    for (prop in vm.selectedAddress) {
                        vm.address[prop] = vm.selectedAddress[prop];
                    }
                }
            };

            // also clears the address lookup error and the selected address (forces form invalid)
            vm.postcodeOnChange = function () {
                if (vm.postcode !== vm.lookeduppostcode) {
                    clearAddress();
                    vm.lookupError = null;
                    vm.addressResults = null;
                    vm.selectedAddress = null;
                }
                vm.address.postcode = vm.postcode;
            };

            // remove lookup errors when switching to manual entry
            // also cleas nup the lookup selection
            vm.toggleEnterManually = function () {
                var toggleAction = vm.enterManually ? 'cancel' : 'show';
                ppTrack.action('address-kyc-uk.enter-manually.' + toggleAction);

                vm.lookupError = null;
                vm.enterManually = !vm.enterManually;

                if (vm.enterManually && vm.selectedAddress) {
                    vm.address = angular.copy(vm.selectedAddress);
                }

                if (vm.enterManually) {
                    vm.address = vm.address || {};
                    vm.address.postcode = vm.postcode;
                }

                vm.selectedAddress = null;
            };

            // -- scope bindings

            // In this instance vm.feedbackEnabled is a proxy for form.$submitted
            $scope.$watch('vm.feedbackEnabled', function (enabled) {
                if (enabled && vm.form.$invalid) {
                    // If there is an error when submitting kyc form in address always open manual address entry
                    // This is so any errors that may be hidden within the selected address from postcode lookup
                    // are exposed.
                    vm.enterManually = true;
                }
            });

            // -- main

            vm.$postLink = function () {
                ppComponentEmitter.emit('address-kyc-uk.dom.loaded', $element);
            };

            vm.$onInit = function () {
                ppComponentEmitter.emit('address-kyc-uk.controller.loaded', vm);
            };
        }]
    };
}]);
})(window, window.angular);