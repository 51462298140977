(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.marketing-event-card', [
    'pp.widgets-templates'
]);

ngModule.component('ppMarketingEventCard', {
    templateUrl: 'widgets/_angular/marketing-event-card/marketing-event-card.tpl.html',
    bindings: {
        eventTitle: '<',
        type: '<',
        subtitle: '<',
        description: '<',
        date: '<',
        cta: '<',
        url: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', function ($scope) {
        var vm = this;

        // -- initial state

        // -- util functions

        // -- api

        // -- scope bindings

        // -- main

    }]
});
})(window, window.angular);