(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-total-return-table', [
    'pp.widgets-templates',
    'yaru22.angular-timeago',
    'pp.controllers.dashboard-table',
    'pp.components.tooltip',
    'pp.widgets.aum-dividend-notice',
    'pp.services.number'
]);

ngModule.component('ppDashboardTotalReturnTable', {
    templateUrl: 'widgets/_angular/dashboard-total-return-table/dashboard-total-return-table.tpl.html',
    bindings: {
        data: '<',
        sortAttribute: '<',
        sortOrder: '<',
        showTradingValuation: '<',
        openSidebarSymbol: '<',
        onChange: '&',
        switchValuationMethod: '&',
        valuationMethod: '<',
        showValuationTooltips: '<'

    },
    controllerAs: 'vm',
    controller: 'ppDashboardTableCtrl'
});
})(window, window.angular);