(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.property-image-single', [
    'pp.widgets-templates',
    'pp.ui.services.wistia-video-dialog',
    'pp.services.core'
]);

ngModule.component('ppPropertyImageSingle', {
    templateUrl: 'widgets/_angular/property-image-single/property-image-single.tpl.html',
    bindings: {
        property: '<'
    },
    controllerAs: 'vm',
    controller: ['$scope', 'ppConfig', 'wistiaVideoDialogService', function ($scope, ppConfig, wistiaVideoDialogService) {
        var vm = this;

        // -- initial state

        var __config = ppConfig.get('pp.external.endpoints') || {};
        var s3Endpoint = __config.assets;

        // -- util functions

        function createUrl(property) {
            return s3Endpoint + '/images/properties/' + property.symbol + '/image-single.jpg';
        }

        // -- api

        vm.showVideo = function () {
            if (vm.property.metadata.video_id) {
                wistiaVideoDialogService.show(vm.property.metadata.video_id);
            }
        };

        // -- lifecycle

        vm.$onChanges = function (changes) {
            if (changes.property && changes.property.currentValue) {
                vm.propertyImageUrl = createUrl(changes.property.currentValue);
            }
        };

        // -- main

    }]
});
})(window, window.angular);