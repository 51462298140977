(function(window, angular){'use strict';

var ngModule = angular.module('pp.widgets.dashboard-current-loans-table', [
    'pp.widgets-templates',
    'pp.controllers.dashboard-table'
]);

ngModule.component('ppDashboardCurrentLoansTable', {
    templateUrl: 'widgets/_angular/dashboard-current-loans-table/dashboard-current-loans-table.tpl.html',
    bindings: {
        data: '<'
    },
    controllerAs: 'vm',
    controller: 'ppDashboardTableCtrl'
});
})(window, window.angular);